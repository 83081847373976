import { defineStore } from 'pinia';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { getCurrentDate, downloadFileFromMemory } from '@/utils';

import {
  isValidContextPeriodMode,
  isValidReportingPeriodMode,
  REPORTING_PERIOD_MODES,
  CONTEXT_PERIOD_MODES,
  updateDateRange,
} from '@/components/core/comparison-date-picker/constants';
import { DashboardAPI, FacebookAPI } from '@/apis';
import * as FacebookAllAPI from '@/apis/facebook';

export const REPORT_RANGE_DEFAULT = [getCurrentDate(7), getCurrentDate(1)];
export const CONTEXT_RANGE_DEFAULT = [getCurrentDate(14), getCurrentDate(8)];

export function formatSummaryMetrics(responseMetrics) {
  if (!responseMetrics) return {};
  const result = cloneDeep(responseMetrics);

  Object.keys(result).forEach((key) => {
    if (result[key].FACEBOOK && result[key].FACEBOOK.value == null) {
      result[key].FACEBOOK.value = '-';
    }
  });
  return result;
}

function toCampaignMap(campaignList) {
  return (campaignList ?? []).reduce((acc, campaign) => {
    acc[campaign.sourceCampaignId] = campaign;
    return acc;
  }, {});
}

export const useFacebookStore = defineStore('facebook', {
  resetOnBrandChange: true,
  state: () => ({
    pending: {
      pageMetrics: {
        summary_metrics: false,
        timeseries_metrics: false,
        past_twelve_weeks: false,
      },
      marketingV2Campaigns: false,
      fbAdsAccounts: false,
    },
    campaignList: [],
    fbAdsAccounts: [],
    adAccounts: {},
    adAccountsLoading: false,
    pageFansCSV: null,
    pageMetricsCSV: null,
    topPostsCSV: null,
    allPostsCSV: null,
    exportPending: false,
    pageMetrics: {
      summary_metrics: {},
      timeseries_metrics: {},
      past_twelve_weeks: {},
    },
    twelveWeekAverageEngagement: null,
    reportDateRange: cloneDeep(REPORT_RANGE_DEFAULT),
    contextDateRange: cloneDeep(CONTEXT_RANGE_DEFAULT),
    marketingV2Campaigns: {},
  }),
  getters: {
    adAccountsList(state) {
      const clonedAdAccounts = cloneDeep(state.adAccounts);
      const uniqAdAccounts = Object.values(
        Object.entries(clonedAdAccounts).reduce((acc, [brandId, adAccounts]) => {
          const brandIdAsInt = parseInt(brandId, 10);
          for (let i = 0; i < adAccounts?.length; i += 1) {
            const adAccount = adAccounts[i];
            if (!acc[adAccount.accountId]) {
              acc[adAccount.accountId] = adAccount;
              acc[adAccount.accountId].brandIds = [brandIdAsInt];
            } else if (!acc[adAccount.accountId].brandIds.includes(brandIdAsInt)) {
              acc[adAccount.accountId].brandIds.push(brandIdAsInt);
            }
          }
          return acc;
        }, {}),
      );
      const sortedUniqAdAccounts = uniqAdAccounts.sort((adAccountA, adAccountB) => {
        return adAccountA.accountName.localeCompare(adAccountB.accountName);
      });
      return sortedUniqAdAccounts;
    },
    metaAdsAccountToCurrency() {
      const mapping = {};
      this.adAccountsList.forEach((account) => {
        mapping[account.accountId] = account.currency;
      });
      return mapping;
    },
  },
  actions: {
    updateReportingPeriodMode(mode) {
      let valueToBeUsed = null;
      if (isValidReportingPeriodMode(mode)) {
        valueToBeUsed = mode;
      } else {
        valueToBeUsed = REPORTING_PERIOD_MODES.LAST_7_DAYS.value;
      }

      this.reportingPeriodMode = valueToBeUsed;
    },
    updateContextPeriodMode(mode) {
      this.reportingPeriodMode = isValidContextPeriodMode(mode)
        ? mode
        : CONTEXT_PERIOD_MODES.PREVIOUS_WEEK.value;
    },

    initDateRanges({
      startDate,
      endDate,
      contextStartDate,
      contextEndDate,
      reportingPeriodMode,
      contextPeriodMode,
    }) {
      this.updateReportDate({ reportDateRange: [startDate, endDate] });
      this.updateContextDate({ contextDateRange: [contextStartDate, contextEndDate] });
      this.updateReportingPeriodMode(reportingPeriodMode);
      this.updateContextPeriodMode(contextPeriodMode);
    },
    updateReportDate({ reportDateRange }) {
      updateDateRange(this.$state, 'reportDateRange', reportDateRange, REPORT_RANGE_DEFAULT);
    },
    updateContextDate({ contextDateRange }) {
      updateDateRange(this.$state, 'contextDateRange', contextDateRange, CONTEXT_RANGE_DEFAULT);
    },
    updateDateRanges({ newReportDateRange, newContextDateRange }) {
      const hasNewReportDateRange = newReportDateRange?.length > 0;
      const hasNewContextDateRange = newContextDateRange?.length > 0;

      const hasUpdatedReportDateRange = !isEqual(newReportDateRange, this.reportDateRange);
      const hasUpdatedContextDateRange = !isEqual(newContextDateRange, this.contextDateRange);

      if (hasNewReportDateRange && hasUpdatedReportDateRange) {
        this.updateReportDate({ reportDateRange: newReportDateRange });
      }

      if (hasNewContextDateRange && hasUpdatedContextDateRange) {
        this.updateContextDate({ newContextDateRange });
      }
    },
    async getPageMetrics({ brandId, startDate, endDate, scale, metrics }) {
      this.pending.pageMetrics = {
        summary_metrics: true,
        timeseries_metrics: true,
        past_twelve_weeks: true,
      };
      this.pageMetrics = {
        summary_metrics: {},
        timeseries_metrics: {},
        past_twelve_weeks: {},
      };
      await this.getTwelveWeeksData({
        brandId,
        startDate: startDate.slice(0, 10),
        endDate: endDate.slice(0, 10),
        scale,
      });

      await this.getTopLineMetrics({
        brandId,
        startDate,
        endDate,
        requestedMetrics: metrics,
        contextStartDate: startDate, // TODO: Facebook overview should support context dates
        contextEndDate: endDate, // TODO: Facebook overview should support context dates
      });
      await this.getGraphStats({
        brandIds: brandId,
        startDate,
        endDate,
        contextStartDate: startDate, // TODO: Facebook overview should support context dates
        contextEndDate: endDate, // TODO: Facebook overview should support context dates
        timeScale: scale,
      });
    },
    async getTwelveWeeksData({ brandId, startDate, endDate, scale }) {
      this.pending.pageMetrics.past_twelve_weeks = true;
      try {
        const response = await FacebookAPI.getPageMetrics({ brandId, startDate, endDate, scale });

        const data = response?.data;
        this.pageMetrics.past_twelve_weeks = { ...data.past_twelve_weeks };
        this.twelveWeekAverageEngagement = Math.round(
          this.pageMetrics?.past_twelve_weeks?.avg_engagements,
        );
      } catch (error) {
        if (error?.response?.status === 404) {
          this.pageMetrics.past_twelve_weeks = {};
        } else {
          throw error;
        }
      } finally {
        this.pending.pageMetrics.past_twelve_weeks = false;
      }
      return this.pageMetrics.past_twelve_weeks;
    },
    async getTopLineMetrics({
      brandId,
      startDate,
      endDate,
      contextStartDate,
      contextEndDate,
      requestedMetrics,
    }) {
      this.pending.pageMetrics.summary_metrics = true;

      try {
        const response = await DashboardAPI.getTopLineStatsForChannel({
          brandIds: brandId,
          requirePosts: true,
          startDate,
          endDate,
          contextStartDate,
          contextEndDate,
          channels: 'FACEBOOK',
          metrics: requestedMetrics.join(','),
        });
        this.pageMetrics.summary_metrics = formatSummaryMetrics(
          response.data.data[brandId]?.metrics,
        );
      } catch (error) {
        if (error?.response?.status === 404) {
          this.pageMetrics.summary_metrics = {};
        } else {
          throw error;
        }
      } finally {
        this.pending.pageMetrics.summary_metrics = false;
      }
      return this.pageMetrics.summary_metrics;
    },
    async getGraphStats({
      brandIds,
      startDate,
      endDate,
      contextStartDate,
      contextEndDate,
      timeScale,
    }) {
      this.pending.pageMetrics.timeseries_metrics = true;
      try {
        const response = await DashboardAPI.getGraphStats({
          brandIds,
          startDate,
          endDate,
          contextStartDate,
          contextEndDate,
          timeScale,
          channels: 'FACEBOOK',
          metrics:
            'NET_NEW_FOLLOWERS,TOTAL_FOLLOWERS,TOTAL_ENGAGEMENTS,AVG_ENGAGEMENT_RATE,AVG_EFFECTIVENESS,TOTAL_POST_REACH,IMPRESSIONS,LINK_CLICKS',
        });
        this.pageMetrics.timeseries_metrics = response.data?.data?.[brandIds]?.FACEBOOK;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.pageMetrics.timeseries_metrics = {};
        } else {
          throw error;
        }
      } finally {
        this.pending.pageMetrics.timeseries_metrics = false;
      }
      return this.pageMetrics.timeseries_metrics;
    },
    async getCampaignList({ brandId }) {
      const response = FacebookAPI.getCampaignList({ brandId });
      this.campaignList = response?.data;
    },
    async fetchFbAccountsFromApi({ brandId, fetchApi, connectFacebookAdsToken }) {
      this.pending.fbAdsAccounts = true;
      try {
        const response = await FacebookAPI.getAccountsFromApi({
          brandId,
          fetchApi,
          connectFacebookAdsToken,
        });
        const data = response?.data || [];
        this.fbAdsAccounts = data.map((item) => ({ ...item, brandId }));
      } finally {
        this.pending.fbAdsAccounts = false;
      }
    },
    async getAdAccounts({ brandIds }) {
      const brandIdsToRequest = (brandIds ?? []).filter((brandId) => !this.adAccounts[brandId]);
      if (brandIdsToRequest.length === 0) return this.adAccounts;

      this.adAccountsLoading = true;
      try {
        const { data } = await FacebookAPI.getAccountsMultiBrandsFromApi(
          { brandIds: brandIdsToRequest },
          { camelizeKeys: true },
        );
        this.adAccounts = merge(this.adAccounts, data) ?? {};
      } finally {
        this.adAccountsLoading = false;
      }
      return this.adAccounts;
    },
    clearAdAccounts() {
      this.adAccounts = {};
    },
    async getMarketingV2Campaigns({ brandIds, adAccountIds, sourceCampaignIds }) {
      const outstandingSourceCampaignIds = sourceCampaignIds?.filter(
        (sourceCampaignId) => !this.marketingV2Campaigns[sourceCampaignId],
      );

      if (
        (brandIds?.length > 0 && adAccountIds?.length > 0) ||
        outstandingSourceCampaignIds?.length > 0
      ) {
        this.pending.marketingV2Campaigns = true;
        try {
          const response = await FacebookAllAPI.getMarketingV2Campaigns({
            brandIds,
            adAccountIds,
            sourceCampaignIds: outstandingSourceCampaignIds,
          });
          const payload = response?.data;
          if (payload) {
            const newCampaignMap = toCampaignMap(payload);
            this.marketingV2Campaigns = {
              ...this.marketingV2Campaigns,
              ...newCampaignMap,
            };
          }
        } finally {
          this.pending.marketingV2Campaigns = false;
        }
      }
    },
    async updateFbAccount({ brandId, fbAccountId, isMonitored }) {
      return FacebookAPI.updateAccount({ brandId, fbAccountId, isMonitored });
    },
    async deleteFbAccount({ brandId, fbAccountId }) {
      return FacebookAPI.deleteAccount({ brandId, fbAccountId });
    },
    async getPageFansCSV({ brandId, startDate, endDate, filename, scale }) {
      this.exportPending = true;
      try {
        const response = await FacebookAPI.getPageFansCSV({
          brandId,
          startDate,
          endDate,
          filename,
          scale,
        });
        downloadFileFromMemory(response?.data, response?.config?.params?.filename);
      } finally {
        this.exportPending = false;
      }
    },
    async getTopPostsCSV({ brandId, startDate, endDate, sortOrder, filename, scale }) {
      this.exportPending = true;
      try {
        const response = await FacebookAPI.getTopPostsCSV({
          brandId,
          startDate,
          endDate,
          sortOrder,
          filename,
          scale,
        });
        downloadFileFromMemory(response?.data, response?.config?.params?.filename);
      } finally {
        this.exportPending = false;
      }
    },
    async getAllPostsCSV({ brandId, startDate, endDate, socketId, filename }) {
      try {
        return await FacebookAPI.getAllPostsCSV({
          brandId,
          startDate,
          endDate,
          socketId,
          filename,
        });
      } finally {
        this.exportPending = true;
      }
    },
    receivedCSV() {
      this.exportPending = false;
    },
  },
});
