<template>
  <div
    class="date-range-input"
    :class="{ highlight: highlight, invalid: !valid, moveLabelUp: moveLabelUp, disabled }"
  >
    <label v-if="label">{{ label }}</label>
    <input
      ref="dateRangeInput"
      v-model="internalValue"
      type="text"
      @input="onInput"
      @focus="active = true"
      @blur="active = false"
    />
    <div v-if="!hideMessages" class="messages messages-error">
      <div v-for="(error, index) in errors" :key="index" class="message">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import debounce from 'lodash/debounce';
import upperFirst from 'lodash/upperFirst';
import toLower from 'lodash/toLower';
import dayjs from 'dayjs';
import { debounceInputDelay } from '@/config';
import validateRules from '@/utils/ruleValidation';

const TEXT_DATE_FORMAT = 'MMM DD, YYYY';
const DEFAULT_DATE_FORMATS = [
  TEXT_DATE_FORMAT,
  'MMM D, YYYY',
  'MMMM DD, YYYY',
  'MMMM D, YYYY',
  'MMMM DD YYYY',
  'MMMM D YYYY',
  'MMMM DD',
  'MMMM D',
  'MMM DD YYYY',
  'MMM D YYYY',
  'MMM DD',
  'MMM D',
  'MM DD YY',
  'MM D YY',
  'M DD YY',
  'M D YY',
  'MM/DD/YYYY',
  'MM/D/YYYY',
  'M/DD/YYYY',
  'M/D/YYYY',
  'MM-DD-YYYY',
  'MM-D-YYYY',
  'M-DD-YYYY',
  'M-D-YYYY',
  'YYYY-MM-DD',
  'YYYY-MM-D',
  'YYYY-M-DD',
  'YYYY-M-D',
  'YYYY/MM/DD',
  'YYYY/MM/D',
  'YYYY/M/DD',
  'YYYY/M/D',
  'YYYY MM DD',
  'YYYY MM D',
  'YYYY M DD',
  'YYYY M D',
];

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: undefined,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    hideMessages: {
      type: Boolean,
      default: false,
    },
    dateFormats: {
      type: Array,
      default: () => DEFAULT_DATE_FORMATS,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['errors', 'active', 'input'],
  data() {
    return {
      internalValue: undefined,
      errors: [],
      active: undefined,
    };
  },
  computed: {
    valid() {
      return this.errors.length === 0;
    },
    moveLabelUp() {
      return this.active || !!this.internalValue;
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (dayjs.isDayjs(newValue)) {
          const newFormattedValue = newValue.format(TEXT_DATE_FORMAT);
          if (this.internalValue !== newFormattedValue) {
            this.internalValue = newFormattedValue;
          }
        } else {
          this.internalValue = null;
        }
        this.updateValue();
      },
      immediate: true,
    },
    focus: {
      handler() {
        if (this.focus) {
          nextTick(() => {
            this.$refs.dateRangeInput.focus();
          });
        }
      },
      immediate: true,
    },
    errors() {
      this.$emit('errors', this.errors);
    },
    active(value) {
      if (value === false) {
        this.updateValue();
      }
      this.$emit('active', value);
    },
  },
  methods: {
    onInput: debounce(function delay() {
      this.updateValue();
    }, debounceInputDelay),
    async updateValue() {
      this.errors = [];
      if (this.internalValue) {
        const userDateInput = this.convertToDayjs(this.internalValue);
        if (userDateInput.isValid()) {
          await this.validate(userDateInput);
          if (this.valid) {
            if (!userDateInput.isSame(this.value, 'day')) {
              this.$emit('input', userDateInput);
            }
          }
        } else {
          this.errors.push(`Invalid date`);
        }
      } else if (this.value != null) {
        this.$emit('input', null);
      }
    },
    async validate(value) {
      this.errors = await validateRules(this.rules, value);
    },
    convertToDayjs(value) {
      const modifiedValue = upperFirst(toLower(value));
      return this.dateFormats?.reduce((found, dateFormat) => {
        if (found && dayjs.isDayjs(found) && found.isValid()) {
          return found;
        }
        return dayjs(modifiedValue, dateFormat, true);
      }, null);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.date-range-input {
  position: relative;
  padding: 0 var(--space-4);

  label {
    position: absolute;
    background: var(--background-0);
    margin-bottom: 0;
    left: var(--x16);
    top: var(--x16);
    pointer-events: none;
    transition: var(--transition-all);
  }

  &.moveLabelUp {
    label {
      left: var(--x10);
      top: -4px;
      transition: var(--transition-all);
    }
  }

  input {
    height: 2.5rem;
    width: 100%;
    margin: var(--space-8) auto 0;
    padding: var(--space-4);
  }

  .messages {
    margin-top: 2px;
    margin-left: 4px;
    flex: 1 1 auto;
    font-size: var(--x12);
    min-height: var(--x14);
    min-width: 1px;
    position: relative;

    &.messages-error {
      color: var(--error-500);
    }

    .message {
      display: none;
      line-height: 12px;
      word-break: break-word;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }
  }

  &.highlight {
    label {
      color: var(--action-500);
    }

    input {
      border-color: var(--action-400);
      color: var(--action-500);
      font-weight: 500;

      &::placeholder {
        color: var(--action-500);
        -webkit-text-fill-color: var(--action-500);
      }
    }
  }

  &.invalid {
    input {
      border-color: var(--error-500);
    }

    label {
      color: var(--error-500);
    }

    .messages {
      .message {
        display: block;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>
