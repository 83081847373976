import { ref, computed, watch } from 'vue';
import { defineStore } from 'pinia';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { datadogRum } from '@datadog/browser-rum';
import kebabCase from 'lodash/kebabCase';
import { useIdentityStore } from '@/stores/identity';
import { env } from '@/env';

export const createFlagsProxy = (base = {}) => {
  return new Proxy(
    {
      internalPoc: false,
      ...base,
    },
    {
      get(target, prop) {
        // The __v_isRef property is used by vue to determine if this is a ref or not.
        // We need to return the real value for this and not default to true.
        if (['__v_raw', '__v_isRef'].includes(prop)) {
          // eslint-disable-next-line prefer-rest-params
          return Reflect.get(...arguments);
        }
        const value = target[prop];
        return value === undefined ? true : value;
      },
    },
  );
};

export const useFlagStore = defineStore('flag', () => {
  const clientSideId = `${env.launchdarklyToken}`;
  const identityStore = useIdentityStore();

  let waitForUserFlagsResolve;
  let waitForUserFlagsReject;
  const waitForUserFlags = new Promise((resolve, reject) => {
    waitForUserFlagsResolve = resolve;
    waitForUserFlagsReject = reject;
  });

  const initialized = ref(false);
  const ready = ref(false);
  const userFlagsReady = ref(false);
  const internalFlags = ref({});
  const clientError = ref(null);
  const isMocked = ref(false);
  const flagsMock = ref(null);
  let client = null;

  function createVariationFlagsProxy() {
    return new Proxy(
      {},
      {
        get(target, prop) {
          // The __v_isRef property is used by vue to determine if this is a ref or not.
          // We need to return the real value for this and not default to true.
          if (prop === '__v_isRef') {
            // eslint-disable-next-line prefer-rest-params
            return Reflect.get(...arguments);
          }
          const flag = kebabCase(prop);
          return client?.variation(flag);
        },
      },
    );
  }

  const flags = computed({
    get() {
      return isMocked.value ? flagsMock.value : internalFlags.value;
    },
    set(v) {
      if (isMocked.value) {
        flagsMock.value = v;
      } else {
        internalFlags.value = v;
      }
    },
  });

  function ldUser() {
    if (identityStore.identity) {
      return {
        kind: 'user',
        key: identityStore.identity.id,
        email: identityStore.identity.email,
        name: `${identityStore.identity.first_name} ${identityStore.identity.last_name}`,
        brand: identityStore.currentBrand?.label,
        organization: identityStore.identity.organization?.label,
      };
    }
    return {
      kind: 'user',
      key: 'anonymous',
      email: 'test+anonuser@dashhudson.com',
      name: 'Anonymous User',
    };
  }

  function mockFlags(obj = {}) {
    ready.value = true;
    waitForUserFlagsResolve();
    userFlagsReady.value = true;
    flagsMock.value = createFlagsProxy(obj);
    isMocked.value = true;
  }
  function clearFlagsMock() {
    flagsMock.value = null;
    isMocked.value = false;
  }

  function createLdClient() {
    if (!client) {
      clearFlagsMock();

      const context = ldUser();
      const ldOptions = {
        fetchGoals: false,
        bootstrap: 'localStorage',
        inspectors: [
          {
            type: 'flag-used',
            name: 'dd-inspector',
            method: (key, detail) => {
              datadogRum.addFeatureFlagEvaluation(key, detail.value);
            },
          },
        ],
      };

      if (env.launchDarklyRelay) {
        ldOptions.streamUrl = env.launchDarklyRelay;
        ldOptions.baseUrl = env.launchDarklyRelay;
        ldOptions.eventsUrl = env.launchDarklyRelay;
      }

      client = LDClient.initialize(clientSideId, context, ldOptions);
      client.on('ready', () => {
        internalFlags.value = createVariationFlagsProxy();
        ready.value = true;
      });
      client.on('change', () => {
        internalFlags.value = createVariationFlagsProxy();
      });
      client.on('error', (e) => {
        clientError.value = e;
        waitForUserFlagsReject();
      });
    }
  }

  function updateLdIdentity() {
    if (client) {
      userFlagsReady.value = false;
      client.identify(ldUser(), null, () => {
        const ctx = client.getContext();
        if (ctx?.kind === 'user' && ctx?.key !== 'anonymous') {
          userFlagsReady.value = true;
          waitForUserFlagsResolve();
        }
      });
    }
  }

  watch(
    () => identityStore.identity,
    () => {
      createLdClient();
    },
    {
      immediate: true,
    },
  );

  watch(
    () => identityStore.currentBrand,
    () => {
      updateLdIdentity();
    },
  );

  function updateFlags({ ready: incomingReady = false, flags: incomingFlags = {} } = {}) {
    ready.value = incomingReady;
    if (incomingReady) {
      initialized.value = true;
      internalFlags.value = incomingFlags;
    }
  }

  return {
    initialized,
    ready,
    userFlagsReady,
    flags,
    clientError,
    updateFlags,
    waitForUserFlags,
    mockFlags,
    isMocked,
    clearFlagsMock,
  };
});
