<!-- eslint-disable vue/one-component-per-file -->
<template>
  <Popup :close="closePopup" type="large" data-cy="shoppable-gallery-settings-popup">
    <main>
      <header class="shoppable-setting-header">
        <div class="title">
          <h1>Shoppable Gallery Settings</h1>
          <span
            :class="[isShoppableGalleryEnabled ? 'enabled' : 'disabled']"
            data-cy="shoppable-gallery-status"
          >
            <template v-if="isShoppableGalleryEnabled">Enabled</template>
            <template v-else>Disabled</template>
          </span>
        </div>
        <div class="toggle-container">
          <ToggleSwitch
            ref="toggle"
            v-model="shoppableGalleryToggle"
            toggle-label="Enable Shoppable Gallery"
            @input="shoppableGalleryEnabledToggled"
          />
        </div>
      </header>

      <!-- TODO - Add this back in when the URL page is ready -->
      <PillTab
        ref="pillTabs"
        :tabs="pillTabs"
        class="pill-tab-nav"
        @on-change="pillTabToBeChanged"
      />

      <!-- On-Site Setting -->

      <template v-if="currentPillTab === 'On-Site'">
        <SettingGroup v-model="shoppableSettingsForm" tab="On-Site" />
        <CodeSnippet :code="script" @copied="sendInteractionMixpanelEvent('Code Snippet')">
          <template #title>
            <h5>Copy and paste this code into your page</h5>
          </template>
          <template #footnote>
            <p>
              *Note: The above code snippet should not be inserted into the &lt;head&gt; section of
              your page, but rather into the element on the page you want the shoppable gallery to
              be displayed inside of.
            </p>
          </template>
        </CodeSnippet>

        <Preview
          :disabled="!isShoppableGalleryEnabled"
          :shoppable-settings-form="shoppableSettingsForm"
          :code="script"
          reload
        />
      </template>

      <!-- URL Setting -->
      <template v-else-if="currentPillTab === 'URL'">
        <CopyField
          :value="galleryLikeshopURL"
          :disabled="!isShoppableGalleryEnabled"
          class="url-field"
          @copied="sendInteractionMixpanelEvent('Gallery URL')"
        />

        <SettingGroup v-model="shoppableSettingsForm" tab="URL" />

        <Button
          :disabled="!hasUnsyncedChanges"
          :loading="galleryStore.pending.gallery"
          primary
          icon-name="synchronize"
          icon-color="white"
          icon-hover-color="white"
          class="center-button"
          @click="saveShoppableGallerySetting"
        >
          <span>Apply Changes</span>
        </Button>

        <Preview
          :disabled="!isShoppableGalleryEnabled"
          :shoppable-settings-form="shoppableSettingsForm"
          :preview-link="galleryLikeshopURL"
          :code="script"
          reload
        />
      </template>

      <!-- Email -->
      <template v-else>
        <SettingGroup v-model="shoppableSettingsForm" tab="Email" />
        <CodeSnippet :code="emailHTML" @copied="sendInteractionMixpanelEvent('Code Snippet')">
          <template #title>
            <p>Copy and paste this code into your Email template</p>
          </template>
        </CodeSnippet>
        <Preview
          v-if="!galleryStore.pending.gallery"
          :disabled="!isShoppableGalleryEnabled"
          :shoppable-settings-form="shoppableSettingsForm"
          :code="emailHTML"
          :append-widget="false"
          reload
        />
      </template>
    </main>
  </Popup>
</template>

<script>
import { createVNode, defineComponent, render } from 'vue';
import { mapStores } from 'pinia';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { toolTips } from '@/config';
import { env } from '@/env';
import Popup from '@/components/Popup.vue';
import PillTab from '@/components/PillTab.vue';
import Button from '@/components/foundation/Button.vue';
import CopyField from '@/components/CopyField.vue';
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import SettingGroup from '@/app/library/components/SpiritSettings/SettingGroup.vue';
import Preview from '@/app/library/components/SpiritSettings/Preview.vue';
import CodeSnippet from '@/components/CodeSnippet.vue';
import EmailTemplate from '@/app/library/components/SpiritSettings/EmailTemplate.vue';
import { useNotificationStore } from '@/stores/notification';
import { useGalleryStore } from '@/stores/gallery';
import { defaultShoppableSettings } from '../constants';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ShoppableGallerySettings',
  components: {
    Button,
    CodeSnippet,
    CopyField,
    PillTab,
    Popup,
    Preview,
    SettingGroup,
    ToggleSwitch,
  },
  data() {
    return {
      pillTabs: [
        { name: 'On-Site', label: null },
        { name: 'URL', label: null },
        { name: 'Email', label: null },
      ],
      currentPillTab: 'On-Site',
      shoppableSettingsForm: cloneDeep(defaultShoppableSettings),
      defaultShoppableSettings: cloneDeep(defaultShoppableSettings),
      hasUnsyncedChanges: false,
      tooltips: {
        mobileColumn: toolTips.shoppableSettings.mobileColumn,
        mobileGap: toolTips.shoppableSettings.mobileGap,
        customLinks: toolTips.shoppableSettings.customLinks,
      },
      disableShoppableGallery: {
        title: 'Disable Shoppable Gallery',
        message:
          'Disabling Shoppable gallery will make all widgets invisible to customers. Are you sure you want to disable them?',
      },
      discardConfirmation: {
        title: 'Discard Changes',
        message: 'If you exit now, your unsaved changes will be discarded.',
      },
      shoppableGalleryToggle: null,
    };
  },
  computed: {
    ...mapStores(useNotificationStore, useGalleryStore, useAuthStore, useTrackingStore),
    /* eslint-disable */
    script() {
      const form = this.shoppableSettingsForm;
      const callToActionScript =
        form.callToAction === '-' ? '' : `data-call-to-action="${form.callToAction}" `;
      const dataLinkScript = form.customLinks ? '' : 'data-links-disabled="true" ';
      // Gallery Script
      if (form.viewType === 'gallery') {
        // TODO connect to the right src link and insert real data id / board id
        return `<script src="${env.galleryEmbedUrl}" type="text/javascript" data-name="dhboard" \
data-gallery-id="${this.galleryStore.gallery.id}" data-row-size="${form.column}" data-gap-size="${form.gap}" \
data-row-limit="${form.row}" data-mobile-row-size="${form.mobileColumn}" \
data-mobile-gap-size="${form.mobileGap}" ${callToActionScript} ${dataLinkScript}><\/script>`;
      }
      // Carousel Script
      const showScroll = form.scroll === 'hide' ? 'data-scroll-disabled="true"' : '';
      const autoplay = form.autoplay === 'on' ? 'data-autoplay="true"' : '';

      return `<script src="${env.carouselEmbedUrl}" type="text/javascript" data-name="dhboard-carousel" \
data-gallery-id="${this.galleryStore.gallery.id}" data-row-size="${form.column}" data-gap-size="${form.gap}" \
data-mobile-row-size="${form.mobileColumn}" ${showScroll} ${autoplay} data-mobile-gap-size="${form.mobileGap}" \
${callToActionScript} ${dataLinkScript}><\/script>`;
    },
    /* estlint disabled */
    emailHTML() {
      const { row, column, gap, customLinks } = this.shoppableSettingsForm;
      const imagePathPrefix = `${env.likeShopUrl}board/${this.galleryStore.gallery.id}/newest/thumbs`;
      const imageLinkPrefix = `${env.likeShopUrl}board/${this.galleryStore.gallery.id}/url/newest`;
      const props = {
        rows: Number(row),
        columns: Number(column),
        gaps: Number(gap),
        customLinks,
        imagePathPrefix,
        imageLinkPrefix,
      };

      const vNode = createVNode(EmailTemplate, props);
      const el = document.createElement('div');
      render(vNode, el);
      return el.outerHTML;
    },
    galleryLikeshopURL() {
      return `${env.likeShopUrl}${this.authStore.currentBrand.label}/${this.galleryStore.gallery.label}`;
    },
    isShoppableGalleryEnabled() {
      return Boolean(
        this.galleryStore.gallery?.spiritGalleryEnabled ??
          this.galleryStore.gallery?.spirit_gallery_enabled,
      );
    },
    imagePathPrefix() {
      return `${env.likeShopUrl}board/${this.galleryStore.gallery.id}/newest/thumbs`;
    },
    imageLinkPrefix() {
      return `${env.likeShopUrl}board/${this.galleryStore.gallery.id}/url/newest`;
    },
  },
  watch: {
    shoppableSettingsForm: {
      handler(to) {
        this.checkUnsyncedChanges();
        // if uncheck custom links, reset and disable call to cation field.
        if (!to.customLinks) {
          this.shoppableSettingsForm.callToAction = '-';
        }
      },
      deep: true,
    },
  },
  created() {
    // if shoppable gallery setting is null, set hasUnsyncedChanges to true
    // to enable the Apply Changes button by default
    this.hasUnsyncedChanges = !(
      this.galleryStore.gallery &&
      this.galleryStore.gallery.settings &&
      this.galleryStore.gallery.settings.spiritGallery
    );
  },
  mounted() {
    this.shoppableGalleryToggle = this.isShoppableGalleryEnabled;
    this.trackingStore.track('Spirit Settings Clicked');
  },
  methods: {
    async shoppableGalleryEnabledToggled(newVal) {
      if (!newVal) {
        const { title, message } = this.disableShoppableGallery;
        await this.notificationStore.confirm(title, message, {
          confirmAlias: 'Disable',
          onConfirm: () => this.updateShoppableGalleryEnabledStatus(newVal),
          onCancel: () => {
            this.shoppableGalleryToggle = true;
          },
        });
        return;
      }
      this.updateShoppableGalleryEnabledStatus(newVal);
    },
    async updateShoppableGalleryEnabledStatus(newVal) {
      try {
        await this.galleryStore.updateGallery({
          galleryId: this.galleryStore.gallery.id,
          spiritGalleryEnabled: Number(newVal),
        });
      } catch {
        return;
      }
      this.shoppableGalleryToggle = newVal;
      this.trackingStore.track(`Spirit Gallery ${newVal ? 'Enabled' : 'Disabled'}`, {
        galleryId: this.galleryStore.gallery.id,
      });
    },
    checkUnsyncedChanges() {
      // only URL tab can have unsyced changes
      if (this.currentPillTab === 'URL') {
        if (
          this.galleryStore.gallery &&
          this.galleryStore.gallery.settings &&
          this.galleryStore.gallery.settings.spiritGallery
        ) {
          const targetFields = [
            'column',
            'gap',
            'row',
            'mobileColumn',
            'mobileGap',
            'callToAction',
            'customLinks',
          ];
          const newGallery = pick(this.shoppableSettingsForm, targetFields);
          const oldGallery = pick(this.galleryStore.gallery.settings.spiritGallery, targetFields);
          newGallery.galleryTitle = this.shoppableSettingsForm.galleryTitle;
          oldGallery.galleryTitle = this.galleryStore.gallery.settings.spiritGallery.galleryTitle;
          this.hasUnsyncedChanges = !isEqual(oldGallery, newGallery);
        } else if (this.galleryStore.gallery.settings === null) {
          this.hasUnsyncedChanges = !isEqual(
            this.defaultShoppableSettings,
            this.shoppableSettingsForm,
          );
        } else {
          this.hasUnsyncedChanges = false;
        }
      } else {
        this.hasUnsyncedChanges = false;
      }
    },
    async pillTabToBeChanged(option) {
      this.checkUnsyncedChanges();
      if (this.hasUnsyncedChanges) {
        const { title, message } = this.discardConfirmation;
        await this.notificationStore.confirm(title, message, {
          confirmAlias: 'Discard',
          onConfirm: () => {
            this.updatePillTab(option);
          },
          onCancel: this.handleDismiss,
        });
      } else {
        this.updatePillTab(option);
      }
    },
    updatePillTab(option) {
      this.currentPillTab = this.pillTabs[option].name;
      // load shoppable gallery setting when change tab to 'URL'
      if (this.currentPillTab === 'URL' && this.galleryStore.gallery) {
        if (
          this.galleryStore.gallery.settings &&
          this.galleryStore.gallery.settings.spiritGallery
        ) {
          this.loadShoppableGallerySetting();
        } else if (this.galleryStore.gallery.name) {
          this.shoppableSettingsForm = Object.assign(
            this.shoppableSettingsForm,
            defaultShoppableSettings,
          );
          this.shoppableSettingsForm.galleryTitle = this.galleryStore.gallery.name;
          this.defaultShoppableSettings.galleryTitle = this.galleryStore.gallery.name;
        }
      } else if (this.currentPillTab === 'Email') {
        this.shoppableSettingsForm.viewType = 'gallery';
        if (this.shoppableSettingsForm.row === 'auto') {
          this.shoppableSettingsForm.row = '2';
        }
      }
      this.sendInteractionMixpanelEvent('Tab');
    },
    async closePopup() {
      this.checkUnsyncedChanges();
      if (this.hasUnsyncedChanges) {
        const { title, message } = this.discardConfirmation;
        await this.notificationStore.confirm(title, message, {
          confirmAlias: 'Discard',
          onConfirm: this.galleryStore.closeShoppableSettingsPopup,
          onCancel: this.handleDismiss,
        });
      } else {
        this.galleryStore.closeShoppableSettingsPopup();
      }
    },
    handleDismiss() {
      // ugly work around for tab get switched over in the first place
      // revert tab back to 'URL' to 'dismiss'
      this.$refs.pillTabs.setTabByIndex(1);
    },
    loadShoppableGallerySetting() {
      const { column, gap, row, mobileColumn, mobileGap, customLinks, callToAction } =
        this.galleryStore.gallery.settings.spiritGallery;
      const galleryTitle = this.galleryStore.gallery.settings.spiritGallery.galleryTitle;
      this.shoppableSettingsForm.viewType = 'gallery';
      this.shoppableSettingsForm.row = row;
      this.shoppableSettingsForm.column = column;
      this.shoppableSettingsForm.gap = gap;
      this.shoppableSettingsForm.mobileColumn = mobileColumn;
      this.shoppableSettingsForm.mobileGap = mobileGap;
      this.shoppableSettingsForm.callToAction = callToAction;
      this.shoppableSettingsForm.customLinks = customLinks;
      this.shoppableSettingsForm.galleryTitle = galleryTitle;
      this.defaultShoppableSettings.galleryTitle = galleryTitle;
      this.hasUnsyncedChanges = false;
    },
    saveShoppableGallerySetting() {
      this.galleryStore.updateGallery({
        galleryId: this.galleryStore.gallery.id,
        settings: {
          spiritGallery: {
            column: this.shoppableSettingsForm.column,
            gap: this.shoppableSettingsForm.gap,
            row: this.shoppableSettingsForm.row,
            mobileColumn: this.shoppableSettingsForm.mobileColumn,
            mobileGap: this.shoppableSettingsForm.mobileGap,
            callToAction: this.shoppableSettingsForm.callToAction,
            customLinks: this.shoppableSettingsForm.customLinks,
            galleryTitle: this.shoppableSettingsForm.galleryTitle,
          },
        },
      });
      this.hasUnsyncedChanges = false;
    },
    sendInteractionMixpanelEvent(interactionName) {
      this.trackingStore.track('Shoppable Gallery Settings Interaction', {
        interaction: interactionName,
        currentTab: this.currentPillTab,
        ...this.shoppableSettingsForm,
      });
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
main {
  padding: var(--space-40);
  text-align: center;
}

header {
  padding-bottom: var(--space-40);

  h2 {
    text-align: center;
  }
}

.shoppable-setting-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .toggle-container {
    margin-top: var(--space-16);
  }

  span {
    color: var(--white);
    margin: var(--space-8) var(--space-16) 0;
    font-size: var(--x10);
    border-radius: var(--space-16);
    padding: 0 var(--space-16);
  }

  span.enabled {
    background-color: var(--success-500);
  }

  span.disabled {
    opacity: 1;
    background-color: var(--error-500);
  }

  h2 {
    text-align: left;
  }
}

.pill-tab-nav {
  width: 22rem;
  margin: 0 auto var(--space-40);
}

.url-field {
  width: 50%;
  margin: var(--space-24) auto;
}

.center-button {
  margin: 0 auto;
}

.disable-confirmation {
  p {
    margin: var(--space-24) auto;
  }
}
</style>
