import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.creatorsApiUrl, {
  camelizeKeys: true,
});

export async function searchAccounts(
  {
    brandId,
    sources,
    followersGreaterThan,
    followersLessThan,
    bioIncludes,
    bioDoesNotInclude,
    captionsInclude,
    captionsDoNotInclude,
    bioOrCaptionsInclude,
    hasMentionedBrand,
    hasPostedBetween,
    interests,
    countryCodes,
    visualFeatures,
    audience,
    languages,
    minAvgViews,
    maxAvgViews,
    tiktok,
    sortBy,
    minEngagementRate,
    maxEngagementRate,
    mobileOS,
    limit,
    afterCursor,
  },
  axiosConfig = {},
) {
  const url = `/accounts`;
  const payload = {
    brandId,
    sources,
    followersGreaterThan,
    followersLessThan,
    bioIncludes,
    bioDoesNotInclude,
    captionsInclude,
    captionsDoNotInclude,
    bioOrCaptionsInclude,
    hasMentionedBrand,
    hasPostedBetween,
    interests,
    countryCodes,
    visualFeatures,
    audience,
    languages,
    minAvgViews,
    maxAvgViews,
    tiktok,
    sortBy,
    limit,
    minEngagementRate,
    maxEngagementRate,
    mobileOs: mobileOS,
    afterCursor,
  };
  return axios.put(url, payload, { ...axiosConfig });
}

export async function searchAccountsForTeaser(
  {
    brandId,
    sources,
    followersGreaterThan,
    followersLessThan,
    bioIncludes,
    bioDoesNotInclude,
    captionsInclude,
    captionsDoNotInclude,
    bioOrCaptionsInclude,
    hasMentionedBrand,
    hasPostedBetween,
    predictedInterests,
    predictedLocations,
    visualFeatures,
    sortBy,
  },
  axiosConfig = {},
) {
  const url = `teaser/accounts`;
  const payload = {
    brandId,
    sources,
    followersGreaterThan,
    followersLessThan,
    bioIncludes,
    bioDoesNotInclude,
    captionsInclude,
    captionsDoNotInclude,
    bioOrCaptionsInclude,
    hasMentionedBrand,
    hasPostedBetween,
    predictedInterests,
    estimatedRegions: predictedLocations,
    visualFeatures,
    sortBy,
  };
  return axios.put(url, payload, { ...axiosConfig });
}

export async function fetchAccount({ source, sourceCreatorId, handle, brandId }, axiosConfig = {}) {
  return axios.get(`/accounts/${source}`, {
    ...axiosConfig,
    params: { brandId, sourceCreatorId, handle },
  });
}

export async function fetchBrandCreators({
  url,
  brandId,
  creatorIds,
  postsPublishedStartDate,
  postsPublishedEndDate,
  instagramPostType,
  afterCursor,
}) {
  return axios.put(url ?? `/brands/${brandId}/relationships`, {
    creatorIds,
    postsPublishedStartDate,
    postsPublishedEndDate,
    postType: instagramPostType,
    afterCursor,
  });
}

export async function updateCreatorDetails({ url, brandId, creatorId, creatorDetails }) {
  return axios.put(url ?? `/brands/${brandId}/relationships/${creatorId}/details`, creatorDetails);
}

export async function fetchBrandRelationshipTags({ url, brandId }) {
  return axios.get(url ?? `/brands/${brandId}/relationships/tags`);
}

export async function createInformationRequest({ brandId, creatorId }) {
  return axios.put(`/brands/${brandId}/relationships/${creatorId}/information_requests`);
}

export async function fetchInformationRequest({ token }) {
  return axios.get(`/information_requests?token=${token}`);
}

export async function submitInformationRequest({ token, creatorData }, axiosConfig = {}) {
  const payload = { token, ...creatorData };
  return axios.put(`/information_requests`, payload, { ...axiosConfig });
}

export async function bulkDeleteRelationships({ brandId, creatorIds }, axiosConfig = {}) {
  return axios.post(
    `/brands/${brandId}/relationships/bulk_delete`,
    { creatorIds },
    { ...axiosConfig },
  );
}

export async function addRelationshipTag({ brandId, creatorId, name, color }, axiosConfig = {}) {
  return axios.post(
    `/brands/${brandId}/relationships/${creatorId}/tags`,
    { name, color },
    { ...axiosConfig },
  );
}

export async function bulkAddRelationshipTags({ brandId, creatorIds, tags }, axiosConfig = {}) {
  return axios.post(
    `/brands/${brandId}/relationships/tags`,
    { creatorIds, tags },
    { ...axiosConfig },
  );
}

export async function deleteRelationshipTag({ brandId, creatorId, tagId }, axiosConfig = {}) {
  return axios.delete(`/brands/${brandId}/relationships/${creatorId}/tags/${tagId}`, {
    ...axiosConfig,
  });
}

export async function updateInvitationStatus({
  source,
  sourceCreatorId,
  invitedByBrandId,
  invitationStatus,
  emailContent,
}) {
  const params = {
    source,
    invited_by_brand_id: invitedByBrandId,
    source_creator_id: sourceCreatorId,
    invitation_status: invitationStatus,
    email_content: emailContent,
  };

  return axios.put(`/accounts/invitations`, params);
}

export async function searchCreators(
  {
    brandId,
    limit,
    sort,
    lookupName,
    restrictToPlatforms,
    postsPublishedStartDate,
    postsPublishedEndDate,
    tagFilterType,
    tags,
    hasMentionedBrand,
    instagramPostType,
    instagramConnectionStatus,
    instagramFollowersGreaterThan,
    instagramFollowersLessThan,
    instagramInterests,
    instagramRegions,
    instagramIsBusiness,
    tiktokConnectionStatus,
    tiktokFollowersGreaterThan,
    tiktokFollowersLessThan,
    tiktokTTCMStatus,
    tiktokInterests,
    tiktokRegions,
    tiktokAudienceRegions,
    tiktokAudienceGender,
    tiktokAudienceAge,
    afterCursor,
  },
  axiosConfig = {},
) {
  return axios.put(
    `/brands/${brandId}/relationships`,
    {
      limit,
      sort,
      lookupName,
      restrictToPlatforms,
      postsPublishedStartDate,
      postsPublishedEndDate,
      tagFilterType,
      tags,
      hasMentionedBrand,
      postType: instagramPostType,
      accounts: {
        instagram: {
          status: instagramConnectionStatus,
          followersGreaterThan: instagramFollowersGreaterThan,
          followersLessThan: instagramFollowersLessThan,
          interests: instagramInterests,
          regions: instagramRegions,
          isBusiness: instagramIsBusiness,
        },
        tiktok: {
          status: tiktokConnectionStatus,
          followersGreaterThan: tiktokFollowersGreaterThan,
          followersLessThan: tiktokFollowersLessThan,
          ttcmStatus: tiktokTTCMStatus,
          interests: tiktokInterests,
          regions: tiktokRegions,
          audience: {
            regions: tiktokAudienceRegions,
            gender: tiktokAudienceGender,
            age: tiktokAudienceAge,
          },
        },
      },
      afterCursor,
    },
    { ...axiosConfig },
  );
}

export async function findOrCreateCreators({ creators }, axiosConfig = {}) {
  return axios.post('/creators', creators, { ...axiosConfig });
}

export async function createRelationships({ url, brandId, creatorIds }) {
  return axios.post(url ?? `/brands/${brandId}/relationships`, {
    creatorIds,
  });
}

export async function fetchCreatorsCount({ brandId }) {
  return axios.put(`/brands/${brandId}/relationships`);
}

export async function fetchAccountPosts(
  { brandId, source, handle, cache, limit, afterCursor },
  axiosConfig = {},
) {
  return axios.get('/posts', {
    ...axiosConfig,
    params: { brandId, source, handle, cache, limit, afterCursor },
  });
}

export async function fetchAccountMetrics({ source, handle, brandId }, axiosConfig = {}) {
  return axios.get(`/accounts/${source}/metrics`, {
    ...axiosConfig,
    params: { brandId, handle },
  });
}

export async function fetchSimilarCreators(
  { brandId, source, handle, sourceCreatorId },
  axiosConfig = {},
) {
  return axios.get(`/accounts/${source}/similar`, {
    ...axiosConfig,
    params: { brandId, handle, sourceCreatorId },
  });
}
