import { ref } from 'vue';
import { defineStore } from 'pinia';
import * as LibraryAPI from '@/apis/library';
import { useAuthStore } from '@/stores/auth';

export const useGalleryTagStore = defineStore(
  'galleryTag',
  () => {
    const authStore = useAuthStore();

    const fullGalleryTagList = ref([]);
    const galleryTagFilters = ref(null);
    const galleryTagFilterType = ref(null);

    const pending = ref({
      fullGalleryTagList: false,
      removeTagsStatus: false,
    });

    async function getFullGalleryTagList({ activeOnly, galleryType }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.fullGalleryTagList = true;
      try {
        const response = await LibraryAPI.getFullGalleryTagList({
          brandId,
          activeOnly,
          galleryType,
        });
        fullGalleryTagList.value = response.data;
      } finally {
        pending.value.fullGalleryTagList = false;
      }
    }

    async function deleteGalleryTag({ tagId }) {
      const brandId = authStore.currentBrand?.id;

      const response = await LibraryAPI.deleteGalleryTag({ brandId, tagId });
      const payload = response?.data;

      return payload;
    }

    async function removeTagsFromGallery({ galleryId, tags }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.removeTagsStatus = true;
      try {
        const response = await LibraryAPI.removeTagsFromGallery({ brandId, galleryId, tags });
        const payload = response?.data;

        return payload;
      } finally {
        pending.value.removeTagsStatus = false;
      }
    }

    function setGalleryTagFilters(tags) {
      galleryTagFilters.value = tags;
    }

    function clearGalleryTagFilters() {
      galleryTagFilters.value = null;
    }

    function setGalleryTagFilterType(type) {
      galleryTagFilterType.value = type;
    }

    return {
      pending,
      fullGalleryTagList,
      galleryTagFilters,
      galleryTagFilterType,
      deleteGalleryTag,
      removeTagsFromGallery,
      getFullGalleryTagList,
      setGalleryTagFilters,
      clearGalleryTagFilters,
      setGalleryTagFilterType,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
