/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'peopleUserGrey': {
    width: 10,
    height: 9,
    viewBox: '0 0 10 9',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.084 2.083A2.086 2.086 0 015 4.167a2.086 2.086 0 01-2.083-2.084C2.917.935 3.851 0 5 0c1.15 0 2.084.935 2.084 2.083zm-6.25 5.73c0-1.84 1.714-3.125 4.167-3.125s4.166 1.284 4.166 3.125v.52H.834v-.52z" _fill="#fff"/>'
  }
})
