<script setup>
import ToggleSwitch from '@/components/ToggleSwitch.vue';
import { useFlagStore } from '@/stores/flag';
import { computed } from 'vue';
import { draftToggleDisabledToolTip } from '@/app/scheduler/constants';

const props = defineProps({
  post: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: true,
  },
  isOn: {
    type: Boolean,
    default: true,
  },
});

const flagStore = useFlagStore();
const emit = defineEmits(['draftsToggled']);

const hasDraftsFeatureFlag = computed(() => flagStore.ready && flagStore.flags.schedulerDrafts);
</script>

<template>
  <div class="mb-4 flex justify-between">
    <p>Schedule</p>
    <ToggleSwitch
      v-if="hasDraftsFeatureFlag"
      :disabled="props.disabled"
      :value="props.isOn"
      :tooltip="props.disabled ? draftToggleDisabledToolTip : null"
      toggle-label="Draft"
      data-cy="post-editor-draft-toggle"
      @input="(draft) => emit('draftsToggled', draft)"
    />
  </div>
</template>
