<script setup>
import { computed } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';
import { mediaBadgeEnumsByPlatform } from '@/app/library/constants';

const iconColor = colours.BASIC.WHITE;

const props = defineProps({
  sourceType: { type: String, default: null },
  mediaType: { type: String, required: true },
  carousel: { type: Boolean, default: false },
});

const badgeMediaType = computed(() => {
  if (props.carousel) {
    return 'MULTI_IMAGE';
  }
  return props.mediaType;
});

const mediaBadgeProp = computed(() => {
  if (!props.sourceType) return [];

  return mediaBadgeEnumsByPlatform[props.sourceType]
    .filter((mediaBadge) => badgeMediaType.value === mediaBadge.mediaType)
    .map((badge) => {
      return {
        ...badge,
        showIcon: true,
      };
    });
});

defineExpose({
  mediaBadgeProp,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div>
    <div v-for="badge in mediaBadgeProp" :key="badge.iconName">
      <Icon
        v-if="badge.showIcon"
        v-tooltip="badge.tooltip"
        :name="badge.iconName"
        :color="iconColor"
        xlarge
      />
    </div>
  </div>
</template>
