<script setup>
import { computed, ref, onBeforeUnmount, onBeforeMount } from 'vue';
import ContentBlock from '@/components/common/ContentBlock.vue';
import UserList from '@/components/core/UserList.vue';
import { useAuthStore } from '@/stores/auth';
import { useChiliPiperStore } from '@/stores/chili-piper';

const emit = defineEmits(['contact-clicked']);

const authStore = useAuthStore();
const chiliPiperStore = useChiliPiperStore();

const props = defineProps({
  chiliPiperData: {
    type: Object,
    default: null,
  },
});

const adminUsers = ref([]);

const allFetched = ref(false);
let currentLimit = 0;
const infoContent = {
  content:
    'Admin users have full permissions for enabled Dash Hudson features and can manage permissions for specific users.',
  icon: 'user-shield',
  style: 'default',
  title: 'Admin',
};
const apiParams = computed(() => {
  return {
    limit: 20,
    clear: false,
    brandId: authStore.currentBrand?.id,
  };
});
const subheader = computed(() => {
  const text =
    'In order to manage plans the following permission is required. Ask one of the ' +
    'following teammates to grant you access.';
  if (props.chiliPiperData) {
    return text.replace(/.$/, ' or ');
  }
  return text;
});

onBeforeMount(() => {
  authStore.clearBrandUsers();
});

onBeforeUnmount(() => {
  authStore.clearBrandUsers();
});

async function getAdminUsers() {
  if (allFetched.value) {
    return;
  }
  await authStore.getPaginatedBrandUsers({ ...apiParams.value });
  authStore.brandUsers.slice(currentLimit).every((brandUser) => {
    if (brandUser.role === 'admin' && brandUser.invitationStatus === 'completed') {
      adminUsers.value.push(brandUser);
      return true;
    }
    allFetched.value = true;
    return false;
  });
  currentLimit += apiParams.value.limit;
}

function contactClicked() {
  chiliPiperStore.submit(props.chiliPiperData.router, props.chiliPiperData.name, 'contact us');
  emit('contact-clicked');
}

defineExpose({
  getAdminUsers,
  adminUsers,
  allFetched,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div>
    <div class="flex flex-col items-center justify-center">
      <h4>Admin Permissions Required</h4>
      <p class="subheader my-8 text-center text-sm">
        {{ subheader }}
        <a v-if="chiliPiperData" class="link" @click.prevent="contactClicked">contact us.</a>
      </p>
    </div>
    <ContentBlock
      :appearance="infoContent.style"
      :content="infoContent.content"
      :icon="infoContent.icon"
      :title="infoContent.title"
      class="mb-8"
    ></ContentBlock>
    <p class="pb-2 text-xs">Admins</p>
    <div class="max-h-72 overflow-y-auto border-t border-solid border-[#DDDDDD]">
      <UserList
        :brand-users="adminUsers"
        :on-infinite="getAdminUsers"
        :loading="authStore.pending.brandUsers"
        :display-time-zone="false"
        :display-user-avatar="false"
      />
    </div>
  </div>
</template>
