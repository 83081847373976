import { defineStore } from 'pinia';
import { camelize, camelizeKeys } from 'humps';
import * as CommunityApi from '@/apis/community';
import { LibraryAPI } from '@/apis';
import { findRepliesToComment, searchCommentListById } from '@/app/community/utils/comments';
import {
  COMMUNITY_INTERACTION_ACTIONS,
  COMMUNITY_INTERACTION_TYPES,
  TWITTER_TWEET_TYPES,
} from '@/app/community/constants';
import { useCommunityInteractionStore } from '@/stores/community-interactions';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { getToastForInteractionType } from '@/app/community/utils/community-interactions';
import { useCommunityInteractionRulesStore } from '@/stores/community-interaction-rules';
import { useCommunityTranslationStore } from '@/stores/community-translation';
import { ruleExecutedMessageFormatter } from '@/app/community/utils/interaction-rules';
import unionBy from 'lodash/unionBy';
import { useSocketStore } from './socket';

const COMMENT_DETAILS_API = {
  facebook: CommunityApi.getFacebookComment,
  instagram: CommunityApi.getInstagramComment,
  tiktok: CommunityApi.getTikTokComment,
  twitter: CommunityApi.getTwitterComment,
  youtube: CommunityApi.getYoutubeComment,
};

const COMMENTS_CSV_API = {
  facebook: CommunityApi.getFacebookCommentsCsv,
  instagram: CommunityApi.getInstagramCommentsCsv,
  tiktok: CommunityApi.getTikTokCommentsCsv,
  twitter: CommunityApi.getTwitterCommentsCsv,
  youtube: CommunityApi.getYoutubeCommentsCsv,
};

export const useCommunityCommentsStore = defineStore('communityComments', {
  resetOnBrandChange: true,
  state: () => ({
    pending: {
      commentPopupDetail: false,
      commentList: false,
    },
    commentDetail: null,
    commentList: [],
    commentListNextPage: null,
    commentMedia: null,
    commentPopupDetail: null,
  }),
  actions: {
    async detectCommentListLanguages() {
      const translationStore = useCommunityTranslationStore();
      const commentList = this.commentList;
      if (commentList.length > 0) {
        await translationStore.detectLanguages(commentList);
      }
    },
    async getCommentList({ next, brandId, mediaId, limit, platform }) {
      this.pending.commentList = true;
      if (!next) {
        this.commentList = [];
        this.commentListNextPage = null;
      }
      const res = await CommunityApi.default.getCommentList({
        next,
        brandId,
        mediaId,
        limit,
        platform,
      });
      this.commentList = [...this.commentList, ...res.data.data];
      this.commentListNextPage = res.data.paging.next || res.data.paging.after;
      this.pending.commentList = false;
      return res;
    },
    clearCommentList() {
      this.commentList = [];
    },

    async getCommentDetail({ id, returnParent, platform, refresh, subsequentCall }) {
      if (refresh) {
        this.commentDetail = null;
        this.commentDetailTranslations = {};
      }
      const res = await CommunityApi.default.getCommentDetail(id, returnParent, platform);
      if (
        subsequentCall &&
        this.commentDetail.type === COMMUNITY_INTERACTION_TYPES.TWITTER_RETWEET
      ) {
        this.commentDetail.replies = res.data.replies;
      }
      this.commentDetail = res.data;
      return res;
    },
    updateCommentDetailReplies({ reply, add = true }) {
      if (add) this.commentDetail.replies.push(reply);
      else this.commentDetail.replies = this.commentDetail.replies.filter((r) => r.id !== reply.id);
    },
    replaceCommentDetailReply({ replyId, newReply }) {
      const index = this.commentDetail?.replies.findIndex((c) => c.id === replyId) || -1;
      if (index >= 0) {
        this.commentDetail.replies[index] = newReply;
      }
    },
    removeCommentFromStore({ platform, id, parentCommentId, replyToRemove, mediaId }) {
      let removedComments = 0;
      if (this.commentList.length > 0) {
        // Find comment to remove
        const toRemove = this.commentList.findIndex(
          (comment) => comment[`${platform}CommentId`] === parentCommentId,
        );
        if (replyToRemove) {
          // Find a specific reply to remove
          const reply = this.commentList[toRemove].replies.find((r) => r.id === replyToRemove.id);
          if (!reply?.isOwned) {
            removedComments = 1;
          }
          this.commentList[toRemove].replies = this.commentList[toRemove].replies.filter(
            (r) => r.id !== replyToRemove.id,
          );
        } else {
          removedComments = this.commentList[toRemove].replies.reduce(
            (count, r) => count + (r.isOwned ? 0 : 1),
            0,
          );
          removedComments += 1;
          this.commentList.splice(toRemove, 1);
        }
      }

      if (this.commentDetail?.mediaId === mediaId) {
        if (
          replyToRemove &&
          (replyToRemove.parentId ?? replyToRemove[`${platform}ParentCommentId`]) ===
            this.commentDetail[`${platform}CommentId`]
        ) {
          const reply = this.commentDetail.replies.find((r) => r.id === replyToRemove.id);
          if (!reply?.isOwned) {
            removedComments = 1;
          }
          this.commentDetail.replies = this.commentDetail.replies.filter(
            (r) => r.id !== replyToRemove.id,
          );
        }
      }

      this.commentDetail.commentCount -= removedComments;

      const communityInteractionStore = useCommunityInteractionStore();
      const interactionIdsToRemove = replyToRemove
        ? []
        : findRepliesToComment(
            platform,
            parentCommentId,
            communityInteractionStore.filteredCommunityInteractions,
          );
      communityInteractionStore.removeCommunityInteractionsFromStore({
        interactionIds: [...interactionIdsToRemove, id],
      });
    },
    async deleteComment({ commentId, platform, softDelete }) {
      return CommunityApi.default.deleteComment(commentId, platform, softDelete);
    },
    async updateComment({ params, data }) {
      const { commentId, platform } = params;
      this.updateCommentStore({ commentId, payload: data });
      try {
        return await CommunityApi.default.updateCommentRequest({
          commentId,
          platform,
          payload: data,
        });
      } catch (error) {
        this.updateCommentStore({ commentId, payload: data, undo: true });
        throw Error(error);
      }
    },
    async updateCommentRequest({ params, payload }) {
      const { commentId, platform } = params;
      return CommunityApi.default.updateCommentRequest({ commentId, platform, payload });
    },
    async createComment({
      platform,
      data,
      interaction,
      updateCommentDetailReplies = true,
      isTranslated = false,
    }) {
      const notificationStore = useNotificationStore();
      const res = await CommunityApi.default.createComment(platform, data);
      let payload = { reply: { ...camelizeKeys(res.data) } };
      if (TWITTER_TWEET_TYPES.includes(interaction.type))
        payload = { reply: { ...camelizeKeys(res.data.data), deletedAt: null } };
      const communityInteractionStore = useCommunityInteractionStore();
      // update reply only when interaction is selected currently
      if (
        updateCommentDetailReplies &&
        communityInteractionStore.selectedCommunityInteractionId === interaction.id
      ) {
        this.updateCommentDetailReplies(payload);
        if (isTranslated) {
          const translationStore = useCommunityTranslationStore();
          translationStore.updateTranslationMap(payload.reply.id);
        }
      }
      notificationStore.setToast({
        message: getToastForInteractionType(interaction.type, COMMUNITY_INTERACTION_ACTIONS.POSTED),
      });

      return res;
    },
    updateCommentStore({ commentId, payload, undo = false }) {
      let comment;
      if (this.commentDetail) {
        comment =
          this.commentDetail.id === commentId
            ? this.commentDetail
            : this.commentDetail.replies.find((r) => r.id === commentId);
        if (comment) {
          Object.keys(payload).forEach((key) => {
            const camelizedKey = camelize(key);
            comment[camelizedKey] = undo ? !payload[key] : payload[key];
          });
        }
      }
      if (this.commentList.length > 0) {
        comment = searchCommentListById(this.commentList, commentId);
        if (comment) {
          Object.keys(payload).forEach((key) => {
            const camelizedKey = camelize(key);
            comment[camelizedKey] = undo ? !payload[key] : payload[key];
          });
        }
      }
    },

    async updateTikTokComment({ commentId, payload }) {
      this._updateTikTokCommentStore(commentId, payload);
      try {
        const res = await CommunityApi.default.updateCommentRequest({
          commentId,
          platform: 'tiktok',
          payload,
        });
        return res;
      } catch (error) {
        this._updateTikTokCommentStore(commentId, payload, true);
        throw Error(error);
      }
    },
    _updateTikTokCommentStore(commentId, update, undo = false) {
      let comment;
      comment =
        this.commentDetail.id === commentId
          ? this.commentDetail
          : this.commentDetail.replies.find((r) => r.id === commentId);
      if (comment) {
        Object.keys(update).forEach((key) => {
          const camelizedKey = camelize(key);
          comment[camelizedKey] = undo ? !update[key] : update[key];
        });
      }

      comment = searchCommentListById(this.commentList, commentId);
      if (comment) {
        Object.keys(update).forEach((key) => {
          const camelizedKey = camelize(key);
          comment[camelizedKey] = undo ? !update[key] : update[key];
        });
      }
    },
    async setCommentMedia({ mediaId, commentMedia }) {
      if (mediaId) {
        const authStore = useAuthStore();
        const brandId = authStore.currentBrand?.id;

        const response = await LibraryAPI.getInteractionMediaById(
          { brandId, mediaId },
          {},
          { useCache: true },
        );
        const payload = response?.data;

        this.commentMedia = payload;
      } else {
        this.commentMedia = commentMedia;
      }
    },
    async getCommentPopupDetail({ platform, id, refresh = true }) {
      if (refresh) {
        this.commentPopupDetail = null;
      }

      this.pending.commentPopupDetail = true;
      try {
        const response = await COMMENT_DETAILS_API[platform]({ id });
        const payload = response?.data;

        this.commentPopupDetail = payload;

        return payload;
      } finally {
        this.pending.commentPopupDetail = false;
      }
    },
    async getCommentsCSV({ platform, mediaId }) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand?.id;

      const socketStore = useSocketStore();
      const socketId = socketStore.id;

      this.commentPopupDetail = null;

      const response = await COMMENTS_CSV_API[platform]({
        socketId,
        mediaId,
        brandId,
      });
      const payload = response?.data;

      return payload;
    },
    setCommentPopupDetail(comment) {
      this.commentPopupDetail = comment;
    },
    deleteRuleExecutedMessage(message) {
      this.commentDetail.triggeredMessageRules = this.commentDetail.triggeredMessageRules.filter(
        (t) => t.id !== message.id,
      );
    },
    addRuleExecutionRecord(webhookRuleExecutionRecords, interaction) {
      if (this.commentDetail && this.commentDetail.id === interaction.id) {
        this.commentDetail.triggeredMessageRules = unionBy(
          this.commentDetail.triggeredMessageRules,
          webhookRuleExecutionRecords,
          'id',
        );
      }
    },
  },
  getters: {
    ruleExecutedInternalMessages() {
      if (this.commentDetail?.triggeredMessageRules) {
        const interactionRulesStore = useCommunityInteractionRulesStore();
        return ruleExecutedMessageFormatter(
          this.commentDetail.triggeredMessageRules,
          interactionRulesStore.checkRuleDeleted,
          interactionRulesStore.undoAction,
          this.deleteRuleExecutedMessage,
          interactionRulesStore.openRuleBuilderToEdit,
        );
      }
      return [];
    },
  },
});
