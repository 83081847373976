<script setup>
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useFilterSidePanel } from '@dashhudson/dashing-ui';
import { useFlagStore } from '@/stores/flag';
import { useSecondaryNavigationPanel } from '@/composables/layout/useSecondaryNavigationPanel';
import PrimaryNavigation from '@/components/layout/navigation/PrimaryNavigation.vue';
import TrialBanner from '@/components/InAppTrials/TrialBanner.vue';
import FilterSidePanelProvider from '@/components/dashingUI/FilterSidePanelProvider.vue';
import DrawerProvider from '@/components/dashingUI/DrawerProvider.vue';

const route = useRoute();

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  showNav: {
    type: Boolean,
    default: true,
  },
});

const flagStore = useFlagStore();

const { visible: filterVisible } = useFilterSidePanel();
const { hidden, expanded } = useSecondaryNavigationPanel();

const filterSidePanelTransitionClasses = ref('-z-10 hidden');
const contentAreaTransitionClasses = ref('');

const secondaryNavHidden = computed(() => {
  return hidden.value;
});
const secondaryNavExpanded = computed(() => {
  return expanded.value && !route?.meta?.hideSecondaryNav;
});

const useReportLayout = computed(() => {
  return route?.meta?.report;
});

const hidePrimaryNav = computed(() => {
  return route?.meta?.hidePrimaryNav;
});

const backgroundClass = computed(() => {
  return [
    'before:fixed',
    'before:-z-10',
    'before:bottom-0',
    'before:right-0',
    'before:top-0',
    'before:left-[var(--primary-navigation-width)]',
    'before:h-screen',
    'before:w-[calc(100%-var(--primary-navigation-width))]',
    'before:rounded-tl-[2.5rem]',
    'before:transition-[all_0.3s_cubic-bezier(0.35,0,0.3,1.1)]',
    {
      'before:bg-[#f9fbff]': !useReportLayout.value,
      "before:content-['']": !hidePrimaryNav.value,
    },
  ];
});

const secondaryNavigationVisible = computed(() => {
  return !secondaryNavHidden.value && !useReportLayout.value && secondaryNavExpanded.value;
});

function handleContentTransitionStart($event) {
  const { propertyName, target } = $event;
  if (propertyName === 'transform' && target.id === 'mainContentArea') {
    filterSidePanelTransitionClasses.value =
      '-z-10 block main-content-open before:top-0 before:bottom-0 before:absolute before:h-[9999px] before:content-[""] before:w-[100px] before:z-40 before:left-[-100px]';
    contentAreaTransitionClasses.value = 'z-10 dash-bg-background-page';
  }
}

function handleContentTransitionEnd($event) {
  const { propertyName, target } = $event;
  if (propertyName === 'transform' && target.id === 'mainContentArea') {
    if (filterVisible.value) {
      filterSidePanelTransitionClasses.value =
        'block main-content-open before:top-0 before:bottom-0 before:absolute before:h-[9999px] before:content-[""] before:w-[100px] before:z-40 before:left-[-100px]';
      contentAreaTransitionClasses.value = 'z-10 dash-bg-background-page';
    } else {
      filterSidePanelTransitionClasses.value = '-z-10 hidden';
      contentAreaTransitionClasses.value = '';
    }
  }
}

const primaryNavigationTranslateX = computed(() => {
  if (filterVisible.value) {
    if (secondaryNavigationVisible.value) {
      return '-translate-x-[var(--combined-navigation-width)]';
    }
    return '-translate-x-[var(--primary-navigation-width)]';
  }
  return '';
});

const mainContentAreaTranslateX = computed(() => {
  if (filterVisible.value) {
    return '-translate-x-[var(--combined-navigation-width)]';
  }
  return '';
});

const mainContentPadding = computed(() => {
  if (useReportLayout.value || hidePrimaryNav.value) {
    return 'pl-0';
  }
  if (filterVisible.value) {
    return 'pl-[var(--combined-navigation-width)]';
  }
  if (secondaryNavigationVisible.value && !filterVisible.value) {
    return 'pl-[var(--primary-navigation-width)] xl:pl-[var(--combined-navigation-width)]';
  }
  return 'pl-[var(--primary-navigation-width)]';
});

const innerPadding = computed(() => {
  if (!useReportLayout.value && !secondaryNavHidden.value && !hidePrimaryNav.value) {
    return 'px-10';
  }
  return null;
});
</script>

<template>
  <div class="relative" :class="backgroundClass" data-test="mainContentAreaWrapper">
    <PrimaryNavigation
      v-if="props.showNav"
      :hide-toggle-button="filterVisible"
      class="dash-transition-md"
      :class="primaryNavigationTranslateX"
    />
    <section
      id="mainContentArea"
      class="main-content-transition relative min-h-screen w-full rounded-tl-[2.5rem]"
      :class="[
        filterVisible ? 'main-content-transition-open ' : 'main-content-transition-close',
        contentAreaTransitionClasses,
        mainContentAreaTranslateX,
        mainContentPadding,
      ]"
      @transitionstart="handleContentTransitionStart"
      @transitionend="handleContentTransitionEnd"
    >
      <div :class="['dash-pb-20', innerPadding]">
        <slot />
      </div>
    </section>
    <template v-if="flagStore.flags.saveJackie || flagStore.flags.gdiHeadersAndFilters">
      <FilterSidePanelProvider :class="filterSidePanelTransitionClasses" />
    </template>
    <DrawerProvider
      :class="[
        filterVisible
          ? 'main-content-transition-open dash-elevation-2xl'
          : 'main-content-transition-close',
        mainContentAreaTranslateX,
      ]"
      @transitionstart="handleContentTransitionStart"
      @transitionend="handleContentTransitionEnd"
    />
    <TrialBanner />
  </div>
</template>

<style lang="postcss" scoped>
.main-content-transition-open {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.35, 0, 0.3, 1.1);
  transition-property: transform, padding-left;
}

.main-content-transition-close {
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0, 0.35, 1.1, 0.3);
  transition-property: transform, padding-left;
}

.hs-web-interactives-top-banner-open [data-test='mainContentAreaWrapper']::before {
  top: var(--hubspot-banner-height) !important;
}

.main-content-open::before {
  box-shadow:
    0 6px 12px 0 rgb(45 69 104 / 4%),
    0 12px 24px 0 rgb(45 69 104 / 15%),
    0 24px 32px 0 rgb(45 69 104 / 15%);
}
</style>
