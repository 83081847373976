<template>
  <div class="connection">
    <div v-if="showTopBar" class="top-bar">
      <img alt="Dash Hudson" :src="IMG.logoDhDark" />
    </div>
    <div class="content">
      <slot name="connectionImage" />
      <slot name="connectionTitle" />
      <ConnectButton
        v-if="showConnectButton"
        :platform="platform"
        :disable-on-missing-handle="disableOnMissingHandle"
        :invited-by-brand-id="invitedByBrandId"
        :bypass-connection-check="bypassConnectionCheck"
        from-external-link
      />
      <DHTerms class="privacy-compliance" />
      <slot name="connectionFooter" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ConnectButton from '@/components/ConnectButton.vue';
import DHTerms from '@/components/DHTerms.vue';
import { IMG } from '@/assets';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ConnectionPage',
  components: { DHTerms, ConnectButton },
  props: {
    platform: { type: String, required: true },
    invitedByBrandId: { type: Number, default: null },
    showTopBar: { type: Boolean, default: true },
    showConnectButton: { type: Boolean, default: true },
    disableOnMissingHandle: { type: Boolean, default: true },
    bypassConnectionCheck: { type: Boolean, default: false },
  },
  computed: {
    IMG() {
      return IMG;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.connection {
  background: var(--background-300);
  width: 100%;
  position: absolute;
  text-align: center;

  .top-bar {
    padding-top: var(--space-24);
    padding-bottom: var(--space-24);
    background: var(--background-0);
    display: flex;
    justify-content: center;
    box-shadow: var(--shadow-3);
    border: 0;

    img {
      height: 0.75rem;
    }
  }

  .content {
    .privacy-compliance {
      margin-top: var(--x32);
      margin-bottom: var(--x32);
      font-size: var(--x12);
      line-height: var(--space-16);
      color: var(--text-secondary);
      text-align: center;
    }

    .image-cropper {
      margin: 5rem auto var(--space-32);
      height: 6rem;
      width: 12rem;
      position: relative;
      overflow: hidden;
      border-radius: 50%;

      .profile-pic {
        display: inline;
        height: 100%;
        width: auto;
      }
    }

    .first-line-title {
      margin-top: var(--space-32);
    }

    h4 {
      span {
        font-weight: var(--font-medium);
      }
    }

    .button {
      margin: var(--space-32) auto var(--space-16);
    }

    .svg-icon {
      margin-right: var(--space-8);
    }
  }
}
</style>
