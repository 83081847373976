<script setup>
import { computed, onMounted, ref } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import UserAvatar from '@/components/core/UserAvatar.vue';
import { useAuthStore } from '@/stores/auth';
import CheckBox from '@/components/foundation/CheckBox.vue';
import Button from '@/components/foundation/Button.vue';
import { vOnClickOutside } from '@vueuse/components';
import { useSchedulerStore } from '@/stores/scheduler';

const props = defineProps({
  post: {
    type: Object,
    default: null,
  },
  platform: {
    type: String,
    default: null,
  },
  removeReviewer: {
    type: Function,
    required: true,
  },
});

const authStore = useAuthStore();
const schedulerStore = useSchedulerStore();

const searchText = ref(null);
const active = ref(false);
const canReviewUsers = ref([]);
const existingApprovalRequests = ref([]);

onMounted(async () => {
  await authStore.getBrandUsers({ brandId: authStore.currentBrand.id });
  existingApprovalRequests.value =
    props?.post?.approvalRequests.map((request) => request.reviewUserId) ?? [];
  const brandUsers = authStore.brandUsers.filter((user) => {
    return user.canReviewScheduledPost;
  });
  canReviewUsers.value = brandUsers.map((user) => {
    const existingRequest = existingApprovalRequests.value.includes(user.id);
    return { ...user, selected: existingRequest, existingRequest };
  });
});

const reviewUsers = computed(() => {
  const searchFilter = (user) =>
    `${user.firstName} ${user.lastName}`.toLowerCase().includes(searchText.value.toLowerCase());
  const filteredReviewUsers = searchText.value
    ? canReviewUsers.value.filter(searchFilter)
    : canReviewUsers.value;
  return filteredReviewUsers;
});

function clear() {
  searchText.value = null;
  canReviewUsers.value = canReviewUsers.value.map((user) => {
    return { ...user, selected: user.existingRequest };
  });
}

function updateUser(userId) {
  const index = canReviewUsers.value.findIndex((user) => user.id === userId);
  canReviewUsers.value[index].selected = !canReviewUsers.value[index].selected;
  if (!canReviewUsers.value[index].selected) {
    props.removeReviewer(userId, false);
  }
}

function addApprovalRequests() {
  const newSelectedUsers = canReviewUsers.value.filter(
    (user) => user.selected && !user.existingRequests,
  );
  const newApprovalRequests = newSelectedUsers.map((reviewUser) => {
    return {
      requestedByUserId: authStore.identity.id,
      reviewUserId: reviewUser.id,
    };
  });
  schedulerStore.addApprovalRequests(newApprovalRequests);
  active.value = false;
}

function onClickOutside() {
  addApprovalRequests();
  active.value = false;
}

defineExpose({
  updateUser,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div v-on-click-outside="onClickOutside" @focusin="() => (active = true)">
    <div
      class="align-center filter-popover mb-2 flex flex-row rounded-full border border-solid bg-white"
      :class="{ 'border-[--action-500]': active, 'border-[--border]': !active }"
    >
      <div class="my-auto ml-3 flex">
        <Icon name="search" />
      </div>
      <input
        v-model.trim="searchText"
        placeholder="Search"
        data-cy="search-users-input-bar"
        type="text"
        class="border-hidden"
      />
      <div class="my-auto ml-auto mr-4 h-6">
        <Icon
          id="chevron"
          name="chevronDown"
          :dir="active ? 'down' : 'up'"
          @click="() => (active = !active)"
        />
      </div>
    </div>
    <transition name="slide">
      <div
        v-if="active"
        class="user-list-container absolute z-10 mt-2 flex max-h-96 w-[21rem] flex-col rounded-md bg-white pt-2"
      >
        <div class="overflow-scroll">
          <div v-for="user in reviewUsers" :key="user?.id" class="px-6 py-2">
            <CheckBox
              :value="user.selected"
              :disabled="user.existingRequest"
              data-cy="reviewer-item"
              @input="updateUser(user.id)"
            >
              <UserAvatar :user="user" xxsmall />
              <div class="text-sm">{{ user.firstName }} {{ user.lastName }}</div>
            </CheckBox>
          </div>
          <div v-if="reviewUsers.length < 1" class="no-users px-6 py-2 text-sm">No users found</div>
        </div>
        <div class="flex justify-between rounded-b-md bg-[--background-300] px-3 py-1">
          <Button class="text-[--text-primary]" dismiss small @click="clear"> Clear </Button>
          <Button
            data-cy="select-reviewer-btn"
            dismiss
            small
            class="text-[--action-500]"
            @click="addApprovalRequests()"
          >
            Select
          </Button>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="postcss" scoped>
.user-list-container {
  box-shadow: var(--shadow-4);
}

.border-hidden {
  width: 100%;
}
</style>
