export const TIKTOK_OVERVIEW_SINGLE_BRAND = [
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 27,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['FOLLOWER_GROWTH_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['AVG_TIME_WATCHED'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['NUMBER_OF_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['VIDEO_VIEWS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['TOTAL_VIDEO_VIEWS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['AVG_VIDEO_VIEWS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['TOTAL_ENGAGEMENTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['AVG_RETENTION_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['AVG_ENTERTAINMENT_SCORE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 16,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'TOTAL_VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 20,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'TOTAL_VIDEO_VIEWS',
      sort_order: 'ASC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 7,
    meta: { name: 'Posts Published During Reporting Period' },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 0,
    meta: { name: 'All Published Posts' },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['ENGAGEMENTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['ORGANIC_LIKES'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['ORGANIC_COMMENTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['SHARES'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['TOTAL_LIKES'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['TOTAL_COMMENTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['TOTAL_SHARES'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metric: 'VIDEO_VIEWS',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['AVG_REACH'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 24,
    meta: { name: 'Follower Activity' },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['PROFILE_VIEWS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 25,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['NET_NEW_FOLLOWERS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'ENTERTAINMENT_SCORE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 25,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metric: 'FOLLOWER_GROWTH_RATE',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 25,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['TOTAL_FOLLOWERS'],
      aggregate_by: 'TOTAL',
    },
  },
];
