import startCase from 'lodash/startCase';
import {
  FILTER_TYPE,
  COMMUNITY_INTERACTION_ACTION_LABELS,
  COMMUNITY_INTERACTION_ACTION_BUTTON_LOCATION_LABELS,
  COMMUNITY_INTERACTION_ACTIONS,
  REPORT_TYPE,
} from '@/app/community/constants';
import {
  getFollowerHandleFromInteraction,
  getPlatformByType,
} from '@/app/community/utils/community-interactions';
import { containsEmojis } from '@/utils/text-utils';
import { useAuthStore } from '@/stores/auth';
import { useTrackingStore } from '@/stores/tracking';
import { useIntercomStore } from '@/stores/intercom';

export function communityInteractionProps(communityInteraction = {}) {
  const { platform, messageType } = getPlatformByType(communityInteraction);
  return {
    platform,
    messageType,
    interactionId: communityInteraction.id,
    mediaId: communityInteraction.mediaId,
    mailbox: communityInteraction.isArchived ? 'Archived' : 'Inbox',
    emoji: containsEmojis(communityInteraction.text),
    isFollowUp: communityInteraction.isFollowUp,
    isRead: communityInteraction.isRead,
    isArchived: communityInteraction.isArchived,
    followerHandle: getFollowerHandleFromInteraction(communityInteraction),
  };
}

export function communityFilterEvent(
  filterType,
  filterAction,
  filterDetails,
  filterApplied = true,
) {
  const authStore = useAuthStore();

  const properties = {
    filterUsed: filterType,
    brandHandle: authStore.currentBrand.name,
  };

  // Community Filter Clicked: when a filter is clicked, but before the save happens
  // Community Filters: when a filter is selected, interacted with, and then saved.
  const eventTitle = filterApplied ? 'Community Filters' : 'Community Filter Clicked';

  switch (filterType) {
    case FILTER_TYPE.MAILBOX:
      properties.mailbox = filterAction;
      break;
    case FILTER_TYPE.FOLLOW_UP:
      properties.followUpAction = filterAction;
      break;
    case FILTER_TYPE.TAGS:
      properties.tagAction = filterAction;
      properties.tag = filterDetails.map((t) => t?.name);
      break;
    case FILTER_TYPE.POST:
      properties.count = filterDetails;
      properties.filterAction = filterAction;
      break;
    case FILTER_TYPE.SEARCH:
      properties.search = filterDetails;
      properties.searchType = filterAction;
      break;
    case FILTER_TYPE.SORT:
      properties.sortOption = filterAction;
      break;
    case FILTER_TYPE.QUESTION:
      properties.filterDetails = filterDetails;
      properties.filterAction = filterAction;
      break;
    case FILTER_TYPE.SENTIMENT:
      properties.filterDetails = filterDetails;
      properties.filterAction = filterAction;
      break;
    case FILTER_TYPE.UNREAD:
      properties.filterAction = filterAction;
      break;
    case FILTER_TYPE.MESSAGE_DATE:
      properties.filterDetails = filterDetails;
      break;
    case FILTER_TYPE.PLATFORM:
      properties.platform = filterDetails;
      break;
    case FILTER_TYPE.MENTIONED:
      if (filterAction !== null) properties.filterAction = filterAction;
      if (typeof filterDetails === 'boolean') properties.mentionedMySelf = filterDetails;
      break;
    case FILTER_TYPE.ASSIGNEE:
      if (filterAction !== null) properties.filterAction = filterAction;
      if (typeof filterDetails === 'boolean') properties.assignedToMySelf = filterDetails;
      break;
    case FILTER_TYPE.MESSAGE_TYPE:
      properties.filterDetails = filterDetails;
      break;
    case FILTER_TYPE.MESSAGE_RULE:
      properties.rule = filterDetails;
      break;
    default:
      break;
  }

  const trackingStore = useTrackingStore();
  trackingStore.track(eventTitle, properties);
}

export function communityConversationUpdateEvent({
  platform,
  messageType,
  action,
  location,
  brandHandle,
  originalCommunityInteraction,
  updatedCommunityInteraction,
}) {
  const authStore = useAuthStore();
  const trackingStore = useTrackingStore();
  trackingStore.track('Community Conversation Interaction', {
    interactionId: originalCommunityInteraction.id,
    mediaId: originalCommunityInteraction.mediaId,
    platform,
    messageType,
    mailbox: originalCommunityInteraction.isArchived ? 'Archive' : 'Inbox',
    actionPerformed: COMMUNITY_INTERACTION_ACTION_LABELS[action],
    isFollowUp: updatedCommunityInteraction.isFollowUp,
    isRead: updatedCommunityInteraction.isRead,
    isArchived: updatedCommunityInteraction.isArchived,
    wasFollowUp: originalCommunityInteraction.isFollowUp,
    wasRead: originalCommunityInteraction.isRead,
    wasArchived: originalCommunityInteraction.isArchived,
    followerHandle: getFollowerHandleFromInteraction(originalCommunityInteraction),
    brandHandle: brandHandle ?? authStore.currentBrand?.name ?? '',
    updatedFrom: COMMUNITY_INTERACTION_ACTION_BUTTON_LOCATION_LABELS[location],
  });
}

export function communityMessageInteractionEvent({
  platform,
  messageType,
  text,
  actionPerformed,
  brandHandle,
  reaction = null,
  communityInteraction,
}) {
  const authStore = useAuthStore();
  const trackingStore = useTrackingStore();
  trackingStore.track('Community Message Interaction', {
    interactionId: communityInteraction.id,
    mediaId: communityInteraction.mediaId,
    platform,
    messageType,
    mailbox: communityInteraction.isArchived ? 'Archived' : 'Inbox',
    actionPerformed,
    emoji: containsEmojis(text),
    reaction,
    isFollowUp: communityInteraction.isFollowUp,
    isRead: communityInteraction.isRead,
    isArchived: communityInteraction.isArchived,
    followerHandle: getFollowerHandleFromInteraction(communityInteraction),
    brandHandle: brandHandle ?? authStore.currentBrand?.name ?? '',
    isAdComment: communityInteraction?.isAdComment || false,
  });
}

export function communityConversationBulkUpdateEvent({
  platformTotal,
  platform,
  messageTypeTotal,
  action,
  location,
  brandHandle,
  communityInteractions,
}) {
  const authStore = useAuthStore();
  const bulkUpdatedIds = communityInteractions.map((interaction) => interaction.id);
  const followerHandleNames = communityInteractions.map((interaction) =>
    getFollowerHandleFromInteraction(interaction),
  );
  const options = {
    interactionIds: bulkUpdatedIds,
    platformTotal: Object.entries(platformTotal).reduce((col, entry) => col.concat(entry)),
    platform,
    messageTypeTotal: Object.entries(messageTypeTotal).reduce((col, entry) => col.concat(entry)),
    mailbox:
      (communityInteractions[0].isArchived && action !== COMMUNITY_INTERACTION_ACTIONS.ARCHIVE) ||
      (action === COMMUNITY_INTERACTION_ACTIONS.UNARCHIVE && !communityInteractions[0].isArchived)
        ? 'Archive'
        : 'Inbox',
    actionPerformed: `Bulk ${COMMUNITY_INTERACTION_ACTION_LABELS[action]}`,
    followerHandleNames,
    totalFollowerHandles: followerHandleNames.filter((interaction) => interaction !== 'UNKNOWN')
      ?.length,
    brandHandle: brandHandle ?? authStore.currentBrand?.name ?? '',
    updatedFrom: location,
  };
  const trackingStore = useTrackingStore();
  trackingStore.track('Community Conversation Interaction', options);
}

export function communityPostLevelUpdateEvent({
  mediaId,
  platform,
  actionPerformed,
  location,
  brandHandle,
}) {
  const authStore = useAuthStore();
  const trackingStore = useTrackingStore();
  trackingStore.track('Community Post Level Update', {
    mediaId,
    platform,
    actionPerformed,
    brandHandle: brandHandle ?? authStore.currentBrand?.name ?? '',
    updatedFrom: location,
  });
}

export function communityCommentPopupEvent({ details, communityInteraction }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Community Comment Popup', {
    ...details,
    mediaId: communityInteraction?.mediaId,
    mailBox: communityInteraction?.isArchived ? 'Archive' : 'Inbox',
  });
}

export function communityViewConversationEvent({ communityInteraction, brandHandle }) {
  const authStore = useAuthStore();
  const { platform, messageType } = getPlatformByType(communityInteraction);
  const trackingStore = useTrackingStore();
  const event = 'Community View Conversation';
  const properties = {
    interactionId: communityInteraction.id,
    mediaId: communityInteraction.mediaId,
    platform,
    messageType,
    mailbox: communityInteraction.isArchived ? 'Archive' : 'Inbox',
    followerHandle: getFollowerHandleFromInteraction(communityInteraction),
    brandHandle: brandHandle ?? authStore.currentBrand?.name ?? '',
    isAdComment: communityInteraction?.isAdComment || false,
  };
  trackingStore.track(event, properties);
  const intercomStore = useIntercomStore();
  intercomStore.trackEvent(event, properties);
}

export function communityReportEvent(reportType, interactionType, reportDetails) {
  let properties = {
    reportType,
    interactionType,
  };

  switch (reportType) {
    case REPORT_TYPE.SENTIMENT:
      properties.sentiment = reportDetails;
      break;
    case REPORT_TYPE.CHANNEL:
      properties.platform = reportDetails;
      break;
    case REPORT_TYPE.VOLUME_OVER_TIME:
      properties = { ...properties, ...reportDetails };
      break;
    default:
      break;
  }
  const trackingStore = useTrackingStore();
  trackingStore.track('Community Report Interaction', properties);
}

export function internalNoteCreateEvent(messageType, interactionId, isAdComment = false) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Internal Note Created', {
    messageType,
    interactionId,
    isAdComment,
  });
}

export function internalNoteViewEvent(messageType, interactionId, isAdComment = false) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Internal Note Viewed', {
    messageType,
    interactionId,
    isAdComment,
  });
}

export function messageAssignedEvent({
  messageType,
  interactionId,
  isAutomated,
  assignedById,
  assignedToId,
  isAdComment = false,
}) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Message Assigned', {
    messageType,
    interactionId,
    isAutomated,
    assignedById,
    assignedToId,
    isAdComment,
  });
}

export function updateSentiment(messageType, previousSentiment, newSentiment) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Update Sentiment', {
    messageType,
    previousSentiment,
    newSentiment,
  });
}

export function translationEvent(detectedLanguage, interaction, isReply, updatedEvent) {
  const trackingStore = useTrackingStore();
  const { type } = interaction;
  let [channel, messageType] = type.split('_');
  channel = startCase(channel.toLowerCase());
  if (messageType === 'COMMENT' && interaction.isAdComment) {
    messageType = interaction.isAdComment ? 'Ad Comment' : 'Comment';
  } else if (messageType === 'CONVERSATION') {
    messageType = 'DM';
  } else {
    messageType = startCase(messageType.toLowerCase());
  }
  const payload = {
    channel,
    messageType,
    updatedEvent: updatedEvent ? 'Yes' : 'No',
  };
  if (isReply) {
    payload.translatedTo = detectedLanguage;
    trackingStore.track('Community Translate Reply', payload);
  } else {
    payload.translatedFrom = detectedLanguage;
    trackingStore.track('Community Translate Message', payload);
  }
}

export function userBlockedUpdatedEvent(isBlocked, channel, messageType) {
  const trackingStore = useTrackingStore();

  const payload = {
    channel,
    messageType,
  };

  if (isBlocked) {
    trackingStore.track('Community Block User', payload);
  } else {
    trackingStore.track('Community Unblock User', payload);
  }
}

// MESSAGE RULES
export function messageRulesSelectedEvent() {
  const trackingStore = useTrackingStore();
  trackingStore.track('Message Rules Selected');
}

export function createOrEditMessageRuleEvent({ openedFrom, ruleId, ruleName }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Community - Create/Edit Message Rule', { openedFrom, ruleId, ruleName });
}

export function selectMessageFiltersAndActionsEvent({ action, filterUsed, filterGroups }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Community - Select Message Filters and Actions', {
    action,
    filterUsed,
    filterGroups,
  });
}

export function messageRuleCreatedEvent({
  saveAction,
  ruleName,
  action,
  filterUsed,
  filterGroups,
}) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Community - Message Rule Created', {
    saveAction,
    ruleName,
    action,
    filterUsed,
    filterGroups,
  });
}

export function messageRuleInteractionsEvent({ interaction, ruleId, ruleName }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Community - Message Rule Interactions', { interaction, ruleId, ruleName });
}

export function undoMessageRuleEvent({ ruleId, ruleName }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Community - Undo Message Rule', { ruleId, ruleName });
}
