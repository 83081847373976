<template>
  <main class="rights-acquired-filter">
    <span>
      <Icon name="copyrights" small />
      Rights Acquired
    </span>
    <ToggleSwitch ref="toggle-filter" class="toggle" @on-change="handleToggle" />
  </main>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import ToggleSwitch from '@/components/ToggleSwitch.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'RightsAcquiredFilter',
  components: {
    Icon,
    ToggleSwitch,
  },
  emits: ['toggled'],
  methods: {
    updateFilter(option) {
      this.$refs['toggle-filter'].switcherOn = option === 'on';
    },
    handleToggle(option) {
      this.$emit('toggled', option);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.rights-acquired-filter {
  padding: var(--space-24) 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);

  .svg-icon {
    margin-right: var(--space-24);
  }
}

.toggle {
  margin-right: var(--space-40) !important;
}
</style>
