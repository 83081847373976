<template>
  <div>
    <template v-if="currentScreen === 'index'">
      <PostStatusBox :post="{ ...post, platform: 'instagram' }" />
      <section>
        <PublishOptionHeader
          :disabled="isDraftToggleDisabled"
          :is-on="isDraft"
          :post="post"
          @drafts-toggled="(draft) => onToggleDraft(draft)"
        />
        <AutoPublishOptions
          :key="schedulerStore.instagramTabContext"
          v-model="autoPublish"
          :disable-auto-publish="disableAutoPublish"
          :disabled="disableEditing"
          :post="post"
          platform="instagram"
        />
        <PostSettingItem
          :disabled="disableEditing || approvedPublishingDatesExpired"
          :title="scheduledTimeLabel"
          :clear-action="resetTime"
          :error-message="scheduledTimeError"
          :on-click="() => switchSubScreen('schedule')"
          empty="Schedule Date & Time"
          left-icon="calendar"
        />
        <QuickSelectCalendar
          v-if="isActiveTab && !approvedPublishingDatesExpired"
          ref="quickSelectCalendar"
          :class="{ 'pt-4': publishDateError }"
          :model-value="scheduledTime"
          :restrict-selection-to-interval="canPublishWithin"
          :platform="platformLabels.instagram"
          @update:model-value="timeSelected"
        />
      </section>
      <PostSettingItem
        v-if="hasApprovalRequestsAccess"
        :title="getApprovalRequestTitle({ post })"
        :on-click="
          () =>
            schedulerStore.toggleSidePanel({
              router: $router,
              platform: 'instagram',
              post,
            })
        "
        data-cy="approval-request-setting-item"
        empty="Add Reviewers"
        left-icon="chatBubbleSquareCheck"
        label="Approval Request"
        :approval-status-badge="getApprovalChipText({ post, approvalRequests })"
      />
      <section>
        <PostSettingItem
          :input-tooltip="linkTooltip"
          :disabled="disableEditing || autoPublish"
          :title="storyLink"
          :on-click="showStoryLinkSubScreen"
          :clear-action="resetStoryLink"
          empty="Add Link For Link Sticker"
          left-icon="swipeUp"
          label="Link Sticker"
          :label-info-tooltip="linkStickerTooltip"
        />
        <div :class="{ 'trackable-link-with-margin': showTrackableLink }" class="trackable-link">
          <CheckBox
            v-model="showTrackableLink"
            data-cy="trackable-link-checkbox"
            :tooltip="toolTips.trackableLink"
            :disabled="trackableLinkDisabled"
            label="Enable Trackable Link"
          />
        </div>
        <div v-if="showTrackableLink">
          <CircularLoader v-if="shortURLLoading" class="shortURL-loader" />
          <ValidationError v-if="shortURLError">
            The link is invalid, please update and try again.
          </ValidationError>
          <CopyField
            v-if="shortUrlStore.shortURL"
            :show-popup="media.length > 1"
            :popup-text="toolTips.trackableLinkPopup"
            :value="shortUrlStore.shortURL.shortUrl"
          />
        </div>
      </section>
      <section>
        <p class="section-title">
          Add Note
          <InfoTooltip v-if="!autoPublish" :tooltip="addNoteTooltip" xsmall />
        </p>
        <Textarea
          v-model="note"
          v-tooltip="noteTooltip"
          :disabled="disableEditing || autoPublish"
          placeholder="Enter note"
          data-cy="caption-area"
        />
      </section>
      <PostSettingItem
        :disabled="disableEditing"
        :title="addToLabel"
        :clear-action="resetAddTo"
        empty="After Published, Add to"
        left-icon="folderAdd"
        :label="addToAfterLabel"
        @click="switchSubScreen('addTo')"
      />
      <Controls class="controls">
        <Button
          v-if="!disableEditing && post && post.id"
          v-tooltip="'Remove from Scheduler'"
          remove
          class="remove"
          data-cy="instagram-post-remove-button"
          @click="onRemoveClick"
        >
          Remove
        </Button>
        <SplitButton
          v-if="!disableEditing"
          :tooltip="saveTooltip"
          :disabled="saveDisabled"
          :loading="schedulerStore.postActionPending"
          :options="saveOptions"
          class="save-button"
          data-cy="save-instagram-dropdown-button"
          primary
          @click="save"
        >
          Save
        </SplitButton>
        <DropdownButton
          v-else
          :dropdown-list="duplicatePostOptions"
          button-classes="primary"
          data-cy="duplicate-post-button"
          items-center
        >
          <template #buttonContent>Duplicate Post</template>
        </DropdownButton>
      </Controls>
    </template>

    <!-- Add swipe-up link subscreen -->
    <SubScreen
      v-if="currentScreen === 'storyLink'"
      title="Add Link for Link Sticker"
      class="link-subscreen"
      @on-back="switchSubScreen('index')"
    >
      <template #content>
        <section class="input-row">
          <input
            v-model="newStoryLink"
            data-cy="sticker-link"
            placeholder="https://"
            @input="debounceInput"
          />
          <Button v-tooltip="'Choose from products'" icon-name="windowLink" @click="editProduct" />
        </section>
        <Button
          data-cy="EditUtmParametersButton"
          class="float-left mb-4 mt-2"
          :disabled="!newStoryLink"
          link
          @click="openUtmEditorPanel(true)"
        >
          {{ utmScheduleOpenEditTrackingButton }}
        </Button>
        <section class="button-section">
          <Button primary data-cy="save-story-link" class="wide" @click="saveStoryLink">
            Done
          </Button>
        </section>
      </template>
    </SubScreen>

    <!-- Select post time subscreen -->
    <SubScreen
      v-if="currentScreen === 'schedule'"
      title="Choose Date & Time"
      @on-back="switchSubScreen('index')"
    >
      <template #content>
        <section>
          <Calendar
            :posts="schedulerStore.getPosts(brandInstagramPosts)"
            :ideal-post-times="bestTimesToPost"
            :time-selected="timeSelected"
            :on-fetch-posts="onCalendarFetch"
            :restrict-selection-to-interval="canPublishWithin"
            platform="instagram"
            type="compact"
          />
        </section>
      </template>
    </SubScreen>

    <!-- Select instagram story board to add to subscreen -->
    <AddToGallery
      v-if="currentScreen === 'addTo'"
      :selected-galleries="selectedGalleries"
      :on-galleries-selected="galleriesSelected"
      :on-back="() => switchSubScreen('index')"
      type="INSTAGRAM_STORY"
    />
    <!-- The Side Panel when editing Link Sticker UTMs -->
    <teleport v-if="showUtmEditorPanel" to="#SchedulerRightSidePanel">
      <UtmEditorPanel
        class="w-80"
        :brand-channel-utm-settings="brandChannelUtmSettings"
        :channel="instagramStoryUtmChannel"
        :url="newStoryLink"
        :tracking-data="trackingData"
        @tracking-parameters-updated="updateUtmEditorNewUrl"
        @close="closeUtmEditorPanel(true)"
      />
    </teleport>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import { mapStores } from 'pinia';
import escapeRegExp from 'lodash/escapeRegExp';
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { toolTips, debounceInputDelay } from '@/config';
import { env } from '@/env';
import PostDuplicationMixin from '@/mixins/postDuplicationMixin';
import PostSettingItemLabelMixin from '@/app/scheduler/mixins/PostSettingItemLabelMixin';
import SplitButton from '@/components/foundation/SplitButton.vue';
import Button from '@/components/foundation/Button.vue';
import Textarea from '@/components/Textarea.vue';
import {
  mixpanelActions,
  postStatus,
  postTypes,
  popUpMessages,
  tabContexts,
  PLATFORMS,
  postPublishInfoTooltip,
  platformLabels,
} from '@/app/scheduler/constants';
import CheckBox from '@/components/foundation/CheckBox.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import ValidationError from '@/components/ValidationError.vue';
import CopyField from '@/components/CopyField.vue';
import {
  checkUnsavedChanges,
  allMediaUploaded,
  getUnscheduledRouteName,
} from '@/app/scheduler/utils';
import { BRAND, USER } from '@/models/auth/permissions.enum';
import { useSchedulerStore } from '@/stores/scheduler';
import { allPosts } from '@/app/scheduler/utils/post-query';
import { usePlatformStore } from '@/stores/platform';
import { useInstagramPostsStore } from '@/stores/instagram-posts';
import InstagramRateLimitMixin from '@/app/scheduler/mixins/InstagramRateLimitMixin';
import ApprovedPublishingDatesMixin from '@/app/scheduler/mixins/ApprovedPublishingDatesMixin';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import { useShortUrlStore } from '@/stores/short-url';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import UtmEditorPanel from '@/app/settings/components/Utm/UtmEditorPanel.vue';
import {
  encodeUtmErrorMessage,
  utmChannel,
  utmScheduleOpenEditTrackingButton,
} from '@/app/settings/components/Utm/const';
import {
  trackSchedulerUtmPopout,
  postTypeLabelMap,
  SchedulerUserEventTracker,
} from '@/app/scheduler/mixpanel';
import { LibraryAPI } from '@/apis';
import { logger } from '@/utils/logger';
import { useFlagStore } from '@/stores/flag';
import { fetchUtmSettings } from '@/app/settings/components/Utm/utils';
import { useImpersonatorStore } from '@/stores/impersonator';
import { useCommentingStore } from '@/stores/commenting';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import isEqual from 'lodash/isEqual';
import {
  getApprovalChipText,
  getApprovalRequestTitle,
  postRequiresApproval,
} from '@/app/scheduler/utils/approvals';
import PublishOptionHeader from '@/app/scheduler/components/PublishOptionHeader.vue';
import SubScreen from './Layout/SubScreen.vue';
import PostSettingItem from '../PostSettingItem.vue';
import Controls from './Layout/Controls.vue';
import PostStatusBox from '../PostStatusBox.vue';
import AutoPublishOptions from '../AutoPublishOptions.vue';
import AddToGallery from './AddToGallery.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'EditInstagramStoryPost',
  components: {
    PublishOptionHeader,
    UtmEditorPanel,
    AddToGallery,
    AutoPublishOptions,
    Button,
    Calendar: defineAsyncComponent(() => import('../Calendar.vue')),
    CheckBox,
    CircularLoader,
    Controls,
    CopyField,
    DropdownButton,
    PostSettingItem,
    PostStatusBox,
    QuickSelectCalendar: defineAsyncComponent(
      () => import('@/app/scheduler/components/QuickSelectCalendar.vue'),
    ),
    SplitButton,
    SubScreen,
    Textarea,
    ValidationError,
    InfoTooltip,
  },
  mixins: [
    PostDuplicationMixin,
    InstagramRateLimitMixin,
    PostSettingItemLabelMixin,
    ApprovedPublishingDatesMixin,
  ],
  props: {
    close: { type: Function, required: true },
    disableEditing: { type: Boolean, default: false },
    isActiveTab: { type: Boolean, default: false },
    media: { type: Array, default: null },
    post: { type: Object, default: null },
    uploadPending: { type: Boolean, default: false },
    mediaValidationError: { type: Object, default: null },
    showComments: { type: Boolean, default: false },
    bestTimesToPost: { type: Array, default: () => [] },
  },
  emits: ['updateUnsavedStatus', 'onEditProduct', 'postDataUpdated'],
  data() {
    return {
      autoPublish: false,
      currentScreen: 'index',
      isPublishNow: false,
      isSaving: false,
      newStoryLink: '',
      note: (this.post && this.post.caption) || '',
      scheduledTime: this.post && this.post.timestamp && dayjs(this.post.timestamp),
      selectedGalleries: [
        ...(this.post?.campaigns?.map((campaignObj) => ({ ...campaignObj, campaign: true })) ?? []),
        ...(this.post?.boards ?? []),
      ],
      shortURLLoading: false,
      shortURLError: false,
      showTrackableLink: false,
      storyLink: this.post?.story_link ?? null,
      disablePublishNowDueToAPILimit: false,
      originalLinkUnloaded: false,
      showUtmEditorPanel: false,
      updatedStoryLink: '',
      brandChannelUtmSettings: {},
      selectedProductId: null,
      utmScheduleOpenEditTrackingButton,
      urlLinkCustomized: false,
      approvalRequests: (this.post && this.post.approval_requests) || [],
      isDraft: (!this.post?.id && !this.post?.timestamp) || this.post?.status === postStatus.DRAFT,
    };
  },
  computed: {
    ...mapStores(
      useCommentingStore,
      useCustomerJourneyStore,
      useSchedulerStore,
      useNotificationStore,
      usePlatformStore,
      useShortUrlStore,
      useInstagramPostsStore,
      useAuthStore,
      useFlagStore,
      useTrackingStore,
      useImpersonatorStore,
    ),
    instagramStoryUtmChannel() {
      return utmChannel.INSTAGRAM_STORY;
    },
    brandInstagramPosts() {
      return allPosts.forBrand(this.authStore.currentBrand.id).forPlatform(PLATFORMS.INSTAGRAM);
    },
    toolTips() {
      return toolTips;
    },
    mediaList() {
      return this.media;
    },
    isSaveDropdownDisabled() {
      const isImpersonating = this.impersonatorStore.isImpersonating;
      const isDisabledDueToInsufficientPermission =
        !this.authStore.guard(BRAND.SCHEDULER.CAN_AUTO_PUBLISH_INSTAGRAM) ||
        !this.authStore.guard(USER.SCHEDULER.CAN_AUTO_PUBLISH_INSTAGRAM) ||
        isImpersonating;
      const isDisabledDueToNoMedia = this.media.length < 1 && this.autoPublish;
      const isDisabledDueToNotAutoPublish = !this.autoPublish;
      if (isDisabledDueToInsufficientPermission) {
        return { isDisabled: true, tip: toolTips.insufficientPermission };
      }
      if (isDisabledDueToNoMedia) {
        return { isDisabled: true, tip: toolTips.noMediaSelectedAutoPublish };
      }
      if (isDisabledDueToNotAutoPublish) {
        return { isDisabled: true, tip: toolTips.notAutoPublish };
      }
      if (this.disablePublishNowDueToAPILimit) {
        return {
          isDisabled: true,
          tip: toolTips.maxDailyIGAutoPublishPostsReached(this.maxDailyInstagramAutoPublishPosts),
        };
      }
      if (!this.canAssetPublishToday) {
        return { isDisabled: true, tip: this.publishNowWarning };
      }
      if (this.requiresApproval(this.post)) {
        return { isDisabled: true, tip: toolTips.requiresApproval };
      }
      return false;
    },
    hasApprovalRequestsAccess() {
      return !this.customerJourneyStore.hasPlanGrow;
    },
    duplicatePostOptions() {
      return this.pdDuplicationOptions(this.duplicate);
    },
    saveOptions() {
      return [
        {
          text: 'Save & Duplicate',
          subList: this.pdDuplicationOptions(this.saveAndDuplicate),
        },
        {
          text: 'Publish Now',
          action: this.publishNow,
          disabled: this.isSaveDropdownDisabled.isDisabled,
          tooltip: this.isSaveDropdownDisabled.tip,
        },
        {
          text: 'Publish Now & Duplicate',
          disabled: this.isSaveDropdownDisabled.isDisabled,
          tooltip: this.isSaveDropdownDisabled.tip,
          subList: this.pdDuplicationOptions(this.publishNowAndDuplicate),
        },
      ];
    },
    addToLabel() {
      if (this.selectedGalleries.length) {
        return this.selectedGalleries.map((g) => g.name).join(', ');
      }
      return null;
    },
    scheduledTimeLabel() {
      return this.scheduledTime ? this.scheduledTime.format('LT, ll') : null;
    },
    saveDisabled() {
      return (
        (this.media.length === 0 && this.autoPublish) ||
        this.uploadPending ||
        this.isSaving ||
        this.mediaValidationError?.level === 'error' ||
        !!this.publishDateError ||
        (this.scheduledTime && this.scheduledTime < dayjs.utc(dayjs()).local())
      );
    },
    saveTooltip() {
      if (this.mediaValidationError?.level === 'error') {
        return { content: this.mediaValidationError.message };
      }
      if (this.scheduledTime && this.scheduledTime < dayjs.utc(dayjs()).local()) {
        return { content: toolTips.illegalTimeStamp };
      }
      if (this.uploadPending) {
        return { content: 'Your media is uploading...' };
      }
      return null;
    },
    linkTooltip() {
      if (this.autoPublish) {
        return 'Link Stickers are currently unavailable for Instagram Stories auto publishing';
      }
      return null;
    },
    noteTooltip() {
      if (this.autoPublish) {
        return 'Add Note is unavailable for Instagram Stories auto publishing';
      }
      return null;
    },
    timestampAddedOrRemoved() {
      return (
        (!this.scheduledTime && this.post && this.post.timestamp) ||
        (!this.post.timestamp && this.scheduledTime)
      );
    },
    hasTrackableLinkAccess() {
      return this.authStore.user_can('instagram', 'can_access_stories_swipeup');
    },
    trackableLinkDisabled() {
      return (
        this.disableEditing || !this.storyLink || !this.hasTrackableLinkAccess || this.autoPublish
      );
    },
    postData() {
      return {
        platform: PLATFORMS.INSTAGRAM,
        id: this.post?.id,
        board_ids: this.selectedBoardIds,
        campaign_ids: this.selectedCampaignIds,
        brand_id: this.authStore.currentBrand.id,
        caption: this.note,
        media: this.media,
        media_ids: this.media.map((m) => m.id),
        post_type: postTypes.STORY,
        story_link: this.storyLink,
        timestamp: this.scheduledTime && this.scheduledTime.toISOString(),
        auto_publish: this.autoPublish,
        custom_utms: this.urlLinkCustomized,
        approvalRequests: this.approvalRequests,
        ...(this.isDraft && this.flagStore.ready && this.flagStore.flags.schedulerDrafts
          ? { status: postStatus.DRAFT }
          : {}),
        ...(this.post?.approvalPolicy ? { approval_policy: this.post.approvalPolicy } : {}),
      };
    },
    selectedBoardIds() {
      return this.selectedGalleries.filter((g) => !g.campaign).map((g) => g.id);
    },
    selectedCampaignIds() {
      return this.selectedGalleries.filter((g) => g.campaign).map((g) => g.id);
    },
    duplicatePostData() {
      return {
        ...this.postData,
        boards: this.selectedGalleries.filter((g) => !g.campaign),
        campaigns: this.selectedGalleries.filter((g) => g.campaign),
      };
    },
    disableAutoPublish() {
      if (!allMediaUploaded(this.media)) {
        return { isDisabled: true, tip: toolTips.noMediaSelectedAutoPublish };
      }
      if (this.media.length > 1 && !this.autoPublish) {
        return { isDisabled: true, tip: toolTips.invalidIGStoryMediaSelection };
      }
      return { isDisabled: false, tip: '' };
    },
    addNoteTooltip() {
      return postPublishInfoTooltip.ADD_NOTE;
    },
    linkStickerTooltip() {
      return !this.autoPublish ? postPublishInfoTooltip.LINK_STICKER : null;
    },
    trackingData() {
      return {
        postId: this.post?.id,
        postPlatform: 'Instagram',
        postType: 'Story',
      };
    },
    platformLabels() {
      return platformLabels;
    },
    isDraftToggleDisabled() {
      const isPublished = [
        postStatus.POSTED,
        postStatus.AUTOPUBLISHING,
        postStatus.IMPORTED,
      ].includes(this.post?.status);
      return isPublished || !this.postData?.timestamp;
    },
    trackMixpanel() {
      return new SchedulerUserEventTracker('Scheduler Editor');
    },
  },
  watch: {
    showComments(to) {
      if (to) this.closeUtmEditorPanel(true);
    },
    scheduledTime() {
      this.$emit('updateUnsavedStatus', this.checkUnsavedStatus());
      this.validateMaxAutoPublishPosts();
    },
    'schedulerStore.approvalRequests': {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) this.approvalRequests = newVal;
      },
    },
    postData(to) {
      if (this.isActiveTab) {
        this.$emit('updateUnsavedStatus', this.checkUnsavedStatus());
      }
      if (!to?.timestamp) this.isDraft = true;
      this.$emit('postDataUpdated', to);
    },
    async showTrackableLink(to) {
      if (to) {
        if (!(this.shortUrlStore.shortURL || this.isTrackableLink())) {
          // don't create a new short URL if we're displaying the old one
          this.shortURLLoading = true;
          try {
            const response = await LibraryAPI.encodeUtmSettings({
              brandId: this.currentBrand.id,
              content: this.storyLink,
              channel: this.instagramStoryUtmChannel,
              shortenUrl: true,
              urls: [this.storyLink],
            });
            this.shortUrlStore.shortURL = {
              shortUrl: response.data.content,
              data: response.data.content.split('/').pop(),
            };
            this.trackingStore.track('Instagram Story Post Trackable Link Checkbox Clicked', {
              type: 'IG_STORY',
              brandId: this.authStore.currentBrand.id,
            });
          } catch (error) {
            this.shortURLError = true;
            throw error;
          } finally {
            this.shortURLLoading = false;
          }
        } else if (this.isTrackableLink() && this.originalLinkUnloaded) {
          this.shortURLError = true;
        }
      } else {
        this.shortURLError = false;
      }
    },
    autoPublish(isAutoPublish) {
      this.schedulerStore.setAutoPublish(this.autoPublish);
      if (isAutoPublish) {
        if (this.schedulerStore.instagramTabContext === tabContexts.STORY) {
          this.confirmAutoPublishConnection();
        }
      }
    },
    newStoryLink(to) {
      if (!to) this.closeUtmEditorPanel();
    },
    selectedProductId(to) {
      // selectedProductId will change when selecting product from the product popup
      if (to) {
        this.openUtmEditorPanel();
      }
    },
  },
  mounted() {
    this.autoPublish = this.post?.auto_publish || false;
    this.validateMaxAutoPublishPosts();
    this.fetchStoryLinkURL();
    this.fetchInstagramStoryUtmSettings();
  },
  activated() {
    this.schedulerStore.setActiveSubScreen(this.currentScreen);
  },
  beforeUnmount() {
    this.shortUrlStore.shortURL = null;
  },
  methods: {
    getApprovalRequestTitle,
    getApprovalChipText,
    debounceInput: debounce(function typeAhead() {
      this.selectedProductId = null;
      if (this.newStoryLink) this.openUtmEditorPanel();
    }, debounceInputDelay),
    async fetchInstagramStoryUtmSettings() {
      this.brandChannelUtmSettings = await fetchUtmSettings({
        brandId: this.authStore.currentBrand.id,
        channel: this.instagramStoryUtmChannel,
      });
    },
    async duplicate({ toType }) {
      // Have to define duplicateData within these functions so computed values
      // are not lost when current post is closed.
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.INSTAGRAM_STORY,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.DUPLICATE_FROM_PUBLISHED_CHANNEL,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      this.pdDuplicatePostToChannel({
        fromType: this.DUPLICATION_POST_TYPES.INSTAGRAM_STORY,
        toType,
        post: duplicateData,
      });
    },
    async publishNowAndDuplicate({ toType }) {
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.INSTAGRAM_STORY,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.PUBLISH_NOW_AND_DUPLICATE,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      await this.publishNow(false);
      if (!duplicateData?.id) {
        duplicateData.id = this.schedulerStore.duplicationPostId;
      }
      this.pdDuplicatePostToChannel({
        toType,
        fromType: this.DUPLICATION_POST_TYPES.INSTAGRAM_STORY,
        post: duplicateData,
      });
    },
    async saveAndDuplicate({ toType }) {
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.INSTAGRAM_STORY,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.SAVE_AND_DUPLICATE,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      await this.save(false);
      if (!duplicateData?.id) {
        duplicateData.id = this.schedulerStore.duplicationPostId;
      }
      this.pdDuplicatePostToChannel({
        fromType: this.DUPLICATION_POST_TYPES.INSTAGRAM_STORY,
        toType,
        post: duplicateData,
      });
    },
    async publishNow(closeAfter = true) {
      if (this.confirmAutoPublishConnection()) {
        this.publishing = true;
        this.isPublishNow = true;
        const data = {
          ...this.postData,
          status: postStatus.AUTOPUBLISHING,
        };
        if (this.post?.id) {
          await this.schedulerStore.updatePost(data);
        } else {
          await this.schedulerStore.createPost(data);
        }
        this.notificationStore.setToast({
          message: 'Publishing in progress.',
          actionText: 'See it on your Timeline',
          actionTo: { name: 'scheduler.instagram.timeline' },
          type: 'primary',
        });
        this.publishing = false;
        if (closeAfter) {
          this.close();
        }
      }
    },
    confirmAutoPublishConnection() {
      if (!this.authStore.currentBrand) {
        return null;
      }
      const isConnected = this.platformStore.isPlatformConnected(PLATFORM_CONNECTION.FACEBOOK, {
        scopes: [PLATFORM_CONNECTION.FACEBOOK.SCOPES.INSTAGRAM_CONTENT_PUBLISH],
      });
      if (!isConnected) {
        this.platformStore.connect(PLATFORM_CONNECTION.FACEBOOK, {
          onCancel: () => this.cancelConnectionPopup(),
          title: 'Enable Auto Publishing',
          message:
            'To enable auto publishing for Instagram, please reconnect your Facebook account.',
        });
        return false;
      }
      return true;
    },
    galleriesSelected(galleries) {
      this.selectedGalleries = galleries;
    },
    switchSubScreen(screen) {
      if (!this.disableEditing) {
        this.currentScreen = screen;
        this.schedulerStore.setActiveSubScreen(this.currentScreen);
        if (screen === 'schedule') {
          this.trackingStore.track(mixpanelActions.SCHEDULER_CALENDAR_OPENED, {
            postPlatform: 'Instagram',
            quickSelectClicked: false,
          });
        }
      }
      if (screen !== 'storyLink') this.closeUtmEditorPanel();
    },
    timeSelected(time) {
      if (!this.scheduledTime) this.isDraft = false;
      this.scheduledTime = dayjs(time);
      this.switchSubScreen('index');
    },
    resetTime() {
      this.scheduledTime = null;
    },
    showStoryLinkSubScreen() {
      if (!this.originalLinkUnloaded) {
        this.newStoryLink = this.storyLink;
      }
      this.currentScreen = 'storyLink';
      this.schedulerStore.setActiveSubScreen(this.currentScreen);
    },
    editProduct() {
      this.$emit('onEditProduct', this.$.vnode.key);
    },
    onToggleDraft(draft) {
      this.isDraft = draft;
      this.trackMixpanel.draftToggleClicked(
        draft,
        postTypeLabelMap.instagram,
        postTypeLabelMap.story,
      );
    },
    async getEncodedUrl() {
      try {
        const response = await LibraryAPI.encodeUtmSettings({
          brandId: this.authStore.currentBrand.id,
          channel: utmChannel.INSTAGRAM_STORY,
          content: this.newStoryLink,
          mediaId: this.media[0]?.id,
          productId: this.selectedProductId || undefined,
          shortenUrl: false,
          urls: [this.newStoryLink],
        });
        return response.data.content;
      } catch (e) {
        logger.error(`Error while encoding url: ${e}`);
        this.notificationStore.setToast({
          message: encodeUtmErrorMessage,
          type: 'error',
        });
        return null;
      }
    },
    async saveStoryLink() {
      this.shortUrlStore.shortURL = null;
      this.showTrackableLink = false;

      // if the user edited the UTMs, we want to save the updatedStoryLink
      this.newStoryLink = this.updatedStoryLink || this.newStoryLink;
      this.newStoryLink = this.newStoryLink.trim();

      if (this.newStoryLink !== '' && !/^https?:\/\//.test(this.newStoryLink)) {
        this.newStoryLink = `https://${this.newStoryLink}`;
      }

      const encodedUrl = await this.getEncodedUrl();
      this.storyLink = encodedUrl || null;

      // Remove the old short url since a new one is added.
      this.shortUrlStore.shortURL = null;
      this.currentScreen = 'index';
      this.switchSubScreen(this.currentScreen);
      this.closeUtmEditorPanel();
    },
    resetStoryLink() {
      this.storyLink = null;
      // Remove the old short url since the original link is removed.
      this.shortUrlStore.shortURL = null;
      this.showTrackableLink = false;
    },
    checkUnsavedStatus() {
      return checkUnsavedChanges(this.postData, this.post, this.scheduledTime);
    },
    async onRemoveClick() {
      const { title, message } = popUpMessages.removeFromScheduler;
      await this.notificationStore.confirm(title, message, {
        confirmAlias: 'Remove',
        onConfirm: this.remove,
      });
    },
    async remove() {
      await this.schedulerStore.deletePost(this.post);
      if (this.scheduledTime) {
        this.notificationStore.setToast({ message: 'Post removed from your timeline.' });
      } else {
        this.notificationStore.setToast({
          message: 'Post removed from your unscheduled posts.',
        });
      }
      this.close();
    },
    async save(closeAfter = true) {
      if (this.saveDisabled || this.schedulerStore.postActionPending) {
        return;
      }
      if (this.publishDateError) {
        return;
      }
      this.isSaving = true;
      // Make sure we only save the generated url when it's enabled and the original
      // storyLink is not empty.
      if (this.storyLink && this.shortUrlStore.shortURL && this.showTrackableLink) {
        this.storyLink = this.shortUrlStore.shortURL.shortUrl;
      }
      if (this.post?.id) {
        // Take value before updating post (as it then updates this.post)
        const timestampAddedOrRemoved = this.timestampAddedOrRemoved;
        await this.schedulerStore.updatePost(this.postData);
        if (timestampAddedOrRemoved) {
          if (this.scheduledTime) {
            this.notificationStore.setToast({
              message: 'Post updated & added to your timeline.',
            });
          } else {
            this.notificationStore.setToast({
              message: 'Post updated and added to your ',
              actionText: 'unscheduled posts.',
              actionTo: {
                name: getUnscheduledRouteName(PLATFORMS.INSTAGRAM),
              },
              sameLine: true,
            });
          }
        } else {
          this.notificationStore.setToast({ message: 'Post updated.' });
        }
      } else {
        await this.schedulerStore.createPost(this.postData);
        if (this.scheduledTime) {
          this.notificationStore.setToast({ message: 'Post added to your timeline.' });
        } else {
          this.notificationStore.setToast({
            message: 'Post added to your ',
            actionText: 'unscheduled posts.',
            actionTo: {
              name: getUnscheduledRouteName(PLATFORMS.INSTAGRAM),
            },
            sameLine: true,
          });
        }
      }

      this.isSaving = false;
      if (closeAfter) {
        this.close();
      }
    },
    async onCalendarFetch(start, end) {
      this.schedulerStore.fetchPosts(this.brandInstagramPosts.betweenDates(start, end));
    },
    resetAddTo() {
      this.selectedGalleries = [];
    },
    isTrackableLink() {
      const domains = env.shortURLDomains.map(escapeRegExp).join('|');
      const shortURLRegex = `https://(?:[^.]*\\.)?(?:${domains})/(.*)`;
      return this.storyLink?.match(shortURLRegex) ?? null;
    },
    async fetchStoryLinkURL() {
      if (this.post?.story_link) {
        const matches = this.isTrackableLink();
        if (matches) {
          this.showTrackableLink = true;
          this.shortURLLoading = true;
          try {
            const response = await LibraryAPI.decodeUtmSettings({
              brandId: this.currentBrand.id,
              content: this.storyLink,
              channel: this.instagramStoryUtmChannel,
              urls: [this.storyLink],
            });
            this.shortUrlStore.shortURL = {
              url: response.data.original_content,
              data: response.data.original_content.split('/').pop(),
              clicks: 0,
            };

            this.shortUrlStore.shortURL.shortUrl = this.post.story_link;
            this.storyLink = this.shortUrlStore.shortURL.url;
          } catch (error) {
            this.storyLink = 'Could not load original link';
            this.originalLinkUnloaded = true;
            this.shortUrlStore.shortURL = {
              shortUrl: this.post.story_link,
            };
            throw error;
          } finally {
            this.shortURLLoading = false;
          }
        } else {
          this.storyLink = this.post.story_link;
        }
      } else {
        this.storyLink = null;
      }
    },
    cancelConnectionPopup() {
      this.autoPublish = false;
      this.schedulerStore.setAutoPublish(this.autoPublish);
    },
    openUtmEditorPanel(userInitiated = false) {
      if (this.showComments) {
        this.closeCommentPanel();
      }
      if (!this.showUtmEditorPanel) {
        this.showUtmEditorPanel = true;
        trackSchedulerUtmPopout({
          ...this.trackingData,
          action: userInitiated ? 'open' : 'auto open',
        });
      }
    },
    closeUtmEditorPanel(userInitiated = false) {
      if (this.showUtmEditorPanel) {
        this.showUtmEditorPanel = false;
        trackSchedulerUtmPopout({
          ...this.trackingData,
          action: userInitiated ? 'close' : 'auto close',
        });
      }
    },
    updateUtmEditorNewUrl(updatedUrl, trackingParameters, urlLinkCustomized) {
      this.updatedStoryLink = updatedUrl;
      this.urlLinkCustomized = urlLinkCustomized;
    },
    closeCommentPanel() {
      this.$router.push({
        name: 'scheduler.instagram.posts',
        params: { id: this.post?.id, post: this.post },
        query: { replaceModal: true },
      });
    },
    requiresApproval(post) {
      return postRequiresApproval({ post });
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.section-title {
  display: flex;
  align-items: center;
}

.controls {
  position: sticky;
  bottom: 0;
}

.button-section {
  width: 100%;
  display: flex;
  justify-content: center;

  .wide {
    min-width: 10rem;
  }
}

.subscreen {
  height: 100%;
  position: relative;
}

.bottom-control {
  margin: var(--space-24) auto var(--space-8);
  padding: 0 var(--space-80) var(--space-4);
}

.nav {
  display: flex;
  align-items: center;
  font-size: var(--x18);
  font-weight: var(--font-medium);
  margin: 0;

  .back {
    background: var(--background-300);
    width: var(--space-40);
    height: var(--space-32);
    display: flex;
    border: solid 1px var(--border);
    border-radius: var(--round-corner-small);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: var(--space-16);
    transition: var(--transition-all);

    &:hover {
      transform: var(--hover-move);
    }
  }
}

.add-top-margin {
  margin-top: var(--space-32);
}

.empty-control {
  display: flex;
  justify-content: flex-start;
}

.add-to-content {
  height: 14.5rem;
}

.input-row {
  display: flex;
  margin: var(--space-8) 0;
  justify-content: flex-start;
  width: 100%;

  input {
    width: 100%;
    border: 1px solid var(--border);
    height: var(--space-40);
    line-height: var(--space-32);
    border-radius: var(--round-corner-small) 0 0 var(--round-corner-small);
    background: var(--background-0);
    color: var(--text-primary);
    font-size: var(--x14);
    padding: 0 var(--space-8);
    text-overflow: ellipsis;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--space-40);
    height: var(--space-40);
    border: 1px solid var(--border);
    background: var(--background-0);
    cursor: pointer;
    border-left: none;
    border-radius: 0 var(--round-corner-small) var(--round-corner-small) 0;
    margin: 0;

    &:hover {
      color: var(--action-500);
    }
  }
}

.trackable-link {
  width: 100%;
}

.trackable-link-with-margin {
  margin-bottom: var(--space-16);
}

.shortURL-loader {
  margin: auto;
  height: 56px;
}

.validation-error {
  margin-top: var(--space-16);
}

.save-button {
  min-width: 10rem;
}
</style>
