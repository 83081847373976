<template>
  <main>
    <header @click.prevent="toggleDropdown">
      <span class="stat-name-total" :class="{ clickable: insight.subMetrics }">
        <span>{{ insight.label }}</span>
        <InfoTooltip v-if="insight.tooltip" :tooltip="insight.tooltip" />
        <Icon
          v-if="insight.subMetrics"
          :class="['dropdown-toggle', { open: dropdownOpen }]"
          name="caret"
          xxxsmall
        />
      </span>
      <span class="stat stat-total">{{ formatValue(insight) }}</span>
    </header>
    <ul :class="['insights-container', 'insights-stats', { open: dropdownOpen }]">
      <li v-for="subMetric in insight.subMetrics" :key="subMetric">
        <span class="stat-name indented">{{ subMetric.label }}</span>
        <span class="stat">{{ formatValue(subMetric) }}</span>
      </li>
    </ul>
  </main>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import { formatMetric } from '@/utils/formatters';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'PromotedInsightsDropdown',
  components: { Icon, InfoTooltip },
  props: {
    insight: { type: Object, required: true },
  },
  data() {
    return {
      dropdownOpen: true,
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    formatValue(insight) {
      return formatMetric(insight);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.indented {
  padding-left: var(--space-30);
}

main {
  header::before {
    width: 0;
    white-space: nowrap;
    content: '--------------------------------------------------------------------------------';
    color: var(--border);
  }

  .clickable {
    cursor: pointer;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    font-size: var(--x14);
    color: var(--text-primary);
    margin: var(--space-8) var(--space-8) 0 var(--space-32);
    padding-left: var(--space-4);
    overflow: hidden;
    line-height: var(--space-30);

    .stat-name-total {
      display: flex;
      align-items: center;
      gap: var(--space-8);
      position: absolute;
      background: var(--background-300);
      padding-right: var(--space-8);

      .svg-icon {
        margin: 0;
      }
    }

    .stat-total {
      color: var(--action-500);
    }

    .dropdown-toggle {
      transition: var(--transition-all);

      &.open {
        transform: rotate(90deg);
      }
    }
  }

  .insights-container {
    visibility: hidden;
    max-height: 0;
    opacity: 0;
    transition: all 0.3s;
    text-align: center;

    &.open {
      visibility: visible;
      height: auto;
      max-height: 20rem;
      opacity: 1;
    }
  }

  .insights-list {
    ul {
      margin: 0 var(--space-8) 0 var(--space-32);
      padding-left: var(--space-4);

      li {
        display: flex;
        justify-content: space-between;
        margin: var(--space-8) 0;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        span {
          display: flex;
          align-items: center;

          svg {
            margin-left: var(--space-8);
          }
        }

        .url {
          text-decoration: underline;
        }

        .link-wrapper {
          color: var(--action-500);
          display: block;
          margin-top: var(--space-8);
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .link-wrapper a {
          color: var(--action-500);

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .insights-stats {
      li::before {
        float: left;
        width: 0;
        white-space: nowrap;
        content: '--------------------------------------------------------------------------------';
        color: var(--border);
      }

      li {
        font-size: var(--x12);
        overflow: hidden;
        color: var(--text-secondary);
      }
    }
  }

  .hide {
    display: none;
  }

  .stat-name {
    padding-right: var(--space-8);
    color: var(--text-secondary);
    background: var(--background-300);
    position: absolute;
  }

  .stat {
    padding-left: var(--space-8);
    background: var(--background-300);
  }
}
</style>
