<template>
  <div class="outage">
    <img :src="IMG.emptyField" title="We're on it!" />
    <h3>Oops, Something Went Wrong</h3>
    <p>
      We are currently experiencing a temporary issue with the Dash Hudson Platform. We hope to have
      this resolved shortly. Please check back later.
    </p>
    <br />
    <p>Reach out to your Customer Success Representative with any questions.</p>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { IMG } from '@/assets';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'PlatformOutage',
  computed: {
    IMG() {
      return IMG;
    },
  },
});
export default comp;
</script>

<style lang="postcss">
.outage {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 120px auto;
  padding: var(--space-12);

  h3 {
    margin: var(--space-40) 0 var(--space-24);
  }

  p {
    font-size: var(--x18);
    line-height: var(--space-24);
    text-align: center;
  }

  .home {
    text-transform: uppercase;
  }

  img {
    height: 160px;
    width: 160px;
  }
}

@media (max-width: 500px) {
  .outage {
    margin: 100px auto;

    img {
      width: 10rem;
      height: auto;
    }

    h3 {
      font-size: var(--x20);
    }

    p {
      font-size: var(--x14);
      text-align: center;
    }
  }
}
</style>
