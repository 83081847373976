import {
  A_RATIOS,
  validationTypes,
  groupValidationTypes,
  validationMessageTemplates,
  validationMessagePlaceholders,
  validationMessageStyle,
} from '@/app/scheduler/constants/mediaValidationConsts';
import { toolTips } from '@/config';
import { ValidationError } from '@/app/scheduler/utils/errors';

export class MediaPropertyRequiredError extends ValidationError {
  constructor(property) {
    super(toolTips.mediaConversionError);
    this.name = 'MediaPropertyRequiredError';
    this.property = property;
  }
}

export const getMediaDataForValidation = (media) => {
  const mediaData =
    media.fullMediaObject?.sizes?.originalConverted || media.sizes?.originalConverted;

  if (!mediaData) {
    throw new MediaPropertyRequiredError('originalConverted');
  }

  return {
    aspectRatio: (mediaData.width / mediaData.height).toFixed(5),
    duration: media?.duration || media?.fullMediaObject?.duration || media?.video?.duration || null,
    frameRate:
      media?.frameRate || media?.fullMediaObject?.frameRate || media?.video?.frameRate || null,
    height: mediaData?.height,
    width: mediaData?.width,
    size: mediaData?.size,
    pixels: mediaData.height * mediaData.width,
    type: media?.type,
  };
};

export const formatDuration = (durationSeconds) => {
  const mins = Math.floor(durationSeconds / 60);
  const seconds = durationSeconds - mins * 60;
  const plur = mins > 1 ? 's' : '';
  if (mins > 0) {
    return seconds > 0 ? `${mins} minute${plur} ${seconds} seconds` : `${mins} minute${plur}`;
  }
  return `${seconds} seconds`;
};

export const formatAspectRatio = (decimalVal) => {
  return Object.values(A_RATIOS).find((x) => x.val === decimalVal).str;
};

export const formatFileSize = (sizeBytes) => {
  const k = 1024;
  const mb = k ** 2;
  const gb = mb * k;

  if (sizeBytes >= gb) return `${(sizeBytes / gb).toFixed(0)}GB`;
  if (sizeBytes >= mb) return `${(sizeBytes / mb).toFixed(0)}MB`;
  if (sizeBytes >= k) return `${(sizeBytes / k).toFixed(0)}KB`;
  return `${sizeBytes} bytes`;
};

export const getValidationRuleType = (upperBound, lowerBound) => {
  if (upperBound && lowerBound) return validationMessageStyle.DUAL_BOUND;

  if (upperBound) return validationMessageStyle.SINGLE_BOUND_UPPER;

  if (lowerBound) return validationMessageStyle.SINGLE_BOUND_LOWER;

  return validationMessageStyle.GENERAL;
};

export const validationMessageDataFormatters = {
  [validationTypes.SIZE]: (fileSize) => formatFileSize(fileSize),
  [validationTypes.WIDTH]: (width) => width,
  [validationTypes.HEIGHT]: (height) => height,
  [validationTypes.PIXELS]: (pixel) => pixel,
  [validationTypes.ASPECT_RATIO]: (ratio) => formatAspectRatio(ratio),
  [validationTypes.DURATION]: (duration) => formatDuration(duration),
  [validationTypes.FRAME_RATE]: (frameRate) => frameRate,
  [validationTypes.ALLOW_TYPES]: (allowTypes) => allowTypes[0].toLowerCase(),
  [groupValidationTypes.MAX_COUNT]: (maxVal) => maxVal,
  [groupValidationTypes.MAX_IMAGE_COUNT]: (maxVal) => maxVal,
  [groupValidationTypes.MAX_VIDEO_COUNT]: (maxVal) => maxVal,
};

export function getValidationErrorMessage({
  messageType,
  validationType,
  upperBound,
  lowerBound,
  allowType,
  mediaType = 'media',
}) {
  const dataFormatter = validationMessageDataFormatters[validationType];
  let message =
    validationMessageTemplates[getValidationRuleType(upperBound, lowerBound)][messageType][
      validationType
    ];

  if (upperBound === lowerBound) {
    const multiBound = `between ${validationMessagePlaceholders.LOWER_BOUND} and `;
    message = message.replace(multiBound, '');
    message = message.replace('ratios', 'ratio');
  }

  message = upperBound
    ? message.replace(validationMessagePlaceholders.UPPER_BOUND, dataFormatter(upperBound))
    : message;
  message = lowerBound
    ? message.replace(validationMessagePlaceholders.LOWER_BOUND, dataFormatter(lowerBound))
    : message;
  message = allowType
    ? message.replace(validationMessagePlaceholders.ALLOW_TYPE, dataFormatter(allowType))
    : message;

  message = message.replace(
    validationMessagePlaceholders.MEDIA_TYPE,
    mediaType === 'media' ? mediaType : `${mediaType.toLowerCase()}s`,
  );

  return message;
}
