<template>
  <div
    class="assistant-bubble flex h-fit flex-col items-start justify-between gap-3 rounded-br-2xl rounded-tl-2xl rounded-tr-2xl bg-white bg-opacity-60 px-7 py-6"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'AssistantStyleWrapper',
};
</script>
