<template>
  <Icon
    v-if="icon"
    v-tooltip="{ content: icon.tooltip, boundary: 'viewport' }"
    :name="icon.name"
    :color="icon.color"
    :xsmall="xsmall"
    :small="!xsmall"
  />
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';
import { getPostStatusMessage, STATUS_TYPE } from '@/app/scheduler/utils/post-status';
import { exhaustiveMap } from '@/utils';

export const ICON_COLOR = exhaustiveMap(STATUS_TYPE, {
  danger: colours.ERROR.ERROR_500,
  warning: colours.ALERT.ALERT_500,
  success: colours.SUCCESS.SUCCESS_500,
});

export const ICON_NAME = exhaustiveMap(STATUS_TYPE, {
  danger: 'alertTriangle',
  warning: 'alertCircle',
  success: 'checkCircle',
});

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'PostStatusIcon',
  components: { Icon },
  props: {
    /**
     * The scheduled post for which a status will be shown
     */
    post: { type: Object, default: null },
    /**
     * Should warning icons be shown
     */
    showWarning: { type: Boolean, default: false },
    /**
     * Should success icons be shown
     */
    showSuccess: { type: Boolean, default: false },
    /**
     * A warning in addition to post status warnings.
     */
    additionalWarning: { type: [String, Boolean], default: null },
    /**
     * An error in addition to post status errors.
     */
    additionalError: { type: [String, Boolean], default: null },
    /**
     * Use extra-small icon
     */
    xsmall: { type: Boolean, default: false },
    /**
     * Take post replies into consideration when computing status
     */
    asThread: { type: Boolean, required: true },
    /**
     * Is icon being shown inside a post editor
     */
    inEditor: { type: Boolean, default: false },
  },
  computed: {
    messageByType() {
      const postStatus =
        this.post &&
        getPostStatusMessage(this.post, {
          asThread: this.asThread,
          inEditor: this.inEditor,
        });
      const postStatusMessageByType = Object.fromEntries(
        Object.keys(STATUS_TYPE).map((t) => [t, postStatus?.type === t && postStatus.message]),
      );
      return exhaustiveMap(STATUS_TYPE, {
        danger: this.additionalError || postStatusMessageByType.danger,
        warning: this.additionalWarning || postStatusMessageByType.warning,
        success: postStatusMessageByType.success,
      });
    },
    type() {
      const showType = exhaustiveMap(STATUS_TYPE, {
        danger: true,
        warning: this.showWarning,
        success: this.showSuccess,
      });
      return Object.keys(showType).filter((t) => showType[t] && this.messageByType[t])[0];
    },
    icon() {
      return (
        this.type && {
          color: ICON_COLOR[this.type],
          name: ICON_NAME[this.type],
          tooltip: this.messageByType[this.type],
        }
      );
    },
  },
});
export default comp;
</script>
