<template>
  <div class="pin-card-container" @click="pinClicked">
    <div :class="{ selected: selected }" />
    <div class="pin">
      <div class="media-container">
        <RadialProgressBar
          v-if="pinUploading"
          :diameter="100"
          :completed-steps="pin.uploadProgress"
          :total-steps="100"
          start-color="white"
          stop-color="white"
          inner-stroke-color="lightGrey"
          class="center"
        />

        <InfiniteLoader v-if="pin.uploadStatus && pin.uploadStatus === UPLOAD_STATUS.PROCESSING" />

        <video v-if="isVideo" ref="video" :src="fullSizeMedia" class="pin-media" />
        <img v-else :src="fullSizeMedia" class="pin-media" />
        <div class="badges-bg" />
        <div v-if="isVideo" class="video-duration">
          <Icon name="video-2" xxsmall color="white" />
          <span class="video-duration-text">{{ videoDuration }}</span>
        </div>
        <div class="mask">
          <div v-if="isVideo" class="video-action" @click="videoClicked">
            <Icon v-if="!videoPlaying" xlarge color="white" class="play-icon" name="play" />
            <Icon v-else xlarge color="white" name="pause" />
          </div>

          <div ref="removeButton" class="pin-icon remove" @click="removePin">
            <Icon :hover-color="iconHoverColor" name="bin" color="white" xsmall />
          </div>
          <div v-if="showCropButton" class="pin-icon crop" @click="cropClicked">
            <Icon :hover-color="iconHoverColor" xsmall color="white" name="crop"></Icon>
          </div>
        </div>
        <div class="pin-footer">
          <div v-if="pin.linkUrl" class="pin-icon link" @click="focusPinWebLink">
            <Icon :hover-color="iconHoverColor" xsmall color="white" name="link" />
          </div>
          <div v-if="error" class="error">
            <div class="message">
              <Icon class="left" xsmall color="white" name="info" />
              <p>{{ error.message }}</p>
            </div>
            <div v-if="error.action" class="actions">
              <div class="primary" @click="performAction(error.action[0].actionName)">
                {{ error.action[0].label }}
              </div>
              <div v-if="error.action.length > 1">
                {{ error.action[1].label }}
              </div>
            </div>
          </div>
          <div v-else-if="mediaValidationError" class="error media-error">
            <div class="message">
              <Icon class="left" xsmall color="white" name="info" />
              <p>{{ mediaErrorMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-if="pin.title || pin.description" v-line-clamp="2">
      {{ pin.title || pin.description }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import InfiniteLoader from '@/components/InfiniteLoader.vue';
import RadialProgressBar from '@/vendor/vue-radial-progress/RadialProgressBar.vue';
import Icon from '@/components/foundation/Icon.vue';
import { toolTips, UPLOAD_STATUS } from '@/config';
import { colours } from '@/ux/colours';
import { mediaSizeIsCroppable } from '@/utils/media';
import { validatePinterestMedia, pinBoardAndTitleCheck } from '@/app/scheduler/utils';
import { formatTime } from '@/utils/formatters';
import { useSchedulerPinterestStore } from '@/stores/scheduler-pinterest';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'PinWaterfallItem',
  components: {
    Icon,
    InfiniteLoader,
    RadialProgressBar,
  },
  props: {
    cropClicked: { type: Function, default: () => {} },
    pin: { type: Object, default: () => {} },
  },
  emits: ['focusPinWebLink', 'remove'],
  data() {
    return {
      ...{ UPLOAD_STATUS },
      error: null,
      mediaOversize: false,
      videoPlaying: false,
      iconHoverColor: colours.ACTION.ACTION_500,
      mediaValidationError: null,
    };
  },
  computed: {
    ...mapStores(useSchedulerPinterestStore, useAuthStore),
    showCropButton() {
      return !this.pinUploading && this.mediaIsCroppable;
    },
    pinUploading() {
      return (
        Boolean(this.pin.uploadStatus) &&
        this.pin.uploadStatus !== UPLOAD_STATUS.SUCCESS &&
        this.pin.uploadStatus !== UPLOAD_STATUS.PROCESSING
      );
    },
    fullSizeMedia() {
      const pinMediaObject = this.pin.fullMediaObject || this.pin;
      if (pinMediaObject.sizes) {
        // Media V2
        return pinMediaObject.sizes.originalConverted
          ? pinMediaObject.sizes.originalConverted.url
          : pinMediaObject.sizes.original.url;
      }
      // Uploading media
      return pinMediaObject.url;
    },
    selected() {
      if (this.schedulerPinterestStore.selectedPin) {
        return this.schedulerPinterestStore.selectedPin.id === this.pin.id;
      }
      return false;
    },
    isVideo() {
      return this.pin.type === 'VIDEO';
    },
    videoDuration() {
      if (this.isVideo && this.pin.duration) {
        return formatTime(this.pin.duration);
      }
      return null;
    },
    mediaErrorMessage() {
      this.validateMedia();
      if (this.mediaValidationError) {
        return this.mediaValidationError;
      }
      return null;
    },
  },
  watch: {
    'schedulerPinterestStore.addToSameBoardSetting': {
      handler() {
        this.formatCheck();
      },
    },
    'schedulerPinterestStore.updatePinTrigger': {
      handler() {
        this.formatCheck();
        this.$forceUpdate();
      },
    },
    pin: {
      deep: true,
      handler(to) {
        if (to.uploadStatus === UPLOAD_STATUS.SUCCESS) {
          this.validateMedia();
        }
      },
    },
  },
  mounted() {
    this.formatCheck();
    this.validateMedia();

    if (this.pin.thumbOffset) {
      const { video } = this.$refs;
      video.currentTime = this.pin.thumbOffset;
    }
  },
  methods: {
    formatCheck() {
      let error = null;
      const { publishType, pinterestBoard, invalidLink, linkUrl, title, scheduledTime } = this.pin;

      if (this.mediaErrorMessage) {
        error = {
          message: this.mediaErrorMessage,
          action: [{ label: 'Remove', actionName: 'removePin' }],
        };
      }

      // Check Pinterest Board and Pin Title.
      if (scheduledTime && publishType === 'autoPublish') {
        const noBoard =
          this.schedulerPinterestStore.addToSameBoardSetting &&
          this.schedulerPinterestStore.addToSameBoardSetting.on === true &&
          !pinterestBoard;
        const errMsg = pinBoardAndTitleCheck(noBoard, !title);
        if (errMsg) {
          error = { message: errMsg };
        }
      }

      // check invalid link
      if (invalidLink) {
        if (linkUrl.length > 2000) {
          error = { message: toolTips.invalidLinkLength };
        } else {
          error = { message: toolTips.invalidLinkURL };
        }
      }
      this.error = error;
    },
    async validateMedia() {
      const isAbbrevMessage = true;
      this.mediaValidationError = await validatePinterestMedia(
        this.pin,
        this.authStore.currentBrand.id,
        isAbbrevMessage,
      );
    },
    mediaIsCroppable() {
      mediaSizeIsCroppable(this.pin);
    },
    performAction(actionName) {
      this[actionName]();
    },
    pinClicked(el) {
      const ignoreTagNames = ['path', 'svg'];
      if (
        el.target.classList.contains('pin-icon') ||
        ignoreTagNames.indexOf(el.target.tagName) > -1
      ) {
        return;
      }
      // disable select pins before uploading process finished.
      if (
        this.pin &&
        ((this.pin.uploadStatus && this.pin.uploadStatus !== UPLOAD_STATUS.SUCCESS) || !this.pin.id)
      ) {
        return;
      }
      this.schedulerPinterestStore.setSelectedPin({ pin: this.selected ? null : this.pin });
    },
    videoClicked() {
      const { video } = this.$refs;
      if (video.paused) {
        this.videoPlaying = true;
        video.play();
      } else {
        this.videoPlaying = false;
        video.pause();
      }
      video.onended = () => {
        this.videoPlaying = false;
      };
    },
    focusPinWebLink() {
      this.$emit('focusPinWebLink', this.pin);
    },
    removePin() {
      this.$emit('remove', this.pin);
      if (this.schedulerPinterestStore.selectedPin) {
        if (this.schedulerPinterestStore.selectedPin.id === this.pin.id) {
          this.schedulerPinterestStore.setSelectedPin({ pin: null });
        }
      }
      this.mediaValidationError = null;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.pin-card-container {
  position: relative;
  width: 230px;
  padding: 5px 0;
  cursor: pointer;

  .pin-icon {
    position: absolute;
    width: var(--space-32);
    height: var(--space-32);
    background-color: rgb(0 0 0 / 50%);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .pin {
    position: relative;

    .media-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .center {
        position: absolute;
        width: 100% !important;
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .link {
        right: var(--space-8);
        transform: translateY(calc(-1 * var(--space-40)));
      }

      .video {
        left: var(--space-8);
        transform: translateY(calc(-1 * var(--space-40)));
      }

      .mask {
        display: none;
        width: 220px;
        height: 100%;
        position: absolute;
        align-items: center;
        justify-content: center;

        .crop {
          top: var(--space-8);
          left: var(--space-8);
        }

        .remove {
          left: var(--space-8);
          bottom: var(--space-8);
        }

        .video-action {
          height: var(--space-40);
          width: var(--space-40);
        }
      }

      video.pin-media,
      img.pin-media {
        width: 220px;
        height: auto;
        min-height: 200px;
        object-fit: contain;
        background-color: var(--background-500);
        border-radius: var(--round-corner-small);
      }

      .loader {
        position: absolute;
        margin: 0;
        width: 80%;
      }

      .pin-footer {
        position: absolute;
        bottom: 0;
        left: 5px;
        right: 5px;
        width: 220px;
      }
    }
  }

  p {
    padding: var(--space-8) var(--space-12);
    line-height: var(--space-16);
    font-size: var(--x12);
    text-align: left;
    max-height: var(--space-40);
  }

  .error {
    background-color: var(--error-400);
    border-radius: 0 0 var(--round-corner-small) var(--round-corner-small);
    padding: var(--space-12);

    .message {
      display: flex;
    }

    .actions {
      color: white;
      font-size: var(--x13);
      font-weight: var(--font-medium);
      padding: var(--space-8) var(--space-24) 0;
      cursor: pointer;
      text-decoration: underline;
    }

    p {
      color: white;
      width: 170px;
      padding: 0;
      margin-left: var(--space-8);
    }
  }

  .media-error {
    padding: var(--space-12) var(--space-12) var(--space-38) var(--space-12);
  }

  &:hover {
    .pin {
      .media-container {
        .mask {
          display: flex;
          border-radius: var(--round-corner-small);
          background-color: rgb(0 0 0 / 30%);
        }
      }
    }
  }
}

.selected {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: 3px solid var(--action-500);
  border-radius: var(--round-corner);
}

.badges-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 220px;
  height: auto;
  min-height: 200px;
  background: var(--tile-bg-gradient);
  border-radius: var(--round-corner);
}

.video-duration {
  position: absolute;
  bottom: 10px;
  right: 12px;

  .icon {
    position: relative;
    top: 2px;
  }

  .video-duration-text {
    padding-left: var(--space-4);
    color: var(--white);
    font-size: var(--x12);
    font-weight: var(--font-medium);
    line-height: var(--space-18);
  }
}
</style>
