<template>
  <Popup :close="() => $emit('close')" type="xxsmall">
    <div class="popup-container">
      <div class="popup-title">{{ title }}</div>
      <ul class="statistic-total-container">
        <li>
          <span class="stats-label">{{ statisticLabel }}</span>
          <span class="stats-value">{{ formatStatValue(statistic.value) }}</span>
        </li>
      </ul>
      <ul class="breakdown-container">
        <li v-for="(stat, index) in statistic.statisticBreakdown" :key="index">
          <span class="stat-name">{{ stat.label }}</span>
          <span class="stat">{{ formatStatValue(stat.value) }}</span>
        </li>
      </ul>
    </div>
  </Popup>
</template>

<script>
import { defineComponent } from 'vue';
import startCase from 'lodash/startCase';
import Popup from '@/components/Popup.vue';
import { formatMetric } from '@/utils/formatters';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'AudienceStatisticBreakdownPopup',
  components: { Popup },
  props: {
    popupTitle: { type: String, required: true },
    statistic: { type: Object, required: true },
    format: { type: String, default: 'number_with_commas' },
  },
  emits: ['close'],
  computed: {
    title() {
      return startCase(this.popupTitle);
    },
    statisticLabel() {
      return startCase(this.statistic.altStatName);
    },
  },
  methods: {
    formatStatValue(value) {
      return formatMetric({ format: this.format, value });
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.popup-container {
  padding: var(--space-32);
  text-align: left;
}

.popup-title {
  font-size: var(--x18);
  margin-bottom: var(--space-32);
}

.statistic-total-container {
  line-height: 1;
  margin-bottom: var(--space-16);
  overflow: hidden;

  .stats-label {
    height: var(--x15);
    float: left;
    margin-bottom: var(--space-4);
    padding-right: var(--space-8);
  }

  .stats-value {
    height: var(--x15);
    color: var(--action-500);
    line-height: 1;
  }
}

.breakdown-container {
  width: 100%;
  padding-left: var(--space-24);
  height: auto;
  max-height: 100rem;
  line-height: var(--space-24);

  .stat-name {
    padding-right: var(--space-8);
  }

  .stat {
    color: var(--action-500);
  }
}

li::before {
  float: left;
  width: 0;
  white-space: nowrap;
  content: '--------------------------------------------------------------------------------';
  color: var(--border);
}

li {
  overflow: hidden;

  span + span {
    float: right;
    padding-left: var(--space-8);
    background: var(--background-0);
  }

  span:first-child {
    position: absolute;
    background: var(--background-0);
  }
}
</style>
