<template>
  <ConfirmDialog
    v-if="show"
    :title="title"
    :message="message"
    :confirm-alias="confirmAlias"
    :confirm-type="confirmType"
    :cancel-alias="cancelAlias"
    :pending="pending"
    :uncancellable="uncancellable"
    :larger-text="largerText"
    :platform="platform"
    @confirm="onConfirm"
    @cancel="onCancel"
  />
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useNotificationStore } from '@/stores/notification';
import ConfirmDialog from '@/components/core/dialogs/ConfirmDialog.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      pending: false,
    };
  },
  computed: {
    ...mapStores(useNotificationStore),
    show() {
      return this.notificationStore.confirmMessage.show;
    },
    title() {
      return this.notificationStore.confirmMessage.title;
    },
    message() {
      return this.notificationStore.confirmMessage.message;
    },
    confirmAlias() {
      return this.notificationStore.confirmMessage.confirmAlias;
    },
    confirmType() {
      return this.notificationStore.confirmMessage.confirmType;
    },
    cancelAlias() {
      return this.notificationStore.confirmMessage.cancelAlias;
    },
    uncancellable() {
      return this.notificationStore.confirmMessage.uncancellable;
    },
    largerText() {
      return this.notificationStore.confirmMessage.largerText;
    },
    platform() {
      return this.notificationStore.confirmMessage.platform;
    },
  },
  methods: {
    async onConfirm() {
      this.pending = true;
      try {
        await this.notificationStore.confirmMessage.confirmCallback();
        this.notificationStore.confirmMessage.resolve(true);
      } catch (error) {
        this.notificationStore.confirmMessage.reject(error);
      } finally {
        this.notificationStore.confirmMessage.show = false;
        this.pending = false;
      }
    },
    async onCancel() {
      this.pending = true;
      try {
        await this.notificationStore.confirmMessage.cancelCallback();
        this.notificationStore.confirmMessage.resolve(false);
      } catch (error) {
        this.notificationStore.confirmMessage.reject(error);
      } finally {
        this.notificationStore.confirmMessage.show = false;
        this.pending = false;
      }
    },
  },
});
export default comp;
</script>
