import dayjs from 'dayjs';
import pinia from '@/stores/pinia';
import { useIdentityStore } from '@/stores/identity';

export function guessTimezone() {
  const guess = dayjs?.tz?.guess();
  // Make sure the timezone is valid (e.g. not Etc/Undefined)
  try {
    dayjs().tz(guess);
    return guess;
  } catch {
    return undefined;
  }
}

export function getTimezoneNames() {
  return Intl.supportedValuesOf('timeZone') || [];
}

export function getUserTimezone() {
  const identityStore = useIdentityStore(pinia);
  const userTimeZone =
    identityStore.identity?.timeZoneName || identityStore.identity?.time_zone_name;
  return userTimeZone || guessTimezone();
}
