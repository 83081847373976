<template>
  <div v-if="showSpinner" class="loading-container">
    <CircularLoader />
  </div>
  <div v-else class="media-actions disable-toggle">
    <a
      v-tooltip="mediaItem.hidden ? hiddenProductTooltip : visibleProductTooltip"
      class="media-action-button"
      @click="handleMediaItemHidden"
    >
      <Icon v-if="!mediaItem.hidden" :color="iconColor" xsmall name="hide" />
      <Icon v-else :color="iconColor" xsmall name="eyeOpen" />
    </a>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useProductStore } from '@/stores/product';
import { useNotificationStore } from '@/stores/notification';
import { toolTips } from '@/config';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import CircularLoader from '@/components/CircularLoader.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ProductTile',
  components: {
    CircularLoader,
    Icon,
  },
  props: { mediaItem: { type: Object, default: null } },
  data() {
    return {
      showSpinner: false,
      iconColor: colours.BASIC.WHITE,
      hiddenProductTooltip: toolTips.showProductMedia,
      visibleProductTooltip: toolTips.hideProductMedia,
    };
  },
  computed: {
    ...mapStores(useNotificationStore, useProductStore, useTrackingStore),
    ...mapPiniaState(useAuthStore, ['currentBrand']),
    mediaHiddenStatusMessage() {
      return this.mediaItem.hidden
        ? 'Media is hidden from Product Page Gallery.'
        : 'Media is visible on Product Page Gallery.';
    },
    mpHideMediaAction() {
      return this.mediaItem.hidden ? 'PPW Media Turned Off' : 'PPW Media Turned On';
    },
  },
  methods: {
    async handleMediaItemHidden() {
      const mediaId = this.mediaItem.id;
      const productId = this.$route.params.id;
      this.showSpinner = true;
      await this.productStore.setProductMediaHiddenStatus({
        brandId: this.currentBrand.id,
        mediaId,
        productId,
        hideInWidget: !this.mediaItem.hidden,
      });
      // wait until store states is updated to show current hidden status toast message
      this.notificationStore.setToast({ message: this.mediaHiddenStatusMessage });
      this.trackingStore.track(this.mpHideMediaAction, { mediaId, productId });
      this.showSpinner = false;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.loading-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media-actions {
  position: absolute;
  width: 100%;
  bottom: var(--space-4);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 2;

  a {
    flex: 0 0 1.75rem;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: 50%;
    margin: var(--space-4);
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--action-500);
    color: var(--white);

    .svg-icon {
      pointer-events: none;
    }
  }

  a:hover {
    box-shadow: var(--light-shadow);
    transform: scale(1.1, 1.1);
  }
}
</style>
