<template>
  <div class="validation-error">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ValidationError',
});
export default comp;
</script>

<style lang="postcss" scoped>
.validation-error {
  display: flex;
  padding: var(--space-12) var(--space-16);
  border-radius: var(--round-corner-small);
  font-size: var(--x14);
  color: var(--error-500);
  background: var(--error-100);
}
</style>
