import { postTypes, PLATFORMS, fbPostTypes } from './index';

// Detailed Slab doc about validations:
// https://dashhudson.slab.com/posts/scheduler-media-validations-wr26a2si
export const minRecommendedImageWidth = 320;
export const minRecommendedVideoWidth = 600;
export const maxRecommendedImageWidth = 1440;
export const maxRecommendedVideoWidth = 1920;
const facebookPostMinWidth = 120;
export const fbReelMinHeight = 960;
export const fbReelMinWidth = 540;

export const fbMinRecommendedVideoWidth = 600;

export const aspectRatioTolerance = 0.01; // 1%
const IG_DEFAULT_DIMENSION_VIDEO = { max: 1920, min: 0 };

export const REEL_CUSTOM_COVER = 'reelCustomCover';

export const validationTypes = {
  ASPECT_RATIO: 'aspectRatio',
  WIDTH: 'width',
  HEIGHT: 'height',
  SIZE: 'size',
  DURATION: 'duration',
  ALLOW_TYPES: 'allowTypes',
  FRAME_RATE: 'frameRate',
  PIXELS: 'pixels',
};

export const groupValidationTypes = {
  MAX_COUNT: 'maxCount',
  MAX_IMAGE_COUNT: 'maxImageCount',
  MAX_VIDEO_COUNT: 'maxVideoCount',
  MATCH_MEDIA_TYPE: 'matchMediaType',
  MATCH_ASPECT_RATIO: 'matchAspectRatio',
};

export const mediaTypes = {
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
};

export const ERROR_LEVEL = {
  ERROR: 'error',
  WARN: 'warning',
};

export const validationMessageType = {
  ERROR: 'ERROR',
  WARN: 'WARN',
  GROUP_ERROR: 'GROUP_ERROR',
};

export const validationMessagePlaceholders = {
  UPPER_BOUND: '<UPPER_BOUND>',
  LOWER_BOUND: '<LOWER_BOUND>',
  ALLOW_TYPE: '<ALLOW_TYPE>',
  MEDIA_TYPE: '<MEDIA_TYPE>',
};

export const validationMessageStyle = {
  SINGLE_BOUND_LOWER: 'SINGLE_BOUND_LOWER',
  SINGLE_BOUND_UPPER: 'SINGLE_BOUND_UPPER',
  DUAL_BOUND: 'DUAL_BOUND',
  GENERAL: 'GENERAL',
};

export const validationMessageTemplates = {
  [validationMessageStyle.SINGLE_BOUND_LOWER]: {
    [validationMessageType.ERROR]: {
      [validationTypes.WIDTH]: `Selected media does not meet the pixel width requirements for this post type. Ensure media is greater than ${validationMessagePlaceholders.LOWER_BOUND}px wide before publishing.`,
      [validationTypes.HEIGHT]: `Selected media does not meet the pixel height requirements for this post type. Ensure media is greater than ${validationMessagePlaceholders.LOWER_BOUND}px tall before publishing.`,
    },
    [validationMessageType.WARN]: {
      [validationTypes.WIDTH]: `We recommend using ${validationMessagePlaceholders.MEDIA_TYPE} with a width greater than ${validationMessagePlaceholders.LOWER_BOUND}px to avoid any quality issues when publishing.`,
      [validationTypes.HEIGHT]: `We recommend using ${validationMessagePlaceholders.MEDIA_TYPE} with a height greater than ${validationMessagePlaceholders.LOWER_BOUND}px to avoid any quality issues when publishing.`,
    },
  },
  [validationMessageStyle.DUAL_BOUND]: {
    [validationMessageType.ERROR]: {
      [validationTypes.ASPECT_RATIO]: `Selected media does not meet the aspect ratio requirements for this post type. Use the cropping tool to ensure the aspect ratio (width:height) is between ${validationMessagePlaceholders.LOWER_BOUND} and  ${validationMessagePlaceholders.UPPER_BOUND} before publishing.`,
      [validationTypes.DURATION]: `Videos must be between ${validationMessagePlaceholders.LOWER_BOUND} and ${validationMessagePlaceholders.UPPER_BOUND} for this post type. Select different media or use the trimmer to adjust video length before publishing.`,
      [validationTypes.FRAME_RATE]: `This video is not within the required frame rate of ${validationMessagePlaceholders.LOWER_BOUND}-${validationMessagePlaceholders.UPPER_BOUND} fps. Please use media within that range to avoid issues when publishing.`,
      [validationTypes.WIDTH]: `Selected media is not within the required pixel width of ${validationMessagePlaceholders.LOWER_BOUND}px and ${validationMessagePlaceholders.UPPER_BOUND}px. Please use media within that range to avoid issues when publishing.`,
    },
    [validationMessageType.WARN]: {
      [validationTypes.WIDTH]: `Selected media is not within the recommended pixel width of ${validationMessagePlaceholders.LOWER_BOUND}px and ${validationMessagePlaceholders.UPPER_BOUND}px. We recommend using media within this range to avoid any quality issues when publishing.`,
      [validationTypes.HEIGHT]: `Selected media is not within the recommended pixel height of ${validationMessagePlaceholders.LOWER_BOUND}px and ${validationMessagePlaceholders.UPPER_BOUND}px. We recommend using media within this range to avoid any quality issues when publishing.`,
      [validationTypes.ASPECT_RATIO]: `Selected media is outside of the recommended aspect ratios of between ${validationMessagePlaceholders.LOWER_BOUND} and ${validationMessagePlaceholders.UPPER_BOUND} for this post type. We recommend using media within these aspect ratios to avoid any quality issues when publishing.`,
    },
  },
  [validationMessageStyle.SINGLE_BOUND_UPPER]: {
    [validationMessageType.ERROR]: {
      [validationTypes.WIDTH]: `Selected media does not meet the pixel width requirements for this post type. Ensure media is less than ${validationMessagePlaceholders.UPPER_BOUND}px wide before publishing.`,
      [validationTypes.HEIGHT]: `Selected media does not meet the pixel height requirements for this post type. Ensure media is less than ${validationMessagePlaceholders.UPPER_BOUND}px tall before publishing.`,
      [validationTypes.SIZE]: `Selected media file is too large for this post type. Ensure the file size is less than ${validationMessagePlaceholders.UPPER_BOUND} before publishing.`,
      [validationTypes.PIXELS]: `This image doesn't meet the size requirements for this post type. Please crop your image to be less than ${validationMessagePlaceholders.UPPER_BOUND} and try again!`,
    },
    [validationMessageType.GROUP_ERROR]: {
      [groupValidationTypes.MAX_COUNT]: `This post type only allows a maximum of ${validationMessagePlaceholders.UPPER_BOUND} media to be published at a time.`,
      [groupValidationTypes.MAX_IMAGE_COUNT]: `You can only select up to ${validationMessagePlaceholders.UPPER_BOUND} photos.`,
      [groupValidationTypes.MAX_VIDEO_COUNT]: `You can only select up to ${validationMessagePlaceholders.UPPER_BOUND} video.`,
    },
  },
  [validationMessageStyle.GENERAL]: {
    [validationMessageType.ERROR]: {
      [validationTypes.ALLOW_TYPES]: `This post type only supports ${validationMessagePlaceholders.ALLOW_TYPE} media. Please select a valid media before publishing.`,
    },
    [validationMessageType.GROUP_ERROR]: {
      [groupValidationTypes.MATCH_MEDIA_TYPE]: `This post type only allows media of the same type to be published together.`,
      [groupValidationTypes.MATCH_ASPECT_RATIO]: `This post type requires all media have the same aspect ratio (width:height) to be published together. Use the cropping tool to adjust the media before publishing.`,
    },
  },
};

const mBytes = 1024 * 1024;
export const A_RATIOS = {
  TALL: {
    str: '9:16',
    val: 0.5625,
  },
  WIDE: {
    str: '16:9',
    val: 1.78,
  },
  SQUARE: {
    str: '1:1',
    val: 1.0,
  },
  IG_SQ: {
    str: '4:5',
    val: 0.8,
  },
  IG_WIDE_IMG: {
    str: '1.91:1',
    val: 1.91,
  },
  IG_TALL_BOUND: {
    str: '0.01:1',
    val: 0.01,
  },
  IG_WIDE_BOUND: {
    str: '10:1',
    val: 10.0,
  },
  FB_POST_TALL_BOUND: {
    str: '4:1',
    val: 4.0,
  },
  FB_POST_WIDE_BOUND: {
    str: '1:4',
    val: 0.25,
  },
  TWITTER_TALL_BOUND: {
    str: '1:3',
    val: 0.33,
  },
  TWITTER_WIDE_BOUND: {
    str: '3:1',
    val: 3.0,
  },
};

// https://developers.facebook.com/docs/instagram-api/reference/ig-user/media#creating
// https://business-api.tiktok.com/portal/docs?id=1762228496095234
// https://developer.twitter.com/en/docs/twitter-api/v1/media/upload-media/uploading-media/media-best-practices
export const mediaValidationMapV = {
  [PLATFORMS.TIKTOK]: {
    default: {
      allowTypes: [mediaTypes.VIDEO],
      [mediaTypes.VIDEO]: {
        [validationTypes.WIDTH]: {
          min: 360,
          minSoft: 360,
        },
        [validationTypes.SIZE]: { max: 1024 * mBytes, min: 0 },
        [validationTypes.DURATION]: { max: 60 * 10, min: 3 },
        [validationTypes.FRAME_RATE]: { max: 60, min: 23 },
      },
    },
  },
  [PLATFORMS.INSTAGRAM]: {
    [postTypes.FEED]: {
      allowTypes: [mediaTypes.VIDEO, mediaTypes.IMAGE],
      [mediaTypes.VIDEO]: {
        [validationTypes.ASPECT_RATIO]: {
          max: A_RATIOS.WIDE.val,
          min: A_RATIOS.IG_SQ.val,
          tolerance: aspectRatioTolerance,
        },
        [validationTypes.WIDTH]: {
          ...IG_DEFAULT_DIMENSION_VIDEO,
          minSoft: minRecommendedVideoWidth,
          maxSoft: maxRecommendedVideoWidth,
        },
        [validationTypes.SIZE]: { max: 100 * mBytes, min: 0 },
        [validationTypes.DURATION]: { max: 60, min: 3 },
        [validationTypes.FRAME_RATE]: { max: 60, min: 23 },
      },
      [mediaTypes.IMAGE]: {
        [validationTypes.ASPECT_RATIO]: {
          max: A_RATIOS.IG_WIDE_IMG.val,
          min: A_RATIOS.IG_SQ.val,
          tolerance: aspectRatioTolerance,
        },
        [validationTypes.SIZE]: { max: 8 * mBytes, min: 0 },
        [validationTypes.WIDTH]: {
          minSoft: minRecommendedImageWidth,
          maxSoft: maxRecommendedImageWidth,
        },
      },
    },
    [postTypes.REEL]: {
      allowTypes: [mediaTypes.VIDEO],
      [mediaTypes.VIDEO]: {
        [validationTypes.ASPECT_RATIO]: {
          max: A_RATIOS.IG_WIDE_BOUND.val,
          min: A_RATIOS.IG_TALL_BOUND.val,
          maxSoft: A_RATIOS.TALL.val,
          minSoft: A_RATIOS.TALL.val,
          tolerance: aspectRatioTolerance,
        },
        [validationTypes.WIDTH]: {
          ...IG_DEFAULT_DIMENSION_VIDEO,
          minSoft: minRecommendedVideoWidth,
          maxSoft: maxRecommendedVideoWidth,
        },
        [validationTypes.SIZE]: { max: 1000 * mBytes, min: 0 }, // 1GB
        [validationTypes.DURATION]: { max: 60 * 15, min: 3 },
        [validationTypes.FRAME_RATE]: { max: 60, min: 23 },
      },
    },
    // Not a post type but we still validate custom cover images
    [REEL_CUSTOM_COVER]: {
      allowTypes: [mediaTypes.IMAGE],
      [mediaTypes.IMAGE]: {
        [validationTypes.SIZE]: { max: 8 * mBytes, min: 0 },
        [validationTypes.ASPECT_RATIO]: {
          maxSoft: A_RATIOS.WIDE.val,
          minSoft: A_RATIOS.TALL.val,
          tolerance: aspectRatioTolerance,
        },
        [validationTypes.WIDTH]: {
          minSoft: minRecommendedImageWidth,
          maxSoft: maxRecommendedImageWidth,
        },
      },
    },
    [postTypes.STORY]: {
      allowTypes: [mediaTypes.VIDEO, mediaTypes.IMAGE],
      [mediaTypes.VIDEO]: {
        [validationTypes.ASPECT_RATIO]: {
          max: A_RATIOS.IG_WIDE_BOUND.val,
          min: A_RATIOS.IG_TALL_BOUND.val,
          maxSoft: A_RATIOS.TALL.val,
          minSoft: A_RATIOS.TALL.val,
          tolerance: aspectRatioTolerance,
        },
        [validationTypes.WIDTH]: {
          ...IG_DEFAULT_DIMENSION_VIDEO,
          minSoft: minRecommendedVideoWidth,
          maxSoft: maxRecommendedVideoWidth,
        },
        [validationTypes.SIZE]: { max: 50 * mBytes, min: 0 },
        [validationTypes.DURATION]: { max: 60, min: 3 },
        [validationTypes.FRAME_RATE]: { max: 60, min: 23 },
      },
      [mediaTypes.IMAGE]: {
        [validationTypes.ASPECT_RATIO]: {
          max: A_RATIOS.WIDE.val,
          min: A_RATIOS.TALL.val,
          tolerance: aspectRatioTolerance,
        },
        [validationTypes.SIZE]: { max: 8 * mBytes, min: 0 },
        [validationTypes.WIDTH]: {
          minSoft: minRecommendedImageWidth,
          maxSoft: maxRecommendedImageWidth,
        },
      },
    },
  },
  [PLATFORMS.FACEBOOK]: {
    [fbPostTypes.POST]: {
      allowTypes: [mediaTypes.VIDEO, mediaTypes.IMAGE],
      [mediaTypes.VIDEO]: {
        [validationTypes.ASPECT_RATIO]: {
          max: A_RATIOS.FB_POST_TALL_BOUND.val,
          min: A_RATIOS.FB_POST_WIDE_BOUND.val,
          tolerance: aspectRatioTolerance,
        },
        [validationTypes.WIDTH]: {
          min: facebookPostMinWidth,
          minSoft: fbMinRecommendedVideoWidth,
        },
        [validationTypes.SIZE]: { max: 500 * mBytes, min: 0 },
        [validationTypes.DURATION]: { max: 600, min: 1 },
      },
      [mediaTypes.IMAGE]: {
        [validationTypes.PIXELS]: {
          max: 8000 * 8000,
        },
        [validationTypes.WIDTH]: {
          minSoft: 1080,
        },
        [validationTypes.SIZE]: { max: 4 * mBytes, min: 0 },
      },
    },
    [fbPostTypes.REEL]: {
      allowTypes: [mediaTypes.VIDEO],
      [mediaTypes.VIDEO]: {
        [validationTypes.ASPECT_RATIO]: {
          max: A_RATIOS.TALL.val,
          min: A_RATIOS.TALL.val,
          tolerance: aspectRatioTolerance,
        },
        [validationTypes.WIDTH]: {
          min: fbReelMinWidth,
        },
        [validationTypes.HEIGHT]: {
          min: fbReelMinHeight,
        },
        [validationTypes.SIZE]: { max: 1000 * mBytes, min: 0 }, // 1GB
        [validationTypes.DURATION]: { max: 90, min: 3 },
        [validationTypes.FRAME_RATE]: { max: 60, min: 24 },
      },
    },
    [REEL_CUSTOM_COVER]: {
      allowTypes: [mediaTypes.IMAGE],
      [mediaTypes.IMAGE]: {
        [validationTypes.SIZE]: { max: 10 * mBytes, min: 0 },
        [validationTypes.ASPECT_RATIO]: {
          maxSoft: A_RATIOS.WIDE.val,
          minSoft: A_RATIOS.TALL.val,
          tolerance: aspectRatioTolerance,
        },
        [validationTypes.WIDTH]: {
          maxSoft: maxRecommendedImageWidth,
          minSoft: minRecommendedImageWidth,
        },
      },
    },
  },
  [PLATFORMS.TWITTER]: {
    default: {
      allowTypes: [mediaTypes.VIDEO, mediaTypes.IMAGE],
      [mediaTypes.VIDEO]: {
        [validationTypes.WIDTH]: {
          min: 32,
          max: 1280,
        },
        [validationTypes.SIZE]: { max: 512 * mBytes, min: 0 },
        [validationTypes.DURATION]: { max: 600, min: 0.5 }, // Verrified working but differs from API spec
        [validationTypes.FRAME_RATE]: { max: 60, min: 5 },
        [validationTypes.ASPECT_RATIO]: {
          min: A_RATIOS.TWITTER_TALL_BOUND.val,
          max: A_RATIOS.TWITTER_WIDE_BOUND.val,
        },
      },
      [mediaTypes.IMAGE]: {
        [validationTypes.SIZE]: { max: 5 * mBytes, min: 0 },
        [validationTypes.WIDTH]: {
          minSoft: 1080,
          max: 8192,
        },
        [validationTypes.HEIGHT]: {
          minSoft: 1080,
          max: 8192,
        },
      },
    },
  },
};

export const groupValidationMap = {
  [PLATFORMS.INSTAGRAM]: {
    [postTypes.FEED]: {
      [groupValidationTypes.MAX_COUNT]: 10,
      [groupValidationTypes.MATCH_MEDIA_TYPE]: false,
      [groupValidationTypes.MATCH_ASPECT_RATIO]: true,
    },
    [postTypes.REEL]: {
      [groupValidationTypes.MAX_COUNT]: 1,
    },
    [postTypes.STORY]: {
      [groupValidationTypes.MAX_COUNT]: 1,
    },
  },
  [PLATFORMS.TIKTOK]: {
    default: {
      [groupValidationTypes.MAX_COUNT]: 1,
    },
  },
  [PLATFORMS.FACEBOOK]: {
    [fbPostTypes.POST]: {
      [groupValidationTypes.MAX_IMAGE_COUNT]: 10,
      [groupValidationTypes.MAX_VIDEO_COUNT]: 1,
      [groupValidationTypes.MATCH_MEDIA_TYPE]: true,
    },
    [fbPostTypes.REEL]: {
      [groupValidationTypes.MAX_COUNT]: 1,
    },
  },
  [PLATFORMS.TWITTER]: {
    default: {
      [groupValidationTypes.MAX_IMAGE_COUNT]: 4,
      [groupValidationTypes.MAX_VIDEO_COUNT]: 1,
      [groupValidationTypes.MATCH_MEDIA_TYPE]: true,
    },
  },
};

export const PLATFORM_VALIDATES_SN = {
  [PLATFORMS.INSTAGRAM]: false,
  [PLATFORMS.FACEBOOK]: true,
  [PLATFORMS.TWITTER]: true,
  [PLATFORMS.LINKEDIN]: true,
  [PLATFORMS.PINTEREST]: true,
  [PLATFORMS.TIKTOK]: false,
};
