import { useTrackingStore } from '@/stores/tracking';

export const FILTER_ACTION_TYPES = {
  OPENED: 'Opened',
  CLOSED: 'Closed',
  APPLY: 'Apply',
  REMOVE: 'Remove',
  RESET_FILTERSET: 'Reset Filterset',
  RESET_ALL: 'Reset All',
  COLLAPSE: 'Collapse',
  EXPAND: 'Expand',
};

export function trackFilterDrawerInteraction({ action, filterName, selectedOptions }) {
  const trackingStore = useTrackingStore();
  trackingStore.track('Filter Drawer Interaction', {
    action,
    filterName,
    selectedOptions,
  });
}
