/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'singleUser': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.002 8.006c0 2.205-1.794 4-4 4-2.205 0-4-1.795-4-4s1.794-4 4-4 4 1.795 4 4zm-12 11c0-3.533 3.29-6 8-6 4.711 0 8 2.467 8 6v1h-16v-1z" _fill="#ADADAD"/><mask id="svgicon_singleUser_a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="4" y="4" width="17" height="17"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M16.002 8.006c0 2.205-1.794 4-4 4-2.205 0-4-1.795-4-4s1.794-4 4-4 4 1.795 4 4zm-12 11c0-3.533 3.29-6 8-6 4.711 0 8 2.467 8 6v1h-16v-1z" _fill="#fff"/></mask><g mask="url(#svgicon_singleUser_a)"><path pid="2" _fill="#ADADAD" d="M0 0h24v24H0z"/></g>'
  }
})
