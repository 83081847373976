<template>
  <div class="mx-auto w-fit columns-2">
    <template v-if="showWaterfallItems">
      <div v-for="(item, index) in items" :key="item.id">
        <PinWaterfallItem
          :crop-clicked="() => cropClicked(item, index)"
          :pin="item"
          data-cy="pin-waterfall-item"
          @remove="removeItem"
          @focus-pin-web-link="focusPinWebLink"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import PinWaterfallItem from '@/app/scheduler/components/BulkAddPinterest/PinWaterfallItem.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'WaterfallContainer',
  components: {
    PinWaterfallItem,
  },
  props: {
    cropClicked: { type: Function, default: () => {} },
    items: { type: Array, default: () => [] },
  },
  emits: ['remove', 'focusPinWebLink'],
  data() {
    return {
      showWaterfallItems: false,
    };
  },
  mounted() {
    nextTick(() => {
      this.$forceUpdate();
      this.showWaterfallItems = true;
    });
  },
  methods: {
    removeItem(item) {
      this.$emit('remove', item);
    },
    focusPinWebLink(item) {
      this.$emit('focusPinWebLink', item);
    },
  },
});
export default comp;
</script>
