import { defineStore } from 'pinia';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';

import {
  fetchUsers,
  getTwitterAccount,
  getTwitterAccounts,
  getMetricsCSV,
  getFollowersCSV,
  getAllTweetsCSV,
  getTopTweetsCSV,
  getAccountMetrics,
} from '@/apis/twitter';

import {
  isValidContextPeriodMode,
  isValidReportingPeriodMode,
  REPORTING_PERIOD_MODES,
  CONTEXT_PERIOD_MODES,
  updateDateRange,
} from '@/components/core/comparison-date-picker/constants';

import { LibraryAPI } from '@/apis';
import { downloadFileFromMemory, getCurrentDate } from '@/utils';
import { logger } from '@/utils/logger';

export const REPORT_RANGE_DEFAULT = [getCurrentDate(7), getCurrentDate(1)];
export const CONTEXT_RANGE_DEFAULT = [getCurrentDate(14), getCurrentDate(8)];

export const useTwitterStore = defineStore('twitter', {
  resetOnBrandChange: true,
  state: () => ({
    // consider if state value should be cleared in resetState() action
    users: {},
    requests: {
      users: {},
      searches: {},
    },
    twitterAccount: null,
    twitterAccounts: null,
    accountMetrics: {},
    twelveWeekAverageEngagement: null,
    twitterMostViewedVideos: null,
    exportPending: false,
    reportDateRange: cloneDeep(REPORT_RANGE_DEFAULT),
    contextDateRange: cloneDeep(CONTEXT_RANGE_DEFAULT),
  }),
  getters: {
    getUser: (state) => (username) => state.users[username.toLowerCase()],
    twitterAccountsMap: (state) => {
      const result = (state.twitterAccounts || []).reduce(
        (map, account) => ({
          ...map,
          [account.brand_id]: {
            ...account,
          },
        }),
        {},
      );
      return result;
    },
  },
  actions: {
    resetState() {
      /*
     Reset state for events such as changing brands.
     Some state values such as twitterAccounts are reloaded but are not wanted to be nulled
     while fetching most recent account list.
      */
      this.users = {};
      this.requests = {
        users: {},
        searches: {},
      };
      this.twitterAccount = null;
      this.accountMetrics = {};
      this.twelveWeekAverageEngagement = null;
      this.twitterMostViewedVideos = null;
      this.exportPending = false;
    },
    initDateRanges({
      startDate,
      endDate,
      contextStartDate,
      contextEndDate,
      reportingPeriodMode,
      contextPeriodMode,
    }) {
      this.updateReportDate({ reportDateRange: [startDate, endDate] });
      this.updateContextDate({ contextDateRange: [contextStartDate, contextEndDate] });
      this.updateReportingPeriodMode(reportingPeriodMode);
      this.updateContextPeriodMode(contextPeriodMode);
    },
    updateReportDate({ reportDateRange }) {
      updateDateRange(this.$state, 'reportDateRange', reportDateRange, REPORT_RANGE_DEFAULT);
    },
    updateContextDate({ contextDateRange }) {
      updateDateRange(this.$state, 'contextDateRange', contextDateRange, CONTEXT_RANGE_DEFAULT);
    },
    updateReportingPeriodMode(mode) {
      let valueToBeUsed = null;
      if (isValidReportingPeriodMode(mode)) {
        valueToBeUsed = mode;
      } else {
        valueToBeUsed = REPORTING_PERIOD_MODES.LAST_7_DAYS.value;
      }

      this.reportingPeriodMode = valueToBeUsed;
    },
    updateContextPeriodMode(mode) {
      this.reportingPeriodMode = isValidContextPeriodMode(mode)
        ? mode
        : CONTEXT_PERIOD_MODES.PREVIOUS_WEEK.value;
    },
    updateDateRanges({ newReportDateRange, newContextDateRange }) {
      const hasNewReportDateRange = newReportDateRange?.length > 0;
      const hasNewContextDateRange = newContextDateRange?.length > 0;

      const hasUpdatedReportDateRange = !isEqual(newReportDateRange, this.reportDateRange);
      const hasUpdatedContextDateRange = !isEqual(newContextDateRange, this.contextDateRange);

      if (hasNewReportDateRange && hasUpdatedReportDateRange) {
        this.updateReportDate({ reportDateRange: newReportDateRange });
      }

      if (hasNewContextDateRange && hasUpdatedContextDateRange) {
        this.updateContextDate({ contextDateRange: newContextDateRange });
      }
    },
    addUsers(users) {
      users.forEach((user) => {
        this.users = {
          ...this.users,
          [user.username.toLowerCase()]: Object.freeze(user),
        };
      });
    },
    async searchUsers(query, brandId) {
      if (!this.requests.searches[query]) {
        const request = (async () => {
          const res = await fetchUsers({ handles: query, brandId });
          const users = res.data;
          this.addUsers(users);
          return users.map((user) => this.users[user.username.toLowerCase()]);
        })();
        this.requests.searches = {
          ...this.requests.searches,
          [query]: request,
        };
        request.catch(() => {
          const newValue = { ...this.requests.searches };
          delete newValue[query];
          this.requests.searches = newValue;
        });
      }
      return this.requests.searches[query];
    },
    async fetchUsers(usernames, brandId) {
      const lowercaseUsernames = usernames.map((username) => username.toLowerCase());
      const missingUsernames = lowercaseUsernames.filter(
        (username) => !this.requests.users[username] && !this.users[username],
      );
      if (missingUsernames.length > 0) {
        const request = fetchUsers({ handles: missingUsernames.join(','), brandId })
          .then((response) => this.addUsers(response.data))
          .catch(() =>
            lowercaseUsernames.forEach((username) => {
              const newValue = { ...this.requests.users };
              delete newValue[username];
              this.requests.users = newValue;
            }),
          );
        lowercaseUsernames.forEach((username) => {
          this.requests.users = {
            ...this.requests.users,
            [username]: request,
          };
        });
      }
      await Promise.all(lowercaseUsernames.map((username) => this.requests.users[username]));
    },
    async getTwitterAccount({ brandId }) {
      try {
        const response = await getTwitterAccount({ brandId });
        this.twitterAccount = response?.data;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.twitterAccount = null;
        } else {
          throw error;
        }
      }
      return this.twitterAccount;
    },
    async getTwitterAccounts({ brandIds }) {
      try {
        const response = await getTwitterAccounts({ brandIds });
        this.twitterAccounts = response?.data;
      } catch (error) {
        if (error?.response?.status === 404) {
          this.twitterAccounts = null;
        } else if (error?.response?.status === 401) {
          this.twitterAccounts = null;
          logger.error(
            `twitterStore getTwitterAccounts request returned authentication error: ${error.response?.data?.description}`,
            {},
            error,
          );
        } else {
          throw error;
        }
      }
      return this.twitterAccounts;
    },
    async getFollowersCSV({ brandId, filename, startDate, endDate, scale }) {
      this.exportPending = true;
      const response = await getFollowersCSV({ brandId, filename, startDate, endDate, scale });
      downloadFileFromMemory(response?.data, filename);
      this.exportPending = false;
    },
    async getMetricsCSV({ brandId, filename, startDate, endDate, scale }) {
      this.exportPending = true;
      const response = await getMetricsCSV({ brandId, filename, startDate, endDate, scale });
      downloadFileFromMemory(response?.data, filename);
      this.exportPending = false;
    },
    async getAllTweetsCSV({ brandId, filename, startDate, endDate, scale }) {
      this.exportPending = true;
      const response = await getAllTweetsCSV({ brandId, filename, startDate, endDate, scale });
      downloadFileFromMemory(response?.data, filename);
      this.exportPending = false;
    },
    async getTopTweetsCSV({ brandId, filename, startDate, endDate, scale, sortOrder }) {
      this.exportPending = true;
      const response = await getTopTweetsCSV({
        brandId,
        filename,
        startDate,
        endDate,
        scale,
        sortOrder,
      });
      downloadFileFromMemory(response?.data, filename);
      this.exportPending = false;
    },
    async getAccountMetrics({ brandId, startDate, endDate, scale }) {
      try {
        const response = await getAccountMetrics({ brandId, startDate, endDate, scale });
        this.accountMetrics = { ...this.accountMetrics, ...(response?.data ?? {}) };
        this.twelveWeekAverageEngagement = Math.round(
          this.accountMetrics?.past_twelve_weeks?.avg_engagements ?? 0,
        );
      } catch (error) {
        if (error?.response?.status === 404) {
          this.accountMetrics = {};
        } else {
          throw error;
        }
      }
    },
    async getTwitterMostViewedVideos({
      brandId,
      sortFields,
      limit = 6,
      filters,
      hideSource = false,
      hidePredictions = false,
    } = {}) {
      const response = await LibraryAPI.getMediaListV2({ brandId, sortFields, filters, limit });
      const payload = response?.data;
      this.twitterMostViewedVideos = payload.data.map((el) => {
        return {
          ...el,
          source: hideSource ? null : el.source,
          predictions: hidePredictions ? null : el.predictions,
        };
      });
    },
  },
});
