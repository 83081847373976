<template>
  <div>
    <div v-if="!title" class="dot" />
    <VTooltip
      v-else
      theme="dh-tooltip-small"
      :container="false"
      :boundary="boundary"
      placement="bottom"
      shown
    >
      <div class="tooltip-trigger" />
      <template #popper>
        <div class="tag-tooltip-content">
          <div class="title">{{ title }}</div>
          <Icon
            v-if="!productTaggingViewOnly"
            name="close"
            color="white"
            class="tag-remove"
            xsmall
            @click="() => $emit('deleteTag')"
            @mousedown.stop
            @mouseup.stop
          />
        </div>
      </template>
    </VTooltip>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'MediaTag',
  components: { Icon },
  props: {
    boundary: {
      type: [Array, HTMLDivElement],
      default: null,
    },
    title: { type: String, default: '' },
    productTaggingViewOnly: { type: Boolean, required: false, default: false },
  },
  emits: ['deleteTag'],
});
export default comp;
</script>

<style lang="postcss" scoped>
.dot {
  position: absolute;
  cursor: pointer;
  height: var(--space-24);
  width: var(--space-24);
  background-color: var(--black);
  border-radius: 50%;
  border: 2px solid var(--white);
  opacity: 0.7;
  transform: translate(-50%, -50%);
}

.tag-tooltip-content {
  display: flex;
}

.title {
  max-width: 5.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1;
  white-space: nowrap;
  align-self: center;
  font-size: var(--x12);
}

.tag-remove {
  cursor: pointer;
  padding-left: 0.45rem;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
}
</style>
