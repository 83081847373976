export const LINKEDIN_OVERVIEW_SINGLE_BRAND = [
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 0,
    meta: {
      name: 'All Published Posts',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['PAGE_VIEWS_ALL_POSTS'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['NET_NEW_FOLLOWERS'],
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['PAGE_VIEWS_ALL_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['NET_NEW_FOLLOWERS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['COMMENTS_ALL_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['REACTIONS_ALL_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['CLICK_THROUGH_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 5,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['CLICKS_ALL_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 7,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['SHARES_ALL_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 7,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['AVG_ENGAGEMENT_RATE_ALL_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 7,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['ENGAGEMENTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 7,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['IMPRESSIONS_ALL_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 7,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['UNIQUE_IMPRESSIONS_ALL_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 9,
    meta: {
      name: 'Posts Published During Reporting Period',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['WATCH_TIME_BY_POST'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['ENGAGEMENTS_BY_POST'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['AVG_ENGAGEMENT_RATE_BY_POST'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['COMMENTS_BY_POST'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['CLICKS_BY_POST'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['AVG_CLICK_THROUGH_RATE_BY_POST'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['IMPRESSIONS_BY_POST'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['SHARES_BY_POST'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['NUMBER_OF_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['REACTIONS_BY_POST'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['UNIQUE_VISITORS_ALL_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 14,
    meta: {
      name: 'Post Performance',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 15,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'LINKEDIN',
      metrics: ['ENGAGEMENT_RATE'],
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 19,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['CLICK_THROUGH_RATE_BY_POST'],
      sort_order: 'DESC',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 23,
    meta: {
      name: 'Follower Activity',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 24,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['FOLLOWER_GROWTH_RATE'],
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 24,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['TOTAL_FOLLOWERS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 24,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['NET_NEW_FOLLOWERS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 26,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['FOLLOWER_GROWTH_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 12,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['LINKEDIN'],
      metrics: ['AVG_UNIQUE_IMPRESSIONS_BY_POST'],
      aggregate_by: 'TOTAL',
    },
  },
];
