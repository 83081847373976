<script setup>
import UserBubble from '@/components/VisionAi/UserBubble.vue';
import ErrorBubble from '@/components/VisionAi/ErrorBubble.vue';
import { VISION_AI_SENDER, SKELETON_TYPES } from '@/config';
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useVisionAiStore } from '@/stores/vision-ai';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import { parseQuery } from '@/utils/query';
import { formatDate, formatTimestamp } from '@/utils/vision';
import SkeletonLoader from '@/components/core/skeleton/SkeletonLoader.vue';
import {
  DEFAULT_SUMMARY_PROMPTS_BY_INTENT_TYPE,
  SUMMARY_INTENT_TYPES,
  VISION_AI_WIDGET_INTERACTIONS,
} from '@/components/VisionAi/constants';
import { aiWidgetInteractionEvent } from '@/components/VisionAi/utils/mixpanel-events';
import AssistantBubble from '@/components/VisionAi/AssistantBubble.vue';
import { getMessageTextForIntentType } from '@/components/VisionAi/utils/message';
import debounce from 'lodash/debounce';
import { useScroll } from '@vueuse/core';
import orderBy from 'lodash/orderBy';
import WarningBubble from '@/components/VisionAi/WarningBubble.vue';
import { useVisionAiConversationsStore } from '@/stores/vision-ai-conversations';
import { useVisionAiMessagesStore } from '@/stores/vision-ai-messages';
import VisionWelcomeMessage from '@/components/VisionAi/VisionWelcomeMessage.vue';

const visionAiStore = useVisionAiStore();
const visionAiConversationsStore = useVisionAiConversationsStore();
const visionAiMessagesStore = useVisionAiMessagesStore();

const scrollRef = ref(null);
const smoothScroll = ref('auto');
const mouseEvent = ref(true);

const { y } = useScroll(scrollRef, { behavior: smoothScroll });

const props = defineProps({
  messages: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const messagesGroupedByDate = computed(() => {
  const messagesGrouped = [];

  props.messages.forEach((message) => {
    const date = formatDate(message.createdAt);
    let dateGroup = messagesGrouped.find((group) => group.date === date);
    if (!dateGroup) {
      dateGroup = { date, messages: [] };
      messagesGrouped.push(dateGroup);
    }
    dateGroup.messages.push(message);
  });

  messagesGrouped.forEach((dateGroup) => {
    dateGroup.messages = orderBy(dateGroup.messages, ['createdAt', 'id'], ['asc', 'asc']);
  });

  // use the first message in each date group's date so that we are comparing actual date values
  return orderBy(messagesGrouped, [(group) => new Date(group.messages[0].createdAt)], ['asc']);
});

const showCircularLoader = computed(() => {
  return props.loading;
});

function scrollToTheBottom(scrollBehavior) {
  smoothScroll.value = scrollBehavior;
  nextTick(() => {
    if (scrollRef.value) {
      const { scrollHeight, offsetHeight } = scrollRef.value;
      y.value = scrollHeight - offsetHeight;
    }
  });
}

const debounceScrollToBottom = debounce(function componentMounted({
  scrollBehavior = smoothScroll.value,
} = {}) {
  scrollToTheBottom(scrollBehavior);
}, 100);

function getChatComponent(message) {
  if (message.sender === VISION_AI_SENDER.USER) {
    return UserBubble;
  }
  if (message.errorCode) {
    return ErrorBubble;
  }
  if (message.isWarningMessage) {
    return WarningBubble;
  }
  return AssistantBubble;
}

function getMessageLoadingState(messageId) {
  return messageId === visionAiMessagesStore.regeneratingMessageId;
}

function getMessageProps(message) {
  return {
    messageId: message.id,
    text: getMessageTextForIntentType(
      message.message,
      message.sender,
      message.intentType,
      message.errorCode,
      message.isWarningMessage,
    ),
    time: formatTimestamp(message.createdAt),
    date: formatDate(message.createdAt),
    sourceUrl: message.sourceUrl,
    errorCode: message.errorCode,
    regeneratingMessageLoading:
      (getMessageLoadingState(message.id) && visionAiStore.loadingMessageInCurrentConversation) ||
      null,
    disableRegenerateButton: visionAiStore.loadingMessageInCurrentConversation || null,
    additionalContent: message.additionalContent,
    errorMessageLoading: visionAiStore.loadingMessageInCurrentConversation || null,
    intentType: message.intentType,
    intentQuery: message.intentQuery,
    isWarningMessage: message.isWarningMessage,
  };
}

async function handleRegenerateSummaryMessage(assistantMessage) {
  // Todo: properly handle passing the userPrompt message to createMessage
  const userPrompt = DEFAULT_SUMMARY_PROMPTS_BY_INTENT_TYPE[assistantMessage.intentType];

  const { conversationId, intentType, intentQuery } = assistantMessage;

  const newUserMsg = await visionAiMessagesStore.createMessage({
    conversationId,
    intentType,
    intentQuery,
    sender: VISION_AI_SENDER.USER,
    message: userPrompt,
  });
  debounceScrollToBottom({
    scrollBehavior: 'smooth',
  });
  // add user msg to the chat-box ahead of receiving the web socket notification
  if (!visionAiStore.visionAiChatWindowOpen) {
    visionAiMessagesStore.addMessageToChatWindow(newUserMsg);
    visionAiMessagesStore.setRegenerateMessageId(null);
  }
}

async function handleRegenerateMessage(assistantMessageId) {
  const assistantMessage = await visionAiMessagesStore.getMessageById(
    assistantMessageId,
    undefined,
  );

  visionAiMessagesStore.setRegenerateMessageId(assistantMessageId);
  visionAiConversationsStore.addLoadingConversationIds(assistantMessage.conversationId);

  if (SUMMARY_INTENT_TYPES.includes(assistantMessage.intentType)) {
    await handleRegenerateSummaryMessage(assistantMessage);
  } else {
    visionAiMessagesStore.clearMessageFromHistory(assistantMessageId);
    await visionAiMessagesStore.regenerateMessage({
      conversationId: assistantMessage.conversationId,
      messageId: assistantMessageId,
    });
  }
  visionAiMessagesStore.setRegenerateMessageId(null);
}

const showInfiniteLoadSpinner = computed(() => {
  return Boolean(visionAiMessagesStore.messages.length) && visionAiStore.pending.messages;
});

const preventInfiniteLoad = computed(() => {
  return (
    visionAiStore.pending.messages ||
    !visionAiMessagesStore.messagesListNextUrl ||
    !visionAiConversationsStore.conversationMessagesNextPageUrl
  );
});

async function getNextPageMessageList() {
  const scrollTopBeforeFetch =
    scrollRef.value.scrollHeight + scrollRef.value.offsetHeight - scrollRef.value.scrollTop;
  const isConversationMessages = visionAiConversationsStore.currentConversationId;
  const nextPageUrl = isConversationMessages
    ? visionAiConversationsStore.conversationMessagesNextPageUrl
    : visionAiMessagesStore.messagesListNextUrl;

  if (nextPageUrl) {
    const queryParams = parseQuery(nextPageUrl);
    await visionAiMessagesStore.getMessages(queryParams);

    const assistantMessageCount =
      visionAiMessagesStore.messages.filter(
        (message) => message.sender === VISION_AI_SENDER.ASSISTANT,
      )?.length ?? null;
    aiWidgetInteractionEvent({
      interaction: VISION_AI_WIDGET_INTERACTIONS.LOAD_HISTORY,
      summaryCount: assistantMessageCount,
    });

    // scroll down to where user was, so they are seeing the last message before next page was fetched
    y.value = scrollRef.value.scrollHeight - scrollTopBeforeFetch;
  }
}

function copyResponse(message) {
  visionAiStore.copyResponse(message);
}

async function handleSourceButtonClicked(messageIntentType, messageSourceUrl) {
  await visionAiStore.handleSourceButtonClicked(messageIntentType, messageSourceUrl);
}

function isLatestDateGroup(dateGroup) {
  const numDateGroups = messagesGroupedByDate.value.length;
  const latestDate = messagesGroupedByDate.value?.[numDateGroups - 1]?.date;
  return dateGroup.date === latestDate;
}

watch(messagesGroupedByDate, () => {
  const { style } = scrollRef.value;
  smoothScroll.value = 'smooth';
  debounceScrollToBottom();

  // If the scrollbar isn't in the view, the scrollableChat should have full width
  style.overflowY = 'hidden';
  style.paddingRight = '0';
});

watch(y, (to) => {
  const { style } = scrollRef.value;
  // If the scrollbar is in the view, the scrollableChat should have a padding of 6px

  if (to) {
    style.paddingRight = '6px';
    style.overflowY = 'scroll';
  }
});

onMounted(() => {
  if (props.messages.length > 0) {
    debounceScrollToBottom();
  }
});

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        formatTimestamp,
        showInfiniteLoadSpinner,
        preventInfiniteLoad,
        messagesGroupedByDate,
        getNextPageMessageList,
        copyResponse,
        getMessageProps,
        showCircularLoader,
        handleRegenerateMessage,
      }
    : {},
);
</script>

<template>
  <div
    id="scroll-container"
    ref="scrollRef"
    class="scrollable-area"
    :class="{ 'scrollbar-background-color': mouseEvent }"
    @scroll="handleScroll"
    @mouseenter="() => (mouseEvent = true)"
    @mouseleave="() => (mouseEvent = false)"
  >
    <VisionWelcomeMessage />
    <div v-if="showCircularLoader" class="flex h-screen items-center justify-center pr-2">
      <CircularLoader white large />
    </div>

    <template v-else>
      <InfiniteScroll
        :on-infinite="getNextPageMessageList"
        :prevent-request="preventInfiniteLoad"
        :distance="10"
        scroll-direction="up"
      />

      <CircularLoader v-if="showInfiniteLoadSpinner" />

      <ul v-for="dateGroup in messagesGroupedByDate" :key="dateGroup.date">
        <li class="flex h-full flex-col justify-between">
          <div class="relative mb-8 mt-6">
            <div class="h-px bg-white"></div>
            <div
              class="-translate-1 absolute -top-2 left-1/2 inline-flex h-4 min-w-[120px] -translate-x-1/2 transform items-center justify-center gap-2 rounded-[20px] bg-white p-2"
            >
              <div class="flex items-start justify-start gap-1">
                <div class="text-[10px] font-medium text-stone-500">{{ dateGroup.date }}</div>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-3">
            <div
              v-for="message in dateGroup.messages"
              :key="message.id"
              class="chat-message box-border flex justify-end"
            >
              <component
                :is="getChatComponent(message)"
                v-bind="getMessageProps(message)"
                @regenerate-message="handleRegenerateMessage"
                @copy-response="copyResponse(message.message)"
                @source-button-clicked="handleSourceButtonClicked"
                @expand-media="visionAiStore.expandChatPopup()"
              />
            </div>
            <SkeletonLoader
              v-if="
                isLatestDateGroup(dateGroup) && visionAiStore.loadingMessageInCurrentConversation
              "
              :loading="visionAiStore.loadingMessageInCurrentConversation"
              :type="SKELETON_TYPES.chatBubbleSkeleton"
            />
          </div>
        </li>
      </ul>
    </template>
  </div>
</template>

<style lang="postcss" scoped>
.scrollable-area {
  padding-top: var(--space-16);
  overflow: hidden auto;

  > :first-child {
    margin-top: auto;
  }

  /* Apply borderless scroll bars */

  &::-webkit-scrollbar {
    width: var(--space-10);
    height: var(--space-8);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: var(--round-corner);
    background-clip: content-box;
    border-right: 1px solid transparent;
    border-left: 1px solid transparent;
  }

  &.scrollbar-background-color {
    &::-webkit-scrollbar-thumb {
      background-color: rgb(var(--scrollbar-background) / 100%);
    }
  }

  &:not(.scrollbar-background-color) {
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }

  &::-webkit-scrollbar-track {
    margin-top: var(--space-24);
  }

  .chat-message {
    .user-bubble-container {
      animation: chat-message-with-fade 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }

    .assistant-bubble:not(:last-of-type) {
      animation: chat-message-without-fade 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    }
  }

  @keyframes chat-message-with-fade {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes chat-message-without-fade {
    0% {
      transform: translateY(100%);
    }

    100% {
      transform: translateY(0);
    }
  }
}
</style>
