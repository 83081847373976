<template>
  <Picker
    :data="emojiIndex"
    :show-preview="true"
    :native="true"
    class="picker"
    title="Pick your emoji..."
    emoji="point_up"
    @select="(emoji) => $emit('select', emoji)"
  />
</template>

<script>
import { defineComponent } from 'vue';
// Import only Apple-style emojis
import data from 'emoji-mart-vue-fast/data/apple.json';
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast/src';

const emojiIndex = new EmojiIndex(data);

/**
 * Displays an emoji picker.
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  components: {
    Picker,
  },
  emits: ['select'],
  data() {
    return {
      emojiIndex,
    };
  },
});
export default comp;
</script>

<style lang="postcss">
.picker {
  /* Make all clickable things display the pointer cursor. */
  .emoji-mart-anchors button,
  .emoji-mart-emoji,
  .emoji-mart-emoji span,
  .emoji-mart-preview-skins {
    cursor: pointer;
  }

  .emoji-mart-preview-emoji {
    .emoji-mart-emoji,
    .emoji-mart-emoji span {
      cursor: default;
    }
  }

  .emoji-mart-anchor {
    color: var(--text-primary);

    &:hover {
      color: var(--black);
    }
  }

  /* Don't display emoji shortcuts */
  .emoji-mart-preview-shortname,
  .emoji-mart-preview-emoticon {
    display: none;
  }
}
</style>
