import { defineStore } from 'pinia';
import formatISO from 'date-fns/formatISO';
import memoize from 'lodash/memoize';
import { camelize, camelizeKeys } from 'humps';
import { browserStorageGetItem, browserStorageSetItem } from '@/utils/browserStorage';
import { CALENDAR_MODE } from '@/components/core/calendar/constants';
import { ALL_PLATFORMS, APPROVAL_POLICY, APPROVAL_STATUS } from '@/app/scheduler/constants';
import {
  fetchPosts,
  fetchPost,
  createPost,
  updatePost,
  deletePost,
  reorderPost,
  fetchEvents,
  createEvent,
  updateEvent,
  deleteEvent,
  updateApprovalRequest,
  axios,
  getRecommendedPublishTimes,
} from '@/apis/scheduler';
import * as TwitterAPI from '@/apis/twitter';
import * as FacebookAPI from '@/apis/facebook';
import { isValidDate, parseISOOrElse } from '@/utils/dateUtils';
import { parsePost, mapPostsByKey, getPostKey, mergeCase } from '@/app/scheduler/utils';
import { unscheduledPosts } from '@/app/scheduler/utils/post-query';
import { logger } from '@/utils/logger';
import { useAuthStore } from './auth';

const BROWSER_STORAGE_KEYS = {
  CALENDAR_MODE: 'schedulerCalendarMode',
  CALENDAR_DATE: 'schedulerCalendarDate',
  DISMISSED_RECONNECTION_MESSAGES: 'dismissedReconnectionMessages',
};

const validatePlatform = (platform) => {
  if (!ALL_PLATFORMS.includes(platform)) {
    throw new Error(`${platform} is not a valid platform!`);
  }
};

const validateMode = (mode) => {
  if (!Object.values(CALENDAR_MODE).includes(mode)) {
    throw new Error(`"${mode}" is not a valid calendar mode!`);
  }
};

const validateDate = (date) => {
  if (!isValidDate(date)) {
    throw new Error(`${date} is not a valid date!`);
  }
};

const getPlatformBrandKey = (platform, brandId) => `${platform}-${brandId}`;

export const useSchedulerStore = defineStore('scheduler', {
  state: () => ({
    facebookSchedulingTokens: {},
    calendarMode: browserStorageGetItem(BROWSER_STORAGE_KEYS.CALENDAR_MODE) ?? CALENDAR_MODE.WEEK,
    calendarDate: parseISOOrElse(
      browserStorageGetItem(BROWSER_STORAGE_KEYS.CALENDAR_DATE),
      new Date(),
    ),
    shownReconnectionMessages: [],
    dismissedReconnectionMessages: browserStorageGetItem(
      BROWSER_STORAGE_KEYS.DISMISSED_RECONNECTION_MESSAGES,
    )
      ? browserStorageGetItem(BROWSER_STORAGE_KEYS.DISMISSED_RECONNECTION_MESSAGES).split(',')
      : [],
    postsByKey: {},
    nextPageURLsByQuery: {},
    loadedQueries: [],
    successfulQueries: [],
    lastQueryAt: null,
    duplicationPostId: null,
    platformsWithErrors: [],
    pendingRequests: [],
    pendingPostActions: 0,
    pendingEventActions: 0,
    eventsByBrandId: {},
    twitterLocationSearchList: [],
    instagramLocationSearchList: [],
    facebookLocationSearchList: [],
    facebookAdGeolocations: {},
    locationRateLimit: null,
    locationRateLimitReached: false,
    errors: {
      facebookAdGeolocations: null,
    },
    pending: {
      fetchPost: false,
      twitterLocationSearchList: 0,
      instagramLocationSearchList: 0,
      facebookLocationSearchList: 0,
      facebookAdGeolocations: 0,
    },
    instagramTabContext: '',
    instagramCarouselPage: undefined,
    instagramCarouselActiveMedia: undefined,
    autoPublish: false,
    activeSubScreen: null,
    thumbOffset: null,
    thumbnailSource: null,
    popupType: null,
    popupData: null,
    pendingPostDict: {},
    approvalRequests: [],
    savingApprovalRequestStatus: false,
    recommendedPublishTimes: {},
  }),
  getters: {
    postList: (state) => Object.values(state.postsByKey),
    getPosts() {
      const { postList } = this;
      return memoize((query) => query.filterAndSort(postList));
    },
    getPost:
      (state) =>
      ({ platform, id }) =>
        state.postsByKey[getPostKey({ platform, id })],
    hasNextPage: (state) => (query) => !!state.nextPageURLsByQuery[query],
    isLoaded: (state) => (query) =>
      state.loadedQueries.some((loadedQuery) => loadedQuery.includes(query)),
    wasSuccessful: (state) => (query) =>
      state.successfulQueries.some((successfulQuery) => successfulQuery.includes(query)),
    getPendingRequest: (state) => (query) =>
      state.pendingRequests.find((pendingRequest) => pendingRequest.query.includes(query))?.request,
    isPending: (state) => (query) =>
      state.pendingRequests.some((pendingRequest) => pendingRequest.query.includes(query)),
    postActionPending: (state) => state.pendingPostActions > 0,
    eventActionPending: (state) => state.pendingEventActions > 0,
    isReconnectionMessageDismissed: (state) => (platform, brandId) =>
      state.dismissedReconnectionMessages.includes(getPlatformBrandKey(platform, brandId)),
    isReconnectionMessageShown: (state) => (platform, brandId) =>
      state.shownReconnectionMessages.includes(getPlatformBrandKey(platform, brandId)),
    getEvents:
      (state) =>
      ({ brandId }) =>
        state.eventsByBrandId[brandId] ?? [],
    currentFacebookSchedulingToken: (state) => {
      const authStore = useAuthStore();
      return state.facebookSchedulingTokens?.[authStore.currentBrand?.id] ?? null;
    },
  },
  actions: {
    async getFacebookSchedulingToken({ brandId, ignoreCache = false } = {}) {
      const authStore = useAuthStore();
      const brandIdToUse = brandId ?? authStore.currentBrand?.id;

      // Try to find the token in our cache
      if (!ignoreCache && brandIdToUse in this.facebookSchedulingTokens) {
        return this.facebookSchedulingTokens[brandIdToUse];
      }
      try {
        const response = await FacebookAPI.default.getAccessToken({
          brandId: brandIdToUse,
          requiredScopes: ['pages_manage_posts', 'pages_read_engagement'],
        });
        const token = response?.data;

        // Cache the response so we're not hitting the server on every scheduler popup load, etc.
        this.facebookSchedulingTokens = {
          ...this.facebookSchedulingTokens,
          [brandIdToUse]: token,
        };

        return token;
      } catch (error) {
        if (error.response?.status === 401 || error.response?.status === 404) {
          logger.error(
            `schedulerStore getFacebookSchedulingToken request returned error: ${error.response?.data?.description}`,
            {},
            error,
          );
          return null;
        }
        logger.error('[GetFacebookSchedulingToken] returned error', {}, error);
        throw error;
      }
    },
    async getFacebookPageSearchList({ ids, q, limit }) {
      const response = await FacebookAPI.getPages({ ids, q, limit });
      const payload = response?.data;

      return payload;
    },

    async fetchEvents({ brandId }) {
      const response = await fetchEvents({ brandId });
      const events = response.data.map(mergeCase);
      this.eventsByBrandId = {
        ...this.eventsByBrandId,
        [brandId]: events,
      };
    },
    async createEvent({ brandId, title, startDate, endDate, description }) {
      this.pendingEventActions += 1;
      try {
        return await createEvent({ brandId, title, startDate, endDate, description });
      } finally {
        this.pendingEventActions -= 1;
      }
    },
    async updateEvent({ id, brandId, title, startDate, endDate, description }) {
      this.pendingEventActions += 1;
      try {
        return await updateEvent({ id, brandId, title, startDate, endDate, description });
      } finally {
        this.pendingEventActions -= 1;
      }
    },
    async deleteEvent({ id }) {
      this.pendingEventActions += 1;
      try {
        await deleteEvent({ id });
      } finally {
        this.pendingEventActions -= 1;
      }
    },
    setReconnectionMessageDismissed(platform, brandId) {
      if (!this.isReconnectionMessageDismissed(platform, brandId)) {
        this.dismissedReconnectionMessages.push(getPlatformBrandKey(platform, brandId));
      }
      browserStorageSetItem(
        BROWSER_STORAGE_KEYS.DISMISSED_RECONNECTION_MESSAGES,
        this.dismissedReconnectionMessages.join(','),
      );
    },
    clearReconnectionMessageDismissed(platform, brandId) {
      this.dismissedReconnectionMessages = this.dismissedReconnectionMessages.filter(
        (key) => key !== getPlatformBrandKey(platform, brandId),
      );
      browserStorageSetItem(
        BROWSER_STORAGE_KEYS.DISMISSED_RECONNECTION_MESSAGES,
        this.dismissedReconnectionMessages.join(','),
      );
    },
    setReconnectionMessageShown(platform, brandId) {
      if (!this.isReconnectionMessageShown(platform, brandId)) {
        this.shownReconnectionMessages.push(getPlatformBrandKey(platform, brandId));
      }
    },
    setCalendarMode(newMode) {
      validateMode(newMode);
      browserStorageSetItem(BROWSER_STORAGE_KEYS.CALENDAR_MODE, newMode);
      this.calendarMode = newMode;
    },
    setCalendarDate(newDate) {
      validateDate(newDate);
      browserStorageSetItem(BROWSER_STORAGE_KEYS.CALENDAR_DATE, formatISO(newDate));
      this.calendarDate = newDate;
    },
    async fetchPosts(query, { urls, limit } = {}) {
      if (!query.brandId) throw new Error('A brand must be specified to fetch posts!');
      if (this.lastQueryAt && Date.now() - this.lastQueryAt > 10 * 60 * 1000) {
        // Clear out stale data after 10 minutes
        this.successfulQueries = [];
        this.loadedQueries = [];
        this.lastQueryAt = null;
      }
      if (!urls && (this.wasSuccessful(query) || this.isPending(query))) {
        await this.getPendingRequest(query);
        return;
      }

      const params = {
        brandId: query.brandId,
        sort: query.sort,
        status: query.statuses,
        hasScheduledTime: query.hasScheduledTime,
        ...(query.interval && {
          start: formatISO(query.interval.start),
          end: formatISO(query.interval.end),
        }),
        limit,
      };

      const request = Promise.allSettled(
        urls
          ? urls.map(async ({ url, platform }) => ({
              platform,
              ...(await axios.get(url)),
            }))
          : query.platforms.map(async (platform) => ({
              platform,
              ...(await fetchPosts({ ...params, platform })),
            })),
      );

      if (!urls) this.pendingRequests.push({ query, request });

      let platformsWithErrors = [...query.platforms];

      try {
        const responses = await request;

        const successfulPayloads = responses
          .filter(({ value }) => value)
          .map(({ value: { platform, data } }) => ({ platform, ...data }));

        const posts = successfulPayloads.flatMap(({ platform, data }) =>
          data.map(parsePost(platform)),
        );
        const nextPageURLs = successfulPayloads
          .map(({ platform, paging: { next } }) => ({ platform, url: next }))
          .filter(({ url }) => url);

        this.postsByKey = { ...this.postsByKey, ...mapPostsByKey(posts) };
        this.nextPageURLsByQuery = {
          ...this.nextPageURLsByQuery,
          [query]: nextPageURLs.length && nextPageURLs,
        };

        if (successfulPayloads.length === responses.length && !this.wasSuccessful(query)) {
          this.lastQueryAt = Date.now();
          this.successfulQueries.push(query);
        }

        const successfulPlatforms = successfulPayloads.map(({ platform }) => platform);
        platformsWithErrors = platformsWithErrors.filter(
          (platform) => !successfulPlatforms.includes(platform),
        );

        const errors = responses.map(({ reason }) => reason).filter((reason) => reason);
        if (errors.length > 0) {
          throw new Error(errors);
        }
      } finally {
        this.pendingRequests = this.pendingRequests.filter(
          (pendingRequest) => pendingRequest.query !== query,
        );
        if (!this.isLoaded(query)) this.loadedQueries.push(query);
        this.platformsWithErrors = [
          ...new Set([...this.platformsWithErrors, ...platformsWithErrors]),
        ];
      }
    },
    async fetchNextPage(query) {
      const urls = this.nextPageURLsByQuery[query];
      if (urls) {
        await this.fetchPosts(query, { urls });
      }
    },
    replacePost(post) {
      const { platform } = post;
      validatePlatform(platform);
      this.postsByKey = {
        ...this.postsByKey,
        [getPostKey(post)]: Object.freeze(parsePost(platform)(post)),
      };
    },
    removePost({ platform, id }) {
      validatePlatform(platform);
      const postKey = getPostKey({ platform, id });
      const newValue = {
        ...this.postsByKey,
      };
      delete newValue[postKey];
      this.postsByKey = newValue;
    },
    async fetchPost({ platform, id }) {
      this.pending.fetchPost = true;
      validatePlatform(platform);
      const response = await fetchPost({ platform, id });
      const post = parsePost(platform)(response.data);
      this.postsByKey = {
        ...this.postsByKey,
        [getPostKey({ platform, id })]: Object.freeze(post),
      };
      this.pending.fetchPost = false;
    },
    async createPost({ platform, approvalRequests, ...data }) {
      validatePlatform(platform);
      this.pendingPostActions += 1;
      data.approvalRequests = this.approvalRequests.map((request) => {
        return { requestedByUserId: request.requestedByUserId, reviewUserId: request.reviewUserId };
      });
      try {
        const response = await createPost({ platform, data });

        const post = parsePost(platform)(response.data);
        this.duplicationPostId = post.id;
        this.postsByKey = {
          ...this.postsByKey,
          [getPostKey(post)]: Object.freeze(post),
        };
      } finally {
        this.pendingPostActions -= 1;
      }
    },
    async updatePost({ platform, id, approvalRequests, ...data }) {
      validatePlatform(platform);
      this.pendingPostActions += 1;
      data.approvalRequests = this.approvalRequests.map((request) => {
        return { requestedByUserId: request.requestedByUserId, reviewUserId: request.reviewUserId };
      });
      try {
        const response = await updatePost({ platform, id, data });

        const post = parsePost(platform)(response.data);
        this.postsByKey = {
          ...this.postsByKey,
          [getPostKey(post)]: Object.freeze(post),
        };
      } finally {
        this.pendingPostActions -= 1;
      }
    },
    async deletePost({ platform, id }) {
      validatePlatform(platform);
      this.pendingPostActions += 1;
      try {
        await deletePost({ platform, id });
        const postKey = getPostKey({ platform, id });
        const newValue = {
          ...this.postsByKey,
        };
        delete newValue[postKey];
        this.postsByKey = newValue;
      } finally {
        this.pendingPostActions -= 1;
      }
    },
    async reorderPost({ platform, id }, after) {
      validatePlatform(platform);

      const post = this.getPost({ platform, id });
      const postKey = getPostKey(post);
      if (!post) {
        throw new Error('Post must be loaded before reordering!');
      }

      // Optimistically reorder posts
      let newSortIndex = post.sortIndex;
      const originalSortIndex = post.sortIndex;
      const posts = this.getPosts(unscheduledPosts.forPlatform(platform));
      if (after) {
        const afterPost = this.getPost({ platform, id: after });
        const rightNeighbor = posts.filter(({ sortIndex }) => sortIndex < afterPost.sortIndex)[0];
        if (rightNeighbor) {
          newSortIndex = (afterPost.sortIndex + rightNeighbor.sortIndex) / 2;
        } else {
          newSortIndex = afterPost.sortIndex - 1;
        }
      } else {
        newSortIndex = posts[0].sortIndex + 1;
      }

      this.postsByKey = {
        ...this.postsByKey,
        [postKey]: Object.freeze({ ...post, sortIndex: newSortIndex }),
      };

      this.pendingPostActions += 1;
      try {
        await reorderPost({ platform, id, brandId: post.brandId, after });
      } catch (e) {
        this.postsByKey = {
          ...this.postsByKey,
          [postKey]: Object.freeze({ ...post, sortIndex: originalSortIndex }),
        };
        throw e;
      } finally {
        this.pendingPostActions -= 1;
      }
    },
    async getTwitterLocationSearchList({ query }) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand?.id;

      this.pending.twitterLocationSearchList += 1;
      try {
        const response = await TwitterAPI.getLocations({ brandId, query });
        const payload = response?.data;

        this.twitterLocationSearchList = payload;
        this.locationRateLimit = response.headers['x-rate-limit-limit'];
        const locationRateLimitRemaining = response.headers['x-rate-limit-remaining'];
        const locationRateLimitReset = response.headers['x-rate-limit-reset'];
        if (locationRateLimitRemaining === '0') {
          this.locationRateLimitReached = true;
          const rateLimitResetTime = parseInt(locationRateLimitReset, 10) * 1000 - Date.now();
          setTimeout(() => {
            this.locationRateLimitReached = false;
          }, rateLimitResetTime);
        }

        return payload;
      } catch (e) {
        this.locationRateLimit = e.response.headers['x-rate-limit-limit'];
        const locationRateLimitRemaining = e.response.headers['x-rate-limit-remaining'];
        const locationRateLimitReset = e.response.headers['x-rate-limit-reset'];
        if (locationRateLimitRemaining === '0') {
          this.locationRateLimitReached = true;
          const rateLimitResetTime = parseInt(locationRateLimitReset, 10) * 1000 - Date.now();
          setTimeout(() => {
            this.locationRateLimitReached = false;
          }, rateLimitResetTime);
        }
        throw e;
      } finally {
        this.pending.twitterLocationSearchList -= 1;
      }
    },
    clearTwitterLocationSearchList() {
      this.twitterLocationSearchList = [];
    },
    async getInstagramLocationSearchList({ query, limit }) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand?.id;

      this.pending.instagramLocationSearchList += 1;
      try {
        const response = await FacebookAPI.getLocations({ brandId, query, limit });
        const payload = response?.data;

        this.instagramLocationSearchList = payload;

        return payload;
      } finally {
        this.pending.instagramLocationSearchList -= 1;
      }
    },
    clearInstagramLocationSearchList() {
      this.instagramLocationSearchList = [];
    },
    async getFacebookLocationSearchList({ query, limit }) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand?.id;

      this.pending.facebookLocationSearchList += 1;
      try {
        const response = await FacebookAPI.getLocations({ brandId, query, limit });
        const payload = response?.data;

        this.facebookLocationSearchList = payload;

        return payload;
      } finally {
        this.pending.facebookLocationSearchList -= 1;
      }
    },
    clearFacebookLocationSearchList() {
      this.facebookLocationSearchList = [];
    },
    async facebookAdGeolocationSearch(locationFields) {
      const authStore = useAuthStore();
      const brandId = authStore.currentBrand?.id;

      this.pending.facebookAdGeolocations += 1;
      try {
        const promises = locationFields.map((locationField) =>
          FacebookAPI.getLocations({
            brandId,
            locationType: 'adgeolocation',
            locationFields: locationField,
            limit: 1000,
          }),
        );
        const results = await Promise.all(promises);
        results.forEach((result) => {
          const type = result.data[0]?.type;
          this.facebookAdGeolocations = {
            ...this.facebookAdGeolocations,
            [camelize(type)]: camelizeKeys(result.data),
          };
        });
        this.errors.facebookAdGeolocations = null;
      } catch (error) {
        this.errors.facebookAdGeolocations = error;
      } finally {
        this.pending.facebookAdGeolocations -= 1;
      }
    },
    setInstagramTabContext(tabContext) {
      this.instagramTabContext = tabContext;
    },
    setInstagramCarouselPage({ carouselPage, activeMedia }) {
      this.instagramCarouselPage = carouselPage;
      this.instagramCarouselActiveMedia = activeMedia;
    },
    setAutoPublish(newAutoPublish) {
      this.autoPublish = newAutoPublish;
    },
    setActiveSubScreen(subScreen) {
      this.activeSubScreen = subScreen;
    },
    setThumbOffset(thumbOffset) {
      this.thumbOffset = thumbOffset;
    },
    setThumbnailSource(thumbnailSource) {
      this.thumbnailSource = thumbnailSource;
    },
    openPopup({ type, data = null }) {
      this.popupType = type;
      this.popupData = data;
    },
    closePopup() {
      this.popupType = null;
      this.popupData = null;
    },
    clearPendingPostDict() {
      this.pendingPostDict = {};
    },
    updatePendingPostStatus({ id, result }) {
      if (id && result) {
        this.pendingPostDict[id] = result;
      }
    },

    toggleSidePanel({ router, platform, post }) {
      const routeName = window.location.href.includes('comments')
        ? `scheduler.${platform.toLowerCase()}.posts`
        : `scheduler.${platform.toLowerCase()}.posts.comments`;

      router.push({
        name: routeName,
        params: {
          id: post?.id || 'new',
        },
        query: {
          replaceModal: true,
        },
      });
    },

    updatePostPolicy({ id, platform }) {
      const post = this.getPost({ platform, id });
      this.postsByKey = {
        ...this.postsByKey,
        [getPostKey(post)]: Object.freeze({
          ...post,
          approvalPolicy:
            post.approvalPolicy === APPROVAL_POLICY.APPROVAL_REQUIRED
              ? APPROVAL_POLICY.APPROVAL_NOT_REQUIRED
              : APPROVAL_POLICY.APPROVAL_REQUIRED,
        }),
      };
    },
    addApprovalRequests(newApprovalRequests) {
      const currentReviewUserIds = this.approvalRequests.map((request) => request.reviewUserId);
      const uniqueNewApprovalRequests = newApprovalRequests.filter(
        (request) => !currentReviewUserIds.includes(request.reviewUserId),
      );
      this.approvalRequests = [...this.approvalRequests, ...uniqueNewApprovalRequests];
    },

    async updateApprovalRequest({ postId, platform, approvalRequestId, approvalStatus }) {
      this.savingApprovalRequestStatus = true;
      const approvalRequest = await updateApprovalRequest({
        postId,
        platform,
        approvalRequestId,
        approvalStatus,
      });

      const post = this.getPost({ platform, id: postId });

      const updatedApprovalRequests = post.approvalRequests.map((req) =>
        req.id === approvalRequestId ? approvalRequest.data : req,
      );

      const numOfApprovalRequired = post?.numOfApprovalsRequired;
      const numOfApprovedRequests = updatedApprovalRequests?.filter((request) => {
        return request.approvalStatus === APPROVAL_STATUS.APPROVED;
      }).length;

      const newPostApprovalStatus =
        numOfApprovedRequests >= numOfApprovalRequired
          ? APPROVAL_STATUS.APPROVED
          : APPROVAL_STATUS.PENDING;

      this.postsByKey = {
        ...this.postsByKey,
        [getPostKey(post)]: Object.freeze({
          ...post,
          approvalStatus: newPostApprovalStatus,
          approvalRequests: updatedApprovalRequests,
        }),
      };
      this.approvalRequests = updatedApprovalRequests;
      this.savingApprovalRequestStatus = false;
    },

    async listRecommendedPublishTimes({ brandId, platform }) {
      const response = await getRecommendedPublishTimes({ brandId, platform });
      this.recommendedPublishTimes[brandId] = {
        ...this.recommendedPublishTimes[brandId],
        [platform]: response?.data?.data,
      };
      return this.recommendedPublishTimes[brandId][platform];
    },
    clearRecommendedPublishTimes() {
      this.recommendedPublishTimes = {};
    },
  },
});
