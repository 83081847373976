<template>
  <div class="tag-box">
    <ToggleSwitch
      v-if="hasToggle"
      :toggle-text="toggleOptions"
      :switcher-is-on="toggledOn"
      class="tags-filter-toggle"
      @on-change="toggleTagsFilter"
    />
    <div
      v-for="tag in tags"
      :key="tag.id"
      :style="borderStyleObject(tag)"
      class="tag border"
      @click="onTagClicked(tag)"
    >
      <span :style="{ backgroundColor: tag.color }" class="color" />
      <span class="tag-name">
        {{ tag.name }}
      </span>
      <Icon
        v-if="showDelete"
        :color="closeIconColor"
        name="close"
        class="close-icon"
        xxsmall
        @click="removeTag(tag)"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useNotificationStore } from '@/stores/notification';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import ToggleSwitch from '@/components/ToggleSwitch.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'Tags',
  components: {
    Icon,
    ToggleSwitch,
  },
  props: {
    tags: { type: Array, default: null },
    onTagClicked: { type: Function, default: () => null },
    showDelete: { type: Boolean, default: false },
    onDeleteClicked: { type: Function, default: () => null },
    hasToggle: { type: Boolean, default: false },
    updateTagsFilter: { type: Function, default: () => null },
    filterType: { type: String, default: null },
  },
  emits: ['addTag'],
  data() {
    return {
      closeIconColor: colours.ICON.ICON_SECONDARY,
      closeIconHoverColor: colours.ACTION.ACTION_500,
      toggleOptions: ['AND', 'OR'],
    };
  },
  computed: {
    ...mapStores(useNotificationStore),
    toggledOn() {
      return this.filterType && this.filterType === 'OR';
    },
  },
  methods: {
    addTag(tag) {
      this.$emit('addTag', tag);
    },
    borderStyleObject(tag) {
      if (tag.showBorder) {
        return {
          border: `2px solid ${tag.color}`,
        };
      }
      return null;
    },
    toggleTagsFilter(option) {
      if (option === 'on') {
        this.updateTagsFilter('OR');
      } else {
        this.updateTagsFilter('AND');
      }
    },
    removeTag(tag) {
      this.onDeleteClicked(tag);
      this.notificationStore.setToast({ message: 'Tag has been removed.' });
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.tag-box {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: var(--space-16);
  margin-top: var(--space-16);

  label.tags-filter-toggle {
    margin: 0 var(--space-8) var(--space-8) 0;
  }

  .tag {
    display: flex;
    align-items: center;
    flex: 0 auto;
    background: var(--background-0);
    height: 2rem;
    font-size: var(--x14);
    padding: 0 var(--space-24) 0 var(--space-12);
    margin: 0 var(--space-8) var(--space-8) 0;
    cursor: default;
    transition: var(--transition-all);

    .color {
      display: inline-block;
      width: 0.625rem;
      height: 0.625rem;
      margin-right: var(--space-8);
      border-radius: 50%;
      background: #adadad;
    }

    .tag-name {
      transform: translateY(-1px);
    }

    .svg-icon {
      margin-left: var(--space-8);
      margin-right: calc(-1 * var(--space-8));
    }
  }

  .border {
    border: 2px solid transparent;
    border-radius: 1.1875rem;
    cursor: pointer;
  }

  .tag:hover {
    box-shadow: var(--shadow-1);
    transform: var(--hover-move);
  }
}
</style>
