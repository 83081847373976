<template>
  <div
    :class="[
      'content-rights-comments-block',
      { disabled: disabled },
      { 'editing-block': isEditing },
    ]"
  >
    <img
      v-if="authStore.currentBrand.avatarUrl"
      :src="authStore.currentBrand.avatarUrl"
      class="content-rights-comments-avatar"
    />
    <div class="content-rights-comments-editor">
      <div v-if="!isEditing" v-sanitize-html="requestCommentHtml" class="comment-content" />
      <div v-else class="text-area" @click="isSaving = false">
        <Textarea
          ref="commentTextArea"
          v-model="requestComment.commentText"
          :class="[{ hasError: errorTextArea }]"
          :placeholder="placeholder"
          class="comment-content"
        />
      </div>
      <div v-if="!isEditing" class="tools">
        <a>
          <Icon
            :hover-color="iconHoverColor"
            class="edit-button"
            name="pencil"
            @click="editRequestComment"
          />
        </a>
        <a>
          <Icon :hover-color="iconHoverColor" class="icon-bin-class" name="bin" @click="onDelete" />
        </a>
      </div>
      <template v-else>
        <div class="insert-button" @click="isSaving = false">
          <a
            @click="showDocumentDropdown"
            @mouseover="addDocButtonHovered = true"
            @mouseleave="addDocButtonHovered = false"
          >
            <button>
              <Icon
                :color="addDocButtonHovered ? iconHoverColor : null"
                name="fileNotesDocument"
                xxsmall
              />
              <span>Insert Term Doc</span>
            </button>
          </a>
          <a
            class="insert-hashtag-button"
            @mouseover="addHashtagButtonHovered = true"
            @mouseleave="addHashtagButtonHovered = false"
          >
            <button @click="insertText(hashtagValue)">
              <Icon :color="addHashtagButtonHovered ? iconHoverColor : null" name="tag" xxsmall />
              <span>Insert Tracking Hashtag</span>
            </button>
          </a>
        </div>
        <div v-if="showDocumentList" class="document-list" @mouseleave="showDocumentList = false">
          <DocumentList
            :document-list="termLinks"
            @on-click-outside="onHideDocumentList"
            @on-select-document="onSelectDocument"
          />
        </div>
        <div v-if="isSaving && formErrorMessage" class="form-error">{{ formErrorMessage }}.</div>
        <div class="right-buttons">
          <Button small @click="offEdit()">Cancel</Button>
          <Button
            :loading="loading"
            :disabled="!requestComment.commentText"
            class="save-button"
            small
            primary
            @click="onSave()"
          >
            &nbsp;Save&nbsp;
          </Button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import humps from 'humps';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';

import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import Button from '@/components/foundation/Button.vue';
import Textarea from '@/components/Textarea.vue';
import DocumentList from '@/components/ContentRights/DocumentList.vue';
import { useInstagramRightsRequestStore } from '@/stores/instagram-rights-request';
import { useInstagramContentRightsStore } from '@/stores/instagram-content-rights';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ContentRightsComment',
  components: {
    Icon,
    Button,
    Textarea,
    DocumentList,
  },
  props: {
    initialRequestComment: { type: Object, required: true },
    handle: { type: String, required: true },
    checked: { type: Boolean, default: false },
  },
  emits: ['onRemoveBuffer', 'deleteClicked'],
  data() {
    return {
      addDocButtonHovered: false,
      addHashtagButtonHovered: false,
      requestComment: this.initialRequestComment,
      iconHoverColor: colours.ACTION.ACTION_500,
      disabled: false,
      isEditing: false,
      isSaving: false,
      placeholder: 'Enter Your Request Comment',
      commentTextBeforeEdit: this.initialRequestComment.commentText,
      showDocumentList: false,
      hashtagValue: `#${this.initialRequestComment.hashtag.trackingHashtag}`,
    };
  },
  computed: {
    ...mapStores(
      useNotificationStore,
      useInstagramRightsRequestStore,
      useAuthStore,
      useInstagramContentRightsStore,
    ),
    termLinks() {
      return humps.camelizeKeys(this.instagramContentRightsStore.rightsRequestDocuments.data || []);
    },
    termValue() {
      return this.requestComment.document.termLink;
    },
    loading() {
      return false;
    },
    requestCommentHtml() {
      const termLinkRegExp = new RegExp(this.termValue, 'g');
      const termLinkReplacer = (match) =>
        `<a href = "${match}" class = "figure-blue" target="_blank" rel="noopener">${match}</a>`;
      const tagExp = new RegExp(`${this.hashtagValue}|@${this.handle}`, 'g');
      const tagReplacer = (match) => `<b class = "figure-blue">${match}</b>`;
      return this.requestComment.commentText
        .replace(termLinkRegExp, termLinkReplacer)
        .replace(tagExp, tagReplacer);
    },
    errorTextArea() {
      if (this.formErrorMessage && this.isSaving) {
        return true;
      }
      return false;
    },
    formErrorMessage() {
      let termLinkValid = true;
      const termLinkArray = this.termLinks.map((element) => {
        return element.termLink;
      });
      const termLinkRegExp = new RegExp(termLinkArray.join('|'), 'g');
      const termLinkMatches = this.requestComment.commentText.match(termLinkRegExp);
      if (!termLinkMatches) {
        return `Request must contain one term link, your saved tracking # and @${this.handle}`;
      }
      if (termLinkMatches.length > 1) {
        termLinkValid = false;
      }
      const handleRegExp = new RegExp(`@\\s*${this.handle}`);
      if (
        !termLinkValid ||
        !this.requestComment.commentText.includes(this.hashtagValue) ||
        !handleRegExp.test(this.requestComment.commentText)
      ) {
        if (termLinkValid) {
          return `Request must contain one term link, your saved tracking # and @${this.handle}`;
        }
        return `Request must contain only one term link, your saved tracking # and @${this.handle}`;
      }
      return null;
    },
  },
  watch: {
    'instagramContentRightsStore.hashtag.tracking_hashtag': function trackingHashtag(to) {
      if (to) {
        const commentText = this.convertCommentTextToComment();
        const commentTextBeforeEditing = this.convertCommentTextToComment({
          commentText: this.commentTextBeforeEdit,
        });
        const newHashtag = `#${to}`;
        this.requestComment.commentText = this.convertCommentToCommentText({
          commentText,
          hashtagValue: newHashtag,
        });
        this.commentTextBeforeEdit = this.convertCommentToCommentText({
          commentTextBeforeEditing,
          termValue: this.termValue,
          hashtagValue: newHashtag,
        });
        this.hashtagValue = newHashtag; // This will re-compute comment HTML
      }
    },
  },
  methods: {
    showDocumentDropdown() {
      this.showDocumentList = true;
    },
    onSelectDocument(document) {
      this.insertText(document.termLink);
      this.requestComment.document = document;
      this.showDocumentList = false;
    },
    onHideDocumentList() {
      this.showDocumentList = false;
    },
    offEdit() {
      if (this.requestComment.isBuffer) {
        this.$emit('onRemoveBuffer');
        return;
      }
      this.requestComment.commentText = this.commentTextBeforeEdit;
      this.isEditing = false;
      if (this.$parent.setIsEditing) {
        this.$parent.setIsEditing(false);
      }
    },
    convertCommentTextToComment({ commentText, termValue, hashtagValue } = {}) {
      const comment = commentText || this.requestComment.commentText;
      const term = termValue || this.termValue;
      const hashtag = hashtagValue || this.hashtagValue;
      const termLinkRegExp = new RegExp(term, 'g');
      const hashTagRegExp = new RegExp(hashtag, 'g');
      const instagramHandleRegExp = new RegExp(`@\\s*${this.handle}`, 'g');
      return comment
        .replace(termLinkRegExp, '#t----t#')
        .replace(hashTagRegExp, '#h----h#')
        .replace(instagramHandleRegExp, `@${this.handle}`);
    },
    convertCommentToCommentText({ commentText, termValue, hashtagValue } = {}) {
      const comment = commentText || this.requestComment.commentText;
      const term = termValue || this.termValue;
      const hashtag = hashtagValue || this.hashtagValue;
      const termLinkRegExp = /#t----t#/g;
      const hashTagRegExp = /#h----h#/g;
      const instagramHandleRegExp = new RegExp(`@\\s*${this.handle}`, 'g');
      return comment
        .replace(termLinkRegExp, term)
        .replace(hashTagRegExp, hashtag)
        .replace(instagramHandleRegExp, `@${this.handle}`);
    },
    async onSave() {
      this.isSaving = true;
      if (this.formErrorMessage) {
        return;
      }
      const comment = this.convertCommentTextToComment();
      const requestDocumentId = this.requestComment.document.id;
      const requestHashtagId = this.requestComment.hashtag.id;
      if (this.requestComment.isBuffer) {
        await this.instagramRightsRequestStore.addRightsRequestComment({
          comment,
          requestDocumentId,
          requestHashtagId,
        });
        this.$emit('onRemoveBuffer');
      } else {
        const commentId = this.requestComment.id;
        await this.instagramRightsRequestStore.updateRightsRequestComment({
          commentId,
          comment,
          requestDocumentId,
          requestHashtagId,
        });
      }
      this.commentTextBeforeEdit = this.requestComment.commentText;
      this.isSaving = false;
      this.isEditing = false;
      if (this.$parent.setIsEditing) {
        this.$parent.setIsEditing(false);
      }
      this.notificationStore.setToast({ message: 'Request Comment is updated.' });
    },
    insertText(insertText) {
      const myArea = this.$refs.commentTextArea.$refs.textArea;
      // get cursor's position:
      const startPos = myArea.selectionStart;
      const endPos = myArea.selectionEnd;
      const tmpStr = myArea.value;
      const endSpace = /\s$/;
      let shift = 0;
      let prefix = tmpStr.substring(0, startPos);
      if (!endSpace.test(prefix)) {
        prefix += ' ';
        shift = 1;
      }
      const newValue = `${prefix}${insertText}${tmpStr.substring(endPos, tmpStr.length)}`;
      this.requestComment.commentText = newValue;
      // eslint-disable-next-line func-names
      window.setTimeout(function () {
        myArea.focus();
        myArea.setSelectionRange(
          startPos + insertText.length + shift,
          startPos + insertText.length + shift,
        );
      }, 0);
    },
    async editRequestComment() {
      if (this.checked !== true) {
        this.$parent.checked = true;
      }
      if (await !this.disabled) {
        this.isEditing = true;
        if (await this.$parent.setIsEditing) {
          this.$parent.setIsEditing(true);
        }
      }
      const myArea = this.$refs.commentTextArea.$refs.textArea;
      // eslint-disable-next-line func-names
      window.setTimeout(function () {
        myArea.focus();
      }, 0);
    },
    onDelete() {
      this.$emit('deleteClicked', this.requestComment.id);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.content-rights-comments {
  &-block {
    transition: var(--transition-all);
    border-radius: var(--round-corner);
    box-shadow: var(--shadow-1);
    padding: var(--space-32);
    display: flex;
    margin-bottom: var(--space-16);
    background-color: var(--background-0);
  }

  &-avatar {
    height: var(--space-40);
    width: var(--space-40);
    border-radius: 50%;
    margin-right: var(--space-24);
  }

  &-editor {
    font-size: var(--x14);
    line-height: var(--x20);
    width: 100%;

    &-tools {
      margin-top: var(--space-32);

      a {
        padding-top: var(--space-8);
      }

      .icon-bin-class {
        margin-left: var(--space-24);
      }
    }

    :deep(.dh-text-area textarea) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    :deep(.hasError textarea) {
      border: 1px solid var(--error-500);
    }

    :deep(.figure-blue) {
      color: var(--action-500);
    }

    .tools {
      display: flex;
      justify-content: flex-start;
      margin-top: var(--space-16);

      .icon-bin-class {
        margin-left: var(--space-24);
      }
    }

    .insert-button {
      display: flex;
      flex-wrap: nowrap;
      height: var(--space-24);
      width: 100%;
      border: 1px solid var(--border);
      background: var(--background-300);
      border-radius: var(--round-corner-small);
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      a {
        flex: 1 1 auto;
        border-right: 1px solid var(--border);

        :hover {
          color: var(--action-500);
        }

        button {
          position: relative;
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          font-size: var(--x12);
          color: var(--text-primary);
          font-weight: var(--font-normal);
          cursor: pointer;

          span {
            margin-left: var(--space-8);
          }
        }
      }

      .insert-hashtag-button {
        border: none;
      }
    }

    .comment-content {
      width: 100%;
      cursor: auto;
      text-align: left;
      user-select: text;

      :deep(a) {
        font-weight: var(--font-normal);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .document-list {
      margin-top: var(--space-8);
    }

    .right-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: var(--space-16);

      button:first-child {
        margin-right: var(--space-8);
      }
    }

    .form-error {
      text-align: left;
      color: var(--error-500);
      padding: 0.75rem 1rem;
      border-radius: var(--round-corner);
      background: var(--error-100);
      font-size: var(--x13);
      margin-top: var(--space-8);
    }
  }
}

.editing-block {
  box-shadow: var(--shadow-4);
}
</style>
