<script setup>
import { defineAsyncComponent, watch, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Drawer, useDrawer } from '@dashhudson/dashing-ui';

const route = useRoute();
const router = useRouter();

const { registerDrawer, stateKey, launchDrawer, closeDrawer } = useDrawer();

// Centralized drawer registration
registerDrawer(
  'creatorDeliverablesPosts',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/CreatorDeliverablesPosts.vue')),
);
registerDrawer(
  'creatorProfile',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/CreatorProfile.vue')),
);
registerDrawer(
  'accountProfile',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/AccountProfile.vue')),
);
registerDrawer(
  'assignDeliverables',
  defineAsyncComponent(() => import('@/app/campaigns/drawers/Deliverables/AssignDeliverables.vue')),
);
registerDrawer(
  'topPerformingCreators',
  defineAsyncComponent(() => import('@/app/campaigns/drawers/TopPerformingCreators.vue')),
);
registerDrawer(
  'addCreator',
  defineAsyncComponent(() => import('@/app/campaigns/drawers/Creators/AddCreatorsWizard.vue')),
);
registerDrawer(
  'creatorEmailThread',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/CreatorEmailThread.vue')),
);

registerDrawer(
  'emailInvitation',
  defineAsyncComponent(() => import('@/app/creators/drawers/Profile/EmailInvitation.vue')),
);

registerDrawer(
  'socialListeningTopicCreator',
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningTopicCreator.vue'),
  ),
);

registerDrawer(
  'socialListeningTrendingKeyword',
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningTrendingKeywords.vue'),
  ),
);
registerDrawer(
  'socialListeningTopPerformingPosts',
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningTopPerformingPosts.vue'),
  ),
);
registerDrawer(
  'socialListeningVisualTrends',
  defineAsyncComponent(
    () => import('@/app/socialListening/drawers/SocialListeningVisualTrends.vue'),
  ),
);

registerDrawer(
  'campaignCreation',
  defineAsyncComponent(() => import('@/app/campaigns/pages/CampaignCreationDrawer.vue')),
);

// Watch for stateKey changes and update the route query
watch(
  () => stateKey.value,
  (sk) => {
    if (sk) {
      router.push({ query: { ...route.query, d: sk } });
    } else {
      router.push({ query: { ...route.query, d: null } });
    }
  },
);

onMounted(() => {
  if (route.query?.d) {
    launchDrawer({ ...route.query, stateKey: route.query.d });
  } else {
    closeDrawer();
  }
});
</script>

<template>
  <Drawer />
</template>
