<template>
  <div class="first-comment-status-container">
    <Banner alert-type="danger" class="info-alert mb-8">
      <span>
        The first comment did not publish successfully.
        <br />
        <button @click.prevent="copyFirstComment">Copy First Comment</button>
      </span>
    </Banner>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useNotificationStore } from '@/stores/notification';
import Banner from '@/components/foundation/feedback/Banner.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'FirstCommentStatusBox',
  components: {
    Banner,
  },
  props: {
    post: { type: Object, default: null },
  },
  computed: {
    ...mapStores(useNotificationStore),
    firstComment() {
      if (this.post && this.post.first_comment) {
        return this.post.first_comment;
      }
      return null;
    },
  },
  methods: {
    copyFirstComment() {
      // Copy to the clipboard
      if (this.firstComment) {
        const textArea = document.createElement('textarea');
        textArea.value = this.firstComment;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        this.notificationStore.setToast({ message: 'Copied to clipboard.' });
      }
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.first-comment-status-container {
  width: 100%;

  .info-alert {
    :deep(button) {
      all: unset;
      background: none;
      border: none;
      padding: 0;
      margin-top: var(--space-8);
      cursor: pointer;
      -webkit-text-fill-color: var(--action-500);
      font-weight: var(--font-normal);
    }
  }

  :deep(.information-box) {
    margin-bottom: var(--space-16);
  }
}
</style>
