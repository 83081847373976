<template>
  <div v-if="hasFacebookReelsSchedulingFlag" class="publish-as-options">
    <p class="label">Publish As:</p>
    <div class="radio-group-container">
      <div v-for="option in options" :key="option.value" class="radio-option">
        <RadioInputGroup
          v-model="radioSelection"
          :options="[option]"
          name="publish-as-options"
          class="radio-group"
        />
      </div>
    </div>
    <div v-if="hasFacebookReelsSchedulingChipFlag" class="new-badge-container flex">
      <span class="new-badge h-6">NEW!</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import RadioInputGroup from '@/components/RadioInputGroup.vue';
import { useFlagStore } from '@/stores/flag';
import { fbPostTypes } from '@/app/scheduler/constants';

export default defineComponent({
  name: 'PublishAsOptions',
  components: {
    RadioInputGroup,
  },
  props: {
    value: { type: String, default: fbPostTypes.POST },
    disabled: { type: Boolean, default: false },
  },
  emits: ['input'],
  data() {
    return {
      radioSelection: this.value,
    };
  },
  computed: {
    ...mapStores(useFlagStore),
    options() {
      return [
        {
          label: 'Post',
          value: fbPostTypes.POST,
          disabled: this.disabled,
        },
        {
          label: 'Reel',
          value: fbPostTypes.REEL,
          disabled: this.disabled,
        },
      ];
    },
    hasFacebookReelsSchedulingFlag() {
      return this.flagStore.ready && this.flagStore.flags.facebookReelsScheduling;
    },
    hasFacebookReelsSchedulingChipFlag() {
      return this.flagStore.ready && this.flagStore.flags.facebookReelsSchedulingChip;
    },
  },
  watch: {
    radioSelection(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('input', newVal);
      }
    },
    value(newVal) {
      if (newVal !== this.radioSelection) {
        this.radioSelection = newVal;
      }
    },
  },
});
</script>

<style scoped lang="postcss">
.publish-as-options {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: var(--space-8);
  border-bottom: 1px solid lightgray;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.label {
  font-size: var(--x14);
  margin-right: var(--space-16);
}

.radio-group-container {
  display: flex;
  align-items: center;
  gap: var(--space-16);
}

.radio-group {
  display: flex;
  gap: var(--space-16);
}

.radio-option {
  display: flex;
  padding-top: 0.4rem;
}

.new-badge-container {
  margin-left: 0.2rem;
  margin-bottom: 0.8rem;
  margin-top: 0.5rem;
}
</style>
