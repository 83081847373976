<template>
  <div :class="{ full: fullWidth }" class="multi-select-popup">
    <CircularLoader v-if="loading" />
    <div v-else>
      <slot name="topNav" />
      <input
        id="searchBoardInput"
        v-model="searchTerm"
        :class="['search', { empty: displayItems.length === 0, disabled }]"
        :disabled="disabled"
        :placeholder="searchPlaceholder"
        data-cy="search-board-input"
        @click="inputFocus"
        @input="searchHandler"
      />
      <ul v-if="displayItems.length > 0" :class="{ disabled }">
        <MultiSelectListItem
          v-for="category in displayItems"
          :key="category.id"
          :item="category"
          :disabled="disabled"
          @item-remove="(itemId) => $emit('item-remove', itemId)"
          @item-add="(itemId) => $emit('item-add', itemId)"
        />
      </ul>
      <slot v-else name="emptyListAddOn" />
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import MultiSelectListItem from '@/components/MultiSelectListItem.vue';
import CircularLoader from '@/components/CircularLoader.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'MultiSelectList',
  components: {
    MultiSelectListItem,
    CircularLoader,
  },
  props: {
    items: { type: Array, default: () => [] },
    searchPlaceholder: { type: String, default: null },
    fullWidth: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  emits: ['item-add', 'item-remove'],
  data() {
    return {
      searchTerm: null,
      displayItems: [],
    };
  },
  watch: {
    items(to) {
      this.displayItems = to;
    },
  },
  mounted() {
    this.displayItems = this.items;
  },
  methods: {
    searchHandler() {
      if (this.searchTerm) {
        this.displayItems = this.items.filter((item) => {
          if (item.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1) {
            return item;
          }
          return null;
        });
      } else {
        this.displayItems = this.items;
      }
    },
    inputFocus(e) {
      e.target.focus();
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.disabled {
  opacity: 0.3;
  cursor: auto;
}

.multi-select-popup {
  width: 16rem;
  margin-top: var(--space-4);
  border-radius: var(--round-corner);
  z-index: var(--z-index-popup);
  position: relative;
  background-color: var(--background-0);
  font-weight: var(--font-medium);
  color: var(--text-primary);
  text-align: center;
  box-shadow: var(--shadow-4);

  input.search {
    width: 13.75rem;
    margin: var(--space-24) 0 var(--space-8);
    background: url('@/assets/icons/search.svg') no-repeat var(--space-8) 0.4rem var(--background-0);

    /* have to be exact 2.25rem rather than var(--space-32) or var(--space-40) to align items here. */
    padding: 0 var(--space-32) 0 2.25rem;
    border-radius: var(--space-32);
    height: var(--space-32);
    font-size: var(--x14);
    background-color: var(--background-0);
    color: var(--text-primary);
    border: 1px solid var(--border);
  }

  ul {
    width: 13.75rem;
    max-height: 10.5rem;
    overflow-y: auto;
    padding: 0 var(--space-8);
    margin-bottom: var(--space-8);
    border-radius: var(--round-corner-small);
    background-color: var(--background-0);
    font-weight: var(--font-medium);
    color: var(--text-primary);
    vertical-align: middle;
    display: inline-block;
  }
}

.full {
  width: 100%;
  box-shadow: none;

  input.search {
    width: 100%;
    height: var(--space-40);
    margin-top: 1rem;
    padding-left: 2.5rem;
    background: url('@/assets/icons/search.svg') no-repeat 0.75rem 0.6rem var(--background-0);
  }

  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 50%;
    }
  }
}

.loader {
  padding: var(--space-24) 0;
  margin: 0 auto;
}
</style>
