<template>
  <div class="controls">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'Controls',
});
export default comp;
</script>

<style lang="postcss" scoped>
:slotted(.remove) {
  margin-right: var(--space-24);
}

.controls {
  border-radius: 0 0 var(--round-corner) 0;
  display: flex;
  background-color: var(--background-0);
  padding: var(--space-24);
  margin-top: var(--space-16);
  width: 100%;
  justify-content: center;

  :slotted(button) {
    min-width: 10rem;
  }
}

@media (max-width: 55rem) {
  .controls {
    flex-direction: column;
  }
}
</style>
