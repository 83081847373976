<template>
  <div
    v-on-click-outside="handleClickOutside"
    :class="[{ 'pin-list-item-style': pinListItemStyle }, 'dh-text-area', 'clickable-text-area']"
  >
    <textarea
      :id="textareaId"
      ref="textArea"
      v-model="textContent"
      :style="cssVars"
      :disabled="disabled || readonly"
      :placeholder="placeholder"
      :readonly="readonly"
      :class="['pin-description', 'clickable-text-area', { resizable: resizable }]"
      data-cy="textarea"
      @blur="onBlur"
      @focus="onFocus"
      @input="update"
      @keyup="textAreaAdjust"
      @paste="(e) => onPasteEvent(e)"
    />
    <div v-on-click-outside="onClickOutside" class="clickable-text-area">
      <EmojiPicker
        v-if="showEmojiPicker"
        :class="[{ 'picker-top': showPickerAtTop }, 'picker', 'clickable-text-area']"
        @select="addEmoji"
      />
    </div>

    <Icon
      v-if="enableEmojiPicker && !disabled && showEmojiIcon"
      ref="emojiIcon"
      :color="iconColor"
      :large="!pinListItemStyle"
      :small="pinListItemStyle"
      :class="['icon', 'clickable-text-area']"
      name="smile"
      @click="toggleEmojiPicker"
    />
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import { vOnClickOutside } from '@vueuse/components';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'Textarea',
  components: {
    Icon,
    EmojiPicker: defineAsyncComponent(() => import('@/components/foundation/EmojiPicker.vue')),
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    resizable: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    textareaId: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    enableEmojiPicker: { type: Boolean, default: true },
    focusOnMount: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    value: { type: String, default: '' },
    limit: { type: Number, default: 0 },
    pinListItemStyle: { type: Boolean, default: false },
    onPasteEvent: { type: Function, default: () => {} },
    isAltTextStyle: { type: Boolean, default: false },
    editorHeight: {
      type: String,
      default: null,
    },
  },
  emits: ['onBlur', 'blur', 'onFocus', 'input'],
  data() {
    return {
      showEmojiIcon: !this.pinListItemStyle,
      showEmojiPicker: false,
      showPickerAtTop: null,
      altTextareaHeight: '5rem',
      iconColor: colours.ICON.ICON_SECONDARY,
      textContent: this.$props.value,
      cursorPosition: 0,
    };
  },
  computed: {
    cssVars() {
      let height = '6.5rem';
      if (this.editorHeight) {
        height = this.editorHeight;
      } else if (this.isAltTextStyle) {
        height = '2rem';
      }
      return {
        '--editor-height': height,
      };
    },
  },
  watch: {
    value(to) {
      this.textContent = to;
      if (!to && this.isAltTextStyle) {
        // Reset height of the textarea on text clear
        this.$refs.textArea.style.height = '2rem';
      }
    },
  },
  mounted() {
    if (this.pinListItemStyle) {
      const elements = document.getElementsByClassName('pin-description');
      [].forEach.call(elements, (element) => {
        const o = element;
        o.style.height = 'auto';
        o.style.height = `${element.scrollHeight}px`;
      });
    }
    if (this.focusOnMount) {
      this.focusTextarea();
    }
  },
  methods: {
    handleClickOutside(e) {
      if (
        e.target.classList &&
        !e.target.classList.contains('clickable-text-area') &&
        this.pinListItemStyle
      ) {
        this.showEmojiIcon = false;
      }
    },
    onBlur() {
      if (this.pinListItemStyle) {
        this.$emit('onBlur');
      } else {
        this.$emit('blur');
      }
    },
    onFocus() {
      if (this.pinListItemStyle) {
        this.showEmojiIcon = true;
        this.$emit('onFocus');
      }
    },
    textAreaAdjust(o) {
      const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
      const element = o.target;
      if (this.pinListItemStyle) {
        setTimeout(() => {
          element.style.height = 'auto';
          element.style.height = `${o.target.scrollHeight}px`;
        }, 0);
      } else if (this.isAltTextStyle) {
        setTimeout(() => {
          const elHeight = parseFloat(element.style.height) * rootFontSize; // converting from px to rem
          if (elHeight > parseFloat(this.altTextareaHeight)) {
            this.altTextareaHeight = elHeight;
          }
          if (o.target.scrollHeight < 90) {
            // Expand height and hide the scroll if text not exceeding 3 lines
            element.style.overflowY = 'hidden';
            element.style.height = o.target.scrollHeight <= 44 ? '2rem' : 'auto';
            element.style.height = `${o.target.scrollHeight}px`;
          } else {
            // If the text length exceeds 3 lines.
            element.style.overflowY = 'auto';
            element.style.height = this.altTextareaHeight;
          }
        }, 0);
      }
    },
    setEmojiPickerPosition() {
      const pickerHeight = 300;
      const iconPosition = this.$refs.emojiIcon[1]?.$el.getBoundingClientRect().y;
      this.showPickerAtTop = window.innerHeight - iconPosition <= pickerHeight;
    },
    openEmojiPicker() {
      this.showEmojiPicker = true;
      this.focusTextarea();
      this.setEmojiPickerPosition();
      this.iconColor = colours.ACTION.ACTION_500;
      this.cursorPosition = this.$refs.textArea.selectionStart;
    },
    onClickOutside() {
      if (this.showEmojiPicker) {
        this.closeEmojiPicker();
      }
    },
    closeEmojiPicker() {
      this.showEmojiPicker = false;
      this.iconColor = colours.ICON.ICON_SECONDARY;
    },
    toggleEmojiPicker(e) {
      e.stopPropagation();
      if (this.showEmojiPicker) {
        this.closeEmojiPicker();
      } else {
        this.openEmojiPicker();
      }
    },
    addEmoji(emoji) {
      if (!this.textContent) {
        this.textContent = emoji.native;
      } else {
        this.textContent =
          this.textContent.slice(0, this.cursorPosition) +
          emoji.native +
          this.textContent.slice(this.cursorPosition);
      }
      this.update();
      this.cursorPosition += emoji.native.length;
      this.closeEmojiPicker();
      this.focusTextarea();
    },
    update(e) {
      if (this.isAltTextStyle) {
        this.textAreaAdjust(e);
      }
      if (this.limit && this.textContent.length > this.limit) {
        this.textContent = this.textContent.substr(0, this.limit);
      }
      this.$emit('input', this.textContent);
    },
    // Occasionally the textarea does not focus on click, so we have to force it to.
    focusTextarea() {
      this.$refs.textArea.focus();
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.dh-text-area {
  position: relative;

  textarea {
    padding-top: var(--space-8);
    padding-right: var(--space-48);
    min-height: var(--space-42);
    height: var(--editor-height);
    resize: none;

    &.height-override:not(.resizable) {
      overflow-y: auto;
    }

    &.resizable {
      resize: vertical;
    }
  }

  .icon {
    position: absolute;
    cursor: pointer;
    top: var(--space-8);
    right: var(--space-16);
  }

  .picker {
    position: absolute;
    z-index: var(--z-index-dropdown);
    top: var(--space-40);
    right: var(--space-8);
    margin-bottom: var(--space-32);
  }

  .picker-top {
    top: -300px;
    margin-bottom: 0;
  }
}

.pin-list-item-style {
  height: auto;

  textarea {
    border: none;
    width: 100%;
    height: auto;
    resize: none;

    &:focus {
      background: var(--background-300);
    }
  }
}
</style>
