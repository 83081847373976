<template>
  <VDropdown
    v-if="tooltipEnabled"
    :shown="showTooltip"
    :triggers="[]"
    class="full-width"
    placement="top"
  >
    <p v-if="label" class="label">
      {{ label }}
      <InfoTooltip v-if="labelInfoTooltip" :tooltip="labelInfoTooltip" />
    </p>
    <div v-tooltip="inputTooltip" :class="['setting-option', { disabled }]">
      <div class="option-title" @click="handleClick">
        <Icon v-if="leftIconSize === 'small'" :name="leftIcon" small />
        <Icon v-else :name="leftIcon" />
        <a v-if="title">{{ title }}</a>
        <a v-else class="empty-option">{{ empty }}</a>
      </div>
      <div
        v-if="title && clearAction"
        :class="['clickable-icon', { disabled }]"
        @click.stop="clear"
      >
        <Icon :name="closeIcon" :color="closeIconColor" xsmall />
      </div>
      <div v-else :class="['clickable-icon', { disabled }]" @click="handleClick">
        <Icon :name="openIcon" :color="defaultColor" xsmall />
      </div>
    </div>
    <template #popper>
      <p class="left-content">{{ tooltip }}</p>
      <a v-close-popper class="top-right-icon">
        <Icon :color="closeIconColor" name="close" xxsmall />
      </a>
    </template>
  </VDropdown>
  <div v-else class="postsetting-container">
    <p v-if="label" class="label">
      {{ label }}
      <InfoTooltip v-if="labelInfoTooltip" :tooltip="labelInfoTooltip" />
    </p>
    <div v-tooltip="inputTooltip" :class="['setting-option', { disabled, error: !!errorMessage }]">
      <div class="option-title" @click="handleClick">
        <Icon v-if="leftIconSize === 'small'" :name="leftIcon" small />
        <Icon v-else :name="leftIcon" />
        <a v-if="title">{{ title }}</a>
        <a v-else class="empty-option">{{ empty }}</a>
      </div>
      <div
        v-if="title && clearAction"
        :class="['clickable-icon', { disabled }]"
        @click.stop="clear"
      >
        <Icon :name="closeIcon" :color="closeIconColor" xsmall />
      </div>
      <ApprovalStatusChip
        v-if="empty === 'Add Reviewers'"
        :status="approvalStatusBadge"
        @click="handleClick"
      />
      <div
        v-if="!(title && clearAction)"
        :class="['clickable-icon', { disabled }]"
        @click="handleClick"
      >
        <Icon :name="openIcon" :color="defaultColor" xsmall />
      </div>
    </div>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { toolTips } from '@/config';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import { mapStores } from 'pinia';
import { useFlagStore } from '@/stores/flag';
import ApprovalStatusChip from '@/app/scheduler/components/ApprovalStatusChip.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'PostSettingItem',
  components: {
    ApprovalStatusChip,
    Icon,
    InfoTooltip,
  },
  props: {
    title: { type: String, default: null },
    empty: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    leftIcon: { type: String, required: true },
    leftIconSize: { type: String, default: null },
    closeIcon: { type: String, default: 'close' },
    openIcon: { type: String, default: 'caret' },
    clearAction: { type: Function, default: null },
    onClick: { type: Function, default: null },
    showTooltip: { type: Boolean, default: false },
    label: { type: String, default: null },
    labelInfoTooltip: { type: String, default: null },
    errorMessage: { type: String, default: null },
    approvalStatusBadge: { type: String, default: null },
    inputTooltip: { type: String, default: null },
  },
  data() {
    return {
      tooltip: toolTips.updateLink,
      showClose: false,
      displayTitle: null,
      defaultColor: colours.TEXT.TEXT_SECONDARY,
      closeIconColor: colours.ICON.ICON_PRIMARY,
    };
  },
  computed: {
    ...mapStores(useFlagStore),
    tooltipEnabled() {
      return this.empty === 'Add Links';
    },
  },
  methods: {
    clear() {
      if (!this.disabled) {
        this.clearAction();
      }
    },
    handleClick() {
      if (!this.disabled && this.onClick) {
        this.onClick();
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss">
.disabled {
  opacity: 0.4;
  cursor: default;

  a {
    cursor: default;

    /*
     TODO: Refactor this component to be wrapped by an anchor tag, therefore eliminating the need
     to get overly specific with overrides here. Taking the quick way out and using !important
     until we can do this.
    */
    color: var(--text-primary) !important;

    &:hover {
      color: var(--text-primary) !important;
    }
  }
}

.setting-option {
  width: 100%;
  padding-left: var(--space-16);
  height: var(--space-40);
  border: solid 1px var(--border);
  border-radius: var(--round-corner-small);
  background: var(--background-300);
  cursor: pointer;
  margin-bottom: var(--space-16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--x14);

  &.error:not(.disabled) {
    border: solid 1px var(--error-500);
  }

  .option-title {
    display: flex;
    flex-wrap: nowrap;
    flex: 1 1 auto;
    max-width: 90%;
    align-items: center;
    text-align: left;

    svg {
      margin-right: var(--space-16);
      flex: 0 0 auto;
    }

    a {
      flex: 1 1 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--action-500);
    }

    a.empty-option {
      color: var(--text-primary);
    }
  }

  .clickable-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: var(--space-48);
    cursor: pointer;

    &:hover svg {
      fill: var(--action-500) !important;
    }
  }

  .disabled {
    cursor: default;
  }

  &:hover {
    .option-title .empty-option {
      color: var(--action-500);
    }
  }
}

.setting-option.disabled {
  opacity: 0.4;
  cursor: not-allowed;

  a {
    cursor: not-allowed;

    /*
      TODO: Refactor this component to be wrapped by an anchor tag, therefore eliminating the need
      to get overly specific with overrides here. Taking the quick way out and using !important
      until we can do this.
    */

    color: var(--text-primary) !important;

    &:hover {
      color: var(--text-primary) !important;
    }
  }
}

.full-width,
.full-width .trigger {
  width: 100%;

  &:focus {
    outline: none;
  }
}

.left-content {
  padding-right: var(--space-28);
  transform: translateY(-0.125rem);
}

.top-right-icon {
  position: absolute;
  top: var(--space-12);
  right: var(--space-12);
}

.label {
  display: flex;
  align-items: center;
  font-size: var(--x14);
  margin-bottom: var(--space-6);
  text-align: left;
}

.new-badge {
  color: var(--white);
  text-transform: uppercase;
  font-size: var(--x10);
  font-weight: 500;
  border-radius: var(--space-16);
  padding: 0 var(--space-12);
  background-color: #4990e2;
  margin-left: var(--space-8);
}

.postsetting-container {
  width: 100%;

  .error-message {
    color: var(--error-500);
    margin-top: calc(-1 * var(--space-16));
    font-size: var(--x14);
  }
}
</style>
