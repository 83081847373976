<script setup>
import { computed } from 'vue';
import { parseQuery, stringifyQuery } from '@/utils/query';
import { formatUtmDateString } from '@/app/settings/utils';
import { decodeDynamicValuesInUrl } from '@/app/settings/components/Utm/utils';

const utmDatePreview = formatUtmDateString(new Date());

const VALUE_PREVIEW_MAP = {
  '<media_url>': 'https://app.dashhudson.com/my-brand/library/media/1234567',
  '<product_sku>': 'ABC-12345',
  '<product_name>': 'Your Product Name',
  '<product_url>': 'https://yourwebsite.com/producturl',
  '<shortened_url>': 'https://my-brand.visitlink.me/example',
  '<post_date:%d%m%y>': utmDatePreview,
  '<gallery_name>': 'Your Gallery Name',
  '<gallery_id>': '123456789',
};

const props = defineProps({
  url: { type: String, default: 'https://domain.com/path/to/site' },
  /**
   * {
   *   <UTM_NAME>: <UTM_VALUE>,
   *   ...
   * }
   */
  utmSettings: { type: Object, required: true },
  useDummyValues: { type: Boolean, default: true },
});

const urlPreview = computed(() => {
  const queryStringStart = props.url.indexOf('?');
  const baseUrl = queryStringStart === -1 ? props.url : props.url.substring(0, queryStringStart);

  const queryParams = queryStringStart === -1 ? {} : parseQuery(props.url, {}, true);
  Object.keys(props.utmSettings).forEach((key) => {
    if (props.useDummyValues) {
      queryParams[key] = VALUE_PREVIEW_MAP[props.utmSettings[key]] ?? props.utmSettings[key];
    } else {
      queryParams[key] = props.utmSettings[key];
    }
  });

  // Update the query parameters of the url
  const queryString = `?${decodeDynamicValuesInUrl(stringifyQuery(queryParams, false))}`;
  return `${baseUrl}${queryString}`;
});

const previewTitle = computed(() => {
  return props.useDummyValues ? 'Example' : 'Preview';
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div>
    <span class="preview-title" data-cy="UtmPreviewTitle">{{ previewTitle }}</span>
    <div class="utm-preview-box" data-cy="UtmPreviewURL">
      {{ urlPreview }}
    </div>
  </div>
</template>

<style scoped lang="postcss">
.preview-title {
  font-size: var(--x14);
  color: var(--text-primary);
}

.utm-preview-box {
  padding: var(--space-10) var(--space-12);
  background-color: var(--background-400);
  border: var(--space-1) solid var(--border);
  border-radius: var(--space-4);
  color: var(--text-primary);
  font-size: var(--x14);
  word-break: break-word;
}
</style>
