<template>
  <div :class="['details-container', className]">
    <div class="flex grow">
      <div class="flex">
        <UserAvatar :user="user" class="mr-2" xxsmall />
      </div>
      <div class="body">
        <p class="name">{{ fullName }}</p>
        <p v-if="email" class="email">{{ email }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import UserAvatar from '@/components/core/UserAvatar.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'CommentMentionDetails',
  components: {
    UserAvatar,
  },
  props: {
    avatarUrl: { type: String, default: null },
    firstName: { type: String, required: true },
    lastName: { type: String, required: true },
    email: { type: String, default: null },
    listItem: { type: Boolean, default: false },
  },
  computed: {
    fullName() {
      return `${this.firstName} ${this.lastName}`;
    },
    user() {
      return {
        avatarUrl: this.avatarUrl,
        firstName: this.firstName,
        lastName: this.lastName,
      };
    },
    className() {
      return this.listItem ? null : 'standalone-item';
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--space-8) var(--space-16) var(--space-8) var(--space-24);
  max-width: 17rem;
  min-width: 13.5rem;
  background: var(--background-0);

  &.standalone-item {
    box-shadow: var(--shadow-4);
    border-radius: var(--round-corner-small);
    padding-left: var(--space-16);
  }

  .avatar {
    margin-right: var(--space-16);
  }

  .name {
    text-align: left;
    color: var(--text-primary);
    font-size: var(--x14);
    line-height: var(--x18);
    font-weight: var(--font-medium);
    max-width: 12rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .email {
    color: var(--text-secondary);
    font-size: var(--x12);
    line-height: var(--x15);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 12rem;
    display: list-item !important;
  }
}
</style>
