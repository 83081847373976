<template>
  <div
    v-tooltip="disabled ? disabledTooltipText : tooltip"
    class="selection-display table border-spacing-0 cursor-pointer"
    :class="{ active: active, disabled: disabled, 'read-only': readOnly }"
    data-cy="SelectionDisplayToggle"
  >
    <div
      v-if="label || hideLabel"
      class="text-secondary text-extra-small table-row"
      :class="{ invisible: hideLabel }"
    >
      <div class="table-cell">
        {{ label }}
      </div>
    </div>
    <div class="table-row">
      <div class="table-cell align-middle">
        <div
          class="selection-display-button"
          :class="[largeText ? 'text-h4' : 'text-small', { hidden: hideButton }]"
          data-cy="SelectionDisplayButton"
        >
          <slot />
        </div>
      </div>
      <div v-if="!readOnly" class="table-cell pl-2 align-middle">
        <div class="flex items-center justify-end">
          <Icon
            name="chevronDown"
            :dir="active ? 'down' : 'up'"
            :small="largeText"
            :xsmall="!largeText"
          />
        </div>
      </div>
    </div>
    <div v-if="hint" class="text-secondary text-extra-small table-row">
      <div class="table-cell">{{ hint }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  components: {
    Icon,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    disabledTooltipText: {
      type: String,
      default: null,
    },
    largeText: {
      type: Boolean,
      default: false,
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.selection-display {
  &:hover,
  &.active {
    .selection-display-button {
      color: var(--text-link);
    }
  }

  &.read-only {
    .selection-display-button {
      color: var(--text-primary) !important;
    }
  }

  &.disabled {
    .selection-display-button {
      color: var(--text-primary) !important;
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}
</style>
