<template>
  <div ref="container" :style="{ padding }" class="tagger-container">
    <component
      :is="taggerComponent"
      v-bind="taggerComponentProps"
      :product-tagging-view-only="productTaggingViewOnly"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'TaggerContainer',
  props: {
    mediaWidth: { type: Number, required: true },
    mediaHeight: { type: Number, required: true },
    taggerComponent: { type: Object, required: true },
    taggerComponentProps: { type: Object, required: true },
    productTaggingViewOnly: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      isMounted: false,
    };
  },
  computed: {
    padding() {
      if (!this.isMounted) {
        return undefined;
      }
      const containerWidth = this.$refs.container.clientWidth;
      const containerHeight = this.$refs.container.clientHeight;

      let paddingHorizontal = 0;
      let paddingVertical = 0;
      if (this.mediaWidth >= this.mediaHeight) {
        const ratio = this.mediaHeight / this.mediaWidth;
        paddingVertical = ((1 - ratio) * containerHeight) / 2;
      } else {
        const ratio = this.mediaWidth / this.mediaHeight;
        paddingHorizontal = ((1 - ratio) * containerWidth) / 2;
      }
      return `${paddingVertical}px ${paddingHorizontal}px`;
    },
  },
  mounted() {
    this.isMounted = true;
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.tagger-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
