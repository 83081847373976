import xor from 'lodash/xor';
import {
  ALERT_SENSITIVITY_LABEL_BY_NUMBER,
  SOCIAL_LISTENING_CHANNEL_FILTER_DEFAULT,
  SOCIAL_LISTENING_MEDIATYPE_FILTER_DEFAULT,
  SOCIAL_LISTENING_SENTIMENT_FILTER_DEFAULT,
  SOCIAL_LISTENING_SENTIMENTS_OPTIONS,
} from '@/app/socialListening/constants';
import { useSocialListeningStore } from '@/stores/social-listening';
import { useAuthStore } from '@/stores/auth';
import { parseToQuery } from '@/app/socialListening/utils/parsing';
import { useSocialListeningTrendsStore } from '@/stores/social-listening-trends';

export const includedKeywordsLength = (groups) => {
  return groups.reduce((sum, group) => {
    if (!group.operators.includes('not')) {
      return sum + group.keywords.length;
    }
    return sum;
  }, 0);
};
export const excludedKeywordsLength = (groups) => {
  return groups.reduce((sum, group) => {
    if (group.operators.includes('not')) {
      return sum + group.keywords.length;
    }
    return sum;
  }, 0);
};
export const includedKeywords = (groups) => {
  return groups.reduce((accumulator, group) => {
    let include = accumulator;
    if (!group.operators.includes('not')) {
      include = accumulator.concat(group.keywords);
    }
    return include;
  }, []);
};
export const excludedKeywords = (groups) => {
  return groups.reduce((accumulator, group) => {
    let exclude = accumulator;
    if (group.operators.includes('not')) {
      exclude = accumulator.concat(group.keywords);
    }
    return exclude;
  }, []);
};
export const normalizedKeywordDiff = (a, b) => {
  /**
   * Calculate the symmetric difference of 2 sets of keywords
   * @param {*} a the first list
   * @param {*} b the other list
   * @returns list of keywords resulting from the operation
   * */
  const A = Array.from(new Set(a));
  const B = Array.from(new Set(b));
  const symDiff = xor(A, B);
  if (!symDiff?.length || (!A?.length && !B?.length)) {
    return 0;
  }
  const score = Math.round((symDiff.length / (A.length + B.length) + Number.EPSILON) * 100) / 100;

  return score;
};

export const formattedTopicSentiment = (sentimentFilter) => {
  const sentiment = [];
  if (!sentimentFilter) {
    return SOCIAL_LISTENING_SENTIMENT_FILTER_DEFAULT;
  }
  Object.values(SOCIAL_LISTENING_SENTIMENTS_OPTIONS).forEach((s) => {
    if (sentimentFilter[s.field]) {
      sentiment.push(s.value);
    }
  });
  return sentiment;
};

export const formattedMediaSentiment = (sentimentFilter) => {
  const sentiment = [];
  if (!sentimentFilter) {
    return SOCIAL_LISTENING_SENTIMENT_FILTER_DEFAULT;
  }
  Object.keys(sentimentFilter).forEach((s) => {
    if (sentimentFilter[s] && !s.includes('Question')) {
      sentiment.push(s.split('is')[1]);
    }
  });
  return sentiment;
};

export const getBaseProperties = (isTopic) => {
  const store = useSocialListeningStore();
  const trendsStore = useSocialListeningTrendsStore();
  const platform = isTopic ? store.filters?.sources : trendsStore.filters?.channels;
  const mediaType = isTopic ? store.filters?.mediaTypes?.includes : trendsStore.filters?.mediaTypes;
  const keywordFilter = isTopic
    ? store.unsavedFilters?.keywordsAndHashtags
    : trendsStore.filters?.keyword;
  return {
    platform: platform ?? SOCIAL_LISTENING_CHANNEL_FILTER_DEFAULT,
    dateScale: isTopic
      ? [
          store.filters?.sourceCreated?.onOrAfter.split(' ')[0],
          store.filters?.sourceCreated?.onOrBefore.split(' ')[0],
        ]
      : trendsStore.filters?.dateRange,
    keywordApplied: !!keywordFilter,
    sentiment: isTopic
      ? formattedTopicSentiment(store.filters?.sentiment)
      : trendsStore.filters?.sentiments,
    mediaType: mediaType ?? SOCIAL_LISTENING_MEDIATYPE_FILTER_DEFAULT,
    topicName: isTopic ? store.selectedTopic?.name : '',
    topicID: isTopic ? store.selectedTopic?.id : '',
  };
};

export const getBaseAlertProperties = () => {
  /**
   * Mixpanel event fields already added by default:
   * brand_id, brandName, customerStage, impersonating, page_sp_test, isActive
   *
   */
  const store = useSocialListeningStore();
  const authStore = useAuthStore();
  return {
    brandHandle: authStore.currentBrand.label,
    topicID: store.selectedTopic.id,
    topicName: store.selectedTopic.name,
  };
};

export const getAlertChangedEditContext = (to, from) => {
  const editContext = [];
  if (to.alertRecipients.userIds.sort().join() !== from.alertRecipients.userIds.sort().join()) {
    const lengthDiff = to.alertRecipients.userIds.length - from.alertRecipients.userIds.length;
    if (lengthDiff > 0) {
      editContext.push('recipients added');
    } else if (lengthDiff < 0) {
      editContext.push('recipients removed');
    } else {
      editContext.push('recipients added and removed');
    }
  }

  if (to.sensitivity !== from.sensitivity) {
    editContext.push(
      'sensitivity changed to ' +
        `${ALERT_SENSITIVITY_LABEL_BY_NUMBER[to.sensitivity].toLowerCase().replace('_', ' ')}`,
    );
  }

  return editContext;
};

export const getKeywordEventProps = (keywords) => {
  const numberOfKeywordsIncluded = includedKeywordsLength(keywords);
  const numberOfKeywordsExcluded = excludedKeywordsLength(keywords);
  const keywordsStringParsed = parseToQuery(keywords);
  const keywordsIncluded = includedKeywords(keywords);
  const keywordsExcluded = excludedKeywords(keywords);
  return {
    inclusions: Boolean(numberOfKeywordsIncluded),
    exclusions: Boolean(numberOfKeywordsExcluded),
    numberOfKeywordsIncluded,
    numberOfKeywordsExcluded,
    keywordsString: keywordsStringParsed,
    listInclude: keywordsIncluded,
    listExclude: keywordsExcluded,
    keywordGroups: keywords,
  };
};
