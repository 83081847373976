<template>
  <section class="highlights-container">
    <main>
      <ul>
        <template v-for="insight in insights" :key="insight.name">
          <li class="highlight-row">
            <span class="highlight-meta text-main">
              <Icon :name="insight.iconName" />
              {{ insight.name }}
              <InfoTooltip v-if="insight?.tooltip" :tooltip="insight.tooltip" />
            </span>
            <span class="text-small highlight-value">{{ insight.value }}</span>
          </li>
        </template>
      </ul>
    </main>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  components: {
    Icon,
    InfoTooltip,
  },
  props: {
    insights: { type: Array, default: () => [] },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.highlights-container {
  min-height: 4.4rem;
  background: var(--background-300);
  border-radius: var(--round-corner);
  width: 100%;
  padding: var(--space-24);
  margin: var(--space-8) auto var(--space-16) auto;
  color: var(--text-primary);

  .highlight-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .svg-icon {
    margin-right: var(--space-8);
  }

  .highlight-meta {
    display: flex;
    align-items: center;
  }

  .highlight-value {
    color: var(--action-500);
  }
}
</style>
