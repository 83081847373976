<template>
  <div>
    <Dropzone
      ref="coverImageDropzone"
      :on-accept="uploadStarted"
      :on-error="uploadError"
      :on-preview-generated="uploadPreviewGenerated"
      :on-upload-progress="uploadProgress"
      :on-success="uploadSuccess"
      :accept-video="false"
      :accept-image="true"
      :max-files="1"
    />
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import isEmpty from 'lodash/isEmpty';
import { mapStores } from 'pinia';
import { UPLOAD_STATUS } from '@/config';
import { useMediaStore } from '@/stores/media';
import { useAuthStore } from '@/stores/auth';
import { logger } from '@/utils/logger';
import Dropzone from './Dropzone.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'CoverImageDropzone',
  components: { Dropzone },
  props: {
    onUploadClicked: { type: Function, default: () => {} },
    displayErrorMessage: { type: Function, default: null },
  },
  emits: ['onUpdateCoverImageUploadStatus'],
  data() {
    return {
      media: {},
    };
  },
  computed: {
    ...mapStores(useAuthStore, useMediaStore),
  },
  methods: {
    openFileDialog() {
      this.$refs.coverImageDropzone.openFileDialog();
    },
    uploadStarted(file) {
      // Remove any existing error message
      this.displayErrorMessage(null);
      this.media = {
        id: file.upload.uuid,
        uuid: file.upload.uuid,
        type: file.type.split('/')[0].toUpperCase(),
        previewUrl: null,
        uploadProgress: 0,
        uploadStatus: file.status,
      };
      this.$emit('onUpdateCoverImageUploadStatus', this.media);
    },
    uploadPreviewGenerated(file, dataUrl) {
      this.media.previewUrl = dataUrl;
      this.media.width = file.width ? file.width : null;
      this.media.height = file.height ? file.height : null;
      this.$emit('onUpdateCoverImageUploadStatus', this.media);
    },
    uploadProgress(file, progress) {
      if (isEmpty(this.media)) return;
      this.media.uploadProgress = progress;
      this.media.uploadStatus = file.status;
      this.$emit('onUpdateCoverImageUploadStatus', this.media);
    },
    async uploadSuccess(file, media) {
      if (isEmpty(this.media)) return;
      // Before passing media to the scheduler popup, validate that the fullMediaObject is the correct object shape
      // and if not, call the Media V2 endpoint for it.
      // TODO: Get rid of "fullMediaObject" so all media data is uniform
      this.media.id = media.mediaId;
      /* This is the cause of a potential race condition. Allowing validateMediaObject to make
       *  an async API call allows other functions to execute. This means uploadConverted can
       * start executing before uploadSuccess if the validation takes a while to call back.
       * Preferably we'd completely remove validateMediaObject and just use V2 media objects
       * everywhere. */

      await this.mediaStore.validateMediaObject({
        brandId: this.authStore.currentBrand.id,
        media: this.media,
      });
      const uploadedMedia = this.mediaStore.mediaV2;
      this.media.uploadStatus = UPLOAD_STATUS.SUCCESS;
      this.media.previewUrl = media.urls.full;
      this.media = uploadedMedia;
      this.$emit('onUpdateCoverImageUploadStatus', this.media);
    },
    uploadError(file) {
      // Remove media that caused error
      this.media = {};

      logger.error(
        `An error occurred while trying to upload your media. ID ${file?.upload?.uuid}`,
        {},
        '',
      );
      // Display error message
      this.displayErrorMessage({
        level: 'error',
        message: 'An error occurred while trying to upload your media.  Please try again.',
      });
    },
    removeFile() {
      this.$refs.coverImageDropzone.reset();
      this.media = {};
    },
  },
});

export default comp;
</script>
