<template>
  <div class="date-range-picker">
    <hr v-if="showTopLine" />
    <div class="range">
      <date-text-input
        ref="startDate"
        v-model="startDate"
        :rules="startDateRules"
        :disabled="isUpdating"
        class="dateInput"
        data-cy="FromDateInput"
        label="From"
      />
      <date-text-input
        ref="endDate"
        v-model="endDate"
        :rules="endDateRules"
        :disabled="isUpdating"
        class="dateInput"
        data-cy="ToDateInput"
        label="To"
      />
    </div>
    <div class="flex justify-center">
      <DatePickerBase
        ref="datePicker"
        v-model="model"
        class="date-picker"
        expanded
        is-range
        :min-date="minDate"
        :max-date="maxDate"
        :attributes="attributes"
        :disabled="isUpdating"
      />
    </div>
    <div :class="{ disabled: isUpdating }">
      <slot name="below-calendar" :start-date="startDate" :end-date="endDate" />
    </div>
    <footer class="filter-footer">
      <Button dismiss small :disabled="isUpdating" data-cy="CancelButton" @click="cancel"
        >Cancel</Button
      >
      <Button
        dismiss
        small
        class="blue"
        :disabled="isApplyDisabled"
        :loading="isUpdating"
        data-cy="select-date-range"
        @click="apply"
      >
        Apply
      </Button>
    </footer>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import dayjs from 'dayjs';
import isAfter from 'date-fns/isAfter';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import Button from '@/components/foundation/Button.vue';
import DateTextInput from '@/components/DateTextInput.vue';
import DatePickerBase from '@/components/foundation/pickers/DatePickerBase.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'DateRangePicker',
  components: {
    Button,
    DatePickerBase,
    DateTextInput,
  },
  props: {
    value: { type: Object, default: null },
    minDate: {
      type: Date,
      default: null,
    },
    maxDate: {
      type: Date,
      default: () => new Date(),
    },
    attributes: {
      type: Array,
      default: null,
    },
    showTopLine: {
      type: Boolean,
      default: false,
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cancel', 'apply', 'input'],
  computed: {
    isApplyDisabled() {
      return !this.isValidRange();
    },
    startDate: {
      get() {
        return this.value?.startDate;
      },
      set(newValue) {
        if (!this.value?.startDate || !newValue?.isSame(this.value?.startDate, 'day')) {
          if (newValue?.isValid()) {
            if (this.$refs.datePicker) {
              this.$refs.datePicker.move(newValue.toDate());
            }
            if (this.$refs.endDate) {
              this.$refs.endDate.updateValue();
            }
            this.$emit('input', {
              startDate: newValue,
              endDate: this.value?.endDate,
            });
          }
        }
      },
    },
    endDate: {
      get() {
        return this.value?.endDate;
      },
      set(newValue) {
        if (!this.value?.endDate || !newValue?.isSame(this.value?.endDate, 'day')) {
          if (newValue?.isValid()) {
            if (this.$refs.datePicker) {
              this.$refs.datePicker.move(newValue.toDate());
            }
            if (this.$refs.startDate) {
              this.$refs.startDate.updateValue();
            }
            this.$emit('input', {
              startDate: this.value?.startDate,
              endDate: newValue,
            });
          }
        }
      },
    },
    startDateRules() {
      return [
        (dayjsValue) => {
          if (dayjsValue?.isAfter(dayjs(this.maxDate), 'day')) {
            return 'From date can only be set up to the current date.';
          }
          return true;
        },
        (dayjsValue) => {
          if (dayjsValue?.isBefore(dayjs(this.minDate), 'day')) {
            return 'From date cannot be set before the current date.';
          }
          return true;
        },
        (dayjsValue) => {
          if (dayjsValue?.isAfter(this.endDate, 'day')) {
            return 'From date must be before the To date.';
          }
          return true;
        },
      ];
    },
    endDateRules() {
      return [
        (dayjsValue) => {
          if (dayjsValue?.isAfter(dayjs(this.maxDate), 'day')) {
            return 'To date can only be set up to the current date.';
          }
          return true;
        },
        (dayjsValue) => {
          if (dayjsValue?.isBefore(dayjs(this.minDate), 'day')) {
            return 'To date cannot be set before the current date.';
          }
          return true;
        },
        (dayjsValue) => {
          if (dayjsValue?.isBefore(this.startDate, 'day') && this.startDate) {
            return 'To date must follow the From date.';
          }
          return true;
        },
      ];
    },
    model: {
      get() {
        const start = this.value?.startDate?.toDate();
        const end = this.value?.endDate?.toDate();
        return {
          start,
          end,
        };
      },
      set(newValue) {
        const startDate = dayjs(newValue.start).startOf('day');
        const endDate = dayjs(newValue.end).startOf('day');
        const isSameStartDay =
          this.value?.startDate && startDate.isSame(this.value?.startDate, 'day');
        const isSameEndDay = this.value?.endDate && endDate.isSame(this.value?.endDate, 'day');

        if (!isSameStartDay || !isSameEndDay) {
          this.$emit('input', {
            startDate,
            endDate,
          });
        }
      },
    },
  },
  methods: {
    cancel() {
      this.clearRange();
      this.$emit('cancel');
    },
    apply() {
      this.$emit('apply');
    },
    isValidRange() {
      return isAfter(endOfDay(this.model?.end), startOfDay(this.model?.start));
    },
    clearRange() {
      if (this.model) {
        this.model = {
          start: null,
          end: null,
        };
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.disabled {
  cursor: default;
  opacity: 0.3;
}

.date-range-picker {
  border-radius: 0 0 var(--round-corner-small) var(--round-corner-small);
  background: var(--background-0);
  z-index: var(--z-index-dropdown);

  footer a {
    font-weight: var(--font-medium);
  }

  hr {
    margin: 0 var(--space-16);
  }

  .range {
    display: flex;
    justify-content: space-around;
    padding: var(--space-8) var(--space-12);
    flex-flow: row wrap;

    .dateInput {
      width: 50%;
    }
  }

  .date-picker {
    width: 100%;
  }

  .filter-footer {
    background: var(--background-300);
    border-radius: 0 0 var(--round-corner-small) var(--round-corner-small);
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--space-12);
    font-size: var(--x14);

    a:active,
    a:hover {
      color: var(--action-500);
    }

    .blue {
      color: var(--action-500);
    }
  }
}
</style>
