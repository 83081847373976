<template>
  <div>
    <template v-if="!isReport">
      <Button
        v-tooltip="sortTooltip"
        :icon-name="orderIcon"
        :icon-color="highLowIconColor"
        :icon-hover-color="iconHoverColor"
        :icon-size="iconSize"
        class="sort-button"
        @click="toggleSortOrder"
      ></Button>
    </template>
    <template v-else>
      <div class="sort-tooltip-display">
        <label class="order-label">Order</label>
        <label class="tooltip-label">{{ sortTooltip }}</label>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Button from '@/components/foundation/Button.vue';
import { colours } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'SortOrderToggle',
  components: {
    Button,
  },
  props: {
    value: { type: Boolean, default: false },
  },
  emits: ['input'],
  computed: {
    iconSize() {
      return 'medium';
    },
    highLowIconColor() {
      return colours.ICON.ICON_SECONDARY;
    },
    iconHoverColor() {
      return colours.ACTION.ACTION_500;
    },
    orderIcon() {
      return this.value ? 'moveUp' : 'moveDown';
    },
    sortTooltip() {
      return this.value ? 'Lowest to Highest' : 'Highest to Lowest';
    },
    isReport() {
      return this.$route?.meta?.report;
    },
  },

  methods: {
    toggleSortOrder() {
      this.$emit('input', !this.value);
    },
  },
});
export default comp;
</script>
<style lang="postcss" scoped>
.control-toolbar {
  .sort-button {
    cursor: pointer;
    background: transparent;
    border: none;
    width: 24px;
  }

  .sort-tooltip-display {
    .order-label {
      color: var(--text-secondary);
      display: block;
      font-size: var(--space-12);
      font-weight: normal;
      margin-bottom: calc(-1 * var(--space-12));
      padding: 0 var(--space-32) 0 var(--space-12);
    }

    .tooltip-label {
      margin: 0;
      padding: 0.3rem var(--space-32) 0.3rem var(--space-12);
      position: relative;
    }
  }
}
</style>
