<script setup>
import { onMounted, computed } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useTiktokStore } from '@/stores/tiktok';
import CircularLoader from '@/components/CircularLoader.vue';
import { Panel, StatusChip, Icon } from '@dashhudson/dashing-ui';
import { SparkAdsLearnMoreUrl, tooltips } from '@/app/creators/constants';

const authStore = useAuthStore();
const tiktokStore = useTiktokStore();

const props = defineProps({
  mediaItem: {
    type: Object,
    default: null,
  },
});

const loading = computed(() => tiktokStore.pending.sparkAdsStatus);

const sparkAdData = computed(() => tiktokStore.sparkAdsStatus);

const isEligible = computed(() => sparkAdData.value !== 'Not Eligible');

async function fetchSparkAdsStatus() {
  await tiktokStore.getSparkAdsStatus({
    brandId: authStore.currentBrand.id,
    sourceVideoId: props?.mediaItem?.postSourceId,
  });
}

onMounted(() => {
  fetchSparkAdsStatus();
});
</script>

<template>
  <div class="dash-w-full">
    <Panel
      title="TikTok Spark Ad Authorization"
      channel="TIKTOK"
      :tooltip="tooltips.tiktokSparkAdsHeader"
    >
      <template v-if="isEligible & !loading" #titleAppend>
        <StatusChip></StatusChip>
      </template>
      <div v-if="loading">
        <CircularLoader />
      </div>
      <div v-else-if="!isEligible" class="dash-p-6">
        <Icon name="info-circle" size="24" />
        <p class="learn-more">
          Spark Ad authorization isn't available for this post because it's not linked to a Campaign
          via Invite Link.
          <a class="learn-more-link" :href="SparkAdsLearnMoreUrl" target="_blank" rel="noopener"
            >Learn More
          </a>
        </p>
      </div>
      <div v-else>{{ sparkAdData }}</div>
    </Panel>
  </div>
</template>
