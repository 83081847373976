<template>
  <div />
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'InfiniteScroll',
  props: {
    onInfinite: { type: Function, default: null },
    distance: { type: Number, default: 200 },
    getOnCreated: { type: Boolean, default: false },
    useDocument: { type: Boolean, default: false },
    targetContainer: { type: Object, default: undefined },
    preventRequest: { type: Boolean, default: false },

    // If 'up', infinite loading will happen while scrolling up instead of down
    scrollDirection: { type: String, default: 'down' },
  },
  data() {
    return {
      scrollableParent: null,
      requestPending: false,
    };
  },
  computed: {
    targetElement() {
      return this.targetContainer || this.$el;
    },
  },
  created() {
    if (this.getOnCreated) {
      this.callOnInfinite();
    }
  },
  mounted() {
    this.scrollableParent = this.getScrollableParent(this.targetElement, this.useDocument);
    this.scrollableParent.addEventListener('scroll', this.onScroll, { passive: true });
  },
  unmounted() {
    this.scrollableParent.removeEventListener('scroll', this.onScroll, { passive: true });
  },
  methods: {
    async callOnInfinite() {
      this.requestPending = true;
      await this.onInfinite();
      this.requestPending = false;
    },
    async onScroll() {
      const {
        onInfinite,
        callOnInfinite,
        distance,
        requestPending,
        scrollableParent,
        scrollDirection,
      } = this;

      if (requestPending || this.preventRequest || !onInfinite) {
        return;
      }

      // If we're scrolling down...
      if (scrollDirection === 'down') {
        // And we're scrolling on the document...
        if (scrollableParent === document) {
          // Then we check the distance from the bottom of the page to trigger the infinite load.
          if (window.innerHeight + window.scrollY + this.distance >= document.body.offsetHeight) {
            await callOnInfinite();
          }
        }
        // Otherwise, we're scrolling within an element, so we check distance from the bottom of
        // the element.
        else if (
          scrollableParent.scrollTop >=
          scrollableParent.scrollHeight - scrollableParent.offsetHeight - distance
        ) {
          await callOnInfinite();
        }

        // If we're scrolling up, then we check the distance from the top of the element.
        // Note: I didn't bother implementing infinite loading while scrolling up on the document
        // because I don't see where we'd ever use that. If we happen to need it at some point, this
        // is where it would go.
      } else if (scrollDirection === 'up' && scrollableParent.scrollTop - distance <= 0) {
        await callOnInfinite();
      }
    },
    // This function will find the next available scrollable parent for the infinite scroll event
    getScrollableParent(element, useDocument) {
      const style = getComputedStyle(element);
      const excludeStaticParent = style.position === 'absolute';
      const overflowRegex = /(auto|scroll)/;

      if (style.position === 'fixed' || useDocument) {
        return document;
      }

      let parent = element.parentElement;
      while (parent) {
        const parentStyle = getComputedStyle(parent);
        if (
          !(excludeStaticParent && parentStyle.position === 'static') &&
          overflowRegex.test(parentStyle.overflow + parentStyle.overflowY + parentStyle.overflowX)
        ) {
          return parent;
        }
        parent = parent.parentElement;
      }
      return document;
    },
  },
});
export default comp;
</script>
