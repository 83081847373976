import { useSchedulerStore } from '@/stores/scheduler';

export function refreshBestTimesToPost(brandId, platform) {
  const schedulerStore = useSchedulerStore();
  const refreshPublishTimes = !schedulerStore?.recommendedPublishTimes?.[brandId]?.[platform];

  const shouldClearRecommendedPublishTimes = !schedulerStore?.recommendedPublishTimes?.[brandId];

  if (refreshPublishTimes) {
    if (shouldClearRecommendedPublishTimes) {
      schedulerStore.clearRecommendedPublishTimes();
    }
    schedulerStore.listRecommendedPublishTimes({
      brandId,
      platform,
    });
  }
}
