<template>
  <div class="trend-group-tile-container">
    <LetterAvatar
      :avatar="sourceAccount.avatarUrl"
      :account-id="sourceAccount.instagramId"
      :name="sourceAccount.handle"
      class="avatar"
    />
    <span :title="sourceAccount.handle">{{ sourceAccount.handle }}</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import LetterAvatar from '@/components/LetterAvatar.vue';
import { useInstagramViqStore } from '@/stores/instagram-viq';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'TrendGroupTile',
  components: {
    LetterAvatar,
  },
  props: {
    mediaItem: { type: Object, required: true },
  },
  computed: {
    ...mapStores(useInstagramViqStore),
    mediaAccountId() {
      return this.mediaItem.sourceAccountId;
    },
    sourceAccount() {
      return (
        (this.instagramViqStore.trendGroup &&
          this.instagramViqStore.trendGroup.sourceData &&
          this.instagramViqStore.trendGroup.sourceData[this.mediaAccountId]) ||
        {}
      );
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.trend-group-tile-container {
  position: absolute;
  bottom: calc(-1 * var(--space-28));
  width: 100%;
  display: flex;
  align-items: baseline;

  .avatar {
    margin: 0 var(--space-4) 0 var(--space-8);
  }

  span {
    font-weight: 400;
    transform: translateY(calc(-1 * var(--space-4)));
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
