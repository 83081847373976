<template>
  <div>
    <Popup
      :close="close"
      :close-confirmation="closeConfirmation"
      type="large"
      class="media-cropper-popup"
      :style="{
        padding: 'var(--space-48)',
      }"
      :inline-styles="{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '40rem',
        height: '100%',
        margin: 'auto',
      }"
    >
      <header class="dh-media-cropper">
        <Icon :color="iconColor" medium name="crop" />
        <span>Edit Media</span>
      </header>
      <section class="cropping-tools">
        <div class="media-section">
          <VideoCropper
            ref="videoCropper"
            :disable-audio-track="disableAudioTrack"
            :disabled="creatingCroppedVideo"
            :end-time="trimmedEndTime"
            :on-crop-change="setCropData"
            :on-playing-changed="setPlayVideo"
            :on-video-current-time-changed="setPlayheadPosition"
            :on-video-loaded="setVideoLoaded"
            :on-video-restarted="() => (shouldRestartVideo = false)"
            :original-media="originalMedia"
            :playhead-position="playheadPosition"
            :start-time="trimmedStartTime"
            :should-restart-video="shouldRestartVideo"
            :video-media="mediaObject"
          />
        </div>

        <AspectRatioSelector
          :disabled="creatingCroppedVideo"
          :media="media"
          :custom-cropper-presets="customCropperPresets"
          :enable-free-crop="enableFreeCrop"
          :post-type="postType"
        />
        <ValidationError
          v-if="validationRatioErrorMessage || validationLengthErrorMessage"
          class="cropper-validation-error"
        >
          {{ validationRatioErrorMessage || validationLengthErrorMessage }}
        </ValidationError>
      </section>
      <footer class="dh-media-cropper">
        <div class="footer-tools">
          <Button
            v-tooltip="playVideoToolTip"
            :disabled="disabled"
            :icon-name="playVideo ? 'videoControlPause' : 'videoControlPlay'"
            class="play"
            data-cy="play-button"
            round
            @click="playToggle"
          />
          <VideoTrimmer
            :end-time="trimmedEndTime"
            :hide-video-duration="hideTrimmerDuration"
            :media="originalMedia ? originalMedia : media"
            :on-end-time-changed="onEndTimeChanged"
            :on-playhead-dragged="onPlayheadDragged"
            :on-playhead-position-changed="updateVideoTime"
            :on-start-time-changed="onStartTimeChanged"
            :playhead-position="playheadPosition"
            :saving-video="creatingCroppedVideo"
            :start-time="trimmedStartTime"
            :video-is-loaded="videoLoadComplete"
            :video-playing="playVideo"
          />
          <Button
            v-tooltip="disableAudioTrackToolTip"
            :disabled="disabled"
            :icon-name="disableAudioTrack ? 'speakerVolumeOff' : 'speakerVolumeHigh'"
            class="mute"
            data-cy="disable-audio-button"
            round
            @click="muteToggle"
          />
        </div>
        <div class="controls">
          <Button small data-cy="cancel-crop" @click="close">Cancel</Button>
          <Button
            :loading="creatingCroppedVideo"
            :disabled="
              !hasUnsavedChanges ||
              creatingCroppedVideo ||
              validationRatioErrorMessage !== null ||
              validationLengthErrorMessage !== null
            "
            small
            class="save"
            primary
            data-cy="save-crop"
            @click="saveVideo"
          >
            Save
          </Button>
        </div>
        <div v-if="showPredictionWarning" class="crop-prediction-warning">
          <p>* Cropping or trimming may result in a change of</p>
          <p>Vision predicted performance</p>
        </div>
      </footer>
    </Popup>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { mapStores } from 'pinia';
import isEqual from 'lodash/isEqual';
import camelCase from 'lodash/camelCase';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useMediaStore } from '@/stores/media';
import { useNotificationStore } from '@/stores/notification';
import {
  mediaValidationMap,
  facebookTagsCropConfirmTitle,
  facebookTagsCropConfirmMessage,
} from '@/app/scheduler/constants';
import { videoCropperPresets, discardConfirmMessage } from '@/config';
import { colours } from '@/ux/colours';
import { getVideoDataFromMedia } from '@/utils/media';
import enumTypes from '@/app/library/constants';
import AspectRatioSelector from '@/components/MediaCropping/AspectRatioSelector.vue';
import Button from '@/components/foundation/Button.vue';
import Icon from '@/components/foundation/Icon.vue';
import MediaModel from '@/models/media';
import Popup from '@/components/Popup.vue';
import ValidationError from '@/components/ValidationError.vue';
import VideoCropper from '@/components/MediaCropping/VideoCropper.vue';
import VideoTrimmer from '@/components/MediaCropping/VideoTrimmer.vue';
import { useCropperStore } from '@/stores/cropper';
import { useMediaLinksStore } from '@/stores/media-links';
import SocketsMixin from '@/mixins/socketsMixin';
import { useSocketStore } from '@/stores/socket';
import { showPredictions } from '@/utils/vision';
import { useSchedulerStore } from '@/stores/scheduler';
import { useInstagramShoppingTaggerStore } from '@/stores/instagram-shopping-tagger';
import { useFacebookProductTaggerStore } from '@/stores/facebook-product-tagger';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'VideoCropperPopup',
  components: {
    AspectRatioSelector,
    Button,
    Icon,
    Popup,
    ValidationError,
    VideoCropper,
    VideoTrimmer,
  },
  mixins: [SocketsMixin],
  props: {
    close: { type: Function, required: true },
    media: { type: Object, required: true },
    onSave: { type: Function, required: true },
    originalMedia: { type: Object, default: null },
    platform: { type: String, default: null },
    customCropperPresets: { type: Object, default: null },
    enableFreeCrop: { type: Boolean, default: true },
    postType: { type: String, default: '' },
  },
  data() {
    return {
      creatingCroppedVideo: false,
      croppedMediaData: null,
      croppedMediaItem: null,
      disableAudioTrack: false,
      iconColor: colours.ICON.ICON_SECONDARY,
      hideTrimmerDuration: false,
      playheadPosition: 0,
      playVideo: false,
      shouldRestartVideo: false,
      showTagsConfirmationPopup: false,
      trimmedEndTime: 0,
      trimmedStartTime: 0,
      videoLoadComplete: false,
      validationRatioErrorMessage: null,
      validationLengthErrorMessage: null,
      // props for initial values to compare with to check changes
      initCropData: null,
      initCropperRatio: null,
      initTrimmedStartTime: 0,
      initTrimmedEndTime: 0,
      initDisableAudioTrack: false,
    };
  },
  computed: {
    ...mapStores(
      useNotificationStore,
      useCropperStore,
      useAuthStore,
      useMediaLinksStore,
      useMediaStore,
      useSocketStore,
      useSchedulerStore,
      useInstagramShoppingTaggerStore,
      useFacebookProductTaggerStore,
      useTrackingStore,
    ),
    closeConfirmation() {
      return this.creatingCroppedVideo || this.hasUnsavedChanges ? discardConfirmMessage : null;
    },
    mediaObject() {
      return this.media.fullMediaObject || this.media;
    },
    tagsConfirmationTitle() {
      let label = 'Product';
      if (this.mediaLinksStore.mediaLinks?.length > 0) {
        if (
          this.instagramShoppingTaggerStore.activeMediaTaggedProducts.length > 0 &&
          this.schedulerStore.autoPublish
        ) {
          label = 'Link & Product';
        } else {
          label = 'Link';
        }
      }
      return `${label} Tags Will Need To Be Adjusted`;
    },
    tagsConfirmationMessage() {
      let label = 'product';
      if (this.mediaLinksStore.mediaLinks?.length > 0) {
        if (
          this.instagramShoppingTaggerStore.activeMediaTaggedProducts.length > 0 &&
          this.schedulerStore.autoPublish &&
          this.postType !== 'Reel'
        ) {
          label = 'link & product';
        } else {
          label = 'link';
        }
      }
      return `Cropping this video may adjust the placement of ${label} tags. Are you sure you want to crop anyway? (Tag placements can be re-positioned later)`;
    },
    videoUrl() {
      const media = this.originalMedia ? this.originalMedia : this.media;
      const videoData = getVideoDataFromMedia(media);
      return videoData.url;
    },
    aspectRatioValidationRequired() {
      return (
        (['instagram', 'pinterest'].includes(this.platform) && this.schedulerStore.autoPublish) ||
        ['facebook', 'twitter'].includes(this.platform)
      );
    },
    trimmedLengthValidationRequired() {
      return (
        this.aspectRatioValidationRequired ||
        (this.platform === 'tiktok' && this.schedulerStore.autoPublish)
      );
    },
    trimmedVideoLength() {
      return this.trimmedEndTime - this.trimmedStartTime;
    },
    cropChanged() {
      const cropData = this.croppedMediaData?.cropData;
      const cropperRatio = this.cropperStore.cropperRatio?.ratioType;
      return (
        cropData &&
        (!isEqual(this.initCropData, cropData) || this.initCropperRatio !== cropperRatio)
      );
    },
    videoLengthChanged() {
      return (
        this.trimmedStartTime.toFixed(2) !== this.initTrimmedStartTime.toFixed(2) ||
        this.trimmedEndTime.toFixed(2) !== this.initTrimmedEndTime.toFixed(2)
      );
    },
    audioChanged() {
      return this.disableAudioTrack !== this.initDisableAudioTrack;
    },
    hasUnsavedChanges() {
      return this.cropChanged || this.videoLengthChanged || this.audioChanged;
    },
    platformName() {
      // If the platform is instagram, we need to specify what post type it is to apply different
      // media validations.
      if (this.platform === 'instagram') {
        return camelCase(this.platform + this.postType);
      }
      return camelCase(this.platform);
    },
    mediaType() {
      return this.media.type.toLowerCase();
    },
    playVideoToolTip() {
      if (this.disabled) {
        return '';
      }
      return this.playVideo ? 'Pause' : 'Play';
    },
    disableAudioTrackToolTip() {
      if (this.disabled) {
        return '';
      }
      return this.disableAudioTrack ? 'Enable Audio' : 'Disable Audio';
    },
    disabled() {
      return this.creatingCroppedVideo;
    },
    showPredictionWarning() {
      return showPredictions(this.media, this.$router);
    },
  },
  watch: {
    trimmedVideoLength() {
      this.validateTrimmedVideoLength();
    },
  },
  sockets: {
    convert_video_callback(data) {
      if (this.croppedMediaItem && this.croppedMediaItem.id === data.id) {
        this.updateCroppedVideo(data);
      }
    },
  },
  created() {
    this.facebookProductTaggerStore.setActiveMediaId({ mediaId: this.media?.id });
    const { transforms } = this.media;
    const { sizes, duration } = this.mediaObject;
    const ratioType = this.media.editorData?.aspectRatio ?? 'original';
    // set initial values that will not change
    this.initCropData = transforms?.crop || {
      width: sizes.original.width,
      height: sizes.original.height,
      x: 0,
      y: 0,
    };
    this.initCropperRatio = ratioType;
    this.initTrimmedStartTime = transforms?.trim?.startTime ?? 0;
    this.initTrimmedEndTime = (transforms?.trim?.endTime ?? duration) || 0;
    this.initDisableAudioTrack = transforms?.disableAudioTrack ?? false;
    // initialize values that will change when popup is edited
    this.trimmedStartTime = this.initTrimmedStartTime;
    this.trimmedEndTime = this.initTrimmedEndTime;
    this.disableAudioTrack = this.initDisableAudioTrack;
    if (
      (this.customCropperPresets && !(ratioType in this.customCropperPresets)) ||
      ratioType === 'original'
    ) {
      this.cropperStore.setCropperRatio({
        ratioType: 'original',
        name: 'Original',
        ratio: null,
      });
    } else if (ratioType !== 'freeCrop') {
      this.cropperStore.setCropperRatio({
        ratioType,
        name: videoCropperPresets[ratioType].name,
        ratio: videoCropperPresets[ratioType].ratio,
      });
    }
  },
  beforeUnmount() {
    this.croppedMediaData = null;
    this.setPlayVideo(false);
  },
  methods: {
    onStartTimeChanged(seconds) {
      this.trimmedStartTime = seconds;
      this.shouldRestartVideo = false;
    },
    onEndTimeChanged(seconds) {
      this.trimmedEndTime = seconds;
      this.shouldRestartVideo = true;
    },
    resetThumbnail() {
      this.schedulerStore.setThumbOffset(null);
      this.schedulerStore.setThumbnailSource(null);
    },
    setPlayheadPosition(seconds) {
      this.playheadPosition = seconds;
    },
    playToggle() {
      this.playVideo = !this.playVideo;
      this.$refs.videoCropper.onPlayClicked(this.playVideo);
    },
    setPlayVideo(value) {
      this.playVideo = value;
    },
    muteToggle() {
      this.disableAudioTrack = !this.disableAudioTrack;
    },
    async checkIfTagsExist() {
      const confirmAlias = 'Crop';
      if (
        this.postType === 'Feed Post' &&
        this.cropChanged &&
        ((this.schedulerStore.instagramCarouselPage === 0 &&
          this.mediaLinksStore.mediaLinks?.length > 0) ||
          this.instagramShoppingTaggerStore.activeMediaTaggedProducts.length > 0)
      ) {
        const title = this.tagsConfirmationTitle;
        const message = this.tagsConfirmationMessage;
        await this.notificationStore.confirm(title, message, {
          confirmAlias,
          onConfirm: this.confirmCrop,
          onCancel: this.closeTagsConfirmationPopup,
        });
      } else if (
        this.platform === 'facebook' &&
        this.cropChanged &&
        this.facebookProductTaggerStore.activeMediaTaggedProducts.length > 0
      ) {
        await this.notificationStore.confirm(
          facebookTagsCropConfirmTitle,
          facebookTagsCropConfirmMessage,
          {
            confirmAlias,
            onConfirm: this.confirmCrop,
            onCancel: this.closeTagsConfirmationPopup,
          },
        );
      } else {
        this.createCroppedVideo();
      }
    },
    closeTagsConfirmationPopup() {
      this.creatingCroppedVideo = false;
    },
    async confirmCrop() {
      const croppedVideoCreated = this.createCroppedVideo();
      const videoConverted = this.addVideoConvertCallback();
      return Promise.all([croppedVideoCreated, videoConverted]);
    },
    addVideoConvertCallback() {
      return new Promise((resolve) => {
        this.sockets.subscribe('convert_video_callback', (data) => {
          if (this.croppedMediaItem && this.croppedMediaItem.id === data.id) {
            resolve();
            this.sockets.unsubscribe('convert_video_callback');
          }
        });
      });
    },
    convertWidthAndHeight() {
      let { width, height } = this.croppedMediaData.cropData;

      if (this.aspectRatioValidationRequired) {
        // Since ffmpeg in the backend scale width/height to even number automatically, we need to set
        // width/height manually to match the minimum ratio requirement and make sure they are even.
        const expectedMinRatio =
          mediaValidationMap[this.platformName]?.aspectRatio[this.mediaType]?.minNumeric;
        const expectedMaxRatio =
          mediaValidationMap[this.platformName]?.aspectRatio[this.mediaType]?.maxNumeric;

        // If cropped width and height already met the requirement, or original ratio is selected,
        // we don't need to recalculate again
        if (
          (width % 2 === 0 &&
            height % 2 === 0 &&
            expectedMinRatio <= width / height &&
            width / height <= expectedMaxRatio) ||
          this.cropperStore.cropperRatio?.ratioType === 'original'
        ) {
          return { width, height };
        }
        // Always truncate width and height in case they exceed original values.
        height -= height % 2;
        width -= width % 2;

        const ratio = width / height;
        // Video is too tall
        if (ratio < expectedMinRatio) {
          height = Math.trunc(width / expectedMinRatio);
          height -= height % 2;
        }
        // Video is too wide
        else if (ratio > expectedMaxRatio) {
          width = Math.trunc(height * expectedMaxRatio);
          width -= width % 2;
        }
      }

      return { width, height };
    },
    async createCroppedVideo() {
      this.validateWidthAndHeight();
      // On save, create a new video and set the scheduled post to use that media id
      const { width, height } = this.convertWidthAndHeight();
      // If the media item has been cropped before, save the original parent media id
      const parentMediaId = this.originalMedia ? this.originalMedia.id : this.media.id;

      try {
        const data = {
          callback_socket_id: this.socketStore.id,
          source: enumTypes.EDITOR,
          type: 'VIDEO',
          video: {
            editor_data: {
              aspect_ratio: this.cropperStore.cropperRatio.ratioType,
            },
            sizes: {
              original: {
                url: this.videoUrl,
              },
            },
            transforms: {
              crop:
                this.cropperStore.cropperRatio?.ratioType === 'original'
                  ? null
                  : {
                      x: this.croppedMediaData.cropData.x,
                      y: this.croppedMediaData.cropData.y,
                      width,
                      height,
                    },
              disable_audio_track: this.disableAudioTrack,
              parent_media_id: parentMediaId,
              trim: {
                end_time: this.trimmedEndTime || 0,
                start_time: this.trimmedStartTime || 0,
              },
            },
          },
        };

        await this.mediaStore.createMediaV2({ brandId: this.authStore.currentBrand.id }, data);

        this.croppedMediaItem = new MediaModel(this.mediaStore.newMediaV2);
      } catch (error) {
        this.notificationStore.setToast({
          message: 'Something went wrong, please try again.',
          type: 'error',
        });
        this.creatingCroppedVideo = false;
      }
    },
    onPlayheadDragged(videoWasPlaying, playheadDragged) {
      // If the playhead was dragged while the video was playing,
      // we pause the video while it is being dragged and play it again when dragging ends
      if (videoWasPlaying) {
        this.$refs.videoCropper.onPlayClicked(!playheadDragged);
      }
      this.shouldRestartVideo = false;
    },
    setVideoLoaded(value) {
      this.videoLoadComplete = value;
    },
    validateWidthAndHeight() {
      // Occasionally the calculated width/height of the crop data exceeds the original width and
      // height of the video. If that is the case, we need to set the data to be equal to the
      // original video size
      if (this.croppedMediaData && this.croppedMediaData.cropData) {
        const videoData = getVideoDataFromMedia(this.originalMedia || this.media);
        if (this.croppedMediaData.cropData.width > videoData.width) {
          this.croppedMediaData.cropData.width = videoData.width;
        }
        if (this.croppedMediaData.cropData.height > videoData.height) {
          this.croppedMediaData.cropData.height = videoData.height;
        }
      }
    },
    updateCroppedVideo(data) {
      this.croppedMediaItem = new MediaModel(data);
      this.onSave(this.croppedMediaItem);
      if (
        this.schedulerStore.instagramCarouselPage === 0 &&
        this.mediaLinksStore.mediaLinks?.length > 0
      ) {
        this.saveLinks(this.croppedMediaItem.id);
      }
      if (this.instagramShoppingTaggerStore.activeMediaTaggedProducts.length > 0) {
        this.saveShoppingTags(this.croppedMediaItem.id);
      }
      if (this.facebookProductTaggerStore.activeMediaTaggedProducts.length > 0) {
        this.saveFacebookProductTags(this.croppedMediaItem.id);
      }
      this.trackingStore.track('Cropped media saved', {
        platformType: this.platform,
        mediaType: this.croppedMediaItem.type,
      });

      this.creatingCroppedVideo = false;
    },
    saveLinks(croppedMediaId) {
      this.mediaLinksStore.updateMediaLinks({
        brandId: this.authStore.currentBrand.id,
        mediaId: croppedMediaId,
        data: this.mediaLinksStore.mediaLinks,
      });
      this.cropperStore.setAdjustLinksPrompt();
    },
    saveShoppingTags(croppedMediaId) {
      const shoppingTags = this.instagramShoppingTaggerStore.taggedProducts.map((tag) => {
        if (tag.mediaId === this.media.id.toString()) {
          return { ...tag, mediaId: croppedMediaId };
        }
        return tag;
      });
      this.instagramShoppingTaggerStore.setShoppingTags({ shoppingTags });
      this.cropperStore.setAdjustShoppingTagsPrompt();
    },
    saveFacebookProductTags(croppedMediaId) {
      const productTags = this.facebookProductTaggerStore.taggedProducts.map((tag) => {
        if (tag.mediaId === this.media.id) {
          return { ...tag, mediaId: croppedMediaId };
        }
        return tag;
      });
      this.facebookProductTaggerStore.setFacebookProductTags({ productTags });
      this.cropperStore.setAdjustFacebookProductTagsPrompt();
    },
    saveVideo() {
      this.creatingCroppedVideo = true;
      this.setPlayVideo(false);
      // if video is trimmed from the start, the current time-point of the thumbnail is useless
      // as there is a new start point, be safe and reset thumbnail if length changes
      if (this.videoLengthChanged) this.resetThumbnail();
      this.checkIfTagsExist();
    },
    setCropData(data) {
      this.croppedMediaData = data;
      // Validate media when aspect ratio changes
      this.validateAspectRatio(this.croppedMediaData.cropData);
      if (this.isInitialCrop) {
        this.isInitialCrop = false;
      }
    },
    updateVideoTime(newTime) {
      // manually updating the current time in the media player
      if (newTime !== null) {
        nextTick(() => {
          if (this.$refs.videoCropper.$refs.video) {
            this.$refs.videoCropper.$refs.video.currentTime = newTime;
          }
        });
      }
    },
    validateAspectRatio() {
      if (this.aspectRatioValidationRequired) {
        const validations = mediaValidationMap[this.platformName].aspectRatio[this.mediaType];

        // Convert the width and height before validating, because sometimes the cropper rounds
        // the aspect ratio, making the validation to fail. By calling the convertWidthAndHeight,
        // we can make sure the width and height are both converted to meet the aspect ratio
        // requirement, and they will also be the values we send to library backend.
        const { width, height } = this.convertWidthAndHeight();
        const currentAspectRatio = (width / height).toFixed(4);
        if (
          currentAspectRatio < validations.minNumeric ||
          currentAspectRatio > validations.maxNumeric
        ) {
          this.validationRatioErrorMessage =
            `This ${this.mediaType} aspect ratio doesn't meet ${validations.platformName}'s ` +
            `requirements. Please crop your video to be within a ${validations.min} to ` +
            `${validations.max} range and try again!`;
        } else {
          this.validationRatioErrorMessage = null;
        }
      }
    },
    validateTrimmedVideoLength() {
      if (this.trimmedLengthValidationRequired) {
        const videoValidations = mediaValidationMap[this.platformName].duration[this.mediaType];
        this.hideTrimmerDuration = this.trimmedVideoLength < videoValidations.minNumeric;
        if (this.trimmedVideoLength < videoValidations.minNumeric) {
          this.validationLengthErrorMessage =
            `This video is too short. Please ensure that your video is between ` +
            `${videoValidations.min} and ${videoValidations.max} and try again.`;
        } else if (this.trimmedVideoLength > videoValidations.maxNumeric) {
          this.validationLengthErrorMessage =
            `This video is too long. Please use the video trimmer to trim your video to be ` +
            `between ${videoValidations.min} and ${videoValidations.max} and try again.`;
        } else {
          this.validationLengthErrorMessage = null;
        }
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
#popup-page-container.media-cropper-popup {
  header.dh-media-cropper {
    display: flex;
    padding: var(--space-20) var(--space-24);
    border-bottom: 1px solid var(--border);

    span {
      color: var(--text-primary);
      font-size: var(--x18);
      font-weight: var(--font-medium);
      margin-left: var(--space-12);
    }
  }

  section.cropping-tools {
    position: relative;
    display: flex;
    flex: auto;
    overflow: auto;
    height: fit-content;
    padding: 2rem 2rem 0;

    .media-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 300px);
      margin-bottom: 2rem;
    }

    .media-cropper {
      display: flex;
      justify-content: center;
      max-height: 100%;
      flex-grow: 1;
    }

    .video-footer-tools {
      padding: 2rem;
      margin-bottom: var(--space-24);

      .slider-container {
        display: flex;
        justify-content: center;
      }
    }
  }

  .cropper-validation-error {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  footer.dh-media-cropper {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid var(--border);

    .footer-tools {
      width: calc(100% - 300px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .play {
      flex-shrink: 0;
      margin: 0 var(--space-32);
    }

    .mute {
      flex-shrink: 0;
      margin: 0 var(--space-32);
      border: none;
    }

    .controls {
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .save {
        margin: 0 var(--space-32) 0 var(--space-16);
      }
    }

    .crop-prediction-warning {
      width: 100%;
      font-size: 0.7rem;
      line-height: 16px;
      text-align: right;
      padding: 0 32px 16px 0;
      margin-top: -16px;
    }
  }
}

#popup-page-container.tags-confirmation-popup {
  :deep(p) {
    margin: 0 var(--space-16);
  }
}

:deep(.validation-error) {
  justify-content: center;
  align-items: center;
}
</style>
