<script setup>
import { computed, onMounted, ref } from 'vue';
import EmbedPreviewMediaView from '@/app/library/components/MediaPopup/EmbedPreviewMediaView.vue';

// props
const props = defineProps({
  mediaItem: {
    type: Object,
    required: true,
  },
});

// refs
const charsPerLine = 85;
const pixelsPerLine = 210;
const iframeStyles = ref({
  width: '100%',
  'max-height': '845px',
  'border-radius': 'var(--round-corner-large)',
});

// computed
const srcUrl = computed(
  () => `https://www.linkedin.com/embed/feed/update/${props.mediaItem.postSourceId}`,
);

const iframeHeight = computed(() => {
  const textContentLength = props.mediaItem.caption?.length ?? 0;
  let numOfLines = Math.floor(textContentLength / charsPerLine);
  numOfLines += textContentLength % charsPerLine > 0 ? 1 : 0;
  // empty caption post
  if (numOfLines === 0) {
    numOfLines = 1;
  }
  return numOfLines * pixelsPerLine;
});

onMounted(() => {
  iframeStyles.value = {
    ...iframeStyles.value,
    height: `${iframeHeight.value}px`,
  };
});

defineExpose({
  srcUrl,
  iframeHeight,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <EmbedPreviewMediaView :src-url="srcUrl" :styles="iframeStyles" />
</template>
