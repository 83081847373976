<template>
  <transition name="slide">
    <div v-if="dropdownOpen" v-on-click-outside="closeDropdown" class="add-tags-dropdown">
      <p>Add Visual Tags</p>
      <AddCustomTags @on-save="closeDropdown" />
    </div>
  </transition>
</template>

<script>
import { defineComponent } from 'vue';
import { vOnClickOutside } from '@vueuse/components';
import AddCustomTags from '@/components/AddCustomTags.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'AddTagsDropdown',
  components: {
    AddCustomTags,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  data() {
    return {
      dropdownOpen: false,
    };
  },
  methods: {
    closeDropdown(e) {
      if ((e && e.target !== this.$parent.$refs.addTagsButton) || !e) {
        this.dropdownOpen = false;
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.add-tags-dropdown {
  z-index: var(--z-index-dropdown);
  background: var(--background-0);
  border-radius: var(--round-corner-small);
  box-shadow: var(--shadow-4);
  width: 18.75rem;
  height: fit-content;
  padding-top: var(--space-24);

  p {
    color: var(--text-primary);
    font-size: var(--x16);
    font-weight: var(--font-medium);
    padding: 0 var(--space-24);
  }
}
</style>
