<template>
  <AudienceSection
    v-model="audienceSectionTab"
    :audience-statistics="audienceStatistics"
    :empty-state-copy="emptyStateCopy"
  />
</template>

<script>
import { defineComponent } from 'vue';
import startCase from 'lodash/startCase';
import { toolTips } from '@/config';
import { audienceSectionTabs } from '@/app/library/constants';
import { ageGroupLabelMap, internalTrafficSourceMap } from '@/app/youtube/constants';
import { getCountryName } from '@/utils/countries';
import AudienceSection from '@/app/library/components/MediaPopup/AudienceSection.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'YouTubeAudienceSection',
  components: { AudienceSection },
  props: {
    value: {
      type: String,
      default: audienceSectionTabs.TRAFFIC_SOURCE,
    },
    mediaItem: { type: Object, required: true },
  },
  emits: ['input', 'tabChanged'],
  data() {
    return {
      tooltips: toolTips.tiktokInsights,
      trafficSourceTypes: [
        'withinYouTube',
        'externalTrafficSources',
        'topSearchTerms',
        'directOrUnknown',
      ],
      dropdownOpen: true,
      emptyStateCopy: 'No data available',
      audienceSectionTab: this.value,
      maxBreakdownValuesToDisplay: 5,
      altStatNameMap: {
        externalTrafficSources: 'external sources',
        topSearchTerms: 'search terms',
      },
    };
  },
  computed: {
    audienceStatistics() {
      return {
        [audienceSectionTabs.TRAFFIC_SOURCE]: {
          tooltip: 'The breakdown of how viewers reached this video.',
          statistics: this.trafficSourceStatistics,
        },
        [audienceSectionTabs.DEMOGRAPHICS]: {
          tooltip: 'The gender and age breakdown of logged-in viewers of this video.',
          subHeader: 'Viewer Age',
          statistics: this.ageGenderDemographicStatistics,
          format: 'one_decimal_percent',
        },
        [audienceSectionTabs.LOCATION]: {
          tooltip: 'The country associated with where this video was viewed.',
          statistics: this.sortByValue(this.locationStatistics),
        },
      };
    },
    trafficSourceStatistics() {
      const { audience } = this.mediaItem;

      if (this.isEmptyObject(audience)) {
        return [];
      }

      const trafficSources = this.trafficSourceTypes.filter(
        (source) => audience[source] && !this.isEmptyObject(audience[source]),
      );

      // we'll use the maximum total value for setting the width of the bar
      let maxTotal = -1;
      const trafficSourceData = trafficSources.map((source) => {
        // show all breakdown values for Within YouTube section
        const maxToDisplay = source === 'withinYouTube' ? null : this.maxBreakdownValuesToDisplay;

        const sourceObject = audience[source];
        const total = Object.keys(sourceObject).reduce((prev, cur) => prev + sourceObject[cur], 0);

        if (total > maxTotal) maxTotal = total;

        return {
          label: this.formatStatName(source),
          value: total,
          statisticBreakdown: this.formatTrafficSourceBreakdownObject(sourceObject, source),
          altStatName: this.altStatNameMap[source],
          maxToDisplay,
        };
      });

      // adding the barWidth now that we have the max
      return trafficSourceData.map((sourceObj) => {
        return {
          ...sourceObj,
          barWidthRatio: sourceObj.value / maxTotal,
        };
      });
    },
    locationStatistics() {
      let { countryDemographics } = this.mediaItem.audience;

      if (this.isEmptyObject(countryDemographics)) {
        return [];
      }

      let maxTotal = -1;
      countryDemographics = Object.keys(countryDemographics)
        .filter((key) => countryDemographics[key] !== 0)
        .map((key) => {
          if (countryDemographics[key] > maxTotal) maxTotal = countryDemographics[key];

          return {
            label: getCountryName(key),
            value: countryDemographics[key],
          };
        })
        .sort((a, b) => b.value - a.value);

      // if there are more than 5 countries, we want to condense the countries with the least values
      // into an "Other" category
      if (countryDemographics.length > 5) {
        const displayedStatistics = countryDemographics.slice(0, 4);
        const otherStatisticValue = countryDemographics.slice(4).reduce((prev, cur) => {
          return cur.value + prev;
        }, 0);

        countryDemographics = [
          ...displayedStatistics,
          {
            label: 'Other',
            value: otherStatisticValue,
          },
        ];
      }

      // adding the barWidthRatio to each entry now that we have the maxTotal
      return countryDemographics.map((country) => {
        return {
          ...country,
          barWidthRatio: country.value / maxTotal,
        };
      });
    },
    ageGenderDemographicStatistics() {
      const {
        audience: { ageGenderDemographics },
      } = this.mediaItem;

      if (this.isEmptyObject(ageGenderDemographics)) {
        return [];
      }

      const demographicData = [];
      Object.keys(ageGroupLabelMap).forEach((key) => {
        if (this.isEmptyObject(ageGenderDemographics[key])) return;

        const demographicObject = ageGenderDemographics[key];
        const total = Object.keys(demographicObject).reduce(
          (prev, cur) => prev + demographicObject[cur],
          0,
        );
        demographicData.push({
          label: ageGroupLabelMap[key],
          value: total,
          statisticBreakdown: this.formatDemographicBreakdownObject(demographicObject),
        });
      });
      return demographicData;
    },
  },
  watch: {
    audienceSectionTab(to) {
      this.$emit('input', to);
      this.$emit('tabChanged', 'Audience', `Click ${startCase(to.toLowerCase())}`);
    },
  },
  methods: {
    formatStatName(name) {
      const trafficSourceLabelMap = {
        withinYouTube: 'Within YouTube',
        directOrUnknown: 'Direct/Unknown',
        externalTrafficSources: 'Top External Sources',
      };

      return trafficSourceLabelMap[name] || startCase(name);
    },
    isEmptyObject(obj) {
      if (!obj) return true;
      return Object.keys(obj).length === 0;
    },
    formatTrafficSourceBreakdownObject(sourceObject, source) {
      // we do not want to show the breakdown for directOrUnknown
      if (source === 'directOrUnknown') return null;

      const breakdownObject = Object.keys(sourceObject).map((key) => {
        return {
          label:
            source === 'withinYouTube'
              ? internalTrafficSourceMap[key] || startCase(key.toLowerCase())
              : key,
          value: sourceObject[key],
        };
      });

      // sort the object highest to lowest value
      return this.sortByValue(breakdownObject);
    },
    formatDemographicBreakdownObject(demographicObject) {
      // we want the order to be male, female, user_specified no matter the values
      const statisticOrder = ['male', 'female', 'user_specified'];
      const breakdownObject = [];

      statisticOrder.forEach((gender) => {
        const value = demographicObject[gender];

        if (!value) return;

        breakdownObject.push({
          label: startCase(gender),
          value,
        });
      });
      return breakdownObject;
    },
    sortByValue(items) {
      return items.sort((a, b) => b.value - a.value);
    },
  },
});
export default comp;
</script>
