<template>
  <InsightsDropdown
    :has-insights-access="true"
    header-title="LikeShop Insights"
    platform-icon="shoppingBag"
  >
    <template #dropdownContents>
      <div class="insights-list">
        <div>
          <ul class="insights-stats">
            <li v-for="(value, key) in likeshopInsights" :key="key">
              <span class="stat-name">
                {{ key }}
              </span>
              <span class="stat">{{ value }}</span>
            </li>
          </ul>
        </div>
      </div>
    </template>
  </InsightsDropdown>
</template>

<script>
import { defineComponent } from 'vue';
import InsightsDropdown from './InsightsDropdown.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'LikeShopInsights',
  components: { InsightsDropdown },
  props: { mediaItem: { type: Object, default: null } },
  computed: {
    likeshopInsights() {
      const insights = {};
      if (Number.isInteger(this.mediaItem?.likeshopClicks)) {
        insights['Instagram LikeShop Clicks'] = this.mediaItem.likeshopClicks;
      }
      if (Number.isInteger(this.mediaItem?.tiktokLikeshopClicks)) {
        insights['TikTok LikeShop Clicks'] = this.mediaItem.tiktokLikeshopClicks;
      }
      return insights;
    },
  },
});
export default comp;
</script>
