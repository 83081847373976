import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import * as PinterestAPI from '@/apis/pinterest';

export const useSchedulerPinterestStore = defineStore(
  'schedulerPinterest',
  () => {
    const authStore = useAuthStore();

    const addToSameBoardSetting = ref({});
    const publishAtSameTimeSetting = ref({});
    const boardList = ref([]);
    const pinterestAccounts = ref([]);
    const createBoardStatus = ref(null);
    const selectedPin = ref(null);
    const updatePinTrigger = ref(null);
    const errorMsg = ref(null);

    async function createPinterestBoards({ name }) {
      const brandId = authStore.currentBrand?.id;

      createBoardStatus.value = 'pending';
      errorMsg.value = '';
      try {
        const response = await PinterestAPI.createBoard({ brandId, name });
        const payload = response?.data;

        boardList.value = [payload, ...boardList.value];
        createBoardStatus.value = 'success';

        return payload;
      } catch (e) {
        createBoardStatus.value = 'failed';
        errorMsg.value = e.response?.data?.message;
        throw new Error(errorMsg.value);
      }
    }

    async function listPinterestBoards() {
      const brandId = authStore.currentBrand?.id;
      const response = await PinterestAPI.getBoards({ brandId });
      const payload = response?.data;

      boardList.value = payload;

      return payload;
    }

    async function getPinterestAccounts({ brandIds }) {
      const response = await PinterestAPI.getPinterestAccounts({ brandIds });
      const payload = response?.data;

      pinterestAccounts.value = payload;

      return payload;
    }

    function clearPinterestAccounts() {
      pinterestAccounts.value = [];
    }

    function setSelectedPin({ pin }) {
      selectedPin.value = pin;
    }

    function triggerPinUpdate() {
      updatePinTrigger.value = new Date();
    }

    function updateAddToSameBoard({ setting }) {
      addToSameBoardSetting.value = setting;
    }

    function updatePublishAtSameTime({ setting }) {
      publishAtSameTimeSetting.value = setting;
    }

    return {
      addToSameBoardSetting,
      publishAtSameTimeSetting,
      updatePinTrigger,
      selectedPin,
      boardList,
      createBoardStatus,
      pinterestAccounts,
      errorMsg,
      createPinterestBoards,
      listPinterestBoards,
      getPinterestAccounts,
      clearPinterestAccounts,
      setSelectedPin,
      triggerPinUpdate,
      updateAddToSameBoard,
      updatePublishAtSameTime,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
