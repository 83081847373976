import { defineStore } from 'pinia';
import { ref } from 'vue';
import { isDatadogSyntheticsBrowser } from '@/config';
import { env } from '@/env';
import { getUserTimezone } from '@/utils/timezone';
import { loadScript } from '@/utils/dom';
import { useIdentityStore } from '@/stores/identity';
// eslint-disable-next-line import/no-cycle
import { useTrackingStore } from '@/stores/tracking';

export const useIntercomStore = defineStore('intercom', () => {
  const identityStore = useIdentityStore();
  const trackingStore = useTrackingStore();
  const showIntercomButtonText = ref(null);
  const ready = ref(false);

  const appId = env.intercomAppId;
  const enabled = env.intercomEnabled && !isDatadogSyntheticsBrowser;

  function intercomAction(action, ...options) {
    if (!window.Intercom) return;
    window.Intercom(action, ...options);
  }

  function shutdown() {
    intercomAction('shutdown');
  }

  function reattach() {
    intercomAction('reattach_activator');
  }

  function update(properties) {
    intercomAction('update', properties);
  }

  function show(buttonText = 'Contact Us') {
    showIntercomButtonText.value = buttonText;
    intercomAction('show');
  }

  function trackEvent(eventName, properties = {}) {
    intercomAction('trackEvent', eventName, properties);
  }

  function startChecklist() {
    window.Intercom('startChecklist', 40367175);
  }

  function boot() {
    const identity = identityStore.identity;
    const currentBrand = identityStore.currentBrand;
    if (enabled && identity && currentBrand) {
      intercomAction('boot', {
        app_id: appId,
        name: `${identity.first_name} ${identity.last_name}`,
        email: identity.email,
        first_name: identity.first_name,
        last_name: identity.last_name,
        has_device: identity.has_device,
        time_zone_name: getUserTimezone(),
        organization: identity.organization?.name || '',
        organization_customer_stage: identity.organization?.customer_stage,
        job_title: identity.job_title,
        is_admin: identity.is_admin,
        brand_id: currentBrand.id,
        brand_name: currentBrand.name,
        avatar: currentBrand.avatarUrl,
        Plan: currentBrand.plan?.plan_type || '',
        brand_customer_stage: currentBrand.plan?.customer_stage,
        prospect_journey: currentBrand.plan?.prospect_journey,
        hide_default_launcher: true,
      });
    }
  }

  if (enabled) {
    loadScript(`https://widget.intercom.io/widget/${appId}`);

    // Inspired by:
    // https://developers.intercom.com/installing-intercom/docs/basic-javascript#how-to-install
    // https://github.com/mathieustan/vue-intercom/blob/master/src/install.js

    if (typeof window.Intercom === 'function') {
      reattach();
      update();
    } else {
      const placeholder = (...args) => placeholder.c(args);
      placeholder.q = [];
      placeholder.c = (args) => placeholder.q.push(args);
      window.Intercom = placeholder;
    }

    intercomAction('onShow', () => {
      trackingStore.track('Intercom Open', {
        buttonText: showIntercomButtonText.value,
        openedFrom: showIntercomButtonText.value ? 'button' : 'intercom message',
      });
    });

    intercomAction('onHide', () => {
      showIntercomButtonText.value = null;
      trackingStore.track('Intercom Close');
    });

    ready.value = true;
  }

  return {
    enabled,
    boot,
    show,
    shutdown,
    trackEvent,
    startChecklist,
    ready,
    update,
  };
});
