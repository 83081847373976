<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useCommunityDmsStore } from '@/stores/community-dms';
import { useAuthStore } from '@/stores/auth';
import {
  COMMUNITY_ADS_INTERACTION_TYPES,
  COMMUNITY_INTERACTION_TYPES,
  COMMUNITY_PLATFORM_FILTER_WIDGET,
} from '@/app/community/constants';
import { BRAND, USER } from '@/models/auth/permissions.enum';
import { usePlatformStore } from '@/stores/platform';
import { useFlagStore } from '@/stores/flag';
import { useCommunityStore } from '@/stores/community';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'EnabledCommunityInteractionTypes',
  computed: {
    ...mapStores(
      useAuthStore,
      usePlatformStore,
      useCommunityDmsStore,
      useFlagStore,
      useCommunityStore,
    ),
    brandCanAccessInboxAutomation() {
      return this.authStore.guard(BRAND.COMMUNITY.CAN_ACCESS_INBOX_AUTOMATION);
    },
    userCanAccessCommunityOverview() {
      return this.authStore.guard(USER.COMMUNITY.CAN_ACCESS_COMMUNITY_OVERVIEW);
    },
    brandCanAccessCommunityOverview() {
      return this.authStore.guard(BRAND.COMMUNITY.CAN_ACCESS_COMMUNITY_OVERVIEW);
    },
    canAccessCommunityOverview() {
      return this.userCanAccessCommunityOverview && this.brandCanAccessCommunityOverview;
    },
    brandCanAccessInternalNotes() {
      return this.authStore.guard(BRAND.COMMUNITY.CAN_ACCESS_COMMUNITY_COLLABORATION);
    },
    canAccessInstagramDirect() {
      return this.authStore.guard(USER.COMMUNITY.CAN_ACCESS_INSTAGRAM_DIRECT);
    },
    canAccessInstagramComments() {
      return this.authStore.guard(USER.COMMUNITY.CAN_ACCESS_INSTAGRAM_COMMENTS);
    },
    canAccessTikTokComments() {
      return (
        this.authStore.guard(BRAND.TIKTOK.CAN_ACCESS_TIKTOK_INSIGHTS) &&
        this.authStore.guard(USER.COMMUNITY.CAN_ACCESS_TIKTOK_COMMENTS)
      );
    },
    canAccessFacebookMessenger() {
      return this.authStore.guard(USER.COMMUNITY.CAN_ACCESS_FACEBOOK_MESSENGER);
    },
    canAccessFacebookComments() {
      return this.authStore.guard(USER.COMMUNITY.CAN_ACCESS_FACEBOOK_COMMENTS);
    },
    canAccessTwitterMentions() {
      return this.authStore.guard(USER.COMMUNITY.CAN_ACCESS_TWITTER_MENTIONS);
    },
    canAccessTwitterDirect() {
      return this.authStore.guard(USER.COMMUNITY.CAN_ACCESS_TWITTER_DIRECT);
    },
    canAccessYouTubeComments() {
      return this.authStore.guard(USER.COMMUNITY.CAN_ACCESS_YOUTUBE_COMMENTS);
    },
    enabledTypes() {
      const types = [];
      if (this.canAccessInstagramComments) {
        types.push(COMMUNITY_INTERACTION_TYPES.INSTAGRAM_COMMENT);
        types.push(COMMUNITY_ADS_INTERACTION_TYPES.INSTAGRAM_ADS_COMMENT);
      }
      if (this.canAccessInstagramDirect) {
        types.push(COMMUNITY_INTERACTION_TYPES.INSTAGRAM_CONVERSATION);
      }
      if (this.canAccessTikTokComments) {
        types.push(COMMUNITY_INTERACTION_TYPES.TIKTOK_COMMENT);
      }
      if (this.canAccessFacebookComments) {
        types.push(COMMUNITY_INTERACTION_TYPES.FACEBOOK_COMMENT);
        types.push(COMMUNITY_ADS_INTERACTION_TYPES.FACEBOOK_ADS_COMMENT);
      }
      if (this.canAccessFacebookMessenger) {
        types.push(COMMUNITY_INTERACTION_TYPES.FACEBOOK_CONVERSATION);
      }
      if (this.canAccessTwitterMentions) {
        types.push(COMMUNITY_INTERACTION_TYPES.TWITTER_MENTION);
        types.push(COMMUNITY_INTERACTION_TYPES.TWITTER_RETWEET);
        types.push(COMMUNITY_INTERACTION_TYPES.TWITTER_QUOTE);
      }
      if (this.canAccessTwitterDirect) {
        types.push(COMMUNITY_INTERACTION_TYPES.TWITTER_CONVERSATION);
      }
      if (this.canAccessYouTubeComments) {
        types.push(COMMUNITY_INTERACTION_TYPES.YOUTUBE_COMMENT);
      }
      return types;
    },
    enabledPlatforms() {
      const platforms = [];
      if (this.canAccessFacebookComments || this.canAccessFacebookMessenger) {
        platforms.push(COMMUNITY_PLATFORM_FILTER_WIDGET.FACEBOOK.value);
      }
      if (this.canAccessInstagramComments || this.canAccessInstagramDirect) {
        platforms.push(COMMUNITY_PLATFORM_FILTER_WIDGET.INSTAGRAM.value);
      }
      if (this.canAccessTikTokComments) {
        platforms.push(COMMUNITY_PLATFORM_FILTER_WIDGET.TIKTOK.value);
      }
      if (this.canAccessTwitterMentions || this.canAccessTwitterDirect) {
        platforms.push(COMMUNITY_PLATFORM_FILTER_WIDGET.TWITTER.value);
      }
      if (this.canAccessYouTubeComments) {
        platforms.push(COMMUNITY_PLATFORM_FILTER_WIDGET.YOUTUBE.value);
      }
      return platforms;
    },
    loading() {
      return (
        !this.flagStore?.flags ||
        this.platformStore.pending.platformConnections ||
        this.communityStore.pending.instagramAccount
      );
    },
  },
});
export default comp;
</script>
