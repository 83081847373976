<script setup>
import { computed } from 'vue';
import Icon from '@/components/foundation/Icon.vue';

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
  open: {
    type: Boolean,
    default: false,
  },
  hideOpenIndicator: {
    type: Boolean,
    default: false,
  },
  reducePadding: {
    type: Boolean,
    default: false,
  },
  inline: {
    type: Boolean,
    default: false,
  },
  largeText: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: null,
  },
  value: {
    type: [String, Array],
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const hasValue = computed(() => {
  return Array.isArray(props.value) ? props.value.length > 0 : !!props.value;
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div
    data-cy="SelectionDisplayInput"
    class="selection-display-input text-small items-center justify-between"
    :class="{
      active: props.active,
      open: props.open,
      flex: !props.inline,
      'inline-flex': props.inline,
      'px-4': !props.reducePadding,
      'px-2': props.reducePadding,
      'cursor-pointer': !props.disabled,
      'bg-grey-400': props.disabled,
      'cursor-not-allowed': props.disabled,
    }"
  >
    <div
      class="input-value max-w-full overflow-hidden text-ellipsis"
      :class="{ 'text-h4': largeText }"
    >
      <template v-if="hasValue || !placeholder">
        <slot>
          {{ props.value }}
        </slot>
      </template>
      <template v-else>
        <span class="text-secondary">{{ props.placeholder }}</span>
      </template>
    </div>
    <template v-if="!props.hideOpenIndicator">
      <div class="text-small ml-2 flex h-6 w-6 items-center justify-center">
        <Icon name="chevronDown" :dir="props.open ? 'down' : 'up'" xsmall />
      </div>
    </template>
  </div>
</template>

<style lang="postcss" scoped>
.selection-display-input {
  min-height: 2.5rem;
  border: 1px solid var(--border);
  border-radius: var(--round-corner-small);
  background: var(--background-0);

  &.open,
  &.active,
  &:hover {
    .input-value {
      color: var(--text-link);
    }
  }

  &.open {
    border: 1px solid var(--text-link);
  }
}
</style>
