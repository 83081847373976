import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { useMediaPopupStore } from '@/stores/media-popup';

const contextListIndex = ref();
const context = ref({});

export function useMediaDrawer() {
  const router = useRouter();

  const mediaPopupStore = useMediaPopupStore();

  async function openMedia({ id, brandId, brandLabel, source, replaceModal, patchJson } = {}) {
    const params = {
      id,
    };
    const query = {};

    if (brandId) {
      params.brandId = brandId;
      query.brandId = brandId;
    }

    if (brandLabel) {
      params.brandLabel = brandLabel;
    }

    if (source) {
      query.source = source;
    }
    if (replaceModal) {
      query.replaceModal = !!replaceModal;
    }
    if (patchJson) {
      query.patchJson = patchJson;
    }

    const payload = {
      name: 'library.media',
      params,
    };

    if (Object.keys(query).length > 0) {
      payload.query = query;
    }

    context.value = { brandId, brandLabel, source, patchJson };

    await router.push(payload);
  }

  function updateContextListIndex(media) {
    if (!media || !mediaPopupStore.contextList) {
      contextListIndex.value = null;
      return;
    }

    contextListIndex.value = mediaPopupStore.contextList.findIndex((item) => {
      if (item.id) {
        return item.id === media.id || item.id === media.postSourceId;
      }
      return item.sourceId === media.postSourceId;
    });
  }

  const hasPreviousMedia = computed(() => {
    if (mediaPopupStore.contextList?.length > 0) {
      return contextListIndex.value > 0;
    }
    return false;
  });

  const hasNextMedia = computed(() => {
    if (mediaPopupStore.contextList?.length > 0) {
      return contextListIndex.value < mediaPopupStore.contextList.length - 1;
    }
    return false;
  });

  function loadPreviousMedia() {
    if (!hasPreviousMedia.value) {
      return null;
    }

    contextListIndex.value -= 1;
    const previousMedia = mediaPopupStore.contextList[contextListIndex.value];

    const id = previousMedia.id || previousMedia.sourceId;
    const source = previousMedia.source;

    const previousPatchJson = { ...(context.value.patchJson ?? {}), media_id: id };
    if (previousMedia[source]?.timestamp) {
      previousPatchJson.timestamp = previousMedia[source].timestamp;
    }

    openMedia({
      id,
      patchJson: previousPatchJson,

      brandLabel: context.value.brandLabel,
      brandId: context.value.brandId,
      source: context.value.source,

      replaceModal: true,
    });

    return previousMedia;
  }

  function loadNextMedia() {
    if (!hasNextMedia.value) {
      return null;
    }

    contextListIndex.value += 1;
    const nextMedia = mediaPopupStore.contextList[contextListIndex.value];

    const id = nextMedia.id || nextMedia.sourceId;
    const source = nextMedia.source;

    const nextPatchJson = { ...context.value.patchJson, media_id: id };
    if (nextMedia[source]?.timestamp) {
      nextPatchJson.timestamp = nextMedia[source].timestamp;
    }

    openMedia({
      id,
      patchJson: nextPatchJson,

      brandLabel: context.value.brandLabel,
      brandId: context.value.brandId,
      source: context.value.source,

      replaceModal: true,
    });

    return nextMedia;
  }

  return {
    openMedia,
    hasPreviousMedia,
    hasNextMedia,
    loadPreviousMedia,
    loadNextMedia,
    updateContextListIndex,
  };
}
