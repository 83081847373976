import merge from 'lodash/merge';
import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.instagramApiUrl);

export const axiosCamelCase = createAxiosInstance(env.instagramApiUrl, {
  camelizeKeys: true,
});
// instance for utm data, some keys and value comes from user input
export const unformattedAxios = createAxiosInstance(env.instagramApiUrl, {
  camelizeKeys: false,
  decamelizeRequests: false,
});

export async function updatePost({ brandId, postSourceId }, axiosConfig = {}) {
  return axios.patch(`/brands/${brandId}/instagram/posts/${postSourceId}`, {}, axiosConfig);
}

export async function getInstagramAccount({ brandId }, axiosConfig = {}) {
  return axiosCamelCase.get(`/accounts/${brandId}`, axiosConfig);
}

export async function sendEmailReport({ brandId, reportType, ...params }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/instagram/reports/email/${reportType}`,
    merge({ params }, axiosConfig),
  );
}

export async function addAccount({ handle }, axiosConfig = {}) {
  return axios.post(`/instagram/accounts/${handle}`, {}, axiosConfig);
}

export async function getPublicAccountsByIds({ instagramIds }, axiosConfig = {}) {
  return axiosCamelCase.post('/instagram/accounts/public', { instagramIds }, axiosConfig);
}

export async function getAccountPosts(
  { instagramId, startDate, endDate, limit, sortOrder = 'asc' },
  axiosConfig = {},
) {
  return axiosCamelCase.get(
    `/instagram/accounts/${instagramId}/posts`,
    merge(
      {
        params: {
          startDate,
          endDate,
          limit,
          sortOrder,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getReport360Posts({ month, brandId }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/instagram/reports/360/posts`,
    merge(
      {
        params: {
          month,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getReport360Stories({ month, brandId }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/instagram/reports/360/stories`,
    merge(
      {
        params: {
          month,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getUgcAccounts(
  { brandId, startDate, endDate, limit, url },
  axiosConfig = {},
) {
  return axios.get(
    url || `/brands/${brandId}/ugc_accounts`,
    merge(
      {
        params: {
          startDate,
          endDate,
          limit,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getUgcAccountsCSV(
  { brandId, startDate, endDate, socketId, type },
  axiosConfig = {},
) {
  return axios.post(
    `/brands/${brandId}/ugc_accounts/csv`,
    {},
    merge(
      {
        params: {
          startDate,
          endDate,
          socketId,
          type,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getFollowersLost({ brandId, startDate, endDate, scale }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/followers_lost_insights`,
    merge(
      {
        params: {
          startDate,
          endDate,
          scale,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getFollowersLostCSV(
  { brandId, startDate, endDate, scale, socketId },
  axiosConfig = {},
) {
  return axios.post(
    `/brands/${brandId}/followers_lost_insights/csv`,
    {},
    merge(
      {
        params: {
          startDate,
          endDate,
          scale,
          socketId,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getFollowerInsights(
  { brandId, startDate, endDate, scale },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/followers_insights`,
    merge(
      {
        params: {
          startDate,
          endDate,
          scale,
        },
        camelizeKeys: true,
      },
      axiosConfig,
    ),
  );
}

export async function getFollowerInsightsCSV(
  { brandId, startDate, endDate, scale, socketId },
  axiosConfig = {},
) {
  return axios.post(
    `/brands/${brandId}/followers_insights/csv`,
    {},
    merge(
      {
        params: {
          startDate,
          endDate,
          scale,
          socketId,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getFollowersDemographics({ brandId, date }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/followers_demographics`,
    merge(
      {
        params: { date },
      },
      axiosConfig,
    ),
  );
}

export async function getFollowersDemographicsCSV(
  { brandId, date, socketId, type },
  axiosConfig = {},
) {
  return axios.post(
    `/brands/${brandId}/followers_demographics/csv`,
    { date, socketId, type },
    axiosConfig,
  );
}

export async function getOnlineFollowers({ brandId, startDate, endDate }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/online_followers`,
    merge(
      {
        params: {
          startDate,
          endDate,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getOnlineFollowersCSV(
  { brandId, startDate, endDate, socketId },
  axiosConfig = {},
) {
  return axios.post(
    `/brands/${brandId}/online_followers/csv`,
    {
      startDate,
      endDate,
      socketId,
    },
    axiosConfig,
  );
}

export async function getDemographicInsights({ brandId, date, metric }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/demographics_insights`,
    merge(
      {
        params: { date, metric },
      },
      axiosConfig,
    ),
  );
}

export async function postDemographicInsightsCSV(
  { brandId, date, metric, socketId, breakdown },
  axiosConfig = {},
) {
  return axios.post(
    `/brands/${brandId}/demographics_insights/csv`,
    { date, metric, socketId, breakdown },
    axiosConfig,
  );
}

export async function getInstagramUsers({ brandId, handle, after, limit }, axiosConfig = {}) {
  return axiosCamelCase.get(
    `/brands/${brandId}/instagram_users`,
    merge({ params: { handle, after, limit } }, axiosConfig),
  );
}

export async function getBrandInstagramAccount({ brandId }, axiosConfig = {}) {
  return axiosCamelCase.get(`/brands/${brandId}/instagram/account`, axiosConfig);
}

export async function getBrandUserInsights(
  { brandId, scale, startDate, endDate },
  axiosConfig = {},
) {
  return axiosCamelCase.get(
    `/brands/${brandId}/brand_user_insights`,
    merge(
      {
        params: {
          scale,
          startDate,
          endDate,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getBrandUserInsightsCsv(
  { brandId, scale, startDate, endDate, socketId },
  axiosConfig = {},
) {
  return axiosCamelCase.get(
    `/brands/${brandId}/brand_user_insights/csv`,
    merge(
      {
        params: {
          scale,
          startDate,
          endDate,
          socketId,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getInstagramRightsRequestMediaCounts(
  { brandId, startDate, endDate, userId, contentRightsType },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/instagram/rights_request_media/counts`,
    merge(
      {
        params: {
          startDate,
          endDate,
          userId,
          contentRightsType,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getInstagramRightsRequestMediaCsv(
  { brandId, startDate, endDate, userId, contentRightsType, socketId, type },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/instagram/rights_request_media/csv`,
    merge(
      {
        params: {
          startDate,
          endDate,
          userId,
          contentRightsType,
          socketId,
          type,
        },
      },
      axiosConfig,
    ),
  );
}

export async function addRightsRequestFacebookComment(
  { brandId, postId, message },
  axiosConfig = {},
) {
  return axios.post(
    `/brands/${brandId}/instagram/rights_request_media/${postId}/facebook_comment`,
    { message },
    axiosConfig,
  );
}

export async function getRightsRequestComments({ brandId }, axiosConfig = {}) {
  return axiosCamelCase.get(`/brands/${brandId}/instagram/rights_request_comments`, axiosConfig);
}

export async function addRightsRequestComment(
  { brandId, comment, requestDocumentId, requestHashtagId },
  axiosConfig = {},
) {
  return axiosCamelCase.post(
    `/brands/${brandId}/instagram/rights_request_comments`,
    {
      comment,
      requestDocumentId,
      requestHashtagId,
    },
    axiosConfig,
  );
}

export async function updateRightsRequestComment(
  { brandId, commentId, comment, requestDocumentId, requestHashtagId },
  axiosConfig = {},
) {
  return axiosCamelCase.patch(
    `/brands/${brandId}/instagram/rights_request_comments/${commentId}`,
    { comment, requestDocumentId, requestHashtagId },
    axiosConfig,
  );
}

export async function deleteRightsRequestComment({ brandId, commentId }, axiosConfig = {}) {
  return axiosCamelCase.delete(
    `/brands/${brandId}/instagram/rights_request_comments/${commentId}`,
    axiosConfig,
  );
}

export async function getInstagramUgcComments({ brandId, mediaId }, axiosConfig = {}) {
  return axiosCamelCase.get(`/brands/${brandId}/instagram/ugc_comments/${mediaId}`, axiosConfig);
}

export async function getIdealPostTimes({ brandId, timezone }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/instagram/ideal_post_times`,
    merge(
      {
        params: {
          timezone,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getRightsRequestMedia(
  { brandId, startDate, endDate, userId, contentRightsType, limit, offset },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/instagram/rights_request_media`,
    merge(
      {
        params: {
          startDate,
          endDate,
          userId,
          contentRightsType,
          limit,
          offset,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getPosts({ brandId }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/instagram/posts`, axiosConfig);
}

export async function addRelationship({ brandId, instagramName }, axiosConfig = {}) {
  return axios.post(`/brands/${brandId}/instagram/relationships`, { instagramName }, axiosConfig);
}

export async function getReachStats({ brandId, startDate, endDate, scale }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/reach/stats`,
    merge(
      {
        params: {
          startDate,
          endDate,
          scale,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getRelationshipList(
  {
    brandId,
    url,
    keyword,
    sort,
    publishedStartDate,
    publishedEndDate,
    isBusiness,
    type,
    onlyPostsWithType,
    tagIds,
    tagFilterType,
    isFbConnected,
    acceptanceStatuses,
  },
  axiosConfig = {},
) {
  return axiosCamelCase.get(
    url || `/brands/${brandId}/instagram/relationships`,
    merge(
      {
        params: {
          keyword,
          sort,
          publishedStartDate,
          publishedEndDate,
          isBusiness,
          type,
          onlyPostsWithType,
          tagIds,
          tagFilterType,
          isFbConnected,
          acceptanceStatuses,
        },
      },
      axiosConfig,
    ),
  );
}

export async function exportRelationshipOverviewCSV(
  {
    brandId,
    keyword,
    sort,
    publishedStartDate,
    publishedEndDate,
    isBusiness,
    type,
    onlyPostsWithType,
    tagIds,
    tagFilterType,
    isFbConnected,
    acceptanceStatuses,
    csvType,
    socketId,
  },
  axiosConfig = {},
) {
  return axiosCamelCase.post(
    `/brands/${brandId}/instagram/relationships/csv`,
    {},
    merge(
      {
        params: {
          keyword,
          sort,
          publishedStartDate,
          publishedEndDate,
          isBusiness,
          type,
          onlyPostsWithType,
          tagIds,
          tagFilterType,
          isFbConnected,
          acceptanceStatuses,
          csvType,
          socketId,
        },
      },
      axiosConfig,
    ),
  );
}

export async function findRelationshipByHandle({ brandId, handle }, axiosConfig = {}) {
  return axiosCamelCase.get(
    `/brands/${brandId}/instagram/relationships`,
    merge(
      {
        params: {
          handle,
        },
      },
      axiosConfig,
    ),
  );
}

export async function findMediaRelationshipByHandle({ brandId, handle }, axiosConfig = {}) {
  return axiosCamelCase.get(
    `/brands/${brandId}/instagram/relationships`,
    merge(
      {
        params: {
          handle,
        },
      },
      axiosConfig,
    ),
  );
}

export async function findRelationshipByInfluencerId(
  { brandId, influencerId, connectInstagramInfluencerToken },
  axiosConfig = {},
) {
  return axiosCamelCase.get(
    `/brands/${brandId}/instagram/relationships`,
    merge(
      {
        params: {
          influencerId,
          connectInstagramInfluencerToken,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getRightsRequestMediaPost({ brandId, id }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/instagram/rights_request_media/${id}`, axiosConfig);
}

export async function createRightsRequestMediaPost(
  { brandId, postId, type, requestComment, termLink, trackingHashtag },
  axiosConfig = {},
) {
  return axios.post(
    `/brands/${brandId}/instagram/rights_request_media/${postId}`,
    { type, requestComment, termLink, trackingHashtag },
    axiosConfig,
  );
}

export async function getRelationship(
  { brandId, relationshipId, type, onlyVideoPosts, publishedStartDate, publishedEndDate },
  axiosConfig = {},
) {
  return axiosCamelCase.get(
    `/brands/${brandId}/instagram/relationships/${relationshipId}`,
    merge(
      {
        params: {
          type,
          onlyVideoPosts,
          publishedStartDate,
          publishedEndDate,
        },
      },
      axiosConfig,
    ),
  );
}

export async function createRelationship({ brandId, handle }, axiosConfig = {}) {
  return axiosCamelCase.post(`/brands/${brandId}/instagram/relationships`, { handle }, axiosConfig);
}

export async function updateRelationship(
  {
    brandId,
    relationshipId,
    email,
    invitationStatus,
    emailContent,
    influencerId,
    connectInstagramInfluencerToken,
    notes,
    hasPiq,
    newPath,
  },
  axiosConfig = {},
) {
  if (influencerId && connectInstagramInfluencerToken) {
    return axiosCamelCase.patch(
      `/brands/${brandId}/instagram/relationships/${relationshipId}`,
      { email, invitationStatus, emailContent, notes, hasPiq, newPath },
      merge(
        {
          params: {
            influencerId,
            connectInstagramInfluencerToken,
          },
        },
        axiosConfig,
      ),
    );
  }
  return axiosCamelCase.patch(
    `/brands/${brandId}/instagram/relationships/${relationshipId}`,
    { email, invitationStatus, emailContent, notes, hasPiq, newPath },
    axiosConfig,
  );
}

export async function updateRightsRequestMediaPost({ brandId, id, type }, axiosConfig = {}) {
  return axios.patch(
    `/brands/${brandId}/instagram/rights_request_media/${id}`,
    { type },
    axiosConfig,
  );
}

export async function deleteRightsRequestMediaPost({ brandId, id }, axiosConfig = {}) {
  return axios.delete(`/brands/${brandId}/instagram/rights_request_media/${id}`, axiosConfig);
}

export async function getRightsRequestDocuments({ brandId }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/instagram/rights_request_documents`, axiosConfig);
}

export async function createRightsRequestDocuments(
  { brandId, url, displayName },
  axiosConfig = {},
) {
  return axios.post(
    `/brands/${brandId}/instagram/rights_request_documents`,
    { url, displayName },
    axiosConfig,
  );
}

export async function updateRightsRequestDocuments(
  { brandId, id, url, displayName },
  axiosConfig = {},
) {
  return axios.patch(
    `/brands/${brandId}/instagram/rights_request_documents/${id}`,
    { url, displayName },
    axiosConfig,
  );
}

export async function deleteRightsRequestDocuments({ brandId, id }, axiosConfig = {}) {
  return axios.delete(`/brands/${brandId}/instagram/rights_request_documents/${id}`, axiosConfig);
}

export async function createRightsRequestDocumentsS3Url(
  { brandId, fileName, contentType },
  axiosConfig = {},
) {
  return axios.post(
    `/brands/${brandId}/instagram/rights_request_documents/s3_url`,
    { fileName, contentType },
    axiosConfig,
  );
}

export async function getRightsRequestHashtags({ brandId }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/instagram/rights_request_hashtags`, axiosConfig);
}

export async function updateRightsRequestHashtags(
  { brandId, id, trackingHashtag },
  axiosConfig = {},
) {
  return axios.patch(
    `/brands/${brandId}/instagram/rights_request_hashtags/${id}`,
    {},
    merge(
      {
        params: {
          trackingHashtag,
        },
      },
      axiosConfig,
    ),
  );
}

export async function deleteRelationship({ brandId, relationshipId }, axiosConfig = {}) {
  return axiosCamelCase.delete(
    `/brands/${brandId}/instagram/relationships/${relationshipId}`,
    axiosConfig,
  );
}

export async function getRelationshipPosts(
  {
    brandId,
    type,
    relationshipId,
    onlyVideoPosts,
    publishedStartDate,
    publishedEndDate,
    sort,
    sourceInstagramId,
    isStories,
    url,
  },
  axiosConfig = {},
) {
  return axiosCamelCase.get(
    url || `/brands/${brandId}/instagram/relationships/${relationshipId}/relationship_posts`,
    merge(
      {
        params: {
          type,
          onlyVideoPosts,
          publishedStartDate,
          publishedEndDate,
          sourceInstagramId,
          isStories,
          sort,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getRelationshipRIQStoryPosts(
  {
    brandId,
    relationshipId,
    isStories,
    publishedStartDate,
    publishedEndDate,
    sourceInstagramId,
    url,
    sort,
  },
  axiosConfig = {},
) {
  return axiosCamelCase.get(
    url || `/brands/${brandId}/instagram/mentioned_story_posts`,
    merge(
      {
        params: {
          type: 'RIQ',
          relationshipId,
          isStories,
          publishedStartDate,
          publishedEndDate,
          sourceInstagramId,
          sort,
        },
      },
      axiosConfig,
    ),
  );
}

export async function exportRelationshipRIQStoryPostsCSV(
  {
    brandId,
    relationshipId,
    type,
    socketId,
    sort,
    sourceInstagramId,
    publishedStartDate,
    publishedEndDate,
  },
  axiosConfig = {},
) {
  return axiosCamelCase.post(
    `/brands/${brandId}/instagram/mentioned_story_posts/csv`,
    {},
    merge(
      {
        params: {
          brandId,
          relationshipId,
          type,
          socketId,
          sort,
          sourceInstagramId,
          publishedStartDate,
          publishedEndDate,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getInstagramHashtags({ brandId }, axiosConfig = {}) {
  return axios.get(
    `/brands/${brandId}/instagram/hashtags`,
    merge({ camelizeKeys: true }, axiosConfig),
  );
}

export async function saveInstagramHashtag({ brandId, name }, axiosConfig = {}) {
  return axios.post(`/brands/${brandId}/instagram/hashtags`, { name }, axiosConfig);
}

export async function deleteInstagramHashtag({ brandId, hashtagId }, axiosConfig = {}) {
  return axios.delete(`/brands/${brandId}/instagram/hashtags/${hashtagId}`, axiosConfig);
}

export async function getInstagramRelatedHashtags(
  { brandId, hashtagId, startDate, endDate },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/instagram/hashtags/${hashtagId}/related_tags`,
    merge(
      {
        params: { startDate, endDate },
      },
      axiosConfig,
    ),
  );
}

export async function getUgcPosts(
  { brandId, ugcMediaType, startDate, endDate, offset, limit, startHour },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/instagram/ugc_posts`,
    merge(
      {
        params: {
          ugcMediaType,
          startDate,
          endDate,
          offset,
          limit,
          startHour,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getInstagramHashtagPosts(
  { brandId, hashtagId, startDate, endDate, subTagId, startHour, limit, url },
  axiosConfig = {},
) {
  return axios.get(
    url || `/brands/${brandId}/instagram/hashtags/${hashtagId}/posts`,
    merge(
      {
        params: {
          startDate,
          endDate,
          subTagId,
          startHour,
          limit,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getUgcPostsCounts(
  { brandId, ugcMediaType, startDate, endDate, startHour },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/instagram/ugc_posts/counts`,
    merge(
      {
        params: {
          ugcMediaType,
          startDate,
          endDate,
          startHour,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getUgcPostsCountsCsv(
  { brandId, startDate, endDate, ugcMediaType, socketId, type },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/instagram/ugc_posts/posts_csv`,
    merge(
      {
        params: {
          startDate,
          endDate,
          ugcMediaType,
          socketId,
          type,
        },
      },
      axiosConfig,
    ),
  );
}

export async function exportRelationshipPostsCSV(
  { brandId, relationshipId, type, socketId, sort, publishedStartDate, publishedEndDate },
  axiosConfig = {},
) {
  return axiosCamelCase.post(
    `/brands/${brandId}/instagram/relationships/${relationshipId}/relationship_posts/csv`,
    {},
    merge(
      {
        params: {
          type,
          socketId,
          sort,
          publishedStartDate,
          publishedEndDate,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getInstagramHashtagPostsCsv(
  { brandId, hashtagId, startDate, endDate, subTagId, type, socketId },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/instagram/hashtags/${hashtagId}/posts_csv`,
    merge({ params: { startDate, endDate, subTagId, type, socketId } }, axiosConfig),
  );
}

export async function getInstagramHashtagStats(
  { brandId, hashtagId, startDate, endDate, scale, subTagId },
  axiosConfig = {},
) {
  return axios.get(
    `/brands/${brandId}/instagram/hashtags/${hashtagId}/stats`,
    merge(
      {
        params: {
          startDate,
          endDate,
          scale,
          subTagId,
        },
      },
      axiosConfig,
    ),
  );
}

export async function addRelationshipTag(
  { brandId, relationshipId, tagId, tagName },
  axiosConfig = {},
) {
  return axiosCamelCase.post(
    `/brands/${brandId}/instagram/relationships/tags`,
    {},
    merge(
      {
        params: {
          relationshipId,
          tagId,
          tagName,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getBrandRelationshipTags({ brandId }, axiosConfig = {}) {
  return axiosCamelCase.get(`/brands/${brandId}/instagram/relationships/tags`, axiosConfig);
}

export async function deleteRelationshipTag({ brandId, relationshipId, tagId }, axiosConfig = {}) {
  return axiosCamelCase.delete(
    `/brands/${brandId}/instagram/relationships/tags/${tagId}`,
    merge(
      {
        params: {
          relationshipId,
        },
      },
      axiosConfig,
    ),
  );
}

export async function createOrUpdateInfluencer(
  { brandId, influencerInstagramId },
  axiosConfig = {},
) {
  return axiosCamelCase.post(
    `/brands/${brandId}/instagram/relationships/influencers`,
    {},
    merge(
      {
        params: {
          influencerInstagramId,
        },
      },
      axiosConfig,
    ),
  );
}

export async function getRecentlySearchedHashtags({ brandId }, axiosConfig = {}) {
  return axios.get(`/brands/${brandId}/instagram/hashtags/recently_searched_hashtags`, axiosConfig);
}

export async function addMentionUGC({ brandId, facebookMediaId }, axiosConfig = {}) {
  return axiosCamelCase.post(
    `/instagram/relationships/add_mention_ugc`,
    { brandId, facebookMediaId },
    axiosConfig,
  );
}

export async function getInstagramCustomUtmSettings({ brandId }, axiosConfig = {}) {
  return unformattedAxios.get(`brands/${brandId}/custom_url_params`, axiosConfig);
}

export async function saveInstagramCustomUtmSettings({ brandId, data }, axiosConfig = {}) {
  return unformattedAxios.patch(`brands/${brandId}/custom_url_params`, data, axiosConfig);
}

export function getInstagramAccountAllBrands({ brandIds }, axiosConfig = {}) {
  return axiosCamelCase.put(`/accounts`, { brand_ids: brandIds }, axiosConfig);
}

export async function getInteractionInsights(
  { brandId, scale, startDate, endDate, metrics },
  axiosConfig = {},
) {
  return axiosCamelCase.get(
    `/brands/${brandId}/interaction_insights`,
    merge(
      {
        params: {
          scale,
          startDate,
          endDate,
          metrics: metrics.join(','),
        },
      },
      axiosConfig,
    ),
  );
}

export async function postInteractionInsightsCSV(
  { brandId, scale, startDate, endDate, metrics, csvTitle, socketId },
  axiosConfig = {},
) {
  return axiosCamelCase.post(
    `/brands/${brandId}/interaction_insights/csv`,
    { scale, startDate, endDate, csvTitle, socketId, metrics: metrics.join(',') },
    axiosConfig,
  );
}

export default {
  posts: {
    update: updatePost,
  },
};
