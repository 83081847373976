import LinkifyIt from 'linkify-it';
import cloneDeep from 'lodash/cloneDeep';
import map from 'lodash/map';
import find from 'lodash/find';
import zipObject from 'lodash/zipObject';
import isString from 'lodash/isString';
import dayjs from 'dayjs';
import humps from 'humps';
import axios from 'axios';
import { enumTypes } from '@/app/pinterest/constants';
import { FILTERS_OPERATORS } from '@/config';
import { differenceBetweenDays } from '@/utils/dateUtils';
import { timeseriesHourlyTooltip, timeseriesYearSuffix } from '@/utils/formatters';
import { appendQueryParamsToUrl } from '@/utils/query';
import { guessTimezone } from '@/utils/timezone';

export function getCurrentDate(offset = 0) {
  return dayjs().subtract(offset, 'd').format('YYYY-MM-DD');
}

// fetch end date for engagement pin refresh
export function getNextDate(date, unit) {
  return dayjs(date).add(1, unit).format('YYYY-MM-DD');
}

export function generateMixpanelDateLabels(dateRange, dateOptions) {
  if (!dateRange) {
    return 'All Time';
  }
  if (dateRange[1] !== getCurrentDate()) {
    return 'Custom';
  }
  const selectedOption = dateOptions.find((option) => {
    return option.value === differenceBetweenDays(dateRange[0], dateRange[1]);
  });
  return selectedOption?.label || 'Custom';
}

export function generateMixpanelDates(range, isPrevious) {
  if (!range) {
    return {};
  }
  const isRange = range.length === 2;
  const keyPrefix = isPrevious ? 'Previous ' : '';

  return {
    [`${keyPrefix}Start Date`]: isRange ? range[0] : null,
    [`${keyPrefix}End Date`]: isRange ? range[1] : null,
  };
}

export function formatLineChartData(data, scale = 'DAILY') {
  let labels = Object.keys(data).map((date) => dayjs(date).format('MMM DD'));
  if (scale === 'HOURLY') {
    labels = Object.keys(data).map((date) => {
      if (dayjs(date).hour() === 0) {
        return dayjs(date).format('MMM D ha');
      }
      return dayjs(date).format('ha');
    });
  }
  const values = Object.values(data);
  const total = values.reduce((accumulator, currentValue) => accumulator + currentValue);
  const average = total / values.length;
  return { labels, values, total, average };
}

export function formatEngagementChartData(data, scale = 'DAILY', metric = {}) {
  const dateValues = Object.keys(data).map((date) => dayjs(date).format('YYYY-MM-DD'));
  let labels = Object.keys(data).map((date) => dayjs(date).format('MMM DD, YYYY'));
  if (scale === 'HOURLY') {
    labels = Object.keys(data).map((date) => {
      if (dayjs(date).hour() === 0) {
        return dayjs(date).format('MMM D ha');
      }
      return dayjs(date).format('ha');
    });
  }
  // get chart values for selected metric
  let values = [];
  if (metric === enumTypes.CLICKS) {
    values = Object.values(data).map((linkClicks) => linkClicks.pin_clicks_PINEVENT);
  } else if (metric === enumTypes.SAVES) {
    values = Object.values(data).map((saves) => saves.pin_repins_PINEVENT);
  } else if (metric === enumTypes.CLOSEUPS) {
    values = Object.values(data).map((closeups) => closeups.pin_close_ups_PINEVENT);
  } else if (metric === enumTypes.IMPRESSIONS) {
    values = Object.values(data).map((impressions) => impressions.pin_impressions_PINEVENT);
  } else if (metric === enumTypes.COMMENTS) {
    values = Object.values(data).map((comments) => comments.pin_comments_PINEVENT);
  } else if (metric === enumTypes.ENGAGEMENT) {
    values = Object.values(data).map((item) => {
      // avoid dividing by 0
      if (item.pin_impressions_PINEVENT === 0) {
        return 0;
      }
      return (
        ((item.pin_close_ups_PINEVENT +
          item.pin_clicks_PINEVENT +
          item.pin_aggregated_saves_PINEVENT +
          item.pin_comments_PINEVENT) /
          item.pin_impressions_PINEVENT) *
        100
      );
    });
  } else if (metric === enumTypes.TOTAL_VIDEO_VIEWS || metric === enumTypes.VIDEO_VIEWS) {
    values = Object.values(data).map((comments) => comments.pin_video_views_PINEVENT);
  } else {
    throw new Error(`The chart metric '${metric}' has not been mapped.`);
  }
  const total = values.reduce((accumulator, currentValue) => accumulator + currentValue);
  let average = total / values.length;
  // format engagement percentages
  if (metric === enumTypes.ENGAGEMENT) {
    average = average.toFixed(2);
  }
  return { labels, dateValues, values, total, average };
}

export function formatYourEngagementChartData(payload, scale, metric) {
  const chartData = payload?.data;

  if (!chartData) {
    return null;
  }

  let statData;

  if (scale === enumTypes.DAILY) {
    statData = chartData.daily_metrics;
  } else {
    statData = chartData.monthly_metrics;
  }

  const dateValues = statData?.map((d) => d.date);
  const labels = dateValues?.map((date) => {
    let title = dayjs(date).format('MMM DD');
    title = timeseriesYearSuffix(date, title);
    return title;
  });
  const values = statData?.map((item) => {
    let stat = item.metrics[`${metric.toLowerCase()}`];
    if (stat && item.data_status === 'READY' && metric === enumTypes.ENGAGEMENT_RATE) {
      stat = (parseFloat(stat) * 100).toFixed(2);
    }
    return stat;
  });
  const total = chartData?.summary_metrics?.[`${metric.toLowerCase()}`];

  // calculate average engagement rate
  let average = null;
  if (metric === enumTypes.ENGAGEMENT_RATE) {
    // avoid dividing by 0
    if (chartData?.summary_metrics?.impressions > 0) {
      average = (
        (parseFloat(chartData.summary_metrics.engagements) /
          parseFloat(chartData.summary_metrics.impressions)) *
        100
      ).toFixed(2);
    }
  }
  return { labels, dateValues, values, total, average };
}

export function formatFollowerChartData(data, scale = 'DAILY') {
  let labels;
  if (scale === enumTypes.HOURLY) {
    labels = Object.keys(data).map((date) => {
      return timeseriesHourlyTooltip(date);
    });
  } else {
    labels = Object.keys(data).map((date) => {
      let title = dayjs(date).format('MMM DD');
      title = timeseriesYearSuffix(date, title);
      return title;
    });
  }
  const values = Object.values(data).map((newFollowers) => newFollowers.followers_ALL);
  const valuesTotal = Object.values(data).map(
    (totalFollowers) => totalFollowers.followers_ALL_AGGR,
  );
  const endOfRangeTotalFollowers = valuesTotal[valuesTotal.length - 1];
  const totalNew = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const total = valuesTotal.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const average = Math.floor(totalNew / values.length);
  return { labels, values, valuesTotal, totalNew, total, average, endOfRangeTotalFollowers };
}

export function formatFollowerChartDataV5(data, scale) {
  const labels = Object.keys(data.NET_NEW_FOLLOWERS).map((date) => {
    const format = scale === enumTypes.MONTHLY ? 'MMM' : 'MMM DD';
    return timeseriesYearSuffix(date, dayjs(date).format(format));
  });
  const values = Object.values(data.NET_NEW_FOLLOWERS);
  const valuesTotal = Object.values(data.TOTAL_FOLLOWERS);
  const endOfRangeTotalFollowers = valuesTotal[valuesTotal.length - 1];
  const totalNew = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const total = valuesTotal.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const average = Math.floor(totalNew / values.length);
  return { labels, values, valuesTotal, totalNew, total, average, endOfRangeTotalFollowers };
}

export function formatDateForPin(dateTime) {
  let time;

  if (dayjs(dateTime).isSame(new Date(), 'day')) {
    time = dayjs(dateTime).format('[Today] h:mm A');
  } else if (dayjs(dateTime).isSame(dayjs().subtract(1, 'days'), 'day')) {
    time = dayjs(dateTime).format('[Yesterday] h:mm A');
  }
  return time || dayjs(dateTime).format('lll');
}

export function formatMediaListData(data) {
  // Format because data from library is already camelized, but data from other backends may not be
  const camelizedData = humps.camelizeKeys(data);
  return (
    camelizedData?.map((media) => ({
      // Pinterest returns id and others returns mediaId, maybe unify them in the backend
      // at some point.
      ...media,
      id: media.id ? media.id : media.mediaId,
      url: media.urls ? media.urls.ratio : null,
      width: media.width ? media.width : media.originalWidth,
      height: media.height ? media.height : media.originalHeight,
      sourceType: media.brandType,
      sourceId: media.sourceId,
      imageUrl: media.urls ? media.urls.ratio : null,
      postDate: dayjs(media.createdAt).format('MMMM D h:m a'),
      predictions: media.predictions,
      insights: media?.insights,
    })) ?? []
  );
}

export function formatGalleryMediaListData(data) {
  // sometimes it is an array, some time it is a dict with { data: [], next: Obj }
  const dataList = data.constructor === Array ? data : data.data;
  return dataList.map((item) => {
    const { media, brand_media_type: brandMediaType } = item;
    return {
      id: media.id,
      url: media.urls ? media.urls.ratio : null,
      urls: media.urls,
      width: media.originalWidth,
      height: media.originalHeight,
      source: media.source,
      sourceType: brandMediaType,
      sourceData: media.sourceData,
      sourceId: media.sourceId,
      mediaType: media.type,
      imageUrl: media.urls ? media.urls.ratio : null,
      postDate: dayjs(media.createdAt).format('MMMM D h:m a'),
      predictions: media.predictions,
      products: item.products,
      clicks: item.clicks,
      canPublishWithin: item.canPublishWithin,
    };
  });
}

export function dragSelectableItemGenerator(contextEl, activeMedia, targetItemClassList, selector) {
  return () => {
    const elements = targetItemClassList
      ? document.getElementsByClassName(targetItemClassList, contextEl)
      : document.querySelectorAll(selector);
    return map(elements, (el) => {
      const dataId = el.dataset.id;
      const item = find(activeMedia, (x) => dataId === String(x.id));
      return { el, item };
    });
  };
}

export function truncateUrl(url, maxLen) {
  let formattedUrl = url;
  if (formattedUrl.substring(0, 8) === 'https://') {
    formattedUrl = formattedUrl.substring(8);
  } else if (formattedUrl.substring(0, 7) === 'http://') {
    formattedUrl = formattedUrl.substring(7);
  }
  if (formattedUrl.substring(0, 4) === 'www.') {
    formattedUrl = formattedUrl.substring(4);
  }
  if (formattedUrl.length > maxLen) {
    return `${formattedUrl.substring(0, maxLen - 3)}...`;
  }
  return formattedUrl;
}

export function canvasToBlob(canvas) {
  return new Promise((resolve) => {
    canvas.toBlob((blob) => resolve(blob), 'image/jpeg', 1);
  });
}

export function recursiveObjectPromiseAll(obj) {
  const keys = Object.keys(obj);
  return Promise.all(
    keys.map((key) => {
      const value = obj[key];
      if (typeof value === 'object' && !value.then) {
        return recursiveObjectPromiseAll(value);
      }
      return value;
    }),
  ).then((result) => zipObject(keys, result));
}

export function downloadFileFromMemory(data, filename) {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function downloadFileFromUrl(url, filename = '') {
  window.location.assign(appendQueryParamsToUrl(url, { download: true, filename }));
}

// Attempt to determine file type from hosted file if ext not provided in url
export async function determineFileTypeFromUrl(url) {
  const fileSignatureMap = {
    '89 50 4e 47': 'png',
    'ff d8 ff': 'jpeg',
    '00 00 00 18 66 74 79 70': 'mp4',
    '00 00 00 20 66 74 79 70 71 74': 'mov',
    '52 49 46 46': 'avi',
  };
  const fileRes = await axios.get(url, { responseType: 'arraybuffer' });
  if (fileRes.status === 200 && fileRes.data) {
    const buffer = new Uint8Array(fileRes.data);
    const hexSignature = Array.from(buffer)
      .slice(0, 12) // Get first 12 bytes
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join(' ');
    const sig = find(Object.keys(fileSignatureMap), (signature) =>
      hexSignature.startsWith(signature),
    );
    return fileSignatureMap[sig];
  }
  return false;
}

export function isElementOverflow(element) {
  return element?.scrollWidth > element?.clientWidth;
}

export function openIGProfilePage(handle) {
  window.open(`https://www.instagram.com/${handle}`, '_blank');
}

export const isRangeOperator = (operator) => {
  return [FILTERS_OPERATORS.BETWEEN, FILTERS_OPERATORS.NOT_BETWEEN].includes(operator);
};

export function convertUtcDateTimeToLocalDateTimeByZone(dateTime, timezone) {
  const tz = timezone || guessTimezone();
  return dayjs.utc(dateTime).tz(tz);
}

export function convertDateToUtcDateTimeByZone(date, timezone) {
  const userTimezone = timezone || guessTimezone();
  const localTimestamp = dayjs(date).tz(userTimezone, true);
  return localTimestamp.utc();
}

export function convertDateToUtcDateTimeByZoneString(date, timezone) {
  return convertDateToUtcDateTimeByZone(date, timezone).format('YYYY-MM-DDTHH:mm:ss[Z]');
}

export function convertDateToUtcDateTimeByZoneISOString(date, timezone) {
  return convertDateToUtcDateTimeByZone(date, timezone).toISOString();
}

export function dropNullObjectFields(obj) {
  const localObj = cloneDeep(obj);
  Object.keys(localObj).forEach((key) => localObj[key] == null && delete localObj[key]);
  return localObj;
}

export function extractUrlsFromText(text) {
  const linkify = LinkifyIt();
  return (linkify.match(text) ?? [])
    .map((match) => match.raw)
    .filter((url) => url.match(linkify.re.email_fuzzy) === null)
    .filter((url) => !url.startsWith('file:///'))
    .filter((url) => !url.startsWith('ftp://'));
}

export function convertToUnit(value, unit = 'px') {
  if (value == null || value === '') {
    return undefined;
  }
  if (isString(value)) {
    return String(value);
  }
  return `${Number(value)}${unit}`;
}

export const mapById = (objects) => Object.fromEntries(objects.map((obj) => [obj.id, obj]));

export const DEFAULT = Symbol('DEFAULT');

export const exhaustiveMap = (_enum, _map) => {
  const enumValues = Object.values(_enum);
  const invalidOptions = Object.keys(_map).filter(
    (option) => option !== DEFAULT && !enumValues.includes(option),
  );
  const missingOptions = enumValues.filter((enumValue) => !(enumValue in _map));
  if (invalidOptions.length > 0) {
    throw new Error(`Map contains the following invalid options: ${invalidOptions.join(', ')}`);
  }
  if (!(DEFAULT in _map) && missingOptions.length > 0) {
    throw new Error(
      `The following options have not been defined in map: ${missingOptions.join(', ')}`,
    );
  }
  const newMap = {
    ..._map,
    ...Object.fromEntries(
      missingOptions.map((missingOption) => [missingOption, _map[DEFAULT](missingOption)]),
    ),
  };
  delete newMap[DEFAULT];
  return newMap;
};

// Finds the nested option that has the given value, if it exists
export function findNestedOption(value, options) {
  return options?.reduce?.((result, option) => {
    if (result) return result;
    if (option.value === value) return option;
    if (option.children != null) return findNestedOption(value, option.children);
    return undefined;
  }, undefined);
}

// Finds the index of the first option that has the given value or has a decendent with that value
export function findIndexNestedOption(value, options) {
  return options?.findIndex?.((option) => {
    if (option.children) {
      return findIndexNestedOption(value, option.children) >= 0;
    }
    return option.value === value;
  });
}
