<script setup>
import Avatar from '@/components/foundation/Avatar.vue';
import Button from '@/components/foundation/Button.vue';
import { ref } from 'vue';
import { downloadImage } from '@/components/VisionAi/utils/tile-download';
import { IMAGE_DOWNLOAD_FILE_FORMATS } from '@/app/dashboards/constants';
import { useNotificationStore } from '@/stores/notification';

const props = defineProps({
  userHandle: {
    type: String,
    default: '',
  },
  profilePictureUrl: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['downloadButtonClicked']);

const notificationStore = useNotificationStore();

const dmWidgetContainer = ref(null);

const download = () => {
  if (dmWidgetContainer.value) {
    emit('downloadButtonClicked');
    try {
      downloadImage(dmWidgetContainer.value, IMAGE_DOWNLOAD_FILE_FORMATS.PNG.extension);
    } catch (err) {
      notificationStore.setToast({
        message: 'Failed to download widget image',
        type: 'error',
      });
    }
  }
};
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div ref="dmWidgetContainer" class="dm-widget-container w-full gap-2">
    <Button
      v-tooltip="'Download as .png'"
      class="image-download absolute right-2 top-3 z-dropdown"
      icon-name="download"
      @click="download"
    />
    <div class="avatar-container">
      <div class="avatar-outer">
        <Avatar :img-src="props.profilePictureUrl" medium />
      </div>
    </div>
    <div class="user-handle">
      {{ props.userHandle }}
    </div>
  </div>
</template>

<style scoped lang="postcss">
.dm-widget-container {
  display: flex;
  background-color: var(--background-0);
  max-width: 100%;
  border-radius: var(--round-corner);
  padding: var(--space-12);
  transition: var(--transition-all);
  margin-top: var(--space-8);
  box-shadow: var(--shadow-1);
  position: relative;
}

.avatar-outer {
  padding: var(--space-5);
  border-radius: 100%;
  box-shadow: var(--shadow-1);
}

.user-handle {
  font-size: var(--x16);
  align-content: center;
}
</style>
