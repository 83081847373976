/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'threads': {
    width: 422,
    height: 481,
    viewBox: '0 0 422 481',
    data: '<g clip-path="url(#clip0_2_2512)"><path pid="0" d="M214.416 480.545h-.144c-71.616-.48-126.672-24.096-163.68-70.176C17.712 369.329.72 312.257.144 240.737v-.336C.72 168.833 17.712 111.809 50.64 70.769 87.6 24.641 142.704 1.025 214.272.545h.288c54.912.384 100.848 14.496 136.512 41.952 33.552 25.824 57.168 62.592 70.176 109.344l-40.8 11.376c-22.08-79.2-77.952-119.664-166.08-120.288-58.176.432-102.192 18.72-130.8 54.336-26.736 33.36-40.56 81.552-41.088 143.28.528 61.728 14.352 109.92 41.136 143.28 28.608 35.664 72.624 53.952 130.8 54.336 52.464-.384 87.168-12.624 116.016-40.896 32.928-32.256 32.352-71.856 21.792-95.952-6.192-14.208-17.472-26.016-32.688-34.992-3.84 27.024-12.432 48.912-25.68 65.424-17.712 22.032-42.816 34.08-74.592 35.808-24.048 1.296-47.232-4.368-65.184-16.032-21.264-13.776-33.696-34.8-35.04-59.28-1.296-23.808 8.16-45.696 26.592-61.632 17.616-15.216 42.384-24.144 71.664-25.824 21.552-1.2 41.76-.24 60.384 2.832-2.496-14.832-7.488-26.64-14.976-35.136-10.272-11.712-26.16-17.664-47.184-17.808h-.576c-16.896 0-39.84 4.656-54.432 26.4l-35.136-23.568c19.584-29.088 51.36-45.12 89.568-45.12h.864c63.888.384 101.952 39.504 105.744 107.76 2.16.912 4.32 1.872 6.432 2.832 29.808 14.016 51.6 35.232 63.072 61.392 15.936 36.432 17.424 95.808-30.96 143.184-37.008 36.192-81.888 52.56-145.536 52.992h-.144zm20.064-233.808c-4.848 0-9.744.144-14.784.432-36.72 2.064-59.616 18.912-58.32 42.864 1.344 25.104 29.04 36.768 55.68 35.328 24.48-1.296 56.352-10.848 61.728-74.208-13.536-2.928-28.368-4.416-44.304-4.416z" _fill="#000"/></g><defs><clipPath id="clip0_2_2512"><path pid="1" _fill="#fff" transform="translate(0 .545)" d="M0 0h421.44v480H0z"/></clipPath></defs>'
  }
})
