<script setup>
import { computed } from 'vue';
import svgicon from 'vue-svgicon';

const props = defineProps({
  predictions: { type: Object, default: null },
  suggestedThumbnails: { type: Array, default: null },
  height: { type: Number, default: 56 },
  width: { type: Number, default: 356 },
});

const LINE_WIDTH = 3;

const viewBox = computed(() => `0 0 ${props.width} ${props.height}`);

function autosmoothHandle(a, b, c, alpha = 0.25) {
  const vNext = { x: c.x - b.x, y: c.y - b.y };
  const vPrev = { x: a.x - b.x, y: a.y - b.y };

  const lNext = Math.sqrt(vNext.x ** 2 + vNext.y ** 2);
  const lPrev = Math.sqrt(vPrev.x ** 2 + vPrev.y ** 2);
  const vector = { x: vNext.x * (lPrev / lNext) - vPrev.x, y: vNext.y * (lPrev / lNext) - vPrev.y };
  const norm = Math.sqrt(vector.x ** 2 + vector.y ** 2);
  const normalized = { x: vector.x / norm, y: vector.y / norm };
  return [
    { x: -normalized.x * alpha * lPrev, y: -normalized.y * alpha * lPrev },
    { x: normalized.x * alpha * lNext, y: normalized.y * alpha * lNext },
  ];
}

const xIncrement = computed(() => props.width / (props.predictions.engagement.length - 1));

const scaledPredictionPoints = computed(() =>
  props.suggestedThumbnails.map(({ index, framePosition, predictions }) => ({
    framePosition,
    x: index * xIncrement.value,
    y: props.height - props.height * predictions.engagement,
  })),
);

const predictionPoints = computed(() => {
  // Calculate x,y coordinates for each prediction
  const points = props.predictions.engagement.map((prediction, index) => ({
    x: xIncrement.value * index,
    y: props.height - props.height * prediction,
  }));

  const segments = points
    .slice(1)
    .map((point, index) => ({ c1: { ...points[index] }, c2: { ...point }, end: { ...point } }));
  for (let i = 1; i < segments.length; i += 1) {
    const [u, v] = autosmoothHandle(points[i - 1], points[i], points[i + 1]);

    // Assign handle vectors to associated segments
    segments[i - 1].c2.x += u.x;
    segments[i - 1].c2.y += u.y;
    segments[i].c1.x += v.x;
    segments[i].c1.y += v.y;
  }
  return `M ${points[0].x},${points[0].y} ${segments
    .map(({ c1, c2, end }) => `C ${c1.x},${c1.y} ${c2.x},${c2.y} ${end.x},${end.y}`)
    .join(' ')}`;
});

const visionTooltip = `<div style="display: flex; align-items: center;"><svg version="1.1" viewBox="0 0 20 20" class="svg-icon svg-fill svg-up icon" style="width: 16px; height: 16px;" fill="white">${svgicon.icons['magic-wand-1-filled'].data}</svg> <strong style="margin-left: var(--space-6);">Vision Suggested</strong></div>`;

defineExpose({
  autosmoothHandle,
  predictionPoints,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <svg
    v-if="predictions"
    class="prediction-line-graph"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="viewBox"
    fill="none"
  >
    <path stroke="white" :stroke-width="LINE_WIDTH" :d="predictionPoints" stroke-linecap="square" />
    <circle
      v-for="suggestion in scaledPredictionPoints"
      :key="suggestion.framePosition"
      v-tooltip="{ html: true, content: visionTooltip }"
      fill="white"
      :cx="suggestion.x"
      :cy="suggestion.y"
      r="5"
    />
  </svg>
</template>

<style scoped lang="postcss">
.prediction-line-graph {
  position: absolute;
  inset: 0;
  width: inherit;
  height: inherit;
  border-radius: var(--round-corner-small);
  background-color: rgb(0 0 0 / 50%);
  z-index: 2;
}

svg *:focus {
  outline: none;
}
</style>
