<template>
  <div class="filter-group flex gap-4">
    <SourcesFilter
      v-if="!sourceValue.hide"
      display-label="Sources"
      :value="sourceValue"
      class="dropdown-container"
      :limit-source="limitSource"
      @filters-selected="handleSourceFilter"
      @dropdown-toggled="handleSourceDropdownToggled"
    />
    <div v-if="showDatePublished" class="sort-select">
      <Select
        dropdown-label="Date Published"
        :date-range-value="dateRange"
        :options="dateOptions"
        :active="dateValue.startDate !== null"
        placeholder="All Time"
        type="calendar"
        filter-group
        class="date-filter"
        @selected="dateChanged"
      />
    </div>
    <MoreFiltersDropdown
      :value="filterValue"
      :media-type-limit="mediaTypeLimit"
      class="dropdown-container"
      @filters-selected="handleMoreFilters"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import MoreFiltersDropdown from '@/components/MediaFilters/MoreFiltersDropdown.vue';
import Select from '@/components/Select.vue';
import SourcesFilter from '@/components/MediaFilters/SourcesFilter.vue';
import { enumProp } from '@/utils/props';
import { mediaTypes } from '@/app/library/constants';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'FilterGroup',
  components: {
    MoreFiltersDropdown,
    Select,
    SourcesFilter,
  },
  props: {
    showDatePublished: { type: Boolean, default: true },
    sourceValue: { type: Object, default: null },
    dateValue: { type: Object, default: null },
    filterValue: { type: Object, default: null },
    mediaTypeLimit: enumProp(mediaTypes, null),
    limitSource: { type: String, default: null },
  },
  emits: [
    'sourceFilterSelected',
    'sourceDropdownToggled',
    'moreFilterSelected',
    'dateFilterSelected',
  ],
  data() {
    return {
      dateOptions: [
        { label: 'All Time', value: null },
        { label: 'Last 24 Hours', value: 1 },
        { label: 'Last 3 Days', value: 3 },
        { label: 'Last 7 Days', value: 7 },
        { label: 'Last 4 Weeks', value: 28 },
      ],
      dateRange: null,
    };
  },
  computed: {
    ...mapStores(useTrackingStore),
  },
  watch: {
    dateValue(to) {
      if (to.startDate && to.endDate) {
        this.dateRange = [to.startDate, to.endDate];
      }
    },
  },
  methods: {
    handleSourceFilter(option) {
      this.$emit('sourceFilterSelected', option);
      this.trackingStore.track('Search Filter Changed', {
        filter: 'Media Source',
        filterValue: option,
      });
    },
    handleSourceDropdownToggled(isOpen) {
      this.$emit('sourceDropdownToggled', isOpen);
    },
    handleMoreFilters(option) {
      this.$emit('moreFilterSelected', option);
    },
    dateChanged(option) {
      this.$emit('dateFilterSelected', option);
      if (option) {
        this.trackingStore.track('Search Filter Changed', { filter: 'Date', filterValue: option });
      } else {
        this.trackingStore.track('Search Filter Changed', {
          filter: 'Date',
          filterValue: 'All time',
        });
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.filter-group {
  display: flex;

  .sort-select {
    margin-right: 10px;

    label {
      padding-left: var(--space-12);
      margin-bottom: 0;
      color: var(--text-secondary);
      font-size: var(--x12);
      transform: translateY(10px);
    }
  }

  .filter-dropdown {
    position: relative;

    button {
      height: 2.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--background-0);
      font-size: var(--x14);
      color: var(--text-primary);
      border-radius: var(--button-border-radius);
      border: 1px solid var(--border);
      margin-right: var(--space-8);
      padding: 0 var(--space-16);
      text-transform: capitalize;
      cursor: pointer;
      transition: var(--transition-all);

      &:hover {
        color: var(--action-500);
      }
    }

    &.open {
      button {
        color: var(--action-500);
        border-color: var(--action-500);
      }
    }
  }
}
</style>
