import { getEnumValue } from '@/models/enum.utils';

export const GRAPH_SCALES = Object.freeze({
  DAILY: {
    value: 'DAILY',
    label: 'Day',
    optionIndex: 0,
    chartJSTimeUnit: 'day',
    apiDateFormat: 'YYYY-MM-DD',
  },
  MONTHLY: {
    value: 'MONTHLY',
    label: 'Month',
    optionIndex: 1,
    chartJSTimeUnit: 'month',
    apiDateFormat: 'YYYY-MM',
  },
});

export const getGraphScaleProp = (scale, property) => {
  const scaleKey = getEnumValue(scale);
  const scaleConfig = GRAPH_SCALES[scaleKey];
  return scaleConfig?.[property];
};

export function isValidGraphScale(graphScale) {
  return graphScale && graphScale in GRAPH_SCALES;
}
