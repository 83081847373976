<script setup>
import { computed, ref } from 'vue';
import { Button } from '@dashhudson/dashing-ui';
import { useChiliPiperStore } from '@/stores/chili-piper';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import { useFlagStore } from '@/stores/flag';
import { useIdentityStore } from '@/stores/identity';
import { useIntercomStore } from '@/stores/intercom';
import dayjs from 'dayjs';
import { useStorage, useTimeoutFn } from '@vueuse/core';
import { browserStorageNamespacedKey } from '@/utils/browserStorage';
import { useTrialStates } from '@/composables/inAppTrials/useTrialStates';
import { TRIAL_BOOK_TRAINING_URL } from '@/components/InAppTrials/constants';
import { CHILI_PIPER_FORMS_MAP } from '@/app/settings/constants';

const chiliPiperStore = useChiliPiperStore();
const customerJourneyStore = useCustomerJourneyStore();
const flagStore = useFlagStore();
const intercomStore = useIntercomStore();
const identityStore = useIdentityStore();

const dismissed = useStorage(
  browserStorageNamespacedKey(`${identityStore.currentBrand?.label}-dismissedTrialBanner`),
  false,
  sessionStorage,
);

const appear = ref(false);

const { activeTrialsInfo, expansionTrialDaysLeft } = useTrialStates();

useTimeoutFn(() => {
  appear.value = true;
}, 3000);

const active = ref(false);

function trialActive(trial) {
  const endDate = dayjs(trial?.trialEndDate);
  const today = dayjs();
  return endDate >= today;
}

const hasActiveTrial = computed(() => {
  return (
    (customerJourneyStore.additionalPlanTrial?.trialEndDate &&
      trialActive(customerJourneyStore.additionalPlanTrial)) ||
    (customerJourneyStore.additionalPremiumAnalyticsTrial?.trialEndDate &&
      trialActive(customerJourneyStore.additionalPremiumAnalyticsTrial)) ||
    (customerJourneyStore.socialListeningTrial?.trialEndDate &&
      trialActive(customerJourneyStore.socialListeningTrial))
  );
});

const hasAccessToInAppTrialBannerFlag = computed(() => {
  return flagStore.ready && flagStore.flags.inAppTrialBanner;
});

const show = computed(() => {
  return (
    appear.value &&
    hasAccessToInAppTrialBannerFlag.value &&
    hasActiveTrial.value &&
    !dismissed.value
  );
});
</script>

<template>
  <transition
    enter-to-class="dash-opacity-100"
    leave-to-class="dash-opacity-0"
    enter-from-class="dash-opacity-0 translate-y-1/4"
    leave-from-class="dash-opacity-100"
    enter-active-class="dash-transition-sm"
    leave-active-class="dash-transition-sm"
  >
    <div
      v-if="show"
      class="dash-bg-background-inform-muted dash-whitespace-nowrap dash-text-primary dash-typo-base dash-rounded-md dash-transition-md dash-elevation-md fixed left-1/2 top-4 z-[var(--z-index-nav)] flex h-10 -translate-x-1/2 items-center gap-8 px-4 py-2"
      @click="active = !active"
    >
      <div
        v-for="trialInfo in activeTrialsInfo"
        :key="trialInfo.product"
        v-sanitize-html="expansionTrialDaysLeft(trialInfo)"
      />
      <div class="dash-flex items-center gap-2">
        <Button text severity="primary" @click="intercomStore.startChecklist">Take a Tour</Button>
        <span class="dash-text-subtle">|</span>
        <Button
          text
          severity="primary"
          @click="
            chiliPiperStore.openChiliPiperTab(
              TRIAL_BOOK_TRAINING_URL,
              CHILI_PIPER_FORMS_MAP.button.bookATraining.router,
              CHILI_PIPER_FORMS_MAP.button.bookATraining.name,
              'Book A Training',
              'profile dropdown',
            )
          "
          >Book a Training</Button
        >
        <Button
          class="translate-x-1/4"
          size="xs"
          icon="x-close"
          severity="utility"
          aria-label="Add Button"
          @click="dismissed = true"
        />
      </div>
    </div>
  </transition>
</template>
