<script setup>
import startCase from 'lodash/startCase';
import { ref, computed, inject } from 'vue';
import Chip from '@/components/foundation/Chip.vue';
import Icon from '@/components/foundation/Icon.vue';
import {
  SENTIMENT_LABEL_BY_TYPE,
  COMMUNITY_SENTIMENT_UPDATE_TOASTS,
  INTERACTION_UPDATE_SENTIMENT_TEXT,
} from '@/app/community/constants';
import { colours } from '@/ux/colours';
import { useNotificationStore } from '@/stores/notification';
import { useCommunityInteractionStore } from '@/stores/community-interactions';
import { updateSentiment } from '@/app/community/utils/mixpanel-events';

// stores
const communityInteractionStore = useCommunityInteractionStore();
const notificationStore = useNotificationStore();

const props = defineProps({
  interaction: { type: Object, default: null },
  stat: { type: Object, default: null },
  light: { type: Boolean, default: true },
});

const multiSelectMode = inject('multiSelectMode', () => false);

const open = ref(false);
const dropdownOptions = ref(SENTIMENT_LABEL_BY_TYPE);
const selected = ref();

const iconColor = computed(() => {
  return props.stat?.iconColor ?? colours.ICON.ICON_SECONDARY;
});

const interactionTypeLabel = computed(() => {
  const { type, isAdComment } = props.interaction;
  const label = startCase(type.toLowerCase());
  return isAdComment ? 'Ad comment' : label;
});

const iconDir = computed(() => {
  return open.value ? 'down' : 'up';
});

function toggleDropdown(value) {
  open.value = value ?? !open.value;
}

function isSelected(sentiment) {
  return (selected.value ?? props.stat?.title) === sentiment;
}

async function handleUpdate(sentiment) {
  const previousSentiment = props.stat.title;
  const resp = await communityInteractionStore.updateCommunityInteraction(
    props.interaction.id,
    { sentiment_override: sentiment.toUpperCase() },
    true,
    props.interaction,
  );
  let toastMessage;
  if (resp.data?.data) {
    const interactionType = props.interaction.type;
    const title = INTERACTION_UPDATE_SENTIMENT_TEXT[interactionType] || 'Message';
    // mixpanel event listener
    updateSentiment(title, previousSentiment, sentiment);
    selected.value = sentiment;
    toastMessage = `${title} ${COMMUNITY_SENTIMENT_UPDATE_TOASTS.SUCCESS}`;
  } else {
    toastMessage = `${COMMUNITY_SENTIMENT_UPDATE_TOASTS.ERROR}`;
  }
  notificationStore.setToast({
    message: toastMessage,
    type: toastMessage === COMMUNITY_SENTIMENT_UPDATE_TOASTS.ERROR ? 'error' : 'success',
  });
  toggleDropdown();
}

defineExpose({
  open,
  selected,
  iconDir,
  interactionTypeLabel,
  updateSentiment,
  toggleDropdown,
  isSelected,
  handleUpdate,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <VDropdown
    placement="bottom-start"
    :distance="5"
    :shown="open"
    :triggers="[]"
    theme="dh-dropdown-full"
    :container="false"
    class="flex"
    @auto-hide="toggleDropdown(false)"
  >
    <Chip
      prepend-icon="dot"
      append-icon="chevronDown"
      :icon-props="{ dir: iconDir }"
      :prepend-icon-color="iconColor"
      :gap="false"
      :light="light"
      xxsmall
      class="sentiment-chip"
      :disabled="multiSelectMode()"
      @click="toggleDropdown()"
    >
      <span class="ml-1 mr-1.5">{{ stat.title }}</span>
    </Chip>
    <template #popper>
      <div class="popover-container w-[var(--width-216)] py-2">
        <div
          v-for="option in dropdownOptions"
          :key="option.title"
          class="dropdown-option flex cursor-pointer items-center px-6 py-2"
          @click="handleUpdate(option.title)"
        >
          <Icon name="dot" :color="option.iconColor" xsmall />
          <span class="ml-2 mr-3">{{ option.title }}</span>
          <Icon
            v-if="isSelected(option.title)"
            name="check"
            :color="colours.ACTION.ACTION_500"
            xsmall
          />
        </div>
      </div>
    </template>
  </VDropdown>
</template>

<style lang="postcss" scoped>
.sentiment-chip {
  &:hover {
    span {
      color: var(--text-link);
    }
  }
}

.dropdown-option {
  span {
    font-size: var(--x14);
    font-weight: var(--font-medium);
    color: var(--text-primary);
    line-height: var(--x18);
  }

  &:hover {
    background: var(--background-300);
  }
}
</style>
