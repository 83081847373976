<template>
  <component
    :is="viewerComponent"
    :class="{ dropdownSelect, alignTop, alignBottom }"
    :title="title"
    @on-back="backClicked"
  >
    <template #content>
      <section>
        <template v-if="subscreen === null">
          <p class="dropdown-title">
            {{ title }}
          </p>
          <PostSettingItem
            class="add-top-margin"
            empty="Boards"
            left-icon="galleries"
            @click="boardsClicked"
          />
          <PostSettingItem
            v-if="hasCampaignsAccess"
            empty="Campaigns"
            left-icon="campaigns"
            @click="campaignsClicked"
          />
          <PostSettingItem
            v-if="hasLibraryAccess"
            empty="Galleries"
            left-icon="galleries"
            @click="galleriesClicked"
          />
        </template>
        <template v-else>
          <div v-if="dropdownSelect" class="dropdown-nav" @click="() => (subscreen = null)">
            <Icon
              :hover-color="colours.ACTION.ACTION_500"
              :color="colours.ICON.ICON_PRIMARY"
              name="caret"
              dir="down"
              xsmall
            />
            <span>All</span>
          </div>
          <div class="add-to-content">
            <MultiSelectList
              v-if="subscreen === 'galleries'"
              v-model="mutableSelectedGalleries"
              :items="galleryStore.summaryGalleries"
              :loading="galleryStore.pending.summaryGalleries"
              gallery-type="LIBRARY"
              class="select-list"
              search-placeholder="Search for galleries"
              full-width
            />
            <MultiSelectList
              v-else-if="subscreen === 'boards'"
              v-model="mutableSelectedGalleries"
              :gallery-type="type"
              :items="galleryStore.summaryGalleries"
              :loading="galleryStore.pending.summaryGalleries"
              class="select-list"
              search-placeholder="Search for boards"
              full-width
            />
            <MultiSelectList
              v-else
              v-model="mutableSelectedGalleries"
              :items="formattedCampaigns"
              :loading="campaignsStore.pending.summaryCampaigns"
              gallery-type="CAMPAIGN"
              class="select-list"
              search-placeholder="Search campaign name"
              full-width
            />
            <Button v-if="showFooter" class="done" wide primary @click="save">Done</Button>
          </div>
        </template>
      </section>
    </template>
  </component>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import capitalize from 'lodash/capitalize';
import { useAuthStore } from '@/stores/auth';
import { useCampaignsStore } from '@/stores/campaigns';
import Icon from '@/components/foundation/Icon.vue';
import Button from '@/components/foundation/Button.vue';
import MultiSelectList from '@/components/MultiSelectListRefactored.vue';
import { galleryTypes } from '@/config';
import { colours } from '@/ux/colours';
import { BRAND } from '@/models/auth/permissions.enum';
import { useGalleryStore } from '@/stores/gallery';
import PostSettingItem from '../PostSettingItem.vue';
import SubScreen from './Layout/SubScreen.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'AddToGallery',
  components: {
    Icon,
    Button,
    MultiSelectList,
    PostSettingItem,
    SubScreen,
  },
  props: {
    type: { type: String, required: true },
    selectedGalleries: { type: Array, required: true },
    onGalleriesSelected: { type: Function, required: true },
    onBack: { type: Function, required: true },
    dropdownSelect: { type: Boolean, default: false },
    alignTop: { type: Boolean, default: false },
    alignBottom: { type: Boolean, default: false },
    showFooter: { type: Boolean, default: false },
  },
  data() {
    return {
      galleryTypes,
      subscreen: null,
      mutableSelectedGalleries: this.selectedGalleries,
    };
  },
  computed: {
    ...mapStores(useAuthStore, useCampaignsStore, useGalleryStore),
    colours() {
      return colours;
    },
    hasCampaignsAccess() {
      return (
        this.authStore.brand_can('campaigns', 'can_access_campaigns') &&
        this.authStore.user_can('campaigns', 'can_access_campaigns')
      );
    },
    hasLibraryAccess() {
      return this.authStore.guard(BRAND.LIBRARY.CAN_ACCESS_LIBRARY);
    },
    viewerComponent() {
      return this.dropdownSelect ? 'div' : 'SubScreen';
    },
    title() {
      if (['boards', 'campaigns', 'galleries'].includes(this.subscreen)) {
        return capitalize(this.subscreen);
      }
      return 'After published, add to:';
    },
    formattedCampaigns() {
      return this.campaignsStore.summaryCampaigns.map((campaign) => {
        return {
          campaign: true,
          id: campaign.id,
          name: campaign.name,
        };
      });
    },
  },
  watch: {
    'campaignsStore.pending.createCampaign': function addNewCampaignWatcher(to) {
      if (!to) {
        this.campaignsStore.clearSummaryCampaigns();
        this.campaignsStore.getSummaryCampaigns();
      }
    },
    'galleryStore.pending.createGalleryStatus': function addNewGalleryWatcher(to) {
      if (!to) {
        let { type } = this;
        if (this.subscreen === 'galleries') {
          type = galleryTypes.LIBRARY;
        }
        this.galleryStore.clearSummaryGalleries();
        this.galleryStore.getSummaryGalleries({
          type,
        });
      }
    },
    selectedGalleries(newVal) {
      this.mutableSelectedGalleries = newVal;
    },
  },

  methods: {
    backClicked() {
      if (this.subscreen) {
        this.subscreen = null;
        this.onGalleriesSelected(this.mutableSelectedGalleries);
      } else {
        this.onGalleriesSelected(this.mutableSelectedGalleries);
        this.onBack();
      }
    },
    galleriesClicked() {
      this.galleryStore.clearSummaryGalleries();
      this.subscreen = 'galleries';
      this.galleryStore.getSummaryGalleries({
        type: galleryTypes.LIBRARY,
      });
    },
    boardsClicked() {
      this.galleryStore.clearSummaryGalleries();
      this.subscreen = 'boards';
      this.galleryStore.getSummaryGalleries({
        type: this.type,
      });
    },
    campaignsClicked() {
      this.galleryStore.clearSummaryGalleries();
      this.campaignsStore.clearSummaryCampaigns();
      this.subscreen = 'campaigns';
      this.campaignsStore.getSummaryCampaigns();
    },
    save() {
      this.onGalleriesSelected(this.mutableSelectedGalleries);
      this.onBack();
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.add-top-margin {
  margin-top: var(--space-32);
}

.dropdown-title {
  display: none;
}

.add-to-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select-list :deep(ul) {
  height: 30rem;
}

.done {
  margin-top: var(--space-16);
}

.dropdownSelect {
  width: 17.5rem;
  transition: all 0.3s;
  background-color: var(--background-0);
  padding: var(--space-24) var(--space-16);
  position: absolute;
  border-radius: var(--round-corner-small);
  margin-top: var(--space-8);
  margin-bottom: var(--space-16);
  z-index: var(--z-index-dropdown);
  box-shadow: var(--shadow-4);

  .dropdown-title {
    display: block !important;
    font-size: var(--x16);
    font-weight: var(--font-medium);
    margin-bottom: var(--space-24);
  }

  .dropdown-nav {
    cursor: pointer;
    font-weight: var(--font-medium);
    display: flex;
    align-items: center;

    span {
      margin-left: var(--space-4);
      margin-bottom: 1px;
      font-size: var(--x14);
    }

    &:hover {
      color: var(--action-500);

      svg {
        fill: var(--action-500) !important;
      }
    }
  }

  .add-top-margin {
    margin-top: var(--space-16);
  }

  .done {
    margin-top: 0;
    margin-bottom: var(--space-8);
  }
}

.alignTop.dropdownSelect {
  bottom: var(--space-24);
}

.alignBottom.dropdownSelect {
  top: var(--space-24);
}
</style>
