<template>
  <div v-if="options.length && value">
    <label
      v-for="option in options"
      :key="option.value"
      :class="['radio-label', { disabled: option.disabled }]"
      :data-cy="option.value"
    >
      <span v-tooltip="getTooltip(option)">
        <input
          :value="option.value"
          :checked="value === option.value"
          :disabled="option.disabled"
          :name="name"
          type="radio"
          @change="inputChanged"
        />
        <span class="radio" />
        <Icon
          v-if="option.iconName"
          :color="option.iconColor"
          :name="option.iconName"
          class="icons"
        />
        <span :class="getClass(option)">{{ getOptionLabel(option) }}</span>
      </span>
      <InfoTooltip v-if="option.tooltip" :tooltip="option.tooltip" xsmall />
    </label>
  </div>
  <div v-else class="flex h-20 items-center">{{ noDataText }}</div>
</template>

<script>
import { defineComponent } from 'vue';
/**
 * Radio button group component. Usage:
 *
 * <RadioInputGroup
 *   v-model="radioSelection"
 *   :items="[{ value: 1, label: 'Foo' }, { value: 2, label: 'Bar', disabled: true }]"
 * />
 */
import Icon from '@/components/foundation/Icon.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'RadioInputGroup',
  components: {
    Icon,
    InfoTooltip,
  },
  props: {
    value: { type: String, default: null },
    options: { type: Array, required: true },
    name: { type: String, required: true },
    noDataText: { type: String, default: null },
  },
  emits: ['input'],
  methods: {
    inputChanged(e) {
      if (e.target.checked) {
        this.$emit('input', e.target.value);
      }
    },
    getOptionLabel(option) {
      return option.labelForFilter ?? option.label;
    },
    getTooltip(option) {
      const tooltip = {
        theme: 'dh-tooltip-small',
      };
      if (option.disabled) {
        tooltip.content = option.disabledTooltip;
        return tooltip;
      }
      if (option.truncateClass) {
        tooltip.content = option.label;
        return tooltip;
      }
      return null;
    },
    getClass(option) {
      if (option.truncateClass) {
        return `block truncate ${option.truncateClass}`;
      }
      if (option.fontClass) {
        return `${option.fontClass}`;
      }
      return null;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
/* Custom radio buttons */
label.radio-label {
  flex: 1;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: var(--space-32);
  user-select: none;
}

/* Hide the default radio button */
label.radio-label input {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.radio-label {
  span {
    display: flex;
    margin-bottom: 0;
    margin-top: 0.12rem;
  }
}

/* Custom radio button */
span.radio {
  position: absolute;
  top: 0;
  left: 0;
  height: var(--space-20);
  width: var(--space-20);
  background: var(--background-0);
  border-radius: 50%;
  border: 2px solid #ddd;
  margin-top: 4px;
}

label.radio-label input:checked {
  ~ span.radio {
    background: var(--action-500);
    border: 2px solid var(--action-500);
  }

  &:disabled ~ span.radio {
    background: var(--background-500);
    border: 2px solid var(--background-500);
  }
}

span.radio::after {
  content: '';
  position: absolute;
  display: none;
}

/* White circle inside the radio */
label.radio-label span.radio::after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--background-0);
}

label.radio-label input:checked ~ span.radio::after {
  display: block;
}

label.radio-label p {
  display: inline;
  font-size: var(--x14);
  margin-left: var(--space-8);
}

label.radio-label.disabled {
  cursor: auto;
}

.icons {
  padding-right: 8px;
  height: var(--space-24);
  width: var(--space-24);
}
</style>
