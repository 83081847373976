<template>
  <BaseDialogPopup
    data-cy="ConfirmDialog"
    :title="title"
    :message="message"
    :buttons="buttons"
    :hide-close-button="pending || uncancellable"
    :larger-text="largerText"
    @close="onClose"
  />
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import BaseDialogPopup from '@/components/core/dialogs/BaseDialogPopup.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ConfirmDialog',
  components: {
    BaseDialogPopup,
  },
  props: {
    /**
     * Cancel button text
     */
    cancelAlias: { type: String, default: 'Cancel' },
    /**
     * Confirm button text
     */
    confirmAlias: { type: String, default: 'Continue' },
    /**
     * Confirm button type
     */
    confirmType: { type: String, default: 'danger' },
    /**
     * Message to be displayed.  Adds details to the title of the dialog.
     */
    message: { type: String, default: null },
    /**
     * Disables the confirm button.
     */
    disableConfirm: { type: Boolean, default: false },
    /**
     * Title of the confirm dialog
     */
    title: { type: String, default: null },
    /**
     * Prevent the confirm dialog from being cancelled.
     */
    uncancellable: { type: Boolean, default: false },
    /**
     * Puts the dialog in a pending state, disabling the buttons.
     */
    pending: { type: Boolean, default: false },
    /**
     * If true, increases font size of the dialog message to 16
     */
    largerText: { type: Boolean, default: false },
    /**
     * Current platform popup
     */
    platform: { type: String, default: null },
  },
  emits: ['cancel', 'confirm'],
  computed: {
    ...mapStores(useTrackingStore),
    buttons() {
      const buttons = [];
      if (!this.uncancellable) {
        buttons.push({
          text: this.cancelAlias,
          onClick: this.onClose,
          disabled: this.pending,
          'data-cy': 'CancelButton',
        });
      }
      buttons.push({
        text: this.confirmAlias,
        [this.confirmType]: true,
        onClick: this.onConfirm,
        disabled: this.disableConfirm || this.pending,
        loading: this.pending,
        'data-cy': 'ConfirmButton',
      });
      return buttons;
    },
  },
  methods: {
    onClose() {
      if (!this.pending && !this.uncancellable) {
        this.$emit('cancel');
        this.trackingStore.track('Discard Post Popup', {
          action: 'cancel',
          platform: this.platform,
        });
      }
    },
    onConfirm() {
      if (!this.pending) {
        this.$emit('confirm');
        this.trackingStore.track('Discard Post Popup', {
          action: 'discard',
          platform: this.platform,
        });
      }
    },
  },
});
export default comp;
</script>
