<template>
  <ul ref="fbDropdown" class="account-dropdown">
    <li
      v-for="(account, index) in accountList"
      :key="account.id"
      :class="{ highlight: index === highlightIndex }"
      @click="selectAccount(account)"
    >
      <img :src="account.avatar" />
      <p>{{ account.name }}</p>
    </li>
  </ul>
</template>

<script>
import { defineComponent } from 'vue';
import { UP_ARROW, DOWN_ARROW, ENTER, ESCAPE } from '@/utils/keycodes';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'FacebookAccountPopup',
  props: {
    accountList: { type: Array, default: null },
  },
  emits: ['onClose', 'onSelect'],
  data() {
    return {
      highlightIndex: 0,
    };
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      const key = e.which || e.keyCode;
      if (key === UP_ARROW && this.highlightIndex > 0) {
        this.highlightIndex -= 1;
      } else if (key === DOWN_ARROW && this.highlightIndex < this.accountList.length - 1) {
        this.highlightIndex += 1;
      } else if (key === ESCAPE) {
        this.$emit('onClose');
      } else if (key === ENTER) {
        this.$emit('onSelect', this.accountList[this.highlightIndex]);
      }
    });
  },
  methods: {
    selectAccount(account) {
      this.$emit('onSelect', account);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
ul.account-dropdown {
  margin-top: var(--space-4);
  padding: var(--space-8) 0;
  background: var(--background-0);
  width: max-content;
  border-radius: var(--round-corner-small);
  box-shadow: var(--light-shadow);
  position: absolute;
  z-index: var(--z-index-dropdown);

  li {
    display: flex;
    align-items: center;
    height: var(--space-48);
    cursor: pointer;

    img {
      width: var(--space-32);
      height: var(--space-32);
      border-radius: 50%;
      margin: 0 var(--space-8) 0 var(--space-24);
    }

    p {
      text-transform: capitalize;
      margin-right: var(--space-24);
    }

    &:hover {
      background: var(--background-300);
    }
  }

  .highlight {
    background: var(--background-300);
    color: var(--action-500);
  }
}
</style>
