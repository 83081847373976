<template>
  <div class="embedded-tweet-container" :class="{ 'text-only-preview': textOnlyPreview }">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="embedHTML" :class="{ 'hidden-tweet': !isRenderingFinished }" v-html="embedHTML" />
    <CircularLoader v-if="!isRenderingFinished" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import CircularLoader from '@/components/CircularLoader.vue';
import { initializeTwitterWidget, getEmbeddableTweet, renderTweets } from '@/utils/twitter';
import { MEDIA_TYPE_OPTIONS } from '@/app/library/constants';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'TwitterTextPreview',
  components: {
    CircularLoader,
  },
  props: {
    postType: { type: String, required: true },
    postUrl: { type: String, required: true },
  },
  data() {
    return {
      embedHTML: null,
      isRenderingFinished: false,
      isRenderingTweet: false,
      isWidgetLoaded: false,
    };
  },
  computed: {
    textOnlyPreview() {
      const textOnlySourceTypes = [MEDIA_TYPE_OPTIONS.TEXT];
      return textOnlySourceTypes.includes(this.postType);
    },
  },
  created() {
    initializeTwitterWidget(this.twitterWidgetLoaded, this.tweetRenderingFinished);
    this.getTweetPreview();
  },
  methods: {
    async getTweetPreview() {
      const postUrl = this.postUrl;
      this.embedHTML = await getEmbeddableTweet(
        `https://publish.twitter.com/oembed?url=${postUrl}`,
      );
      this.renderEmbeddedTweet();
    },
    renderEmbeddedTweet() {
      if (!this.isRenderingTweet && this.embedHTML != null && this.isWidgetLoaded) {
        this.isRenderingTweet = true;
        if (window.twttr && window.twttr.widgets) {
          renderTweets(document.querySelector('div.embedded-tweet-container'));
        } else {
          this.tweetRenderingFinished();
        }
      }
    },
    tweetRenderingFinished() {
      this.isRenderingTweet = false;
      this.isRenderingFinished = true;
    },
    twitterWidgetLoaded() {
      this.isWidgetLoaded = true;
      this.renderEmbeddedTweet();
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.hidden-tweet {
  opacity: 0;
}

.embedded-tweet-container .loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.embedded-tweet-container {
  position: relative;
  left: 0;
}
</style>
