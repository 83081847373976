import { routeDetails } from '@/app/creators/constants';

const App = () => import('@/app/creators/App.vue');
const Overview = () => import('@/app/creators/pages/Overview.vue');
const YourCreators = () => import('@/app/creators/pages/YourCreators.vue');
const TikTokDiscovery = () => import('@/app/creators/pages/TikTokDiscovery.vue');
const InstagramDiscovery = () => import('@/app/creators/pages/InstagramDiscovery.vue');

export default {
  path: routeDetails.CREATORS.name,
  component: App,
  children: [
    {
      name: routeDetails.CREATORS.name,
      path: routeDetails.CREATORS.path,
      redirect: { name: routeDetails.OVERVIEW.name },
    },
    {
      name: routeDetails.OVERVIEW.name,
      path: routeDetails.OVERVIEW.path,
      component: Overview,
      meta: {
        analytics: `${routeDetails.CREATORS.title} - ${routeDetails.OVERVIEW.title}`,
      },
    },
    {
      name: routeDetails.YOUR_CREATORS.name,
      path: routeDetails.YOUR_CREATORS.path,
      component: YourCreators,
      meta: {
        analytics: `${routeDetails.CREATORS.title} - ${routeDetails.YOUR_CREATORS.title}`,
      },
    },
    {
      name: routeDetails.CREATOR_DISCOVERY.name,
      path: routeDetails.CREATOR_DISCOVERY.path,
      redirect: { name: routeDetails.TIKTOK_DISCOVERY.name },
    },
    {
      name: routeDetails.TIKTOK_DISCOVERY.name,
      path: routeDetails.TIKTOK_DISCOVERY.path,
      component: TikTokDiscovery,
      meta: {
        analytics: `${routeDetails.CREATORS.title} - ${routeDetails.TIKTOK_DISCOVERY.title}`,
      },
    },
    {
      name: routeDetails.INSTAGRAM_DISCOVERY.name,
      path: routeDetails.INSTAGRAM_DISCOVERY.path,
      component: InstagramDiscovery,
      meta: {
        analytics: `${routeDetails.CREATORS.title} - ${routeDetails.INSTAGRAM_DISCOVERY.title}`,
      },
    },
  ],
  meta: {
    requiresAuth: true,
    internal: true,
  },
};
