import { defineStore } from 'pinia';
import { watch, ref, computed } from 'vue';
import isFunction from 'lodash/isFunction';
import { useAuthStore } from '@/stores/auth';
import * as LibraryAPI from '@/apis/library';
import { useTrackingStore } from '@/stores/tracking';
import { CONTENT_TAG_EDIT_CONTEXTS, CONTENT_TAGS_EVENT_NAMES } from '@/app/settings/constants';
import { ORGANIZATION_USER } from '@/models/auth/permissions.enum';

export const useContentTagsStore = defineStore('contentTags', () => {
  const authStore = useAuthStore();
  const trackingStore = useTrackingStore();

  const organizationContentTags = ref([]);
  const allOrganizationsContentTags = ref([]);
  const pending = ref({
    allOrganizationsContentTags: false,
    currentOrganizationContentTags: false,
  });
  const contentTagFormShow = ref(false);
  const contentTagFormTarget = ref(null);
  const error = ref(null);

  const canManageContentTags = computed(() =>
    authStore.guard([ORGANIZATION_USER.TAGS.CAN_MANAGE_CONTENT_TAGS]),
  );

  async function getCurrentOrganizationContentTags() {
    const currBrandOrganizationId = authStore.currentBrand?.organizationId;
    error.value = null;
    pending.value.currentOrganizationContentTags = true;

    try {
      const response = await LibraryAPI.getContentTags({ organizationId: currBrandOrganizationId });
      const contentTags = response?.data;
      organizationContentTags.value = contentTags.sort((a, b) => a.name.localeCompare(b.name));
    } finally {
      pending.value.currentOrganizationContentTags = false;
    }
  }

  async function getAllOrganizationsContentTags() {
    const currBrandOrganizationId = authStore.currentBrand?.organizationId;
    error.value = null;
    pending.value.allOrganizationsContentTags = true;
    const allOrgIds = Array.from(
      new Set(Object.values(authStore.identity.brands).map((brand) => brand.organizationId)),
    );
    const allOrgsContentTags = [];
    try {
      await Promise.all(
        allOrgIds.map(async (orgId) => {
          const response = await LibraryAPI.getContentTags({ organizationId: orgId });
          const contentTags = response?.data;
          contentTags.forEach((contentTag) => {
            allOrgsContentTags.push(contentTag);
          });
          if (orgId === currBrandOrganizationId) {
            organizationContentTags.value = contentTags.sort((a, b) =>
              a.name.localeCompare(b.name),
            );
          }
        }),
      );
      allOrganizationsContentTags.value = allOrgsContentTags.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
    } catch (e) {
      error.value =
        'There was a delay in loading your content tags. Please refresh the page and try again.';
    } finally {
      pending.value.allOrganizationsContentTags = false;
    }
  }

  async function createContentTag({ contentTag }) {
    const organizationId = authStore.currentBrand?.organizationId;

    const name = contentTag?.name;
    const brandIds = contentTag?.brandIds;

    const response = await LibraryAPI.createContentTag({ organizationId, name, brandIds });
    const payload = response?.data;

    getAllOrganizationsContentTags();

    trackingStore.track(CONTENT_TAGS_EVENT_NAMES.CONTENT_TAG_CREATED, {
      tagName: name,
      tagID: payload.id,
      brandsIDsAttachedToTag: brandIds,
      brandsNamesAttachedToTag: authStore.getBrandNames(brandIds),
    });

    return payload;
  }

  async function updateContentTag({ contentTagId, contentTag }) {
    const organizationId = authStore.currentBrand?.organizationId;

    const name = contentTag?.name;
    const brandIds = contentTag?.brandIds;

    const response = await LibraryAPI.updateContentTag({
      organizationId,
      contentTagId,
      name,
      brandIds,
    });
    const payload = response?.data;

    const fieldsChanged = [];
    if (name !== contentTagFormTarget.value?.name) {
      fieldsChanged.push(CONTENT_TAG_EDIT_CONTEXTS.NAME);
    }
    if (brandIds !== contentTagFormTarget.value?.brandIds) {
      fieldsChanged.push(CONTENT_TAG_EDIT_CONTEXTS.BRANDS);
    }

    trackingStore.track(CONTENT_TAGS_EVENT_NAMES.CONTENT_TAG_EDITED, {
      tagName: name,
      tagID: contentTagId,
      editContext: fieldsChanged,
      brandsIDsAttachedToTag: brandIds,
      brandsNamesAttachedToTag: authStore.getBrandNames(brandIds),
    });

    getAllOrganizationsContentTags();

    return payload;
  }

  async function saveContentTag({ contentTagId, contentTag }) {
    if (contentTagId) {
      return updateContentTag({ contentTagId, contentTag });
    }
    return createContentTag({ contentTag });
  }

  async function deleteContentTag({ contentTag }) {
    const organizationId = authStore.currentBrand?.organizationId;

    const contentTagId = contentTag?.id;

    const response = await LibraryAPI.deleteContentTag({ organizationId, contentTagId });
    const payload = response?.data;

    trackingStore.track(CONTENT_TAGS_EVENT_NAMES.CONTENT_TAG_DELETED, {
      tagName: contentTag?.name,
      tagID: contentTag?.id,
      brandsIDsAttachedToTag: contentTag?.brandIds,
      brandsNamesAttachedToTag: authStore.getBrandNames(contentTag?.brandIds),
    });

    getAllOrganizationsContentTags();

    return payload;
  }

  async function removeBrandFromContentTag({ contentTag, brand }) {
    const organizationId = authStore.currentBrand?.organizationId;

    const contentTagId = contentTag?.id;
    const brandIdToRemove = brand?.id;

    const response = await LibraryAPI.deleteContentTagBrand({
      organizationId,
      contentTagId,
      brandId: brandIdToRemove,
    });
    const payload = response?.data;

    const brandIds = contentTag?.brandIds?.filter((brandId) => brandId !== brandIdToRemove);
    trackingStore.track(CONTENT_TAGS_EVENT_NAMES.CONTENT_TAG_EDITED, {
      tagName: contentTag?.name,
      tagID: contentTag?.id,
      editContext: [CONTENT_TAG_EDIT_CONTEXTS.BRANDS],
      brandsIDsAttachedToTag: brandIds,
      brandsNamesAttachedToTag: authStore.getBrandNames(brandIds),
    });

    getAllOrganizationsContentTags();

    return payload;
  }

  async function associateContentTagsToBrandMedia({
    contentTagIds,
    brandMediaIds,
    createMissingAssociations = false,
  }) {
    const organizationId = authStore.currentBrand?.organizationId;

    const response = await LibraryAPI.associateContentTagsToBrandMedia({
      organizationId,
      contentTagIds,
      brandMediaIds,
      createMissingAssociations,
    });
    const payload = response?.data;

    return payload;
  }

  async function removeContentTagFromBrandMedia({ contentTagId, brandMediaId }) {
    const organizationId = authStore.currentBrand?.organizationId;

    const response = await LibraryAPI.removeContentTagFromBrandMedia({
      organizationId,
      contentTagId,
      brandMediaId,
    });
    const payload = response?.data;

    return payload;
  }

  function filterContentTags(search, sortFunction) {
    const stringToMatch = search?.toLowerCase() ?? '';
    const foundsBrandIds = authStore.filterBrands(stringToMatch)?.map((brand) => brand?.id);
    const filteredList = organizationContentTags.value?.filter((contentTag) => {
      const hasSearch = search?.length > 0;
      const matchesName = contentTag?.name?.toLowerCase()?.includes(stringToMatch);
      const matchesBrand = contentTag?.brandIds?.some((brandId) =>
        foundsBrandIds.includes(brandId),
      );
      return !hasSearch || matchesName || matchesBrand;
    });
    return isFunction(sortFunction) ? sortFunction(filteredList) : filteredList;
  }

  function filterContentTagsByMissingBrand(brandIds, contentTagIds) {
    const brandIdsToCheck = [].concat(brandIds ?? []);
    if (contentTagIds?.length > 0) {
      return allOrganizationsContentTags.value
        ?.filter((contentTag) =>
          contentTagIds.some((contentTagId) => contentTagId === contentTag.id),
        )
        ?.filter(
          (contentTag) =>
            !contentTag?.brandIds?.some((contentTagBrandId) =>
              brandIdsToCheck.includes(contentTagBrandId),
            ),
        );
    }
    return [];
  }

  function inaccessibleContentTagBrands(contentTagId) {
    const contentTag = organizationContentTags.value?.find((tag) => tag.id === contentTagId);

    if (!contentTag) return [];

    const identityBrandIds = new Set(Object.keys(authStore.identityBrandsById).map(Number));
    const inaccessibleBrandIds = contentTag.brandIds.filter(
      (brandId) => !identityBrandIds.has(brandId),
    );

    return inaccessibleBrandIds;
  }

  function showContentTagForm({ contentTag = null } = {}) {
    contentTagFormTarget.value = contentTag;
    contentTagFormShow.value = true;
  }

  function hideContentTagForm() {
    contentTagFormShow.value = false;
    contentTagFormTarget.value = null;
  }
  const brandsWithAccessToContentTags = computed(() => {
    if (!authStore.identity?.brands) return {};
    return Object.fromEntries(
      Object.entries(authStore.identity.brands).filter(([, brandValue]) => {
        return brandValue.permissions?.tags?.can_access_content_tags;
      }),
    );
  });

  watch(
    () => authStore.currentBrand,
    (currentBrand) => {
      if (currentBrand) {
        getAllOrganizationsContentTags();
      }
    },
    {
      immediate: true,
    },
  );

  return {
    pending,
    organizationContentTags,
    allOrganizationsContentTags,
    contentTagFormShow,
    contentTagFormTarget,
    error,
    canManageContentTags,
    getAllOrganizationsContentTags,
    getCurrentOrganizationContentTags,
    saveContentTag,
    deleteContentTag,
    removeBrandFromContentTag,
    associateContentTagsToBrandMedia,
    removeContentTagFromBrandMedia,
    filterContentTags,
    showContentTagForm,
    hideContentTagForm,
    filterContentTagsByMissingBrand,
    inaccessibleContentTagBrands,
    brandsWithAccessToContentTags,
  };
});
