<script setup>
import { vOnClickOutside } from '@vueuse/components';
import Button from '@/components/foundation/Button.vue';

defineProps({
  showPopover: { type: Boolean, default: false },
  onSave: { type: Function, default: () => {} },
  onClear: { type: Function, default: () => {} },
  title: { type: String, default: () => {} },
  saveDisabled: { type: Boolean, default: false },
  clearDisabled: { type: Boolean, default: false },
  handleOutsideClick: { type: Function, default: () => {} },
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template class="popover-wrapper">
  <transition name="slide">
    <div
      v-if="showPopover"
      v-on-click-outside="handleOutsideClick"
      class="popover-wrapper-container"
    >
      <div class="popover-slot-section">
        <p class="title">{{ title }}</p>
        <slot />
      </div>
      <div class="actions-section">
        <Button
          data-cy="PopoverClearButton"
          class="action-button"
          :disabled="clearDisabled"
          no-border
          no-hover
          @click="onClear"
        >
          Clear
        </Button>
        <Button
          data-cy="PopoverSaveButton"
          class="save-button action-button"
          :disabled="saveDisabled"
          no-border
          no-hover
          @click="onSave"
        >
          Save
        </Button>
      </div>
    </div>
  </transition>
</template>

<style lang="postcss" scoped>
.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.popover-wrapper-container {
  z-index: var(--z-index-dropdown);
  background: var(--background-0);
  border-radius: var(--round-corner-small);
  box-shadow: var(--shadow-4);
  width: 18.75rem;
  height: fit-content;
  position: absolute;
  overflow-x: hidden;
  margin-left: var(--space-48);

  .popover-slot-section {
    padding: var(--space-24);

    .title {
      line-height: var(--space-20);
      font-size: var(--x18);
      color: var(--text-primary);
      padding-bottom: var(--space-16);
    }
  }

  .actions-section {
    background-color: var(--background-300);
    font-size: var(--x14);
    display: flex;
    justify-content: space-between;

    .action-button {
      height: 100%;
      padding: var(--space-16) var(--space-24);
    }

    .save-button {
      color: var(--action-500);
    }
  }
}
</style>
