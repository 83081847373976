<template>
  <div class="code-snippet">
    <slot name="title" />
    <textarea ref="scriptText" :value="code" readonly @click="copyScript" />
    <slot name="footnote" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useNotificationStore } from '@/stores/notification';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'CodeSnippet',
  props: {
    code: { type: String, default: null },
  },
  emits: ['copied'],
  computed: {
    ...mapStores(useNotificationStore),
  },
  methods: {
    copyScript() {
      this.$refs.scriptText.select();
      document.execCommand('copy');
      this.notificationStore.setToast({ message: 'Copied to clipboard.' });

      this.$emit('copied');
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.code-snippet {
  z-index: 1;
  margin-top: var(--space-32);
  text-align: left;

  h5 {
    margin-bottom: var(--space-16);
    text-transform: none;
  }

  p {
    font-size: var(--x14);
    margin-bottom: var(--space-8);
  }

  textarea {
    background: var(--background-300);
    border-radius: var(--round-corner-small);
    padding: var(--space-16);
    cursor: pointer;
    margin-bottom: var(--space-16);
    outline: 0;
    width: 100%;
    resize: none;
    scroll-padding: 0;
    display: block;
    height: 80px;
    overflow-y: scroll;
  }
}
</style>
