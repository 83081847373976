import { defineStore } from 'pinia';
import * as LibraryAPI from '@/apis/library';

export const useCsvStore = defineStore('csv', {
  state: () => ({
    communityFacebookCommentsCSVPending: null,
    communityInstagramCommentsCSVPending: null,
    communityTikTokCommentsCSVPending: null,
    instagramStoryCSVPending: null,
    instagramStoryMetricsCSVPending: null,
    instagramStoryFramesCSVPending: null,
    instagramAccountOverviewCSVPending: null,
    instagramBoardCSVPending: null,
    instagramGalleriesCSVPending: null,
    instagramUgcPostCSVPending: null,
    instagramHashtagPostCSVPending: null,
    instagramStoryGalleriesCSVPending: null,
    instagramLikeshopImagesCSVPending: null,
    instagramLikeshopLinksCSVPending: null,
    instagramRightsRequestMediaCSVPending: null,
    instagramFollowersDemosgraphicsCSVPending: null,
    instagramDemographicsInsightsCSVPending: null,
    instagramInteractionInsightsCSVPending: null,
    instagramOnlineFollowersInsightsCSVPending: null,
    instagramUGCAccountsCSVPending: null,
    instagramUGCStoryPostsCSVPending: null,
    instagramFollowersInsightsCSVPending: null,
    instagramFollowersLostInsightsCSVPending: null,
    instagramPostsCSVPending: null,
    instagramStoryBoardMediaCSVPending: null,
    instagramStoryNarrativeMediaCSVPending: null,
    galleryStatsCSVPending: null,
    emailSubmissionCsvCSVPending: null,
    galleryUgcAsAltImageCSVPending: null,
    relationshipsOverviewCSVPending: null,
    relationshipsAllPostsDetailsCSVPending: null,
    relationshipPostsCSVPending: null,
    tikTokLikeShopLinksCSVPending: null,
    tikTokLikeShopPostsCSVPending: null,
    tikTokYourPostsCSVPending: null,
    twitterYourPostsCSVPending: null,
    facebookYourPostsCSVPending: null,
    twitterGalleriesCSVPending: null,
    facebookBoardCSVPending: null,
    tiktokBoardCSVPending: null,
    tiktokBoardV2CSVPending: null,
    facebookGalleriesCSVPending: null,
    tiktokGalleriesCSVPending: null,
    twitterBoardCSVPending: null,
    pinterestBoardCSVPending: null,
    pinterestGalleriesCSVPending: null,
    youtubeGalleriesCSVPending: null,
    youTubeYourVideosCSVPending: null,
    youtubeBoardCSVPending: null,
    instagramUGCCSVPending: null,
    linkedinYourPostsCSVPending: null,
  }),
  actions: {
    requestCSV({ type }) {
      this[`${type}CSVPending`] = true;
    },
    csvReceived({ type }) {
      this[`${type}CSVPending`] = false;
    },
    csvRequestFailed({ type }) {
      this[`${type}CSVPending`] = false;
    },
    async getGalleriesCSV({ brandId, ...params }) {
      const response = await LibraryAPI.getGalleriesCSV({ brandId, ...params });
      const payload = response?.data;
      return payload;
    },
    async getStoryMetricsCSV({ brandId, ...params }) {
      const response = await LibraryAPI.getStoryMetricsCSV({ brandId, ...params });
      const payload = response?.data;
      return payload;
    },
    async getBoardsMediaCSV({ brandId, ...params }) {
      const response = await LibraryAPI.getBoardsMediaCSV({ brandId, ...params });
      const payload = response?.data;
      return payload;
    },
    async getBoardMediaCSV({ brandId, galleryId, ...params }) {
      const response = await LibraryAPI.getBoardMediaCSV({ brandId, galleryId, ...params });
      const payload = response?.data;
      return payload;
    },
    async getGalleryMediaProductsCSV({ brandId, galleryId, ...params }) {
      const response = await LibraryAPI.getGalleryMediaProductsCSV({
        brandId,
        galleryId,
        ...params,
      });
      const payload = response?.data;
      return payload;
    },
    async getGalleryStatsCSV({ brandId, galleryId, ...params }) {
      const response = await LibraryAPI.getGalleryStatsCSV({ brandId, galleryId, ...params });
      const payload = response?.data;
      return payload;
    },
    async getGalleryUgcAsAltImageCSV({ brandId, galleryId, ...params }) {
      const response = await LibraryAPI.getGalleryUgcAsAltImageCSV({
        brandId,
        galleryId,
        ...params,
      });
      const payload = response?.data;
      return payload;
    },
    async getLikeShopEmailSubmissionsCSV({ brandId, ...params }) {
      const response = await LibraryAPI.getLikeShopEmailSubmissionsCSV({ brandId, ...params });
      const payload = response?.data;
      return payload;
    },
  },
});
