<template>
  <Popup :close="clickedClosePopup">
    <main>
      <h4>Search for a section</h4>
      <div
        ref="container"
        :style="{ width: containerW + 'px', height: containerH + 'px' }"
        class="crop-box"
      >
        <img ref="cropImage" :src="img" />
      </div>
      <div class="btn-group">
        <Button primary :disabled="!cropperReady" @click="setCroppedImage">Search</Button>
      </div>
    </main>
  </Popup>
</template>

<script>
import { defineComponent } from 'vue';
import Cropper from 'cropperjs';
import { mapStores } from 'pinia';
import Popup from '@/components/Popup.vue';
import Button from '@/components/foundation/Button.vue';
import { usePopupSearchStore } from '@/stores/popup-search';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ImageCropper',
  components: {
    Popup,
    Button,
  },
  props: {
    img: { type: String, default: null },
    imgW: { type: Number, default: null },
    imgH: { type: Number, default: null },
  },
  emits: ['closeCropper', 'setCroppedImage'],
  data() {
    return {
      cropperReady: false,
      containerW: 500,
      containerH: (500 * this.imgH) / this.imgW,
    };
  },
  computed: {
    ...mapStores(usePopupSearchStore),
  },
  watch: {
    cropperReady(to) {
      if (to && this.popupSearchStore.cropParams) {
        this.cropper.setCropBoxData(this.popupSearchStore.cropParams);
      }
    },
  },
  mounted() {
    this.setContainerSize();
    this.cropper = new Cropper(this.$refs.cropImage, {
      autoCropArea: 0.9,
      checkOrientation: false,
      movable: false,
      rotatable: false,
      scalable: false,
      zoomable: false,
      ready: this.setCropperReady,
      restore: false,
      toggleDragModeOnDblclick: false,
      viewMode: 2,
      dragMode: 'crop',
      guides: false,
      center: false,
      highlight: false,
    });
  },
  methods: {
    clickedClosePopup() {
      this.$emit('closeCropper');
    },
    setCropperReady() {
      this.cropperReady = true;
    },
    setCroppedImage() {
      this.popupSearchStore.setCropParams({ cropParams: this.cropper.getCropBoxData() });
      const data = this.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.2);
      this.$emit('setCroppedImage', data);
    },
    setContainerSize() {
      const scaledH = (this.$refs.container.clientWidth * this.imgH) / this.imgW;
      this.containerH = Math.min(scaledH, this.imgH);
      this.containerW = Math.min(this.$refs.container.clientWidth, this.imgW);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
h4 {
  text-align: center;
  text-transform: uppercase;
  padding: var(--space-12) 0 var(--space-32);
}

.btn-group {
  display: flex;
  justify-content: space-around;
  margin: var(--space-32) 0 0;
}

.crop-box {
  max-width: 100%;
  margin: auto;

  > img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  &:deep(.cropper-container .cropper-point) {
    background-color: var(--background-0);
    opacity: 0.9;
  }

  &:deep(.cropper-line) {
    background-color: var(--background-0);
  }

  &:deep(.cropper-view-box) {
    outline-color: var(--white);
  }

  &:deep(.cropper-modal) {
    opacity: 0.2;
  }
}
</style>
