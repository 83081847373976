<template>
  <section class="media-popup-sentiment-container" data-cy="media-popup-sentiment-container">
    <Icon :name="iconName" medium />
    <span class="text-main">Sentiment - {{ sentimentType }}</span>
    <InfoTooltip :tooltip="sentimentDescriptionTooltip" />
    <span class="sentiment-text-container">
      <template v-if="displayDropdown && sentimentType">
        <DropdownButton
          data-cy="media-popup-sentiment-dropdown"
          :dropdown-list="dropdownOptions"
          :button-classes="['light', 'sentiment']"
          :loading="mediaDetailStore.pending.updateMediaSentimentStatus"
        >
          <template #buttonContent>
            <Dot
              v-if="!mediaDetailStore.pending.updateMediaSentimentStatus"
              :color="sentimentColor"
            />
            {{ sentimentValue }}
            <Icon name="caret" :xxsmall="true" dir="right" style="margin-left: 10px" />
          </template>
        </DropdownButton>
      </template>
      <template v-else>
        <Dot v-if="sentimentType" :color="sentimentColor" class="dot" />
        <span
          v-tooltip="sentimentTextTooltip"
          class="sentiment-value-text"
          :style="{ color: textColor }"
        >
          {{ sentimentValue }}
        </span>
      </template>
    </span>
  </section>
</template>

<script>
import { mapStores } from 'pinia';
import dayjs from 'dayjs';
import { toolTips } from '@/config';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import Dot from '@/components/foundation/Dot.vue';
import enumTypes from '@/app/library/constants';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import { useMediaDetailStore } from '@/stores/media-detail';
import { useNotificationStore } from '@/stores/notification';
import { formatPlatformCommentSentiment } from '@/utils/formatters';
import { SENTIMENTS } from '@/models/sentiment.enum';

export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'MediaPopupSentiment',
  components: { DropdownButton, InfoTooltip, Dot, Icon },
  props: {
    mediaItem: { type: Object, required: true },
    dropdown: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['sentiment-changed'],
  computed: {
    ...mapStores(useMediaDetailStore, useNotificationStore),
    sentimentType() {
      const commentsList = [...enumTypes.OWNED_LIST, ...enumTypes.NO_MEDIA_SOURCE_LIST];
      if (commentsList.includes(this.mediaItem.sourceType)) {
        return formatPlatformCommentSentiment(this.mediaItem.postType);
      }
      return 'Caption';
    },
    sentimentData() {
      if (this.sentimentType === 'Comments' || this.sentimentType === 'Replies') {
        return this.mediaItem.commentSentiment;
      }
      if (this.sentimentType === 'Caption') {
        return this.mediaItem.captionSentiment;
      }
      return null;
    },
    sentimentValue() {
      if (this.sentimentData == null) {
        return '-';
      }
      if (this.sentimentData.isNegative) {
        return SENTIMENTS.NEGATIVE.text;
      }
      if (this.sentimentData.isPositive) {
        return SENTIMENTS.POSITIVE.text;
      }
      return SENTIMENTS.NEUTRAL.text;
    },
    sentimentColor() {
      if (this.sentimentData == null) {
        return '';
      }
      if (this.sentimentData.isNegative) {
        return SENTIMENTS.NEGATIVE.iconColour;
      }
      if (this.sentimentData.isPositive) {
        return SENTIMENTS.POSITIVE.iconColour;
      }
      return SENTIMENTS.NEUTRAL.iconColour;
    },
    iconName() {
      return SENTIMENTS.POSITIVE.icon;
    },
    textColor() {
      if (this.sentimentType === 'Comments') {
        return colours.ACTION.ACTION_500;
      }
      return colours.TEXT.TEXT_PRIMARY;
    },
    displayDropdown() {
      return this.dropdown || (this.sentimentType === 'Caption' && this.sentimentData);
    },
    dropdownOptions() {
      const isPositive = this.sentimentValue === SENTIMENTS.POSITIVE.text;
      const isNeutral = this.sentimentValue === SENTIMENTS.NEUTRAL.text;
      const isNegative = this.sentimentValue === SENTIMENTS.NEGATIVE.text;
      return [
        {
          textColor: colours.TEXT.TEXT_PRIMARY,
          text: SENTIMENTS.POSITIVE.text,
          check: isPositive,
          iconName: 'dot',
          itemColor: SENTIMENTS.POSITIVE.iconColour,
          action: () => this.updateMediaSentiment(SENTIMENTS.POSITIVE.text),
        },
        {
          textColor: colours.TEXT.TEXT_PRIMARY,
          text: SENTIMENTS.NEUTRAL.text,
          check: isNeutral,
          iconName: 'dot',
          itemColor: SENTIMENTS.NEUTRAL.iconColour,
          action: () => this.updateMediaSentiment(SENTIMENTS.NEUTRAL.text),
        },
        {
          textColor: colours.TEXT.TEXT_PRIMARY,
          text: SENTIMENTS.NEGATIVE.text,
          check: isNegative,
          iconName: 'dot',
          itemColor: SENTIMENTS.NEGATIVE.iconColour,
          action: () => this.updateMediaSentiment(SENTIMENTS.NEGATIVE.text),
        },
      ];
    },
    sentimentDescriptionTooltip() {
      if (this.sentimentType === 'Comments') {
        return toolTips.sentiment.commentsDescription;
      }
      if (this.sentimentType === 'Replies') {
        return toolTips.sentiment.repliesDescription;
      }
      if (this.sentimentType === 'Caption') {
        return toolTips.sentiment.captionDescription;
      }
      return '';
    },
    hasComments() {
      return this.mediaItem.insights?.comments || this.mediaItem.insights?.replies_total;
    },
    sentimentTextTooltip() {
      if (this.sentimentData == null) {
        if (!this.hasComments) {
          return this.sentimentType === 'Replies'
            ? toolTips.sentiment.noReplies
            : toolTips.sentiment.noComments;
        }
        if (this.commentsNotImported || this.captionTooOld) {
          return toolTips.sentiment.cannotCalculateSentiment;
        }
        return toolTips.sentiment.overallSentimentProcessing;
      }
      return '';
    },
    commentsNotImported() {
      // comment sentiment should be ready within 5 hours, if the post has comments.
      const postAgeHours = dayjs.duration(dayjs().diff(dayjs(this.mediaItem.datePosted))).asHours();
      return this.sentimentType !== 'Caption' && this.hasComments && postAgeHours > 5;
    },
    captionTooOld() {
      // caption sentiment is not guaranteed on posts made prior to Jan 1st, 2023.
      const postTooOld = dayjs(this.mediaItem.datePosted).isBefore(dayjs('2023-01-01'));
      return this.sentimentType === 'Caption' && postTooOld;
    },
  },
  methods: {
    async updateMediaSentiment(sentiment) {
      if (this.sentimentValue === sentiment) {
        return;
      }

      if (
        await this.notificationStore.confirm(
          `Modify sentiment to ${sentiment.toLowerCase()}?`,
          'Modifying the sentiment on this caption manually will not affect how AI processes sentiment in the future.',
          {
            confirmAlias: 'Modify',
            confirmType: 'primary',
          },
        )
      ) {
        try {
          const meta = {
            captionSentiment: {
              userPositiveScore: sentiment === SENTIMENTS.POSITIVE.text ? 1 : 0,
              userNeutralScore: sentiment === SENTIMENTS.NEUTRAL.text ? 1 : 0,
              userNegativeScore: sentiment === SENTIMENTS.NEGATIVE.text ? 1 : 0,
            },
          };
          await this.mediaDetailStore.updateMediaSentiment({
            brandId: this.mediaItem.brandId,
            mediaId: this.mediaItem.id,
            meta,
          });
          this.notificationStore.setToast({
            message: 'Your caption sentiment has been modified.',
          });
          this.emitSentimentChanged();
        } catch (error) {
          this.notificationStore.setToast({
            message: 'Your caption sentiment could not be modified. Please try again later.',
            type: 'error',
          });
        }
      }
    },
    emitSentimentChanged() {
      this.$emit('sentiment-changed', 'action', 'Changed Sentiment');
    },
  },
};
</script>

<style lang="postcss" scoped>
.media-popup-sentiment-container {
  min-height: 4.4rem;
  background: var(--background-300);
  border-radius: var(--round-corner);
  width: 100%;
  padding: var(--space-24);
  margin: var(--space-8) auto var(--space-8) auto;
  color: var(--text-primary);
  display: flex;
  align-items: center;

  .svg-icon {
    margin-right: var(--space-8);
  }

  .sentiment-value-text {
    color: var(--action-500);
  }

  .sentiment-text-container {
    text-align: right;
    margin-left: auto;
  }

  .dot {
    margin-right: var(--space-10);
  }
}
</style>
