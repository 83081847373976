<template>
  <span>
    <span v-for="(span, index) in truncatedTextSpans" :key="index" class="text-span">
      <a
        v-if="span.type === COMMENTING_SPAN_TYPES.LINK"
        :href="span.url"
        :class="span.type.toLowerCase()"
        target="_blank"
      >
        {{ span.value }}
      </a>
      <span v-else :key="index" :class="span.type.toLowerCase()">
        {{ getSpanText(span) }}
      </span>
    </span>
  </span>
</template>

<script>
import { defineComponent } from 'vue';
import { decodeSpansFromText, COMMENTING_SPAN_TYPES } from '@/utils/commenting';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'CommentText',
  props: {
    comment: { type: Object, required: true },
    users: { type: Object, default: null },
    truncateTo: { type: Number, default: null },
  },
  computed: {
    textSpans() {
      return decodeSpansFromText(this.comment.text, this.users);
    },
    truncatedTextSpans() {
      if (this.truncateTo === null) {
        return this.textSpans;
      }
      let totalLength = 0;
      const truncatedSpans = [];
      for (let i = 0; i < this.textSpans.length; i += 1) {
        const span = this.textSpans[i];
        const spanLength = this.getSpanText(span).length;
        if (totalLength + spanLength <= this.truncateTo) {
          truncatedSpans.push(span);
          totalLength += spanLength;
        } else {
          if (span.type === COMMENTING_SPAN_TYPES.TEXT) {
            truncatedSpans.push({
              type: COMMENTING_SPAN_TYPES.TEXT,
              value: span.value.slice(0, this.truncateTo - totalLength),
            });
          }
          const lastSpan = truncatedSpans[truncatedSpans.length - 1];
          if (lastSpan?.type === COMMENTING_SPAN_TYPES.TEXT) {
            truncatedSpans.pop();
            truncatedSpans.push({
              type: COMMENTING_SPAN_TYPES.TEXT,
              value: `${lastSpan.value}...`,
            });
          } else {
            truncatedSpans.push({
              type: COMMENTING_SPAN_TYPES.TEXT,
              value: '...',
            });
          }
          break;
        }
      }
      return truncatedSpans;
    },
    COMMENTING_SPAN_TYPES() {
      return COMMENTING_SPAN_TYPES;
    },
  },
  methods: {
    getSpanText(span) {
      return span.type === COMMENTING_SPAN_TYPES.MENTION
        ? `@${span.user.first_name || span.user.firstName} ${
            span.user.last_name || span.user.lastName
          }`
        : span.value;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.text-span {
  .link,
  .mention {
    color: var(--action-500);
    font-weight: var(--font-normal);
  }
}
</style>
