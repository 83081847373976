import { getEnumText, getEnumValue } from '@/models/enum.utils';
import { CHANNELS } from '@/models/dashboards/channels.enum';
import { metrics as YOUTUBE_METRICS } from '@/app/youtube/constants';
import { GRAPH_SCALES } from '@/models/dashboards/graph-scales.enum';

export const DASHBOARD_TOOLTIPS = {
  noBrandPermission: "Looks like this feature isn't included in your plan!",
  noAccessMessage: 'Contact your Customer Success Representative to try it out.',
  noIndustryBenchmarksData:
    'Benchmark data is unavailable prior to May 12, 2021. Please select a time period after this date.',
  noAccessToBrandTags: 'Contact your brand admin for permission to use brand tags',
  noAccessToCompetitorTags: 'Contact your brand admin for permission to use competitor tags',
  monetaryTooltipDisabled:
    'Metrics with monetary values are unavailable when ad accounts with different currencies are selected',
  estimatedMetricTooltipDisabled:
    'This metric is only available in Social Advertising Graph Reports',
  estimatedMetricTooltipDisabledForDisplay: 'This metric is not available for bar charts',
  customMetricIcon: 'This is a custom metric',
};
const FORMAT_STRING = 'yyyy-MM-dd';

// This is replacing the old metric frontend config for when there is metric specific logic
export const METRIC_KEYS = {
  AVG_ENTERTAINMENT_SCORE: 'AVG_ENTERTAINMENT_SCORE',
};
const GRAPH_SCALE_LOCAL_STORE_KEY = 'dashboards_graph_scale';

const NUMBER_OF_DASHBOARD_COLS = 6;
const DASHBOARD_ROW_HEIGHT_IN_PIXELS = 100;

const MAX_GRAPH_DATASETS = 10;

export const MAX_NUMBER_OF_CONTENT_REPORTS = 10;

export const MIN_NUMBER_OF_CHANNELS_TO_DISPLAY = 2;

export const MAX_NUMBER_OF_CHANNELS_TO_DISPLAY = 5;

export const MAX_ADS_TABLE_REPORT_ROWS = 50;

export const CHANNELS_WITH_MEDIA_TYPE_BREAKDOWN_SUPPORT = [
  CHANNELS.INSTAGRAM.value,
  CHANNELS.FACEBOOK.value,
];

const REPORT_DATE_RANGE_LS_KEY = 'reportDateRange';
const CONTEXT_DATE_RANGE_LS_KEY = 'contextDateRange';
const CONTEXT_PERIOD_MODE_LS_KEY = 'dashboards_context_period_mode';
const REPORTING_PERIOD_MODE_LS_KEY = 'dashboards_reporting_period_mode';

const ROLES = Object.freeze({
  EDITOR: {
    value: 'EDITOR',
  },
  VIEWER: {
    value: 'VIEWER',
  },
  OWNER: {
    value: 'OWNER',
  },
});

const CONTENT_TYPE_OPTIONS = Object.freeze({
  CONTENT_OWNED: {
    value: 'CONTENT_OWNED',
    text: 'Owned',
  },
  CONTENT_UGC: {
    value: 'CONTENT_UGC',
    text: 'User Generated',
  },
});

const IG_CONTENT_OPTIONS = Object.freeze({
  OWNED: {
    value: 'OWNED',
    text: 'Owned',
  },
  UGC: {
    value: 'UGC',
    text: 'User Generated',
  },
});

export const getRoleProp = (channel, property) => {
  const key = getEnumValue(channel);
  const config = ROLES[key];
  return config?.[property];
};

export function getRoleText(value) {
  const text = getRoleProp(value, 'text');
  return text || getEnumText(value);
}

const USER_STATUS = Object.freeze({
  ACTIVE: 0,
  INACTIVE: 1,
});

const POPUP_MESSAGES = {
  leaveDashboard: {
    title: 'Are you sure you want to leave?',
    message: 'You will no longer have access to this Dashboard.',
    confirmAlias: 'Leave Dashboard',
  },
};

export const ERROR_MESSAGES = {
  savingAutoExport: {
    serverError: 'An error occurred while attempting to save your scheduled delivery.',
  },
};

export const AUTO_EXPORT_FORMATS = Object.freeze({
  PDF: {
    value: 'PDF',
    text: 'PDF',
  },
  SPREADSHEET: {
    value: 'SPREADSHEET',
    text: 'XLSX',
  },
});

export const AUTO_EXPORT_FREQUENCIES = Object.freeze({
  DAILY: {
    value: 'DAILY',
    text: 'Daily',
  },
  WEEKLY: {
    value: 'WEEKLY',
    text: 'Weekly',
  },
  MONTHLY: {
    value: 'MONTHLY',
    text: 'Monthly',
  },
});

export const AUTO_EXPORT_DAY_OF_WEEK = Object.freeze({
  SUNDAY: {
    value: 'SUNDAY',
    text: 'Sunday',
    weeklyExportEndDay: 'Saturday',
  },
  MONDAY: {
    value: 'MONDAY',
    text: 'Monday',
    weeklyExportEndDay: 'Sunday',
  },
  TUESDAY: {
    value: 'TUESDAY',
    text: 'Tuesday',
    weeklyExportEndDay: 'Monday',
  },
  WEDNESDAY: {
    value: 'WEDNESDAY',
    text: 'Wednesday',
    weeklyExportEndDay: 'Tuesday',
  },
  THURSDAY: {
    value: 'THURSDAY',
    text: 'Thursday',
    weeklyExportEndDay: 'Wednesday',
  },
  FRIDAY: {
    value: 'FRIDAY',
    text: 'Friday',
    weeklyExportEndDay: 'Thursday',
  },
  SATURDAY: {
    value: 'SATURDAY',
    text: 'Saturday',
    weeklyExportEndDay: 'Friday',
  },
});

export const DB_MODE = {
  CREATE: 'CREATE',
  RENAME: 'RENAME',
  DUPLICATE: 'DUPLICATE',
};

export const COMPETITIVE_AVERAGE_KEY = 'competitor_average';
export const COMPETITIVE_AVERAGE_NAME = 'Competitor Average';

export const DATA_TYPES = Object.freeze({
  BRAND: 'BRAND',
  COMPETITOR: 'COMPETITOR',
  BENCHMARK: 'BENCHMARK',
});

export const TOTAL_METRIC_GROUPS = Object.freeze({
  CHANNEL: 'CHANNEL',
  BRAND: 'BRAND',
  TOTAL: 'TOTAL',
  TAG: 'TAG',
  SOURCE_CAMPAIGN: 'SOURCE_CAMPAIGN',
  REGION: 'REGION',
  COUNTRY: 'COUNTRY',
  PUBLISHER_PLATFORM: 'PUBLISHER_PLATFORM',
  AGE_GENDER: 'AGE_GENDER',
});

export const SORT_ORDER_OPTIONS = Object.freeze([
  {
    value: 'DESC',
    text: 'Top Performing Posts',
    ugcText: 'UGC Top Performing Posts',
    storiesText: 'Top Performing Stories',
  },
  {
    value: 'ASC',
    text: 'Lowest Performing Posts',
    storiesText: 'Lowest Performing Stories',
  },
]);

export const BENCHMARK_DATA_IMPORT_DATE = '2021-05-12';

export const NO_ACCESS_MESSAGES = Object.freeze({
  average: 'Average not available.',
  brand: "You don't have access to this brand.",
  channel: "You don't have access to this channel.",
  metric: "You don't have access to this metric.",
  disconnect: 'Account Disconnected.',
  feature: "You don't have access to this feature.",
  competitor: "You don't have access to this competitor.",
  noAccess: "You Don't Have Access",
  sort_by: "Sort by 'Day'",
  benchmark: "This brand doesn't have access to competitive insights",
  dashboards: "This brand doesn't have access to Dashboards",
  adsBrand: "This brand doesn't have access to ad analytics",
  adsUser: "You don't have access to ad analytics for this brand",
});

const instagramContentReportMediaMetrics = Object.freeze({
  labels: {
    comments_count: 'Comments',
    engagement: 'Engagement Rate',
    like_count: 'Likes',
    saved: 'Saves',
    total_engagement: 'Engagements',
    reach: 'Estimated Reach',
  },
  metrics: ['engagement', 'effectiveness', 'total_engagement'],
});

const instagramStoriesContentReportMediaMetrics = Object.freeze({
  labels: {
    ig_story_avg_reach: 'Avg. Reach',
    ig_story_avg_impressions: 'Avg. Impressions',
    ig_story_avg_completion_rate: 'Avg. Completion Rate',
    ig_story_avg_exit_rate: 'Avg. Exit Rate',
    ig_story_swipe_ups: 'Stories Link Clicks',
    reach: 'Reach',
    views: 'Impressions',
    completion_rate: 'Completion Rate',
    exit_rate: 'Exit Rate',
  },
  metrics: [
    'ig_story_avg_reach',
    'ig_story_avg_impressions',
    'ig_story_avg_completion_rate',
    'ig_story_avg_exit_rate',
  ],
  storyFrameMetrics: ['reach', 'views', 'completion_rate', 'exit_rate'],
});

const instagramCompetitiveContentReportMediaMetrics = Object.freeze({
  labels: {
    comments_count: 'Comments',
    engagement: 'Engagement Rate',
    like_count: 'Likes',
    reach: 'Estimated Reach',
  },
  metrics: ['comments_count', 'engagement', 'reach', 'like_count'],
});

const facebookContentReportMediaMetrics = Object.freeze({
  metrics: ['engagement_rate', 'impressions', 'total_engagements'],
});

const linkedinContentReportMediaMetrics = Object.freeze({
  metrics: ['engagements', 'engagement_rate', 'impressions', 'click_through_rate'],
});

const pinterestContentReportMediaMetrics = Object.freeze({
  labels: {
    total_closeups: 'Pin Clicks',
    engagement_rate: 'Engagement Rate',
    total_impressions: 'Impressions',
    total_clicks: 'Outbound Clicks',
    total_saves: 'Saves',
    video_views: 'Video Views',
    average_watch_time: 'Average Watch Time',
  },
  metrics: ['engagement_rate', 'total_saves', 'total_impressions', 'video_views'],
});

const twitterContentReportMediaMetrics = Object.freeze({
  labels: {
    engagements: 'Total Engagements',
    url_clicks: 'Link Clicks',
    follows: 'User Follows',
    user_profile_clicks: 'Profile Clicks',
  },
  metrics: ['engagement_rate', 'engagements', 'impressions'],
});

const tiktokContentReportMediaMetrics = Object.freeze({
  labels: {
    entertainment_score: 'Entertainment Score',
    views: 'Video Views',
    total_engagements: 'Total Engagements',
    average_time_watched: 'Avg. Time Watched',
  },
  metrics: ['entertainment_score', 'views', 'total_engagements'],
});

const youtubeContentReportMediaMetrics = Object.freeze({
  metrics: [
    YOUTUBE_METRICS.VIEWS.field,
    YOUTUBE_METRICS.AVG_VIEW_DURATION.field,
    YOUTUBE_METRICS.TOTAL_ENGAGEMENTS.field,
  ],
  labels: {
    [YOUTUBE_METRICS.VIEWS.field]: 'Video Views',
    [YOUTUBE_METRICS.AVG_VIEW_DURATION.field]: 'Avg. View Duration',
    [YOUTUBE_METRICS.TOTAL_ENGAGEMENTS.field]: 'Total Engagements',
    [YOUTUBE_METRICS.AVG_VIEW_PERCENTAGE.field]: 'Avg. % Viewed',
  },
});

export const CONTENT_REPORT_DEFAULT_MEDIA_METRICS = Object.freeze({
  [CHANNELS.INSTAGRAM.value]: instagramContentReportMediaMetrics,
  [CHANNELS.INSTAGRAM_UGC.value]: instagramContentReportMediaMetrics,
  [CHANNELS.INSTAGRAM_STORIES.value]: instagramStoriesContentReportMediaMetrics,
  [CHANNELS.INSTAGRAM_COMPETITIVE.value]: instagramCompetitiveContentReportMediaMetrics,
  [CHANNELS.LINKEDIN.value]: linkedinContentReportMediaMetrics,
  [CHANNELS.FACEBOOK.value]: facebookContentReportMediaMetrics,
  [CHANNELS.PINTEREST.value]: pinterestContentReportMediaMetrics,
  [CHANNELS.TWITTER.value]: twitterContentReportMediaMetrics,
  [CHANNELS.TIKTOK.value]: tiktokContentReportMediaMetrics,
  [CHANNELS.YOUTUBE.value]: youtubeContentReportMediaMetrics,
});

export const DASHBOARD_COMPETITIVE_RADIO_OPTIONS = Object.freeze({
  Competitors: {
    label: 'Competitors',
    value: 'Competitors',
  },
  Tags: { label: 'Tags', value: 'Tags' },
});

export const DASHBOARD_OWNED_RADIO_OPTIONS = Object.freeze({
  Brands: {
    label: 'Brands',
    value: 'Brands',
  },
  Tags: { label: 'Tags', value: 'Tags' },
});

export const DASHBOARD_EVENT_NAMES = {
  UPGRADE_NOW_CLICKED: 'Upgrade Options - Button Clicked',
  MANAGE_TAG_BUTTON_CLICKED: 'Manage Tags',
};

export const REPORT_CSV_LAYOUTS = {
  STANDARD: 'standard',
  OVERVIEW: 'overview',
};

export const DASHBOARD_TAG_BUTTON_INFO = {
  BRAND: {
    title: 'Brand',
    pageName: 'Settings - Brands',
    routeName: 'settings.brands',
  },
  COMPETITOR: {
    title: 'Competitor',
    pageName: 'Settings - Competitors',
    routeName: 'settings.competitors',
  },
};

export const DATA_TYPE_TIME_UNITS = {
  SECONDS: 'SECONDS',
  MILLISECONDS: 'MILLISECONDS',
};

export const DASHBOARD_COMPETITOR_ROUTE_INFO = {
  ADD_COMPETITOR: {
    routeName: 'settings.competitors',
    buttonTitle: 'Add Competitor',
  },
  NEW_COMPETITOR: {
    routeName: 'settings.competitors',
    buttonTitle: 'Add a new competitor',
  },
};

export const DASHBOARD_ROUTE_NAMES = {
  DASHBOARDS: 'dashboards',
  REPORT: 'dashboards.report',
  ID: 'dashboards.id',
};

export const IMAGE_DOWNLOAD_FILE_FORMATS = {
  PNG: {
    extension: 'png',
    dataUrl: 'image/png',
  },
  JPEG: {
    extension: 'jpeg',
    dataUrl: 'image/jpeg',
  },
};

export const CAMPAIGN_STATUS_OPTIONS = Object.freeze({
  [CHANNELS.META_ADS.value]: [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Archive', value: 'ARCHIVED' },
    { label: 'Paused', value: 'PAUSED' },
    { label: 'With Issues', value: 'WITH_ISSUES' },
  ],
  [CHANNELS.TIKTOK_ADS.value]: [
    { label: 'Active', value: 'ENABLE' },
    { label: 'Deleted', value: 'DELETED' },
    { label: 'Paused', value: 'DISABLE' },
    { label: 'Over Budget', value: 'BUDGET_EXCEED' },
  ],
});

export const SEE_MORE_LAUNCH_LOCATIONS = {
  AI_WIDGET: 'AI Widget',
  DASHBOARDS: 'Dashboards',
};

export const CONTENT_REPORT_MEDIA_LIST_SIZES = {
  VISION_AI_THUMBNAIL_MODE: 2,
  VISION_AI_EXPANDED_MODE: 4,
  DASHBOARDS: 6,
};

export const PLATFORM_CUSTOM_METRIC_OPTIONS = [
  { value: 'platformMetrics', text: 'Platform Metrics', icon: 'system' },
  { value: 'customMetrics', text: 'Custom Metrics', icon: 'singleUser' },
];

const DEFAULT_DATE_RANGE_OPTION = Object.freeze({
  CUSTOM: {
    value: 'CUSTOM',
    label: 'CUSTOM',
  },
});

const GRAPH_SCALE_FILTER_DETAILS = {
  label: 'View Graph',
  filter: 'graphScale',
  options: [
    {
      value: GRAPH_SCALES.DAILY.value,
      label: GRAPH_SCALES.DAILY.label,
    },
    {
      value: GRAPH_SCALES.MONTHLY.value,
      label: GRAPH_SCALES.MONTHLY.label,
    },
  ],
};

const REPORTING_PERIOD_FILTERS = Object.freeze({
  LAST_24_HOURS: {
    value: 'LAST_24_HOURS',
    text: 'last 24 hours',
  },
  LAST_7_DAYS: {
    value: 'LAST_7_DAYS',
    text: 'Last 7 Days',
  },
  LAST_WEEK: {
    value: 'LAST_WEEK',
    text: 'Last Week',
  },
  LAST_MONTH: {
    value: 'LAST_MONTH',
    text: 'Last Month',
  },
  CUSTOM: {
    value: 'CUSTOM',
    text: 'Custom',
  },
});

const COMPARISON_PERIOD_FILTERS = Object.freeze({
  PREVIOUS_24_HOURS: {
    value: 'PREVIOUS_24_HOURS',
    text: 'Previous 24 hours',
  },
  PREVIOUS_7_DAYS: {
    value: 'PREVIOUS_7_DAYS',
    text: 'Previous 7 Days',
  },
  PREVIOUS_WEEKS: {
    value: 'PREVIOUS_WEEKS',
    text: 'Previous Weeks',
  },
  PREVIOUS_MONTH: {
    value: 'PREVIOUS_MONTH',
    text: 'Previous Month',
  },
  CUSTOM: {
    value: 'CUSTOM',
    text: 'Custom',
  },
});

const DASHBOARD_FILTER_DETAILS = Object.freeze({
  DASHBOARD: {
    scope: 'dashboard',
    options: {
      reportingPeriod: {},
      comparisonPeriod: {
        label: 'Comparison Period',
        required: true,
      },
      graphScale: GRAPH_SCALE_FILTER_DETAILS.options,
    },
    default: {
      reportingPeriod: {
        preset: REPORTING_PERIOD_FILTERS.LAST_7_DAYS.value,
      },
      comparisonPeriod: {
        preset: COMPARISON_PERIOD_FILTERS.PREVIOUS_7_DAYS.value,
      },
      graphScale: GRAPH_SCALES.DAILY.value,
    },
  },
});

export const DASHBOARD_CUSTOM_METRICS_PANEL = {
  LINK_TEXT: 'Manage Custom Metrics',
  AGGREGATION: 'Aggregation (Advanced)',
  PLACEHOLDER: 'Select a custom metric',
  HEADER: 'Custom Metrics',
  NO_RESULTS: 'No results',
  AGGREGATION_LABEL: 'Aggregation',
};

export const CUSTOM_METRICS_AGGREGATION_OPTIONS = [
  { value: 'SUM', label: 'Metric-Level Sum' },
  { value: 'AVG', label: 'Post-Level Average' },
];

export default {
  COMPARISON_PERIOD_FILTERS,
  CONTENT_TYPE_OPTIONS,
  CONTEXT_DATE_RANGE_LS_KEY,
  CONTEXT_PERIOD_MODE_LS_KEY,
  CUSTOM_METRICS_AGGREGATION_OPTIONS,
  DASHBOARD_FILTER_DETAILS,
  DASHBOARD_ROW_HEIGHT_IN_PIXELS,
  DATA_TYPE_TIME_UNITS,
  DEFAULT_DATE_RANGE_OPTION,
  FORMAT_STRING,
  GRAPH_SCALE_FILTER_DETAILS,
  GRAPH_SCALE_LOCAL_STORE_KEY,
  IG_CONTENT_OPTIONS,
  IMAGE_DOWNLOAD_FILE_FORMATS,
  MAX_NUMBER_OF_CONTENT_REPORTS,
  MAX_GRAPH_DATASETS,
  NUMBER_OF_DASHBOARD_COLS,
  POPUP_MESSAGES,
  REPORT_DATE_RANGE_LS_KEY,
  REPORTING_PERIOD_FILTERS,
  REPORTING_PERIOD_MODE_LS_KEY,
  ROLES,
  DASHBOARD_TOOLTIPS,
  USER_STATUS,
};
