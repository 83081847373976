<template>
  <ConnectionPage platform="tiktok_ads">
    <template #connectionImage>
      <div class="image-cropper-small">
        <img :src="authStore.currentBrand.avatar_url" class="profile-pic translate-x-right" />
        <Icon
          name="tiktok"
          color="#FFFFFF"
          width="5rem"
          height="5rem"
          class="profile-pic translate-x-left"
        />
      </div>
    </template>
    <template #connectionTitle>
      <h4 class="first-line-title">Connect Your TikTok Ads Account for</h4>
      <h4>
        <span>{{ authStore.currentBrand.name }}</span>
      </h4>
    </template>
  </ConnectionPage>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import Icon from '@/components/foundation/Icon.vue';
import ConnectionPage from './ConnectionPage.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ConnectTiktokAds',
  components: {
    Icon,
    ConnectionPage,
  },
  computed: {
    ...mapStores(useAuthStore),
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.image-cropper-small {
  margin: 7.5rem auto var(--space-32);
  height: 5rem;
  width: 11rem;
  position: relative;

  .profile-pic {
    display: inline;
    height: 100%;
    width: auto;
    border-radius: 50%;
    border: 4px solid var(--white);
    box-shadow: var(--shadow-1);
  }

  .translate-x-left {
    transform: translateX(calc(-1 * var(--space-8)));
    background-color: var(--black);
    padding: var(--space-13);
  }

  .translate-x-right {
    transform: translateX(var(--space-16));
  }
}

p {
  max-width: var(--width-420);
  margin: var(--space-24) auto 0;
}
</style>
