import { computed } from 'vue';
import { useIdentityStore } from '@/stores/identity';
import { useFlagStore } from '@/stores/flag';
import { ORGANIZATION, ORGANIZATION_USER } from '@/models/auth/permissions.enum';

export function useListeningPermissions() {
  const identityStore = useIdentityStore();
  const flagStore = useFlagStore();

  const organizationCanAccessSocialListening = computed(() => {
    return identityStore.guard(ORGANIZATION.LISTENING.CAN_ACCESS_SOCIAL_LISTENING);
  });

  const organizationUserCanAccessSocialListening = computed(() => {
    return identityStore.guard(ORGANIZATION_USER.LISTENING.CAN_ACCESS_SOCIAL_LISTENING);
  });

  const canAccessSocialListening = computed(() => {
    return (
      organizationCanAccessSocialListening.value && organizationUserCanAccessSocialListening.value
    );
  });

  const canAccessYoutubeCompliance = computed(() => {
    return flagStore.ready && flagStore.flags.youtubeCompliance;
  });

  const canAccessGDIHeadersAndFilters = computed(() => {
    return flagStore.ready && flagStore.flags.gdiHeadersAndFilters;
  });

  const canAccessSocialListeningGDI = computed(() => {
    return flagStore.ready && flagStore.flags.socialListeningGdi;
  });

  const hasSpecialSearchFeatureFlag = computed(() => {
    return flagStore.ready && flagStore.flags.slSpecialSearch;
  });

  return {
    organizationCanAccessSocialListening,
    organizationUserCanAccessSocialListening,
    canAccessSocialListening,
    canAccessYoutubeCompliance,
    canAccessGDIHeadersAndFilters,
    canAccessSocialListeningGDI,
    hasSpecialSearchFeatureFlag,
  };
}
