<template>
  <div class="popup-container" :style="zIndex" @mousedown="$emit('mousedown')">
    <h5 class="find-similar">{{ labels.header }}</h5>
    <div v-sanitize-html="labels.subHeading" class="subheading">{{ labels.subHeading }}</div>
    <div class="media-search-image">
      <img :src="image" />
    </div>
    <Button small primary class="choose-media" @click="$emit('chooseMedia')">
      {{ labels.chooseMediaButtonText }}
    </Button>
    <div class="upload">
      <a class="upload-link" @click="$emit('uploadImage')">
        {{ labels.uploadLinkText }}
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useMediaSelectStore } from '@/stores/media-select';
import Button from '@/components/foundation/Button.vue';
import constants from '@/app/competitive/constants';
import mediaSearchImage from '@/assets/img/media-search.png';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'SearchDropdown',
  components: { Button },
  props: {
    labels: {
      type: Object,
      default: () => {
        return constants.callToActionLabels;
      },
    },
    image: { type: String, default: mediaSearchImage },
  },
  emits: ['uploadImage', 'mousedown', 'chooseMedia'],
  computed: {
    ...mapStores(useMediaSelectStore),
    zIndex() {
      const zIndex = this.mediaSelectStore.multiSelectSelectedItems.length > 0 ? 251 : 149;
      return { zIndex };
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.popup-container {
  width: 27.5rem;
  position: absolute;
  box-shadow: var(--shadow-4);
  border-radius: var(--round-corner);
  background-color: var(--background-0);
  padding: var(--space-16) var(--space-80);
  text-align: center;

  .find-similar {
    margin: var(--space-16);
  }

  .subheading {
    line-height: var(--space-16);
    font-size: var(--x12);
  }

  .media-search-image {
    margin-top: var(--space-24);
    margin-bottom: var(--space-24);
    height: 98px;
  }

  .choose-media {
    margin: auto;
    pointer-events: all;
  }

  .upload {
    margin: var(--space-16) auto;

    .upload-link {
      color: var(--action-500);
      font-weight: normal;
      font-size: var(--x12);
      text-decoration: underline;
    }
  }
}
</style>
