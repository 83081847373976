<template>
  <div class="pilltab-container">
    <div
      v-for="(tab, tabIndex) in tabs"
      :key="tabIndex"
      :class="{
        act: currentTabIndex === tabIndex,
        'pilltab-left': tabIndex === 0,
        'pilltab-middle': tabIndex > 0 && tabIndex < tabs.length - 1,
        'pilltab-right': tabIndex === tabs.length - 1,
      }"
      class="pilltab"
      @click="setTabByIndex(tabIndex)"
    >
      <router-link :to="tab.link ? tab.link : ''">
        <span v-if="tab.label" class="tab-label">{{ tab.label }}</span>
        {{ tab.name }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'PillTab',
  props: {
    tabs: { type: Array, default: null },
  },
  emits: ['onChange'],
  data() {
    return {
      currentTabIndex: 0,
    };
  },
  methods: {
    setTabByIndex(index) {
      this.currentTabIndex = index;
      this.$emit('onChange', index);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.pilltab-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-secondary);
  text-transform: capitalize;

  .pilltab {
    width: fit-content;
    min-width: 11rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    border: 1px solid var(--border);
    font-size: var(--x16);
    line-height: 2rem;
    background-color: var(--background-0);
    cursor: pointer;
    transition: var(--transition-all);
    border-radius: 0;

    a {
      font-weight: var(--font-normal);
    }

    &:focus a,
    &:hover a,
    &.act a {
      color: var(--brand-accent);
    }

    &.act a {
      font-weight: var(--font-medium);
    }
  }

  .pilltab-left {
    border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);
    margin-right: -1px;
  }

  .pilltab-middle {
    border-right: 0;
  }

  .pilltab-right {
    border-radius: 0 var(--button-border-radius) var(--button-border-radius) 0;
  }

  .tab-label {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(-1 * var(--space-8));
    left: 50%;
    transform: translateX(-50%);
    height: 1rem;
    width: 3rem;
    border-radius: 1rem;
    text-transform: uppercase;
    font-size: var(--x10);
    color: var(--white);
    background-color: var(--success-500);
  }
}
</style>
