<template>
  <div class="dh-card-title" :class="{ small, disabled }" :style="measurableStyles">
    <div class="dh-card-title-left">
      <slot name="left" />
      <slot />
      <InfoTooltip
        v-if="tooltip"
        :tooltip="tooltip"
        :icon-color="colours.ICON.ICON_SECONDARY"
        class="card-title-tooltip"
      />
    </div>
    <div class="dh-card-title-right">
      <!-- @slot Content to be placed to the right of the title. -->
      <slot name="right" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { colours } from '@/ux/colours';
import MeasurableMixin from '@/components/foundation/mixins/measurable.mixin';
import InfoTooltip from '@/components/core/InfoTooltip.vue';

/**
 * To be used with the Card component.  Displays a title within the Card with the correct formatting.
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  components: {
    InfoTooltip,
  },
  mixins: [MeasurableMixin],
  props: {
    /**
     * Small styling for the card title.
     */
    small: {
      type: Boolean,
      default: false,
    },
    /**
     * If present, an InfoTooltip will be displayed to the right of the title.
     * Value can be a string or a v-tooltip config object.
     */
    tooltip: {
      type: [String, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colours() {
      return colours;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.dh-card-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 var(--space-24);

  .card-title-tooltip {
    margin-top: 3px;
  }

  .disabled {
    opacity: 0.3;
  }

  .dh-card-title-left {
    display: flex;
    align-items: center;
    color: var(--text-primary);
    font-size: var(--x18);
    line-height: var(--x23);
    flex-grow: 1;
  }

  &.small {
    .dh-card-title-left {
      font-size: var(--x16);
      line-height: var(--x20);
    }
  }
}
</style>
