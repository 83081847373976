<script setup>
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { FilterSidePanel, useFilterSidePanel } from '@dashhudson/dashing-ui';
import {
  FILTER_ACTION_TYPES,
  trackFilterDrawerInteraction,
} from '@/components/dashingUI/constants/mixpanel';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import without from 'lodash/without';

const route = useRoute();

const prevFilters = ref({});
const prevChanges = ref({});

function onFilterApply({
  scopedFilters: currentFilters,
  changedFilters,
  defaultFilters: initialFilters,
}) {
  if (!isEqual(changedFilters, prevChanges.value)) {
    const updatedFilterKey = Object.keys(currentFilters).find(
      (key) => !isEqual(currentFilters[key], prevFilters.value[key]),
    );

    const prevSelected = prevFilters.value[updatedFilterKey];
    const currentSelected = currentFilters[updatedFilterKey];
    const defaultSelected = initialFilters[updatedFilterKey];

    let isRemoved = isEqual(defaultSelected, currentSelected) || !currentSelected;
    if (Array.isArray(defaultSelected)) {
      isRemoved = !currentSelected?.length || prevSelected?.length > currentSelected?.length;
    }

    trackFilterDrawerInteraction({
      action: isRemoved ? FILTER_ACTION_TYPES.REMOVE : FILTER_ACTION_TYPES.APPLY,
      filterName: updatedFilterKey,
      selectedOptions: currentFilters[updatedFilterKey],
    });
    prevFilters.value = cloneDeep(currentFilters);
    prevChanges.value = cloneDeep(changedFilters);
  }
}

const {
  visible,
  resetFilterNames,
  scopedFilters,
  scopedDefaultFilters: defaultFilters,
  filterPanels,
} = useFilterSidePanel({ onApply: () => onFilterApply });

const collapseData = computed(() => {
  return Object.keys(filterPanels.value).filter((k) => filterPanels.value[k].collapsed === true);
});

watch(
  () => resetFilterNames.value,
  (to) => {
    if (to.length) {
      const resetAll = resetFilterNames.value.length === Object.keys(scopedFilters.value).length;
      const action = resetAll ? FILTER_ACTION_TYPES.RESET_ALL : FILTER_ACTION_TYPES.RESET_FILTERSET;
      const filterName = resetAll ? undefined : resetFilterNames.value[0];

      trackFilterDrawerInteraction({
        action,
        filterName,
      });
    }
  },
  { immediate: true, deep: true },
);

watch(
  () => visible.value,
  (to, from) => {
    if (to) {
      document.body.dataset.filterSidePanelOpen = '';
      trackFilterDrawerInteraction({ action: FILTER_ACTION_TYPES.OPENED });
    } else {
      delete document.body.dataset.filterSidePanelOpen;
      if (from) {
        trackFilterDrawerInteraction({ action: FILTER_ACTION_TYPES.CLOSED });
      }
    }
  },
  { immediate: true },
);

watch(
  () => defaultFilters.value,
  (to, from) => {
    if (!isEqual(to, from)) {
      prevFilters.value = to;
    }
  },
  { immediate: true, deep: true },
);

watch(
  () => collapseData.value,
  (to, from) => {
    if (!isEqual(to, from)) {
      const lastCollapsed = without(to, ...from)[0];
      const lastExpanded = without(from, ...to)[0];
      if (lastCollapsed) {
        trackFilterDrawerInteraction({
          action: FILTER_ACTION_TYPES.COLLAPSE,
          filterName: filterPanels.value[lastCollapsed].title,
        });
      }
      if (lastExpanded) {
        trackFilterDrawerInteraction({
          action: FILTER_ACTION_TYPES.EXPAND,
          filterName: filterPanels.value[lastExpanded].title,
        });
      }
    }
  },
);

watch(
  () => route.name,
  () => {
    visible.value = false;
  },
);
</script>

<template>
  <FilterSidePanel ref="filterSidePanel" />
</template>
