import { engagementRating } from '@/utils/vision';
import enumTypes from '@/app/library/constants';
import { colours } from '@/ux/colours';
import { CHANNELS } from '@/models/dashboards/channels.enum';

const competitiveSources = Object.freeze({
  INSTAGRAM: 'INSTAGRAM',
  TWITTER: 'TWITTER',
  YOUTUBE: 'YOUTUBE',
  FACEBOOK: 'FACEBOOK',
});

const competitiveChannelSelector = {
  [CHANNELS.INSTAGRAM.value]: {
    value: CHANNELS.INSTAGRAM.value,
    routeName: 'competitors.instagram.competitorOverview',
  },
  [CHANNELS.TWITTER.value]: {
    value: CHANNELS.TWITTER.value,
    routeName: 'competitors.twitter.competitorOverview',
  },
  [CHANNELS.YOUTUBE.value]: {
    value: CHANNELS.YOUTUBE.value,
    routeName: 'competitors.youtube.competitorOverview',
  },
};

const toolTips = Object.freeze({
  [competitiveSources.INSTAGRAM]: {
    platformName: 'Instagram',
    noBrandPermission: "Looks like this feature isn't included in your plan!",
    noUserPermission: "Looks like you don't have permission to use this feature!",
    connectCallToAction:
      "Dash Hudson brings you all of the insights you love, including Competitors! Ask your Dash Hudson administrator to provide you access. Or if this feature isn't in your package, contact your Customer Success Representative to try it out!",
    noMediaForDatesEmptyMessages: 'No posts found during this time period',
    noMediaForFiltersEmptyMessages: 'No posts match your selected filters',
    mostEngagingPostsTooltip:
      'Highest performing organic competitor posts published during the selected date range by engagement rate',
    mostEngagingPostsEmptyMessage: 'No Competitor Posts',
    mostEngagingPostsReportTitle: 'Most Engaging Posts',
    shareofVoice:
      'Share is the measure of your specific brand metric divided by the total of the competitors selected',
    noCompetitorsEmptyStatus: {
      title: 'Add Competitors to Get Started',
    },
    noFilteredCompetitorsEmptyStatus: {
      message:
        'There are no matching competitors for this set of filters. Remove some to try again.',
      title: 'Remove Filters',
    },
  },
  [competitiveSources.TWITTER]: {
    platformName: 'X',
    noBrandPermission: "Looks like this feature isn't included in your plan!",
    noUserPermission: "Looks like you don't have permission to use this feature!",
    connectCallToAction:
      "Dash Hudson brings you all of the insights you love, including Competitors! Ask your Dash Hudson administrator to provide you access. Or if this feature isn't in your package, contact your Customer Success Representative to try it out!",
    noMediaForDatesEmptyMessages: 'No posts found during this time period',
    noMediaForFiltersEmptyMessages: 'No posts match your selected filters',
    mostEngagingPostsTooltip:
      'Highest performing competitor posts published during the selected date range by public engagements',
    mostEngagingPostsEmptyMessage: 'No Competitor Posts',
    mostEngagingPostsReportTitle: 'Top Performing Posts',
    shareofVoice:
      'Share is the measure of your specific brand metric divided by the total of the competitors selected',
    noCompetitorsEmptyStatus: {
      title: 'Add Competitors to Get Started',
    },
    noFilteredCompetitorsEmptyStatus: {
      message:
        'There are no matching competitors for this set of filters. Remove some to try again.',
      title: 'Remove Filters',
    },
  },
  [competitiveSources.YOUTUBE]: {
    platformName: 'YouTube',
    noBrandPermission: "Looks like this feature isn't included in your plan!",
    noUserPermission: "Looks like you don't have permission to use this feature!",
    connectCallToAction:
      "Dash Hudson brings you all of the insights you love, including Competitors! Ask your Dash Hudson administrator to provide you access. Or if this feature isn't in your package, contact your Customer Success Representative to try it out!",
    noMediaForDatesEmptyMessages: 'No posts found during this time period',
    noMediaForFiltersEmptyMessages: 'No posts match your selected filters',
    mostEngagingPostsTooltip:
      'Highest performing competitor videos published during the selected date range by video views',
    mostEngagingPostsEmptyMessage: 'No Competitor Posts',
    mostEngagingPostsReportTitle: 'Top Performing Videos',
    shareofVoice:
      'Share is the measure of your specific brand metric divided by the total of the competitors selected',
    noCompetitorsEmptyStatus: {
      title: 'Add Competitors to Get Started',
    },
    noFilteredCompetitorsEmptyStatus: {
      message:
        'There are no matching competitors for this set of filters. Remove some to try again.',
      title: 'Remove Filters',
    },
  },
});

const callToActionLabels = Object.freeze({
  header: 'Find Similar Media',
  subHeading: "Easily discover comparable content with <br> Dash Hudson's visual search tools",
  chooseMediaButtonText: 'Choose Media',
  uploadLinkText: 'Upload an Image',
});

export const competitiveFiltersForInstagram = Object.freeze({
  FILTER_POST_TYPES: 'competitive_post_types',
  FILTER_PREDICTED_PERFORMANCE: 'prediction',
  FILTER_HASHTAG: 'hashtag',
});

const competitivePostsFilterOptionsForInstagram = Object.freeze([
  { label: 'Hashtag', value: competitiveFiltersForInstagram.FILTER_HASHTAG, icon: 'hash' },
  { label: 'Media Type', value: competitiveFiltersForInstagram.FILTER_POST_TYPES, icon: 'media' },
  {
    label: 'Predicted Performance',
    value: competitiveFiltersForInstagram.FILTER_PREDICTED_PERFORMANCE,
    icon: 'boosted',
  },
]);

export const competitiveFiltersForTwitter = Object.freeze({
  FILTER_POST_TYPES: 'competitive_post_types',
  FILTER_KEYWORDS: 'tweet_keywords',
});

export const competitiveFiltersForYouTube = Object.freeze({
  FILTER_POST_TYPES: 'competitive_post_types',
  FILTER_TITLE_OR_DESCRIPTION: 'title_or_description',
});

const sourceToCompetitiveFilters = Object.freeze({
  [competitiveSources.INSTAGRAM]: competitiveFiltersForInstagram,
  [competitiveSources.TWITTER]: competitiveFiltersForTwitter,
  [competitiveSources.YOUTUBE]: competitiveFiltersForYouTube,
});

const competitivePostsFilterOptionsForTwitter = Object.freeze([
  { label: 'Media Type', value: competitiveFiltersForTwitter.FILTER_POST_TYPES, icon: 'media' },
  { label: 'Post', value: competitiveFiltersForTwitter.FILTER_KEYWORDS, icon: 'pencil' },
]);

const competitivePostsFilterOptionsForYouTube = Object.freeze([
  { label: 'Media Type', value: competitiveFiltersForYouTube.FILTER_POST_TYPES, icon: 'media' },
  {
    label: 'Title or Description',
    value: competitiveFiltersForYouTube.FILTER_TITLE_OR_DESCRIPTION,
    icon: 'pencil',
  },
]);

const competitiveSourceToPostsFilterOptions = Object.freeze({
  [competitiveSources.INSTAGRAM]: competitivePostsFilterOptionsForInstagram,
  [competitiveSources.TWITTER]: competitivePostsFilterOptionsForTwitter,
  [competitiveSources.YOUTUBE]: competitivePostsFilterOptionsForYouTube,
});

const competitiveCompetitorWidget = Object.freeze({
  competitors: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Competitors',
    icon: 'competitive',
    noDuplicate: true,
    disableDelete: true,
    showNumberBadge: true,
    showLoader: true,
    options: {
      enableClearSearch: true,
      showSearchBar: true,
      searchPlaceholder: 'Search competitors',
      showSelectAllToggle: true,
      showManage: true,
    },
  },
});

export const competitivePostsWidgetsForInstagram = Object.freeze({
  competitive_post_types: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Media Type',
    icon: 'media',
    noDuplicate: true,
    options: {
      filterOptions: [
        { label: 'Videos', value: 'video' },
        { label: 'Reels', value: 'reel' },
        { label: 'Photos', value: 'image' },
        { label: 'Carousels', value: 'carousel' },
      ],
    },
  },
  prediction: {
    name: 'MediaFilterRadioWidget',
    label: 'Predicted Performance',
    icon: 'boosted',
    noDuplicate: true,
    options: {
      filterOptions: [
        { label: 'Top', value: 'top', labelForFilter: 'Top Performer' },
        { label: 'High', value: 'high', labelForFilter: 'High Performer' },
        { label: 'Top or High', value: 'top_high', labelForFilter: 'Top or High Performer' },
        { label: 'Low', value: 'low', labelForFilter: 'Low Performer' },
      ],
    },
  },
  hashtag: {
    name: 'MediaFilterHashtagWidget',
    label: 'Hashtag',
    icon: 'hash',
    escapeHtml: true,
  },
});
export const competitivePostsWidgetsForTwitter = Object.freeze({
  competitive_post_types: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Media Type',
    icon: 'media',
    noDuplicate: true,
    options: {
      filterOptions: [
        { label: 'Photo', value: 'photo' },
        { label: 'Video', value: 'video' },
        { label: 'Carousel', value: 'carousel' },
        { label: 'Text', value: 'text' },
        { label: 'Link', value: 'link' },
      ],
    },
  },
  tweet_keywords: {
    name: 'MediaFilterSearchWidget',
    label: 'Post',
    options: {
      placeholder: 'Enter keywords, #hashtags or @mentions',
    },
  },
});
export const competitivePostsWidgetsForYoutube = Object.freeze({
  competitive_post_types: {
    name: 'MediaFilterCheckboxWidget',
    label: 'Media Type',
    noDuplicate: true,
    options: {
      filterOptions: [
        { label: 'Live', value: 'LIVE' },
        { formattedLabel: 'On-demand', label: 'On-demand', value: 'ON_DEMAND' },
        { label: 'Shorts', value: 'SHORTS' },
      ],
    },
  },
  title_or_description: {
    name: 'MediaFilterSearchWidget',
    label: 'Title or Description',
    options: {
      placeholder: 'Enter keywords or #hashtags',
    },
  },
});
const competitiveSourceToPostsWidgets = Object.freeze({
  [competitiveSources.INSTAGRAM]: competitivePostsWidgetsForInstagram,
  [competitiveSources.TWITTER]: competitivePostsWidgetsForTwitter,
  [competitiveSources.YOUTUBE]: competitivePostsWidgetsForYoutube,
});

export const predictionLabelToRange = Object.freeze({
  top: [1, engagementRating.excellent],
  high: [engagementRating.excellent, engagementRating.good],
  top_high: [1, engagementRating.good],
  low: [engagementRating.good, 0],
});

const competitivePostsDateOptions = Object.freeze([
  { label: 'All Time', value: null },
  { label: 'Last 24 Hours', value: 1 },
  { label: 'Last 3 Days', value: 3 },
  { label: 'Last 7 Days', value: 7 },
  { label: 'Last 4 Weeks', value: 28 },
]);

const competitiveOverviewDateOptions = Object.freeze([
  { label: 'Last 24 Hours', value: 1 },
  { label: 'Last 3 Days', value: 3 },
  { label: 'Last 7 Days', value: 7 },
  { label: 'Last 4 Weeks', value: 28 },
]);

const competitivePostsDefaultSort = 'DATE';

const competitivePostsSortOptionsForInstagram = Object.freeze([
  { value: competitivePostsDefaultSort, label: 'Recently Added' },
  { value: 'INSTAGRAM_ORGANIC_COMMENTS', label: 'Comments', field: 'commentsCount' },
  { value: 'INSTAGRAM_ENGAGEMENT_RATE', label: 'Engagement Rate', field: 'engagement' },
  { value: 'INSTAGRAM_ORGANIC_LIKES', label: 'Likes', field: 'likeCount' },
]);

const competitivePostsSortOptionsForTwitter = Object.freeze([
  { value: competitivePostsDefaultSort, label: 'Recently Added' },
  { value: 'TWITTER_LIKES', label: 'Likes', field: 'likes' },
  { value: 'TWITTER_PUBLIC_ENGAGEMENTS', label: 'Public Engagements', field: 'engagementsTotal' },
  { value: 'TWITTER_QUOTE_TWEETS', label: 'Quote Posts', field: 'quoteTweets' },
  { value: 'TWITTER_REPLIES', label: 'Replies', field: 'replies' },
  { value: 'TWITTER_RETWEETS', label: 'Reposts', field: 'retweets' },
  { value: 'TWITTER_TOTAL_RETWEETS', label: 'Total Reposts', field: 'totalRetweets' },
  {
    value: 'TWITTER_VIDEO_VIEWS',
    label: 'Video Views',
    field: 'videoViewsTotal',
  },
]);

const competitivePostsSortOptionsForYoutube = Object.freeze([
  { value: competitivePostsDefaultSort, label: 'Recently Added' },
  { value: 'YOUTUBE_COMMENTS', label: 'Comments', field: 'comments' },
  { value: 'YOUTUBE_LIKES', label: 'Likes', field: 'likes' },
  { value: 'YOUTUBE_VIEWS', label: 'Video Views', field: 'views' },
]);

export const sourceToCompetitiveSortOptions = Object.freeze({
  [competitiveSources.INSTAGRAM]: competitivePostsSortOptionsForInstagram,
  [competitiveSources.TWITTER]: competitivePostsSortOptionsForTwitter,
  [competitiveSources.YOUTUBE]: competitivePostsSortOptionsForYoutube,
});

const competitiveOverviewChartConfigInstagram = Object.freeze({
  channels: 'INSTAGRAM_COMPETITIVE',
  legend: {
    BRAND: {
      color: '#4990E2',
      label: 'Brand - Public Data',
    },
    COMPETITOR_AVERAGE: {
      average: true,
      backgroundColor: colours.SUCCESS.SUCCESS_100,
      color: colours.SUCCESS.SUCCESS_500,
      label: 'Competitor Average',
      tooltip: 'The average of the specific metric based on your selected competitors',
    },
    BENCHMARK: {
      average: true,
      backgroundColor: colours.ALERT.ALERT_100,
      color: colours.ALERT.ALERT_500,
      label: 'Industry Benchmark',
      tooltip:
        'Industry benchmarks are an average—calculated from a curated set of brands within a specific industry',
    },
  },
  metrics: [
    {
      benchmarks: true,
      value: 'AVG_ENGAGEMENT_RATE_PUBLIC',
      label: 'Avg. Engagement Rate - Public',
      percent: true,
      tooltip:
        'The average engagement rate for organic posts published during the selected date range. This engagement rate uses public data only for brand and competitor data\n' +
        '\n' +
        'Engagement rate = (Likes + Comments) / Followers',
      default: true,
    },
    {
      benchmarks: false,
      value: 'TOTAL_ENGAGEMENTS',
      label: 'Engagements - Organic',
      percent: false,
      tooltip:
        'The total number of organic likes and comments on posts published during the selected date range',
    },
    {
      benchmarks: true,
      value: 'FOLLOWER_GROWTH_RATE',
      label: 'Followers - Growth Rate',
      percent: true,
      tooltip:
        "The percentage competitors' followers increased by during the Reporting Period\n" +
        '\n' +
        'Follower Growth Rate = (New Followers - Old Followers) / Old Followers x 100%',
    },
    {
      benchmarks: false,
      value: 'NET_NEW_FOLLOWERS',
      label: 'Followers - Net New',
      percent: false,
      tooltip:
        'The net total of followers your competitor account has accumulated during the selected date range',
    },
    {
      benchmarks: false,
      value: 'TOTAL_FOLLOWERS',
      label: 'Followers - Total',
      percent: false,
      tooltip:
        'The number of people who have followed your competitor Instagram account by the last day of the selected date range',
    },
    {
      benchmarks: true,
      value: 'NUMBER_OF_POSTS',
      label: 'Number of Posts',
      percent: false,
      tooltip:
        'The number of Reels, Videos, Photos, and Carousels published to your competitor Instagram account during the selected date range',
    },
  ],
  reportType: 'COMPETITIVE_MULTI_BRAND_METRIC',
  averages: ['COMPETITOR_AVERAGE', 'BENCHMARK'],
});

const competitiveOverviewChartConfigTwitter = {
  channels: 'TWITTER_COMPETITIVE',
  legend: {
    BRAND: {
      color: '#4990E2',
      label: 'Brand',
    },
    COMPETITOR_AVERAGE: {
      average: true,
      backgroundColor: colours.SUCCESS.SUCCESS_100,
      color: colours.SUCCESS.SUCCESS_500,
      label: 'Competitor Average',
      tooltip: 'The average of the specific metric based on your selected competitors',
    },
  },
  metrics: [
    {
      value: 'FOLLOWER_GROWTH_RATE',
      label: 'Followers - Growth Rate',
      percent: true,
      tooltip:
        "The percentage competitors' followers increased by during the Reporting Period\n" +
        '\n' +
        'Follower Growth Rate = (New Followers - Old Followers) / Old Followers x 100%',
    },
    {
      value: 'NET_NEW_FOLLOWERS',
      label: 'Followers - Net New',
      tooltip:
        'The net total of followers your competitor account has accumulated during the selected date range',
    },
    {
      value: 'TOTAL_FOLLOWERS',
      label: 'Followers - Total',
      tooltip:
        'The number of people who have followed your competitor X account by the last day of the selected date range',
    },
    {
      value: 'LIKES',
      label: 'Likes',
      tooltip: 'The number of likes your competitor posts received during the selected date range',
    },
    {
      value: 'TOTAL_ENGAGEMENTS',
      label: 'Public Engagements',
      tooltip:
        'The total number of times a user interacted with your competitor posts during the selected date range\n' +
        '\n' +
        'Public Engagements = Replies + Likes + Reposts + Quote Posts',
      default: true,
    },
    {
      value: 'NUMBER_OF_POSTS',
      label: 'Number of Posts',
      tooltip:
        'The number of carousel, video, link, and text posts and reposts published to your competitor X account during the selected date range',
    },
    {
      value: 'REPLIES',
      label: 'Replies',
      tooltip:
        'The number of replies your competitor posts received during the selected date range',
    },
    {
      value: 'TOTAL_RETWEETS',
      label: 'Total Reposts',
      tooltip:
        'The total number of times your competitor posts were reposted or quote posted during the selected date range',
    },
    {
      value: 'VIDEO_VIEWS',
      label: 'Video Views',
      tooltip:
        'The number of video views your competitor posts accumulated during the selected date range',
    },
  ],
  reportType: 'COMPETITIVE_MULTI_BRAND_METRIC',
  averages: ['COMPETITOR_AVERAGE'],
};

const competitiveOverviewChartConfigYouTube = {
  channels: 'YOUTUBE_COMPETITIVE',
  legend: {
    BRAND: {
      color: '#4990E2',
      label: 'Brand',
    },
    COMPETITOR_AVERAGE: {
      average: true,
      backgroundColor: colours.SUCCESS.SUCCESS_100,
      color: colours.SUCCESS.SUCCESS_500,
      label: 'Competitor Average',
      tooltip: 'The average of the specific metric based on your selected competitors',
    },
  },
  metrics: [
    {
      value: 'SUBSCRIBERS_NET_NEW',
      label: 'Net New Subscribers',
      tooltip:
        'The net total of subscribers your competitor channel has accumulated during the selected date range',
    },
    {
      value: 'NUMBER_OF_POSTS',
      label: 'Number of Videos',
      tooltip:
        'The number of public on-demand, Shorts and live videos published to your competitor YouTube channel during the selected date range',
    },
    {
      value: 'SUBSCRIBERS_TOTAL',
      label: 'Total Subscribers',
      tooltip:
        'The total number of people who have subscribed to your competitor channel by the last day of the selected date range',
    },
    {
      value: 'TOTAL_VIDEO_VIEWS',
      label: 'Video Views',
      tooltip:
        'The total number of video views your competitor channel has accumulated during the selected date range regardless of publish date',
      default: true,
    },
  ],
  reportType: 'COMPETITIVE_MULTI_BRAND_METRIC',
  averages: ['COMPETITOR_AVERAGE'],
};

const competitiveOverviewChartConfig = Object.freeze({
  [competitiveSources.INSTAGRAM]: competitiveOverviewChartConfigInstagram,
  [competitiveSources.TWITTER]: competitiveOverviewChartConfigTwitter,
  [competitiveSources.YOUTUBE]: competitiveOverviewChartConfigYouTube,
});

const defaultSelectedBenchmark = Object.freeze({
  name: 'All Industries',
  follower_bucket: 'BUCKET_OVERALL',
  id: null,
  label: 'All Industries',
});

export const postNavNaming = Object.freeze({
  [competitiveSources.INSTAGRAM]: 'Competitor Posts',
  [competitiveSources.YOUTUBE]: 'Competitor Videos',
  [competitiveSources.TWITTER]: 'Competitor Posts',
});

export const competitiveSourceToRouteOverview = Object.freeze({
  [competitiveSources.INSTAGRAM]: 'competitors.instagram.competitorOverview',
  [competitiveSources.TWITTER]: 'competitors.twitter.competitorOverview',
  [competitiveSources.YOUTUBE]: 'competitors.youtube.competitorOverview',
});

export const competitiveRouteNames = Object.freeze({
  instagram: {
    main: 'competitors.instagram',
    overview: competitiveSourceToRouteOverview[competitiveSources.INSTAGRAM],
    posts: 'competitors.instagram.competitorPosts',
    viq: {
      main: 'competitors.instagram.viq.competitive',
      detail: 'competitors.instagram.viq.competitive.detail',
      trends: {
        main: 'competitors.instagram.viq.trends',
        new: 'competitors.instagram.viq.trend.new',
      },
      trend: {
        main: 'competitors.instagram.viq.trend',
        edit: 'competitors.instagram.viq.trend.edit',
      },
    },
    galleries: {
      main: 'competitors.instagram.galleries',
      new: 'competitors.instagram.galleries.new',
    },
    boards: {
      main: 'competitors.instagram.galleries.board',
      edit: 'competitors.instagram.galleries.edit',
      report: 'competitors.instagram.galleries.boards.report',
    },
  },
  twitter: {
    main: 'competitors.twitter',
    overview: competitiveSourceToRouteOverview[competitiveSources.TWITTER],
    posts: 'competitors.twitter.competitorPosts',
    galleries: {
      main: 'competitors.twitter.galleries',
      new: 'competitors.twitter.galleries.new',
    },
    boards: {
      main: 'competitors.twitter.galleries.board',
      edit: 'competitors.twitter.galleries.edit',
      report: 'competitors.twitter.galleries.boards.report',
    },
  },
  youtube: {
    main: 'competitors.youtube',
    overview: competitiveSourceToRouteOverview[competitiveSources.YOUTUBE],
    posts: 'competitors.youtube.competitorPosts',
    galleries: {
      main: 'competitors.youtube.galleries',
      new: 'competitors.youtube.galleries.new',
    },
    boards: {
      main: 'competitors.youtube.galleries.board',
      edit: 'competitors.youtube.galleries.edit',
      report: 'competitors.youtube.galleries.boards.report',
    },
  },
});

const topPerformingMediaSortFields = Object.freeze({
  [competitiveSources.INSTAGRAM]: '-INSTAGRAM_ENGAGEMENT_RATE',
  [competitiveSources.TWITTER]: '-TWITTER_PUBLIC_ENGAGEMENTS',
  [competitiveSources.YOUTUBE]: '-YOUTUBE_VIEWS',
});

const totalPostsSeeMoreRoutes = Object.freeze({
  [competitiveSources.INSTAGRAM]: {
    name: competitiveRouteNames.instagram.posts,
    query: {
      sort: topPerformingMediaSortFields.INSTAGRAM,
    },
  },
  [competitiveSources.TWITTER]: {
    name: competitiveRouteNames.twitter.posts,
    query: {
      sort: topPerformingMediaSortFields.TWITTER,
    },
  },
  [competitiveSources.YOUTUBE]: {
    name: competitiveRouteNames.youtube.posts,
    query: {
      sort: topPerformingMediaSortFields.YOUTUBE,
    },
  },
});

const quotaLimitErrorMessage =
  'YouTube channel search quota has been reached.\nTry again in 24 hours.';

const competitiveSourceToBrandMediaTypes = Object.freeze({
  [competitiveSources.INSTAGRAM]: [
    enumTypes.INSTAGRAM_UGC,
    enumTypes.INSTAGRAM_UGC_IGTV,
    enumTypes.INSTAGRAM_OTHER,
    enumTypes.INSTAGRAM_OTHER_IGTV,
  ],
  [competitiveSources.TWITTER]: [
    enumTypes.TWITTER_OTHER,
    enumTypes.TWITTER_OTHER_LINK,
    enumTypes.TWITTER_OTHER_TEXT,
  ],
  [competitiveSources.YOUTUBE]: [enumTypes.YOUTUBE_OTHER],
});

const visualSearchMediaBrandTypes = Object.freeze({
  [competitiveSources.INSTAGRAM]: [enumTypes.INSTAGRAM_OTHER, enumTypes.INSTAGRAM_OTHER_IGTV],
  [competitiveSources.TWITTER]: [enumTypes.TWITTER_OTHER],
  [competitiveSources.YOUTUBE]: [],
});

const topPerformingMediaMetricsForInstagram = Object.freeze({
  labels: {
    engagement: 'Engagement Rate',
    likeCount: 'Likes',
    commentsCount: 'Comments',
  },
  metrics: ['engagement', 'likeCount', 'commentsCount'],
});

const topPerformingMediaMetricsForTwitter = Object.freeze({
  labels: {
    replies: 'Replies',
    totalRetweets: 'Total Reposts',
    likes: 'Likes',
    engagementsTotal: 'Public Engagements',
  },
  metrics: ['replies', 'totalRetweets', 'likes', 'engagementsTotal'],
});

const topPerformingMediaMetricsForYoutube = Object.freeze({
  labels: {
    views: 'Video Views',
    likes: 'Likes',
    comments: 'Comments',
  },
  metrics: ['views', 'likes', 'comments'],
});

export const topPerformingMediaMetrics = Object.freeze({
  [competitiveSources.INSTAGRAM]: topPerformingMediaMetricsForInstagram,
  [competitiveSources.TWITTER]: topPerformingMediaMetricsForTwitter,
  [competitiveSources.YOUTUBE]: topPerformingMediaMetricsForYoutube,
});

function getGridConfig(metrics) {
  const height = metrics.length === 3 ? '25rem' : '26rem';
  return {
    numMediaStats: metrics.length,
    minItemHeight: height,
  };
}

export const competitiveSourceToGridConfig = Object.freeze({
  [competitiveSources.INSTAGRAM]: getGridConfig(topPerformingMediaMetricsForInstagram.metrics),
  [competitiveSources.TWITTER]: getGridConfig(topPerformingMediaMetricsForTwitter.metrics),
  [competitiveSources.YOUTUBE]: getGridConfig(topPerformingMediaMetricsForYoutube.metrics),
});

export const competitiveSourceHasBenchmarks = Object.freeze({
  [competitiveSources.INSTAGRAM]: true,
  [competitiveSources.TWITTER]: false,
  [competitiveSources.YOUTUBE]: false,
});

export const competitiveOverviewNumberBrandsShown = 15;

export const instagramCompetitorIndustryMap = Object.freeze({
  'All Industries': {
    nytimes: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/1624593034.jpeg',
      target_source_id: '1624593034',
    },
    target: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/209372398.jpeg',
      target_source_id: '209372398',
    },
    charlottetilbury: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/176465776.jpeg',
      target_source_id: '176465776',
    },
  },
  Automotive: {
    ford: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/215007312.jpeg',
      target_source_id: '215007312',
    },
    jeep: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/44173580.jpeg',
      target_source_id: '44173580',
    },
    lexususa: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/30968697.jpeg',
      target_source_id: '30968697',
    },
  },
  Beauty: {
    esteelauder: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/14293571.jpeg',
      target_source_id: '14293571',
    },
    charlottetilbury: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/176465776.jpeg',
      target_source_id: '176465776',
    },
    soldejaneiro: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/1785626724.jpeg',
      target_source_id: '1785626724',
    },
  },
  'Consumer Electronics': {
    dyson: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/477945858.jpeg',
      target_source_id: '477945858',
    },
    microsoft: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/524549267.jpeg',
      target_source_id: '524549267',
    },
    breville: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/2067855.jpeg',
      target_source_id: '2067855',
    },
  },
  'Consumer Packaged Goods': {
    harrys: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/285962650.jpeg',
      target_source_id: '285962650',
    },
    benandjerrys: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/2024495.jpeg',
      target_source_id: '2024495',
    },
    dove: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/361603874.jpeg',
      target_source_id: '361603874',
    },
  },
  Cosmetics: {
    lancomeofficial: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/1652548021.jpeg',
      target_source_id: '1652548021',
    },
    kyliecosmetics: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/2223304785.jpeg',
      target_source_id: '2223304785',
    },
    benefitcosmetics: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/2339286.jpeg',
      target_source_id: '2339286',
    },
  },
  Fashion: {
    jcrew: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/183814919.jpeg',
      target_source_id: '183814919',
    },
    abercrombie: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/23788300.jpeg',
      target_source_id: '23788300',
    },
    cosstores: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/392125511.jpeg',
      target_source_id: '392125511',
    },
  },
  'Financial Services': {
    americanexpress: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/7063352.jpeg',
      target_source_id: '7063352',
    },
    bankofamerica: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/175865077.jpeg',
      target_source_id: '175865077',
    },
    wealthsimple: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/1495266683.jpeg',
      target_source_id: '1495266683',
    },
  },
  'Food & Beverage': {
    mcdonalds: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/18343150.jpeg',
      target_source_id: '18343150',
    },
    heineken: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/264204243.jpeg',
      target_source_id: '264204243',
    },
    starbucks: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/1034466.jpeg',
      target_source_id: '1034466',
    },
  },
  Fragrance: {
    malinandgoetz: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/187499509.jpeg',
      target_source_id: '187499509',
    },
    jomalonelondon: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/319827075.jpeg',
      target_source_id: '319827075',
    },
    diorbeauty: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/2463047524.jpeg',
      target_source_id: '2463047524',
    },
  },
  Haircare: {
    olaplex: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/1102517084.jpeg',
      target_source_id: '1102517084',
    },
    bumbleandbumble: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/182125955.jpeg',
      target_source_id: '182125955',
    },
    redken: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/2089417202.jpeg',
      target_source_id: '2089417202',
    },
  },
  Home: {
    ruggable: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/1419058951.jpeg',
      target_source_id: '1419058951',
    },
    potterybarn: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/179019990.jpeg',
      target_source_id: '179019990',
    },
    crateandbarrel: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/21818262.jpeg',
      target_source_id: '21818262',
    },
  },
  Kids: {
    mattel: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/1427814426.jpeg',
      target_source_id: '1427814426',
    },
    coterie: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/40995274.jpeg',
      target_source_id: '40995274',
    },
    babylist: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/1176158404.jpeg',
      target_source_id: '1176158404',
    },
  },
  Luxury: {
    prada: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/304662892.jpeg',
      target_source_id: '304662892',
    },
    chanelofficial: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/695995017.jpeg',
      target_source_id: '695995017',
    },
    burberry: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/1889387.jpeg',
      target_source_id: '1889387',
    },
  },
  Media: {
    espn: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/1320207.jpeg',
      target_source_id: '1320207',
    },
    netflix: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/207587378.jpeg',
      target_source_id: '207587378',
    },
    todayshow: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/5814140.jpeg',
      target_source_id: '5814140',
    },
  },
  Publishing: {
    vogue: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/4329108192.jpeg',
      target_source_id: '4329108192',
    },
    nytimes: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/1624593034.jpeg',
      target_source_id: '1624593034',
    },
    foodandwine: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/181415118.jpeg',
      target_source_id: '181415118',
    },
  },
  Retail: {
    nordstrom: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/25501780.jpeg',
      target_source_id: '25501780',
    },
    target: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/209372398.jpeg',
      target_source_id: '209372398',
    },
    homedepot: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/29740022.jpeg',
      target_source_id: '29740022',
    },
  },
  Skincare: {
    kiehls: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/448044564.jpeg',
      target_source_id: '448044564',
    },
    clinique: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/409035188.jpeg',
      target_source_id: '409035188',
    },
    skinceuticals: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/218238660.jpeg',
      target_source_id: '218238660',
    },
  },
  Travel: {
    united: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/238426677.jpeg',
      target_source_id: '238426677',
    },
    tripadvisor: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/248181152.jpeg',
      target_source_id: '248181152',
    },
    airbnb: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/639837.jpeg',
      target_source_id: '639837',
    },
  },
  Wedding: {
    brides: {
      avatar_url: 'https://s3-us-west-2.amazonaws.com/dashhudson-static/avatars/avatar-brand.png',
      target_source_id: '22840238',
    },
    theknot: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/40045773.jpeg',
      target_source_id: '40045773',
    },
    weddingwire: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/35825261.jpeg',
      target_source_id: '35825261',
    },
  },
  Wellness: {
    goop: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/16168781.jpeg',
      target_source_id: '16168781',
    },
    sakaralife: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/210441328.jpeg',
      target_source_id: '210441328',
    },
    drinkag1: {
      avatar_url: 'https://cdn.dashhudson.com/avatars/266820911.jpeg',
      target_source_id: '266820911',
    },
  },
});

export default {
  competitiveChannelSelector,
  competitiveCompetitorWidget,
  competitiveOverviewChartConfig,
  competitiveOverviewDateOptions,
  competitivePostsDateOptions,
  competitivePostsDefaultSort,
  competitiveSourceToPostsFilterOptions,
  competitiveSourceToPostsWidgets,
  competitiveSources,
  topPerformingMediaMetrics,
  toolTips,
  predictionLabelToRange,
  defaultSelectedBenchmark,
  callToActionLabels,
  quotaLimitErrorMessage,
  totalPostsSeeMoreRoutes,
  topPerformingMediaSortFields,
  competitiveSourceToBrandMediaTypes,
  sourceToCompetitiveFilters,
  competitiveSourceHasBenchmarks,
  visualSearchMediaBrandTypes,
};
