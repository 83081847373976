<template>
  <div class="dropzone-container">
    <Dropzone
      ref="dropzone"
      :on-accept="onAccept"
      :on-error="onError"
      :on-added-file="onAddedFile"
      :on-preview-generated="onPreviewGenerated"
      :on-upload-progress="onUploadProgress"
      :on-success="onSuccess"
      :on-converted="onConverted"
      :accept-video="acceptVideo"
      :thumbnail="false"
      class="dropzone-area"
    >
      <div class="dropzone-message" @click="openFileDialog">
        <Icon
          :color="colours.BACKGROUND.BACKGROUND_400"
          name="addImageFile"
          height="60"
          width="60"
        />
        <h4 class="dropzone-title">Select media to create new pins</h4>
        <div v-if="hasLibraryAccess">
          <Button class="button" primary data-cy="select-media" @click.stop="openMediaPopup">
            Select From Library
          </Button>
          <hr />
        </div>

        <p>
          You can
          <span v-if="hasLibraryAccess">also</span>
          drag and drop to add media
          <br />
          or
          <span class="blue">
            <a>select from your computer</a>
          </span>
        </p>
      </div>
    </Dropzone>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { colours } from '@/ux/colours';
import Dropzone from '@/app/scheduler/components/EditPost/MediaViewer/Dropzone.vue';
import Icon from '@/components/foundation/Icon.vue';
import Button from '@/components/foundation/Button.vue';
import { useAuthStore } from '@/stores/auth';
import { BRAND } from '@/models/auth/permissions.enum';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'DropzoneContainer',
  components: {
    Button,
    Dropzone,
    Icon,
  },
  props: {
    acceptVideo: { type: Boolean, default: false },
    displayErrorMessage: { type: Function, default: null },
    openMediaPopup: { type: Function, default: null },
    mediaRemoved: { type: Function, default: null },
    onAddedFile: { type: Function, default: null },
    onError: { type: Function, default: null },
    onPreviewGenerated: { type: Function, default: null },
    onAccept: { type: Function, default: null },
    onUploadProgress: { type: Function, default: null },
    onSuccess: { type: Function, default: null },
    onConverted: { type: Function, default: null },
  },
  data() {
    return {
      media: [],
    };
  },
  computed: {
    ...mapStores(useAuthStore),
    colours() {
      return colours;
    },
    hasLibraryAccess() {
      return this.authStore.guard(BRAND.LIBRARY.CAN_ACCESS_LIBRARY);
    },
  },
  methods: {
    openFileDialog() {
      this.$refs.dropzone.openFileDialog();
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.dropzone-container {
  padding: var(--space-32);
  height: 100%;
}

.dropzone-area {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
  border: 2px dashed var(--border);
  border-radius: var(--round-corner-small);
  background-color: var(--background-0);
  height: 100%;
  cursor: pointer;

  &:hover {
    background: var(--action-100);
    border-color: var(--action-500);
  }
}

.dropzone-message {
  text-align: center;

  .button {
    height: var(--space-32);
    margin: 0 auto;
    padding: 0 var(--space-48);
    cursor: pointer;
  }

  h4 {
    text-transform: none;
    color: var(--text-primary);
    font-weight: var(--font-medium);
    margin: var(--space-32) auto;
  }

  hr {
    width: 100%;
    margin: var(--space-40) auto;
  }

  a {
    text-decoration: underline;
  }

  p {
    font-weight: var(--font-medium);
    line-height: var(--space-28);
    margin-bottom: var(--space-80);
  }
}
</style>
