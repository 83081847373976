<template>
  <div class="text-alert" :style="{ 'background-color': backgroundColor }">
    <Icon v-if="iconName" :color="iconColor" :name="iconName" small />
    <span>
      {{ alertMessage }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'TextAlert',
  components: {
    Icon,
  },
  props: {
    iconColor: { type: String, default: colours.ICON.ICON_SECONDARY },
    iconName: { type: String, default: null },
    alertMessage: { type: String, default: '' },
    backgroundColor: { type: String, default: colours.BACKGROUND.BACKGROUND_300 },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.text-alert {
  display: flex;
  flex-flow: row nowrap;
  padding: var(--space-24);
  border-radius: var(--round-corner-small);

  span {
    font-size: 0.875rem;
    text-align: left;
  }

  .icon {
    flex: 0 0 auto;
    margin-right: var(--space-16);
    margin-top: 0.2rem;
  }
}
</style>
