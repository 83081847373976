const REPORTING_PERIOD_FILTERS = Object.freeze({
  LAST_24_HOURS: {
    value: 'LAST_24_HOURS',
    text: 'last 24 hours',
  },
  LAST_7_DAYS: {
    value: 'LAST_7_DAYS',
    text: 'Last 7 Days',
  },
  LAST_WEEK: {
    value: 'LAST_WEEK',
    text: 'Last Week',
  },
  LAST_MONTH: {
    value: 'LAST_MONTH',
    text: 'Last Month',
  },
  CUSTOM: {
    value: 'CUSTOM',
    text: 'Custom',
  },
});

const COMPARISON_PERIOD_FILTERS = Object.freeze({
  PREVIOUS_24_HOURS: {
    value: 'PREVIOUS_24_HOURS',
    text: 'Previous 24 hours',
  },
  PREVIOUS_7_DAYS: {
    value: 'PREVIOUS_7_DAYS',
    text: 'Previous 7 Days',
  },
  PREVIOUS_WEEKS: {
    value: 'PREVIOUS_WEEKS',
    text: 'Previous Weeks',
  },
  PREVIOUS_MONTH: {
    value: 'PREVIOUS_MONTH',
    text: 'Previous Month',
  },
  CUSTOM: {
    value: 'CUSTOM',
    text: 'Custom',
  },
});

export default { REPORTING_PERIOD_FILTERS, COMPARISON_PERIOD_FILTERS };
