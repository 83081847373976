<template>
  <span v-if="reviewStatus" class="review-label" :class="reviewStatusConfig.style">
    <Icon
      v-if="reviewStatusConfig.icon"
      :name="reviewStatusConfig.icon"
      :color="reviewStatusConfig.iconColor"
      xsmall
    />
    {{ reviewStatusConfig.label }}
  </span>
</template>

<script>
import { defineComponent } from 'vue';
import { productReviewStatusLabels, productReviewStatusTypes } from '@/app/library/constants';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';

const reviewStatusOptions = {
  [productReviewStatusTypes.REJECTED]: {
    label: productReviewStatusLabels.REJECTED,
    style: 'review-red',
    icon: 'rejectedCircle',
    iconColor: colours.ERROR.ERROR_500,
  },
};

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  components: {
    Icon,
  },
  props: {
    reviewStatus: {
      type: String,
      default: null,
      validator(value) {
        if (!value) {
          return true;
        }
        return Object.keys(reviewStatusOptions).includes(value);
      },
    },
  },
  computed: {
    reviewStatusConfig() {
      return reviewStatusOptions[this.reviewStatus];
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.review-label {
  font-size: var(--x12);
  font-weight: var(--font-medium);

  :deep(.icon) {
    position: relative;
    top: 3px;
  }
}

.review-red {
  color: var(--error-500);
}
</style>
