<template>
  <div class="chart-stat">
    <div v-if="insertDivider" class="stat-divider" :class="{ 'm-0': thumbnailMode }" />
    <div class="stat-title" :class="{ 'text-[5px]': thumbnailMode }">
      <span>{{ title }}</span>
    </div>
    <div v-tooltip="tooltip" class="stat-value" :class="{ 'ml-0.5 text-[5px]': thumbnailMode }">
      {{ value }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ChartStatistic',
  inject: ['thumbnailMode'],
  props: {
    insertDivider: { type: Boolean, default: false },
    title: { type: String, required: true },
    value: { type: String, required: true },
    tooltipValue: { type: String, default: '' },
  },
  computed: {
    tooltip() {
      if (this.tooltipValue) {
        return { content: this.tooltipValue, placement: 'bottom-start' };
      }
      return null;
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.chart-stat {
  align-items: center;
  display: flex;
  justify-content: flex-start;

  .stat-divider {
    border-left: 1px solid var(--border);
    box-sizing: border-box;
    margin: 0 var(--space-14);
    min-height: 15px;
    width: 1px;
  }

  .stat-title {
    color: var(--text-primary);
    font-size: var(--x14);
    min-width: 0;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .stat-value {
    color: var(--action-500);
    font-size: var(--x14);
    font-weight: var(--font-medium);
    margin-left: var(--space-8);
  }
}
</style>
