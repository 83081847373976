<template>
  <div class="embedded-content-container">
    <iframe
      id="embedded-content-iframe"
      v-bind="iframeAttributes"
      :class="{ 'hidden-content': !isRenderingFinished }"
      :style="styles"
      :src="srcUrl"
      @load="isRenderingFinished = true"
    />
    <slot />
    <CircularLoader v-if="!isRenderingFinished" />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useActiveElement } from '@vueuse/core';
import CircularLoader from '@/components/CircularLoader.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'EmbedPreviewMediaView',
  components: {
    CircularLoader,
  },
  props: {
    srcUrl: { type: String, required: true },
    iframeAttributes: { type: Object, default: null },
    styles: { type: Object, default: null },
  },
  emits: ['iframeClicked'],
  data() {
    return {
      isRenderingFinished: false,
      activeElement: useActiveElement(),
    };
  },
  watch: {
    activeElement(newEl, prevEl) {
      if (prevEl.tagName !== newEl.tagName && newEl.tagName === 'IFRAME') {
        this.handleEvent();
      }
    },
  },
  methods: {
    handleEvent() {
      this.$emit('iframeClicked');
    },
  },
});

export default comp;
</script>

<style lang="postcss" scoped>
.embedded-content-container {
  position: relative;
  top: 0;
  left: 0;

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .hidden-content {
    opacity: 0;
  }
}
</style>
