<template>
  <EmbedPreviewMediaView class="embed-preview-media-view" :styles="styles" :src-url="embedUrl">
    <a
      class="tiktok-media-overlap absolute bottom-0 h-full cursor-pointer"
      :href="tiktokLink"
      target="_blank"
      rel="noopener"
    />
  </EmbedPreviewMediaView>
</template>

<script>
import { defineComponent } from 'vue';
import EmbedPreviewMediaView from '@/app/library/components/MediaPopup/EmbedPreviewMediaView.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'TikTokEmbed',
  components: { EmbedPreviewMediaView },
  props: {
    mediaItem: { type: Object, required: true },
  },
  computed: {
    embedUrl() {
      /*
      We are using an alternative embed URL as a workaround for an issue where
      some video would not play automatically, and some would. We don't want to
      save this URL in the DB in case we encounter issues with it in the future.
      See sc113691 for more details.
       */
      return `https://www.tiktok.com/static/profile-video?id=${this.mediaItem.postSourceId}`;
    },
    mediaHeightWidth() {
      const height = this.mediaItem?.originalHeight;
      const width = this.mediaItem?.originalWidth;
      if (!height || !width) {
        return { height: 830, width: 325 };
      }

      if (height > width) {
        return { height: 325 * (height / width), width: 325 };
      }
      return { height: 325, width: 325 * (width / height) };
    },
    styles() {
      return {
        width: `${this.mediaHeightWidth.width}px`,
        height: `${this.mediaHeightWidth.height}px`,
        'border-radius': '8px',
      };
    },
    tiktokLink() {
      return this.mediaItem?.postUrl ?? this.mediaItem?.embedUrl;
    },
    tiktokOverlayWidth() {
      return `${document.querySelector('#embedded-content-iframe').clientWidth}px`;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.embedded-content-container {
  display: flex;
  justify-content: center;

  .tiktok-media-overlap {
    width: v-bind(tiktokOverlayWidth);
  }
}
</style>
