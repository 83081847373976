/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'system': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.34 3.75c.456 0 .846.163 1.172.488.325.326.488.716.488 1.172v10.84c0 .456-.163.846-.488 1.172a1.598 1.598 0 01-1.172.488h-7.5v1.68h3.32v1.66H7.84v-1.66h3.32v-1.68h-7.5c-.456 0-.846-.163-1.172-.488A1.598 1.598 0 012 16.25V5.41c0-.456.163-.846.488-1.172A1.598 1.598 0 013.66 3.75h16.68zm0 1.66H3.66v8.34h16.68V5.41zM3.66 16.25h16.68v-.84H3.66v.84z" _fill="#ADADAD"/>'
  }
})
