/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrowRight': {
    width: 7,
    height: 12,
    viewBox: '0 0 7 12',
    data: '<path pid="0" d="M.339 1.707A1 1 0 011.753.293l4.954 4.954a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414l4.293-4.293L.339 1.707z" _fill="#ADADAD"/><mask id="svgicon_arrowRight_a" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="7" height="12"><path pid="1" d="M.339 1.707A1 1 0 011.753.293l4.954 4.954a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414l4.293-4.293L.339 1.707z" _fill="#fff"/></mask>'
  }
})
