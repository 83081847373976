<template>
  <VDropdown :shown="popupText && showPopup" :triggers="[]" class="full-width" placement="top">
    <div :class="{ disabled: disabled }" class="label" @click="copyContent">
      <input ref="fieldToCopy" data-cy="field-to-copy" :value="value" type="text" readonly />
      <Icon name="fileCopy" small />
    </div>
    <template #popper>
      <p class="left-content">{{ popupText }}</p>
      <a v-close-popper class="top-right-icon">
        <Icon :color="iconColor" name="close" xxsmall />
      </a>
    </template>
  </VDropdown>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import { useNotificationStore } from '@/stores/notification';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'CopyField',
  components: {
    Icon,
  },
  props: {
    popupText: { type: String, default: null },
    showPopup: { type: Boolean, default: false },
    value: { type: String, default: null },
    disabled: { type: Boolean, default: false },
  },
  emits: ['copied'],
  computed: {
    ...mapStores(useNotificationStore, useTrackingStore),
    iconColor() {
      return colours.ICON.ICON_PRIMARY;
    },
  },
  methods: {
    copyContent() {
      if (this.disabled) {
        return;
      }
      this.$refs.fieldToCopy.select();
      document.execCommand('copy');
      this.notificationStore.setToast({ message: 'Copied!' });

      if (this.$route.path.includes('relationships')) {
        this.sendRelationshipMixpanelEvent();
      }

      this.$emit('copied');
    },
    sendRelationshipMixpanelEvent() {
      this.trackingStore.track('Relationship Copy Invite', {
        page: this.$route.path,
        accountType: this.$parent?.$vnode?.context?.relationship?.user?.isBusiness
          ? 'Professional'
          : 'Personal',
        influencer: this.$parent?.$vnode?.context?.relationship?.user?.handle,
      });
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.label {
  width: 100%;
  padding: 0 var(--space-16);
  height: var(--space-40);
  border: solid 1px var(--border);
  border-radius: var(--round-corner-small);
  background: var(--background-300);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--x14);

  input {
    width: 100%;
    background-color: transparent;
    border: none;
    font-size: var(--x14);
    color: var(--text-primary);
    cursor: pointer;
  }

  svg {
    flex: 0 0 auto;
  }
}

.disabled {
  input,
  svg {
    cursor: not-allowed;
  }
}

.top-right-icon {
  position: absolute;
  top: var(--space-12);
  right: var(--space-12);
}

.left-content {
  padding-right: var(--space-28);
  transform: translateY(-0.125rem);
  width: 400px;
}
</style>
