<template>
  <div class="document-list">
    <div v-on-click-outside="handleClickOutside" class="document-list-dropdown">
      <ul>
        <li v-for="document in documentList" :key="document.id" @click="onSelectDocument(document)">
          <div>
            <a class="figure-blue">{{ document.termLink }}</a>
          </div>
          <div>
            <span>{{ document.displayName }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { vOnClickOutside } from '@vueuse/components';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'DocumentList',
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    documentList: { type: Array, required: true },
  },
  emits: ['onClickOutside', 'onSelectDocument'],
  data() {
    return {};
  },
  methods: {
    handleClickOutside() {
      this.$emit('onClickOutside');
    },
    onSelectDocument(document) {
      this.$emit('onSelectDocument', document);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.document-list {
  position: relative;

  :deep(.figure-blue) {
    color: var(--action-500);
  }

  &-dropdown {
    color: var(--text-primary);
    width: 25.625rem;
    box-shadow: var(--heavy-shadow);
    border-radius: var(--round-corner-small);
    position: absolute;
    left: 0;
    height: auto;
    z-index: var(--z-index-dropdown);
    transition: var(--transition-all);

    ul {
      height: auto;

      li {
        height: 3.575rem;
        width: 25.625rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: var(--space-24);
        background: var(--background-0);
        border-width: 0 1px 1px;
        border-style: solid;
        border-color: #ddd;
        align-items: flex-start;

        span {
          display: inline-block;
          width: 22rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      li:hover {
        background: var(--background-300);
      }

      li:first-child {
        border-top: 1px solid #ddd;
        border-radius: var(--round-corner-small) var(--round-corner-small) 0 0;
      }

      li:last-child {
        border-radius: 0 0 var(--round-corner-small) var(--round-corner-small);
      }
    }
  }
}
</style>
