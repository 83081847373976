<template>
  <div class="find-media-popup">
    <div :class="{ 'brand-select-enabled': visualFilterSearch }">
      <ImageDropdownSelect
        v-if="visualFilterSearch"
        :value="selectedBrandId"
        :options="brandOptions"
        option-value="id"
        option-text="name"
        option-image
        bold
        :min-picker-width="brandSelectWidth"
        :max-picker-width="brandSelectWidth"
        class="brand-select"
        @input="setBrandId"
      />
      <SearchBar
        v-if="!widgetMediaFilter"
        class="search-bar"
        :default-placeholder="placeholder"
        @submit-search="updateSearchTerm"
        @image-search="updateSearchImage"
      />
    </div>
    <section class="control" :class="{ 'no-top-margin': widgetMediaFilter }">
      <div class="flex items-center">
        <Select
          v-if="(limitSource && !limitUgcOrOwnedMedia) || limitToCompetitiveSearch"
          :dropdown-label="sourceLabel"
          :model-value="source"
          :options="sourceOptions"
          :disabled="sourceDisabled"
          @selected="sourceChanged"
        />
        <FilterGroup
          data-test="find-media-filter-group"
          :show-date-published="false"
          :source-value="{ type, platform, hide: hideFilterGroup }"
          :limit-source="limitSource"
          :date-value="{ startDate, endDate }"
          :media-type-limit="mediaTypeLimit"
          :filter-value="{
            aspectRatio,
            mediaType,
            size,
            color,
            contentRights,
            performance,
            showDuplicateMedia,
          }"
          @source-dropdown-toggled="sourceDropdownToggled"
          @source-filter-selected="updateSourceFilter"
          @more-filter-selected="updateMoreFilter"
          @date-filter-selected="updateDateFilter"
        />
        <MultiSelectFilter
          v-if="showContentTagFilter"
          v-model:selected-items="contentTags"
          class="content-tags"
          icon-name="tag"
          label="Content Tag"
          :items="contentTagItems"
          preposition=""
          conjunction=""
          show-dropdown-icon
          show-numbered-description
          confirm-button-text="See Results"
          more-vertical-padding
          @update:selected-items="updateContentTags"
        />
      </div>

      <div class="select-wrapper">
        <TimeFilterGroup
          :date-value="{ startDate, endDate }"
          :filter-value="{ aspectRatio, mediaType, size, color, contentRights, performance }"
          :sort-options="sortOptions"
          :sort="sort"
          page="FindMedia"
          @update-result="updateResult"
          @update-sort="updateSort"
          @date-filter-selected="updateDateFilter"
        />
      </div>
    </section>

    <section class="gallery">
      <MediaLayout
        v-if="popupSearchStore.mediaSearchList.length > 0"
        ref="mediaContainer"
        :items="popupSearchStore.formattedMediaSearchList"
        :row-height="160"
        :drag-select="{
          enabled: enableDragSelect,
          popup: true,
          scrollableAreaSelector: '.select-media-scrollable-area',
        }"
      >
        <template #default="{ item, width, height }">
          <MediaTile
            :tile-type="tileType"
            context="mediaSelectPopup"
            :item="item"
            :width="width"
            :height="height"
            :allow-mixed-media="allowMixedMedia"
            :media-count-limit="mediaCountLimit"
            :video-count-limit="videoCountLimit"
            :can-be-published-at="canBePublishedAt"
            :disallow-past-publish-dates="disallowPastPublishDates"
            :disallow-incompatible-publish-dates="disallowIncompatiblePublishDates"
            :publish-dates-must-overlap-with="publishDatesMustOverlapWith"
            click-select
          />
        </template>
      </MediaLayout>

      <InfiniteScroll :on-infinite="onInfinite" :distance="290" />

      <CircularLoader v-if="!popupSearchStore.searchListFullyLoaded" />

      <div
        v-if="
          !popupSearchStore.pending.mediaSearchList &&
          popupSearchStore.mediaSearchList.length === 0 &&
          popupSearchStore.searchListFullyLoaded
        "
      >
        <EmptyStatus image="empty-beach.png" title="No media found." class="empty">
          <p>
            <a @click="openUploadPopup">Add media</a>
            to your library.
          </p>
        </EmptyStatus>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import dayjs from 'dayjs';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import {
  filterColorOptions,
  SEARCH_BAR_PLACEHOLER,
  COMPETITIVE_SEARCH_BAR_PLACEHOLER,
  PREDICTION_MODEL,
} from '@/config';
import { dropNullObjectFields } from '@/utils';
import { getPerformanceParameters } from '@/utils/vision';
import FilterGroup from '@/components/FilterGroup.vue';
import MediaLayout from '@/components/core/layout/MediaLayout.vue';
import MediaTile from '@/components/MediaTile.vue';
import Select from '@/components/Select.vue';
import SearchBar from '@/components/SearchBar.vue';
import enumTypes, { mediaTypes, MAX_MEDIA_OFFSET, visualSources } from '@/app/library/constants';
import CircularLoader from '@/components/CircularLoader.vue';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import EmptyStatus from '@/components/EmptyStatus.vue';
import TimeFilterGroup from '@/components/TimeFilterGroup.vue';
import { BRAND, USER } from '@/models/auth/permissions.enum';
import { enumProp } from '@/utils/props';
import { useMediaSelectStore } from '@/stores/media-select';
import { usePopupSearchStore } from '@/stores/popup-search';
import ImageDropdownSelect from '@/components/foundation/form/ImageDropdownSelect.vue';
import MultiSelectFilter from '@/components/foundation/MultiSelectFilter.vue';
import { refreshCancelToken } from '@/apis/axios.utils';
import { useFlagStore } from '@/stores/flag';
import { xssEscapeHtml } from '@/utils/xss';
import { useContentTagsStore } from '@/stores/content-tags';
import { ALL_TIME, isValidInterval } from '@/utils/dateUtils';

const mediaTypeFilterValues = [null, enumTypes.IMAGE, enumTypes.VIDEO];
const filterValuesByMediaType = Object.fromEntries(mediaTypeFilterValues.map((v, i) => [v, i]));

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'FindMediaPopup',
  components: {
    ImageDropdownSelect,
    EmptyStatus,
    FilterGroup,
    InfiniteScroll,
    MediaLayout,
    MediaTile,
    SearchBar,
    Select,
    CircularLoader,
    TimeFilterGroup,
    MultiSelectFilter,
  },
  inject: {
    widgetMediaFilter: { from: 'widgetMediaFilter', default: false },
    widgetSourceOptions: { from: 'widgetSourceOptions', default: null },
    sourceDisabled: { from: 'widgetSourceDisabled', default: false },
    previousMediaListSelected: { from: 'widgetMediaListSelected', default: null },
  },
  props: {
    limitSource: { type: String, default: null },
    mediaTypeLimit: enumProp(mediaTypes, null),
    allowMixedMedia: { type: Boolean, default: true },
    mediaCountLimit: { type: Number, default: null },
    videoCountLimit: { type: Number, default: null },
    competitiveSearchMediaSources: { type: Array, default: () => [] },
    visualFilterSearch: { type: Boolean, default: false },
    brandOptions: { type: Array, default: () => [] },
    tileType: { type: String, default: 'library' },
    cancelRequests: { type: Boolean, default: false },
    canBePublishedAt: { type: [Date, Object], default: null },
    disallowPastPublishDates: { type: Boolean, default: false },
    disallowIncompatiblePublishDates: { type: Boolean, default: false },
    publishDatesMustOverlapWith: {
      type: Object,
      default: () => ALL_TIME,
      validator: (value) => value === false || isValidInterval(value),
    },
  },
  data() {
    return {
      sortOptions: [
        {
          label: 'Recently Added',
          value: 'date',
        },
        {
          label: 'Reach',
          value: 'reach',
        },
      ],
      sourceOptions: [
        {
          label: 'All Sources',
          value: null,
        },
        {
          label: 'Owned',
          value: 'owned',
        },
        {
          label: 'UGC',
          value: 'ugc',
        },
      ],
      sourceLabel: 'Sources',
      imageData: null,
      search: null,
      sort: 'date',
      source: null,
      startDate: null,
      endDate: null,
      type: null,
      platform: null,
      aspectRatio: null,
      mediaType: filterValuesByMediaType[this.mediaTypeLimit],
      size: null,
      color: null,
      contentRights: null,
      performance: null,
      defaultValue: null,
      selectedBrandId: null,
      brandSelectWidth: '13rem',
      cancelTokens: {},
      contentTags: [],
      sourceFilterDropdownOpen: false,
      showDuplicateMedia: null,
    };
  },
  computed: {
    ...mapStores(
      useMediaSelectStore,
      usePopupSearchStore,
      useTrackingStore,
      useFlagStore,
      useContentTagsStore,
    ),
    ...mapPiniaState(useAuthStore, ['currentBrand', 'identity', 'brand_can', 'user_can', 'guard']),
    canAccessUGC() {
      return (
        this.guard(BRAND.INSTAGRAM.CAN_ACCESS_UGC_MORE) &&
        this.guard(USER.INSTAGRAM.CAN_ACCESS_UGC_MORE)
      );
    },
    canAccessCompetitors() {
      return (
        this.guard(BRAND.COMPETITIVE.CAN_ACCESS_COMPETITORS) &&
        this.guard(USER.COMPETITIVE.CAN_ACCESS_COMPETITORS)
      );
    },
    defaultFilter() {
      return {
        search: null,
        sort: 'date',
        source: null,
        startDate: null,
        endDate: null,
        type: null,
        platform: null,
        aspectRatio: null,
        mediaType: filterValuesByMediaType[this.mediaTypeLimit],
        size: null,
        color: null,
        mediaId: null,
        contentRights: null,
        performance: null,
        showDuplicateMedia: null,
      };
    },
    aspectRatioValue() {
      return [null, enumTypes.SQUARE, enumTypes.LANDSCAPE, enumTypes.PORTRAIT];
    },
    sizeValue() {
      return [null, '1024x768', '800x600', '1600x1200'];
    },
    queryData() {
      const performanceParams = getPerformanceParameters(this.performance);
      const params = {
        brandId: this.selectedBrandId || this.currentBrand.id,
        sort: this.sort,
        query: this.search,
        aspect_ratio:
          this.aspectRatio || this.aspectRatio === 0
            ? this.aspectRatioValue[this.aspectRatio]
            : this.aspectRatio,
        size: this.size || this.size === 0 ? this.sizeValue[this.size] : this.size,
        type: mediaTypeFilterValues[this.mediaType],
        color: this.color || this.color === 0 ? filterColorOptions[this.color].name : this.color,
        rights_reqd: this.contentRights === 'on',
        date_from: this.startDate && dayjs(this.startDate).format('YYYY-MM-DD'),
        date_to: this.endDate && dayjs(this.endDate).format('YYYY-MM-DD'),
        image_data: !!this.imageData,
        media_id: this.mediaId,
        prediction_model: PREDICTION_MODEL.ENGAGEMENT.field,
        performance_from: performanceParams.from,
        performance_to: performanceParams.to,
        page: this.popupSearchStore.searchPage,
        limit: this.popupSearchStore.searchLimit,
        sources: this.getSourceFilter(),
        content_tags: this.contentTags,
      };
      if (this.popupSearchStore.searchPage * this.popupSearchStore.searchLimit > MAX_MEDIA_OFFSET) {
        params.next_token = this.popupSearchStore.nextToken;
      }
      return dropNullObjectFields(params);
    },
    sourceValue() {
      if (this.limitSource === enumTypes.INSTAGRAM) {
        if (!this.canAccessUGC) {
          return enumTypes.INSTAGRAM_SOURCE_LIST.filter((type) => !type.endsWith('_UGC')).join(',');
        }
        return enumTypes.INSTAGRAM_SOURCE_LIST.join(',');
      }
      if (this.limitSource === enumTypes.PINTEREST) {
        return enumTypes.PINTEREST_SOURCE_LIST.join(',');
      }
      if (this.limitSource === enumTypes.SOURCE_FILTER_INSTAGRAM_STORIES) {
        if (!this.canAccessUGC) {
          return enumTypes.INSTAGRAM_STORY_SOURCE_LIST.filter(
            (type) => !type.endsWith('_UGC'),
          ).join(',');
        }
        return enumTypes.INSTAGRAM_STORY_SOURCE_LIST.join(',');
      }
      if (this.limitSource === enumTypes.TIKTOK) {
        return enumTypes.TIKTOK_SOURCE_LIST.join(',');
      }
      if (this.limitSource === enumTypes.FACEBOOK) {
        return enumTypes.FACEBOOK_SOURCE_LIST_V1.join(',');
      }
      if (this.limitSource === enumTypes.TWITTER) {
        return enumTypes.TWITTER_SOURCE_LIST_V1.join(',');
      }
      if (this.limitSource === enumTypes.YOUTUBE) {
        return enumTypes.YOUTUBE_SOURCE_LIST.join(',');
      }
      if (this.limitSource === 'OWNED') {
        return enumTypes.OWNED_LIST.join(',');
      }
      if (this.limitSource === 'UGC') {
        return enumTypes.UGC_SOURCE_LIST.join(',');
      }
      return this.limitSource;
    },
    limitUgcOrOwnedMedia() {
      return this.limitSource === 'UGC' || this.limitSource === 'OWNED';
    },
    hideFilterGroup() {
      if (this.limitUgcOrOwnedMedia) {
        return false;
      }
      return this.sourceValue || this.limitToCompetitiveSearch;
    },
    placeholder() {
      return this.limitToCompetitiveSearch
        ? COMPETITIVE_SEARCH_BAR_PLACEHOLER
        : SEARCH_BAR_PLACEHOLER;
    },
    limitToCompetitiveSearch() {
      return this.competitiveSearchMediaSources.length > 0;
    },
    competitorsOptionLabel() {
      if (
        this.competitiveSearchMediaSources.includes(enumTypes.INSTAGRAM_OTHER) ||
        this.competitiveSearchMediaSources.includes(enumTypes.INSTAGRAM_OTHER_IGTV)
      ) {
        return 'Competitors & Relationships';
      }
      return 'Competitors';
    },
    enableDragSelect() {
      return this.mediaCountLimit === null;
    },
    canAccessContentTags() {
      return this.guard(BRAND.TAGS.CAN_ACCESS_CONTENT_TAGS);
    },
    showContentTagFilter() {
      return (
        this.flagStore.ready &&
        this.flagStore.flags.uploadedMediaContentTags &&
        this.canAccessContentTags
      );
    },
    contentTagItems() {
      const brandIds = [this.currentBrand?.id];
      return [
        {
          items: this.contentTagsStore.organizationContentTags
            ?.filter((organizationContentTag) => {
              return organizationContentTag.brandIds.some((contentTagBrandId) =>
                brandIds.includes(contentTagBrandId),
              );
            })
            .map((contentTag) => {
              return {
                type: 'item',
                label: xssEscapeHtml(contentTag.name),
                value: xssEscapeHtml(contentTag.name),
              };
            }),
          name: '',
        },
      ];
    },
  },
  created() {
    this.configureSourceData();
    this.checkPreviousSelected();
  },
  mounted() {
    if (this.visualFilterSearch) {
      this.selectedBrandId = this.currentBrand.id || this.brandOptions[0].id;
    }
    this.fetchSearchResult({ clear: true });
    if (this.guard(USER.VISION.CAN_ACCESS_VISION)) {
      this.sortOptions.push({ label: 'Predicted Performance', value: 'performance' });
    }
  },
  unmounted() {
    this.mediaSelectStore.clearItemsFromMultiSelect();
    this.popupSearchStore.clearMediaSearchList();
  },
  methods: {
    configureSourceData() {
      if (this.widgetSourceOptions) {
        this.sourceOptions = this.widgetSourceOptions;
        this.source = this.limitSource;
        return;
      }

      if (
        (!this.limitSource || this.limitSource === enumTypes.INSTAGRAM) &&
        this.canAccessCompetitors
      ) {
        if (this.canAccessUGC) {
          this.sourceOptions = [
            ...this.sourceOptions,
            { label: this.competitorsOptionLabel, value: 'competitors' },
          ];
        } else {
          this.sourceOptions = [
            { label: 'All Sources', value: null },
            { label: 'Owned', value: 'owned' },
            { label: this.competitorsOptionLabel, value: 'competitors' },
          ];
        }
        if (this.limitToCompetitiveSearch) {
          this.source = 'competitors';
          this.defaultValue = this.competitiveSearchMediaSources.join(',');
        }
      }

      if (
        !this.limitSource ||
        ([enumTypes.INSTAGRAM, enumTypes.SOURCE_FILTER_INSTAGRAM_STORIES].includes(
          this.limitSource,
        ) &&
          (this.canAccessUGC || this.canAccessCompetitors))
      ) {
        return;
      }

      let label;
      this.sourceLabel = 'Source';

      switch (this.limitSource) {
        case enumTypes.UPLOADED:
          label = 'Uploads';
          break;
        case enumTypes.PINTEREST:
          label = 'Pinterest Owned';
          break;
        case enumTypes.FACEBOOK:
          label = 'Facebook Owned';
          break;
        case enumTypes.TWITTER:
          label = 'X Owned';
          break;
        case enumTypes.TIKTOK:
          label = 'TikTok Owned';
          break;
        case enumTypes.YOUTUBE:
          label = 'YouTube Owned';
          break;
        case enumTypes.INSTAGRAM:
          label = 'Instagram Owned';
          if (!this.canAccessUGC) {
            this.type = 'Owned';
            this.platform = ['instagram', 'instagram stories'];
          }
          break;
        case enumTypes.SOURCE_FILTER_INSTAGRAM_STORIES:
          label = 'Instagram Story Owned';
          break;
        case 'OWNED':
          this.type = 'Owned';
          this.platform = [
            'tikTok',
            'facebook',
            'twitter',
            'pinterest',
            'instagram',
            'youTube',
            'instagram stories',
          ];
          label = 'Owned';
          break;
        case 'UGC':
          this.type = 'UGC';
          this.platform = ['instagram', 'instagram stories'];
          label = 'UGC';
          break;
        default:
          label = 'All Sources';
      }

      this.sourceOptions = [{ label, value: this.limitSource }];
    },
    checkPreviousSelected() {
      if (this.previousMediaListSelected && this.previousMediaListSelected.length > 0) {
        this.mediaSelectStore.setMultiSelectSelectedItems(this.previousMediaListSelected);
      }
    },
    async fetchSearchResult(options = {}) {
      const { clear } = options;
      if (clear) {
        this.popupSearchStore.clearMediaSearchList();
      }
      let cancelToken;
      if (this.cancelRequests) {
        cancelToken = refreshCancelToken(this.cancelTokens, 'fetchSearchResultCancelToken');
      }

      const {
        brandId,
        sort,
        query,
        aspect_ratio: aspectRatio,
        size,
        type,
        color,
        rights_reqd: rightsReqd,
        date_from: dateFrom,
        date_to: dateTo,
        image_data: imageDataIncluded,
        media_id: mediaId,
        prediction_model: predictionModel,
        performance_from: performanceFrom,
        performance_to: performanceTo,
        page,
        limit,
        sources,
        next_token: nextToken,
        content_tags: contentTags,
      } = this.queryData;
      if (this.imageData) {
        await this.popupSearchStore.getMediaSearchList({
          brandId,
          sort,
          query,
          aspectRatio,
          size,
          type,
          color,
          rightsReqd,
          dateFrom,
          dateTo,
          imageDataIncluded,
          mediaId,
          predictionModel,
          performanceFrom,
          performanceTo,
          page,
          limit,
          sources,
          nextToken,
          imageData: this.imageData,
          clear,
          cancelToken,
          contentTags,
          showDuplicateMedia: this.showDuplicateMedia === 'on',
        });
      } else {
        await this.popupSearchStore.getMediaSearchList({
          brandId,
          sort,
          query,
          aspectRatio,
          size,
          type,
          color,
          rightsReqd,
          dateFrom,
          dateTo,
          imageDataIncluded,
          mediaId,
          predictionModel,
          performanceFrom,
          performanceTo,
          page,
          limit,
          sources,
          nextToken,
          clear,
          cancelToken,
          contentTags,
          showDuplicateMedia: this.showDuplicateMedia === 'on',
        });
      }
    },
    sortChanged(option) {
      this.sort = this.sortOptions[option].value;
      this.updateResult();
    },
    sourceChanged(option) {
      if (this.defaultValue !== null) {
        this.defaultValue = null;
      }
      this.type = this.sourceOptions[option].value;
      if (this.limitSource) {
        this.platform = [this.limitSource.toLowerCase()];
      } else if (
        this.competitiveSearchMediaSources.includes(visualSources.INSTAGRAM_OTHER) &&
        this.type === 'owned'
      ) {
        this.platform = ['instagram'];
      } else if (
        this.competitiveSearchMediaSources.includes(visualSources.TWITTER_OTHER) &&
        this.type === 'owned'
      ) {
        this.platform = ['twitter'];
      } else {
        this.platform = ['instagram'];
      }
      this.trackingStore.track('Search Filter Changed', {
        filter: 'Media Source',
        platforms: this.platform,
        mediaSource: this.sourceOptions[option].value,
        filterValue: option,
      });
      this.updateResult();
    },
    updateSourceFilter(option) {
      this.type = option.mediaSource;
      this.platform = option.platforms;
      this.updateResult();
    },
    updateSort(option) {
      this.sort = this.sortOptions[option].value;
    },
    updateResult() {
      if (!this.widgetMediaFilter) this.mediaSelectStore.clearItemsFromMultiSelect();
      this.fetchSearchResult({ clear: true });
    },
    updateDateFilter(option) {
      if (option) {
        [this.startDate, this.endDate] = option;
      } else {
        this.startDate = null;
        this.endDate = null;
      }
      this.mediaSelectStore.clearItemsFromMultiSelect();
      this.fetchSearchResult({ clear: true });
    },
    updateMoreFilter(option) {
      this.aspectRatio = option.aspectRatio;
      this.mediaType = option.mediaType;
      this.size = option.size;
      this.color = option.color;
      this.contentRights = option.contentRights;
      this.performance = option.performance;
      this.mediaSelectStore.clearItemsFromMultiSelect();
      this.showDuplicateMedia = option.showDuplicateMedia;
      this.fetchSearchResult({ clear: true });
    },
    updateSearchTerm(term) {
      this.imageData = null;
      this.search = term;
      this.mediaId = null;
      this.mediaSelectStore.clearItemsFromMultiSelect();
      this.fetchSearchResult({ clear: true });
    },
    updateSearchImage(imageData) {
      this.imageData = imageData;
      this.search = null;
      this.mediaId = null;
      this.mediaSelectStore.clearItemsFromMultiSelect();
      this.fetchSearchResult({ clear: true });
    },
    updateContentTags(option) {
      this.contentTags = option;
      this.fetchSearchResult({ clear: true });
    },
    // Hack to prevent an infinite loading from resetting the dropdown's state
    sourceDropdownToggled(isOpen) {
      this.sourceFilterDropdownOpen = isOpen;
    },
    onInfinite() {
      if (
        !this.sourceFilterDropdownOpen &&
        !this.popupSearchStore.pending.mediaSearchList &&
        !this.popupSearchStore.searchListFullyLoaded
      ) {
        this.fetchSearchResult();
      }
    },
    getPlatformToCompetitiveMapping() {
      return {
        [enumTypes.INSTAGRAM]: [enumTypes.INSTAGRAM_OTHER, enumTypes.INSTAGRAM_OTHER_IGTV],
        [enumTypes.TWITTER]: [
          enumTypes.TWITTER_OTHER,
          enumTypes.TWITTER_OTHER_LINK,
          enumTypes.TWITTER_OTHER_TEXT,
        ],
        [enumTypes.YOUTUBE]: [enumTypes.YOUTUBE_OTHER],
      };
    },
    getCompetitiveFilter() {
      if (this.competitiveSearchMediaSources.length > 0) {
        return this.competitiveSearchMediaSources;
      }
      const platformToCompetitive = this.getPlatformToCompetitiveMapping();
      if (this.platform?.length > 0) {
        const competitiveContext = [];
        this.platform.forEach((platform) => {
          competitiveContext.push(platformToCompetitive[platform.toUpperCase()]);
        });
        return competitiveContext;
      }
      return Object.values(platformToCompetitive);
    },
    getSourceFilter() {
      if (this.type && this.widgetSourceOptions) {
        return this.type.toUpperCase();
      }
      if (this.type && this.type === 'Uploads') {
        return enumTypes.UPLOADED;
      }
      if (this.defaultValue) {
        return this.defaultValue;
      }
      if (this.type === 'competitors') {
        const competitiveFilter = this.getCompetitiveFilter();
        return competitiveFilter.join(',');
      }
      if (this.type && this.platform) {
        const sources =
          this.platform.constructor === Array ? this.platform : this.platform.split(',');
        const formattedSources = [];
        sources
          .filter((item) => item !== 'any')
          .forEach((platform) => {
            if (platform === enumTypes.SOURCE_FILTER_INSTAGRAM_STORIES) {
              if (this.type.toLowerCase() === 'owned') {
                formattedSources.push(enumTypes.INSTAGRAM_STORY);
              }
              if (this.type.toLowerCase() === 'ugc') {
                formattedSources.push(enumTypes.INSTAGRAM_STORY_UGC);
              }
            } else if (platform === 'ads') {
              formattedSources.push(enumTypes.FACEBOOK_ADS);
            } else if (platform === 'facebook') {
              formattedSources.push(...enumTypes.FACEBOOK_GALLERY_LIST);
            } else if (platform === 'instagram' && this.type.toLowerCase() === 'owned') {
              formattedSources.push(enumTypes.INSTAGRAM_OWNED_IGTV);
              formattedSources.push(`${platform}_${this.type}`.toUpperCase());
            } else if (platform === 'instagram' && this.type.toLowerCase() === 'competitors') {
              formattedSources.push(enumTypes.INSTAGRAM_OTHER);
              formattedSources.push(enumTypes.INSTAGRAM_OTHER_IGTV);
            } else {
              formattedSources.push(`${platform}_${this.type}`.toUpperCase());
            }
          });
        return formattedSources.join(',');
      }

      if (this.limitSource) {
        return this.sourceValue;
      }

      const sources = [
        enumTypes.INSTAGRAM_OWNED,
        enumTypes.INSTAGRAM_OWNED_IGTV,
        enumTypes.PINTEREST_OWNED,
      ];
      if (this.brand_can('facebook', 'can_access_fb_insights')) {
        sources.push(...enumTypes.FACEBOOK_GALLERY_LIST);
      }
      if (this.brand_can('twitter', 'can_access_twitter_insights')) {
        sources.push(enumTypes.TWITTER_OWNED);
      }

      if (
        this.guard(BRAND.TIKTOK.CAN_ACCESS_TIKTOK_INSIGHTS) &&
        this.guard(USER.TIKTOK.CAN_ACCESS_TIKTOK_INSIGHTS)
      ) {
        sources.push(...enumTypes.TIKTOK_OWNED_SOURCE_LIST);
      }

      if (
        this.guard(BRAND.YOUTUBE.CAN_ACCESS_YOUTUBE_INSIGHTS) &&
        this.guard(USER.YOUTUBE.CAN_ACCESS_YOUTUBE_INSIGHTS)
      ) {
        sources.push(...enumTypes.YOUTUBE_OWNED_SOURCE_LIST);
      }

      if (
        this.guard(BRAND.INSTAGRAM.CAN_ACCESS_UGC_MORE) &&
        this.guard(USER.INSTAGRAM.CAN_ACCESS_UGC_MORE)
      ) {
        sources.push(enumTypes.INSTAGRAM_UGC, enumTypes.INSTAGRAM_STORY_UGC);
      }

      if (
        this.guard(BRAND.COMPETITIVE.CAN_ACCESS_COMPETITORS) &&
        this.guard(USER.COMPETITIVE.CAN_ACCESS_COMPETITORS) &&
        this.limitToCompetitiveSearch
      ) {
        sources.push(enumTypes.INSTAGRAM_OTHER, enumTypes.INSTAGRAM_OTHER_IGTV);
        if (
          this.guard(BRAND.TWITTER.CAN_ACCESS_TWITTER_INSIGHTS) &&
          this.guard(USER.TWITTER.CAN_ACCESS_TWITTER_INSIGHTS)
        ) {
          sources.push(enumTypes.TWITTER_OTHER);
        }
        // no sources for YouTube competitive, since vision search don't work for this channel
      }

      // Only fetch uploaded media on non-campaigns pages
      if (!this.$route.name.includes('campaign')) {
        sources.push(enumTypes.UPLOADED);
      }
      // Do not fetch UGC media on campaigns if user/ brand have no access, or if brand has no access to Campaign UGC & relationships
      if (
        this.$route.name.includes('campaign') &&
        (!this.guard(BRAND.INSTAGRAM.CAN_ACCESS_UGC_MORE) ||
          !this.guard(USER.INSTAGRAM.CAN_ACCESS_UGC_MORE))
      ) {
        const campaignsFilteredSources = sources.filter(
          (source) =>
            source !== enumTypes.INSTAGRAM_UGC && source !== enumTypes.INSTAGRAM_STORY_UGC,
        );
        return campaignsFilteredSources.join(',');
      }

      return sources.join(',');
    },
    openUploadPopup() {
      this.$router.push({
        name: 'library.media.upload',
      });
    },
    setBrandId(id) {
      this.selectedBrandId = id;
      this.updateResult();
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.find-media-popup {
  background: var(--background-300);
  padding: var(--space-24);
  border-radius: 0 0 var(--round-corner) var(--round-corner);

  .brand-select-enabled {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .brand-select {
      :deep(.picker) {
        border-radius: var(--space-20);
      }
    }

    .search-bar {
      width: var(--width-420);
      margin: 0;
    }
  }

  .control {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: var(--space-24) 0;

    &.no-top-margin {
      margin-top: 0;
    }

    .select-wrapper {
      display: flex;
    }
  }

  .empty {
    margin-bottom: var(--space-48);
  }

  .content-tags {
    :deep(.filter-popup) {
      max-height: var(--space-420);
    }
  }
}
</style>
