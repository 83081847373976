<template>
  <div>
    <section
      :class="[{ 'search-media': searchStore.mediaSearch && searchImageFile }]"
      class="search-bar"
    >
      <div :class="['search-bar-wrapper', { loading: loadingStatus }]">
        <input
          ref="searchTerm"
          v-model="searchValue"
          :placeholder="placeHolder"
          :disabled="searchStore.mediaSearch && searchImageFile"
          data-cy="search-media-input"
          type="text"
          @input="debounceInput"
          @focusin="openDropdown"
          @blur="closeDropdown"
        />
      </div>
      <div v-if="searchStore.mediaSearch && searchImageFile" class="search-media-box">
        <a
          v-if="!searchedVideo"
          v-tooltip="'Search a section of this photo'"
          :class="{ processing: !imgFile && searchStore.mediaSearch }"
          class="media cropper-link"
          @click="showCropPanel"
        >
          <img
            :class="{ processing: !imgFile && searchStore.mediaSearch }"
            class="media"
            :src="searchImageFile"
          />
        </a>
        <div v-else :class="{ processing: !imgFile && searchStore.mediaSearch }" class="media">
          <img :src="searchImageFile" />
          <Icon name="video" xsmall color="white" class="thumbnail-overlay-icon" />
        </div>
        <a title="Remove" class="btn-close" @click="enableTextSearch">
          <Icon name="close" xxsmall />
        </a>
      </div>
      <div :class="{ 'search-by-photo-disabled': !searchByImage }" class="search-options">
        <button id="clear-search" :class="hideClearButton" @click="clearSearchTerm">
          <Icon name="closeCircleSolid" small />
        </button>
        <button
          v-if="searchByImage"
          id="search-photo"
          v-tooltip="'Search by photo'"
          @click="findImg"
        >
          <input ref="imgSelector" type="file" :accept="imageTypes" @change="selectImg" />
          <Icon name="camera" small />
        </button>
      </div>

      <!-- Crop container -->
      <ImageCropper
        v-if="showCropper"
        :img="imgFile"
        :img-w="imgWidth"
        :img-h="imgHeight"
        @close-cropper="closeCropPanel"
        @set-cropped-image="setCroppedImage"
      />
      <!-- Crop container -->
    </section>
    <SearchDropdown
      v-if="showDropdown"
      @upload-image="findImgViaDropdown"
      @choose-media="chooseMedia"
      @mousedown="mouseDown"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import debounce from 'lodash/debounce';
import { mapStores } from 'pinia';
import { debounceInputDelay, SEARCH_BAR_PLACEHOLER } from '@/config';
import Icon from '@/components/foundation/Icon.vue';
import ImageCropper from '@/components/ImageCropper.vue';

import { scaleImage } from '@/app/library/utils';
import enumTypes, { visualSearchImageTypes } from '@/app/library/constants';
import SearchDropdown from '@/components/SearchDropdown.vue';
import { usePopupSearchStore } from '@/stores/popup-search';
import { useSearchStore } from '@/stores/search';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'SearchBar',
  components: {
    SearchDropdown,
    Icon,
    ImageCropper,
  },
  props: {
    search: { type: String, default: '' },
    imageData: { type: String, default: null },
    imageWidth: { type: Number, default: null },
    imageHeight: { type: Number, default: null },
    searchByImage: { type: Boolean, default: true },
    defaultPlaceholder: { type: String, default: SEARCH_BAR_PLACEHOLER },
    isLoading: { type: Boolean, default: null, required: false },
    mediaType: { type: String, default: null },
    includeDropdown: { type: Boolean, default: false },
    disableSearchOnUrlChange: { type: Boolean, default: false },
  },
  emits: ['imageSearch', 'submitSearch', 'chooseMedia'],
  data() {
    return {
      isReload: false,
      imgFile: null,
      croppedImgFile: null,
      imgHeight: null,
      imgWidth: null,
      showCropper: false,
      showSearchBarSpinner: false,
      searchValue: '',
      placeHolder: this.defaultPlaceholder,
      imageTypes: visualSearchImageTypes,
      active: false,
      dropdownClicked: false,
      mediaSearchSource: null,
      uploadViaDropdown: false,
    };
  },
  computed: {
    ...mapStores(usePopupSearchStore, useSearchStore),
    searchImageFile() {
      return this.croppedImgFile || this.imgFile;
    },
    hideClearButton() {
      return { hide: !this.searchValue };
    },
    searchedVideo() {
      return this.mediaType === enumTypes.VIDEO;
    },
    loadingStatus() {
      if (this.isLoading !== null) {
        return this.isLoading;
      }
      return this.showSearchBarSpinner;
    },
    showDropdown() {
      return this.includeDropdown && (this.searchValue === '' || !this.searchValue) && this.active;
    },
  },
  watch: {
    'searchStore.pending.mediaSearchList': function libraryMediaSearchWatcher(to) {
      this.showSearchBarSpinner = to;
    },
    'popupSearchStore.pending.mediaSearchList': function popupMediaSearchWatcher(to) {
      this.showSearchBarSpinner = to;
    },
    'searchStore.carouselState': function carouselStateWatcher(to) {
      if (to) {
        this.croppedImgFile = null;
      }
    },
    search(to) {
      this.searchValue = this.search;
      if (to) {
        this.showSearchBarSpinner = true;
      }
    },
    imgFile(imageData) {
      // if there is a media_id, don't query by data even we have image data
      // the media data here is for cropper, for search we always query by media_id
      if (imageData && !this.$route.query.media_id) {
        this.$emit('imageSearch', imageData, this.mediaSearchSource);
        this.mediaSearchSource = null;
      }
    },
    'searchStore.mediaSearch': function mediaSearchWatcher(to) {
      if (this.disableSearchOnUrlChange) {
        return;
      }
      if (to) {
        this.imgFile = null;
        this.croppedImgFile = null;
        this.disableTextSearch();
      } else {
        this.enableTextSearch();
      }
    },
    imageData(imageData) {
      if (imageData) {
        this.searchStore.setMediaSearchStatus({ status: true });
        this.placeHolder = '';
        this.searchValue = null;
        this.imgFile = imageData;
        if (this.imageWidth) {
          this.imgWidth = this.imageWidth;
        }
        if (this.imageHeight) {
          this.imgHeight = this.imageHeight;
        }
      } else {
        this.imgFile = null;
        this.croppedImgFile = null;
        this.popupSearchStore.resetCropParams();
      }
    },
  },
  mounted() {
    if (this.$route.query.media_id || this.$route.query.url) {
      this.disableTextSearch();
    } else {
      this.searchValue = this.search || '';
    }
  },
  methods: {
    chooseMedia() {
      this.active = false;
      this.mediaSearchSource = 'Choose Media';
      this.$emit('chooseMedia');
    },
    clearSearchTerm() {
      this.$refs.searchTerm.blur();
      this.active = false;
      this.searchValue = '';
      this.debounceInput();
    },
    enableTextSearch() {
      this.searchStore.setMediaSearchStatus({ status: false });
      this.imgFile = null;
      this.croppedImgFile = null;
      this.mediaSearchSource = null;
      this.placeHolder = this.defaultPlaceholder;
      this.popupSearchStore.resetCropParams();
      this.$emit('imageSearch', null);
      if (this.$refs.imgSelector) {
        this.$refs.imgSelector.value = null;
      }
    },
    disableTextSearch() {
      this.searchStore.setMediaSearchStatus({ status: true });
      this.placeHolder = '';
      this.searchValue = null;
    },
    findImg() {
      this.mediaSearchSource = this.uploadViaDropdown ? 'Image Upload' : 'Camera Icon';
      this.$refs.searchTerm.blur();
      this.$refs.imgSelector.click();
      this.uploadViaDropdown = false;
    },
    findImgViaDropdown() {
      this.uploadViaDropdown = true;
      this.findImg();
    },
    imageLoaded(img) {
      const [imgW, imgH] = scaleImage(img.width, img.height);

      const canvas = document.createElement('canvas');
      canvas.width = imgW;
      canvas.height = imgH;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, imgW, imgH);

      this.imgHeight = imgH;
      this.imgWidth = imgW;
      this.croppedImgFile = null;
      this.popupSearchStore.resetCropParams();
      this.imgFile = canvas.toDataURL('image/jpeg');
      this.clearUrlParams();
      this.popupSearchStore.clearSectionMedia();
    },
    selectImg({ target }) {
      const file = target.files[0];
      if (!file) {
        return;
      }
      const fReader = new FileReader();
      fReader.onload = (el) => {
        const image = new Image();
        image.onload = () => this.imageLoaded(image);
        image.src = el.target.result;
      };
      fReader.readAsDataURL(file);
      this.disableTextSearch();
    },
    clearUrlParams() {
      // need to remove 'media_id' in the url to enable search by image data.
      const { query } = this.$route;
      delete query.media_id;
      delete query.mediaId;
      query.reload = new Date().getTime();
      this.$router.push({ query });
    },
    showCropPanel() {
      this.showCropper = true;
    },
    closeCropPanel() {
      this.showCropper = false;
    },
    setCroppedImage(imageData) {
      this.croppedImgFile = imageData;
      this.showCropper = false;
      this.clearUrlParams();
      this.$emit('imageSearch', imageData, 'Cropped');
    },
    redirect() {
      if (this.$route.name !== 'discoverSearch') {
        this.$router.push('/library');
      }
    },
    debounceInput: debounce(function typeAhead() {
      this.$emit('submitSearch', this.searchValue);
    }, debounceInputDelay),
    mouseDown() {
      this.dropdownClicked = true;
    },
    closeDropdown() {
      if (this.dropdownClicked) {
        const rect = this.$refs.searchTerm.getBoundingClientRect();
        this.dropdownClicked = false;
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          this.$refs.searchTerm.focus();
        }
      } else {
        this.active = false;
      }
    },
    openDropdown() {
      this.active = true;
      this.mediaSearchSource = null;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.hide {
  display: none;
}

.search-bar {
  max-width: 31.25rem;
  margin: 0 auto var(--space-8);
  position: relative;

  .search-bar-wrapper.loading::after {
    content: '';
    display: block;
    border: 2px solid var(--text-secondary);
    border-left-color: var(--background-500);
    border-right-color: var(--background-500);
    border-bottom-color: var(--background-500);
    height: var(--space-16);
    width: var(--space-16);
    border-radius: 50%;
    position: absolute;
    right: 6rem;
    top: 50%;
    box-sizing: border-box;
    margin-left: calc(-1 * var(--space-8));
    margin-top: calc(-1 * var(--space-8));
    animation: rotate 1s linear infinite;
  }

  input {
    margin: auto;
    width: 100%;
    border-radius: 1.375rem;
    padding: var(--space-12) var(--space-24) var(--space-12) var(--space-48);
    background: url('@/assets/icons/search.svg') var(--background-0) no-repeat 1rem center;
    transition: var(--transition-all);
  }

  input:focus {
    box-shadow: var(--shadow-2);
  }

  input[disabled=''] {
    background-color: var(--background-300);
  }

  .search-media-box {
    display: flex;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 50%;
    top: 1.375rem;
    transform: translate(-50%, -50%);
    height: 3.75rem;
    transition: var(--transition-all);

    .media {
      flex: 1;
      display: block;
      position: relative;
      height: 100%;
      background: var(--background-0);
      float: left;
      border-radius: var(--round-corner) 0 var(--round-corner) var(--round-corner);
      box-shadow: var(--shadow-2);

      &.processing {
        min-width: 3.75rem;
      }

      img {
        height: 100%;
        width: auto;
        max-width: 18.75rem;
        border-radius: var(--round-corner) 0 var(--round-corner) var(--round-corner);
        box-shadow: var(--shadow-2);
        display: block;
        float: left;
      }

      .thumbnail-overlay-icon {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    a.btn-close {
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
      background: var(--background-0);
      height: 20px;
      width: 20px;
      border-radius: 0 var(--round-corner) var(--round-corner) 0;
      box-shadow: var(--shadow-2);
    }

    .mask {
      position: absolute;
      width: 100%;
      height: 3.75rem;
      z-index: 1;
      background: rgb(0 0 0 / 50%);
      transition: var(--transition-all);
    }
  }

  .search-options {
    position: absolute;
    top: 0.6rem;
    right: 0;
    display: flex;
    align-items: center;
    padding: 0 var(--space-16) 0 var(--space-16);
    border-left: none;

    #clear-search {
      padding: 0 var(--space-16) 0 0;
    }

    #search-photo {
      border-left: 1px solid var(--border);
      padding: 0 0 0 var(--space-16);
    }

    input {
      display: none;
    }

    button {
      padding: var(--space-4) var(--space-8);
      margin: 0;
      font: inherit;
      font-weight: var(--font-medium);
      border: 0;
      background: transparent;
      outline: 0;
      text-align: left;
      cursor: pointer;

      svg {
        transition: var(--transition-all);
        display: block;
        color: var(--text-primary);
      }
    }
  }

  .search-by-photo-disabled {
    #clear-search {
      padding-right: 0;
    }
  }

  .search-options button:hover {
    svg {
      color: var(--text-primary);
    }
  }

  .search-options button.act {
    svg {
      color: var(--action-500);
    }
  }

  .photo-filter {
    top: 3.375rem;
    left: 50%;
    z-index: var(--z-index-raised);
    max-width: 41.25rem;
    overflow-x: auto;
    padding: 0;
    transform: translate(-50%, -1.875rem);

    .column {
      width: 25%;
      min-width: 8.75rem;
      padding: var(--space-16);

      .act {
        color: var(--action-500);
      }
    }
  }

  .photo-filter.open {
    transform: translate(-50%, 0);
  }

  @media (max-width: 41.25rem) {
    .photo-filter {
      width: 100%;
    }
  }
}

.search-bar.search-media {
  input {
    text-align: right;
  }

  .search-media-box {
    opacity: 1;
    visibility: visible;
  }
}
</style>
