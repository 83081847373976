<template>
  <div :class="{ full: fullWidth, list: hideSearchBar }" class="multi-select-list">
    <CircularLoader v-if="loading" />
    <div v-else>
      <slot name="topNav" />
      <input
        v-if="!hideSearchBar"
        id="searchBoardInput"
        v-model="searchTerm"
        :placeholder="searchPlaceholder"
        class="search"
      />
      <ul class="results" data-cy="ms-list-results" @scroll="handleScroll">
        <MultiSelectListItem
          v-for="item in filteredItems"
          :key="item[valueProperty]"
          :selected="isItemSelected(item)"
          :label="item[labelProperty]"
          :value="item[valueProperty]"
          :icon="showVisual ? item[iconProperty] : null"
          :item="item"
          :on-change="(val) => itemChanged(item, val)"
        />
        <div v-if="showCreateButton && allowCreate" class="create">
          <Button @click="createNew">Create {{ searchTerm }}</Button>
        </div>
      </ul>
      <div v-if="overflowFadeOut && showFade && !allowCreate" class="fade"></div>
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
/**
 * Multi-select component that supports search and input-like behavior. Usage:
 *
 * <MultiSelectList
 *   v-model="selectedValues"
 *   :items="[{ value: 1, label: 'Foo' }, { value: 2, label: 'Bar' }]"
 * />
 */
import { mapStores } from 'pinia';
import { constants } from '@/config';
import MultiSelectListItem from '@/components/MultiSelectListItemRefactored.vue';
import Button from '@/components/foundation/Button.vue';
import CircularLoader from '@/components/CircularLoader.vue';
import { useCampaignsStore } from '@/stores/campaigns';
import { useNotificationStore } from '@/stores/notification';
import { useGalleryStore } from '@/stores/gallery';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'MultiSelectList',
  components: {
    Button,
    MultiSelectListItem,
    CircularLoader,
  },
  props: {
    value: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    valueProperty: { type: String, default: 'id' },
    labelProperty: { type: String, default: 'name' },
    iconProperty: { type: String, default: 'icon' },
    showVisual: { type: Boolean, default: false },
    galleryType: { type: String, default: 'LIBRARY' },
    searchPlaceholder: { type: String, default: null },
    fullWidth: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    hideSearchBar: { type: Boolean, default: false },
    allowCreate: { type: Boolean, default: true },
    overflowFadeOut: { type: Boolean, default: false },
  },
  emits: ['input'],
  data() {
    return {
      selectedItems: this.value,
      searchTerm: null,
      showFade: true,
    };
  },
  computed: {
    ...mapStores(useCampaignsStore, useNotificationStore, useGalleryStore),
    filteredItems() {
      if (this.searchTerm) {
        return this.items.filter(
          (item) =>
            item[this.labelProperty].toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1,
        );
      }
      return this.items;
    },
    showCreateButton() {
      if (this.searchTerm) {
        if (this.filteredItems.length) {
          const exist = this.filteredItems.filter(
            (item) => item[this.labelProperty].toLowerCase() === this.searchTerm.toLowerCase(),
          ).length;
          // if board with exact same name is existing, hide create button, otherwise, show it
          return !exist;
        }
        // show if there is a search term and no result
        return true;
      }
      // don't show if now search term
      return false;
    },
  },
  watch: {
    value(newVal) {
      this.selectedItems = newVal;
    },
  },
  methods: {
    handleScroll({ target: { clientHeight, scrollHeight, scrollTop } }) {
      this.showFade = scrollHeight - scrollTop - clientHeight > 1;
    },
    isItemSelected(item) {
      return (
        this.selectedItems.findIndex((i) => i[this.valueProperty] === item[this.valueProperty]) > -1
      );
    },
    itemChanged(item, val) {
      const mutableItem = item;
      if (Object.values(constants).includes(this.galleryType)) {
        mutableItem.galleryType = this.galleryType;
      }
      if (val) {
        this.$emit('input', [...this.selectedItems, mutableItem]);
      } else {
        // compare object is causing issue, change to compare unique filed
        this.$emit(
          'input',
          this.selectedItems.filter((i) => i.id !== mutableItem.id),
        );
      }
    },
    createNew() {
      if (this.galleryType === 'CAMPAIGN') {
        this.addCampaign();
      } else {
        this.addGallery();
      }
    },
    async addCampaign() {
      try {
        const payload = { name: this.searchTerm };
        await this.campaignsStore.createCampaign(payload);
      } catch (e) {
        this.notificationStore.setToast({
          message: 'An error has occurred.',
          type: 'error',
        });
      }
    },
    async addGallery() {
      try {
        await this.galleryStore.createGallery({
          galleryType: this.galleryType,
          name: this.searchTerm,
          tags: [],
          description: '',
        });
      } catch (e) {
        this.notificationStore.setToast({
          message: e?.response?.data?.description ?? '',
          type: 'error',
        });
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.multi-select-list {
  width: 16rem;
  margin-top: var(--space-4);
  line-height: var(--space-40);
  border-radius: var(--round-corner);
  position: relative;
  background-color: var(--background-0);
  font-weight: var(--font-medium);
  color: var(--text-primary);
  text-align: center;
  box-shadow: var(--shadow-4);

  input.search {
    width: 13.75rem;
    margin: var(--space-24) 0 var(--space-8);
    background: url('@/assets/icons/search.svg') no-repeat var(--space-8) 0.4rem var(--background-0);

    /* have to be exact 2.25rem rather than var(--space-32) or var(--space-40) to align items here. */
    padding: 0 var(--space-32) 0 2.25rem;
    border-radius: var(--space-32);
    height: var(--space-32);
    font-size: var(--x12);
    background-color: var(--background-0);
    color: var(--text-primary);
    border: 1px solid var(--border);
  }

  ul {
    width: 13.75rem;
    overflow-y: auto;
    padding: 0 var(--space-8);
    margin-bottom: var(--space-24);
    border-radius: var(--round-corner-small);
    background-color: var(--background-0);
    font-weight: var(--font-medium);
    color: var(--text-primary);
    vertical-align: middle;
    display: inline-block;
    position: relative;
  }

  .fade {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: block;
    height: 3rem;
    background: linear-gradient(180deg, transparent 0%, var(--background-0) 100%);
  }

  .create {
    width: 100%;

    button {
      margin: var(--space-16) auto;
    }
  }
}

.full {
  width: 100%;
  box-shadow: none;

  input.search {
    width: 100%;
    height: var(--space-40);
    margin-top: 1rem;
    padding-left: 2.5rem;
    background: url('@/assets/icons/search.svg') no-repeat 0.75rem 0.6rem var(--background-0);
  }

  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    li {
      width: 100%;
    }
  }
}

.list {
  ul {
    &::-webkit-scrollbar {
      /* for Firefox */
      display: none;

      /* for Chrome, Opera, Safari, etc. */
      width: 0 !important;
    }
  }
}

.loader {
  padding: var(--space-24) 0;
  margin: 0 auto;
}
</style>
