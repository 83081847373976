<template>
  <div v-if="hasTicketChips" class="ticket-chips-container">
    <template v-for="(ticketChip, index) in ticketChips" :key="`ticket-${index}`">
      <Chip
        :prepend-img="ticketChip.sourceIcon"
        :append-icon="!!ticketChip.ticketHref ? 'expand' : ''"
        :tooltip="ticketChip.ticketTooltip"
        xxsmall
        :light="light"
        class="ticket-chip"
        :hover-mode="!!ticketChip.ticketHref"
        @click="ticketChip.goToTicket($event)"
      >
        Status: {{ ticketChip.status }}
      </Chip>
    </template>
  </div>
</template>

<script>
import { mapStores } from 'pinia';
import Chip from '@/components/foundation/Chip.vue';
import { useTicketStore } from '@/stores/ticket';

export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  components: {
    Chip,
  },
  props: {
    tickets: {
      type: Array,
      default: () => [],
    },
    interaction: {
      type: Object,
      default: null,
    },
    light: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapStores(useTicketStore),
    hasTicketChips() {
      return this.ticketChips?.length > 0;
    },
    ticketChips() {
      return this.ticketStore.getTicketChips({
        tickets: this.tickets,
        communityInteraction: this.interaction,
      });
    },
  },
};
</script>

<style lang="postcss" scoped>
.ticket-chips-container {
  display: flex;
  flex-wrap: wrap;

  .ticket-chip {
    margin: var(--space-2);
    text-transform: capitalize;
  }
}
</style>
