import { FILTER_TYPE } from '@/app/settings/constants/content-automation';
import dayjs from 'dayjs';

// input: { instagram: { can_access_instagram: true ... }, pinterest: {...} }.
// output: { can_access_instagram: true, can_access_pinterest: true }.
export const flattenBrandUserPermissions = (permissions) => {
  const perms = {};
  Object.entries(permissions)
    .filter(([module]) => module !== 'brand_roles')
    .forEach(([, userModulePermissions]) => {
      Object.entries(userModulePermissions).forEach(([permission, value]) => {
        perms[permission] = value;
      });
    });
  return perms;
};

export const flattenIdentityPermissions = (permissions, brand) => {
  const brandUserPermissions = Object.fromEntries(
    Object.entries(permissions)
      .filter(([module]) => module !== 'brand_roles')
      .map(([module, userBrandModulePermissions]) => {
        return [module, userBrandModulePermissions[brand]];
      }),
  );
  return flattenBrandUserPermissions(brandUserPermissions);
};

export const formatUtmDateString = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());
  return `${day}${month}${year}`;
};

export function contentAutomationFilterKey(filter) {
  if (filter.operator !== undefined) {
    const childrenKeys = filter.items.map((child) => contentAutomationFilterKey(child));
    return `(${childrenKeys.join(`-${filter.operator}`)})`;
  }
  if (filter.key === FILTER_TYPE.DATE_PUBLISHED) {
    return `${filter.key}=${filter.value.startDate}–${filter.value.endDate}`;
  }
  return `${filter.key}=${filter.value}`;
}

export function formatContentAutomationDateRange(start, end) {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  if (startDate.isValid() && endDate.isValid()) {
    const currentYear = dayjs().year();
    const startDateLabel =
      startDate.year() === currentYear
        ? startDate.format('MMM D')
        : startDate.format('MMM D, YYYY');
    const endDateLabel =
      endDate.year() === currentYear ? endDate.format('MMM D') : endDate.format('MMM D, YYYY');
    return `${startDateLabel} – ${endDateLabel}`;
  }
  return 'Invalid';
}
