<script setup>
import AdminPermissionRequiredPanel from '@/components/AdminPermissionRequiredPanel.vue';
import Button from '@/components/foundation/Button.vue';
import Popup from '@/components/Popup.vue';

const props = defineProps({
  backAction: {
    type: Function,
    default: null,
  },
  onClose: {
    type: Function,
    required: true,
  },
  type: {
    type: String,
    default: 'xsmall',
  },
  chiliPiperData: {
    type: Object,
    default: null,
  },
});

function onContactClicked() {
  props.onClose();
}
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div>
    <Popup :close="props.onClose" :type="props.type">
      <Button
        v-if="props.backAction"
        back
        rectangular
        icon-name="caret"
        class="absolute left-10 top-10"
        @click="props.backAction"
      />
      <AdminPermissionRequiredPanel
        :chili-piper-data="chiliPiperData"
        @contact-clicked="onContactClicked"
      />
    </Popup>
  </div>
</template>
