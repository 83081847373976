<template>
  <div class="stats">
    <AudienceStatisticBreakdownPopup
      v-if="showAudienceStatisticBreakdownPopup"
      :popup-title="selectedAudienceTabName"
      :statistic="statistic"
      :format="format"
      @close="showAudienceStatisticBreakdownPopup = false"
    />
    <div
      :class="['stats-label-container', { clickable: statistic.statisticBreakdown }]"
      @click.prevent="toggleDropdown"
    >
      <span class="stats-label">{{ statistic.label }}</span>
      <Icon
        v-if="hasStatisticBreakdown"
        :class="['dropdown-toggle', { open: dropdownOpen }]"
        name="caret"
        xxsmall
      />
    </div>
    <div class="bar-container">
      <span class="stacked-bar" :style="{ width: barWidth }" />
      <span class="stats-value">{{ formatStatValue(statistic.value) }}</span>
    </div>
    <CollapsibleContainer
      v-if="hasStatisticBreakdown"
      class="breakdown-container"
      :open="dropdownOpen"
    >
      <ul>
        <li v-for="(stat, index) in statisticBreakdown" :key="index">
          <span class="stat-name indented">{{ stat.label }}</span>
          <span class="stat">{{ formatStatValue(stat.value) }}</span>
        </li>
        <li v-if="showSeeAllButton">
          <Button data-cy="see-all-button" link @click="showAudienceStatisticBreakdownPopup = true">
            See more {{ statistic.altStatName }}
          </Button>
        </li>
      </ul>
    </CollapsibleContainer>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';
import Button from '@/components/foundation/Button.vue';
import CollapsibleContainer from '@/components/CollapsibleContainer.vue';
import AudienceStatisticBreakdownPopup from '@/app/library/components/MediaPopup/AudienceStatisticBreakdownPopup.vue';
import { formatMetric } from '@/utils/formatters';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'AudienceStatSection',
  components: { AudienceStatisticBreakdownPopup, Button, Icon, CollapsibleContainer },
  props: {
    statistic: { type: Object, required: true },
    selectedAudienceTabName: { type: String, default: '' },
    format: { type: String, default: 'number_with_commas' },
  },
  data() {
    return {
      dropdownOpen: false,
      showAudienceStatisticBreakdownPopup: false,
    };
  },
  computed: {
    barWidth() {
      // minimum number of pixels the width of the bar should be
      const minimumBarWidth = 2;
      const calculatedBarWidth = this.calculateBarChartLength(
        this.statistic.barWidthRatio || this.statistic.value,
      );
      return `${Math.max(calculatedBarWidth, minimumBarWidth)}px`;
    },
    hasStatisticBreakdown() {
      const { statisticBreakdown } = this.statistic;
      return statisticBreakdown && statisticBreakdown.length > 0;
    },
    statisticBreakdown() {
      if (!this.hasStatisticBreakdown) {
        return [];
      }

      const { statisticBreakdown, maxToDisplay } = this.statistic;

      if (maxToDisplay) {
        return statisticBreakdown.slice(0, maxToDisplay);
      }

      return statisticBreakdown;
    },
    showSeeAllButton() {
      if (!this.hasStatisticBreakdown) return false;

      const { statisticBreakdown, maxToDisplay } = this.statistic;
      return maxToDisplay && statisticBreakdown.length > maxToDisplay;
    },
  },
  methods: {
    toggleDropdown() {
      if (!this.statistic.statisticBreakdown) {
        return;
      }
      this.dropdownOpen = !this.dropdownOpen;
    },
    calculateBarChartLength(value) {
      return (value * 30000) / 100;
    },
    formatStatValue(value) {
      return formatMetric({ format: this.format, value });
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.stats {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 var(--space-8) var(--space-16) var(--space-36);

  .clickable {
    cursor: pointer;
  }

  .stats-label-container {
    line-height: 1;
    display: flex;
    align-items: center;

    .dropdown-toggle {
      margin-left: 4px;
      transition: var(--transition-all);

      &.open {
        transform: rotate(90deg);
      }
    }

    .stats-label {
      height: var(--x15);
      float: left;
      margin-bottom: var(--space-4);
    }
  }

  .bar-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .stats-value {
      height: var(--x15);
      color: #4990e2;
      line-height: 1;
      padding-left: var(--space-6);
    }

    .stacked-bar {
      height: var(--x10);
      background: var(--action-500);
      border-radius: var(--space-2);
    }
  }

  .breakdown-container {
    width: 100%;
    margin: var(--space-12) 0 0 var(--space-16);

    .stat-name {
      padding-right: var(--space-8);
    }

    span {
      display: flex;
      align-items: center;

      svg {
        margin-left: var(--space-8);
      }
    }

    li {
      display: flex;
      justify-content: space-between;
      margin: var(--space-8) 0;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span + span {
        float: right;
        padding-left: var(--space-8);
        background: var(--background-300);
      }

      .stat {
        color: var(--action-500);
      }
    }

    li::before {
      float: left;
      width: 0;
      white-space: nowrap;
      color: var(--border);
    }

    li:first-child {
      margin-top: 0;
    }
  }
}
</style>
