<script setup>
import Chip from '@/components/foundation/Chip.vue';
import { xssEscapeHtml } from '@/utils/xss';

const props = defineProps({
  tags: {
    type: Array,
    default: () => [],
  },
  otherTags: {
    type: Array,
    default: () => [],
  },
  preventActions: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['add', 'remove']);

function extractTagName(tag) {
  return tag?.name ?? tag;
}

function onAddClick() {
  if (props.preventActions) {
    return;
  }
  emit('add');
}
function onRemoveClick(tag) {
  if (props.preventActions) {
    return;
  }
  emit('remove', tag);
}
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div class="flex flex-wrap items-center gap-2" data-cy="popup-tags">
    <Chip
      ref="addButton"
      small
      color="var(--background-500)"
      prepend-icon="add"
      prepend-icon-color="var(--text-primary)"
      :icon-props="{ xxsmall: true, xsmall: false, small: false }"
      data-cy="TagsBaseAddButton"
      @click.prevent="onAddClick"
    />
    <slot name="prependTags" />
    <template v-for="tag in props.tags" :key="tag.id">
      <Chip
        v-tooltip="xssEscapeHtml(extractTagName(tag))"
        small
        data-cy="popup-tag"
        :loading-right="!tag.id || tag.deleting"
        :remove="!!tag.id"
        color="var(--background-500)"
        text-color="var(--text-primary)"
        truncated-text-width="var(--width-196)"
        @remove="onRemoveClick(tag)"
      >
        {{ extractTagName(tag) }}
      </Chip>
    </template>
    <template v-for="(tag, index) in props.otherTags" :key="index">
      <Chip
        v-tooltip="xssEscapeHtml(extractTagName(tag))"
        small
        color="var(--background-400)"
        text-color="var(--text-secondary)"
        truncated-text-width="var(--width-196)"
      >
        {{ extractTagName(tag) }}
      </Chip>
    </template>
    <slot name="appendTags" />
  </div>
</template>
