import { ref } from 'vue';
import { defineStore } from 'pinia';
import dayjs from 'dayjs';
import * as LibraryAPI from '@/apis/library';
import { useAuthStore } from '@/stores/auth';
import enumTypes from '@/app/library/constants';
import { refreshAbortController, handleCancelError } from '@/apis/axios.utils';

function formatSegmentMedia(mediaList) {
  // VIQ media is formatted with a set width/height to display as a square in the UI
  return mediaList.map((media) => ({
    id: media.mediaId,
    duration: media.duration,
    url: media.urls.ratio,
    urls: media.urls,
    mediaType: media.mediaType,
    sourceType: media.type,
    sourceAccountId: media.sourceAccountId,
    width: 160,
    height: 160,
    source: media.source,
    postType: media?.sourceData?.type,
    sourceId: media.sourceId,
    imageUrl: media.urls ? media.urls.ratio : null,
    postDate: dayjs(media.createdAt).format('MMMM D h:m a'),
    predictions: media.predictions,
  }));
}

function checkForLimitTypeError(error, brandId) {
  return (
    error && error.includes(`The segment cluster limit for brand ${brandId} has been reached.`)
  );
}

export const useInstagramViqStore = defineStore(
  'instagramViq',
  () => {
    const authStore = useAuthStore();

    const segmentList = ref([]);
    const segmentListLoaded = ref(false);
    const segmentMedia = ref(null);
    const trendGroup = ref(null);
    const trendGroups = ref([]);
    const trendGroupSelectedTags = ref([]);
    const segmentClusters = ref([]);
    const segmentClustersCount = ref(null);
    const segmentClustersNextUrl = ref(null);
    const segmentCluster = ref(null);
    const segmentClusterTags = ref([]);
    const segmentClusterSearchTerm = ref(null);
    const segmentClusterSelectedTags = ref(null);
    const segmentClusterSort = ref('-CREATED');
    const showAccountEdit = ref(false);
    const accountLimitMet = ref(false);
    const showAddAccountError = ref(false);
    const segmentClusterTagsFilterType = ref(null);
    const editTagsPopupVisible = ref(false);
    const newSegmentCluster = ref(null);
    const fullTrendTagList = ref([]);
    const segmentStats = ref(null);
    const segmentClusterStats = ref(null);

    const abortControllers = {
      getSegmentClusters: ref(null),
      getSegmentList: ref(null),
    };

    const pending = ref({
      account: false,
      createSegmentClusterStatus: false,
      updateSegmentClusterStatus: false,
      trendGroup: false,
      segmentClusters: false,
      trendGroups: false,
    });
    const error = ref({
      account: null,
      trendGroup: null,
      createSegmentClusterStatus: null,
    });

    function clearSegmentList() {
      segmentList.value = [];
      segmentListLoaded.value = false;
    }

    function clearSegmentMedia() {
      segmentMedia.value = null;
    }

    function clearTrendGroup() {
      trendGroup.value = null;
    }

    function clearTrendGroups() {
      trendGroups.value = [];
    }

    function setTrendGroupSelectedTags(tags) {
      trendGroupSelectedTags.value = tags;
    }

    function clearViqList() {
      segmentClusters.value = [];
      segmentClustersCount.value = null;
      segmentClustersNextUrl.value = null;
    }

    function clearSegmentCluster() {
      segmentCluster.value = null;
    }

    function clearSegmentClusterTags() {
      segmentClusterTags.value = [];
    }

    function setSegmentClusterSearch(searchTerm) {
      segmentClusterSearchTerm.value = searchTerm;
    }

    function clearSegmentClusterSearch() {
      segmentClusterSearchTerm.value = null;
    }

    function setSegmentClusterSelectedTags(tags) {
      if (tags && tags.length > 0) {
        segmentClusterSelectedTags.value = [...tags];
      } else {
        segmentClusterSelectedTags.value = [];
      }
    }

    function clearSegmentClusterSelectedTags() {
      segmentClusterSelectedTags.value = null;
    }

    function setSegmentClusterSort(sort) {
      segmentClusterSort.value = sort;
    }

    function clearSegmentClusterSort() {
      segmentClusterSort.value = '-CREATED';
    }

    function openAccountEdit() {
      showAccountEdit.value = true;
    }

    function closeAccountEdit() {
      showAccountEdit.value = false;
    }

    function displayAddAccountError() {
      showAddAccountError.value = true;
    }

    function hideAddAccountError() {
      showAddAccountError.value = false;
      accountLimitMet.value = false;
    }

    function setSegmentClusterTagsFilterType(type) {
      segmentClusterTagsFilterType.value = type;
    }

    function clearSegmentClusterTagsFilterType() {
      segmentClusterTagsFilterType.value = null;
    }

    function showEditTagsPopup() {
      editTagsPopupVisible.value = true;
    }

    function hideEditTagsPopup() {
      editTagsPopupVisible.value = false;
    }

    function clearNewSegmentCluster() {
      newSegmentCluster.value = null;
    }

    async function getTrendGroup({ trendGroupId }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.trendGroup = true;
      error.value.trendGroup = null;
      try {
        const response = await LibraryAPI.getTrendGroup({ brandId, trendGroupId });
        const payload = response?.data;
        trendGroup.value = payload;
        return payload;
      } catch (e) {
        error.value.trendGroup = e;
        throw e;
      } finally {
        pending.value.trendGroup = false;
      }
    }

    async function getTrendGroups({ source }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.trendGroups = true;
      try {
        const response = await LibraryAPI.getTrendGroups({ brandId, source });
        const payload = response?.data;
        trendGroups.value = [...trendGroups.value, ...payload];
        return payload;
      } finally {
        pending.value.trendGroups = false;
      }
    }

    async function getFullTrendTagList({ activeOnly }) {
      const brandId = authStore.currentBrand?.id;

      const response = await LibraryAPI.getTrendGroupTags({ brandId, activeOnly });
      const payload = response?.data;
      fullTrendTagList.value = payload;
      return payload;
    }

    async function createTrendGroup({ name, description, tags, targetSource }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.trendGroup = true;
      error.value.trendGroup = null;
      try {
        const response = await LibraryAPI.createTrendGroup({
          brandId,
          name,
          description,
          tags,
          targetSource,
        });
        const payload = response?.data;
        trendGroup.value = payload;
        trendGroups.value = [payload, ...trendGroups.value];
        return payload;
      } catch (e) {
        error.value.trendGroup = e;
        throw e;
      } finally {
        pending.value.trendGroup = false;
      }
    }

    async function updateTrendGroup({ trendGroupId, name, description, tags }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.trendGroup = true;
      error.value.trendGroup = null;
      try {
        const response = await LibraryAPI.updateTrendGroup({
          brandId,
          trendGroupId,
          name,
          description,
          tags,
        });
        const payload = response?.data;

        const targetIndex = trendGroups.value.findIndex((trend) => trend.id === trendGroupId);
        trendGroup.value = payload;
        // only replace list when list exist
        // skip if loading from detail page url directly
        if (targetIndex > -1) {
          const listObj = trendGroups.value[targetIndex];
          // payload data has fewer fields than list data, (i.e, last_media_urls)
          // so just update field with new info, not replace the object directly.
          Object.assign(listObj, payload);
          trendGroups.value.splice(targetIndex, 1, listObj);
        }

        return payload;
      } catch (e) {
        error.value.trendGroup = e;
        throw e;
      } finally {
        pending.value.trendGroup = false;
      }
    }

    async function deleteTrendGroup({ trendGroupId }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.trendGroup = true;
      error.value.trendGroup = null;
      try {
        const response = await LibraryAPI.deleteTrendGroup({ brandId, trendGroupId });
        const payload = response?.data;

        const targetIndex = trendGroups.value.findIndex((trend) => trend.id === trendGroupId);
        trendGroups.value.splice(targetIndex, 1);
        trendGroup.value = null;

        return payload;
      } catch (e) {
        error.value.trendGroup = e;
        throw e;
      } finally {
        pending.value.trendGroup = false;
      }
    }

    async function deleteAccountFromTrendGroup({ trendGroupId, accountId }) {
      const brandId = authStore.currentBrand?.id;

      const response = await LibraryAPI.removeTrendGroupAccount({
        brandId,
        trendGroupId,
        accountId,
      });
      const payload = response?.data;

      return payload;
    }

    async function getSegmentClusters({ sort, tags, tagsFilterType, search, url }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.segmentClusters = true;
      try {
        const signal = refreshAbortController(abortControllers.getSegmentClusters);
        const response = await LibraryAPI.default.getSegmentClusters(
          {
            brandId,
            sources: enumTypes.INSTAGRAM,
            limit: 100,
            sort,
            tags,
            tagsFilterType,
            search,
            url,
            summary: false,
          },
          { signal, camelizeKeys: false },
        );
        const payload = response?.data;

        if (payload.data) {
          segmentClusters.value = [...segmentClusters.value, ...payload.data];
          segmentClustersCount.value = payload?.paging?.count;
          segmentClustersNextUrl.value = payload?.paging?.next;
        } else if (payload) {
          segmentClusters.value = payload;
        }

        return payload;
      } catch (e) {
        handleCancelError(e);
      } finally {
        pending.value.segmentClusters = false;
      }
      return undefined;
    }

    async function getSegmentCluster({ segmentClusterId, isUgc, period, sort, resolution }) {
      const brandId = authStore.currentBrand?.id;

      const response = await LibraryAPI.getSegmentCluster({
        brandId,
        segmentClusterId,
        isUgc,
        period,
        sort,
        resolution,
      });
      const payload = response?.data;
      segmentCluster.value = payload;
      return payload;
    }

    async function updateSegmentCluster({ segmentClusterId, tags }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.updateSegmentClusterStatus = true;
      try {
        const response = await LibraryAPI.updateSegmentCluster({ brandId, segmentClusterId, tags });
        const payload = response?.data;

        return payload;
      } finally {
        pending.value.updateSegmentClusterStatus = false;
      }
    }

    async function getSegmentList({
      sort,
      period,
      resolution,
      sourceId,
      mediaCount,
      isUgc,
      brand,
      token,
    }) {
      const brandId = authStore.currentBrand?.id;
      const source = enumTypes.INSTAGRAM;

      segmentList.value = [];
      segmentListLoaded.value = false;
      try {
        const signal = refreshAbortController(abortControllers.getSegmentList);
        const response = await LibraryAPI.getSegments(
          {
            brandId,
            source,
            sourceId,
            sort,
            period,
            resolution,
            mediaCount,
            isUgc,
            brand,
            token,
          },
          { signal },
        );
        const payload = response?.data;

        segmentList.value = payload.map((segment) => {
          const newSegment = segment;
          newSegment.media = formatSegmentMedia(segment.media);
          return newSegment;
        });

        segmentListLoaded.value = true;
        return payload;
      } catch (e) {
        handleCancelError(e);
      }
      return undefined;
    }

    async function getSegmentMedia({ segmentId }) {
      const brandId = authStore.currentBrand?.id;

      const response = await LibraryAPI.getSegment({ brandId, segmentId });
      const payload = response?.data;

      segmentMedia.value = formatSegmentMedia(payload.media);

      return payload;
    }

    async function getSegmentStats({ sourceId, isUgc }) {
      const brandId = authStore.currentBrand?.id;
      const source = enumTypes.INSTAGRAM;
      const response = await LibraryAPI.getSegmentStats({ brandId, source, sourceId, isUgc });
      const payload = response?.data;
      segmentStats.value = payload;
      return payload;
    }

    async function getSegmentClusterStats({ clusterId, period, resolution, sourceId }) {
      const brandId = authStore.currentBrand?.id;

      const response = await LibraryAPI.getSegmentClusterStats({
        brandId,
        clusterId,
        period,
        resolution,
        sourceId,
      });
      const payload = response?.data;
      segmentClusterStats.value = payload;
      return payload;
    }

    async function getSegmentClusterTags() {
      const brandId = authStore.currentBrand?.id;

      const response = await LibraryAPI.getSegmentClusterTags({ brandId, activeOnly: 1 });
      const payload = response?.data;
      segmentClusterTags.value = payload;
      return payload;
    }

    async function createSegmentCluster({ targetSourceId }) {
      const brandId = authStore.currentBrand?.id;

      pending.value.createSegmentClusterStatus = true;
      error.value.createSegmentClusterStatus = null;
      try {
        const response = await LibraryAPI.default.createSegmentCluster({
          brandId,
          targetSource: enumTypes.INSTAGRAM,
          targetSourceId,
        });
        const payload = response?.data;
        newSegmentCluster.value = payload;
        return payload;
      } catch (e) {
        error.value.createSegmentClusterStatus = e;
        if (checkForLimitTypeError(e?.response?.data?.description, brandId)) {
          accountLimitMet.value = true;
        }
        throw e;
      } finally {
        pending.value.createSegmentClusterStatus = false;
      }
    }

    async function removeSegmentClusterTag({ clusterId, tagId }) {
      const brandId = authStore.currentBrand?.id;

      const response = await LibraryAPI.removeSegmentClusterTag({ brandId, clusterId, tagId });
      const payload = response?.data;

      return payload;
    }

    async function deleteSegmentCluster({ clusterId }) {
      const brandId = authStore.currentBrand?.id;

      const response = await LibraryAPI.deleteSegmentCluster({ brandId, clusterId });
      const payload = response?.data;

      return payload;
    }

    async function getTrendGroupStats({ period, resolution, sourceId, trendId }) {
      const brandId = authStore.currentBrand?.id;

      const response = await LibraryAPI.getTrendGroupStats({
        brandId,
        period,
        resolution,
        sourceId,
        trendId,
      });
      const payload = response?.data;
      segmentClusterStats.value = payload;
      return payload;
    }

    async function addAccountToTrendGroup({ trendGroupId, accountId }) {
      const brandId = authStore.currentBrand?.id;
      pending.value.account = true;
      error.value.account = null;
      try {
        const response = await LibraryAPI.addTrendGroupAccount({
          brandId,
          trendGroupId,
          accountId,
        });
        const payload = response?.data;

        return payload;
      } catch (e) {
        error.value.account = e;
        if (checkForLimitTypeError(e?.response?.data?.description, brandId)) {
          accountLimitMet.value = true;
        }
        throw e;
      } finally {
        pending.value.account = false;
      }
    }

    async function removeTrendGroupTag({ trendGroupId, tagId }) {
      const brandId = authStore.currentBrand?.id;

      const response = await LibraryAPI.removeTrendGroupTag({ brandId, trendGroupId, tagId });
      const payload = response?.data;

      const targetTrend = trendGroups.value.find((trend) => trend.id === trendGroupId);
      if (targetTrend) {
        targetTrend.tags = payload;
      }
      trendGroup.value.tags = payload;

      return payload;
    }

    return {
      pending,
      error,
      segmentList,
      segmentListLoaded,
      segmentMedia,
      trendGroup,
      trendGroups,
      trendGroupSelectedTags,
      segmentClusters,
      segmentClustersCount,
      segmentClustersNextUrl,
      segmentCluster,
      segmentClusterTags,
      segmentClusterSearchTerm,
      segmentClusterSelectedTags,
      segmentClusterSort,
      showAccountEdit,
      accountLimitMet,
      showAddAccountError,
      segmentClusterTagsFilterType,
      editTagsPopupVisible,
      newSegmentCluster,
      fullTrendTagList,
      segmentStats,
      segmentClusterStats,
      clearSegmentList,
      clearSegmentMedia,
      clearTrendGroup,
      clearTrendGroups,
      setTrendGroupSelectedTags,
      clearViqList,
      clearSegmentCluster,
      clearSegmentClusterTags,
      setSegmentClusterSearch,
      clearSegmentClusterSearch,
      setSegmentClusterSelectedTags,
      clearSegmentClusterSelectedTags,
      setSegmentClusterSort,
      clearSegmentClusterSort,
      openAccountEdit,
      closeAccountEdit,
      displayAddAccountError,
      hideAddAccountError,
      setSegmentClusterTagsFilterType,
      clearSegmentClusterTagsFilterType,
      showEditTagsPopup,
      hideEditTagsPopup,
      clearNewSegmentCluster,
      getTrendGroup,
      getTrendGroups,
      getFullTrendTagList,
      createTrendGroup,
      updateTrendGroup,
      deleteTrendGroup,
      deleteAccountFromTrendGroup,
      getSegmentClusters,
      getSegmentCluster,
      updateSegmentCluster,
      getSegmentList,
      getSegmentMedia,
      getSegmentStats,
      getSegmentClusterStats,
      getSegmentClusterTags,
      createSegmentCluster,
      removeSegmentClusterTag,
      deleteSegmentCluster,
      getTrendGroupStats,
      addAccountToTrendGroup,
      removeTrendGroupTag,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
