<template>
  <section class="color-filter">
    <header>
      <Icon name="colorPalette" small />
      Color
    </header>
    <section class="any-color" @click.prevent="handleClickAny">
      <span>Any</span>
      <Icon v-if="!currentFilter" :color="blueCheck" name="check" small />
    </section>
    <main>
      <div
        v-for="(color, index) in colors"
        :key="index"
        :class="['color', { 'border-color': color.hex === '#FFFFFF' }]"
        :style="{ background: color.hex }"
        @click.prevent="handleClickColor(color, index)"
      >
        <Icon
          v-if="currentFilter === color"
          :color="color.hex === '#FFFFFF' ? '#000000' : '#ffffff'"
          name="check"
          xsmall
        />
      </div>
    </main>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useTrackingStore } from '@/stores/tracking';
import { filterColorOptions } from '@/config';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ColorFilter',
  components: {
    Icon,
  },
  emits: ['filterSelected'],
  data() {
    return {
      blueCheck: colours.ACTION.ACTION_500,
      currentFilter: null,
      colors: filterColorOptions,
    };
  },
  computed: {
    ...mapStores(useTrackingStore),
  },
  methods: {
    updateFilter(index) {
      if (index === 'any') {
        this.handleClickAny();
      } else if (index < filterColorOptions.length) {
        this.currentFilter = filterColorOptions[index];
      }
    },
    handleClickColor(color, index) {
      if (this.currentFilter !== color) {
        this.currentFilter = color;
        this.$emit('filterSelected', index);
        this.trackingStore.track('Search Filter Changed', { filter: 'Color', filterValue: color });
      } else {
        this.currentFilter = null;
        this.$emit('filterSelected', null);
      }
    },
    handleClickAny() {
      this.currentFilter = null;
      this.$emit('filterSelected', null);
      this.trackingStore.track('Search Filter Changed', { filter: 'Color', filterValue: 'any' });
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.color-filter {
  padding: 0 var(--space-24);
}

header {
  display: flex;
  align-items: center;
  color: var(--text-secondary);

  .svg-icon {
    margin-right: 1.25rem;
  }
}

.color {
  height: 2rem;
  width: 2rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition-all);
  transform: translateY(0);

  &.border-color {
    border: 1px solid var(--border);
  }

  &:hover {
    transform: var(--hover-move);
  }

  .svg-icon {
    margin-right: 0;
  }
}

section.any-color {
  display: flex;
  align-items: center;
  color: var(--text-primary);
  padding: var(--space-8) 0 0 var(--space-40);
  cursor: pointer;
  margin-left: var(--space-4);

  span {
    cursor: pointer;
    transition: var(--transition-all);

    &:hover {
      color: var(--action-500);
    }
  }

  .svg-icon {
    margin-left: var(--space-16);
  }
}

main {
  display: grid;
  grid-template-columns: repeat(auto-fill, 2rem);
  grid-gap: var(--space-8);
  padding: var(--space-16) 0 var(--space-24) var(--space-40);
  margin-left: var(--space-4);
}
</style>
