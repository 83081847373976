<template>
  <div
    v-on-click-outside="handleClickOutside"
    data-cy="expandable-search-container"
    :style="isExpanded && { width: `${expandedWidth}rem` }"
    :class="['search-container', { expanded, compact, large }, { 'grey-border': resetBorder }]"
    @click="handleClick"
    @keydown.esc.stop="handleEscapeKeyUp"
  >
    <button :class="['search-button', { compact, large }]" @click="handleClick">
      <Icon v-bind="{ [prependIconSize]: true }" name="search" />
    </button>
    <TextInput
      v-if="isExpanded"
      ref="expanded-search-input"
      v-bind="$attrs"
      v-on-click-outside="handleClickOutside"
      :class="['search-input', { compact, large }]"
      :focus="expanded"
      :compact="compact"
      :large="large"
      :clearable="clearable"
      :label="null"
      @update:model-value="onInput"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { vOnClickOutside } from '@vueuse/components';
import Icon from '@/components/foundation/Icon.vue';
import TextInput from '@/components/foundation/form/TextInput.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ExpandableSearch',
  components: {
    Icon,
    TextInput,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    /**
     * Use a compact styling for the text input.
     */
    compact: {
      type: Boolean,
      default: false,
    },
    /**
     * Use a large styling for the text input.
     */
    large: {
      type: Boolean,
      default: false,
    },
    /**
     * Adds a clearable button on the right to allow users to clear the input.
     */
    clearable: {
      type: Boolean,
      default: false,
    },
    /**
     * Number in rem to use for the max expanded width of the input.
     */
    expandedWidth: {
      type: Number,
      default: 20,
    },
    stayOpen: {
      type: Boolean,
      default: false,
    },
    expandByDefault: {
      type: Boolean,
      default: false,
    },
    enableEscKey: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['input'],
  data() {
    return {
      expanded: false,
      resetBorder: false,
    };
  },
  computed: {
    prependIconSize() {
      if (this.compact) return 'xxsmall';
      if (!this.compact && !this.large) return 'xsmall';
      return 'medium';
    },
    isExpanded() {
      return Boolean(this.expanded || this.expandByDefault || this.$attrs.value);
    },
  },
  watch: {},
  methods: {
    handleClick() {
      this.resetBorder = false;
      if (!this.expanded) {
        this.expanded = true;
      }
    },
    handleClickOutside() {
      if (this.expanded && !this.stayOpen && !this.$attrs.value) {
        this.expanded = false;
      }
      if (this.expandByDefault) {
        this.resetBorder = true;
      }
    },
    handleEscapeKeyUp(e) {
      if (this.enableEscKey) {
        const ESCAPE_KEY = 27;
        const key = e.which || e.keyCode;
        if (key === ESCAPE_KEY) {
          this.$emit('input', null);
        }
      } else {
        e.preventDefault();
      }
    },
    onInput(e) {
      this.$emit('input', e);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.search-container {
  display: flex;
  justify-content: left;
  align-items: center;
  transition: all 0.3s;
  border-radius: var(--button-border-radius);
  overflow: visible;
  padding-right: 5px;
  background-color: var(--background-0);

  &.expanded {
    height: var(--input-height);
    border: 1px solid var(--action-500);

    &.grey-border {
      border: 1px solid var(--border);
    }

    &.compact {
      height: var(--input-height-compact);
    }

    &.large {
      height: var(--input-height-large);
    }
  }

  &:not(.expanded) {
    width: var(--input-height);
    height: var(--input-height);
    border: 1px solid var(--background-500);

    &.compact {
      width: var(--input-height-compact);
      height: var(--input-height-compact);
    }

    &.large {
      width: var(--input-height-large);
      height: var(--input-height-large);
    }
  }
}

.search-button {
  background: var(--background-0);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: var(--button-border-radius);
  width: var(--input-height);
  margin-left: 2px;

  &.compact {
    width: var(--input-height-compact);
  }

  &.large {
    margin-left: var(--space-4);
  }
}

.search-input {
  outline: none;
  height: calc(var(--input-height) - 0.2rem);
  border-radius: 0 15px 15px 0;
  margin: 0;
  padding: 0;
  overflow: hidden;

  &.compact {
    height: calc(var(--input-height-compact) - 0.3rem);
  }

  &.large {
    height: calc(var(--input-height-compact) - 0.2rem);
  }

  :deep(.text-input-wrapper) {
    border: none;
    height: calc(var(--input-height) - 0.3rem);

    &.compact {
      height: calc(var(--input-height-compact) - 0.3rem);
    }

    &.large {
      height: calc(var(--input-height-compact) - 0.2rem);
    }

    input {
      &.large {
        font-size: var(--x14) !important;
      }

      padding: var(--space-10) var(--space-10) var(--space-10) 0;
    }
  }
}
</style>
