<template>
  <div
    ref="calendarContainer"
    :class="{ compact: type === 'compact' }"
    class="scheduler-calendar fs-ignore-rage-clicks"
  >
    <CalendarTimeSelect
      v-if="timeSelectDatetime && showCalendarTimeSelect"
      :datetime="timeSelectDatetime"
      :offset-top="timeSelectOffsetTop"
      :offset-left="timeSelectOffsetLeft"
      :time-selected="onTimeSelected"
      :tooltip="disabledTooltip"
      :disabled="!isAppConnected"
      :restrict-selection-to-interval="restrictSelectionToInterval"
    />
    <CalendarDurationSelect
      :mode="currentView"
      :date="currentViewStartDate"
      @update:date="weekSelected"
    />

    <div v-on-click-outside="onFullCalendarClickOutside">
      <FullCalendar
        ref="fullCalendar"
        :options="config"
        data-cy="full-calendar"
        @click="calendarClicked"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import capitalize from 'lodash/capitalize';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import { vOnClickOutside } from '@vueuse/components';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useAuthStore } from '@/stores/auth';
import { useTrackingStore } from '@/stores/tracking';
import { useNotificationStore } from '@/stores/notification';
import CalendarTimeSelect from '@/app/scheduler/components/CalendarTimeSelect.vue';
import CalendarDurationSelect from '@/app/scheduler/components/CalendarDurationSelect.vue';
import {
  postTypes,
  calendarDragReschedulingFailureNotificationMessage,
  calendarDragReschedulingFailureNotificationSubtext,
  twitterMentionLimitExceededText,
  publishDateRangeError,
  publishDateOutOfRangeSubtext,
  noOverlapPublishDateSubtext,
  DATE_RESTRICTION_ERRORS,
  postStatus,
} from '@/app/scheduler/constants';
import { SchedulerUserEventTracker } from '@/app/scheduler/mixpanel';
import { selectorContainsEventTarget } from '@/utils/dom';
import { usePlatformStore } from '@/stores/platform';
import { useSchedulerStore } from '@/stores/scheduler';
import { formatDateRangeLabel } from '@/utils/formatters';
import {
  areIntervalsOverlapping,
  FAR_FUTURE,
  isValidInterval,
  intersectIntervals,
  ALL_TIME,
  getFutureAsInterval,
} from '@/utils/dateUtils';
import { toolTips } from '@/config';
import { useFlagStore } from '@/stores/flag';
import { useParentElement, useElementSize } from '@vueuse/core';
import { formatPost, twitterMentionLimitExceeded, isPublishDateError } from '../utils';

// Get the position of an element relative to the document
function offset(el, clickEvent) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  // Adjust the left position if clicking time block at the edge of the calendar container
  const containerRightBorderX = rect.right;
  const timeSelectWidth = 240;
  const timeSelectRightBorderX = clickEvent.pageX + timeSelectWidth;

  let offsetContainerWidthOverflow = 0;
  if (containerRightBorderX < timeSelectRightBorderX) {
    offsetContainerWidthOverflow = timeSelectWidth;
  }

  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  // Adjust the top position if clicking time block at the bottom edge of the calendar container
  const containerBottomBorderY = rect.bottom;
  const timeSelectHeight = 130;
  const timeSelectBottomBorderY = clickEvent.pageY + timeSelectHeight;

  let offsetContainerHeightOverflow = 0;
  if (containerBottomBorderY < timeSelectBottomBorderY) {
    offsetContainerHeightOverflow = timeSelectHeight;
  }
  return {
    top: rect.top + scrollTop + offsetContainerHeightOverflow,
    left: rect.left + scrollLeft + offsetContainerWidthOverflow,
  };
}

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'Calendar',
  components: {
    CalendarTimeSelect,
    CalendarDurationSelect,
    FullCalendar,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    type: { type: String, default: 'full' },
    platform: { type: String, required: true },
    // Either an array of event data or function for fetching event data should be provided
    posts: { type: Array, default: null },
    onFetchPosts: { type: Function, default: null },
    onUpdatePost: { type: Function, default: null },
    idealPostTimes: { type: Array, default: () => [] },
    // Ensure that only dates/times within this interval can be selected.
    restrictSelectionToInterval: {
      type: Object,
      default: () => ALL_TIME,
      validator: (value) => value === false || isValidInterval(value),
    },
    // Events are optional since they're not displayed in the compact version
    events: { type: Array, default: () => [] },
    timeSelected: { type: Function, default: null },
    disableScheduleOnClick: { type: Boolean, default: false },
  },
  setup() {
    const parentEl = useParentElement();
    const { width: parentWidth } = useElementSize(parentEl);
    return { parentWidth };
  },
  data() {
    return {
      currentView: null,
      currentViewStartDate: null,
      timeSelectDatetime: null,
      timeSelectOffsetTop: 0,
      timeSelectOffsetLeft: 0,
      showCalendarTimeSelect: false,
      eventSources: {
        posts: null,
        idealPostTimes: null,
        events: null,
        pastBackgroundEvent: null,
        restrictedBackgroundEvent: null,
      },
      config: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'timeGridWeek',
        editable: this.type === 'full',
        droppable: true,
        headerToolbar: {
          left: '',
          center: '',
          right: 'timeGridWeek,dayGridMonth,prev,next',
        },
        height: this.type === 'compact' ? '30rem' : 'auto',
        defaultTimedEventDuration: '01:00:00',
        timeZone: 'local',
        eventConstraint: {
          // Prevent users from dragging events into the past
          start: new Date(),
          end: new Date(2100, 0),
        },
        selectAllow: (selectInfo) => {
          // Restrict selections in calendar to 1 hour periods
          if (this.currentView === 'week') {
            const start = dayjs(selectInfo.start);
            const end = dayjs(selectInfo.end);
            const duration = dayjs.duration(end.diff(start));
            return duration.asHours() === 1;
          }
          return true;
        },
        views: {
          timeGrid: {
            slotDuration: '01:00:00',
            eventTimeFormat: {
              hour: '2-digit',
              minute: '2-digit',
              meridiem: false,
            },
            dayHeaderContent: ({ date }, createElement) => {
              const d = dayjs(date);
              return createElement(
                'div',
                {},
                createElement('span', { className: 'day' }, d.format('D')),
                createElement('span', { className: 'day-name' }, d.format('ddd')),
              );
            },
            selectable: true,
          },
          dayGridMonth: {
            eventTimeFormat: {
              hour: 'numeric',
              minute: '2-digit',
              meridiem: 'short',
            },
            // threshold for 'show more' button
            dayMaxEventRows: 4,
            dayPopoverFormat: {
              day: 'numeric',
              weekday: 'short',
            },
            fixedWeekCount: false,
            selectable: true,
          },
        },
        datesSet: this.timeRangeUpdated,
        select: this.select,
        eventClick: this.eventClicked,
        eventDrop: this.eventDropped,
        eventReceive: this.eventReceived,
        viewDidMount: this.viewChanged,
        eventDidMount: ({ event, el }) => {
          if (window.innerWidth <= 1400) {
            el.classList.add('fc-narrow');
          } else {
            el.classList.add('fc-full');
          }
          if (event.extendedProps.type === 'idealPostTime') {
            if (!areIntervalsOverlapping(event, this.allowableInterval)) {
              // Hide ideal post times in the past
              el.style.display = 'none';
            } else {
              el.classList.add('fc-ideal-post-time');
              el.dataset.engagement = event.extendedProps.engagement;
            }
          } else if (event.extendedProps.type === 'post') {
            el.classList.add('fc-post');
            if (this.platform === 'twitter' && !event.extendedProps.imageUrl) {
              // Set style for twitter post placeholder
              el.classList.add('twitter-placeholder');
            }
            if (this.platform === 'twitter' && event.extendedProps.fullObj?.replies.length > 0) {
              el.classList.add('fc-thread');
            }
            if (event.extendedProps.fullObj && event.extendedProps.fullObj.thumbnail_url) {
              // Set background image for posts
              el.style.backgroundImage = `url(${event.extendedProps.fullObj.thumbnail_url})`;
            } else if (event.extendedProps.imageUrl) {
              // Set background image for posts
              el.style.backgroundImage = `url(${event.extendedProps.imageUrl})`;
            }
            switch (event.extendedProps.fullObj.post_type) {
              case postTypes.STORY:
                el.classList.add('fc-story');
                break;
              case postTypes.REEL:
                el.classList.add('fc-reel');
                break;
              default:
                break;
            }
            // Grey out past posts
            if (event.start < new Date()) {
              el.classList.add('fc-past-event');
            }
          }
          // Grey out events not finished yet
          else if (event.extendedProps.type === 'event') {
            el.title = event.title;
            if (event.end < new Date()) {
              el.classList.add('fc-past-event');
            }
          }
        },
      },
    };
  },
  computed: {
    ...mapStores(
      useNotificationStore,
      useTrackingStore,
      usePlatformStore,
      useSchedulerStore,
      useAuthStore,
      useFlagStore,
    ),
    hasDraftsFeatureFlag() {
      return this.flagStore.ready && this.flagStore.flags.schedulerDrafts;
    },
    allowableInterval() {
      return intersectIntervals(getFutureAsInterval(), this.restrictSelectionToInterval);
    },
    restrictedFutureIntervals() {
      if (!this.restrictSelectionToInterval || this.restrictSelectionToInterval.end <= new Date()) {
        return [getFutureAsInterval()];
      }
      if (this.restrictSelectionToInterval.start <= new Date()) {
        return [
          {
            start: this.restrictSelectionToInterval.end,
            end: FAR_FUTURE,
          },
        ];
      }
      return [
        {
          start: new Date(),
          end: this.restrictSelectionToInterval.start,
        },
        {
          start: this.restrictSelectionToInterval.end,
          end: FAR_FUTURE,
        },
      ];
    },
    connection() {
      const platformConnection = this.platformStore.platformConnectionsMap[this.platform];
      return platformConnection && platformConnection[this.authStore.currentBrand.id];
    },
    isAppConnected() {
      switch (this.platform) {
        case 'twitter':
          return this.connection && this.connection.status === 'connected';
        case 'linkedin':
          return this.connection && this.connection.status === 'connected';
        default:
          return true;
      }
    },
    disabledTooltip() {
      if (!this.isAppConnected) {
        if (this.platform === 'twitter') {
          const verb = this.connection ? 'reconnect' : 'connect';
          return `To schedule a post, you need to ${verb} your ${capitalize(
            this.platform,
          )} account.`;
        }
        if (this.platform === 'linkedin') {
          return toolTips.noLinkedinConnection;
        }
      }
      return null;
    },
    trackMixpanel() {
      return new SchedulerUserEventTracker(this.platform);
    },
    canAccessIdealPostTimes() {
      return this.authStore.brand_can('scheduler', 'can_access_ideal_post_times');
    },
    fullCalendar() {
      return this.$refs.fullCalendar.getApi();
    },
  },
  watch: {
    posts() {
      this.refreshPosts();
    },
    idealPostTimes() {
      this.refreshIdealPostTimes();
    },
    events() {
      this.refreshEvents();
    },
    currentView(to, from) {
      if (from && to !== from) {
        this.trackMixpanel.calendarInteraction(
          'Calendar Mode',
          this.currentView,
          this.currentViewStartDate,
          0,
        );
      }
    },
    parentWidth() {
      this.fullCalendar.updateSize();
    },
  },
  mounted() {
    this.$refs.calendarContainer.addEventListener('mouseover', this.showTooltip);
    this.$refs.calendarContainer.addEventListener('mouseout', this.removeTooltip);
    this.addPostsEventSource();
    this.refreshPosts();
    this.refreshIdealPostTimes();
    this.refreshEvents();
    this.addPastBackgroundEvent();
    this.addRestrictedBackgroundEvents();
  },
  beforeUnmount() {
    this.$refs.calendarContainer.removeEventListener('mouseover', this.showTooltip);
    this.$refs.calendarContainer.removeEventListener('mouseout', this.removeTooltip);
    // Had to call removeChild from direct parent node, otherwise will get this error
    // on each time after you select a time from calendar:
    // 'Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.'
    [...document.querySelectorAll('.calendar-tooltip')].forEach((t) => t.parentNode.removeChild(t));
  },
  methods: {
    mixpanelCreatePost(time) {
      this.trackMixpanel.schedulerCreatePost(
        this.platform,
        this.platform,
        time.toISOString(),
        'Calendar',
      );
    },
    onTimeSelected(time) {
      this.timeSelected(time);
      this.mixpanelCreatePost(time);
      this.hideCalendarTimeSelect();
    },
    showTooltip(e) {
      // Unfortunately we can't use v-tooltip and such because the calendar is outside of the
      // control of Vue, so we inject one here that has the same HTML structure as v-tooltip.
      const el = e.target;
      const isDragging = document.querySelectorAll('.fc-dragging').length > 0;
      const isIdeal = el.classList.contains('fc-ideal-post-time');
      const isRestricted = el.classList.contains('fc-restricted-background-event');
      if ((isIdeal || isRestricted) && !isDragging) {
        // Build the HTML for the tooltip
        const tooltip = document.createElement('div');
        tooltip.className =
          'calendar-tooltip v-popper__popper v-popper--theme-tooltip v-popper__popper--shown v-popper__popper--show-to';
        tooltip.setAttribute('data-popper-placement', 'top');

        const tooltipBackdrop = document.createElement('div');
        tooltipBackdrop.className = 'v-popper__backdrop';

        const tooltipWrapper = document.createElement('div');
        tooltipWrapper.className = 'v-popper__wrapper';

        const tooltipInner = document.createElement('div');
        tooltipInner.style.whiteSpace = 'unset';
        tooltipInner.style.fontSize = `var(--x16)`;
        tooltipInner.className = 'v-popper__inner';
        tooltipInner.innerHTML = isIdeal
          ? `
          This is one of your best times to post. Your average engagement at this time is
          <strong>${(el.dataset.engagement * 100).toFixed(2)}%</strong>.
        `
          : 'The selected time falls outside of the approved publishing dates';

        const tooltipArrowContainer = document.createElement('div');
        tooltipArrowContainer.className = 'v-popper__arrow-container';

        const tooltipArrowOuter = document.createElement('div');
        tooltipArrowOuter.className = 'v-popper__arrow-outer';
        const tooltipArrowInner = document.createElement('div');
        tooltipArrowInner.className = 'v-popper__arrow-inner';

        tooltipArrowContainer.appendChild(tooltipArrowOuter);
        tooltipArrowContainer.appendChild(tooltipArrowInner);

        tooltipWrapper.appendChild(tooltipInner);
        tooltipWrapper.appendChild(tooltipArrowContainer);

        // Inject the tooltip element into the page
        tooltip.appendChild(tooltipBackdrop);
        tooltip.appendChild(tooltipWrapper);
        document.body.appendChild(tooltip);

        // Position the tooltip
        tooltip.style.top = `${
          e.pageY - tooltip.offsetHeight - tooltipArrowContainer.offsetHeight - 10
        }px`;
        tooltip.style.left = `${e.pageX - tooltip.offsetWidth / 2}px`;
        tooltipArrowContainer.style.left = `${
          tooltip.offsetWidth / 2 - tooltipArrowContainer.offsetWidth
        }px`;
      }
    },
    removeTooltip(e) {
      if (
        e.target.classList.contains('fc-ideal-post-time') ||
        e.target.classList.contains('fc-restricted-background-event')
      ) {
        [...document.querySelectorAll('.calendar-tooltip')].forEach((t) =>
          t.parentNode.removeChild(t),
        );
      }
    },
    onFullCalendarClickOutside(event) {
      const clickedCalendarTimeSelect = selectorContainsEventTarget(
        '[data-cy=CalendarTimeSelect]',
        event,
      );
      if (!clickedCalendarTimeSelect) {
        this.hideCalendarTimeSelect();
      }
    },
    hideCalendarTimeSelect() {
      this.showCalendarTimeSelect = false;
    },
    setTimeSelectPosition(start, end, jsEvent) {
      // Set coordinates of time selector when hour is clicked, ensure user cannot select
      // time in the past
      if (this.disableScheduleOnClick) {
        return;
      }
      if (areIntervalsOverlapping({ start, end }, this.allowableInterval)) {
        const containerOffset = offset(this.$refs.calendarContainer, jsEvent);
        const scrollOffset = this.$el.scrollTop;
        this.timeSelectOffsetTop = jsEvent.pageY - containerOffset.top + scrollOffset;
        this.timeSelectOffsetLeft = jsEvent.pageX - containerOffset.left;
        this.timeSelectDatetime = start;
        this.showCalendarTimeSelect = true;
      } else {
        this.hideCalendarTimeSelect();
      }
    },
    calendarClicked(e) {
      const arrowButtonClasses = ['fc-icon-chevron', 'next-button', 'prev-button'];
      if (arrowButtonClasses.some((substring) => e.target.className.includes(substring))) {
        // clicked previous/next arrows
        const prefix =
          e.target.className.includes('-left') || e.target.className.includes('prev-')
            ? 'Previous'
            : 'Next';
        this.trackMixpanel.calendarInteraction(
          `${prefix} ${startCase(this.currentView)}`,
          this.currentView,
          this.currentViewStartDate,
          0,
        );
      }
    },
    select({ start, end, jsEvent }) {
      if (this.currentView === 'week') {
        this.setTimeSelectPosition(start, end, jsEvent);
      } else if (
        this.currentView === 'month' &&
        dayjs() < dayjs(end) &&
        document.getElementsByClassName('fc-more-popover').length === 0
      ) {
        // support 'create post on click' when selected date is in the future and 'see more' popup is not open.
        // TODO: check why select time after 11:30 will causing error
        const noonOnEndDay = dayjs(end).subtract(1, 'day').set('hour', 12);
        this.timeSelected(noonOnEndDay);
        this.mixpanelCreatePost(noonOnEndDay);
      }
    },
    eventClicked({ event, jsEvent }) {
      const post = event.extendedProps.fullObj;
      // If a post is clicked in the compact calendar, show the time picker.
      if (this.type === 'compact') {
        this.setTimeSelectPosition(event.start, event.end, jsEvent);
      }
      // If a post is clicked in the full calendar, show the scheduled post popup.
      else if (event.allDay) {
        this.schedulerStore.openPopup({ type: 'event-detail', data: post });
      } else {
        this.$router.push({
          name: `scheduler.${this.platform}.posts`,
          params: {
            id: post.id,
            post,
          },
        });
        this.trackMixpanel.viewScheduledPost(post);
      }
    },
    weekSelected(date) {
      this.showCalendarTimeSelect = false;
      this.fullCalendar.gotoDate(date);
      this.trackMixpanel.calendarInteraction('Date Selection', this.currentView, date, 0);
    },
    viewChanged() {
      if (this.fullCalendar.view.type === 'dayGridMonth') {
        // handle monthly view
        this.currentView = 'month';
      } else if (this.fullCalendar.view.type === 'timeGridWeek') {
        // handle weekly agenda view.
        this.currentView = 'week';
      }
    },
    timeRangeUpdated() {
      this.currentViewStartDate = this.fullCalendar.getDate();
    },
    revertScheduledPost(revert, err, event) {
      // Removes duplicate post if api call fails but the optimistic local rendering still sets it
      const duplicateEvent = this.fullCalendar.getEventById(event.id);
      if (duplicateEvent) {
        duplicateEvent.remove();
      }
      this.handlePostError(err);
      revert();
      throw Error(err);
    },
    eventDropped(info) {
      const { event, revert } = info;
      // Fired when a scheduled post is dragged within the calendar
      if (event.extendedProps.type === 'post' && this.onUpdatePost) {
        // Workaround problem with Full Calendar where removing the posts event source in
        // refreshPosts() doesn't actually remove all the scheduled posts from the calendar.
        // This lead to duplicate posts rendering when dragging & dropping in some cases.
        const oldEvent = this.fullCalendar.getEventById(event.id);
        oldEvent?.remove();
        if (this.platform === 'pinterest') {
          this.onUpdatePost(event).catch((err) => {
            this.revertScheduledPost(revert, err, event);
          });
        } else {
          this.onUpdatePost({
            data: {
              timestamp: event.start.toISOString(),
              ...(this.hasDraftsFeatureFlag &&
              event.extendedProps.fullObj.status === postStatus.DRAFT
                ? { status: postStatus.DRAFT }
                : {}),
            },
            params: {
              id: event.extendedProps.fullObj.id,
            },
          }).catch((err) => {
            this.revertScheduledPost(revert, err, event);
          });
        }
      }
    },
    eventReceived(info) {
      const { event, revert } = info;
      // Fired when an unscheduled post gets dropped on the calendar
      if (this.onUpdatePost) {
        event.remove();
        if (this.platform === 'pinterest') {
          this.onUpdatePost(event).catch((err) => {
            this.revertScheduledPost(revert, err, event);
          });
        } else {
          this.onUpdatePost({
            data: {
              timestamp: event.start.toISOString(),
            },
            params: { id: event.extendedProps.fullObj.id },
          }).catch((err) => {
            this.revertScheduledPost(revert, err, event);
          });
        }
      }
    },
    addPostsEventSource() {
      if (this.onFetchPosts !== null) {
        this.fullCalendar.addEventSource({
          events: async ({ start, end }, successCallback) => {
            this.onFetchPosts(start.toISOString(), end.toISOString());
            successCallback([]);
          },
        });
      }
    },
    refreshPosts() {
      if (this.posts) {
        if (this.eventSources.posts) this.eventSources.posts.remove();
        this.eventSources.posts = this.fullCalendar.addEventSource({
          events: this.posts.map(formatPost),
        });
      }
    },
    clearIdealPostTimes() {
      if (this.eventSources.idealPostTimes) this.eventSources.idealPostTimes.remove();
    },
    refreshIdealPostTimes() {
      if (this.idealPostTimes && this.canAccessIdealPostTimes) {
        this.clearIdealPostTimes();
        this.eventSources.idealPostTimes = this.fullCalendar.addEventSource({
          events: this.idealPostTimes.map((idealPostTime) => ({
            startTime: `${idealPostTime.hour}:00`,
            endTime: `${idealPostTime.hour + 1}:00`,
            daysOfWeek: [idealPostTime.weekday],
            className: `fc-engagement-${idealPostTime.engagementClass}`,
            display: 'background',
            extendedProps: {
              type: 'idealPostTime',
              engagement: idealPostTime.engagement,
            },
          })),
        });
      } else {
        // Clear ideal post times if switching to brand without access permission
        this.clearIdealPostTimes();
      }
    },
    refreshEvents() {
      if (this.events) {
        if (this.eventSources.events) this.eventSources.events.remove();
        this.eventSources.events = this.fullCalendar.addEventSource({
          editable: false,
          events: this.events.map((event) => ({
            title: event.title,
            start: event.startDate,
            end: dayjs(event.endDate).add(1, 'days').format('YYYY-MM-DD'),
            extendedProps: {
              type: 'event',
              fullObj: event,
            },
          })),
        });
      }
    },
    addPastBackgroundEvent() {
      if (this.eventSources.pastBackgroundEvent) this.eventSources.pastBackgroundEvent.remove();
      this.eventSources.pastBackgroundEvent = this.fullCalendar.addEventSource({
        events: [
          {
            start: '1900-01-01',
            end: new Date(),
            className: `fc-past-background-event`,
            display: 'background',
            extendedProps: {
              type: 'pastBackgroundEvent',
            },
          },
        ],
      });
    },
    addRestrictedBackgroundEvents() {
      if (this.eventSources.restrictedBackgroundEvents)
        this.eventSources.restrictedBackgroundEvents.remove();
      this.eventSources.restrictedBackgroundEvents = this.fullCalendar.addEventSource({
        events: this.restrictedFutureIntervals.map(({ start, end }) => ({
          start: start === FAR_FUTURE ? '9999-01-01' : start,
          end: end === FAR_FUTURE ? '9999-01-01' : end,
          className: `fc-restricted-background-event`,
          display: 'background',
          extendedProps: {
            type: 'pastBackgroundEvent',
          },
        })),
      });
    },
    handlePostError(error) {
      if (twitterMentionLimitExceeded(error)) {
        this.notificationStore.setToast({
          message: calendarDragReschedulingFailureNotificationMessage,
          subtext: twitterMentionLimitExceededText,
          buttonText: 'Got It',
          type: 'error',
          autoClear: false,
        });
        this.trackMixpanel.twitterMentionWarning(this.post?.id, 'day');
      } else if (
        isPublishDateError(error, DATE_RESTRICTION_ERRORS.OUTSIDE_OF_APPROVED_PUBLISHING_DATES)
      ) {
        const publishDateRange = formatDateRangeLabel(
          dayjs.utc(error.response.data.errors[0].accept.start).local(),
          dayjs.utc(error.response.data.errors[0].accept.end).local(),
          { withTime: true },
        );
        this.notificationStore.setToast({
          message: publishDateRangeError,
          subtext: publishDateOutOfRangeSubtext + publishDateRange,
          type: 'error',
        });
      } else if (
        isPublishDateError(error, DATE_RESTRICTION_ERRORS.APPROVED_PUBLISHING_DATES_HAVE_NO_OVERLAP)
      ) {
        this.notificationStore.setToast({
          message: publishDateRangeError,
          subtext: noOverlapPublishDateSubtext,
          type: 'error',
        });
      } else {
        this.notificationStore.setToast({
          message: calendarDragReschedulingFailureNotificationMessage,
          subtext: calendarDragReschedulingFailureNotificationSubtext,
          buttonText: 'Got It',
          autoClear: false,
          type: 'error',
        });
      }
      this.isSaving = false;
    },
  },
});
export default comp;
</script>

<style lang="postcss">
.scheduler-calendar {
  flex: 1 1 auto;
  position: relative;
  overflow: auto;
  user-select: none;

  /* Week selector */
  .week-select-dropdown {
    position: sticky;
    top: 2.5rem;
    left: 2rem;
    z-index: var(--z-index-raised);
  }

  /* Week / month toggle */
  .fc-button.fc-button-primary.fc-dayGridMonth-button,
  .fc-button.fc-button-primary.fc-timeGridWeek-button {
    font-weight: 400;
    color: var(--text-primary);
    text-transform: capitalize;
    margin-left: var(--space-16);
    border: none;
    border-radius: 0;
    box-shadow: none;
    background-color: var(--background-0);

    &:focus,
    &:active {
      outline: none;
      box-shadow: none;
      background: none;
    }

    &.fc-button-active {
      color: var(--brand-accent);
      border-bottom: 2px solid var(--brand-accent);
      font-weight: 500;
      outline: none;
      box-shadow: none;
      background: none;
    }

    &:hover {
      color: var(--brand-accent);
    }
  }

  .fc-button.fc-dayGridMonth-button {
    margin-right: 2rem;
  }

  /* Prev / next week buttons */
  .fc-button.fc-button-primary {
    &.fc-prev-button,
    &.fc-next-button {
      &:hover {
        background-color: var(--background-300);
        border: 1px solid var(--border);
      }

      &:focus {
        box-shadow: none;
      }

      &:active {
        box-shadow: inset var(--space-2) var(--space-2) var(--space-4) var(--border);
      }
    }
  }

  /* Shared button style */
  .fc-button {
    text-shadow: none;
    display: inline-block;
    height: 2.5rem;
    padding: 0 var(--space-24);
    border: 1px solid var(--border);
    border-radius: var(--button-border-radius);
    font-size: var(--x16);
    font-weight: var(--font-medium);
    color: var(--text-primary);
    background: none;
    background-color: var(--background-300);
    cursor: pointer;
  }

  /* Back/forward buttons */
  .fc-button-group {
    margin-right: 2rem;

    .fc-button {
      padding: 0 var(--space-8);

      .fc-icon {
        width: 1.5rem;
        height: 0.875rem;
      }
    }

    .fc-button.fc-prev-button {
      border-radius: var(--button-border-radius) 0 0 var(--button-border-radius);

      .fc-icon-chevron-left {
        &::before {
          content: '';
        }
      }

      .fc-icon {
        background: url('@/assets/icons/caret.svg') no-repeat center/contain;
        transform: scaleX(-1);
        margin: 0 0 0 var(--space-4);

        &::after {
          content: none;
        }
      }
    }

    .fc-button.fc-next-button {
      border-radius: 0 var(--button-border-radius) var(--button-border-radius) 0;

      .fc-icon-chevron-right {
        &::before {
          content: '';
        }
      }

      .fc-icon {
        background: url('@/assets/icons/caret.svg') no-repeat center/contain;
        margin: 0 var(--space-4) 0 0;

        &::after {
          content: none;
        }
      }
    }
  }

  /* Calendar border */
  .fc-theme-standard .fc-scrollgrid {
    border: none;
  }

  /* Calendar day header */
  .fc-col-header {
    .fc-timegrid-axis {
      border: none;
    }

    .fc-col-header-cell {
      border-width: 0;

      .fc-col-header-cell-cushion {
        text-transform: uppercase;
        font-weight: var(--font-normal);
        cursor: default;
      }
    }
  }

  /* Sticky header */
  .fc-toolbar.fc-header-toolbar {
    position: sticky;
    top: 0;
    margin: calc(-1 * var(--space-60)) 0 0;
    padding: var(--space-40) 0 27px;
    background-color: var(--background-0);
    z-index: var(--z-index-sticky);
  }

  .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
    position: sticky;
    top: 115px;
    z-index: var(--z-index-sticky);
  }

  .fc-timeGridWeek-view .fc-scrollgrid-section-body:first-of-type {
    position: sticky;
    top: 177px;
    background-color: var(--background-0);
    z-index: var(--z-index-sticky);
  }

  .fc-scrollgrid-section-header > th {
    border: none;
  }

  /**** Week view ****/

  .fc-timeGridWeek-view {
    /* -- Custom classes --- */
    .day {
      display: block;
      font-size: var(--x24);
    }

    .day-name {
      display: block;
      margin-bottom: var(--space-8);
      text-transform: uppercase;
      font-size: var(--x13);
      color: var(--text-secondary);
    }

    /* All-day events at top of calendar */
    .fc-daygrid-body {
      .fc-timegrid-axis span {
        display: none; /* Hide "all day" text */
      }

      .fc-timegrid-axis,
      .fc-day {
        border: none;
      }

      .fc-row {
        min-height: auto;
        margin-bottom: var(--space-8);
      }

      .fc-today {
        background: none;
      }

      .fc-daygrid-day-events {
        min-height: auto;
        margin-bottom: var(--space-8);
      }

      .fc-daygrid-event {
        height: var(--space-20);
        line-height: var(--space-18);
        background-color: var(--brand-accent);
        border: none;
        font-size: var(--x12);
        font-weight: var(--font-normal);
        padding: 0 var(--space-8);
        border-radius: var(--round-corner-small);

        .fc-content {
          padding-top: 0;
        }
      }
    }

    /* Leftmost "time" column */
    .fc-timegrid-slot-label {
      border: none;
    }

    .fc-timegrid-slot-label-frame {
      font-size: var(--x14);
      text-align: center;
      border: none;
    }

    .fc-timegrid-slot-label-cushion {
      padding: 0 var(--space-20);
    }

    /* Divider */
    .fc-timegrid-divider {
      display: none;
    }

    /* Cells */
    .fc-timegrid-slot {
      height: var(--space-40);
      border-color: var(--border);
      cursor: pointer;
    }

    /* Cells in the past */
    .fc-past-background-event {
      background-color: rgb(230 230 230 / 30%);
      cursor: auto !important;
    }

    /* Restricted cells */
    .fc-restricted-background-event {
      background-color: rgb(230 230 230 / 30%);
      cursor: auto !important;
    }

    /* Scheduled posts in the past */
    .fc-past-event {
      opacity: 0.3;
    }

    /* Ideal post times styles */
    .fc-bg-event {
      opacity: 1;
      cursor: pointer;
    }

    .fc-engagement-low {
      background-color: var(--action-100);
    }

    .fc-engagement-medium {
      background-color: var(--action-150);
    }

    .fc-engagement-high {
      background-color: var(--action-200);
    }

    /* Scheduled posts & stories */
    .fc-post {
      display: flex;
      justify-content: center;
      background-size: cover;
      background-color: var(--text-secondary);
      border-radius: var(--round-corner-small);
      width: 44px;
      height: 44px;
      margin-left: -2px;
      font-size: var(--x14);

      .fc-content {
        padding-top: var(--space-12);
      }

      .fc-bg {
        opacity: 0.2;
        background-color: #000;
      }

      /* Icons for each post type */
      .fc-event-title {
        margin-top: -3px;
      }
    }

    /* Scheduled stories */
    .fc-timegrid-event.fc-story,
    .fc-timegrid-event.fc-reel,
    .fc-timegrid-event.fc-thread {
      .fc-content {
        overflow: visible;
        height: 100%;
        padding-top: var(--space-4);
      }
    }
  }

  /**** Month view ****/

  .fc-dayGridMonth-view {
    .fc-day-past {
      background-color: rgb(230 230 230 / 30%);
      cursor: auto;
    }

    .fc-daygrid-day-number {
      font-weight: var(--font-normal);
      font-size: var(--x14);
      padding: 6px 6px 0 0;
      cursor: default;
    }

    .fc-col-header {
      border-bottom: 1px solid var(--border);
    }

    .fc-col-header-cell-cushion {
      float: right;
      height: var(--space-52);
      line-height: var(--space-42);
    }

    /* Rows */
    .fc-daygrid-body tr {
      height: 12rem;
    }

    /* Scheduled posts & stories */
    .fc-post {
      border-radius: var(--round-corner-small);
      padding: 0;
      margin-left: var(--space-8);
      margin-bottom: var(--space-4);
      height: var(--space-32);
      width: var(--space-32);
      overflow: visible;
      background-color: var(--background-500);

      .fc-daygrid-event-dot {
        display: none;
      }

      .fc-event-time {
        transform: translateX(2.3rem);
        overflow: visible;
        color: var(--text-primary);
        font-weight: 500;
        font-size: var(--x12);
      }

      /* Icons for each post type */
      .fc-event-title {
        position: absolute;
        margin-left: 7px;
      }

      &.fc-story .fc-event-title {
        height: var(--space-18);
        width: var(--space-18);
      }

      &.fc-reel .fc-event-title {
        height: var(--space-18);
        width: var(--space-18);
      }

      &.fc-thread .fc-event-title {
        height: var(--space-16);
        width: var(--space-16);
      }
    }

    /* Events within calendar */
    .fc-daygrid-event:not(.fc-post) {
      height: var(--space-20);
      line-height: var(--space-18);
      background-color: var(--brand-accent);
      border: none;
      font-size: var(--x12);
      font-weight: var(--font-normal);
      padding: 0 var(--space-8);
      border-radius: var(--round-corner-small);
      margin: 0 9px var(--space-4);

      .fc-content {
        padding-top: 0;
      }
    }

    /* The show more button */
    .fc-more-link {
      margin-left: var(--space-8);
      font-size: var(--x12);
      color: var(--action-500);
    }
  }

  /**** Compact week view ****/

  &.compact {
    overflow: initial;

    /* Hide month view in compact mode */
    .fc-timeGridWeek-button,
    .fc-dayGridMonth-button {
      display: none;
    }

    /* Position toolbar buttons */
    .week-select-dropdown {
      position: relative;
      left: 0;
    }

    .fc-toolbar {
      margin: 0;
      padding: 0;
      position: relative;

      .fc-left {
        margin-left: 0;
      }

      .fc-button-group {
        margin-right: 0;
      }
    }

    .fc-timeGridWeek-view {
      padding-top: var(--space-12);

      /* Unstick header divider */
      .fc-scrollgrid-section-body:first-of-type {
        display: none;
        position: relative;
        top: 0;
      }
    }

    /* Day numbers / day names */
    .fc-scrollgrid-section-header {
      th {
        padding-bottom: var(--space-4);
      }

      .day {
        display: block;
        font-size: var(--x16);
      }

      .day-name {
        font-size: var(--x10);
        line-height: var(--space-16);
        margin: 0 auto;
      }
    }

    /* Leftmost time column */
    .fc-timegrid-slot-label-frame {
      font-size: var(--x10);
    }

    .fc-scroller-liquid-absolute {
      border-top: 1px solid var(--border);
      border-left: 1px solid var(--border);
    }

    /* Cells */
    .fc-timegrid-slot {
      height: var(--space-32);
    }

    /* Scheduled posts & stories */
    .fc-timegrid-event {
      width: var(--space-32);
      height: var(--space-32);
      font-size: var(--x12);
    }

    .fc-post.fc-story .fc-event-title {
      background-size: var(--space-14) var(--space-14);
    }
  }

  /* Shared styles between all post tiles */
  .fc-post {
    background-size: cover;
    box-shadow: none;
    border: none;
    transition: none;

    &.fc-story,
    &.fc-reel,
    &.fc-thread {
      .fc-event-title-container {
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .fc-event-title {
        width: var(--space-14);
        height: var(--space-14);
      }
    }

    &.fc-story .fc-event-title {
      background: url('@/assets/icons/story.svg') no-repeat center;
      background-size: var(--space-24) var(--space-24);
    }

    &.fc-reel .fc-event-title {
      background: url('@/assets/icons/reels.svg') no-repeat center;
      background-size: contain;
    }

    &.fc-thread .fc-event-title {
      background: url('@/assets/icons/threadWhite.svg') no-repeat center;
      background-size: contain;
    }

    /* Hide time & icons while dragging */
    &.fc-daygrid-dot-event.fc-event-dragging div {
      display: none;
    }
  }

  /**** Overrides for narrow screens ****/

  /* Would use a variable here but they aren't supported in media queries */
  @media (max-width: 1919px) {
    .add-event {
      top: 109px;
    }

    .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
      top: 107px;
    }

    .fc-timeGridWeek-view .fc-scrollgrid-section-body:first-of-type {
      top: 164px;
    }

    .fc-timeGridWeek-view .fc-post {
      width: 40px;
      height: 40px;
    }
  }

  /* Today */
  .fc-day.fc-day-today {
    background: none;
  }

  /* Highlighted cells */
  .fc-highlight {
    background-color: rgb(230 230 230 / 30%);
  }
}

/* Ideal post time tooltip */
.calendar-tooltip {
  position: absolute;
  padding-left: 0;
  pointer-events: none;
}

/**** Show more popup ****/
.fc-more-popover {
  box-shadow: var(--shadow-4) !important;
  border: none !important;
  border-radius: var(--round-corner-small);
  max-height: 24rem;
  padding: var(--space-8);
  overflow-y: scroll;
  z-index: 50 !important;

  .fc-event-container {
    padding-top: 1rem;
  }

  /* Popup header */
  .fc-popover-header {
    background: var(--background-0);
    margin: 15px 10px 0;

    .fc-popover-title {
      color: var(--text-secondary);
      font-size: var(--x14);
      font-weight: var(--font-medium);
    }

    .fc-popover-close {
      font-size: var(--x24);
      color: var(--text-secondary);
      margin-top: 0;
      font-weight: var(--font-medium);
    }
  }

  .fc-time {
    margin-left: var(--space-4);
    font-size: var(--x14);
    font-weight: var(--font-medium);
  }

  .fc-post {
    border-radius: var(--round-corner-small);
    padding: 0;
    margin-left: var(--space-8);
    margin-bottom: var(--space-4);
    height: var(--space-32);
    width: var(--space-32);
    overflow: visible;
    background-color: var(--background-500);

    .fc-daygrid-event-dot {
      display: none;
    }

    .fc-event-time {
      transform: translateX(2.3rem);
      overflow: visible;
      color: var(--text-primary);
      font-weight: 500;
      font-size: var(--x12);
    }

    /* Icons for each post type */
    .fc-event-title {
      position: absolute;
      margin-left: 7px;
    }

    &.fc-story .fc-event-title {
      height: var(--space-18);
      width: var(--space-18);
    }

    &.fc-reel .fc-event-title {
      height: var(--space-18);
      width: var(--space-18);
    }

    &.fc-thread .fc-event-title {
      height: var(--space-16);
      width: var(--space-16);
    }
  }
}
</style>
