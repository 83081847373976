<template>
  <InsightsDropdown
    :has-insights-access="canAccessYouTube"
    header-title="YouTube Insights"
    :header-tooltip="insightsTooltip"
    platform-icon="youtube"
    :icon-color="youtubeIconColor"
    @toggle-insights="sendToggleEvent"
  >
    <template #dropdownContents>
      <div class="insights-list">
        <div v-if="!insightsExist" class="alert-message">
          <p>YouTube source data is currently unavailable for this video.</p>
        </div>
        <div v-else>
          <ul class="insights-stats">
            <template v-for="(value, key) in mediaItem.insights" :key="key">
              <li :class="{ indented: isIndented(key) }">
                <span class="stat-name">
                  {{ formatKey(key) }}
                  <InfoTooltip
                    v-if="youtubeInsightsTooltips[key]"
                    :tooltip="youtubeInsightsTooltips[key]"
                  />
                </span>
                <span class="stat">{{ formatValue(key, value) }}</span>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </template>
  </InsightsDropdown>
</template>

<script>
import { defineComponent } from 'vue';
import startCase from 'lodash/startCase';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useFlagStore } from '@/stores/flag';
import { toolTips } from '@/config';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import { formatDynamicDuration, formatMetric } from '@/utils/formatters';
import { BRAND, USER } from '@/models/auth/permissions.enum';
import { useSupportChatStore } from '@/stores/support-chat';
import enumTypes from '@/app/library/constants';
import { colours } from '@/ux/colours';
import InsightsDropdown from './InsightsDropdown.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'YouTubeInsights',
  components: { InfoTooltip, InsightsDropdown },
  props: { mediaItem: { type: Object, default: null } },
  emits: ['toggleInsights'],
  data() {
    return {
      youtubeInsightsTooltips: toolTips.youtubeInsights,
      formattedKeyMap: {
        avgViewDuration: 'Avg. View Duration',
        avgViewPercentage: 'Avg. Percentage Viewed',
        videosAddedToPlaylist: 'Added to Playlist',
      },
    };
  },
  computed: {
    ...mapStores(useSupportChatStore, useFlagStore),
    ...mapPiniaState(useAuthStore, ['guard']),
    canAccessYouTube() {
      return (
        this.guard(BRAND.YOUTUBE.CAN_ACCESS_YOUTUBE_INSIGHTS) &&
        this.guard(USER.YOUTUBE.CAN_ACCESS_YOUTUBE_INSIGHTS)
      );
    },
    insightsExist() {
      return Object.keys(this.mediaItem?.insights || {}).length > 0;
    },
    isOwned() {
      return this.mediaItem.sourceType === enumTypes.YOUTUBE_OWNED;
    },
    insightsTooltip() {
      if (this.isOwned) {
        return this.youtubeInsightsTooltips.insightsHeader;
      }
      return undefined;
    },
    youtubeIconColor() {
      return this.flagStore?.ready && this.flagStore.flags?.youtubeCompliance
        ? null
        : colours.SOCIAL_CHANNEL.SC_YOUTUBE;
    },
  },
  methods: {
    formatKey(key) {
      return this.formattedKeyMap[key] || startCase(key);
    },
    formatValue(key, value) {
      if (value === null || value === undefined) {
        return '-';
      }
      if (key === 'avgViewPercentage') {
        return formatMetric({ format: 'percent', value });
      }
      if (['avgViewDuration', 'watchTime'].includes(key)) {
        return formatDynamicDuration(value);
      }
      return value.toLocaleString();
    },
    contactUsClicked() {
      this.supportChatStore.show();
    },
    sendToggleEvent(isOpen) {
      const actionName = isOpen ? 'Show' : 'Hide';
      this.$emit('toggleInsights', 'Insights', `${actionName} insights`);
    },
    isIndented(key) {
      if (this.isOwned && this.flagStore.flags?.youtubeCompliance) {
        return ['likes', 'dislikes', 'comments', 'shares'].includes(key);
      }
      return false;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.indented {
  padding-left: var(--space-30);
}
</style>
