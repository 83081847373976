<template>
  <div v-if="timeZoneName" class="time-zone-container">
    <Icon class="details-icon timezone" name="locationGlobe" xsmall />
    <span>{{ timeZoneName }}</span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Icon from '@/components/foundation/Icon.vue';

/**
 * Displays a user's timezone.
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'UserTimeZone',
  components: {
    Icon,
  },
  props: {
    /**
     * User object to use to find the timezone to display.
     */
    user: {
      type: Object,
      default: null,
    },
  },
  computed: {
    timeZoneName() {
      return this.user?.timeZoneName || this.user?.time_zone_name;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.time-zone-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-secondary);
  font-size: var(--x12);

  .details-icon {
    margin-right: var(--space-4);
    flex: none;
  }
}
</style>
