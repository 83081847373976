<script setup>
import { computed, ref, onMounted, onUnmounted, watch } from 'vue';
import merge from 'lodash/merge';
import isNumber from 'lodash/isNumber';
import { useMediaSelectStore } from '@/stores/media-select';
import { constants } from '@/config';
import { dragSelectableItemGenerator } from '@/utils';
import { useParentElement, useElementSize } from '@vueuse/core';

const mediaSelectStore = useMediaSelectStore();

const props = defineProps({
  items: {
    type: Array,
    default: null,
  },
  rowCount: {
    type: Number,
    default: null,
  },
  rowHeight: {
    type: Number,
    default: 240,
  },
  limitSource: {
    type: String,
    default: null,
  },
  dragSelect: {
    type: Object,
    default: () => {},
  },
  squarePhotos: {
    type: Boolean,
    default: false,
  },
  portraitPhotos: {
    type: Boolean,
    default: false,
  },
  showFilename: {
    type: Boolean,
    default: false,
  },
});

const parentEl = useParentElement();
const { width: parentWidth } = useElementSize(parentEl);

const mediaLayout = ref(null);
const mediaCards = ref(null);
const mediaLayoutWidth = ref(0);

function updateMediaLayoutWidth() {
  if (mediaLayout.value) {
    const rect = mediaLayout.value.getBoundingClientRect();
    mediaLayoutWidth.value = rect.width;
  }
}
const controller = new AbortController();
onMounted(() => {
  updateMediaLayoutWidth();
  const { signal } = controller;
  window.addEventListener('scroll', updateMediaLayoutWidth, {
    signal,
    capture: true,
    passive: true,
  });
});
onUnmounted(() => {
  controller.abort();
});

const firstMediaCard = computed(() => mediaCards.value?.[0]);
const firstMediaCardStyle = computed(() => {
  const element = firstMediaCard.value?.children?.[0];
  if (element) {
    return window.getComputedStyle ? getComputedStyle(element) : element.currentStyle;
  }
  return null;
});

const selectableItems = computed(() => {
  if (props.limitSource) {
    const limitSource = props.limitSource.toUpperCase();
    return props.items.filter((item) => item.source.toUpperCase() === limitSource);
  }
  return props.items;
});

const dragSelectConfig = computed(() => {
  return merge(
    {
      enabled: true,
      scrollableAreaSelector: '#app',
      ignoreSelectionWithinSelector: '.popup-container',
      selectionMargin: 10,
      additiveSelect: true,
      selectableGetter: () => {
        const selector = '.media-layout-row-item [data-id]';
        return dragSelectableItemGenerator(
          mediaLayout.value,
          selectableItems.value,
          null,
          selector,
        )();
      },
      selectedSetter: mediaSelectStore.dragSelectableSelectedSetter,
    },
    props.dragSelect,
  );
});

const mediaCardMarginLeft = computed(() => {
  const style = firstMediaCardStyle?.value;
  const marginRight = parseInt(style?.marginRight ?? 0, 10);
  const borderRightWidth = parseInt(style?.borderRightWidth ?? 0, 10);
  const paddingRight = parseInt(style?.paddingRight ?? 0, 10);
  return marginRight + borderRightWidth + paddingRight;
});
const mediaCardMarginRight = computed(() => {
  const style = firstMediaCardStyle?.value;
  const marginRight = parseInt(style?.marginRight ?? 0, 10);
  const borderRightWidth = parseInt(style?.borderRightWidth ?? 0, 10);
  const paddingRight = parseInt(style?.paddingRight ?? 0, 10);
  return marginRight + borderRightWidth + paddingRight;
});
const mediaCardMargin = computed(() => {
  return mediaCardMarginLeft.value + mediaCardMarginRight.value;
});
const mediaLayoutMarginLeft = computed(() => {
  return `-${mediaCardMarginLeft.value}px`;
});
const mediaLayoutMarginRight = computed(() => {
  return `-${mediaCardMarginRight.value}px`;
});

function calculateMediaCardDimensions(row) {
  let rowWidth = 0;
  const height = Math.ceil(props.rowHeight * row.ratio);
  row.items = row.items.map((item) => {
    const normalizedWidth = (item.thumbnailWidth * props.rowHeight) / item.thumbnailHeight;
    let width = row.lastRow
      ? Math.floor(normalizedWidth * row.ratio)
      : Math.ceil(normalizedWidth * row.ratio);

    rowWidth += width + mediaCardMargin.value;
    if (rowWidth > mediaLayoutWidth.value) {
      width -= rowWidth - mediaLayoutWidth.value;
    }

    return {
      item,
      width,
      height,
    };
  });

  return row;
}

const mediaRows = computed(() => {
  let currentRowWidth = 0;
  let currentRowRatio = 0;
  let currentRowItems = [];

  return props.items
    .reduce((rows, item, index, array) => {
      if (props.squarePhotos) {
        item.thumbnailWidth = constants.THUMBNAIL_SIZE;
        item.thumbnailHeight = constants.THUMBNAIL_SIZE;
      } else if (props.portraitPhotos) {
        item.thumbnailWidth = (constants.THUMBNAIL_SIZE * 3) / 4;
        item.thumbnailHeight = constants.THUMBNAIL_SIZE;
      } else {
        const isMediaV1 = item?.image_sizes || item?.video_sizes;
        if (!isMediaV1) {
          if (!item.uploadStatus) {
            if (!item.width) {
              item.width =
                item.type === 'IMAGE'
                  ? item?.image?.sizes?.small?.width
                  : item?.video?.thumbnails?.small?.width;
            }
            if (!item.height) {
              item.height =
                item.type === 'IMAGE'
                  ? item?.image?.sizes?.small?.height
                  : item?.video?.thumbnails?.small?.height;
            }
          }
        }

        item.thumbnailWidth = item.width;
        item.thumbnailHeight = item.height;
      }

      if (!isNumber(item.width) && props.rowCount != null) {
        return rows;
      }

      const normalizedWidth = (item.thumbnailWidth * props.rowHeight) / item.thumbnailHeight;
      currentRowItems.push(item);
      currentRowWidth += mediaCardMargin.value + normalizedWidth;

      const startNewRow = currentRowWidth >= mediaLayoutWidth.value;
      const addLastRow = array.length === index + 1;

      if (startNewRow) {
        const marginInRow = currentRowItems.length * mediaCardMargin.value;
        currentRowRatio = (mediaLayoutWidth.value - marginInRow) / (currentRowWidth - marginInRow);
        rows.push({
          items: currentRowItems,
          ratio: currentRowRatio,
        });
        currentRowItems = [];
        currentRowWidth = 0;
      } else if (addLastRow) {
        if (currentRowRatio === 0) {
          currentRowRatio = 1;
        }
        rows.push({
          items: currentRowItems,
          ratio: currentRowRatio,
          lastRow: true,
        });
      }

      return rows;
    }, [])
    .map(calculateMediaCardDimensions);
});

const visibleMediaRows = computed(() => {
  if (props.rowCount && props.rowCount < mediaRows.value.length) {
    return mediaRows.value.slice(0, props.rowCount);
  }
  return mediaRows.value;
});

watch(
  () => parentWidth.value,
  () => {
    updateMediaLayoutWidth();
  },
);

defineExpose({
  mediaRows,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div
    ref="mediaLayout"
    v-drag-select="dragSelectConfig"
    class="media-layout overflow-x-hidden"
    data-cy="MediaLayout"
  >
    <template v-for="(mediaRow, mediaRowIndex) in visibleMediaRows" :key="mediaRowIndex">
      <div class="media-layout-row" data-cy="MediaLayoutRow">
        <template
          v-for="(mediaRowItem, mediaRowItemIndex) in mediaRow.items"
          :key="mediaRowItemIndex"
        >
          <div
            ref="mediaCards"
            class="media-layout-row-item"
            :class="{ 'upload-select-with-filename': mediaRowItem.item.id === '+' && showFilename }"
          >
            <slot
              :item="mediaRowItem.item"
              :width="mediaRowItem.width"
              :height="mediaRowItem.height"
            />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<style scoped lang="postcss">
.media-layout {
  display: flex;
  flex-direction: column;
  margin-left: v-bind(mediaLayoutMarginLeft);
  margin-right: v-bind(mediaLayoutMarginRight);

  .media-layout-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: left;
    width: 100%;
    page-break-inside: avoid;

    .media-layout-row-item {
      display: inline-block;
      page-break-inside: avoid;
    }

    .upload-select-with-filename {
      margin-bottom: calc(var(--space-32) + 2px);
    }
  }
}
</style>
