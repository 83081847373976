<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { useChiliPiperStore } from '@/stores/chili-piper';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import { CHILI_PIPER_FORMS_MAP } from '@/app/settings/constants';
import { useSocialListeningStore } from '@/stores/social-listening';
import { TRIAL_BOOK_TRAINING_URL } from '@/components/InAppTrials/constants';
import { useTrialStates } from '@/composables/inAppTrials/useTrialStates';

const {
  trialActiveOrExpiredForTwoDays,
  activeTrialsInfo,
  expiredTrialsInfo,
  expansionTrialDaysLeft,
} = useTrialStates();

const emits = defineEmits(['selected']);

const authStore = useAuthStore();
const chiliPiperStore = useChiliPiperStore();
const customerJourneyStore = useCustomerJourneyStore();
const socialListeningStore = useSocialListeningStore();

const router = useRouter();

const showPlanTrial = computed(() => {
  return (
    customerJourneyStore.additionalPlanTrial?.trialEndDate &&
    trialActiveOrExpiredForTwoDays(customerJourneyStore.additionalPlanTrial)
  );
});

const planTrialType = computed(() => {
  return customerJourneyStore.additionalPlanTrial?.dhProduct?.name;
});

const isAdmin = computed(() => {
  return authStore.isCurrentBrandRoleAdmin;
});

function openChiliPiperForm(buttonText) {
  let formRouter;
  let formName;
  if (buttonText === 'Book A Training') {
    chiliPiperStore.openChiliPiperTab(
      TRIAL_BOOK_TRAINING_URL,
      CHILI_PIPER_FORMS_MAP.button.bookATraining.router,
      CHILI_PIPER_FORMS_MAP.button.bookATraining.name,
      'Book A Training',
      'profile dropdown',
    );
    emits('selected');
    return;
  }
  if (buttonText === 'Extend Trial') {
    formRouter = CHILI_PIPER_FORMS_MAP.button.extendTrial.router;
    formName = CHILI_PIPER_FORMS_MAP.button.extendTrial.name;
  } else if (showPlanTrial.value) {
    formRouter = CHILI_PIPER_FORMS_MAP[planTrialType.value.toLowerCase()].subscribe.router;
    formName = CHILI_PIPER_FORMS_MAP[planTrialType.value.toLowerCase()].subscribe.name;
  } else {
    formRouter = CHILI_PIPER_FORMS_MAP.premiumAnalytics.subscribe.router;
    formName = CHILI_PIPER_FORMS_MAP.premiumAnalytics.subscribe.name;
  }
  chiliPiperStore.submit(formRouter, formName, buttonText, 'profile dropdown');
  emits('selected');
}

function subscribeClicked() {
  const trialsInfoToCheck =
    activeTrialsInfo?.value?.length > 0 ? activeTrialsInfo.value : expiredTrialsInfo.value;
  const isSocialListeningOnly =
    trialsInfoToCheck.length === 1 &&
    (trialsInfoToCheck[0] === 'Social Listening' ||
      trialsInfoToCheck[0]?.product === 'Social Listening');
  if (isSocialListeningOnly && !customerJourneyStore.isEligibleForSocialListening) {
    router.push({
      name: 'settings.billing',
      params: { brandLabel: authStore?.currentBrandLabel },
    });
    socialListeningStore.togglePopup('upgradePlan', true);
    return;
  }
  if (!isAdmin.value) {
    customerJourneyStore.togglePopup(
      'featureAccess',
      true,
      isSocialListeningOnly ? 'socialListening' : null,
    );
    return;
  }
  if (trialsInfoToCheck.length > 1) {
    router.push({
      name: 'settings.billing',
      params: { brandLabel: authStore?.currentBrandLabel },
    });
  } else if (isSocialListeningOnly) {
    router.push({
      name: 'settings.socialListeningUpgrade',
      params: { brandLabel: authStore?.currentBrandLabel },
    });
  } else {
    openChiliPiperForm('Subscribe');
  }
  emits('selected');
}

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        activeTrialsInfo,
        expiredTrialsInfo,
        subscribeClicked,
      }
    : {},
);
</script>

<script></script>

<template>
  <div v-if="activeTrialsInfo?.length > 0 || expiredTrialsInfo?.length > 0" class="mb-6">
    <div
      v-if="activeTrialsInfo?.length > 0"
      class="dash-bg-background-inform-muted dash-rounded-sm dash-typo-base p-6"
    >
      <div
        v-for="trialInfo in activeTrialsInfo"
        :key="trialInfo.product"
        v-sanitize-html="expansionTrialDaysLeft(trialInfo)"
        class="mb-4"
      ></div>
      <div class="dash-typo-base dash-typo-base-semibold text-[--action-500]">
        <div class="mt-3">
          <span class="cursor-pointer" @click="openChiliPiperForm('Book A Training')"
            >Book a Training</span
          >
        </div>
        <div class="mt-3">
          <span class="cursor-pointer" @click="openChiliPiperForm('Extend Trial')"
            >Extend Trial</span
          >
        </div>
        <div class="mt-3 cursor-pointer">
          <span class="cursor-pointer" @click="subscribeClicked">Subscribe</span>
        </div>
      </div>
    </div>
    <div
      v-if="expiredTrialsInfo?.length > 0"
      class="dash-bg-background-inform-muted dash-rounded-sm dash-typo-base p-6"
      :class="[{ 'mt-4': activeTrialsInfo?.length > 0 }]"
    >
      <div
        v-for="trialInfo in expiredTrialsInfo"
        :key="trialInfo"
        :class="{ 'mb-4': activeTrialsInfo?.length === 0 }"
      >
        Your <span class="font-medium">{{ trialInfo }}</span> trial has expired.
      </div>
      <div v-if="activeTrialsInfo?.length === 0" class="text-sm font-medium text-[--action-500]">
        <div class="mt-3">
          <span class="cursor-pointer" @click="openChiliPiperForm('Extend Trial')"
            >Extend Trial</span
          >
        </div>
        <div class="mt-3">
          <span class="cursor-pointer" @click="subscribeClicked">Subscribe</span>
        </div>
      </div>
    </div>
  </div>
</template>
