<template>
  <div class="week-select-dropdown" data-cy="calendar-date-picker">
    <VDropdown
      :shown="show"
      :triggers="[]"
      boundary="viewport"
      placement="bottom"
      @auto-hide="show = false"
    >
      <SelectionDisplayButton grey prepend-icon="calendar" :open="show" @click="show = !show">
        {{ dateLabel }}
      </SelectionDisplayButton>
      <template #popper>
        <div>
          <WeekPicker :date="date" :mode="mode" @update:date="$emit('update:date', $event)" />
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent } from 'vue';
import WeekPicker from '@/components/foundation/pickers/WeekPicker.vue';
import { CALENDAR_MODE } from '@/components/core/calendar/constants';
import { enumProp } from '@/utils/props';
import { colours } from '@/ux/colours';
import { getIntervalByMode } from '@/components/core/calendar/calendar-utils';
import SelectionDisplayButton from '@/components/foundation/SelectionDisplayButton.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'CalendarDurationSelect',
  components: {
    WeekPicker,
    SelectionDisplayButton,
  },
  props: {
    mode: enumProp(CALENDAR_MODE, CALENDAR_MODE.WEEK),
    date: { type: Date, default: null },
  },
  emits: ['update:date'],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    darkGrey() {
      return colours.ICON.ICON_PRIMARY;
    },
    dateLabel() {
      if (this.date) {
        const { start, end } = getIntervalByMode(this.date, this.mode);
        const startDjs = dayjs(start);
        const endDjs = dayjs(end);
        const startMonth = startDjs.format('MMMM');
        const endMonth = endDjs.format('MMMM');
        const startMonthShort = startDjs.format('MMM');
        const endMonthShort = endDjs.format('MMM');
        const startYear = startDjs.format('YYYY');
        const endYear = endDjs.format('YYYY');
        if (startYear !== endYear) {
          return `${startMonthShort} ${startYear} -  ${endMonthShort} ${endYear}`;
        }
        if (startMonth !== endMonth) {
          return `${startMonthShort} - ${endMonthShort} ${startYear}`;
        }
        return `${startMonth} ${startYear}`;
      }
      return '';
    },
  },
  watch: {
    date() {
      this.show = false;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.week-select-dropdown {
  margin: 0 var(--space-4);
  width: fit-content;
  position: relative;
  z-index: 3;

  .week-picker {
    display: flex;
    align-items: center;
    position: relative;
    border: 1px solid var(--border);
    font-size: var(--x14);
    color: var(--text-primary) !important;
    min-width: 8.5rem;
    line-height: 2rem;
    text-transform: uppercase;
    cursor: pointer;
    padding: 0 var(--space-16) 0 var(--space-24);
    height: 2.5rem;
    background-color: var(--background-300);
    border-radius: 1.25rem;
    transition: all 0.3s;

    .date-label {
      margin: 0 var(--space-16) 0 var(--space-12);
    }
  }

  .week-picker:hover {
    color: var(--action-500);
  }
}
</style>
