<template>
  <section class="all-insights-container">
    <HighlightedInsights v-if="showHighlightedInsights" :insights="highlightedInsights" />
    <section class="main-insights-container">
      <InsightsBase :header-title="insightsHeader" header-icon="instagram-reels">
        <template #title>
          <Icon
            v-if="postIsPromoted"
            v-tooltip="boostedTooltip"
            :color="colours.BRAND.BRAND_ACCENT"
            name="boosted"
          />
        </template>
        <CircularLoader v-if="!insights" />
        <template v-else>
          <div v-if="hasConnectionBanner" class="pt-4">
            <Banner v-bind="callToActionBanner" small @click-action="callToActionBanner.actionTo">
              {{ callToActionBanner.message }}
            </Banner>
          </div>
          <div v-if="disabledLikeCount" class="hidden-likes">
            Like count is hidden on this post.
            <br />
            Impacted metrics will not be shown.
          </div>
          <span v-for="insightProp in insightsProps" :key="insightProp.label">
            <InsightsItem
              v-if="!insightProp.hidden"
              :label="insightProp.label"
              :label-tooltip="insightProp.labelTooltip"
              :value="formatValue(insightProp.value, insightProp.key)"
              :value-tooltip="insightProp.valueTooltip"
            />
            <template v-for="childInsightProp in insightProp.children">
              <InsightsItem
                v-if="!childInsightProp.hidden"
                :key="childInsightProp.label"
                indented
                :label="childInsightProp.label"
                :label-tooltip="childInsightProp.labelTooltip"
                :value="childInsightProp.value"
              />
            </template>
          </span>
        </template>
      </InsightsBase>
    </section>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import isEmpty from 'lodash/isEmpty';
import differenceInHours from 'date-fns/differenceInHours';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { updatePost } from '@/apis/instagram';
import CircularLoader from '@/components/CircularLoader.vue';
import enumTypes from '@/app/library/constants';
import instagramConstants, {
  rateFieldFormat,
  accountingField,
  metaPromotedLevelReelFields,
  metaPromotedReelFieldsToVerbose,
  organicInsightsLabelsVerbose,
} from '@/app/instagram/constants';
import Icon from '@/components/foundation/Icon.vue';
import InsightsBase from '@/components/insights/InsightsBase.vue';
import InsightsItem from '@/components/insights/InsightsItem.vue';
import { toolTips } from '@/config';
import { colours } from '@/ux/colours';
import HighlightedInsights from '@/app/library/components/MediaPopup/HighlightedInsights.vue';
import { useFlagStore } from '@/stores/flag';
import { METRIC_FORMATS } from '@/models/dashboards/metrics.constants';
import { validateRealUser } from '@/utils/user';
import { formatDynamicDuration, formatValueByFormatType, localize } from '@/utils/formatters';
import {
  formatMetaPromoted,
  getFormattedNestings,
  sortMetaPromotedFields,
} from '@/app/instagram/utils';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import Banner from '@/components/foundation/feedback/Banner.vue';
import { usePlatformStore } from '@/stores/platform';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ReelsInsights',
  components: {
    HighlightedInsights,
    CircularLoader,
    Icon,
    InsightsBase,
    InsightsItem,
    Banner,
  },
  props: {
    mediaItem: { type: Object, default: null },
  },
  data() {
    return {
      insights: null,
      boostedTooltip: toolTips.instagramInsights.boosted,
      tooltips: toolTips.instagramReelsInsights,
      metaPromotedTooltips: toolTips.metaInstagramReelMediaPopup,
    };
  },
  computed: {
    ...mapStores(useFlagStore, usePlatformStore),
    ...mapPiniaState(useAuthStore, ['currentBrand']),
    isInstagramPromotedPostInsights() {
      return this.flagStore.flags.instagramPromotedPostInsights;
    },
    isMetaAdsConnected() {
      return this.platformStore.isPlatformConnected(PLATFORM_CONNECTION.FACEBOOK_ADS_NEW);
    },
    hasConnectedMetaAdsPreviously() {
      return !this.platformStore.isPlatformNeverConnected(PLATFORM_CONNECTION.FACEBOOK_ADS_NEW);
    },
    showPromotedInsights() {
      return this.isInstagramPromotedPostInsights && this.hasConnectedMetaAdsPreviously;
    },
    callToActionBanner() {
      if (!this.isInstagramPromotedPostInsights) return {};
      if (this.hasConnectedMetaAdsPreviously && !this.isMetaAdsConnected) {
        return {
          customIcon: 'alertCircle',
          message: 'Meta Ads not connected, data may not be current',
          actionText: 'Reconnect in Settings',
          alertType: 'warning',
          actionTo: this.showMetaAdsConnectionPopup,
        };
      }
      if (!this.hasConnectedMetaAdsPreviously) {
        return {
          customIcon: 'boosted',
          message: 'Inform your social strategy with promoted post insights!',
          actionText: 'Connect Meta Ads Account',
          alertType: 'white',
          actionTo: this.showMetaAdsConnectionPopup,
        };
      }
      return {};
    },
    currency() {
      if (this.showPromotedInsights) {
        const metaAdsAccounts = this.platformStore.getAccount(PLATFORM_CONNECTION.FACEBOOK_ADS);
        const currency = metaAdsAccounts?.[0]?.currency;
        return currency;
      }
      return undefined;
    },
    hasConnectionBanner() {
      return Object.keys(this.callToActionBanner).length > 0;
    },
    organicNewInsightProps() {
      const {
        replays,
        total_plays: totalPlays,
        avg_time_viewed_sec: avgTimeViewedSec,
        total_time_viewed_sec: totalTimeViewedSec,
      } = this.insights;
      return [
        {
          label: 'Replays',
          labelTooltip: this.tooltips.replays,
          value: replays,
          children: [],
          hidden: !this.showBusinessInsights,
        },
        {
          label: 'All Plays',
          labelTooltip: this.tooltips.totalPlays,
          value: totalPlays,
          children: [],
          hidden: !this.showBusinessInsights,
        },
        {
          label: 'Average Watch Time',
          labelTooltip: this.tooltips.avgTimeViewedSec,
          value: formatDynamicDuration(avgTimeViewedSec),
          children: [],
          hidden: !this.showBusinessInsights,
        },
        {
          label: 'Total Time Viewed',
          labelTooltip: this.tooltips.totalTimeViewedSec,
          value: formatDynamicDuration(totalTimeViewedSec),
          children: [],
          hidden: !this.showBusinessInsights,
        },
      ];
    },
    relationshipInsightProps() {
      const { relationship_post: relationshipPost } = this.insights;
      return [
        {
          label: 'Followers Gained',
          labelTooltip: this.tooltips.followersGained,
          value: relationshipPost?.followers_gained ?? '-',
          children: [],
        },
        {
          label: 'EMV',
          labelTooltip: this.tooltips.emv,
          value:
            relationshipPost?.emv != null && !this.disabledLikeCount
              ? this.formatEmvRate(relationshipPost.emv)
              : '-',
          children: [],
        },
      ];
    },
    promotedNewInsightProps() {
      const {
        replays,
        total_plays: totalPlays,
        avg_time_viewed_sec: avgTimeViewedSec,
        total_time_viewed_sec: totalTimeViewedSec,
      } = this.insights;
      return [
        {
          label: 'Replays - Organic',
          labelTooltip: this.tooltips.replays,
          value: replays,
          children: [],
          hidden: !this.showBusinessInsights,
        },
        {
          label: 'All Plays - Organic',
          labelTooltip: this.tooltips.totalPlays,
          value: totalPlays,
          children: [],
          hidden: !this.showBusinessInsights,
        },
        {
          label: 'Average Watch Time - Organic',
          labelTooltip: this.tooltips.avgTimeViewedSec,
          value: formatDynamicDuration(avgTimeViewedSec),
          children: [],
          hidden: !this.showBusinessInsights,
        },
        {
          label: 'Total Time Viewed - Organic',
          labelTooltip: this.tooltips.totalTimeViewedSec,
          value: formatDynamicDuration(totalTimeViewedSec),
          children: [],
          hidden: !this.showBusinessInsights,
        },
      ];
    },
    colours() {
      return colours;
    },
    highlightedInsights() {
      const value = this.insights?.entertainment_score;
      const formatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
      let formattedValue;
      if (value == null) {
        formattedValue = '-';
      } else {
        formattedValue = value.toLocaleString('en', formatOptions);
      }
      return [
        {
          name: 'Entertainment Score',
          value: formattedValue,
          iconName: 'leisurePartyPopper',
          tooltip: toolTips.instagramInsights.entertainmentScore,
        },
      ];
    },
    postIsPromoted() {
      return !isEmpty(this.mediaItem.boostedData);
    },
    postIsRIQ() {
      return (this.insights.is_mentioned || this.insights.is_tagged) && !this.postIsOwned;
    },
    postIsOwned() {
      return this.mediaItem.sourceType === enumTypes.INSTAGRAM_OWNED;
    },
    showBusinessInsights() {
      return this.insights?.show_business_insights;
    },
    userDataUnavailable() {
      return !validateRealUser(this.mediaItem.userName);
    },
    insightsHeader() {
      return this.postIsOwned ? 'Reels Insights' : 'Organic Reels Insights';
    },
    insightsProps() {
      let props = [];
      if (this.isInstagramPromotedPostInsights && this.postIsPromoted) {
        props = this.metaPromotedProps;
      } else {
        props = this.postIsPromoted ? this.promotedInsightsProps : this.organicInsightsProps;
      }

      // make sure that items are always in alphabetical order by label
      props.sort((aa, bb) => aa?.label?.localeCompare(bb?.label));
      return props;
    },
    disabledLikeCount() {
      const likes = this.insights?.like_count;
      const moreThanOneHourAgo =
        differenceInHours(new Date(), new Date(this.mediaItem.datePosted)) > 1;
      return !this.showBusinessInsights && likes === 0 && moreThanOneHourAgo;
    },
    organicInsightsProps() {
      const {
        comments_count: comments,
        effectiveness,
        engagement: engagementRate,
        engagement_rate_impressions: engagementRateImpressions,
        impressions,
        like_count: likes,
        reach,
        saved,
        shares,
        video_views: videoViews,
      } = this.insights;
      const engagementsChildren = [
        {
          label: 'Likes',
          labelTooltip: this.postIsOwned ? null : this.tooltips.likes,
          value: !this.disabledLikeCount ? likes : null,
        },
        {
          label: 'Comments',
          labelTooltip: this.postIsOwned ? null : this.tooltips.comments,
          value: comments,
        },
        {
          label: 'Shares',
          labelTooltip: null,
          value: shares,
          hidden: !this.showBusinessInsights,
        },
        {
          label: 'Saves',
          labelTooltip: null,
          value: saved,
          hidden: !this.showBusinessInsights,
        },
      ];
      const relationshipInsightsProp = this.postIsRIQ ? this.relationshipInsightProps : [];
      let insightsProps = [
        {
          label: 'Plays',
          labelTooltip: this.tooltips.videoViewsOrganic,
          value: videoViews,
          children: [],
          hidden: !this.showBusinessInsights,
        },
        {
          label: !this.showBusinessInsights ? 'Estimated Reach' : 'Reach',
          labelTooltip: !this.showBusinessInsights
            ? this.tooltips.estimatedReach
            : this.tooltips.reachOrganic,
          value: this.userDataUnavailable ? '-' : reach,
          valueTooltip: this.userDataUnavailable ? instagramConstants.userDataUnavailable : '',
          children: [],
        },
        {
          label: 'Impressions',
          labelTooltip: null,
          value: impressions,
          children: [],
          hidden: !this.showBusinessInsights,
        },
        {
          label: 'Effectiveness',
          labelTooltip: this.showBusinessInsights
            ? this.tooltips.effectivenessOrganic
            : this.tooltips.effectivenessUgc,
          value:
            this.userDataUnavailable || this.disabledLikeCount
              ? '-'
              : this.formatRate(effectiveness),
          valueTooltip: this.userDataUnavailable ? instagramConstants.userDataUnavailable : '',
          children: [],
        },
        {
          label: 'Engagement Rate',
          labelTooltip: this.postIsOwned
            ? this.tooltips.engagementRateOrganic
            : this.tooltips.engagementRateUgc,
          value:
            this.userDataUnavailable || this.disabledLikeCount
              ? '-'
              : this.formatRate(engagementRate),
          valueTooltip: this.userDataUnavailable ? instagramConstants.userDataUnavailable : '',
          children: [],
        },
        {
          label: 'Engagement Rate Impressions',
          labelTooltip: this.tooltips.engagementRateOrganicImpressions,
          value: this.formatRate(engagementRateImpressions),
          valueTooltip: this.userDataUnavailable ? instagramConstants.userDataUnavailable : '',
          children: [],
          hidden: !this.postIsOwned,
        },
        {
          label: 'Engagements',
          labelTooltip: this.showBusinessInsights
            ? this.tooltips.engagementsOrganic
            : this.tooltips.engagementsUgc,
          value: !this.disabledLikeCount ? comments + likes + shares + saved : '-',
          children: engagementsChildren,
        },
        ...this.organicNewInsightProps,
        ...relationshipInsightsProp,
      ];

      if (this.postIsOwned) {
        insightsProps = this.insightsPropsReformatLabelsOrganic(insightsProps);
      }
      if (this.postIsOwned) {
        insightsProps.push({
          label: 'LikeShop Clicks',
          labelTooltip: null,
          value: this.likeshopClicks,
          children: [],
        });
      }
      return insightsProps;
    },
    promotedInsightsProps() {
      const {
        boostedData: { commentsCountPaid, impressionsPaid, likeCountPaid, videoViewsPaid },
      } = this.mediaItem;

      const {
        comments_count: comments,
        effectiveness,
        engagement,
        impressions,
        like_count: likes,
        reach,
        saved,
        shares,
        total_comments: totalComments,
        total_likes: totalLikes,
        video_views: videoViews,
      } = this.insights;

      return [
        {
          label: 'Plays',
          labelTooltip: null,
          value: videoViews + videoViewsPaid,
          children: [
            {
              label: 'Plays - Organic',
              labelTooltip: this.tooltips.videoViewsOrganic,
              value: videoViews,
            },
            {
              label: 'Plays - Promoted',
              labelTooltip: this.tooltips.videoViewsPromoted,
              value: videoViewsPaid,
            },
          ],
        },
        {
          label: 'Reach - Organic',
          labelTooltip: this.tooltips.reachOrganic,
          value: reach,
          children: [],
        },
        {
          label: 'Impressions',
          labelTooltip: null,
          value: impressions + impressionsPaid,
          children: [
            {
              label: 'Impressions - Organic',
              labelTooltip: null,
              value: impressions,
            },
            {
              label: 'Impressions - Promoted',
              labelTooltip: this.tooltips.impressionsPromoted,
              value: impressionsPaid,
            },
          ],
        },
        {
          label: 'Effectiveness - Organic',
          labelTooltip: this.tooltips.effectivenessOrganic,
          value: this.formatRate(effectiveness),
          children: [],
        },
        {
          label: 'Engagement Rate - Organic',
          labelTooltip: this.tooltips.engagementRateOrganic,
          value: this.formatRate(engagement),
          children: [],
        },
        {
          label: 'Engagements - Total',
          labelTooltip: this.tooltips.engagementsTotal,
          value: totalComments + totalLikes + shares + saved,
          children: [
            {
              label: 'Engagements - Organic',
              labelTooltip: this.tooltips.engagementsOrganic,
              value: comments + likes + shares + saved,
            },
            {
              label: 'Engagements - Promoted',
              labelTooltip: this.tooltips.engagementsPromoted,
              value: commentsCountPaid + likeCountPaid,
            },
          ],
        },
        {
          label: 'Likes',
          labelTooltip: null,
          value: likes + likeCountPaid,
          children: [
            {
              label: 'Likes - Organic',
              labelTooltip: null,
              value: likes,
            },
            {
              label: 'Likes - Promoted',
              labelTooltip: null,
              value: likeCountPaid,
            },
          ],
        },
        {
          label: 'Comments',
          labelTooltip: null,
          value: comments + commentsCountPaid,
          children: [
            {
              label: 'Comments - Organic',
              labelTooltip: null,
              value: comments,
            },
            {
              label: 'Comments - Promoted',
              labelTooltip: null,
              value: commentsCountPaid,
            },
          ],
        },
        {
          label: 'Shares - Organic',
          labelTooltip: null,
          value: shares,
          children: [],
        },
        {
          label: 'Saves - Organic',
          labelTooltip: null,
          value: saved,
          children: [],
        },
        ...this.promotedNewInsightProps,
        {
          label: 'LikeShop Clicks',
          labelTooltip: null,
          value: this.likeshopClicks,
          children: [],
        },
      ];
    },
    metaPromotedProps() {
      const isNeverConnected = !this.isMetaAdsConnected && !this.hasConnectedMetaAdsPreviously;
      const mapped = Object.entries(metaPromotedLevelReelFields)
        .sort(this.sortMetaPromoted)
        .filter(([topLevel]) => !isNeverConnected || !topLevel.endsWith('Paid'))
        .map(([topLevel, nesting]) => {
          const formatter = rateFieldFormat.includes(topLevel) ? this.formatRate : (v) => v;
          const children = [];
          if (nesting !== null && !isNeverConnected) {
            const nestingFormatted = getFormattedNestings(nesting, this.mediaItem.boostedData);
            children.push({
              label: 'Organic',
              labelTooltip: null,
              value: formatter(nestingFormatted.organic),
            });
            children.push({
              label: 'Promoted',
              labelTooltip: null,
              value: formatter(nestingFormatted.promoted),
            });
          }
          const label = this.formatMetaPromoted(topLevel);
          const value =
            topLevel === 'likeshopClicks'
              ? this.likeshopClicks
              : this.mediaItem.boostedData[topLevel];
          const labelTooltip = this.metaPromotedTooltips[topLevel];
          return {
            label,
            key: topLevel,
            labelTooltip,
            value: formatter(value),
            children,
          };
        });
      return mapped;
    },
    likeshopClicks() {
      const { likeshopClicks } = this.mediaItem;
      if (likeshopClicks && !Number.isNaN(likeshopClicks)) {
        return Number(likeshopClicks);
      }
      return 0;
    },
    showHighlightedInsights() {
      return this.postIsOwned;
    },
  },
  mounted() {
    this.fetchInsights();
  },
  methods: {
    async fetchInsights() {
      const { data } = await updatePost({
        brandId: this.mediaItem.brandId ?? this.currentBrand.id,
        postSourceId: this.mediaItem.postSourceId,
      });
      this.insights = data;
    },
    formatRate(value) {
      if (value === 0) {
        return 0;
      }
      return `${(value * 100).toFixed(2)}%`;
    },
    formatValue(value, key) {
      if (accountingField.includes(key)) {
        const formattedValue = formatValueByFormatType(value, METRIC_FORMATS.MONETARY_LONG, '-');
        return this.currency ? `${formattedValue} ${this.currency}` : formattedValue;
      }
      return value;
    },
    insightsPropsReformatLabelsOrganic(insightsProps) {
      return insightsProps.map((prop) => {
        prop.label = organicInsightsLabelsVerbose[prop.label] ?? `${prop.label} - Organic`;

        if (prop.children) {
          prop.children = prop.children.map((childProp) => {
            childProp.label += ' - Organic';
            return childProp;
          });
        }
        return prop;
      });
    },
    formatEmvRate(value) {
      return `$${localize(value)}`;
    },
    formatMetaPromoted(field) {
      return formatMetaPromoted(field, metaPromotedReelFieldsToVerbose);
    },
    sortMetaPromoted([a], [b]) {
      return sortMetaPromotedFields(a, b, this.formatMetaPromoted);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.all-insights-container {
  width: 100%;

  .main-insights-container {
    background: var(--background-300);
    border-radius: var(--round-corner);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--space-24);
    margin: var(--space-8) auto;
    font-size: var(--x14);
    color: var(--text-primary);
  }

  .hidden-likes {
    margin: var(--space-16) var(--space-32) 0 var(--space-32);
    background-color: var(--background-400);
    padding: var(--space-16) var(--space-16) var(--space-16) var(--space-16);
    text-align: left;
  }
}
</style>
