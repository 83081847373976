<template>
  <div class="link-preview-viewer" @mouseenter="hovered = true" @mouseleave="hovered = false">
    <LinkPreviewMedia v-if="value.length" :media="value[0]" />
    <div v-else class="no-image">
      <p>No Image</p>
    </div>

    <div v-show="showControls" class="media-controls">
      <DropdownButton
        v-tooltip="canEdit && 'Select a new image'"
        :disabled="!canEdit"
        :dropdown-list="addDropdownList"
        :button-icon-color="buttonIconColor"
        :button-icon-hover-color="buttonIconHoverColor"
        button-icon-size="xsmall"
        button-icon="pencil"
        round-small
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { colours } from '@/ux/colours';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import LinkPreviewMedia from '@/app/scheduler/components/EditPost/MediaViewer/LinkPreviewMedia.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'LinkPreviewViewer',
  components: {
    DropdownButton,
    LinkPreviewMedia,
  },
  props: {
    value: { type: Array, required: true },
    uploadClicked: { type: Function, required: true },
    libraryClicked: { type: Function, required: true },
    compactLayout: { type: Boolean, default: false },
    canEdit: { type: Boolean, default: false },
  },
  data() {
    return {
      hovered: false,
      buttonIconColor: colours.ICON.ICON_PRIMARY,
      buttonIconHoverColor: colours.ICON.ICON_LINK,
      addDropdownList: [
        {
          text: 'From Library',
          action: () => this.libraryClicked(),
        },
        {
          text: 'From Your Computer',
          action: () => this.uploadClicked(),
        },
      ],
    };
  },
  computed: {
    showControls() {
      return !this.compactLayout && this.hovered;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.link-preview-viewer {
  position: relative;
  height: 100%;

  .no-image {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: var(--text-secondary);
    font-weight: var(--font-medium);
  }

  .media-controls {
    display: flex;
    position: absolute;
    bottom: var(--space-12);
    left: 50%;
    transform: translate(-50%, 0);
  }
}
</style>
