<template>
  <div v-if="postLoaded">
    <EditInstagramPost
      v-if="type === 'instagram'"
      :disable-editing="isEditingDisabled"
      :context="context"
      :post="internalPost"
      :media="internalMedia"
      :media-caption="mediaCaption"
      :close="closeInstagramPopup"
      :show-post-editor-side-panel="showPostEditorSidePanel"
      :linked-comment-id="linkedCommentId"
      :post-type="postType"
    />
    <EditPinterestPost
      v-else-if="type === 'pinterest'"
      :disable-editing="isEditingDisabled"
      :context="context"
      :post="internalPost"
      :media="internalMedia"
      :media-caption="mediaCaption"
      :close="closePopup"
      :change-on-popup="changeOnPopup"
      :show-post-editor-side-panel="showPostEditorSidePanel"
      :linked-comment-id="linkedCommentId"
    />
    <BulkAddPinterestPost
      v-else-if="type === 'pinterestBulk'"
      :close="closePopup"
      :context="context"
      :initial-publish-type="initialPublishType"
      :media-list="internalMedia"
      :default-timestamp="internalDefaultTimestamp"
    />
    <EditTwitterPost
      v-else-if="type === 'twitter'"
      :key="authStore.currentBrand.id"
      :disable-editing="isEditingDisabled"
      :context="context"
      :post="internalPost"
      :media="internalMedia"
      :media-caption="mediaCaption"
      :close="closePopup"
      :show-post-editor-side-panel="showPostEditorSidePanel"
      :linked-comment-id="linkedCommentId"
      :duplicated-from="duplicatePostPlatform"
    />
    <EditFacebookPost
      v-else-if="type === 'facebook'"
      :key="authStore.currentBrand.id"
      :disable-editing="isEditingDisabled"
      :context="context"
      :post="internalPost"
      :media="internalMedia"
      :media-caption="mediaCaption"
      :close="closePopup"
      :show-post-editor-side-panel="showPostEditorSidePanel"
      :linked-comment-id="linkedCommentId"
      :duplicated-from="duplicatePostPlatform"
    />
    <EditTikTokPost
      v-else-if="type === 'tiktok'"
      :disable-editing="isEditingDisabled"
      :context="context"
      :post="internalPost"
      :media="internalMedia"
      :media-caption="mediaCaption"
      :close="closePopup"
      :show-post-editor-side-panel="showPostEditorSidePanel"
      :linked-comment-id="linkedCommentId"
    />
    <EditLinkedInPost
      v-else-if="type === 'linkedin'"
      :close="closePopup"
      :post="internalPost"
      :disable-editing="isEditingDisabled"
      :media="internalMedia"
      :media-caption="mediaCaption"
      :show-post-editor-side-panel="showPostEditorSidePanel"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { useMediaStore } from '@/stores/media';
import {
  DIALOG_TYPE_PLATFORMS,
  DIALOG_TYPES,
  postStatus,
  postTypes,
} from '@/app/scheduler/constants';
import EditInstagramPost from '@/app/scheduler/components/EditPost/EditInstagramPost.vue';
import EditPinterestPost from '@/app/scheduler/components/EditPost/EditPinterestPost.vue';
import EditTwitterPost from '@/app/scheduler/components/EditPost/EditTwitterPost.vue';
import EditFacebookPost from '@/app/scheduler/components/EditPost/EditFacebookPost.vue';
import BulkAddPinterestPost from '@/app/scheduler/components/EditPost/BulkAddPinterestPost.vue';
import PostDuplicationMixin from '@/mixins/postDuplicationMixin';
import EditTikTokPost from '@/app/scheduler/components/EditPost/EditTikTokPost.vue';
import { useSchedulerStore } from '@/stores/scheduler';
import { enumProp } from '@/utils/props';
import { useFlagStore } from '@/stores/flag';
import { useInstagramUserTaggerStore } from '@/stores/instagram-user-tagger';
import EditLinkedInPost from '@/app/scheduler/components/EditPost/EditLinkedInPost.vue';
import { convertPostFrom, convertPostTo, convertCrossBrandPost } from '@/utils/postDuplication';
import { schedulerStoreEventListener } from '@/app/scheduler/mixpanel';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ScheduledPostPopup',
  components: {
    EditTikTokPost,
    EditFacebookPost,
    EditInstagramPost,
    EditPinterestPost,
    EditTwitterPost,
    EditLinkedInPost,
    BulkAddPinterestPost,
  },
  mixins: [PostDuplicationMixin],
  props: {
    post: { type: Object, default: null },
    mediaIds: { type: [String, Array], default: null },
    mediaCaption: { type: String, default: null },
    changeOnPopup: { type: Boolean, default: false },
    duplicatePostId: { type: [Number, String], default: null },
    duplicatePostPlatform: { type: String, default: null },
    toType: { type: String, default: null },
    id: { type: [String, Number], default: null },
    context: { type: String, default: null },
    initialPublishType: { type: String, default: 'autoPublish' },
    showPostEditorSidePanel: { type: Boolean, default: false },
    type: enumProp(DIALOG_TYPES),
    hash: { type: String, default: '' },
    postType: enumProp(postTypes, postTypes.FEED),
    defaultTimestamp: { type: [String], default: null },
    ts: { type: String, default: null },
  },
  data() {
    return {
      postDataLoaded: false,
    };
  },
  computed: {
    ...mapStores(
      useSchedulerStore,
      useFlagStore,
      useInstagramUserTaggerStore,
      useAuthStore,
      useMediaStore,
    ),
    DIALOG_TYPES() {
      return DIALOG_TYPES;
    },
    linkedCommentId() {
      return this.hash ? parseInt(this.hash, 10) : null;
    },
    isEditingDisabled() {
      return (
        !!this.internalPost &&
        [postStatus.AUTOPUBLISHING, postStatus.POSTED, postStatus.IMPORTED].includes(
          this.internalPost.status,
        )
      );
    },
    isNewPost() {
      return this.id === 'new' || this.id === null;
    },
    postParameters() {
      return this.isNewPost ? null : { platform: DIALOG_TYPE_PLATFORMS[this.type], id: this.id };
    },
    internalPost() {
      const post = this.postParameters
        ? this.schedulerStore.getPost(this.postParameters)
        : this.post;
      if (!this.duplicatePostId) {
        if (this.ts) {
          return this.post
            ? { ...this.post, timestamp: new Date(this.ts) }
            : { timestamp: new Date(this.ts) };
        }
        return post;
      }
      return this.duplicatePost;
    },
    duplicatePost() {
      const postFromStore = this.schedulerStore.getPost({
        platform: this.duplicatePostPlatform,
        id: this.duplicatePostId,
      });
      if (this.ts) {
        postFromStore.timestamp = new Date(this.ts);
      }
      if (!this.toType) {
        return convertCrossBrandPost(postFromStore);
      }
      const fromType = this.pdPostTypeByPlatform(this.duplicatePostPlatform, postFromStore);
      const basePost = convertPostFrom(postFromStore, fromType);
      const newPost = convertPostTo(basePost, this.toType);
      return newPost.data.post ?? newPost.data;
    },
    postLoaded() {
      return (this.isNewPost || !!this.internalPost) && this.postDataLoaded;
    },
    internalDefaultTimestamp() {
      if (this.defaultTimestamp) {
        return new Date(this.defaultTimestamp);
      }
      return null;
    },
    internalMedia() {
      if (!this.mediaIds) {
        return this.internalPost?.mediaList ?? null;
      }
      const ids = Array.isArray(this.mediaIds ?? [])
        ? this.mediaIds ?? []
        : this.mediaIds.split(',');
      const media = (ids ?? []).map((id) => ({ id }));
      return media?.length > 0 ? media : null;
    },
  },
  async created() {
    schedulerStoreEventListener(this.schedulerStore);
    if (this.id && this.id !== 'new') {
      await this.schedulerStore.fetchPost({
        platform: DIALOG_TYPE_PLATFORMS[this.type],
        id: this.id,
      });
    }
    if (
      this.type === 'tiktok' &&
      !(
        this.authStore.user_can('scheduler', 'can_access_scheduler_tiktok') &&
        this.authStore.brand_can('scheduler', 'can_access_scheduler_tiktok')
      )
    ) {
      this.$router.replace({ name: 'scheduler.tiktok' });
    }
    this.postDataLoaded = true;
  },
  methods: {
    closePopup() {
      this.schedulerStore.setActiveSubScreen(null);
      this.$router.back();
    },
    closeInstagramPopup() {
      // Ensure that tagging people is reset correctly when closing the popup
      this.instagramUserTaggerStore.setEditingUserTag({ value: false });
      this.closePopup();
    },
  },
});
export default comp;
</script>
