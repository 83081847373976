import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useKeywordExpressionValidators } from '@/app/socialListening/composables/useKeywordExpressionValidators';
import { KEYWORD_TEXT_EDITOR_ERRORS } from '@/app/socialListening/constants';

export const useBooleanExpressionEditorStore = defineStore('booleanExpressionEditorStore', () => {
  const syntaxErrorMap = ref({
    '(': null,
    ')': null,
    AND: null,
    OR: null,
    NOT: null,
  });

  function resetTokenErrorMap() {
    syntaxErrorMap.value = {
      '(': null,
      ')': null,
      AND: null,
      OR: null,
      NOT: null,
    };
  }

  function errorHighlightForSyntaxError(error, keywordBooleanExpression) {
    const unexpected = error.tokens.unexpected;
    const { column, line } = error.location;
    const expressionByLine = keywordBooleanExpression.split('\n');
    const sliceOffset = Math.max(0, unexpected.length - 1); // some unexpected tokens have multiple characters, e.g. "AND"
    const n = expressionByLine[line - 1]
      .slice(0, column + sliceOffset) // slice up to the column + length of the unexpected token
      .split(/(\b|\s+|\(|\))/)
      .filter((char) => char.trim() === unexpected).length;
    syntaxErrorMap.value[unexpected] = n;
  }

  function handleTextEditorErrorsUsingExpression(expression) {
    const { hasMoreThanOneNotClause } = useKeywordExpressionValidators();

    if (hasMoreThanOneNotClause(expression)) {
      syntaxErrorMap.value.NOT = '*';
      return [
        {
          error: KEYWORD_TEXT_EDITOR_ERRORS.MULTIPLE_NOT_CLAUSES,
        },
      ];
    }
    return [];
  }

  function highlightDuplicateKeywords(keywordGroups) {
    // the goal here is to highlight the right nth occurrence of the keyword in the editor
    // build a word to total count map which helps to build a word to occurrences map so we
    // can highlight the correct occurrences
    const wordCountMap = {};
    const wordsToOccurrenceMap = {};
    keywordGroups.forEach((group) => {
      const keywords = group.keywords;
      const localWordCountMap = {};
      keywords.forEach((keyword) => {
        if (!wordCountMap[keyword]) {
          localWordCountMap[keyword] = 1;
          wordCountMap[keyword] = 1;
        } else {
          if (!localWordCountMap[keyword]) {
            localWordCountMap[keyword] = 1;
          } else {
            localWordCountMap[keyword] += 1;
          }
          wordCountMap[keyword] += 1;
        }
        // if local word count map value > 1 it's a duplicate
        if (localWordCountMap?.[keyword] > 1) {
          if (!wordsToOccurrenceMap[keyword]) {
            wordsToOccurrenceMap[keyword] = [wordCountMap[keyword]];
          } else {
            wordsToOccurrenceMap[keyword].push(wordCountMap[keyword]);
          }
        }
      });
    });
    // merge words to occurrence map into the state variable
    syntaxErrorMap.value = {
      ...syntaxErrorMap.value,
      ...wordsToOccurrenceMap,
    };
  }

  return {
    errorHighlightForSyntaxError,
    resetTokenErrorMap,
    syntaxErrorMap,
    handleTextEditorErrorsUsingExpression,
    highlightDuplicateKeywords,
  };
});
