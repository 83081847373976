import { defineAsyncComponent } from 'vue';

const App = () => import('@/app/threads/App.vue');
const Overview = () => import('@/app/threads/pages/Overview.vue');
const YourPosts = defineAsyncComponent(() => import('@/app/threads/pages/YourPosts.vue'));

const PAGE_LEVEL_TOP = 'Threads';

export default {
  path: 'threads',
  component: App,
  meta: {
    requiresAuth: true,
  },
  children: [
    {
      name: 'threads',
      path: '',
      redirect: { name: 'threads.overview' },
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Overview`,
      },
    },
    {
      name: 'threads.overview',
      path: 'overview',
      component: Overview,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Overview`,
      },
    },
    {
      name: 'threads.yourPosts',
      path: 'your-posts',
      component: YourPosts,
      meta: {
        analytics: `${PAGE_LEVEL_TOP} - Your Posts`,
      },
    },
  ],
};
