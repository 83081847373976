<template>
  <div class="carousel-container">
    <div :class="['carousel-viewer', aspectRatio, { 'has-multiple-media': hasMultipleMedia }]">
      <div class="aspect-ratio-container">
        <Carousel
          ref="carousel"
          class="carousel"
          :per-page="1"
          :pagination-color="paginationColor"
          :pagination-active-color="paginationActiveColor"
          :pagination-padding="5"
          :pagination-size="8"
          :navigate-to="schedulerStore.instagramCarouselPage"
          :navigation-enabled="navigationEnabled"
          :navigation-click-target-size="0"
          navigation-next-label=""
          navigation-prev-label=""
          @page-change="onPageChange"
          @transitionend.passive="onTransitionEnd"
        >
          <Slide v-for="(media, index) in value" :key="index" :is-draggable="hasMultipleMedia">
            <Media
              ref="slideMedia"
              :media="media"
              :cover-image="coverImage"
              :aspect-ratio="aspectRatio"
              :media-warning-ids="mediaWarningIds"
              :validation-error="validationError"
              :post-type="postType"
              :cover="isCover"
            />
          </Slide>
        </Carousel>

        <TaggerContainer
          v-if="activeTaggerComponent"
          :tagger-component="activeTaggerComponent"
          :tagger-component-props="{
            carouselActiveMedia: schedulerStore.instagramCarouselActiveMedia,
            transitioning,
          }"
          :media-width="taggerMediaWidth"
          :media-height="taggerMediaHeight"
          :product-tagging-view-only="productTaggingViewOnly"
        />

        <TagCounter v-if="showTagCounter" />

        <div class="controls-top">
          <DropdownButton
            v-if="!disableEditing && !viewingSubScreen && allowAdd"
            v-tooltip="'Add Media'"
            :dropdown-list="addDropdownList"
            :button-icon-color="buttonIconColor"
            :button-icon-hover-color="buttonIconHoverColor"
            :button-tooltip="dropdownButtonTooltip"
            :disabled="isDropdownButtonDisabled"
            align-left
            button-classes="rectangular"
            button-icon="add"
            data-cy="add-media-button"
            @dropdown-action-clicked="addActionClicked"
          />
          <div v-if="showCropperButton" class="button-wrapper">
            <Button
              v-tooltip="'Edit Media'"
              :icon-color="buttonIconColor"
              :icon-hover-color="buttonIconHoverColor"
              icon-name="crop"
              rectangular
              data-cy="open-cropper"
              class="crop-button"
              @click="handleCropClicked"
            />
          </div>
          <div v-if="!disableEditing && !viewingSubScreen && value.length" class="button-wrapper">
            <Button
              v-tooltip="'Remove Media'"
              :icon-color="buttonIconColor"
              :icon-hover-color="buttonIconHoverColor"
              icon-name="bin"
              rectangular
              data-cy="remove-media"
              @click="removeItem"
            />
          </div>
          <div v-if="!viewingSubScreen && value.length" class="button-wrapper">
            <Button
              v-if="showDownloadButton"
              v-tooltip="'Download Media'"
              :icon-color="buttonIconColor"
              :icon-hover-color="buttonIconHoverColor"
              icon-name="download"
              rectangular
              data-cy="download-media"
              :loading="
                !!downloadingMediaList.includes(value[schedulerStore.instagramCarouselPage].id)
              "
              @click="handleDownloadMedia(value[schedulerStore.instagramCarouselPage])"
            />
          </div>
        </div>
        <div v-if="showCropCoverImageButton" class="crop-cover-image-wrapper">
          <Button
            :loading="isGeneratingThumbnailUrl"
            small
            rectangular
            class="crop-cover-image-button"
            @click="handleCropCoverImageClicked"
          >
            Crop Cover Image
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide } from '@/vendor/vue-carousel';
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import downloadMediaMixin from '@/mixins/downloadMediaMixin';
import { toolTips, UPLOAD_STATUS } from '@/config';
import { colours } from '@/ux/colours';
import Button from '@/components/foundation/Button.vue';
import { tabContexts, fbPostTypes } from '@/app/scheduler/constants';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import { mediaSizeIsCroppable } from '@/utils/media';
import TaggerContainer from '@/components/TaggerContainer.vue';
import TagCounter from '@/app/scheduler/components/EditPost/TagCounter.vue';
import UserTagger from '@/app/scheduler/components/EditPost/UserTagger.vue';
import ShoppingTagger from '@/app/scheduler/components/EditPost/ShoppingTagger.vue';
import FacebookProductTagger from '@/app/scheduler/components/EditPost/FacebookProductTagger.vue';
import { useFlagStore } from '@/stores/flag';
import { useCropperStore } from '@/stores/cropper';
import { BRAND } from '@/models/auth/permissions.enum';
import { useSchedulerStore } from '@/stores/scheduler';
import { useInstagramShoppingTaggerStore } from '@/stores/instagram-shopping-tagger';
import { useInstagramUserTaggerStore } from '@/stores/instagram-user-tagger';
import { useFacebookProductTaggerStore } from '@/stores/facebook-product-tagger';
import { ThumbnailUrlGenerator } from '@/app/scheduler/utils';
import Media from './Media.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'CarouselViewer',
  components: {
    TaggerContainer,
    TagCounter,
    Button,
    Carousel,
    DropdownButton,
    Media,
    Slide,
  },
  mixins: [downloadMediaMixin],
  props: {
    allowAdd: { type: Boolean, default: true },
    disableEditing: { type: Boolean, default: false },
    value: { type: Array, required: true },
    enableCropping: { type: Boolean, default: false },
    cropClicked: { type: Function, default: () => {} },
    uploadClicked: { type: Function, required: false, default: null },
    libraryClicked: { type: Function, required: false, default: null },
    removeClicked: { type: Function, required: false, default: null },
    aspectRatio: { type: String, default: 'square' },
    platform: { type: String, required: false, default: null },
    navigationEnabled: { type: Boolean, required: false, default: false },
    downloadMediaClicked: { type: Function, default: () => ({}) },
    productTaggingViewOnly: { type: Boolean, required: false, default: false },
    coverImage: { type: Object, default: null },
    onCroppingCoverImage: { type: Function, default: () => ({}) },
    mediaWarningIds: { type: Array, default: () => [] },
    validationError: { type: Object, default: null },
    postType: { type: String, default: null },
    isCover: { type: Boolean, default: false },
  },
  data() {
    return {
      paginationColor: colours.BACKGROUND.BACKGROUND_400,
      paginationActiveColor: colours.TEXT.TEXT_SECONDARY,
      buttonIconColor: colours.ICON.ICON_PRIMARY,
      buttonIconHoverColor: colours.ICON.ICON_LINK,
      dropdownButtonTooltipMessage: null,
      mediaExplorer: DropdownButton,
      transitioning: false,
      downloadingMediaList: [],
      thumbnailUrl: null,
      thumbnailUrlGenerator: new ThumbnailUrlGenerator(),
      isGeneratingThumbnailUrl: false,
    };
  },
  computed: {
    ...mapStores(
      useCropperStore,
      useAuthStore,
      useSchedulerStore,
      useInstagramShoppingTaggerStore,
      useInstagramUserTaggerStore,
      useFacebookProductTaggerStore,
      useFlagStore,
    ),
    hasLibraryAccess() {
      return this.authStore.guard(BRAND.LIBRARY.CAN_ACCESS_LIBRARY);
    },
    addDropdownList() {
      const fromLibrary = this.hasLibraryAccess
        ? [
            {
              text: 'From Library',
              action: 'libraryClicked',
            },
          ]
        : [];
      return fromLibrary.concat([
        {
          text: 'From Your Computer',
          action: 'uploadClicked',
        },
      ]);
    },
    isDropdownButtonDisabled() {
      if (this.schedulerStore.instagramTabContext === tabContexts.FEED_POST) {
        return this.dropdownButtonDisabledDueToMediaCapacityExceeded;
      }
      return false;
    },
    currentMediaUploading() {
      if (this.value.length > 0 && this.value[this.schedulerStore.instagramCarouselPage]) {
        return (
          Boolean(this.value[this.schedulerStore.instagramCarouselPage].uploadStatus) &&
          Boolean(!this.value[this.schedulerStore.instagramCarouselPage].fullMediaObject)
        );
      }
      return false;
    },
    mediaIsCroppable() {
      return mediaSizeIsCroppable(this.value[this.schedulerStore.instagramCarouselPage]);
    },
    showCropperButton() {
      return (
        this.value.length > 0 &&
        this.value[this.schedulerStore.instagramCarouselPage] &&
        !this.disableEditing &&
        !this.currentMediaUploading &&
        !this.viewingSubScreen &&
        this.mediaIsCroppable &&
        (!this.value[this.schedulerStore.instagramCarouselPage].uploadStatus ||
          this.value[this.schedulerStore.instagramCarouselPage].uploadStatus ===
            UPLOAD_STATUS.SUCCESS)
      );
    },
    showDownloadButton() {
      return (
        !this.currentMediaUploading &&
        (this.value[this.schedulerStore.instagramCarouselPage].uploadStatus ===
          UPLOAD_STATUS.SUCCESS ||
          !this.value[this.schedulerStore.instagramCarouselPage].uploadStatus)
      );
    },
    isReel() {
      return (
        this.schedulerStore.instagramTabContext === tabContexts.REEL ||
        this.postType === fbPostTypes.REEL
      );
    },
    coverPhotoCroppable() {
      // The cover photo should either haven't been cropped yet, or it should have a parent media id
      return (
        (this.coverImage?.sizes &&
          mediaSizeIsCroppable(this.coverImage) &&
          !this.coverImage?.transforms) ||
        this.coverImage?.transforms?.parentMediaId
      );
    },
    thumbnailCroppable() {
      if (this.platform === 'facebook' && this.isReel) {
        return true;
      }
      return this.isReel && this.schedulerStore.autoPublish && !this.coverImage?.transforms;
    },
    showCropCoverImageButton() {
      // We show the crop cover photo button when a thumbnail url is generated or a
      // cover image is selected.
      const isCroppable = this.coverPhotoCroppable || this.thumbnailCroppable;
      return (
        !this.disableEditing &&
        !this.currentMediaUploading &&
        !this.viewingSubScreen &&
        this.schedulerStore.instagramCarouselActiveMedia?.type !== 'IMAGE' &&
        isCroppable &&
        // TODO: add check for upload status
        this.flagStore.ready
      );
    },
    dropdownButtonDisabledDueToMediaCapacityExceeded() {
      return this.value.length >= 10 && this.platform === 'instagram';
    },
    dropdownButtonTooltip() {
      if (this.dropdownButtonDisabledDueToMediaCapacityExceeded) {
        return toolTips.igMediaCapacityExceeded;
      }
      return null;
    },
    activeTaggerComponent() {
      if (
        this.instagramUserTaggerStore.enabled &&
        this.schedulerStore.instagramCarouselActiveMedia.type !== 'VIDEO'
      ) {
        return UserTagger;
      }
      if (this.instagramShoppingTaggerStore.shoppingTaggerEnabled) {
        return ShoppingTagger;
      }
      if (this.facebookProductTaggerStore.facebookProductTaggerEnabled) {
        return FacebookProductTagger;
      }
      return undefined;
    },
    showTagCounter() {
      return (
        this.activeTaggerComponent === UserTagger ||
        this.activeTaggerComponent === ShoppingTagger ||
        this.instagramUserTaggerStore.enabledTagCounter
      );
    },
    taggerMediaWidth() {
      return this.schedulerStore.instagramCarouselActiveMedia.sizes
        ? // Media V2 Object
          this.schedulerStore.instagramCarouselActiveMedia.sizes.originalConverted?.width ||
            this.schedulerStore.instagramCarouselActiveMedia.sizes.original.width
        : // Uploaded media
          this.schedulerStore.instagramCarouselActiveMedia.width;
    },
    taggerMediaHeight() {
      return this.schedulerStore.instagramCarouselActiveMedia.sizes
        ? // Media V2 Object
          this.schedulerStore.instagramCarouselActiveMedia.sizes.originalConverted?.height ||
            this.schedulerStore.instagramCarouselActiveMedia.sizes.original.height
        : // Uploaded media
          this.schedulerStore.instagramCarouselActiveMedia.height;
    },
    viewingTaggerSubScreen() {
      return (
        [tabContexts.FEED_POST, tabContexts.REEL].includes(
          this.schedulerStore.instagramTabContext,
        ) && ['tagPeople', 'tagProducts'].includes(this.schedulerStore.activeSubScreen)
      );
    },
    viewingSubScreen() {
      return this.schedulerStore.activeSubScreen && this.schedulerStore.activeSubScreen !== 'index';
    },
    hasMultipleMedia() {
      return this.value.length > 1;
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (newVal.length === 0) {
          this.schedulerStore.setInstagramCarouselPage({
            carouselPage: undefined,
            activeMedia: undefined,
          });
        } else if (oldVal?.length < newVal.length && oldVal?.length !== 0) {
          // Scroll to end of carousel when new items are added
          const lastPage = newVal.length - 1;
          this.schedulerStore.setInstagramCarouselPage({
            carouselPage: lastPage,
            activeMedia: newVal[lastPage],
          });
        } else {
          const firstPage = 0;
          this.schedulerStore.setInstagramCarouselPage({
            carouselPage: firstPage,
            activeMedia: newVal[firstPage],
          });
        }
      },
      immediate: true,
    },
    'schedulerStore.instagramCarouselPage': {
      handler(newVal, oldVal) {
        if (this.$refs.slideMedia) {
          const oldMediaItem = this.$refs.slideMedia[oldVal];
          if (oldMediaItem && oldMediaItem.media.type === 'VIDEO' && !oldMediaItem.paused) {
            oldMediaItem.pause();
          }
        }
      },
    },
  },
  methods: {
    addActionClicked(option) {
      this[option]();
    },
    removeItem() {
      this.cropperStore.removeAdjustLinksPrompt();
      this.cropperStore.removeAdjustTagsPrompt();
      this.removeClicked(this.value[this.schedulerStore.instagramCarouselPage]);
    },
    handleCropClicked() {
      const media = this.value[this.schedulerStore.instagramCarouselPage];
      this.cropClicked(media, this.schedulerStore.instagramCarouselPage);
      // pause the current video if it is played
      if (media.type === 'VIDEO') {
        this.$refs.slideMedia[this.schedulerStore.instagramCarouselPage].pause();
      }
    },
    async generateThumbnailMedia() {
      return this.thumbnailUrlGenerator.createThumbnailUrl(
        this.value,
        this.schedulerStore.thumbOffset,
      );
    },
    handleCropCoverImageClicked() {
      // If cropping a thumbnail
      if (!this.coverImage) {
        // Wait until the url is generated
        this.isGeneratingThumbnailUrl = true;
        this.generateThumbnailMedia().then((res) => {
          this.thumbnailUrl = res;
          this.onCroppingCoverImage(true);
          this.isGeneratingThumbnailUrl = false;
          this.cropClicked(this.thumbnailUrl, this.schedulerStore.instagramCarouselPage, true);
        });
      } else {
        this.onCroppingCoverImage(true);
        this.cropClicked(this.coverImage, this.schedulerStore.instagramCarouselPage, true);
      }
    },
    onPageChange(page) {
      this.schedulerStore.setInstagramCarouselPage({
        carouselPage: page,
        activeMedia: this.value[page],
      });
      // @transition-start doesn't fire until after the transition occurred (vue-carousel bug)
      this.transitioning = true;
    },
    onTransitionEnd() {
      this.transitioning = false;
    },
    async handleDownloadMedia(media) {
      if (this.downloadingMediaList.includes(media.id)) return;
      this.downloadingMediaList.push(media.id);
      this.downloadMediaClicked(media);
      const status = await this.downloadMedia(this.getDownloadableMediaUrl(media));
      if (status) {
        const index = this.downloadingMediaList.indexOf(media.id);
        this.downloadingMediaList.splice(index, 1);
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss">
.VueCarousel-navigation .VueCarousel-navigation-button {
  height: var(--space-24);
  width: var(--space-24);
  z-index: 2;
  border-radius: 50%;

  &:focus {
    outline: none;
  }

  &.VueCarousel-navigation-prev {
    background: url('@/assets/img/caret-white-left.svg') no-repeat center / 30%
      var(--black-alpha-70);
    top: calc(50% - var(--space-19));
    left: var(--space-32);
  }

  &.VueCarousel-navigation-next {
    background: url('@/assets/img/caret-white-right.svg') no-repeat calc(50% + 1px) / 30%
      var(--black-alpha-70);
    top: calc(50% - var(--space-19));
    right: var(--space-32);
  }

  &.VueCarousel-navigation--disabled {
    display: none;
  }
}

.VueCarousel-pagination .VueCarousel-dot:focus {
  outline: none;
}
</style>

<style lang="postcss" scoped>
.aspect-ratio-container {
  width: 100%;
  height: 100%;
}

.square {
  aspect-ratio: 1 / 1;
}

.portrait {
  aspect-ratio: 9 / 16;
}

.carousel-container {
  height: 100%;

  .carousel-viewer {
    position: relative;
    background: url('@/assets/img/logos/white-dh.png') no-repeat center var(--background-500);
    border-radius: var(--round-corner-small);

    .comment-bubble {
      position: absolute;
      top: calc(-2.5rem - 5px);
      display: inline-block;
      margin-left: var(--space-60);
    }
  }
}

.has-multiple-media {
  margin-bottom: var(--space-32);
}

.controls-top {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: var(--space-12);
  left: var(--space-12);

  .dropdown-button {
    margin-right: var(--space-8);
  }

  .button-wrapper {
    position: relative;
    margin-right: var(--space-8);
  }
}

.crop-cover-image-wrapper {
  position: absolute;
  bottom: var(--space-8);
  left: var(--space-8);

  .crop-cover-image-button {
    border-radius: var(--button-border-radius);
    padding: 0 var(--space-12);
  }

  .rectangular.button.loading {
    width: unset;
    height: var(--space-32);
    transition: unset;
    padding: 0 var(--space-12);

    &::after {
      border: 2px solid var(--text-secondary);
      border-left-color: var(--background-500);
      border-right-color: var(--background-500);
      border-bottom-color: var(--background-500);
    }
  }
}
</style>
