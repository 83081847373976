<template>
  <div class="error-panel">
    <img :src="IMG.emptyField" />
    <h4 class="error-title">Something went wrong.</h4>
    <div class="error-message">
      We have been notified of this issue and are working to fix it. Please try again later or reach
      out to your Customer Success Representative.
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { IMG } from '@/assets';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  props: {
    error: {
      type: [Object, Boolean, Error],
      default: false,
    },
  },
  computed: {
    IMG() {
      return IMG;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.error-panel {
  width: 400px;
  margin: 100px auto;
  text-align: center;

  .error-title {
    margin-top: var(--space-40);
  }

  .error-message {
    margin-top: var(--space-16);
  }
}
</style>
