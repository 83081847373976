import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { handleCancelError, refreshAbortController } from '@/apis/axios.utils';
import * as InstagramAPI from '@/apis/instagram';
import constants, {
  CONTACT_BUTTON_BREAKDOWN,
  INTERACTION_INSIGHTS_METRIC,
  MEDIA_PRODUCT_TYPE_BREAKDOWN,
} from '@/app/instagram/constants';
import { useAuthStore } from '@/stores/auth';
import { useSocketStore } from '@/stores/socket';

function createMetricBreakdownArray(baseMetric, breakdowns, includeBaseMetric = true) {
  return [
    ...(includeBaseMetric ? [baseMetric] : []),
    ...Object.values(breakdowns).map((breakdown) => `${baseMetric}.${breakdown}`),
  ];
}

export const INTERACTIONS_DATA_KEY_TO_CSV_METRICS_SET = Object.freeze({
  accountsEngaged: Object.freeze([INTERACTION_INSIGHTS_METRIC.ACCOUNTS_ENGAGED]),
  totalInteractions: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.TOTAL_INTERACTIONS,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.TOTAL_INTERACTIONS_MEDIA_PRODUCT_TYPE,
      MEDIA_PRODUCT_TYPE_BREAKDOWN,
    ),
  ]),
  likes: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.LIKES,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.LIKES_MEDIA_PRODUCT_TYPE,
      MEDIA_PRODUCT_TYPE_BREAKDOWN,
    ),
  ]),
  comments: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.COMMENTS,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.COMMENTS_MEDIA_PRODUCT_TYPE,
      MEDIA_PRODUCT_TYPE_BREAKDOWN,
    ),
  ]),
  shares: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.SHARES,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.SHARES_MEDIA_PRODUCT_TYPE,
      MEDIA_PRODUCT_TYPE_BREAKDOWN,
    ),
  ]),
  saves: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.SAVES,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.SAVES_MEDIA_PRODUCT_TYPE,
      MEDIA_PRODUCT_TYPE_BREAKDOWN,
    ),
  ]),
  replies: Object.freeze([INTERACTION_INSIGHTS_METRIC.REPLIES]),
  profileActions: Object.freeze([
    INTERACTION_INSIGHTS_METRIC.WEBSITE_CLICKS,
    ...createMetricBreakdownArray(
      INTERACTION_INSIGHTS_METRIC.PROFILE_LINKS_TAPS,
      CONTACT_BUTTON_BREAKDOWN,
      false,
    ),
  ]),
});

export const useInstagramAccountStore = defineStore(
  'instagramAccount',
  () => {
    const authStore = useAuthStore();
    const socketStore = useSocketStore();

    const tooltips = constants.accountOverviewTooltips;

    const pending = ref({
      accountOverview: false,
      igUser: false,
      addAccountStatus: false,
      brandInstagramAccount: false,
      interactionInsights: false,
      audienceDemographics: false,
      profileActivityInsights: false,
    });

    const initialized = ref({
      brandInstagramAccount: false,
    });

    const error = ref({
      addAccountStatus: null,
      getIgUser: null,
      getInteractionsInsights: null,
      getAudienceDemographics: null,
      getProfileActivityInsights: null,
    });

    const abortControllers = {
      getIgUser: ref(null),
      getInteractionsInsights: ref(null),
      getAudienceDemographics: ref(null),
    };

    const brandInstagramAccount = ref(null);
    const addedAccount = ref(null);
    const accountOverview = ref(null);
    const igUser = ref({});
    const igUserPosts = ref([]);
    const igUserNextToken = ref(null);

    const interactionInsights = ref([]);
    const audienceDemographics = ref({});

    const profileActivityInsights = ref([]);

    const profileStats = computed(() => {
      if (!accountOverview.value) {
        return [];
      }
      return [
        {
          label: 'Content Reach',
          total: accountOverview.value.reach.values ? '' : '-',
          tooltip: tooltips.contentReach,
          chartData: accountOverview.value.reach.values
            ? accountOverview.value.reach
            : { labels: accountOverview.value.impressions.labels, values: [], disabled: true },
          metric: 'Reach',
        },
        {
          label: 'Content Impressions',
          total: accountOverview.value.impressions.values
            ? sum(accountOverview.value.impressions.values)
            : '-',
          tooltip: tooltips.contentImpression,
          chartData: accountOverview.value.impressions,
          metric: 'Impressions',
        },
        {
          label: 'Profile Views',
          total: accountOverview.value.views.values ? sum(accountOverview.value.views.values) : '-',
          tooltip: tooltips.profileViews,
          chartData: accountOverview.value.views,
        },
      ];
    });

    const interactionStats = computed(() => {
      if (!accountOverview.value) {
        return [];
      }
      const { websiteClicks, emailContactClicks, textMessageClicks, getDirectionClicks } =
        accountOverview.value;
      return [
        {
          label: 'Website Clicks',
          total: websiteClicks.values ? sum(websiteClicks.values) : '-',
          tooltip: tooltips.websiteClicks,
          chartData: websiteClicks,
        },
        {
          label: 'Email Contact Clicks',
          total: emailContactClicks.values ? sum(emailContactClicks.values) : '-',
          tooltip: tooltips.emailContactClicks,
          chartData: emailContactClicks,
        },
        {
          label: 'Text Message Clicks',
          total: textMessageClicks.values ? sum(textMessageClicks.values) : '-',
          tooltip: tooltips.textMessageClicks,
          chartData: textMessageClicks,
        },
        {
          label: 'Get Direction Clicks',
          total: getDirectionClicks.values ? sum(getDirectionClicks.values) : '-',
          tooltip: tooltips.getDirectionClicks,
          chartData: getDirectionClicks,
        },
      ];
    });

    async function getIgUser({ handle, after, limit }) {
      pending.value.igUser = true;
      try {
        const signal = refreshAbortController(abortControllers.getIgUser);

        // If the handle were searching doesn't match the existing posts, clear them.
        if (igUserPosts.value[0]?.handle !== handle) {
          igUserPosts.value = [];
        }

        const brandId = authStore.currentBrand?.id;
        const response = await InstagramAPI.getInstagramUsers(
          { brandId, handle, after, limit },
          { signal },
        );
        const payload = response?.data;

        if (isEmpty(igUser.value)) {
          igUser.value = payload?.user;
        }

        if (payload?.posts) {
          const posts = payload.posts.map((item) => ({ handle: igUser.value?.handle, ...item }));
          igUserPosts.value = [...igUserPosts.value, ...posts];
          igUserNextToken.value = payload.after;
        }

        return payload;
      } catch (e) {
        error.value.getIgUser = e.response;
        if (e.response?.status === 422) {
          igUserPosts.value = [];
          return [];
        }
        return handleCancelError(e);
      } finally {
        pending.value.igUser = false;
      }
    }

    async function addAccount({ handle }) {
      pending.value.addAccountStatus = true;
      error.value.addAccountStatus = null;
      try {
        const response = await InstagramAPI.addAccount({ handle });
        const payload = response?.data;
        addedAccount.value = payload;
        return payload;
      } catch (e) {
        error.value.addAccountStatus = e;
        throw e;
      } finally {
        pending.value.addAccountStatus = false;
      }
    }

    async function getBrandInstagramAccount() {
      const brandId = authStore.currentBrand?.id;
      pending.value.brandInstagramAccount = true;
      try {
        const response = await InstagramAPI.getBrandInstagramAccount({ brandId });
        const payload = response?.data;
        brandInstagramAccount.value = payload;
        initialized.value.brandInstagramAccount = true;
        return payload;
      } catch (e) {
        if (e?.response?.status === 404) {
          brandInstagramAccount.value = null;
        } else {
          throw e;
        }
      } finally {
        pending.value.brandInstagramAccount = false;
      }
      return undefined;
    }

    async function getAccountOverview({ scale, startDate, endDate }) {
      pending.value.accountOverview = true;
      try {
        const brandId = authStore.currentBrand?.id;
        const response = await InstagramAPI.getBrandUserInsights({
          brandId,
          scale,
          startDate,
          endDate,
        });
        const payload = response?.data;
        accountOverview.value = payload;
        return payload;
      } finally {
        pending.value.accountOverview = false;
      }
    }

    async function getInteractionsInsights({ scale, startDate, endDate }) {
      if (!scale || !startDate || !endDate) {
        return [];
      }

      pending.value.interactionInsights = true;
      try {
        const response = await InstagramAPI.getInteractionInsights(
          {
            scale,
            startDate,
            endDate,
            brandId: authStore.currentBrand?.id,
            metrics: [
              INTERACTION_INSIGHTS_METRIC.ACCOUNTS_ENGAGED,
              INTERACTION_INSIGHTS_METRIC.TOTAL_INTERACTIONS,
              INTERACTION_INSIGHTS_METRIC.TOTAL_INTERACTIONS_MEDIA_PRODUCT_TYPE,
              INTERACTION_INSIGHTS_METRIC.LIKES,
              INTERACTION_INSIGHTS_METRIC.LIKES_MEDIA_PRODUCT_TYPE,
              INTERACTION_INSIGHTS_METRIC.COMMENTS,
              INTERACTION_INSIGHTS_METRIC.COMMENTS_MEDIA_PRODUCT_TYPE,
              INTERACTION_INSIGHTS_METRIC.SHARES,
              INTERACTION_INSIGHTS_METRIC.SHARES_MEDIA_PRODUCT_TYPE,
              INTERACTION_INSIGHTS_METRIC.SAVES,
              INTERACTION_INSIGHTS_METRIC.SAVES_MEDIA_PRODUCT_TYPE,
              INTERACTION_INSIGHTS_METRIC.REPLIES,
              INTERACTION_INSIGHTS_METRIC.PROFILE_LINKS_TAPS,
              INTERACTION_INSIGHTS_METRIC.WEBSITE_CLICKS,
            ],
          },
          { signal: refreshAbortController(abortControllers.getInteractionsInsights) },
        );
        const payload = response?.data;
        interactionInsights.value = payload?.data;
        return payload?.data;
      } catch (e) {
        error.value.getInteractionsInsights = e.response;
        return handleCancelError(e);
      } finally {
        pending.value.interactionInsights = false;
      }
    }

    async function getProfileActivityInsights({ scale, startDate, endDate }) {
      if (!startDate || !endDate) {
        return [];
      }

      pending.value.profileActivityInsights = true;
      try {
        const response = await InstagramAPI.getInteractionInsights({
          scale,
          startDate,
          endDate,
          brandId: authStore.currentBrand?.id,
          metrics: [
            INTERACTION_INSIGHTS_METRIC.REACH_ALL,
            INTERACTION_INSIGHTS_METRIC.REACH_FOLLOWERS,
            INTERACTION_INSIGHTS_METRIC.REACH_NON_FOLLOWERS,
            INTERACTION_INSIGHTS_METRIC.IMPRESSIONS,
            INTERACTION_INSIGHTS_METRIC.PROFILE_VIEWS,
          ],
        });
        const payload = response?.data;
        profileActivityInsights.value = payload?.data;
        return payload?.data;
      } catch (e) {
        error.value.getProfileActivityInsights = e.response;
        return handleCancelError(e);
      } finally {
        pending.value.profileActivityInsights = false;
      }
    }

    async function generateInteractionsInsightsCSV({
      dataKey,
      csvTitle,
      scale,
      startDate,
      endDate,
    }) {
      const response = await InstagramAPI.postInteractionInsightsCSV({
        scale,
        startDate,
        endDate,
        csvTitle,
        metrics: INTERACTIONS_DATA_KEY_TO_CSV_METRICS_SET[dataKey],
        brandId: authStore.currentBrand?.id,
        socketId: socketStore.id,
      });
      const payload = response?.data;
      return payload;
    }

    async function getAccountOverviewCSV({ scale, startDate, endDate }) {
      const brandId = authStore.currentBrand?.id;
      const socketId = socketStore.id;
      const response = await InstagramAPI.getBrandUserInsightsCsv({
        brandId,
        scale,
        startDate,
        endDate,
        socketId,
      });
      const payload = response?.data;
      return payload;
    }

    async function getAudienceDemographics({ date, metric }) {
      if (!date) {
        return {};
      }
      pending.value.audienceDemographics = true;
      try {
        const response = await InstagramAPI.getDemographicInsights(
          {
            brandId: authStore.currentBrand?.id,
            date,
            metric,
          },
          { signal: refreshAbortController(abortControllers.getAudienceDemographics) },
        );
        const payload = response?.data;
        audienceDemographics.value = payload;
        return payload;
      } catch (e) {
        error.value.getAudienceDemographics = e.response;
        return handleCancelError(e);
      } finally {
        pending.value.audienceDemographics = false;
      }
    }

    async function generateAudienceDemographicsCSV({ date, metric, breakdown }) {
      const brandId = authStore.currentBrand?.id;
      const socketId = socketStore.id;
      const response = await InstagramAPI.postDemographicInsightsCSV({
        brandId,
        date,
        metric,
        socketId,
        breakdown,
      });
      return response?.data;
    }

    function clearBrandInstagramAccount() {
      brandInstagramAccount.value = null;
    }

    function clearIgUser() {
      igUser.value = null;
      igUserPosts.value = [];
      error.value.getIgUser = null;
      igUserNextToken.value = null;
    }

    return {
      pending,
      initialized,
      error,
      igUser,
      igUserPosts,
      igUserNextToken,
      accountOverview,
      profileStats,
      interactionStats,
      interactionInsights,
      profileActivityInsights,
      audienceDemographics,
      addedAccount,
      brandInstagramAccount,
      getAccountOverview,
      getAccountOverviewCSV,
      clearBrandInstagramAccount,
      getIgUser,
      clearIgUser,
      addAccount,
      getBrandInstagramAccount,
      getInteractionsInsights,
      generateInteractionsInsightsCSV,
      getAudienceDemographics,
      generateAudienceDemographicsCSV,
      getProfileActivityInsights,
    };
  },
  {
    resetOnBrandChange: true,
  },
);
