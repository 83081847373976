<template>
  <div :class="{ compact: compactLayout }" data-cy="link-preview" class="link-preview">
    <Button
      v-if="!disabled && !compactLayout"
      :icon-color="iconColor"
      :icon-hover-color="iconHoverColor"
      :disabled="disabled"
      class="remove"
      icon-name="close"
      xsmall
      @click="$emit('remove-clicked')"
    />

    <div class="media-section">
      <template v-if="compactLayout">
        <img v-if="image" :src="image" class="image" />
      </template>
      <MediaManager
        v-else
        :display-error-message="onMediaUploadError"
        :media-selected="onMediaSelected"
        :media-list="mediaList"
        :viewer-component="viewerComponent"
        :viewer-component-props="{ compactLayout, canEdit }"
        :media-type-limit="'IMAGE'"
        class="image"
        single-select-media
        clear-media-on-add
        :can-be-published-at="canBePublishedAt"
      />
    </div>

    <div class="body" @mouseover="bodyHovered = true" @mouseleave="bodyHovered = false">
      <div v-if="!showLinkPreviewEditTextView" class="link-info">
        <div :class="{ 'text-fields': !compactLayout }">
          <p class="url no-overflow no-wrap">{{ hostname }}</p>
          <p v-if="title" v-line-clamp="2" class="title no-overflow">{{ title }}</p>
          <p v-if="description" class="description no-overflow no-wrap">{{ description }}</p>
          <Icon
            v-if="showEditLinkPreviewIcon"
            v-tooltip="canEdit && 'Edit title and description'"
            name="pencil"
            xsmall
            :class="['edit-link-preview-button', { disabled: !canEdit }]"
            @click="() => canEdit && editLinkTextPreview()"
          />
        </div>
      </div>
      <div v-else class="text-fields">
        <label class="edit-title">
          <span>Title</span>
          <textarea v-model="newTitle" maxlength="255" />
        </label>
        <label class="edit-description">
          <span>Description</span>
          <textarea v-model="newDescription" />
        </label>
        <div class="action-buttons">
          <Button dismiss small class="cancel" @click="discardChanges">Cancel</Button>
          <Button dismiss small class="save" @click="saveChanges">Save</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import Button from '@/components/foundation/Button.vue';
import MediaModel from '@/models/media';
import LinkPreviewViewer from './EditPost/MediaViewer/LinkPreviewViewer.vue';
import MediaManager from './EditPost/MediaViewer/MediaManager.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'LinkPreview',
  components: {
    Button,
    Icon,
    MediaManager,
  },
  props: {
    disabled: { type: Boolean, required: false, default: false },
    url: { type: String, required: true },
    image: { type: String, required: false, default: null },
    title: { type: String, required: false, default: null },
    description: { type: String, required: false, default: null },
    canEdit: { type: Boolean, required: false, default: true },
    compactLayout: { type: Boolean, required: false, default: false },
    canBePublishedAt: { type: [Date, Object], required: false, default: null },
  },
  emits: ['text-updated', 'media-updated', 'remove-clicked'],
  data() {
    return {
      bodyHovered: false,
      iconColor: colours.BASIC.WHITE,
      iconHoverColor: colours.ICON.ICON_SECONDARY,
      mediaList: [],
      newTitle: '',
      newDescription: '',
      showLinkPreviewEditTextView: false,
    };
  },
  computed: {
    viewerComponent() {
      return LinkPreviewViewer;
    },
    showEditLinkPreviewIcon() {
      return (
        this.bodyHovered &&
        !this.showLinkPreviewEditTextView &&
        !this.compactLayout &&
        !this.disabled
      );
    },
    hostname() {
      return this.url && new URL(this.url).hostname.replace(/^www\./, '');
    },
  },
  watch: {
    image(newVal) {
      this.initMediaList(newVal);
    },
  },
  created() {
    if (this.image) {
      this.initMediaList(this.image);
    }
  },
  methods: {
    initMediaList(image) {
      // Generate a media object for existing link preview media so that the MediaManager
      // component functions properly
      this.mediaList = [
        new MediaModel({
          type: 'IMAGE',
          image: { sizes: { original: { url: image } } },
        }),
      ];
    },
    editLinkTextPreview() {
      this.showLinkPreviewEditTextView = true;
      this.newTitle = this.title;
      this.newDescription = this.description;
    },
    discardChanges() {
      this.newTitle = '';
      this.newDescription = '';
      this.showLinkPreviewEditTextView = false;
    },
    saveChanges() {
      this.$emit('text-updated', this.newTitle, this.newDescription);
      this.showLinkPreviewEditTextView = false;
    },
    onMediaUploadError(errorMessage) {
      this.mediaUploadError = errorMessage;
    },
    onMediaSelected(media) {
      const formattedMedia = media[0]?.fullMediaObject || media[0];
      this.$emit('media-updated', media[0], formattedMedia?.sizes.originalConverted.url);
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.link-preview {
  margin-top: var(--space-24);
  position: relative;
}

.link-preview.compact {
  display: flex;
  flex-direction: row;
  margin-top: var(--space-12);
  height: 5.5rem;
}

.remove {
  position: absolute;
  width: var(--space-16);
  height: var(--space-16);
  padding: 0;
  top: calc(-1 * var(--space-8));
  right: calc(-1 * var(--space-8));
  background-color: var(--black);
  border: none;
  z-index: 99;
}

.no-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-wrap {
  white-space: nowrap;
}

.media-section {
  position: relative;

  .image {
    overflow: hidden;
    height: 10.935rem;
    border-radius: var(--space-4) var(--space-4) 0 0;
    background-color: var(--background-500);

    :deep(.dz-clickable) {
      height: 100%;
    }
  }
}

.body {
  background-color: var(--background-0);
  border-radius: 0 0 var(--space-4) var(--space-4);
  display: flex;
}

.title {
  font-size: var(--x14);
  line-height: var(--x18);
  font-weight: var(--font-medium);
  color: var(--text-primary);
  padding: var(--space-3) 0;
  text-transform: none;
}

.text-fields {
  position: relative;
  width: 100%;
  background-color: var(--background-0);
  padding: var(--space-16);

  label {
    margin: unset;
  }

  textarea {
    height: 5.375rem;
    resize: vertical;
  }

  .title {
    display: block;
  }
}

.url {
  font-size: var(--x12);
  line-height: var(--x15);
  text-transform: uppercase;
  color: var(--text-secondary);
}

.link-info {
  width: 100%;
}

.description {
  font-size: var(--x12);
  line-height: var(--x15);
  color: var(--text-secondary);
}

.edit-description {
  padding-top: var(--space-10);
}

.action-buttons {
  display: flex;
  justify-content: flex-end;

  .save {
    color: var(--action-500);
  }
}

.edit-link-preview-button {
  position: absolute;
  margin-top: calc(-1 * var(--space-32));
  margin-right: var(--space-8);
  right: 0;

  &:focus {
    outline: none;
  }

  &:hover:not(.disabled) {
    cursor: pointer;
  }
}

.compact {
  .image {
    display: flex;
    height: 5.5rem;
    min-width: 5.5rem;
    width: 5.5rem;
    border-radius: var(--space-4) 0 0 var(--space-4);
    object-fit: cover;
  }

  .body {
    background-color: var(--background-300);
    border-radius: 0 var(--space-4) var(--space-4) 0;
    min-width: 0;
    width: 100%;
    padding: var(--space-12);

    .title {
      line-height: var(--x15);
      font-size: var(--x12);
    }

    .url,
    .description {
      font-size: var(--x10);
    }

    &.no-image {
      border-radius: var(--space-4);
    }
  }
}
</style>
