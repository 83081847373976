<template>
  <div class="composer-wrapper" :class="composerType">
    <RichTextarea
      ref="richTextArea"
      :key="richTextAreaKey"
      v-model="composerHTML"
      capture-enter
      :disabled="editorDisabled"
      :placeholder="placeholderText"
      emoji-picker-size="1.25rem"
      type="text"
      class="composer"
      enable-mentions
      :maxlength="500"
      :mention-details-component="mentionDetailsComponent"
      mention-search-position="editor"
      :on-mention-search="getUserSuggestions"
      :on-mention-lookup="(mentionId) => mentions[mentionId]"
      :on-mention-add="(mention) => (mentions[mention.id] = mention)"
      :autofocus="focusOnMount ? 'end' : null"
      @enter-pressed="submitComposerText"
      @focus="isFocused = true"
      @blur="isFocused = false"
    />
    <div v-if="showActions" class="action-wrapper">
      <button @click.prevent="cancelComment">Cancel</button>
      <button
        :disabled="submitDisabled"
        :class="[{ disabled: submitDisabled }, 'blue']"
        @click.prevent="submitComposerText"
      >
        {{ actionText }}
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import humps from 'humps';
import keyBy from 'lodash/keyBy';
import RichTextarea from '@/app/scheduler/components/EditPost/RichTextarea/RichTextarea.vue';
import {
  encodeForTiptapFromComment,
  decodeFromTiptapForComment,
} from '@/app/scheduler/utils/tiptap';
import CommentMentionDetails from '@/app/scheduler/components/EditPost/RichTextarea/CommentMentionDetails.vue';
import { COMPOSER_TYPES } from '@/config';
import { useCommentingStore } from '@/stores/commenting';
import { useAuthStore } from '@/stores/auth';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'Composer',
  components: {
    RichTextarea,
  },
  props: {
    value: { type: String, default: '' },
    composerType: {
      type: String,
      required: true,
      validator: (value) => {
        const labels = [];
        Object.keys(COMPOSER_TYPES).forEach((key) => {
          labels.push(COMPOSER_TYPES[key].label);
        });
        return Object.values(labels).indexOf(value) > -1;
      },
    },
    editorDisabled: { type: Boolean, default: false },
    submitDisabled: { type: Boolean, default: false },
    focusOnMount: { type: Boolean, default: false },
    clearOnSubmit: { type: Boolean, default: true },
  },
  emits: ['cancel', 'input', 'unsaved-changes', 'submit'],
  data() {
    return {
      isFocused: false,
      composerHTML: '',
      richTextAreaKey: 0,
      mentions: {},
      mentionDetailsComponent: CommentMentionDetails,
    };
  },
  computed: {
    ...mapStores(useCommentingStore, useAuthStore),
    users() {
      return {
        ...(this.commentingStore?.users ?? {}),
        ...keyBy(this.authStore.brandAccessibleUsers, 'id'),
      };
    },
    actionText() {
      return COMPOSER_TYPES[this.composerType].actionText;
    },
    placeholderText() {
      return COMPOSER_TYPES[this.composerType].placeHolderText;
    },
    showActions() {
      if (this.composerType !== COMPOSER_TYPES.comment.label) {
        return true;
      }
      return this.value.length !== 0 || this.isFocused;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.composerHTML = encodeForTiptapFromComment(to, this.users);
      },
    },
    composerHTML(to) {
      this.$emit('input', decodeFromTiptapForComment(to));
    },
  },
  methods: {
    cancelSubmit() {
      this.composerHTML = encodeForTiptapFromComment(
        this.composerType === COMPOSER_TYPES.edit.label ? '' : `${this.value}`,
        this.users,
      );
      this.$emit('unsaved-changes', false);
      this.$emit('cancel');
    },
    cancelComment() {
      this.$emit('cancel');

      // Remounts the RichTextArea to avoid weird emoji behavior
      this.richTextAreaKey += 1;
    },
    submitComposerText() {
      if (!this.submitDisabled) {
        this.$emit('submit');
        if (this.clearOnSubmit) {
          this.composerHTML = '';

          // Remounts the RichTextArea to avoid weird emoji behavior
          this.richTextAreaKey += 1;
        }
      }
    },
    async getUserSuggestions(query) {
      const trimmedQuery = query.trim().toLowerCase();
      if (trimmedQuery) {
        return humps
          .camelizeKeys(this.authStore.brandAccessibleUsers)
          .filter((user) => {
            const firstName = user.firstName?.toLowerCase();
            const lastName = user.lastName?.toLowerCase();
            const email = user.email?.toLowerCase();
            const fullName = `${firstName} ${lastName}`;
            return (
              firstName?.startsWith(trimmedQuery) ||
              lastName?.startsWith(trimmedQuery) ||
              fullName?.startsWith(trimmedQuery) ||
              email?.startsWith(trimmedQuery)
            );
          })
          .map((user) => ({ ...user, text: `@${user.firstName} ${user.lastName}` }))
          .slice(0, 5);
      }
      return [];
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.composer-wrapper {
  margin: 0 var(--space-24);
  display: flex;
  flex-direction: column;

  :deep(.editor) {
    font-size: var(--x12);
    min-height: var(--space-56);
    overflow: auto;
    height: fit-content;
    max-height: 15rem;
    padding-right: var(--space-32);

    p.is-empty:first-child::before {
      font-size: var(--x12);
    }
  }

  :deep(.svg-icon) {
    top: 0.6875rem;
    right: 0.5rem;
  }
}

.composer {
  width: 100%;
}

.action-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: var(--space-8) 0 0;
  line-height: 1rem;

  .blue {
    color: var(--action-500);
  }

  button {
    font-size: var(--x12);
    background: none !important;
    border: none;
    cursor: pointer;
    font-weight: var(--font-medium);
    color: var(--text-primary);
    padding: 0;
    margin: 0;
  }

  button:disabled {
    cursor: default;
  }

  button:last-child {
    margin-left: var(--space-16);
  }
}
</style>
