<template>
  <label :class="['request-comments-form', { disabled: disabled }]">
    <input
      :value="index"
      :checked="checked"
      :disabled="disabled"
      type="radio"
      name="opitions"
      @change="onSelect(index)"
    />
    <div :class="className">
      <ContentRightsComment
        ref="contentRightsComments"
        :key="requestComment.id"
        :initial-request-comment="requestComment"
        :term-links="termLinks"
        :handle="handle"
        :checked="checked"
        @on-remove-buffer="onRemoveBuffer"
        @delete-clicked="onDelete"
      />
    </div>
  </label>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useInstagramAccountStore } from '@/stores/instagram-account';
import ContentRightsComment from '@/app/instagram/components/UGC/ContentRightsComment.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'ContentRightsCommentForm',
  components: {
    ContentRightsComment,
  },
  props: {
    requestComment: { type: Object, default: null },
    index: { type: Number, default: null },
    onSelect: { type: Function, required: true },
    checked: { type: Boolean, default: false },
    onRemoveBuffer: { type: Function, required: true },
    onDelete: { type: Function, required: true },
    termLinks: { type: Array, default: null },
  },
  emits: ['onEdit', 'offEdit'],
  data() {
    return {
      disabled: false,
      isEditing: false,
      className: this.checked ? 'blue-border' : 'grey-border',
    };
  },
  computed: {
    ...mapStores(useInstagramAccountStore),
    handle() {
      return this.instagramAccountStore.brandInstagramAccount?.handle ?? '';
    },
  },
  watch: {
    checked(val) {
      if (!val) {
        this.isEditing = false;
        this.className = 'grey-border';
      } else {
        if (this.$refs.contentRightsComments.isEditing) {
          this.isEditing = true;
        }
        this.className = 'blue-border';
      }
    },
    isEditing(val) {
      if (val) {
        this.$emit('onEdit');
      } else {
        this.$emit('offEdit');
      }
    },
  },
  methods: {
    editRequestComment() {
      if (!this.disabled) {
        this.$refs.contentRightsComments.editRequestComment();
      }
    },
    setIsEditing(val) {
      this.isEditing = val;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.request-comments-form {
  text-transform: none;
  display: flex;
  margin: var(--space-16) var(--space-32) 0 1.375rem;

  &:last-child {
    margin-bottom: var(--space-24);
  }

  align-content: space-between;

  input[type='radio'] {
    align-self: center;
  }

  .grey-border {
    margin: 0 0 0 var(--space-14);
    width: 100%;
    border-radius: var(--round-corner-small);
    border: 1px solid var(--border);
  }

  .blue-border {
    margin: 0 0 0 var(--space-14);
    width: 100%;
    border-radius: var(--round-corner-small);
    border: 1px solid var(--action-500);
  }

  :deep(.content-rights-comments-block) {
    margin: 0 auto;
    padding: var(--space-32) var(--space-32) var(--space-24) var(--space-32);
    display: flex;
    cursor: pointer;
    border-radius: var(--round-corner);
    box-shadow: var(--shadow-3);

    .avatar {
      height: var(--space-40);
      width: var(--space-40);
      border-radius: 50%;
      margin-right: var(--space-12);
      margin-top: var(--space-32);
    }

    .content-rights-comments-editor {
      .comment-content {
        text-align: left;
      }

      .dh-text-area textarea {
        border-bottom-left-radius: 0;
      }

      .hasError textarea {
        border: 1px solid var(--error-500);
      }

      .figure-blue {
        color: var(--action-500);
      }

      .tools {
        display: flex;
        justify-content: flex-start;
        margin-top: var(--space-24);

        .icon-bin-class {
          margin-left: var(--space-24);
        }
      }

      .insert-button {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        height: var(--space-24);
        width: 100%;
        border: 1px solid var(--border);
        background: var(--background-300);
        border-radius: var(--round-corner-small);
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        a {
          flex: 1 1 auto;
          border-right: 1px solid var(--border);

          &:last-of-type {
            border: none;
          }

          button {
            position: relative;
            width: 100%;
            height: 100%;
            border: none;
            background: transparent;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-size: var(--x12);
            color: var(--text-primary);
            font-weight: var(--font-normal);
            cursor: pointer;

            span {
              margin-left: var(--space-8);
            }
          }
        }
      }

      .document-list-dropdown {
        width: 20rem;

        li {
          width: 20rem;
          padding: var(--space-24);

          div {
            text-align: left;

            span {
              width: 18rem;
              display: inline-block;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }

      .right-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: var(--space-16);

        button:first-child {
          margin-right: var(--space-16);
        }
      }

      .form-error {
        text-align: left;
        color: var(--error-500);
        padding: 0.75rem 1rem;
        border-radius: var(--round-corner);
        background: var(--error-100);
        font-size: var(--x13);
        margin-top: var(--space-8);
      }
    }
  }

  :deep(.editing-block) {
    transform: none;
  }
}

.disabled {
  pointer-events: none;
}
</style>
