<script setup>
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';
import { computed } from 'vue';
import Button from '@/components/foundation/Button.vue';
import { ERROR_TYPES } from '@/components/VisionAi/constants';

const props = defineProps({
  errorCode: {
    type: Number,
    default: null,
  },
  messageId: {
    type: Number,
    default: null,
  },
  text: {
    type: String,
    default: '',
  },
  errorMessageLoading: {
    type: Boolean,
    default: null,
  },
});

const emit = defineEmits(['regenerateMessage']);

const canRegenerateMessage = computed(() => {
  const errorType = Object.values(ERROR_TYPES).find(
    (error) => error.statusCode === props.errorCode,
  );
  return !errorType?.disallowMessageRegeneration;
});

function regenerateMessage() {
  emit('regenerateMessage', props.messageId);
}

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        regenerateMessage,
        canRegenerateMessage,
      }
    : {},
);
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div
    class="border-1 flex h-fit items-start gap-2 rounded-2xl border border-solid border-[--error-500] bg-[--error-100] bg-opacity-60 px-4 py-6 text-[14px] text-[--error-700]"
    data-cy="assistant-message"
  >
    <Icon name="alert-triangle" small :color="colours.ERROR.ERROR_500" class="flex-none" />
    <div class="flex flex-col">
      <span>{{ props.text }}</span>
      <div>
        <Button
          v-if="canRegenerateMessage"
          :class="{
            'error-bubble-regenerate text-extra-small-medium text-[14px] text-[--action-500]': true,
            'cursor-not-allowed': props.errorMessageLoading,
            'cursor-pointer': !props.errorMessageLoading,
          }"
          link
          :disabled="props.errorMessageLoading"
          @click="regenerateMessage"
        >
          Try Again
        </Button>
      </div>
    </div>
  </div>
</template>
