<template>
  <div
    v-tooltip="withDisabledTooltip"
    class="checkbox-component"
    :class="{ 'checkbox-disabled': disabled }"
  >
    <label
      :class="[
        'checkbox-wrapper',
        { large, small, disabled },
        { 'no-text-transform': removeLabelTextTransform },
      ]"
    >
      <label
        :class="['checkbox', { indeterminate: useIndeterminateStyle }]"
        :style="checkboxStyles"
      >
        <input
          id="checkboxInput"
          ref="tooltipTarget"
          :checked="isChecked"
          :disabled="disabled"
          type="checkbox"
          :name="name"
          data-cy="checkbox-input-checkbox"
          @change="inputChanged"
          @click="inputClicked"
        />
        <div v-if="!preventEventBubbling" v-tooltip="checkboxTooltip" class="indicator" />
        <div v-else class="indicator" @click.stop.prevent="onToggled" />
      </label>
      <slot>
        <slot name="visual" />
        <span
          v-if="!isCustomLabel"
          ref="label"
          v-tooltip="labelTooltip"
          v-sanitize-html="label"
          :class="['checkbox-label', { 'bold-font': boldFont, 'text-extra-small': smallText }]"
        ></span>
        <span v-if="isRequired" class="required-asterisk">&nbsp;*</span>
        <InfoTooltip v-if="tooltip" :tooltip="tooltip" :small="!small" :xsmall="small" />
      </slot>
    </label>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import { isElementOverflow } from '@/utils';

import { colours } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'CheckBox',
  components: {
    InfoTooltip,
  },
  props: {
    label: { type: String, default: null },
    value: { type: [Boolean, Array], default: false },
    inputValue: { type: String, default: undefined },
    tooltip: { type: String, default: null },
    checkboxTooltip: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    boldFont: { type: Boolean, default: false },
    smallText: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
    isCustomLabel: { type: Boolean, default: false },
    name: { type: String, default: null },
    removeLabelTextTransform: { type: Boolean, default: false },
    customColor: { type: String, default: colours.ACTION.ACTION_500 },
    customBorderColor: { type: String, default: colours.BORDER.BORDER },
    /**
     * If you place this component within another clickable element, the `click` event will bubble up.
     * If you don't want that, set `preventEventBubbling` to true.
     * * */
    preventEventBubbling: { type: Boolean, default: false },
    useIndeterminateStyle: { type: Boolean, default: false },
    passthroughClickEvents: { type: Boolean, default: false },
    supressOverflowTooltip: { type: Boolean, default: false },
    disabledTooltip: { type: String, default: '' },
  },
  emits: ['input', 'click'],
  data() {
    return {
      labelTooltip: null,
    };
  },
  computed: {
    isChecked() {
      return Array.isArray(this.value) ? this.value.includes(this.inputValue) : this.value;
    },
    checkboxStyles() {
      return {
        '--checkbox-color': this.customColor,
        '--checkbox-border-color': this.customBorderColor,
      };
    },
    withDisabledTooltip() {
      return this.disabled && this.disabledTooltip;
    },
  },
  mounted() {
    if (this.$refs.label && isElementOverflow(this.$refs.label) && !this.supressOverflowTooltip) {
      this.labelTooltip = {
        content: this.label,
        theme: 'dh-tooltip-medium',
        delay: { show: 300, hide: 0 },
      };
    }
  },
  methods: {
    onToggled(e) {
      this.$emit('input', e);
    },
    inputChanged(e) {
      if (Array.isArray(this.value)) {
        const currentValue = [...this.value];
        const indexOfValue = currentValue.indexOf(this.inputValue);
        if (indexOfValue > -1) {
          currentValue.splice(indexOfValue, 1);
        } else {
          currentValue.push(this.inputValue);
        }
        this.$emit('input', currentValue);
      } else {
        this.$emit('input', e.target.checked);
      }
    },
    setIndeterminate(val) {
      document.getElementById('checkboxInput').indeterminate = val;
    },
    inputClicked(event) {
      if (this.passthroughClickEvents) {
        event.preventDefault();
        event.stopPropagation();
        setTimeout(() => this.$emit('click'), 0);
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.no-text-transform {
  text-transform: none;
}

.checkbox-component {
  label {
    cursor: pointer;
    margin-bottom: 0;
    text-align: left;
    text-transform: inherit;
  }
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    text-align: left;
    display: inline;
  }

  .info-tooltip {
    margin-bottom: calc(-1 * var(--space-4));
  }

  .required-asterisk {
    margin: 0;
    color: var(--error-500);
  }

  .checkbox-label {
    margin: 0;
  }
}

.checkbox {
  position: relative;
  cursor: pointer;
  text-align: left;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.indicator {
  top: 2px;
  left: 0;
  width: 20px;
  height: 20px;
  background: var(--background-0);
  border-radius: var(--round-corner-small);
  border: 2px solid var(--checkbox-border-color);
}

.checkbox.small .indicator {
  top: 4px;
}

.disabled {
  cursor: default;
  pointer-events: none;
}

.checkbox-disabled {
  cursor: default;
}

/* Check mark */
.indicator::after {
  position: absolute;
  display: none;
  content: '';
}

/* Checkbox tick */
.checkbox .indicator::after {
  top: 4px;
  left: 7px;
  width: 3px;
  height: 8px;
  transform: rotate(45deg);
  border: solid var(--white);
  border-width: 0 2px 2px 0;
  box-sizing: content-box;
}

/* Show check mark */
.checkbox input:checked ~ .indicator::after {
  display: block;
}

.checkbox-wrapper.small {
  .indicator {
    width: 16px;
    height: 16px;
    border-radius: var(--round-corner-small);
    border: 2px solid var(--checkbox-border-color);
  }

  .checkbox .indicator::after {
    top: 2px;
    left: 6px;
  }

  .checkbox input:indeterminate ~ .indicator::after,
  .checkbox.indeterminate input:checked ~ .indicator::after {
    top: 50%;
    left: 50%;
  }
}

.checkbox-wrapper.large {
  .indicator {
    width: 24px;
    height: 24px;
  }

  .checkbox .indicator::after {
    top: 3px;
    left: 9px;
    width: 5px;
    height: 13px;
  }
}

/* Hover and focus states */
.checkbox:hover input ~ .indicator,
.checkbox input:focus ~ .indicator {
  background: var(--background-300);
}

/* Checked state */
.checkbox input:checked {
  ~ .indicator {
    border: 2px solid var(--checkbox-color);
    background: var(--checkbox-color);
  }

  &:disabled ~ .indicator {
    border: 2px solid var(--background-500);
    background: var(--background-500);
  }
}

/* Show horizontal line mark */
.checkbox input:indeterminate ~ .indicator,
.checkbox.indeterminate input:checked ~ .indicator {
  border: 2px solid var(--checkbox-color);
  background: var(--checkbox-color);
}

.checkbox input:indeterminate ~ .indicator::after,
.checkbox.indeterminate input:checked ~ .indicator::after {
  display: block;
  top: 50%;
  left: 50%;
  width: 13px;
  height: 0;
  border: solid var(--white);
  border-width: 2px 0 0;
  transform: translateY(-50%) translateX(-50%);
}

/* Disabled state */

.bold-font {
  font-weight: 500;
}
</style>
