<template>
  <div class="upload-tile">
    <div
      v-if="
        mediaItem.uploadStatus === UPLOAD_STATUS.PENDING ||
        mediaItem.uploadStatus === UPLOAD_STATUS.UPLOADING
      "
      class="loading"
    >
      <RadialProgressBar
        :diameter="100"
        :completed-steps="mediaItem.uploadProgress"
        :total-steps="100"
        start-color="white"
        stop-color="white"
        inner-stroke-color="lightGrey"
      />
    </div>

    <InfiniteLoader v-if="mediaItem.uploadStatus === UPLOAD_STATUS.PROCESSING" tile />

    <div v-if="mediaItem.uploadStatus === UPLOAD_STATUS.FAILED" class="upload-failed">
      <span>Upload failed</span>
      <!--<Button-->
      <!--light-->
      <!--small-->
      <!--@click="handleRetryUpload">-->
      <!--Try Again-->
      <!--</Button>-->
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import RadialProgressBar from '@/vendor/vue-radial-progress/RadialProgressBar.vue';
import { UPLOAD_STATUS } from '@/config';
import { colours } from '@/ux/colours';
import InfiniteLoader from '@/components/InfiniteLoader.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'UploadTile',
  components: {
    InfiniteLoader,
    RadialProgressBar,
  },
  props: { mediaItem: { type: Object, default: null } },
  data() {
    return {
      ...{ UPLOAD_STATUS },
      iconColor: colours.BASIC.WHITE,
    };
  },
  methods: {
    handleRetryUpload() {
      //  TODO - Add functionality to try upload again
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.upload-tile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.upload-failed {
  text-align: center;

  span {
    color: var(--white);
    font-weight: var(--font-medium);
    font-size: var(--x14);
  }

  button {
    margin-top: var(--space-16);
  }
}
</style>
