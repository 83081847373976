<template>
  <TagsBase :tags="customTags" :other-tags="systemTags" @add="openTagInput" @remove="removeTag">
    <template #prependTags>
      <Chip v-if="showInput" v-on-click-outside="handleNewTag" color="var(--background-500)" small>
        <input
          ref="tagInput"
          v-model="newTag"
          maxlength="20"
          class="add-tag-input text-small-medium"
          placeholder="Enter tag"
          @keyup.enter="handleNewTag"
        />
      </Chip>
    </template>
  </TagsBase>
</template>

<script>
import { defineComponent, nextTick } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { vOnClickOutside } from '@vueuse/components';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import TagsBase from '@/app/library/components/MediaPopup/TagsBase.vue';
import Chip from '@/components/foundation/Chip.vue';
import { useMediaDetailStore } from '@/stores/media-detail';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'Tags',
  components: {
    Chip,
    TagsBase,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    customTags: { type: Array, default: () => [] },
    systemTags: { type: Array, default: () => [] },
  },
  data() {
    return {
      customTagList: [],
      showInput: false,
      newTag: null,
    };
  },
  computed: {
    ...mapStores(useMediaDetailStore, useTrackingStore, useTrackingStore),
    ...mapPiniaState(useAuthStore, ['currentBrand']),
  },
  watch: {
    customTags(to) {
      this.customTagList = to;
    },
  },
  mounted() {
    this.customTagList = this.$props.customTags;
  },
  methods: {
    openTagInput() {
      if (this.showInput) {
        return;
      }
      // TODO: add slide transition when input shows
      this.showInput = true;
      nextTick(() => {
        this.$refs.tagInput.focus();
      });
    },
    handleNewTag(e) {
      if (!this.showInput) {
        return;
      }
      if (e && e.target === this.$refs.addButton) {
        this.$refs.tagInput.focus();
        return;
      }
      if (this.newTag?.trim()) {
        this.addTag();
      }
      this.showInput = false;
      this.newTag = null;
    },
    addTag() {
      const tagIndex = this.customTagList.findIndex((item) => item === this.newTag);
      if (tagIndex === -1) {
        this.mediaDetailStore.addMediaTag({
          brandId: this.currentBrand.id,
          mediaId: this.mediaDetailStore.mediaDetail.id,
          name: this.newTag,
        });
        this.customTagList.push({ name: this.newTag });
        this.trackingStore.track('Media Tag Added', {
          tag: this.newTag,
          mediaId: this.mediaDetailStore.mediaDetail.id,
          component: 'Media Popup',
          'Page Opened From': this.$route?.name,
        });
      }
    },
    removeTag(tag) {
      // TODO: add remove confirmation popup
      const tagIndex = this.customTagList.findIndex((item) => item === tag);
      if (tagIndex > -1) {
        this.mediaDetailStore.removeMediaTag({
          brandId: this.currentBrand.id,
          mediaId: this.mediaDetailStore.mediaDetail.id,
          tagId: tag.id,
        });
        this.customTagList[tagIndex].id = null;
        this.trackingStore.track('Media Tag Removed', {
          tag: tag.name,
          mediaId: this.mediaDetailStore.mediaDetail.id,
          component: 'Media Popup',
        });
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
input.add-tag-input {
  border: none;
  background: transparent;
  text-align: left;
  width: var(--space-96);
  height: var(--space-28);
  margin: 0;
}
</style>
