<script setup>
import { computed, onMounted, ref, toRef } from 'vue';
import { storeToRefs } from 'pinia';
import dayjs from 'dayjs';
import ActionPopup from '@/components/ActionPopup.vue';
import { useTiktokStore } from '@/stores/tiktok';
import { usePlatformStore } from '@/stores/platform';
import AccountToggle from '@/components/AccountToggle.vue';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import { getUserTimezone } from '@/utils/timezone';
import SkeletonLoader from '@/components/core/skeleton/SkeletonLoader.vue';
import { useNotificationStore } from '@/stores/notification';
import { useTikTokAds } from '@/app/settings/composables/tiktokAds';

const tiktokStore = useTiktokStore();
const platformStore = usePlatformStore();
const notificationStore = useNotificationStore();

const { adAccountsFromApi: adAccounts } = storeToRefs(tiktokStore);
const initialIsMonitoredMap = ref();

const props = defineProps({
  brand: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(['close']);

const brand = toRef(props, 'brand');
const { brandId, platformConnection, canEnableAccounts, connectTikTokAdsToken } =
  useTikTokAds(brand);

// computed
const monitoredAccountsCount = computed(() => {
  return adAccounts.value.filter((adAccount) => adAccount.isMonitored).length;
});
const areAccountsLoading = computed(() => {
  return tiktokStore.pending.adAccountsFromApi;
});
const showAccounts = computed(() => {
  return adAccounts.value?.length > 0 || areAccountsLoading.value;
});
const popupTitle = computed(() => {
  if (showAccounts.value) {
    return canEnableAccounts.value ? 'Enable TikTok Ads' : 'TikTok Ad Accounts';
  }
  return 'No Ad Accounts found';
});
const popupMessage = computed(() => {
  if (showAccounts.value) {
    return canEnableAccounts.value
      ? 'Select the ad accounts you want to associate with this brand. At least one ad account must be enabled.'
      : 'Ads insights are being pulled from any enabled accounts';
  }
  return '';
});
const popupSize = computed(() => {
  return showAccounts.value ? 'medium' : 'small';
});
const popupButtonLabel = computed(() => {
  return showAccounts.value ? 'Done' : 'OK';
});

// actions
const closeConnectionPopup = () => {
  platformStore.closeConnectionPopup();
  emit('close');
};
const wasAccountMonitoredInitially = (account) => {
  if (initialIsMonitoredMap.value) {
    return initialIsMonitoredMap.value[account.sourceAdvertiserId];
  }
  return false;
};
const disconnectTikTokAds = async () => {
  try {
    const connectionId = platformStore.getPlatformConnection(PLATFORM_CONNECTION.TIKTOK_ADS, {
      brandId: brandId.value,
    }).id;
    await platformStore.deletePlatformConnection({
      platform: PLATFORM_CONNECTION.TIKTOK_ADS,
      connectionId,
      brandId: brandId.value,
    });
  } catch {
    notificationStore.setToast({
      message: 'Something went wrong, please try again.',
      type: 'error',
    });
  }
};
const exitPopupWithNothingToggled = () => {
  if (canEnableAccounts.value) {
    disconnectTikTokAds();
  }
  closeConnectionPopup();
};
const exit = async () => {
  const accountsMonitoredInitially = adAccounts.value.filter((adAccount) =>
    wasAccountMonitoredInitially(adAccount),
  );

  if (canEnableAccounts.value && showAccounts.value && accountsMonitoredInitially.length === 0) {
    await notificationStore.confirm(
      'Exit Connection Process?',
      'If you exit now, your account won’t be connected',
      {
        confirmAlias: 'Exit',
        onConfirm: exitPopupWithNothingToggled,
      },
    );
  } else {
    closeConnectionPopup();
  }
};
const saveChanges = async () => {
  if (canEnableAccounts.value) {
    await Promise.all(
      adAccounts.value.map(async (adAccount) => {
        if (adAccount.isMonitored === wasAccountMonitoredInitially(adAccount)) return;
        await tiktokStore.updateAdAccount({
          brandId: brandId.value,
          sourceAdvertiserId: adAccount.sourceAdvertiserId,
          isMonitored: adAccount.isMonitored,
          connectTiktokAdsToken: connectTikTokAdsToken.value,
        });
      }),
    );
  }
  platformStore.getTikTokAdAccount(brandId.value);
  closeConnectionPopup();
};
const changeAccountMonitoredStatus = async (account, value) => {
  const updatedAccount = adAccounts.value.find(
    (element) => element.sourceAdvertiserId === account.sourceAdvertiserId,
  );
  updatedAccount.isMonitored = value;
};
const formatConnectedAtDate = (account) => {
  const connectedAtDate = account.connectedAt;
  if (!connectedAtDate) {
    return '';
  }

  const date = dayjs.utc(connectedAtDate).tz(getUserTimezone());
  return date.format(`DD/MM/YYYY`);
};
const formatTooltip = (account) => {
  if (!wasAccountMonitoredInitially(account)) {
    return '';
  }
  if (platformConnection?.value?.connected_by_name) {
    return `${account.name}<br>Connected by ${
      platformConnection.value.connected_by_name
    }<br>${formatConnectedAtDate(account)}`;
  }
  return `${account.name}<br>${formatConnectedAtDate(account)}`;
};

// hooks
onMounted(async () => {
  await tiktokStore.fetchAdAccountsFromApi({
    brandId: brandId.value,
    connectTiktokAdsToken: connectTikTokAdsToken.value,
  });
  initialIsMonitoredMap.value = Object.assign(
    {},
    ...adAccounts.value.map((account) => ({
      [account.sourceAdvertiserId]: account.isMonitored,
    })),
  );
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <ActionPopup
    :title="popupTitle"
    :message="popupMessage"
    :button-label="popupButtonLabel"
    :button-disabled="canEnableAccounts && showAccounts && monitoredAccountsCount === 0"
    :type="popupSize"
    :loading="areAccountsLoading"
    class="ads-accounts-popup"
    @submit="saveChanges"
    @cancel="exit"
  >
    <template #actionPopupContent>
      <SkeletonLoader :loading="areAccountsLoading" type="adsAccountsPopupSkeleton">
        <ul v-if="showAccounts" class="brand-list">
          <li v-for="account in adAccounts" :key="account.sourceAdvertiserId" class="ads-accounts">
            <AccountToggle
              :key="account.sourceAdvertiserId"
              :account="account"
              :label="account.name"
              :disabled="
                !canEnableAccounts || (account.isMonitored && monitoredAccountsCount === 1)
              "
              :toggled="account.isMonitored"
              :wrapper-tooltip="{ content: formatTooltip(account), html: true }"
              @change="changeAccountMonitoredStatus"
            />
          </li>
        </ul>
        <div v-else class="empty">
          <p class="text-small">
            There are no active ad accounts associated with your TikTok Ad account.
          </p>
        </div>
      </SkeletonLoader>
    </template>
  </ActionPopup>
</template>

<style lang="postcss" scoped>
.ads-accounts-popup {
  .brand-list > li:nth-child(odd) {
    background-color: var(--background-300);
    border-radius: var(--space-4);
  }

  .empty {
    text-align: center;
    margin-bottom: var(--space-48);
    color: var(--text-secondary);

    img {
      margin: var(--space-32) auto var(--space-8);
    }

    h4 {
      text-transform: none;
      margin-bottom: var(--space-16);
      font-size: var(--x22);
    }

    p {
      font-size: var(--x16);
      font-weight: var(--font-normal);
    }
  }
}
</style>
