<template>
  <div class="tag-counter-container">
    <div class="tag-counter">
      <Icon name="user" color="white" xxsmall />
      <p>{{ numUserTags }}</p>
    </div>
    <div v-if="hasAccessToShoppingTags" class="tag-counter">
      <Icon name="tag" color="white" xxsmall />
      <p>{{ numShoppingTags }}</p>
    </div>
    <div v-if="hasCollaboratorFeatureFlag" class="tag-counter">
      <Icon name="paperplane" color="white" xxsmall />
      <p>{{ numberOfCollaborators }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import Icon from '@/components/foundation/Icon.vue';
import { useSchedulerStore } from '@/stores/scheduler';
import { useInstagramShoppingTaggerStore } from '@/stores/instagram-shopping-tagger';
import { useInstagramUserTaggerStore } from '@/stores/instagram-user-tagger';
import { useFlagStore } from '@/stores/flag';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'TagCounter',
  components: { Icon },
  props: {
    activeMediaOnly: { type: Boolean, default: false },
  },
  computed: {
    ...mapStores(
      useSchedulerStore,
      useInstagramShoppingTaggerStore,
      useInstagramUserTaggerStore,
      useFlagStore,
    ),
    hasAccessToShoppingTags() {
      return this.instagramShoppingTaggerStore.shoppingTagsEligible;
    },
    numUserTags() {
      if (this.activeMediaOnly) {
        return this.instagramUserTaggerStore.activeMediaTaggedUsers.length;
      }
      return this.instagramUserTaggerStore.taggedUsers.length;
    },
    numShoppingTags() {
      if (this.activeMediaOnly) {
        return this.instagramShoppingTaggerStore.activeMediaTaggedProducts.length;
      }
      return this.instagramShoppingTaggerStore.taggedProducts.length;
    },
    numberOfCollaborators() {
      return this.instagramUserTaggerStore.collaborators.length;
    },
    hasCollaboratorFeatureFlag() {
      return this.flagStore.ready && this.flagStore.flags.instagramCollabPosts;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.tag-counter-container {
  display: flex;
  padding: 0 var(--space-6);
  gap: var(--space-8);
  background: var(--black-alpha-70);
  border-radius: var(--round-corner-extra-small);
  position: absolute;
  left: var(--space-8);
  bottom: var(--space-8);
  pointer-events: none;
  z-index: 10;

  .tag-counter {
    display: flex;
    align-items: center;
    gap: var(--space-4);
    color: var(--white);
    font-size: var(--x11);
  }
}
</style>
