import dayjs from 'dayjs';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import { computed } from 'vue';
import { CUSTOMER_PLAN } from '@/models/auth/customer-plan.enum';

export function useTrialStates() {
  function trialActiveOrExpiredForTwoDays(trial) {
    const endDate = dayjs(trial.trialEndDate).add(2, 'days');
    const today = dayjs();
    return endDate >= today;
  }

  function getTrialDaysLeft(trial) {
    const trialEndDate = dayjs(trial?.trialEndDate);
    const today = dayjs();
    return Math.ceil(trialEndDate.diff(today, 'days', true));
  }

  const customerJourneyStore = useCustomerJourneyStore();

  const showPlanTrial = computed(() => {
    return (
      customerJourneyStore.additionalPlanTrial?.trialEndDate &&
      trialActiveOrExpiredForTwoDays(customerJourneyStore.additionalPlanTrial)
    );
  });

  const showPremiumAnalyticsTrial = computed(() => {
    return (
      customerJourneyStore.additionalPremiumAnalyticsTrial?.trialEndDate &&
      trialActiveOrExpiredForTwoDays(customerJourneyStore.additionalPremiumAnalyticsTrial)
    );
  });

  const showSocialListeningTrial = computed(() => {
    return (
      customerJourneyStore.socialListeningTrial?.trialEndDate &&
      trialActiveOrExpiredForTwoDays(customerJourneyStore.socialListeningTrial)
    );
  });

  const planTrialType = computed(() => {
    return customerJourneyStore.additionalPlanTrial?.dhProduct?.name;
  });

  const planTrialDaysLeft = computed(() => {
    return getTrialDaysLeft(customerJourneyStore.additionalPlanTrial);
  });

  const premiumAnalyticsTrialDaysLeft = computed(() => {
    return getTrialDaysLeft(customerJourneyStore.additionalPremiumAnalyticsTrial);
  });

  const socialListeningTrialDaysLeft = computed(() => {
    return getTrialDaysLeft(customerJourneyStore.socialListeningTrial);
  });

  const basePlanTrialDaysLeft = computed(() => {
    return getTrialDaysLeft(customerJourneyStore.basePlanTrial);
  });

  function getBrandTrialDaysLeft() {
    const earliestExpiration =
      planTrialDaysLeft.value > basePlanTrialDaysLeft.value &&
      customerJourneyStore.basePlanTrial?.dhProduct?.name === CUSTOMER_PLAN.GROW.name
        ? basePlanTrialDaysLeft.value
        : planTrialDaysLeft.value;
    return showPlanTrial.value && earliestExpiration > 0
      ? earliestExpiration
      : premiumAnalyticsTrialDaysLeft.value;
  }

  function getBrandTrialProductCopy() {
    if (planTrialDaysLeft.value > 0 && premiumAnalyticsTrialDaysLeft.value <= 0) {
      return planTrialType.value;
    }
    if (premiumAnalyticsTrialDaysLeft.value > 0 && planTrialDaysLeft.value <= 0) {
      return 'Premium Analytics';
    }
    return `${showPlanTrial.value ? planTrialType.value : ''}${showPlanTrial.value && showPremiumAnalyticsTrial.value ? ' + ' : ''}${showPremiumAnalyticsTrial.value ? 'Premium Analytics' : ''}`;
  }

  const activeTrialsInfo = computed(() => {
    const activeTrials = [];
    if (planTrialDaysLeft.value > 0 || premiumAnalyticsTrialDaysLeft.value > 0) {
      activeTrials.push({
        product: getBrandTrialProductCopy(),
        daysLeft: getBrandTrialDaysLeft(),
      });
    }
    if (socialListeningTrialDaysLeft.value > 0) {
      activeTrials.push({
        product: 'Social Listening',
        daysLeft: socialListeningTrialDaysLeft.value,
      });
    }
    return activeTrials;
  });

  const expiredTrialsInfo = computed(() => {
    const expiredTrials = [];
    if (
      ((showPlanTrial.value && planTrialDaysLeft.value <= 0) ||
        (showPremiumAnalyticsTrial.value && premiumAnalyticsTrialDaysLeft.value <= 0)) &&
      !(planTrialDaysLeft.value > 0 || premiumAnalyticsTrialDaysLeft.value > 0)
    ) {
      expiredTrials.push(getBrandTrialProductCopy());
    }
    if (showSocialListeningTrial.value && socialListeningTrialDaysLeft.value <= 0) {
      expiredTrials.push('Social Listening');
    }
    return expiredTrials;
  });

  const expansionTrialDaysLeft = computed(() => {
    return (trialInfo) =>
      trialInfo.daysLeft > 1
        ? `<span>${trialInfo.daysLeft}</span> days remaining in your <span class="font-medium">${trialInfo.product}</span> trial.`
        : `This is the last day of your <span class="font-medium">${trialInfo.product}</span> trial.`;
  });

  return {
    socialListeningTrialDaysLeft,
    basePlanTrialDaysLeft,
    activeTrialsInfo,
    expiredTrialsInfo,
    expansionTrialDaysLeft,
    trialActiveOrExpiredForTwoDays,
    getTrialDaysLeft,
  };
}
