import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const camelCaseAxios = createAxiosInstance(env.threadsApiUrl, {
  camelizeKeys: true,
});

export async function getThreadsProfile({ brandId }, axiosConfig = {}) {
  return camelCaseAxios.get(`/brands/${brandId}/profile`, axiosConfig);
}

export async function getThreadsProfileAllBrands({ brandIds }, axiosConfig = {}) {
  return camelCaseAxios.put(`/brands/profiles`, { brand_ids: brandIds }, axiosConfig);
}
