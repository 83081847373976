import { FacebookAPI } from '@/apis';
import * as AuthAPI from '@/apis/auth';
import { handleCancelError, refreshCancelToken } from '@/apis/axios.utils';
import { getFacebookPage } from '@/apis/facebook';
import { getInstagramAccount, getInstagramAccountAllBrands } from '@/apis/instagram';
import LibraryAPI from '@/apis/library';
import { getLinkedinAccount, getLinkedinAccountAllBrands } from '@/apis/linkedin';
import { getPinterestAccount } from '@/apis/pinterest';
import * as TikTokAPI from '@/apis/tiktok';
import { getTwitterAccount } from '@/apis/twitter';
import { getYouTubeChannel } from '@/apis/youtube';
import { BRAND, USER } from '@/models/auth/permissions.enum';
import { CHANNELS } from '@/models/dashboards/channels.enum';
import {
  BRAND_PLATFORM_PERMISSIONS,
  PLATFORM_CONNECTION,
  PLATFORM_CONNECTION_NAMES,
  PLATFORM_CONNECTION_STATUS,
  USER_PLATFORM_PERMISSIONS,
  extractPlatformConnectionScope,
  extractPlatformConnectionType,
  isSamePlatformConnection,
} from '@/models/platform/platform-connection.enum';
import { useAuthStore } from '@/stores/auth';
import { useFacebookStore } from '@/stores/facebook';
import { useFlagStore } from '@/stores/flag';
import { useIdentityStore } from '@/stores/identity';
import { useTrackingStore } from '@/stores/tracking';
import { useTwitterStore } from '@/stores/twitter';
import { browserStorageGetItem, browserStorageSetItem } from '@/utils/browserStorage';
import { logger } from '@/utils/logger';
import { getThreadsProfile, getThreadsProfileAllBrands } from '@/apis/threads';
import dayjs from 'dayjs';
import camelCase from 'lodash/camelCase';
import chunk from 'lodash/chunk';
import mapValues from 'lodash/mapValues';
import { defineStore } from 'pinia';
import { watch } from 'vue';

function bypassConnectionCheck() {
  const flagStore = useFlagStore();
  return flagStore.ready && flagStore.flags.bypassConnectionCheck;
}

function formatLinkedinConnectionAccounts(accounts) {
  return accounts.map((account) => ({
    ...account,
    isValid: account?.role === 'ADMINISTRATOR',
    name: account?.name || account?.vanityName,
  }));
}

function formatConnectionAccounts(platformType, accounts) {
  switch (platformType) {
    case PLATFORM_CONNECTION.LINKEDIN.value:
      return formatLinkedinConnectionAccounts(accounts);
    default:
      return accounts;
  }
}

export const PLATFORM_COPY = {
  noHandleTooltip: `Your handle is needed in our system to connect. Please contact us to update this.`,
  noRequiredPermissionTooltip: {
    facebook_ads: `In order to connect a Facebook ad account your Instagram account must be connected to Dash Hudson.`,
  },
};

const PLATFORM_TO_HANDLE_GETTER = {
  [PLATFORM_CONNECTION.FACEBOOK.value]: (account) => account?.handle,
  [PLATFORM_CONNECTION.FACEBOOK.sandbox]: (account) => account?.handle,
  [PLATFORM_CONNECTION.FACEBOOK_ADS.value]: (account) => account?.handle,
  [PLATFORM_CONNECTION.FACEBOOK_PAGE.value]: (account) => account?.name,
  [PLATFORM_CONNECTION.FACEBOOK_PAGE.sandbox]: (account) => account?.name,
  [PLATFORM_CONNECTION.FACEBOOK_ANALYTICS.value]: (account) => account?.name,
  [PLATFORM_CONNECTION.FACEBOOK_ANALYTICS.sandbox]: (account) => account?.name,
  [PLATFORM_CONNECTION.INSTAGRAM_PAGE.value]: (account) => account?.handle,
  [PLATFORM_CONNECTION.INSTAGRAM_PAGE.sandbox]: (account) => account?.handle,
  [PLATFORM_CONNECTION.INSTAGRAM_INFLUENCER.value]: (account) => account?.handle,
  [PLATFORM_CONNECTION.PINTEREST.value]: (account) => account?.pinterestUsername,
  [PLATFORM_CONNECTION.TWITTER.value]: (account) => account?.handle,
  [PLATFORM_CONNECTION.TIKTOK.value]: (account) => account?.tiktokUsername,
  [PLATFORM_CONNECTION.YOUTUBE.value]: (account) => account?.title,
  [PLATFORM_CONNECTION.GOOGLE_ANALYTICS.value]: (account) => account?.[0]?.name,
  [PLATFORM_CONNECTION.LINKEDIN.value]: (account) => account?.name,
  [PLATFORM_CONNECTION.THREADS.value]: (account) => account?.handle,
};

export const PLATFORM_CONNECTION_REQUIRED = [
  PLATFORM_CONNECTION.FACEBOOK.value,
  PLATFORM_CONNECTION.FACEBOOK_ANALYTICS.value,
  PLATFORM_CONNECTION.LINKEDIN.value,
  PLATFORM_CONNECTION.PINTEREST.value,
  PLATFORM_CONNECTION.TWITTER.value,
  PLATFORM_CONNECTION.TIKTOK.value,
  PLATFORM_CONNECTION.YOUTUBE.value,
  PLATFORM_CONNECTION.THREADS.value,
];

export const PLATFORM_MIXPANEL = {
  STATUS_UPDATE: 'Platform Connection Status Update',
  CALLBACK_SUCCESS: 'Platform Connection Callback Success',
  POPUP: 'Platform Connection Popup',
  CLICKED: 'Platform Connection Clicked',
  DISCONNECTED: 'Platform Disconnected',
};

async function loadAccount(loadAccountFunc, brandId) {
  let account = null;
  try {
    const response = await loadAccountFunc({ brandId });
    account = response?.data;
  } catch (error) {
    if (error?.response?.status === 404) {
      account = null;
    } else {
      throw error;
    }
  }
  return account;
}

export const usePlatformStore = defineStore('platform', {
  state: () => ({
    accounts: {
      [PLATFORM_CONNECTION.FACEBOOK.value]: {},
      [PLATFORM_CONNECTION.FACEBOOK_ADS.value]: {},
      [PLATFORM_CONNECTION.FACEBOOK_PAGE.value]: {},
      [PLATFORM_CONNECTION.FACEBOOK_ANALYTICS.value]: {},
      [PLATFORM_CONNECTION.INSTAGRAM_PAGE.value]: {},
      [PLATFORM_CONNECTION.INSTAGRAM_INFLUENCER.value]: {},
      [PLATFORM_CONNECTION.LINKEDIN.value]: {},
      [PLATFORM_CONNECTION.PINTEREST.value]: {},
      [PLATFORM_CONNECTION.TWITTER.value]: {},
      [PLATFORM_CONNECTION.TIKTOK.value]: {},
      [PLATFORM_CONNECTION.TIKTOK_ADS.value]: {},
      [PLATFORM_CONNECTION.TIKTOK_CREATOR_MARKETPLACE.value]: {},
      [PLATFORM_CONNECTION.TIKTOK_CREATOR.value]: {},
      [PLATFORM_CONNECTION.YOUTUBE.value]: {},
      [PLATFORM_CONNECTION.GOOGLE_ANALYTICS.value]: {},
      [PLATFORM_CONNECTION.THREADS.value]: {},
    },
    connectionPopup: {
      show: false,
      platform: null,
      title: null,
      message: null,
      showTerms: true,
      onCancel: null,
      brand: null,
      useSandbox: null,
      bypassCheckConnection: false,
    },
    error: {
      platformConnection: null,
    },
    pending: {
      platformConnections: false,
      accounts: false,
      connectionAccounts: false,
      createPlatformConnection: false,
    },
    platformConnectionsInitialized: false,
    platformConnections: null,
    platformConnectionsCancelToken: null,
    platformConnectionsByBrandToken: null,
    connectionAccounts: [],
    showImportingPopup: false,
    pinterestAccount: null,
    facebookPage: null,
    showTrialStartedPopup: false,
    connectedMetaAdsIsMonitored: null,
    refreshAllAccounts: false,
  }),
  getters: {
    hasOneValidPlatformConnection(state) {
      if (state.platformConnectionsInitialized === false) {
        return null;
      }

      const list = Array.isArray(state.platformConnections) ? state.platformConnections : [];
      return list.some(
        (platformConnection) => platformConnection.status === PLATFORM_CONNECTION_STATUS.CONNECTED,
      );
    },
    hasOneRequiredBrandPlatformConnection(state) {
      if (state.platformConnectionsInitialized === false) {
        return null;
      }
      const authStore = useAuthStore();
      const brandIdToUse = authStore.currentBrand?.id;
      const list = PLATFORM_CONNECTION_REQUIRED.map(
        (platform) => state.platformConnectionsMap?.[platform]?.[brandIdToUse],
      );
      return list.some((connection) => !!connection);
    },
    hasOneValidRequiredBrandPlatformConnection(state) {
      if (state.platformConnectionsInitialized === false) {
        return null;
      }
      const authStore = useAuthStore();
      const brandIdToUse = authStore.currentBrand?.id;
      const list = PLATFORM_CONNECTION_REQUIRED.map(
        (platform) =>
          state.platformConnectionsMap?.[platform]?.[brandIdToUse]?.status ===
          PLATFORM_CONNECTION_STATUS.CONNECTED,
      );
      return list.some((connection) => !!connection);
    },
    platformConnectionsMap: (state) => {
      const list = Array.isArray(state.platformConnections) ? state.platformConnections : [];
      return list.reduce((acc, pc) => {
        if (!acc[pc.platform_type]) {
          acc[pc.platform_type] = {};
        }
        acc[pc.platform_type][pc.brand_id] = pc;
        return acc;
      }, {});
    },
    hasPlatformConnections: (state) => {
      return !!state.platformConnections;
    },
    getPlatformConnection: (state) => {
      return (platform, { brandId, useSandbox = false } = {}) => {
        const authStore = useAuthStore();
        const platformType = extractPlatformConnectionType(platform, useSandbox);
        const brandIdToUse = brandId ?? authStore.currentBrand?.id;
        return state.platformConnectionsMap?.[platformType]?.[brandIdToUse];
      };
    },
    getAccount: (state) => {
      return (platform, { brandId } = {}) => {
        const authStore = useAuthStore();
        const platformType = extractPlatformConnectionType(platform);
        const brandIdToUse = brandId ?? authStore.currentBrand?.id;
        return state.accounts?.[platformType]?.[brandIdToUse];
      };
    },
    platformBrandHandleMap: (state) =>
      mapValues(state.accounts, (brandsMap, platformType) => {
        const handleGetter = PLATFORM_TO_HANDLE_GETTER[platformType];
        return mapValues(brandsMap, (account) => handleGetter?.(account) ?? null);
      }),
    hasFacebookConnection() {
      if (!this.hasPlatformConnections) {
        return null;
      }
      return this.isPlatformConnected(PLATFORM_CONNECTION.FACEBOOK);
    },
  },
  actions: {
    init() {
      const authStore = useAuthStore();
      watch(
        () => authStore.identity,
        (identity) => {
          if (identity) {
            this.getPlatformConnections();
          } else {
            this.platformConnections = [];
            this.platformConnectionsInitialized = false;
          }
        },
        { immediate: true },
      );
      watch(
        () => authStore.currentBrand,
        () => {
          this.pinterestAccount = null;
          this.facebookPage = null;

          if (authStore.isLoggedIn) {
            this.refreshAccounts();
          }
        },
        { immediate: true },
      );
    },
    logMixpanelEvent(event, platform, otherProperties = {}, useSandbox = false) {
      const validEvent = Object.values(PLATFORM_MIXPANEL).some(
        (validEventName) => validEventName === event,
      );
      if (!validEvent) {
        return;
      }
      const authStore = useAuthStore();
      const brandId = otherProperties?.brandId ?? authStore.currentBrand?.id;
      const platformType = extractPlatformConnectionType(platform, useSandbox);

      const account = this.platformBrandHandleMap?.[platformType]?.[brandId] ?? null;

      const connection = this.getPlatformConnection(platform, { brandId });
      const status = connection?.status;

      const trackingStore = useTrackingStore();
      trackingStore.track(event, {
        status,
        platformType,
        brandId,
        account,
        influencerConnection: !!otherProperties.invitedByBrandId,
        ...otherProperties,
      });
    },
    async getPlatformConnections() {
      const authStore = useAuthStore();
      if (!authStore?.isLoggedIn) {
        return undefined;
      }

      // When the user has no active brands
      if (authStore.identityBrandIds.length < 1) {
        return undefined;
      }

      this.pending.platformConnections = true;
      const cancelToken = refreshCancelToken(this, 'platformConnectionsCancelToken');
      let payload;
      try {
        // For users with hundreds of brands, split the IDs into chunks and make multiple requests
        // With a chunk size of 100, few users should ever send multiple requests.
        const brandIdArray = authStore.identityBrandIds;
        const responses = await Promise.all(
          chunk(brandIdArray, 100).map((brandIds) =>
            AuthAPI.getPlatformConnections({ brandIds }, { cancelToken: cancelToken.token }),
          ),
        );

        payload = [].concat(...responses.map((response) => response.data));
        this.platformConnections = payload;
        this.platformConnectionsInitialized = true;
      } catch (error) {
        this.platformConnections = null;
        if (error.response?.status === 403) {
          logger.error(
            `Get platform connections returned authentication error: ${error.response?.data?.description}`,
            {},
            error,
          );
        } else {
          handleCancelError(error);
        }
      } finally {
        this.pending.platformConnections = false;
      }
      return payload;
    },
    async getPlatformConnectionsByBrandToken({ brandId, brandToken, platformType }) {
      const response = await AuthAPI.getPlatformConnections({
        brandId,
        brandToken,
        platformType,
      });
      const payload = response?.data;
      this.platformConnectionsByBrandToken = payload;
      return payload;
    },
    isPlatformConnected(platform, { brandId, scopes = [], useSandbox = false } = {}) {
      if (bypassConnectionCheck()) {
        return true;
      }

      const authStore = useAuthStore();
      const brandIdToUse = brandId ?? authStore.currentBrand?.id;

      let platformType = extractPlatformConnectionType(platform, useSandbox);
      if (platformType === 'pinterest') {
        const flagStore = useFlagStore();
        if (flagStore.ready && flagStore.flags['pinterest-migration'])
          platformType = 'pinterest_v5';
      }

      const connection = this.platformConnectionsMap[platformType]?.[brandIdToUse];
      const hasRequiredScopes = this.canAccessScope(platform, scopes, {
        brandId: brandIdToUse,
        useSandbox,
      });
      return connection?.status === PLATFORM_CONNECTION_STATUS.CONNECTED && hasRequiredScopes;
    },
    isPlatformNeverConnected(platform, { brandId, useSandbox = false } = {}) {
      if (bypassConnectionCheck()) {
        return false;
      }

      const authStore = useAuthStore();
      const brandIdToUse = brandId ?? authStore.currentBrand?.id;

      const platformType = extractPlatformConnectionType(platform, useSandbox);
      return !(
        platformType in this.platformConnectionsMap &&
        brandIdToUse in this.platformConnectionsMap[platformType]
      );
    },
    canAccessScope(platform, scopesToCheck = [], { brandId, useSandbox = false } = {}) {
      const authStore = useAuthStore();
      const brandIdToUse = brandId ?? authStore.currentBrand?.id;
      const platformType = extractPlatformConnectionType(platform, useSandbox);
      const presentScopes =
        this.platformConnectionsMap?.[platformType]?.[brandIdToUse]?.scopes?.split?.(',') ?? [];
      return (scopesToCheck ?? []).every((scopeToCheck) =>
        presentScopes.includes(extractPlatformConnectionScope(scopeToCheck)),
      );
    },
    hasCompleteTwitterConnection(twitterAccount) {
      if (bypassConnectionCheck()) {
        return true;
      }
      // TOOD: When twitterAccount gets moved to a pinia store, we won't need to pass it in.
      return (
        !!twitterAccount?.ads_account_id &&
        this.isPlatformConnected(PLATFORM_CONNECTION.TWITTER, {
          brandId: twitterAccount.brand_id,
          scopes: [PLATFORM_CONNECTION.TWITTER.SCOPES.ADS_API_ACCESS],
        })
      );
    },
    async getInstagramAccount(brandId) {
      const account = await loadAccount(getInstagramAccount, brandId);
      this.accounts[PLATFORM_CONNECTION.FACEBOOK.value] = { [brandId]: account };
      this.accounts[PLATFORM_CONNECTION.FACEBOOK.sandbox] = { [brandId]: account };
      this.accounts[PLATFORM_CONNECTION.INSTAGRAM_PAGE.value] = { [brandId]: account };
      this.accounts[PLATFORM_CONNECTION.INSTAGRAM_PAGE.sandbox] = { [brandId]: account };
      this.accounts[PLATFORM_CONNECTION.INSTAGRAM_INFLUENCER.value] = { [brandId]: account };
      return account;
    },
    async getFacebookAdsAccount(brandId) {
      const account = await loadAccount(FacebookAPI.getAccountsFromApi, brandId);
      if (brandId in this.accounts[PLATFORM_CONNECTION.FACEBOOK_ADS.value]) {
        this.accounts[PLATFORM_CONNECTION.FACEBOOK_ADS.value][brandId] =
          account?.length !== 0 ? account : null;
      } else {
        this.accounts[PLATFORM_CONNECTION.FACEBOOK_ADS.value] = {
          [brandId]: account?.length !== 0 ? account : null,
          ...this.accounts[PLATFORM_CONNECTION.FACEBOOK_ADS.value],
        };
      }
    },
    async getTikTokAdAccount(brandId) {
      const account = await loadAccount(TikTokAPI.getAdAccounts, brandId);
      if (brandId in this.accounts[PLATFORM_CONNECTION.TIKTOK_ADS.value]) {
        this.accounts[PLATFORM_CONNECTION.TIKTOK_ADS.value][brandId] =
          account?.length !== 0 ? account : null;
      } else {
        this.accounts[PLATFORM_CONNECTION.TIKTOK_ADS.value] = {
          [brandId]: account?.length !== 0 ? account : null,
          ...this.accounts[PLATFORM_CONNECTION.TIKTOK_ADS.value],
        };
      }
    },
    async getTikTokCreatorMarketplaceAccounts(brandId) {
      const response = await TikTokAPI.getBrandTTCMAccounts({ brandId });
      const accounts = response?.data?.data || [];

      if (brandId in this.accounts[PLATFORM_CONNECTION.TIKTOK_CREATOR_MARKETPLACE.value]) {
        this.accounts[PLATFORM_CONNECTION.TIKTOK_CREATOR_MARKETPLACE.value][brandId] =
          accounts?.length !== 0 ? accounts : null;
      } else {
        this.accounts[PLATFORM_CONNECTION.TIKTOK_CREATOR_MARKETPLACE.value] = {
          [brandId]: accounts?.length !== 0 ? accounts : null,
          ...this.accounts[PLATFORM_CONNECTION.TIKTOK_CREATOR_MARKETPLACE.value],
        };
      }
    },
    async getFacebookPageAllBrands(brandIds) {
      const identityStore = useIdentityStore();
      const response = await FacebookAPI.getAllFacebookPages(brandIds);
      const brandAccounts = response?.data;
      Object.entries(brandAccounts).forEach(([brandId, accounts]) => {
        brandAccounts[brandId] = accounts?.length !== 0 ? accounts : null;
      });
      this.facebookPage = brandAccounts[identityStore.currentBrand?.id];
      this.accounts[PLATFORM_CONNECTION.FACEBOOK_ANALYTICS.value] = {
        ...this.accounts[PLATFORM_CONNECTION.FACEBOOK_ANALYTICS.value],
        ...brandAccounts,
      };
      this.accounts[PLATFORM_CONNECTION.FACEBOOK_ANALYTICS.sandbox] = {
        ...this.accounts[PLATFORM_CONNECTION.FACEBOOK_ANALYTICS.sandbox],
        ...brandAccounts,
      };
      this.accounts[PLATFORM_CONNECTION.FACEBOOK_PAGE.value] = {
        ...this.accounts[PLATFORM_CONNECTION.FACEBOOK_PAGE.value],
        ...brandAccounts,
      };
      this.accounts[PLATFORM_CONNECTION.FACEBOOK_PAGE.sandbox] = {
        ...this.accounts[PLATFORM_CONNECTION.FACEBOOK_PAGE.sandbox],
        ...brandAccounts,
      };
    },
    async getInstagramAccountAllBrands(brandIds) {
      const response = await getInstagramAccountAllBrands(brandIds);
      const brandAccounts = response?.data;
      Object.entries(brandAccounts).forEach(([brandId, accounts]) => {
        brandAccounts[brandId] = accounts?.length !== 0 ? accounts : null;
      });
      this.accounts[PLATFORM_CONNECTION.FACEBOOK.value] = {
        ...this.accounts[PLATFORM_CONNECTION.FACEBOOK.value],
        ...brandAccounts,
      };
      this.accounts[PLATFORM_CONNECTION.FACEBOOK.sandbox] = {
        ...this.accounts[PLATFORM_CONNECTION.FACEBOOK.sandbox],
        ...brandAccounts,
      };
      this.accounts[PLATFORM_CONNECTION.INSTAGRAM_PAGE.value] = {
        ...this.accounts[PLATFORM_CONNECTION.INSTAGRAM_PAGE.value],
        ...brandAccounts,
      };
      this.accounts[PLATFORM_CONNECTION.INSTAGRAM_PAGE.sandbox] = {
        ...this.accounts[PLATFORM_CONNECTION.INSTAGRAM_PAGE.sandbox],
        ...brandAccounts,
      };
      this.accounts[PLATFORM_CONNECTION.INSTAGRAM_INFLUENCER.value] = {
        ...this.accounts[PLATFORM_CONNECTION.INSTAGRAM_INFLUENCER.value],
        ...brandAccounts,
      };
    },
    async getFacebookAdsAccountAllBrands(brandIds) {
      const response = await FacebookAPI.getAccountsMultiBrandsFromApi(brandIds);
      const brandAccounts = response?.data;
      Object.entries(brandAccounts).forEach(([brandId, accounts]) => {
        brandAccounts[brandId] = accounts?.length !== 0 ? accounts : null;
      });
      this.accounts[PLATFORM_CONNECTION.FACEBOOK_ADS.value] = {
        ...this.accounts[PLATFORM_CONNECTION.FACEBOOK_ADS.value],
        ...brandAccounts,
      };
    },
    async getLinkedinAccountAllBrands(brandIds) {
      const response = await getLinkedinAccountAllBrands(brandIds);
      const brandAccounts = response?.data;
      Object.entries(brandAccounts).forEach(([brandId, accounts]) => {
        brandAccounts[brandId] = accounts?.length !== 0 ? accounts : null;
      });
      this.accounts[PLATFORM_CONNECTION.LINKEDIN.value] = {
        ...this.accounts[PLATFORM_CONNECTION.LINKEDIN.value],
        ...brandAccounts,
      };
    },

    async getTikTokAdAccountsAllBrands(brandIds) {
      const response = await TikTokAPI.getAdAccountsAllBrands(brandIds);
      const brandAccounts = response?.data.data;
      Object.entries(brandAccounts).forEach(([brandId, accounts]) => {
        brandAccounts[brandId] = accounts?.length !== 0 ? accounts : null;
      });
      this.accounts[PLATFORM_CONNECTION.TIKTOK_ADS.value] = {
        ...this.accounts[PLATFORM_CONNECTION.TIKTOK_ADS.value],
        ...brandAccounts,
      };
    },
    async getTikTokCreatorMarketplaceAccountsAllBrands(brandIds) {
      const response = await TikTokAPI.getTTCMAccountsForMultipleBrands(brandIds);
      const brandAccounts = response?.data.data;
      Object.entries(brandAccounts).forEach(([brandId, accounts]) => {
        brandAccounts[brandId] = accounts?.length !== 0 ? accounts : null;
      });
      this.accounts[PLATFORM_CONNECTION.TIKTOK_CREATOR_MARKETPLACE.value] = {
        ...this.accounts[PLATFORM_CONNECTION.TIKTOK_CREATOR_MARKETPLACE.value],
        ...brandAccounts,
      };
    },
    async getPinterestAccount(brandId) {
      const account = await loadAccount(getPinterestAccount, brandId);
      this.accounts[PLATFORM_CONNECTION.PINTEREST.value] = { [brandId]: account };
      this.pinterestAccount = account;
      return account;
    },
    async getTwitterAccount(brandId) {
      const account = await loadAccount(getTwitterAccount, brandId);
      this.accounts[PLATFORM_CONNECTION.TWITTER.value] = { [brandId]: account };
      return account;
    },
    async getFacebookPage(brandId) {
      const page = await loadAccount(getFacebookPage, brandId);
      this.facebookPage = page;
      this.accounts[PLATFORM_CONNECTION.FACEBOOK_PAGE.value] = { [brandId]: page };
      this.accounts[PLATFORM_CONNECTION.FACEBOOK_PAGE.sandbox] = { [brandId]: page };
      this.accounts[PLATFORM_CONNECTION.FACEBOOK_ANALYTICS.value] = { [brandId]: page };
      this.accounts[PLATFORM_CONNECTION.FACEBOOK_ANALYTICS.sandbox] = { [brandId]: page };
      return page;
    },
    async getTikTokAccount(brandId) {
      const getAccount = (arg) => TikTokAPI.getTikTokAccount(arg).then(({ data }) => data);
      const account = await loadAccount(getAccount, brandId);
      this.accounts[PLATFORM_CONNECTION.TIKTOK.value] = { [brandId]: account };
      return account;
    },
    async getYouTubeChannel(brandId) {
      const payload = await loadAccount(getYouTubeChannel, brandId);
      const channel = payload?.data; // why an extra data prop?
      this.accounts[PLATFORM_CONNECTION.YOUTUBE.value] = { [brandId]: channel };
      return channel;
    },
    async getLinkedinAccount(brandId) {
      const payload = await loadAccount(getLinkedinAccount, brandId);
      const account = payload?.data;
      this.accounts[PLATFORM_CONNECTION.LINKEDIN.value] = { [brandId]: account };
      return account;
    },
    async getThreadsAccount(brandId) {
      const payload = await loadAccount(getThreadsProfile, brandId);
      const account = payload?.data;
      this.accounts[PLATFORM_CONNECTION.THREADS.value] = { [brandId]: account };
      return account;
    },
    async getThreadsAccountAllBrands(brandIds) {
      const response = await getThreadsProfileAllBrands(brandIds);
      const brandProfiles = {};
      Object.entries(response?.data).forEach(([brandId, profiles]) => {
        brandProfiles[brandId] = profiles?.length !== 0 ? profiles : null;
      });
      this.accounts[PLATFORM_CONNECTION.THREADS.value] = {
        ...this.accounts[PLATFORM_CONNECTION.THREADS.value],
        ...brandProfiles,
      };
    },
    async getGoogleAnalyticsAccount(brandId) {
      const account = await loadAccount(LibraryAPI.getBrandsGoogleAnalyticsAccount, brandId);
      this.accounts[PLATFORM_CONNECTION.GOOGLE_ANALYTICS.value] = { [brandId]: account };
    },
    async refreshAccounts() {
      const authStore = useAuthStore();
      const identityStore = useIdentityStore();
      const brandId = authStore.currentBrand?.id;

      if (!brandId) {
        // likely trying to update an influencer connection.
        return;
      }

      this.pending.accounts = true;
      const getAccountRequests = [
        this.getPinterestAccount(brandId),
        this.getTwitterAccount(brandId),
        this.getTikTokAccount(brandId),
        this.getYouTubeChannel(brandId),
      ];
      if (this.refreshAllAccounts) {
        getAccountRequests.push(
          this.getInstagramAccountAllBrands({ brandIds: identityStore.identityBrandIds }),
          this.getFacebookPageAllBrands({ brandIds: identityStore.identityBrandIds }),
          this.getFacebookAdsAccountAllBrands({ brandIds: identityStore.identityBrandIds }),
          this.getLinkedinAccountAllBrands({ brandIds: identityStore.identityBrandIds }),
          this.getTikTokAdAccountsAllBrands({ brandIds: identityStore.identityBrandIds }),
          this.getTikTokCreatorMarketplaceAccountsAllBrands({
            brandIds: identityStore.identityBrandIds,
          }),
          this.getThreadsAccountAllBrands({ brandIds: identityStore.identityBrandIds }),
        );
      } else {
        getAccountRequests.push(
          this.getInstagramAccount(brandId),
          this.getFacebookPage(brandId),
          this.getFacebookAdsAccount(brandId),
          this.getLinkedinAccount(brandId),
          this.getTikTokAdAccount(brandId),
          this.getTikTokCreatorMarketplaceAccounts(brandId),
          this.getThreadsAccount(brandId),
        );
      }
      if (
        authStore.guard([
          BRAND.CAMPAIGNS.CAN_ACCESS_CAMPAIGNS_GOOGLE_ANALYTICS,
          USER.CAMPAIGNS.CAN_ACCESS_CAMPAIGNS_GOOGLE_ANALYTICS,
        ])
      ) {
        getAccountRequests.push(this.getGoogleAnalyticsAccount(brandId));
      }
      try {
        await Promise.all(getAccountRequests);
      } catch (error) {
        if (error.response?.status === 401) {
          logger.error('Get accounts returned authentication error ', {}, error);
        } else {
          logger.error('[RefreshAccounts] returned error ', {}, error);
          throw error;
        }
      } finally {
        this.pending.accounts = false;
      }
    },
    async connect(
      platform,
      {
        title,
        message,
        showTerms = true,
        onCancel,
        brand,
        removeTitleFormatting = false,
        useSandbox = false,
        bypassCheckConnection = false,
        openedFrom = 'Connect Button',
        callToAction = 'Connect',
      } = {},
    ) {
      const platformType = extractPlatformConnectionType(platform, useSandbox);
      if (platformType) {
        this.connectionPopup.platform = platformType;
        this.connectionPopup.title = title;
        this.connectionPopup.message = message;
        this.connectionPopup.showTerms = showTerms;
        this.connectionPopup.onCancel = onCancel;
        this.connectionPopup.brand = brand;
        this.connectionPopup.removeTitleFormatting = removeTitleFormatting;
        this.connectionPopup.useSandbox = useSandbox;
        this.connectionPopup.show = true;
        this.connectionPopup.bypassConnectionCheck = bypassCheckConnection;

        this.logMixpanelEvent(PLATFORM_MIXPANEL.POPUP, platform, {
          openedFrom,
          callToAction,
        });
      }
    },
    closeConnectionPopup() {
      this.connectionPopup.show = false;
      this.connectionPopup.platform = null;
      this.connectionPopup.title = null;
      this.connectionPopup.message = null;
      this.connectionPopup.showTerms = null;
      this.connectionPopup.onCancel = null;
      this.connectionPopup.brand = null;
      this.connectionPopup.removeTitleFormatting = false;
      this.connectionPopup.useSandbox = null;
    },
    async createPlatformConnection({
      brandId,
      oauth,
      brandToken,
      platformType,
      invitedBy,
      sourceCreatorId,
      creatorHandleName,
      page,
    }) {
      this.pending.createPlatformConnection = true;
      this.error.platformConnection = null;
      let payload;
      try {
        const response = await AuthAPI.createPlatformConnection({
          brandId,
          oauth,
          brandToken,
          platformType,
          invitedBy,
          sourceCreatorId,
          creatorHandleName,
          page,
        });
        payload = response?.data;
        this.platformConnections = [...(this.platformConnections ?? []), payload];
        this.logMixpanelEvent(PLATFORM_MIXPANEL.STATUS_UPDATE, platformType, {
          brandId,
          fromExternalLink: !!brandToken,
          error: false,
          invitedByBrandId: invitedBy,
        });
      } catch (error) {
        this.error.platformConnection = error;
        throw error;
      }
      this.getPlatformConnections();
      this.pending.createPlatformConnection = false;
      return payload;
    },
    async updatePlatformConnection({
      connectionId,
      brandId,
      oauth,
      brandToken,
      platformType,
      invitedBy,
    }) {
      this.error.platformConnection = null;
      let payload;
      try {
        const response = await AuthAPI.updatePlatformConnection({
          connectionId,
          brandId,
          oauth,
          brandToken,
          platformType,
          invitedBy,
        });
        payload = response?.data;
        this.platformConnections = [
          ...(this.platformConnections ?? []).filter((pc) => pc.id !== payload.id),
          payload,
        ];
        this.logMixpanelEvent(PLATFORM_MIXPANEL.STATUS_UPDATE, platformType, {
          brandId,
          fromExternalLink: !!brandToken,
          update: true,
          error: false,
          invitedByBrandId: invitedBy,
        });
      } catch (error) {
        this.error.platformConnection = error;
        throw error;
      }

      this.getPlatformConnections();

      return payload;
    },
    async deletePlatformConnection({ platform, connectionId, brandId }) {
      this.error.platformConnection = null;
      try {
        if (isSamePlatformConnection(PLATFORM_CONNECTION.GOOGLE_ANALYTICS, platform)) {
          await this.disconnectGoogleAnalyticsAccount({ brandId });
        } else if (isSamePlatformConnection(PLATFORM_CONNECTION.FACEBOOK_ADS, platform)) {
          await this.disconnectFacebookAdsAccount({ brandId, connectionId });
        } else {
          await AuthAPI.deletePlatformConnection({ connectionId });
        }
        this.platformConnections = (this.platformConnections ?? []).filter(
          (pc) => pc.id !== connectionId,
        );
        this.logMixpanelEvent(PLATFORM_MIXPANEL.DISCONNECTED, platform);
      } catch (error) {
        this.error.platformConnection = error;
        throw error;
      }
      this.getPlatformConnections();
    },
    async connectPlatform({
      provider,
      connectionId,
      brandId,
      invitedByBrandId,
      invitedByBrandName,
      influencer,
      fromExternalLink,
      sourceCreatorId,
      router,
    }) {
      this.logMixpanelEvent(PLATFORM_MIXPANEL.CLICKED, provider, {
        brandId,
        invitedByBrandId,
        invitedByBrandName,
        influencer,
        fromExternalLink,
      });

      let parsedProvider = extractPlatformConnectionType(provider);
      if (parsedProvider === 'pinterest') parsedProvider = 'pinterest_v5';

      const authStore = useAuthStore();
      let next = router?.currentRoute?.value?.fullPath;
      // redirect to success page instead of original page if connect through stand alone connection page
      if (browserStorageGetItem('oauthBrandToken')) {
        // from the query parameter, token for standalone link for each brand
        // if stand alone page failed to connect, fall back to current page instead of success.
        browserStorageSetItem('oauthDeniedNext', next);
        const platformType =
          parsedProvider === 'facebook_analytics' || parsedProvider === 'facebook_sandbox'
            ? 'facebook'
            : parsedProvider;
        next = router?.resolve({ name: `connect.${camelCase(platformType)}.success` })?.href ?? '';
      }
      browserStorageSetItem('oauthBrand', brandId || authStore.currentBrand.id);
      if (connectionId) {
        browserStorageSetItem('oauthConnectionId', connectionId);
      }
      if (invitedByBrandId) {
        browserStorageSetItem('oauthInvitedByBrandId', invitedByBrandId);
      }
      if (influencer) {
        browserStorageSetItem('oauthInfluencer', influencer);
      }
      if (sourceCreatorId) {
        browserStorageSetItem('oauthSourceCreatorId', sourceCreatorId);
      }

      const startOAuthOptions = { router, provider: parsedProvider, next, brandId };
      authStore.startOAuth(startOAuthOptions);
    },
    async disconnectGoogleAnalyticsAccount({ brandId } = {}) {
      const authStore = useAuthStore();
      const brandIdToUse = brandId ?? authStore.currentBrand?.id;
      // this call deletes saved Google Analytics Account info
      // and platform connection token
      await LibraryAPI.deleteGoogleAnalyticsAccount(brandIdToUse);
      delete this.accounts[PLATFORM_CONNECTION.GOOGLE_ANALYTICS.value][brandId];
    },
    async disconnectFacebookAdsAccount({ brandId, connectionId } = {}) {
      const authStore = useAuthStore();
      const brandIdToUse = brandId ?? authStore.currentBrand?.id;
      const accountsToDelete = this.accounts[PLATFORM_CONNECTION.FACEBOOK_ADS.value][
        brandIdToUse
      ]?.map((account) => account.account_id);
      if (accountsToDelete) {
        await Promise.all(
          accountsToDelete.map(async (fbAccountId) => {
            await useFacebookStore().deleteFbAccount({ brandId: brandIdToUse, fbAccountId });
          }),
        );
      }
      if (connectionId) {
        await AuthAPI.deletePlatformConnection({ connectionId });
      }
      this.accounts[PLATFORM_CONNECTION.FACEBOOK_ADS.value][brandIdToUse] = null;
    },
    hasCompletePlatformConnection(brandId, channel) {
      const connectionName = PLATFORM_CONNECTION_NAMES[channel] || channel?.toLowerCase();

      const twitterChannels = [
        PLATFORM_CONNECTION.TWITTER.value.toUpperCase(),
        CHANNELS.TWITTER_COMPETITIVE.value,
      ];
      if (twitterChannels.includes(channel) && brandId) {
        const twitterStore = useTwitterStore();
        return this.hasCompleteTwitterConnection(twitterStore.twitterAccountsMap[brandId]);
      }

      return brandId
        ? this.isPlatformConnected(connectionName, {
            brandId,
          })
        : false;
    },
    brandHasPlatformAccess(brand, channel) {
      const identityStore = useIdentityStore();
      const brandChannelPerms = BRAND_PLATFORM_PERMISSIONS[channel];
      return identityStore.checkIfBrandPermissionsExist(brandChannelPerms, [brand]);
    },
    userHasPlatformAccess(brand, channel) {
      const identityStore = useIdentityStore();
      const userChannelPerms = USER_PLATFORM_PERMISSIONS[channel];
      return identityStore.checkIfUserPermissionsExist(userChannelPerms, [brand]);
    },
    brandAndUserHavePlatformAccess(brand, channel) {
      const brandHasAccess = this.brandHasPlatformAccess(brand, channel);
      const excludePlatformFromUserPlatformCheckList = [CHANNELS.INSTAGRAM_BENCHMARKS.value];
      const skipUserHasPlatformAccessCheck =
        excludePlatformFromUserPlatformCheckList.includes(channel);
      const userHasAccess = this.userHasPlatformAccess(brand, channel);

      return brandHasAccess && Boolean(skipUserHasPlatformAccessCheck || userHasAccess);
    },
    async getAccounts({ brandId, oauth, brandToken, platformType, invitedBy }) {
      this.error.platformConnection = null;
      this.pending.connectionAccounts = true;
      let payload;
      try {
        const response = await AuthAPI.getAccounts({
          brandId,
          oauth,
          brandToken,
          platformType,
          invitedBy,
        });
        payload = response?.data;
        const accounts = Array.isArray(payload) ? payload : payload.data;
        this.connectionAccounts = formatConnectionAccounts(
          platformType || oauth?.platform,
          accounts,
        );
      } catch (error) {
        this.error.platformConnection = error;
        throw error;
      }
      this.pending.connectionAccounts = false;

      await this.getPlatformConnections();

      return payload;
    },
    isNewPlatformConnection({ platformType, withinHours = 24 }) {
      const authStore = useAuthStore();
      const currentBrand = authStore.currentBrand?.id;
      const platformConnectedTime =
        this.platformConnectionsMap?.[platformType]?.[currentBrand]?.created_at;
      if (!platformConnectedTime) {
        return false;
      }
      const connectionTime = dayjs.duration(dayjs().diff(dayjs(platformConnectedTime))).asHours();
      return connectionTime <= withinHours && this.isPlatformConnected(platformType);
    },
    openImportingPopup() {
      this.showImportingPopup = true;
    },
    closeImportingPopup() {
      this.showImportingPopup = false;
    },
    openTrialStartedPopup() {
      this.showTrialStartedPopup = true;
    },
    closeTrialStartedPopup() {
      this.showTrialStartedPopup = false;
    },
  },
});
