export const SOCIAL_OVERVIEW_SINGLE_BRAND_OLD = [
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 0,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['FOLLOWER_GROWTH_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 0,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['FOLLOWER_GROWTH_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 0,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metrics: ['FOLLOWER_GROWTH_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 0,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['PINTEREST'],
      metrics: ['FOLLOWER_GROWTH_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 0,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metrics: ['FOLLOWER_GROWTH_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 2,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 2,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 2,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 2,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['PINTEREST'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 2,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 4,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK', 'INSTAGRAM', 'FACEBOOK', 'PINTEREST', 'TWITTER', 'YOUTUBE'],
      metrics: ['NET_NEW_FOLLOWERS'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 4,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK', 'INSTAGRAM', 'FACEBOOK', 'PINTEREST', 'TWITTER'],
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK', 'INSTAGRAM', 'PINTEREST', 'YOUTUBE'],
      metrics: ['TOTAL_VIDEO_VIEWS'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK', 'TWITTER'],
      metric: 'ORGANIC_VIDEO_VIEWS',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 12, meta: { name: 'TikTok Performance' } },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 13,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['NUMBER_OF_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 13,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 13,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['AVG_REACH'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 13,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['AVG_VIDEO_VIEWS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 13,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['AVG_RETENTION_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 13,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['AVG_ENTERTAINMENT_SCORE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 15,
    meta: { brand_ids: ['brand_ids'], channels: ['TIKTOK'], metric: 'TOTAL_ENGAGEMENTS' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 15,
    meta: { brand_ids: ['brand_ids'], channels: ['TIKTOK'], metric: 'TOTAL_VIDEO_VIEWS' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 19,
    meta: { brand_ids: ['brand_ids'], channels: ['TIKTOK'], metric: 'NET_NEW_FOLLOWERS' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 19,
    meta: { brand_ids: ['brand_ids'], channels: ['TIKTOK'], metric: 'VIDEO_VIEWS' },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 23,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'ENTERTAINMENT_SCORE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 27,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metric: 'TOTAL_VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 31, meta: { name: 'Instagram Feed Performance' } },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 32,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['NUMBER_OF_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 32,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 32,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['AVG_EFFECTIVENESS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 32,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['AVG_REACH'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 32,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['TOTAL_IMPRESSIONS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 32,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['LIKESHOP_CLICKS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 34,
    meta: { brand_ids: ['brand_ids'], channels: ['INSTAGRAM'], metric: 'TOTAL_ENGAGEMENTS' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 34,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['TOTAL_VIDEO_VIEWS', 'TOTAL_VIDEO_VIEWS_VIDEO', 'TOTAL_VIDEO_VIEWS_REEL'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 38,
    meta: { brand_ids: ['brand_ids'], channels: ['INSTAGRAM'], metric: 'NET_NEW_FOLLOWERS' },
  },
  {
    h: 4,
    type: 'MULTI_METRIC_MEDIA_TYPE',
    w: 3,
    x: 3,
    y: 38,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metrics: [
        'NUMBER_OF_POSTS_VIDEO',
        'NUMBER_OF_POSTS_REEL',
        'NUMBER_OF_POSTS_PHOTO',
        'NUMBER_OF_POSTS_CAROUSEL',
      ],
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 42,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 46,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metric: 'TOTAL_VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 50, meta: { name: 'Instagram Story Performance' } },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 51,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_STORIES'],
      metrics: ['NUMBER_OF_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 51,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_STORIES'],
      metrics: ['AVG_REACH'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 51,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_STORIES'],
      metrics: ['IMPRESSIONS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 51,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_STORIES'],
      metrics: ['AVG_COMPLETION_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 51,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_STORIES'],
      metrics: ['AVG_EXIT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 51,
    meta: { brand_ids: ['brand_ids'], channels: ['INSTAGRAM_STORIES'], metrics: 'SWIPE_UPS' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 53,
    meta: { brand_ids: ['brand_ids'], channels: ['INSTAGRAM_STORIES'], metric: 'IMPRESSIONS' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 53,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM_STORIES'],
      metric: 'AVG_COMPLETION_RATE',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 57,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM_STORIES',
      metric: 'SWIPE_UPS',
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 61, meta: { name: 'Facebook Performance' } },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 62,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metrics: ['NUMBER_OF_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 62,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 62,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metrics: ['AVG_EFFECTIVENESS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 62,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metrics: ['AVG_REACH'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 62,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metrics: ['IMPRESSIONS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 62,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metrics: ['LINK_CLICKS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 64,
    meta: { brand_ids: ['brand_ids'], channels: ['FACEBOOK'], metric: 'TOTAL_ENGAGEMENTS' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 64,
    meta: { brand_ids: ['brand_ids'], channels: ['FACEBOOK'], metric: 'ORGANIC_VIDEO_VIEWS' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 68,
    meta: { brand_ids: ['brand_ids'], channels: ['FACEBOOK'], metric: 'NET_NEW_FOLLOWERS' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 68,
    meta: { brand_ids: ['brand_ids'], channels: ['FACEBOOK'], metric: 'IMPRESSIONS' },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 72,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 76,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metric: 'VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 80, meta: { name: 'Pinterest Performance' } },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 81,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['PINTEREST'],
      metrics: ['NUMBER_OF_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 81,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['PINTEREST'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 81,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['PINTEREST'],
      metrics: ['IMPRESSIONS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 81,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['PINTEREST'],
      metrics: ['SAVES'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 81,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['PINTEREST'],
      metrics: ['CLOSEUPS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 81,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['PINTEREST'],
      metrics: ['LINK_CLICKS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 83,
    meta: { brand_ids: ['brand_ids'], channels: ['PINTEREST'], metric: 'NET_NEW_FOLLOWERS' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 83,
    meta: { brand_ids: ['brand_ids'], channels: ['PINTEREST'], metric: 'IMPRESSIONS' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 87,
    meta: { brand_ids: ['brand_ids'], channels: ['PINTEREST'], metric: 'SAVES' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 87,
    meta: { brand_ids: ['brand_ids'], channels: ['PINTEREST'], metric: 'LINK_CLICKS' },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 91,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'PINTEREST',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 95,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'PINTEREST',
      metric: 'TOTAL_VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 99, meta: { name: 'Twitter Performance' } },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 100,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metrics: ['NUMBER_OF_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 100,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 100,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metrics: ['IMPRESSIONS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 100,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metrics: ['RETWEETS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 100,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metrics: ['REPLIES'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 100,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metrics: ['LINK_CLICKS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 102,
    meta: { brand_ids: ['brand_ids'], channels: ['TWITTER'], metric: 'TOTAL_ENGAGEMENTS' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 102,
    meta: { brand_ids: ['brand_ids'], channels: ['TWITTER'], metric: 'ORGANIC_VIDEO_VIEWS' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 106,
    meta: { brand_ids: ['brand_ids'], channels: ['TWITTER'], metric: 'NET_NEW_FOLLOWERS' },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 106,
    meta: { brand_ids: ['brand_ids'], channels: ['TWITTER'], metric: 'IMPRESSIONS' },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 110,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 114,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metric: 'VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 0,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['SUBSCRIBER_GROWTH_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 118,
    meta: { name: 'YouTube Performance' },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 119,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['NUMBER_OF_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 119,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['TOTAL_AVG_VIEW_DURATION'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 119,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['TOTAL_ENGAGEMENTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 119,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['TOTAL_LIKES'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 119,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['TOTAL_ESTIMATED_SECONDS_WATCHED'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 119,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['TOTAL_VIDEO_VIEWS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 121,
    meta: { brand_ids: ['brand_ids'], channels: ['YOUTUBE'], metrics: ['TOTAL_ENGAGEMENTS'] },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 121,
    meta: { brand_ids: ['brand_ids'], channels: ['YOUTUBE'], metrics: ['TOTAL_VIDEO_VIEWS'] },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 125,
    meta: { brand_ids: ['brand_ids'], channels: ['YOUTUBE'], metrics: ['SUBSCRIBERS_NET_NEW'] },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 125,
    meta: { brand_ids: ['brand_ids'], channels: ['YOUTUBE'], metrics: ['VIEWS'] },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 129,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_ENGAGEMENTS'],
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 133,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_VIDEO_VIEWS'],
      sort_order: 'DESC',
    },
  },
];
