import { CHANNELS, compareChannels } from '@/models/dashboards/channels.enum';
import { GRAPH_SCALES } from '@/models/dashboards/graph-scales.enum';
import { constants } from '@/config';
import dateRangeFilter from '@/components/dashingUI/constants/dateRangeFilter';
import { searchBrandMediaTypes } from '../library/constants';

export const pinterestTotalEngagementMetrics = [
  'TOTAL_CLOSEUPS',
  'TOTAL_LINK_CLICKS',
  'TOTAL_SAVES',
];

export const topLineMetricList = {
  tiktok: 'NET_NEW_FOLLOWERS,TOTAL_VIDEO_VIEWS,TOTAL_ENGAGEMENTS,AVG_ENGAGEMENT_RATE',
  instagram:
    'NET_NEW_FOLLOWERS,ORGANIC_VIDEO_VIEWS,TOTAL_ENGAGEMENTS,IMPRESSIONS,AVG_ENGAGEMENT_RATE',
  pinterest:
    'NET_NEW_FOLLOWERS,TOTAL_VIDEO_VIEWS,IMPRESSIONS_BY_POST,TOTAL_CLOSEUPS,TOTAL_LINK_CLICKS,TOTAL_SAVES,TOTAL_AVG_ENGAGEMENT_RATE',
  youtube: 'SUBSCRIBERS_NET_NEW,TOTAL_VIDEO_VIEWS,TOTAL_ENGAGEMENTS',
  facebook:
    'NET_NEW_FOLLOWERS,ORGANIC_VIDEO_VIEWS,TOTAL_ENGAGEMENTS_POSTS,IMPRESSIONS_BY_POST,AVG_ENGAGEMENT_RATE',
  twitter:
    'NET_NEW_FOLLOWERS,ORGANIC_VIDEO_VIEWS,TOTAL_ENGAGEMENTS_POSTS,IMPRESSIONS_BY_POST,TOTAL_AVG_ENGAGEMENT_RATE',
};

export const tooltips = {
  videoViews:
    'The sum of video views of all posts published for all channels during the reporting period.',
  netNewFollowers:
    'The net new followers accumulated for all channels during the reporting period. Net New Followers = Total Followers - Followers Lost',
  impressions:
    'The total impressions from all posts published for all channels during the reporting period.',
  totalEngagements:
    'The total engagements from all posts published for all channels during the reporting period.',
  avgEngagementRate:
    'The average of the average engagement rates for all channels during the reporting period.',
  topTweets: 'The Posts published during the reporting period that received the most impressions.',
  topPins: 'The Pins published during the reporting period that received the most impressions.',
};

export const avgEngagementRate = 'AVG_ENGAGEMENT_RATE';

export const topLineTotalAggregationType = 'TOTAL';

export const toplineValues = [
  {
    valueName: 'videoViews',
    label: 'Video Views',
    isPercent: false,
    tooltip: tooltips.videoViews,
    metric: 'VIDEO_VIEWS',
  },
  {
    valueName: 'netNewFollowers',
    label: 'Net New Followers',
    isPercent: false,
    tooltip: tooltips.netNewFollowers,
    metric: 'NET_NEW_FOLLOWERS',
  },
  {
    valueName: 'impressions',
    label: 'Impressions',
    isPercent: false,
    tooltip: tooltips.impressions,
    metric: 'IMPRESSIONS',
  },
  {
    valueName: 'totalEngagements',
    label: 'Total Engagements',
    isPercent: false,
    tooltip: tooltips.totalEngagements,
    metric: 'TOTAL_ENGAGEMENTS',
  },
  {
    valueName: 'avgEngagementRate',
    label: 'Avg. Engagement Rate',
    isPercent: true,
    tooltip: tooltips.avgEngagementRate,
    metric: 'AVG_ENGAGEMENT_RATE',
  },
];

export const verticalMediaPanelCopy = {
  topInstagramStories: {
    title: 'Top Performing Instagram Story Frames',
    tooltip:
      'The Instagram Story Frames published during the reporting period that received the most impressions',
    channel: CHANNELS.INSTAGRAM_STORIES.value,
    emptyMessage: 'No Story Frames found during this reporting period',
  },
  topInstagramVideos: {
    title: 'Top Performing Instagram Videos',
    tooltip:
      'The Instagram videos published during the reporting period that received the most video views',
    channel: CHANNELS.INSTAGRAM.value,
    emptyMessage: 'No Instagram videos found during this reporting period',
  },
  topInstagramVideosOverview: {
    title: 'Top Performing Videos',
    tooltip: 'Your highest performing videos published within the selected date range',
    channel: CHANNELS.INSTAGRAM.value,
    emptyMessage: 'No videos found during this reporting period',
  },
  mostViewedVideos: {
    title: 'Most Viewed Videos',
    tooltip:
      'Top performing videos across all channels, sorted by video views or impressions if applicable',
    emptyMessage: 'No videos found during this reporting period',
  },
  topTiktokVideos: {
    title: 'Top Performing TikTok Posts',
    tooltip:
      'The TikTok posts published during the reporting period that received the most video views',
    channel: CHANNELS.TIKTOK.value,
    emptyMessage: 'No TikTok posts found during this reporting period',
  },
  topVisionPredictedUGCVideos: {
    title: 'Top Vision Predicted UGC Videos',
    tooltip:
      'Vision predicted top UGC that either @mentioned or phototagged you during the reporting period, sorted by video views',
    emptyMessage: 'No videos found during this reporting period',
  },
  twitterMostViewedVideos: {
    title: 'Most Viewed Videos',
    tooltip: 'Your Posts that received the highest number of views during the selected date range',
    emptyMessage: 'No X videos found during this reporting period',
  },
  mostEntertainingVideos: {
    title: 'Most Entertaining Videos',
    tooltip:
      'Most entertaining videos across Instagram and TikTok sorted by the Entertainment Score',
    emptyMessage: 'No videos found during this reporting period',
  },
};

export const mediaPanelCopy = {
  topYoutubeVideos: {
    title: 'Top Performing YouTube Videos',
    tooltip:
      'The YouTube videos published during the reporting period that received the most video views.',
    channel: CHANNELS.YOUTUBE.value,
    emptyMessage: 'No videos found during this time range',
  },
};

export const orderedReportChannelList = [
  constants.TIKTOK,
  constants.INSTAGRAM,
  constants.FACEBOOK,
  constants.PINTEREST,
  constants.TWITTER,
  constants.YOUTUBE,
].sort(compareChannels);

export const graphScale = GRAPH_SCALES.DAILY.value;

export const graphMetrics = {
  AVG_ENGAGEMENT_RATE: {
    value: 'AVG_ENGAGEMENT_RATE',
    text: 'Avg. Engagement Rate',
  },
  TOTAL_VIDEO_VIEWS: {
    value: 'TOTAL_VIDEO_VIEWS',
    text: 'Video Views',
  },
  ORGANIC_VIDEO_VIEWS: {
    value: 'ORGANIC_VIDEO_VIEWS',
    text: 'Video Views',
  },
  VIEWS: {
    value: 'VIEWS',
    text: 'Video Views',
  },
};

export const entertainmentScoreAliasMap = {
  TIKTOK: 'entertainmentScore',
  INSTAGRAM: 'entertainmentScore',
};

export const mostEntertainingVidoesBrandMediaTypes = [
  searchBrandMediaTypes.INSTAGRAM_OWNED,
  searchBrandMediaTypes.INSTAGRAM_OWNED_IGTV,
  searchBrandMediaTypes.TIKTOK_OWNED,
];

export const mostViewedVideosBrandMediaTypes = [
  searchBrandMediaTypes.INSTAGRAM_OWNED,
  searchBrandMediaTypes.INSTAGRAM_OWNED_IGTV,
  searchBrandMediaTypes.TIKTOK_OWNED,
  searchBrandMediaTypes.FACEBOOK_OWNED,
  searchBrandMediaTypes.PINTEREST_OWNED,
  searchBrandMediaTypes.TWITTER_OWNED,
  searchBrandMediaTypes.YOUTUBE_OWNED,
];

export const LANDING_PAGE_FILTER_DETAILS = Object.freeze({
  LANDING_PAGE: {
    scope: 'landingPage',
    options: {
      reportingPeriod: {},
      comparisonPeriod: {
        label: 'Comparison Period',
        required: true,
      },
    },
    default: {
      reportingPeriod: {
        preset: dateRangeFilter.REPORTING_PERIOD_FILTERS.LAST_7_DAYS.value,
      },
      comparisonPeriod: {
        preset: dateRangeFilter.COMPARISON_PERIOD_FILTERS.PREVIOUS_7_DAYS.value,
      },
    },
  },
});
