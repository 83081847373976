<template>
  <Avatar v-bind="$attrs" :img-src="avatarUrl" class="dh-letter-avatar">
    <template v-if="displayPlaceholderAvatar">
      <Icon name="user" class="user-icon" width="55%" height="55%" />
    </template>
    <template v-else-if="!avatar">
      <img :src="avatarUrl" />
      <div class="avatar-bg-mask" />
      <span>{{ name.slice(0, 1) }}</span>
    </template>
  </Avatar>
</template>

<script>
import { defineComponent } from 'vue';
import bg1 from '@/assets/img/empty-beach.png';
import bg2 from '@/assets/img/empty-kanpai.png';
import bg3 from '@/assets/img/empty-ok.png';
import bg4 from '@/assets/img/empty-wave.png';
import bg5 from '@/assets/img/empty-cups.png';
import bg6 from '@/assets/img/empty-driving.png';
import bg7 from '@/assets/img/empty-shell.png';
import bg8 from '@/assets/img/empty-road.png';
import bg9 from '@/assets/img/empty-palm.png';
import bg10 from '@/assets/img/empty-handsup.png';
import Avatar from '@/components/foundation/Avatar.vue';
import Icon from '@/components/foundation/Icon.vue';

// This component creates an avatar with first letter of handle on top of a random blur background,
// and same handle will always have the same avatar with same background.
// Did not introduce letterpic to dash-web because it is a jQuery plugin.

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'LetterAvatar',
  components: {
    Avatar,
    Icon,
  },
  props: {
    accountId: { type: [Number, String], default: 0 },
    name: { type: String, default: '' },
    avatar: { type: String, default: '' },
  },
  data() {
    return {
      backgroundFiles: [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10],
    };
  },
  computed: {
    displayPlaceholderAvatar() {
      return !this.avatar && (!this.name || this.name === 'null');
    },
    avatarUrl() {
      if (this.avatar) {
        return this.avatar;
      }
      const index = Number(this.accountId) % this.backgroundFiles.length;
      return this.backgroundFiles[index];
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.dh-letter-avatar {
  overflow: hidden;
  margin-left: calc(-1 * var(--space-12));
  border: 2px solid var(--white);
  position: relative;
  transform: translate3d(0, 0, 0);

  .avatar-bg-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgb(0 0 0 / 15%);
  }

  img {
    width: 100%;
    height: 100%;
    filter: blur(5px);
    transition: none;
  }

  span {
    position: absolute;
    transform: translateY(-2px);
    font-weight: 400;
    text-transform: uppercase;
    font-size: var(--x18);
    color: var(--white);
  }
}

.user-icon {
  max-width: var(--space-20);
}
</style>
