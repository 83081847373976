<script setup>
import Icon from '@/components/foundation/Icon.vue';

const emit = defineEmits(['add']);

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  container: {
    type: String,
    required: true,
  },
  iconName: {
    type: String,
    default: 'arrow-enter',
  },
  text: {
    type: String,
    default: 'Add',
  },
  placement: {
    type: String,
    default: 'bottom-start',
  },
  boundary: {
    type: String,
    default: '',
  },
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div>
    <VDropdown
      :shown="props.show"
      :container="props.container"
      :boundary="props.boundary"
      :placement="props.placement"
      theme="dh-dropdown-full"
      :triggers="[]"
      no-auto-focus
    >
      <template #popper>
        <div
          class="prompt absolute ml-10 mt-[-1rem] flex h-fit w-fit cursor-pointer rounded bg-white pl-2 pr-2.5"
        >
          <Icon :name="props.iconName" color="#666" class="m-auto" xxsmall />
          <span
            class="ml-1.5 mt-[0.3125rem] h-[29px] text-sm font-normal text-[#666]"
            @click.prevent="emit('add')"
          >
            {{ props.text }}
          </span>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<style lang="postcss" scoped>
.prompt {
  box-shadow: var(--shadow-4);
}
</style>
