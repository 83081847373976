<template>
  <a class="more-button">
    <button v-if="showDeleteOption || showAddToRIQ" ref="moreDropdown" @click="showDropdown">
      <Icon large name="ellipsis" />
      More
    </button>
    <button v-else id="notesButton" @click="handleNotesClicked">
      <Icon name="note" large />
      Notes
    </button>

    <transition name="slide">
      <ul
        v-if="dropdownOpen && currentDropdownView.actionList"
        v-on-click-outside="hideDropdown"
        class="more-dropdown"
      >
        <li v-if="showAddToRIQ" @click="handleAddToRiqClicked">Add to RIQ</li>
        <li @click="handleNotesClicked">
          <Icon name="note" small />
          Notes
        </li>
        <li v-if="showDeleteOption" data-cy="delete-media" @click="handleDeleteClicked">
          <Icon name="bin" small />
          Delete
        </li>
      </ul>
    </transition>

    <transition name="fade">
      <div
        v-if="dropdownOpen && currentDropdownView.notes"
        v-on-click-outside="hideDropdown"
        class="notes-container"
      >
        <Textarea v-model="notes" placeholder="Enter notes" />
        <span class="button-row">
          <Button small @click="handleCancelClicked">Cancel</Button>
          <Button ref="saveButton" small primary @click="handleSaveClicked">Save</Button>
        </span>
      </div>
    </transition>
  </a>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { vOnClickOutside } from '@vueuse/components';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { useMediaDetailStore } from '@/stores/media-detail';
import constants, {
  ugcMediaDeleteMessage,
  nonUGCMediaDeleteMessage,
} from '@/app/library/constants';
import { useInstagramRelationshipsStore } from '@/stores/instagram-relationships';
import Button from '@/components/foundation/Button.vue';
import Textarea from '@/components/Textarea.vue';
import Icon from '@/components/foundation/Icon.vue';
import { getMediaTypeFromDetail } from '@/utils/media';

const sourceTypesWithDeleteButtonHidden = [
  constants.PINTEREST_OWNED,
  constants.INSTAGRAM_OWNED,
  constants.INSTAGRAM_STORY,
  constants.FACEBOOK_ADS,
  constants.FACEBOOK_OWNED,
  constants.FACEBOOK_TEXT,
  constants.FACEBOOK_LINK,
  constants.FACEBOOK_TEXT_LINK,
  constants.LINKEDIN_OWNED,
  constants.TWITTER_OWNED,
  constants.TWITTER_OWNED_TEXT,
  constants.TWITTER_OWNED_LINK,
  constants.TIKTOK_OWNED,
  constants.TIKTOK_UGC,
  constants.YOUTUBE_OWNED,
];

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'MoreDropdown',
  components: {
    Button,
    Icon,
    Textarea,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  emits: ['dropdownClicked', 'changeSaved'],
  data() {
    return {
      dropdownOpen: false,
      showDeleteConfirm: false,
      currentDropdownView: {
        actionList: true,
        notes: false,
      },
      notes: null,
    };
  },
  computed: {
    ...mapStores(
      useNotificationStore,
      useMediaDetailStore,
      useInstagramRelationshipsStore,
      useTrackingStore,
    ),
    ...mapPiniaState(useAuthStore, ['currentBrand', 'isSuperAdmin']),
    sourceType() {
      return (
        this.mediaDetailStore.mediaDetail.sourceType ||
        this.mediaDetailStore.mediaDetail.source_type
      );
    },
    onDifferentBrand() {
      return this.currentBrand.id !== this.mediaDetailStore.mediaDetail.brandId;
    },
    showDeleteOption() {
      const { sourceType } = this.mediaDetailStore.mediaDetail;
      return (
        !this.onDifferentBrand &&
        !sourceTypesWithDeleteButtonHidden.some((hiddenType) => hiddenType === sourceType)
      );
    },
    showAddToRIQ() {
      return (
        this.isSuperAdmin &&
        this.mediaDetailStore?.mediaDetail?.sourceType === constants.INSTAGRAM_UGC &&
        Boolean(this.mediaDetailStore.mediaDetail?.relationship) &&
        !this.onDifferentBrand
      );
    },
  },
  watch: {
    mediaDetail(to) {
      if (to) {
        this.notes = to.notes;
      }
    },
    'mediaDetailStore.pending.updateMediaNotesStatus': function updateMediaNotesStatusWatcher(to) {
      if (!to) {
        this.notificationStore.setToast({ message: 'Your notes have been saved.' });
        this.currentDropdownView.notes = false;
        this.currentDropdownView.actionList = true;
        this.dropdownOpen = false;
      }
    },
  },
  mounted() {
    this.notes =
      this.mediaDetailStore.mediaDetail &&
      this.mediaDetailStore.mediaDetail.meta &&
      this.mediaDetailStore.mediaDetail.meta.notes
        ? this.mediaDetailStore.mediaDetail.meta.notes
        : null;
  },
  methods: {
    getDeleteTitle() {
      const deletedMediaType = getMediaTypeFromDetail(this.mediaDetailStore.mediaDetail);
      return `Delete ${deletedMediaType}?`;
    },
    getDeleteMessage() {
      const deletedMediaType = getMediaTypeFromDetail(this.mediaDetailStore.mediaDetail);
      const message = constants.UGC_SOURCE_LIST.includes(this.sourceType)
        ? ugcMediaDeleteMessage
        : nonUGCMediaDeleteMessage;
      return `${message}${deletedMediaType}?`;
    },
    hideDropdown(e) {
      if (this.dropdownOpen && e.target !== this.$refs.moreDropdown) {
        this.dropdownOpen = false;
        this.currentDropdownView.actionList = true;
        this.currentDropdownView.notes = false;
      }
    },
    showDropdown() {
      this.dropdownOpen = true;
    },
    handleNotesClicked() {
      this.$emit('dropdownClicked', 'Notes');
      this.currentDropdownView.actionList = false;
      this.currentDropdownView.notes = true;
      this.showDropdown();
    },
    async handleDeleteClicked() {
      this.$emit('dropdownClicked', 'Delete');
      this.showDeleteConfirm = true;
      const title = this.getDeleteTitle();
      const message = this.getDeleteMessage();
      await this.notificationStore.confirm(title, message, {
        confirmAlias: 'Delete',
        onConfirm: this.confirmDelete,
      });
    },
    cancelDelete() {
      this.showDeleteConfirm = false;
    },
    async confirmDelete() {
      await this.mediaDetailStore.deleteMediaItem({
        brandId: this.currentBrand.id,
        mediaId: this.mediaDetailStore.mediaDetail.id,
      });
      this.$emit('changeSaved', 'Delete');
      if (this.mediaDetailStore.mediaDetail.sourceType === constants.INSTAGRAM_UGC) {
        // Gather evidence for #56321 and #54387 in case users are deleting posts intentionally
        this.trackingStore.track('Library UGC Deleted', {
          brand_id: this.currentBrand.id,
          media_id: this.mediaDetailStore.mediaDetail.id,
        });
      }
      // TODO: delete locally by update store instead of fetching media list again. make sure media section layout not breaking.
    },
    handleCancelClicked() {
      this.currentDropdownView.notes = false;

      if (this.showDeleteOption) {
        this.currentDropdownView.actionList = true;
      }
    },
    handleSaveClicked() {
      this.$emit('changeSaved', 'Notes');
      const metadata = this.mediaDetailStore.mediaDetail.meta
        ? this.mediaDetailStore.mediaDetail.meta
        : {};
      metadata.notes = this.notes;
      this.mediaDetailStore.updateMediaNotes({
        brandId: this.mediaDetailStore.mediaDetail.brandId,
        mediaId: this.mediaDetailStore.mediaDetail.id,
        meta: metadata,
      });
      this.$refs.saveButton.spin();
    },
    async handleAddToRiqClicked() {
      this.$emit('dropdownClicked', 'Add To RIQ');
      try {
        await this.instagramRelationshipsStore.addMentionUGC({
          brandId: this.currentBrand.id,
          facebookMediaId: this.mediaDetailStore.mediaDetail.postSourceId,
        });
        this.notificationStore.setToast({ message: 'Post Added to RIQ' });
      } catch {
        this.notificationStore.setToast({
          message: 'Unable to add post to RIQ',
          type: 'error',
        });
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.more-button {
  > button {
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: var(--x14);
    color: var(--text-primary);
    font-weight: var(--font-medium);
    cursor: pointer;
  }

  .svg-icon {
    pointer-events: none;
    margin-bottom: var(--space-8);
  }
}

.more-dropdown {
  li {
    display: flex;
    align-items: center;
    width: 7.8rem;
    padding: var(--space-8) var(--space-16);
    font-size: var(--x14);
    font-weight: var(--font-medium);
    cursor: pointer;

    &:hover {
      color: var(--action-500);
    }

    .svg-icon {
      margin: 0 var(--space-8) 0 0;
    }
  }
}

.more-dropdown,
.notes-container {
  position: absolute;
  top: 5.5rem;
  right: 0;
  background: var(--background-0);
  border-radius: var(--round-corner-small);
  padding: var(--space-8) 0;
  box-shadow: var(--shadow-4);
  z-index: var(--z-index-dropdown);
}

.notes-container {
  width: 18.75rem;
  padding: var(--space-24) var(--space-24) var(--space-16);

  textarea {
    resize: none;
  }

  .button-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: var(--space-12);

    button {
      margin: 0 var(--space-4);
    }
  }
}
</style>
