<template>
  <SubScreen :title="productTagsSubScreenTitle" @on-back="handleBack">
    <template #content>
      <ImageDropdownSelect
        v-model="selectedCatalogId"
        class="catalog-select"
        :options="facebookProductTaggerStore.facebookProductCatalogs"
        option-text="catalogName"
        option-value="catalogId"
        placeholder="Select product catalog"
        :disabled="disableCatalogSelect"
        :grey="disableCatalogSelect"
        round
      />
      <SearchSelect
        v-if="facebookProductTaggerStore.facebookProductTaggingEligible"
        ref="searchSelect"
        v-model="searchTerm"
        class="product-search-select"
        :options="productOptions"
        placeholder="Search product name"
        :disabled="!selectedCatalogId"
        :loading="loadingProducts"
        return-object
        round
        @option-click="handleOptionClick"
        @input="handleInput"
        @blur="clearSearchResults"
        @focus="handleInputFocused"
      >
        <template #option="{ option }">
          <ProductListItem :product="option" />
        </template>
        <template #noResults>
          <p class="empty-search-message">No products found</p>
        </template>
      </SearchSelect>

      <template v-if="savedActiveMediaProductTags.length > 0">
        <List
          class="search-list"
          :items="savedActiveMediaProductTags"
          :alternate-item-color="false"
          option-key="fbProductId"
        >
          <template #main-content="{ item: productItem, index }">
            <ProductListItem
              :product="productItem"
              @delete-tag="deleteFacebookShoppingTag({ index })"
            />
          </template>
        </List>
      </template>

      <CircularLoader v-if="isPending" />

      <FacebookProductTagsErrorState
        v-else-if="showErrorState"
        :show-reconnect-message="showReconnectMessage"
      />

      <div v-else-if="facebookProductTaggerStore.activeMediaTaggedProducts.length === 0">
        <p class="empty-state-message">{{ shoppingTagsSubScreenEmptyStateMessage }}</p>
      </div>
    </template>
  </SubScreen>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import debounce from 'lodash/debounce';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { FacebookAPI } from '@/apis';
import CircularLoader from '@/components/CircularLoader.vue';
import FacebookProductTagsErrorState from '@/app/scheduler/components/EditPost/FacebookProductTagsErrorState.vue';
import ImageDropdownSelect from '@/components/foundation/form/ImageDropdownSelect.vue';
import List from '@/components/foundation/List.vue';
import ProductListItem from '@/components/core/products/ProductListItem.vue';
import SearchSelect from '@/components/foundation/form/SearchSelect.vue';
import SubScreen from '@/app/scheduler/components/EditPost/Layout/SubScreen.vue';
import {
  shoppingTagsSubScreenTitle as productTagsSubScreenTitle,
  eligibilityCallFailureNotificationMessage,
  eligibilityCallFailureNotificationSubtext,
  shoppingTagsSubScreenEmptyStateMessage,
} from '@/app/scheduler/constants';
import { fbGraphApiErrors, productReviewStatusTypes } from '@/app/library/constants';
import { debounceInputDelayShort } from '@/config';
import { useFacebookProductTaggerStore } from '@/stores/facebook-product-tagger';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'FacebookProductTags',
  components: {
    CircularLoader,
    FacebookProductTagsErrorState,
    ImageDropdownSelect,
    List,
    ProductListItem,
    SearchSelect,
    SubScreen,
  },
  props: {
    onBack: { type: Function, required: true },
  },
  data() {
    return {
      productTagsSubScreenTitle,
      shoppingTagsSubScreenEmptyStateMessage,
      facebookProducts: [],
      showErrorState: false,
      showReconnectMessage: false,
      searchTerm: null,
      loadingProducts: false,
    };
  },
  computed: {
    ...mapStores(useNotificationStore, useFacebookProductTaggerStore),
    ...mapPiniaState(useAuthStore, ['currentBrand']),
    isPending() {
      return (
        !!this.facebookProductTaggerStore.pending.facebookProductTaggingEligible ||
        !!this.facebookProductTaggerStore.pending.facebookProductCatalogs
      );
    },
    disableCatalogSelect() {
      return (
        !!this.facebookProductTaggerStore.pending.facebookProductCatalogs ||
        this.facebookProductTaggerStore.facebookProductCatalogs === null
      );
    },
    selectedCatalogId: {
      get() {
        return this.facebookProductTaggerStore.selectedFacebookProductCatalogId;
      },
      set(catalogId) {
        this.facebookProductTaggerStore.setSelectedFacebookProductCatalogId({ catalogId });
      },
    },
    productOptions() {
      return this.facebookProducts.map((product) => {
        const disabled =
          this.facebookProductTaggerStore.activeMediaTaggedProducts.findIndex(
            (tag) => tag.fbProductId === product.fbProductId,
          ) !== -1 || product.reviewStatus?.toLowerCase() === productReviewStatusTypes.REJECTED;
        return {
          ...product,
          disabled,
        };
      });
    },
    savedActiveMediaProductTags() {
      return this.facebookProductTaggerStore.activeMediaTaggedProducts.filter(
        (tag) => tag.fbProductId !== null,
      );
    },
  },
  watch: {
    'facebookProductTaggerStore.editingFacebookProductTag': {
      handler(to) {
        if (to) {
          this.$refs.searchSelect.$refs.textInput.$refs.textInput.focus();
        }
      },
    },
  },
  async created() {
    if (!this.facebookProductTaggerStore.facebookProductTaggingEligible) {
      await this.fetchEligibility();
    }
    if (!this.facebookProductTaggerStore.facebookProductTaggingEligible) {
      this.showErrorState = true;
      return;
    }

    if (this.facebookProductTaggerStore.facebookProductCatalogs === null) {
      await this.facebookProductTaggerStore.getFacebookProductCatalogs();
    }

    if (this.facebookProductTaggerStore.facebookProductCatalogs.length === 1) {
      this.selectedCatalogId = this.facebookProductTaggerStore.facebookProductCatalogs[0].catalogId;
    }

    this.facebookProductTaggerStore.enableFacebookProductTagger();
  },
  unmounted() {
    this.facebookProductTaggerStore.disableFacebookProductTagger();
  },
  methods: {
    async fetchEligibility() {
      try {
        await this.facebookProductTaggerStore.checkFacebookProductTaggingEligible();
      } catch (error) {
        // Facebook graph api is inconsistent with error codes, for now we want to catch these errors when showing the connection popup
        if (
          [fbGraphApiErrors.API_PERMISSION_ERROR, fbGraphApiErrors.INVALID_PARAMETER].includes(
            error.response?.data?.fb_graph_api_error,
          )
        ) {
          this.showReconnectMessage = true;
          return;
        }

        this.handleUnknownFbGraphApiError(error);
      }
    },
    handleInput() {
      this.loadingProducts = true;
      this.searchProducts();
    },
    searchProducts: debounce(async function searchProducts() {
      if (!this.searchTerm) {
        this.clearSearchResults();
        this.loadingProducts = false;
        return;
      }

      this.loadingProducts = true;
      const response = await FacebookAPI.facebookProductTags.searchProducts({
        brandId: this.currentBrand.id,
        params: {
          fbPageId: this.facebookProductTaggerStore.facebookPageId,
          catalogId: this.selectedCatalogId,
          query: this.searchTerm,
        },
      });
      this.facebookProducts = response.data.products;
      this.loadingProducts = false;
    }, debounceInputDelayShort),
    clearSearchResults() {
      this.facebookProducts = [];
    },
    handleInputFocused() {
      if (!this.facebookProductTaggerStore.editingFacebookProductTag) {
        this.facebookProductTaggerStore.addFacebookProductTag({
          productTag: {
            fbProductId: null,
            title: null,
            x: 0.5,
            y: 0.5,
          },
        });
        this.facebookProductTaggerStore.setEditingFacebookProductTag({ value: true });
      }
    },
    handleOptionClick(value) {
      const { disabled: _, ...productTag } = value;
      this.facebookProductTaggerStore.updateFacebookProductTag({ productTag });
      this.facebookProductTaggerStore.setEditingFacebookProductTag({ value: false });
      this.clearSearchResults();
    },
    handleBack() {
      if (this.facebookProductTaggerStore.editingFacebookProductTag) {
        this.facebookProductTaggerStore.deleteFacebookProductTag();
        this.facebookProductTaggerStore.setEditingFacebookProductTag({ value: false });
      }
      this.onBack();
    },
    handleUnknownFbGraphApiError(error) {
      this.notificationStore.setToast({
        message: eligibilityCallFailureNotificationMessage,
        subtext: eligibilityCallFailureNotificationSubtext,
        buttonText: 'Got It',
        autoClear: false,
        type: 'error',
      });
      throw error;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.catalog-select {
  margin: var(--space-32) 0 0 0;
  text-align: left;

  :deep(&.disabled) {
    opacity: 1;
  }
}

.product-search-select {
  margin: var(--space-24) 0 0 0;
}

.empty-state-message {
  margin-top: var(--space-88);
  font-weight: var(--font-medium);
  font-size: var(--x18);
  color: var(--text-secondary);
}

.search-list {
  margin-top: var(--space-24);
}

:deep(.main-content-panel) {
  margin-left: 0;
  width: 100%;
}

:deep(.item-details) {
  padding: var(--space-8) 0;
}
</style>
