<template>
  <!-- eslint-disable vue/no-v-html -->
  <svg version="1.1" :class="clazz" :viewBox="box" :style="style" @click="onClick" v-html="path" />
</template>

<script>
import svgicon from 'vue-svgicon';

/* eslint-disable */
const notLoadedIcons = [];
const defaultWidth = '';
const defaultHeight = '';
const classPrefix = 'svg';
const isStroke = false;
const isOriginalDefault = false;

export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'SvgIcon',
  props: {
    icon: String,
    name: String,
    width: {
      type: [String, Number],
      default: '',
    },
    height: {
      type: [String, Number],
      default: '',
    },
    scale: String,
    dir: String,
    fill: {
      type: Boolean,
      default() {
        return !isStroke;
      },
    },
    color: String,
    original: {
      type: Boolean,
      default() {
        return isOriginalDefault;
      },
    },
    title: String,
  },
  emits: ['click'],
  data() {
    return {
      loaded: false,
    };
  },

  computed: {
    clazz() {
      let clazz = `${classPrefix}-icon`;

      if (this.fill) {
        clazz += ` ${classPrefix}-fill`;
      }

      if (this.dir) {
        clazz += ` ${classPrefix}-${this.dir}`;
      }

      return clazz;
    },

    iconName() {
      return this.name || this.icon;
    },

    iconData() {
      const iconData = svgicon.icons[this.iconName];
      if (iconData || this.loaded) {
        return iconData;
      }

      return null;
    },

    colors() {
      if (this.color) {
        return this.color.split(' ');
      }
      return [];
    },

    path() {
      let pathData = '';
      if (this.iconData) {
        pathData = this.iconData.data;

        pathData = this.setTitle(pathData);

        // use original color
        if (this.original) {
          pathData = this.addOriginalColor(pathData);
        }

        if (this.colors.length > 0) {
          pathData = this.addColor(pathData);
        }
      } else {
        // if no iconData, push to notLoadedIcons
        notLoadedIcons.push({
          name: this.iconName,
          component: this,
        });
      }

      return this.getValidPathData(pathData);
    },

    box() {
      const width = this.width || 16;
      const height = this.width || 16;

      if (this.iconData) {
        if (this.iconData.viewBox) {
          return this.iconData.viewBox;
        }
        return `0 0 ${this.iconData.width} ${this.iconData.height}`;
      }

      return `0 0 ${parseFloat(width)} ${parseFloat(height)}`;
    },

    style() {
      const digitReg = /^\d+$/;
      const scale = Number(this.scale);
      let width;
      let height;

      // apply scale
      if (!isNaN(scale) && this.iconData) {
        width = `${Number(this.iconData.width) * scale}px`;
        height = `${Number(this.iconData.height) * scale}px`;
      } else {
        width = digitReg.test(this.width) ? `${this.width}px` : this.width || defaultWidth;
        height = digitReg.test(this.height) ? `${this.height}px` : this.height || defaultWidth;
      }

      const style = {};

      if (width) {
        style.width = width;
      }

      if (height) {
        style.height = height;
      }
      return style;
    },
  },

  created() {
    if (svgicon.icons[this.iconName]) {
      this.loaded = true;
    }
  },

  beforeUnmount() {
    const index = notLoadedIcons.findIndex((v) => v.name === this.iconName);
    if (index >= 0) {
      notLoadedIcons.splice(index, 1);
    }
  },

  methods: {
    addColor(data) {
      const reg = /<(path|rect|circle|polygon|line|polyline|ellipse)\s/gi;
      let i = 0;
      return data.replace(reg, (match) => {
        let color = this.colors[i++] || this.colors[this.colors.length - 1];
        let fill = this.fill;

        // if color is '_', ignore it
        if (color && color === '_') {
          return match;
        }

        // if color start with 'r-', reverse the fill value
        if (color && /^r-/.test(color)) {
          fill = !fill;
          // color = color.split('r-')[1]
          color = color.substr(2);
        }

        const style = fill ? 'fill' : 'stroke';
        const reverseStyle = fill ? 'stroke' : 'fill';
        return `${match}${style}="${color}" ${reverseStyle}="none" `;
      });
    },

    addOriginalColor(data) {
      const styleReg = /_fill="|_stroke="/gi;
      return data.replace(styleReg, (styleName) => {
        return styleName && styleName.slice(1);
      });
    },

    getValidPathData(pathData) {
      // If use original and colors, clear double fill or stroke
      if (this.original && this.colors.length > 0) {
        const reg =
          /<(path|rect|circle|polygon|line|polyline|ellipse)(\sfill|\sstroke)([="\w\s\.\-\+#\$\&>]+)(fill|stroke)/gi;
        pathData = pathData.replace(reg, (match, p1, p2, p3, p4) => {
          return `<${p1}${p2}${p3}_${p4}`;
        });
      }

      return pathData;
    },

    setTitle(pathData) {
      if (this.title) {
        const title = this.title
          .replace(/\</gi, '&lt;')
          .replace(/>/gi, '&gt;')
          .replace(/&/g, '&amp;');
        return `<title>${title}</title>${pathData}`;
      }
      return pathData;
    },

    onClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>
