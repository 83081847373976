<template>
  <div class="gallery-image-select">
    <header>
      <div class="header-left">
        <div class="back" @click="handleBackClick">
          <Icon :color="backIconColor" name="caret" dir="down" xsmall />
        </div>
        <span>{{ gallery.name }}</span>
      </div>
      <Select
        :model-value="sort"
        :options-style="['right-align']"
        :options="sortOptions"
        placeholder="Recently Added"
        class="select-control"
        @selected="sortChanged"
      />
    </header>

    <section class="gallery-detail-media">
      <MediaLayout
        v-if="popupGalleryStore.galleryMediaList && popupGalleryStore.galleryMediaList.length > 0"
        :items="popupGalleryStore.formattedGalleryMediaList"
        :row-height="130"
        :drag-select="{
          enabled: !disableMultiSelect,
          popup: true,
          scrollableAreaSelector: '.scrollable-area',
        }"
        :limit-source="limitSource"
      >
        <template #default="{ item, width, height }">
          <MediaTile
            tile-type="library"
            context="mediaSelectPopup"
            :item="item"
            :width="width"
            :height="height"
            :allow-mixed-media="allowMixedMedia"
            :media-type-limit="mediaTypeLimit"
            :media-count-limit="mediaCountLimit"
            :video-count-limit="videoCountLimit"
            :add-to-pinterest-board="addToPinterestBoard"
            :can-be-published-at="canBePublishedAt"
            :disallow-past-publish-dates="disallowPastPublishDates"
            :disallow-incompatible-publish-dates="disallowIncompatiblePublishDates"
            :publish-dates-must-overlap-with="publishDatesMustOverlapWith"
            click-select
          />
        </template>
      </MediaLayout>

      <CircularLoader v-if="!popupGalleryStore.galleryMediaListFullyLoaded" />

      <InfiniteScroll :on-infinite="onInfinite" :distance="260" />

      <div
        v-if="
          popupGalleryStore.galleryMediaListFullyLoaded &&
          popupGalleryStore.galleryMediaList &&
          popupGalleryStore.galleryMediaList.length === 0
        "
      >
        <EmptyStatus image="empty-cattub.png" title="This gallery is empty!" />
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { colours } from '@/ux/colours';
import CircularLoader from '@/components/CircularLoader.vue';
import Icon from '@/components/foundation/Icon.vue';
import InfiniteScroll from '@/components/InfiniteScroll.vue';
import MediaLayout from '@/components/core/layout/MediaLayout.vue';
import MediaTile from '@/components/MediaTile.vue';
import Select from '@/components/Select.vue';
import EmptyStatus from '@/components/EmptyStatus.vue';
import { enumProp } from '@/utils/props';
import { mediaTypes } from '@/app/library/constants';
import { useMediaSelectStore } from '@/stores/media-select';
import { usePopupGalleryStore } from '@/stores/popup-gallery';
import { ALL_TIME, isValidInterval } from '@/utils/dateUtils';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'GalleryImageSelect',
  components: {
    EmptyStatus,
    CircularLoader,
    Icon,
    InfiniteScroll,
    MediaLayout,
    MediaTile,
    Select,
  },
  props: {
    gallery: { type: Object, default: null },
    limitSource: { type: String, default: null },
    mediaTypeLimit: enumProp(mediaTypes, null),
    mediaCountLimit: { type: Number, default: null },
    videoCountLimit: { type: Number, default: null },
    allowMixedMedia: { type: Boolean, default: true },
    addToPinterestBoard: { type: Boolean, default: false },
    disableMultiSelect: { type: Boolean, default: false },
    canBePublishedAt: { type: [Date, Object], default: null },
    disallowPastPublishDates: { type: Boolean, default: false },
    disallowIncompatiblePublishDates: { type: Boolean, default: false },
    publishDatesMustOverlapWith: {
      type: Object,
      default: () => ALL_TIME,
      validator: (value) => value === false || isValidInterval(value),
    },
  },
  emits: ['onBackClick'],
  data() {
    return {
      sortOptions: [
        {
          label: 'Recently Uploaded/Published',
          value: '-CREATED',
        },
        {
          label: 'Recently Added to Gallery',
          value: '-ADDED',
        },
        {
          label: 'Total Traffic',
          value: '-CLICKS',
        },
      ],
      sort: '-CREATED',
      backIconColor: colours.ICON.ICON_PRIMARY,
    };
  },
  computed: {
    ...mapStores(useMediaSelectStore, usePopupGalleryStore),
    ...mapPiniaState(useAuthStore, ['currentBrand']),
  },
  unmounted() {
    this.popupGalleryStore.clearGalleryMedia();
    this.mediaSelectStore.clearItemsFromMultiSelect();
  },
  mounted() {
    this.updateGalleryMedia();
  },
  methods: {
    handleBackClick() {
      this.$emit('onBackClick');
      this.popupGalleryStore.clearGalleryMedia();
    },
    sortChanged(option) {
      this.popupGalleryStore.clearGalleryMedia();
      this.sort = this.sortOptions[option].value;
      this.updateGalleryMedia();
    },
    updateGalleryMedia() {
      this.popupGalleryStore.getGalleryMedia({
        brandId: this.currentBrand.id,
        galleryId: this.gallery.id,
        url: this.popupGalleryStore.galleryMediaListNext,
        limit: 30,
        sort: this.sort,
      });
    },
    onInfinite() {
      if (
        !this.popupGalleryStore.pending.getGalleryMedia &&
        !this.popupGalleryStore.galleryMediaListFullyLoaded
      ) {
        this.updateGalleryMedia();
      }
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.gallery-image-select {
  background: var(--background-300);
  border-radius: 0 0 var(--round-corner) var(--round-corner);
  padding: var(--space-40);
  min-height: 19rem;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--space-40);
  }

  .header-left {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--font-medium);
    font-size: var(--x18);
  }

  .back {
    background: var(--background-0);
    width: var(--space-40);
    height: var(--space-32);
    display: flex;
    border: solid 1px var(--border);
    border-radius: var(--round-corner-small);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: var(--space-16);
    transition: var(--transition-all);

    &:hover {
      transform: var(--hover-move);
    }
  }
}
</style>
