<script setup>
import VisionNotAvailable from '@/components/VisionAi/VisionNotAvailable.vue';
import VisionUpgradeCTA from '@/components/VisionAi/VisionUpgradeCTA.vue';
import ScrollableChat from '@/components/VisionAi/ScrollableChat.vue';
import PromptButton from '@/components/VisionAi/PromptButton.vue';
import VisionWelcomeMessage from '@/components/VisionAi/VisionWelcomeMessage.vue';
import VisionAiTextbox from '@/components/VisionAi/VisionAiTextbox.vue';
import { useVisionAiStore } from '@/stores/vision-ai';
import { computed, nextTick, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';
import { communityRouteName } from '@/app/community/routes';
import { useVisionAiPermissions } from '@/components/VisionAi/composables/useVisionAiPermissions';
import {
  UPGRADE_COMMUNITY_CTA_CONFIG,
  DEFAULT_METRIC_AND_ANALYTICS_PROMPTS,
  INTENT_TYPES,
  UPGRADE_CONVERSATIONAL_DASHBOARDS_CTA_CONFIG,
} from '@/components/VisionAi/constants';
import { useIntentMap } from './composables/useIntentMap';

const visionAiStore = useVisionAiStore();

const route = useRoute();
const { userHasVisionAiPermissionForCurrentRoute, userCanAccessVisionAiForSomeBrandOrOrg } =
  useVisionAiPermissions();
const { getTooltip } = useIntentMap();

const props = defineProps({
  defaultPrompts: {
    type: Array,
    default: null,
  },
  hasMessages: {
    type: Boolean,
    default: false,
  },
  messages: {
    type: Array,
    default: null,
  },
  messagesLoading: {
    type: Boolean,
    default: false,
  },
  scrollableChatLoading: {
    type: Boolean,
    default: false,
  },
  showNotAvailableEmptyState: {
    type: Boolean,
    default: false,
  },
});

const { messagesLoading, hasMessages, defaultPrompts, showNotAvailableEmptyState } = toRefs(props);

const upgradeCTAConfig = computed(() => {
  const isCommunity = route.name?.startsWith(communityRouteName);

  // conversational will use CTA screens going forward as vision AI is available on every page
  const showUpgrade =
    Boolean(!defaultPrompts.value?.length) && userCanAccessVisionAiForSomeBrandOrOrg.value;

  if (!showUpgrade) return null;

  // return route specific CTA or fallback to general conversational CTA
  if (isCommunity) return UPGRADE_COMMUNITY_CTA_CONFIG;

  return UPGRADE_CONVERSATIONAL_DASHBOARDS_CTA_CONFIG;
});

const showUpgradeCTA = computed(() => {
  return Boolean(upgradeCTAConfig.value);
});

const showPromptButton = computed(
  () => !messagesLoading.value && !hasMessages.value && Boolean(defaultPrompts.value?.length),
);

const isExpanded = computed(() => {
  return visionAiStore.popupIsExpanded;
});

function isMetricAndAnalyticsPrompts(promptMessage) {
  return DEFAULT_METRIC_AND_ANALYTICS_PROMPTS.includes(promptMessage);
}

function getPromptButtonTooltip(promptMessage) {
  if (isMetricAndAnalyticsPrompts(promptMessage)) {
    return getTooltip(INTENT_TYPES.METRIC_ANALYTICS);
  }
  return getTooltip(visionAiStore.currentIntentType);
}

function expandPromptClass() {
  nextTick(() => {
    document.getElementById('prompt-button-container')?.classList.add('grid-cols-2');
    document.getElementById('prompt-button')?.classList.remove('w-fit');
    document.getElementById('prompt-button')?.classList.remove('justify-self-end');
  });
}

function minimizePromptClass(timeout = 0) {
  // Add timing to the prompt button transition, this will slow down the transition from
  // 2 row grid layout to 4 row grid layout. Making the transition more smooth.
  setTimeout(() => {
    document.getElementById('prompt-button-container')?.classList.remove('grid-cols-2');
    document.getElementById('prompt-button')?.classList.add('w-fit');
    document.getElementById('prompt-button')?.classList.add('justify-self-end');
  }, timeout);
}

watch(isExpanded, (to) => {
  if (to) {
    expandPromptClass();
  } else {
    minimizePromptClass(300);
  }
});

watch(
  showPromptButton,
  (to) => {
    if (to) {
      if (isExpanded.value) {
        expandPromptClass();
      } else {
        minimizePromptClass();
      }
    }
  },
  { immediate: true },
);

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        userHasVisionAiPermissionForCurrentRoute,
        hasMessages,
        defaultPrompts,
        getPromptButtonTooltip,
      }
    : {},
);
</script>

<template>
  <VisionUpgradeCTA v-if="showUpgradeCTA" :upgrade-cta-config="upgradeCTAConfig" class="cta" />
  <VisionNotAvailable
    v-else-if="showNotAvailableEmptyState"
    class="px-4"
    @launch-vision-ai-resource-center="visionAiStore.sendToResourceCenter"
  />
  <div v-else-if="showPromptButton" class="self-end pr-4">
    <VisionWelcomeMessage :use-summary-message="!visionAiStore.canAccessConversational" />

    <div id="prompt-button-container" class="grid gap-2">
      <template v-for="(promptMessage, index) in props.defaultPrompts" :key="index">
        <PromptButton
          id="prompt-button"
          :class="{ 'h-full': isExpanded }"
          :prompt-message="promptMessage"
          :tooltip="getPromptButtonTooltip(promptMessage)"
        />
      </template>
    </div>
    <VisionAiTextbox
      v-if="visionAiStore.canAccessConversational"
      class="mt-3"
      :is-expanded="isExpanded"
    />
  </div>

  <div v-else class="flex h-full w-full flex-col justify-end gap-3 overflow-hidden">
    <ScrollableChat :messages="messages" :loading="scrollableChatLoading" />
    <VisionAiTextbox v-if="visionAiStore.canAccessConversational" :is-expanded="isExpanded" />
  </div>
</template>
