export const BRAND_HEALTH_SINGLE_BRAND = [
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 7, meta: { name: 'Instagram Health' } },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['COMMENT_SENTIMENT_POSITIVE_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['COMMENT_SENTIMENT_NEGATIVE_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['COMMENT_SENTIMENT_NEUTRAL_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['SUM_TOTAL_ENGAGEMENTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 8,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM'],
      metrics: ['NUMBER_OF_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 10,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'INSTAGRAM',
      metrics: ['SUM_TOTAL_ENGAGEMENTS'],
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 0, meta: { name: 'TikTok Health' } },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['COMMENT_SENTIMENT_POSITIVE_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['COMMENT_SENTIMENT_NEGATIVE_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['COMMENT_SENTIMENT_NEUTRAL_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['TOTAL_ENGAGEMENTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 1,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TIKTOK'],
      metrics: ['NUMBER_OF_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 3,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TIKTOK',
      metrics: ['TOTAL_ENGAGEMENTS'],
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 14, meta: { name: 'Facebook Health' } },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 15,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metrics: ['COMMENT_SENTIMENT_POSITIVE_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 15,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metrics: ['COMMENT_SENTIMENT_NEGATIVE_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 15,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metrics: ['COMMENT_SENTIMENT_NEUTRAL_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 15,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 15,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metrics: ['TOTAL_ENGAGEMENTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 15,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['FACEBOOK'],
      metrics: ['NUMBER_OF_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 17,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'FACEBOOK',
      metrics: ['TOTAL_ENGAGEMENTS'],
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 21, meta: { name: 'X Health' } },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 22,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metrics: ['COMMENT_SENTIMENT_POSITIVE_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 22,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metrics: ['COMMENT_SENTIMENT_NEGATIVE_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 22,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metrics: ['COMMENT_SENTIMENT_NEUTRAL_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 22,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 22,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metrics: ['TOTAL_ENGAGEMENTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 22,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['TWITTER'],
      metrics: ['NUMBER_OF_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 24,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'TWITTER',
      metrics: ['TOTAL_ENGAGEMENTS'],
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 28, meta: { name: 'YouTube Health' } },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 29,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['COMMENT_SENTIMENT_POSITIVE_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 29,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['COMMENT_SENTIMENT_NEGATIVE_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 29,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['COMMENT_SENTIMENT_NEUTRAL_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 29,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['TOTAL_ENGAGEMENTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 29,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['YOUTUBE'],
      metrics: ['NUMBER_OF_POSTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'CONTENT_OWNED',
    w: 6,
    x: 0,
    y: 31,
    meta: {
      brand_ids: ['brand_ids'],
      channel: 'YOUTUBE',
      metrics: ['TOTAL_ENGAGEMENTS'],
      sort_order: 'DESC',
    },
  },
  { h: 1, type: 'HEADER', w: 6, x: 0, y: 35, meta: { name: 'Sentiment Trend' } },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 36,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM', 'TIKTOK', 'FACEBOOK', 'TWITTER', 'YOUTUBE'],
      metrics: ['COMMENT_SENTIMENT_POSITIVE_RATE'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 36,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM', 'TIKTOK', 'FACEBOOK', 'TWITTER', 'YOUTUBE'],
      metrics: ['COMMENT_SENTIMENT_NEGATIVE_RATE'],
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 40,
    meta: {
      brand_ids: ['brand_ids'],
      channels: ['INSTAGRAM', 'TIKTOK', 'FACEBOOK', 'TWITTER', 'YOUTUBE'],
      metrics: ['COMMENT_SENTIMENT_NEUTRAL_RATE'],
    },
  },
];
