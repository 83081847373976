<template>
  <Calendar
    ref="calendar"
    locale="en"
    :attributes="attributes"
    :from-date="date"
    @dayclick="onDayClick"
  />
</template>

<script>
import { defineComponent } from 'vue';
import { Calendar } from 'v-calendar';
import { todayAttribute, rangeAttribute } from '@/utils/vcalendar.utils';
import { CALENDAR_MODE } from '@/components/core/calendar/constants';
import { enumProp } from '@/utils/props';
import { getIntervalByMode } from '@/components/core/calendar/calendar-utils';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  components: {
    Calendar,
  },
  props: {
    date: {
      type: Date,
      default: null,
    },
    mode: enumProp(CALENDAR_MODE, CALENDAR_MODE.WEEK),
  },
  emits: ['update:date'],
  computed: {
    attributes() {
      return [
        todayAttribute(),
        { ...rangeAttribute(), dates: getIntervalByMode(this.date, this.mode) },
      ];
    },
  },
  watch: {
    date(to) {
      if (this.$refs.calendar && to) {
        this.$refs.calendar.focusDate(to);
      }
    },
  },
  methods: {
    onDayClick({ date }) {
      this.$emit('update:date', date);
    },
  },
});
export default comp;
</script>
