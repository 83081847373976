function sortTopKeywords(keywords) {
  return keywords.sort((a, b) => {
    return b.count - a.count;
  });
}

function normalizeSentiment({ positive, neutral, negative }) {
  const total = positive + neutral + negative;
  const positiveRatio = positive / total;
  const negativeRatio = negative / total;
  const neutralRatio = 1 - (positiveRatio + negativeRatio);

  return { positiveRatio, negativeRatio, neutralRatio };
}

export function convertTopKeywordsToMeters(keywords, loading = false) {
  if (loading) {
    return [];
  }
  const sortedTopKeywords = sortTopKeywords(keywords);
  return sortedTopKeywords.map((keywordData) => {
    const { positive, neutral, negative } = keywordData.sentiment;
    const total = positive + neutral + negative;
    const { positiveRatio, negativeRatio, neutralRatio } = normalizeSentiment(
      keywordData.sentiment,
    );
    return {
      label: keywordData.keyword,
      data: [
        {
          label: 'Positive',
          value: positive,
          color: 'var(--color-sentiment-positive)',
        },
        {
          label: 'Neutral',
          value: neutral,
          color: 'var(--color-sentiment-neutral)',
        },
        {
          label: 'Negative',
          value: negative,
          color: 'var(--color-sentiment-negative)',
        },
      ],
      tooltipData: [
        {
          label: 'Mentions',
          value: total,
        },
        {
          label: 'Positive',
          value: positiveRatio,
          formatType: 'percent',
          formatOptions: {
            style: 'percent',
            maximumFractionDigits: 0,
          },
          dot: {
            severity: 'positive',
          },
        },
        {
          label: 'Neutral',
          value: neutralRatio,
          formatType: 'percent',
          formatOptions: {
            style: 'percent',
            maximumFractionDigits: 0,
          },
          dot: {
            severity: 'neutral',
          },
        },
        {
          label: 'Negative',
          value: negativeRatio,
          formatType: 'percent',
          formatOptions: {
            style: 'percent',
            maximumFractionDigits: 0,
          },
          dot: {
            severity: 'negative',
          },
        },
      ],
    };
  });
}

export function filterTooltipMetrics(meterData, meterTooltipData) {
  const hiddenCategories = meterData.reduce((acc, { hidden, label }) => {
    return hidden ? [...acc, label] : acc;
  }, []);
  return meterTooltipData.filter(({ label }) => !hiddenCategories.includes(label));
}
