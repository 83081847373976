<script setup>
// Vue
import { computed } from 'vue';
// Components
import Icon from '@/components/foundation/Icon.vue';

const props = defineProps({
  appearance: {
    type: String,
    default: 'default',
  },
  content: {
    type: String,
    default: null,
  },
  icon: {
    type: String,
    default: null,
  },
  linkText: {
    type: String,
    default: null,
  },
  linkUrl: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});

const showLink = computed(() => props.linkText && props.linkUrl);
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <article :class="['content-box', props.appearance]">
    <div class="image">
      <slot name="image">
        <Icon v-if="props.icon" :name="props.icon" medium />
      </slot>
    </div>
    <div class="content">
      <div class="title">
        <slot name="title">
          <p>{{ title }}</p>
        </slot>
      </div>
      <slot name="content">
        <!-- eslint-disable vue/no-v-html -->
        <p v-if="props.content" class="text-small" v-html="props.content" />
        <!-- eslint-enable vue/no-v-html -->
      </slot>
      <slot name="action">
        <p v-if="showLink" class="text-small">
          <a :href="props.linkUrl" target="_blank" rel="noopener">
            {{ props.linkText }}
          </a>
        </p>
      </slot>
    </div>
  </article>
</template>

<style lang="postcss" scoped>
/* Base Styles */
.content-box {
  border-radius: var(--round-corner-small);
  display: grid;
  grid-gap: var(--space-16);
  grid-template-columns: var(--space-24) auto;
  padding: var(--space-24);
}

.content {
  display: grid;
  grid-gap: var(--space-16);
}

.title {
  font-weight: var(--font-medium);
  line-height: 1;
}

/* Appearance Property Styles */
.default {
  background-color: var(--background-300);
}

.dotted {
  background-color: var(--background-0);
  border: 1px dashed var(--border);
}
</style>
