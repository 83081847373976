<template>
  <teleport to="body">
    <PortalPopupBase
      v-bind="$attrs"
      ref="portalPopupBase"
      :size="size"
      :container-styles="containerStyles"
      :popup-styles="popupStyles"
      :vertical-center="verticalCenter"
      :role="role"
      :transition="transition"
      :hide-close-button="hideCloseButton"
      @close="handleClose"
    >
      <slot />
      <ConfirmDialog
        v-if="showConfirmDiscard"
        :message="discardMessage"
        confirm-alias="Discard"
        title="Discard Changes?"
        @confirm="close"
        @cancel="showConfirmDiscard = false"
      />
    </PortalPopupBase>
  </teleport>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import PortalPopupBase from '@/components/PortalPopup/PortalPopupBase.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'PortalPopup',
  components: {
    PortalPopupBase,
    ConfirmDialog: defineAsyncComponent(
      () => import('@/components/core/dialogs/ConfirmDialog.vue'),
    ),
  },
  props: {
    // It is safer to use the containerStyles prop over size
    size: { type: String, default: null },
    containerStyles: {
      type: Object,
      default: null,
    },
    popupStyles: {
      type: Object,
      default: null,
    },
    discardMessage: { type: String, default: null },
    verticalCenter: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: Boolean,
      default: true,
    },
    role: {
      type: String,
      default: 'standalone',
    },
    hideCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['back', 'close'],
  data() {
    return {
      showConfirmDiscard: false,
      uid: null,
    };
  },
  methods: {
    back() {
      this.$emit('back');
    },
    close({ force = false } = {}) {
      this.$emit('close', { force });
    },
    handleClose({ force = false } = {}) {
      if (this.discardMessage && !force) {
        this.showConfirmDiscard = true;
      } else {
        this.close({ force });
      }
    },
  },
});
export default comp;
</script>
