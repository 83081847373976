import { metaPromotedFieldsToVerbose } from '@/app/instagram/constants';
import startCase from 'lodash/startCase';

export function formatMetaPromoted(field, verboseFields = metaPromotedFieldsToVerbose) {
  let verbose = verboseFields[field];
  if (verbose) return verbose;
  if (field.endsWith('Paid')) {
    verbose = startCase(field);
    let replacement = ' - Promoted';
    if (field.startsWith('videoPlays')) {
      replacement = '% - Promoted';
    }
    verbose = verbose.replace(' Paid', replacement);
    return verbose;
  }
  verbose = field
    .replaceAll(/([A-Z])/g, ' $1')
    .replaceAll(/([_])/g, ' ')
    .split(' ')
    .map((word) => {
      return `${word[0].toUpperCase()}${word.substr(1)}`;
    })
    .join(' ');
  return verbose;
}

export function getFormattedNestings(nestings, values) {
  if (nestings.length === 2) {
    const [organicField, promotedField] = nestings;
    return {
      organic: values[organicField] ?? '',
      promoted: values[promotedField] ?? '',
    };
  }
  const formattedNestings = {};
  nestings.forEach((subField) => {
    formattedNestings[subField] = values[subField] ?? '';
  });
  return formattedNestings;
}

export function sortMetaPromotedFields(a, b, formatter = formatMetaPromoted) {
  if (a === 'likeshopClicks') return 1;
  if (b === 'likeshopClicks') return -1;
  const aFormatted = formatter(a);
  const bFormatted = formatter(b);
  return aFormatted.localeCompare(bFormatted, undefined, { numeric: true });
}
