<script setup>
import { DropdownBase, Icon, OverlayBadge } from '@dashhudson/dashing-ui';
import CircularLoader from '@/components/CircularLoader.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import NotificationsPanelItem from '@/components/layout/navigation/NotificationsPanelItem.vue';
import { BASIC } from '@/ux/colours';
import { computed, onBeforeMount, watch } from 'vue';
import { isEqual } from 'lodash/isEqual';
import { COMMENT_RESOURCE_TYPES } from '@/config';
import {
  SCHEDULER_APPROVAL_REQUEST_RESOURCE_TYPE,
  SCHEDULER_REQUEST_APPROVED_RESOURCE_TYPE,
} from '@/app/scheduler/constants';
import { useRouter } from 'vue-router';
import { useRegisterSockets } from '@/composables/useRegisterSockets';
import { useCommentingStore } from '@/stores/commenting';
import { useIdentityStore } from '@/stores/identity';
import { useSchedulerStore } from '@/stores/scheduler';
import { useTrackingStore } from '@/stores/tracking';
import { MIXPANEL_VALUES } from '@/components/layout/navigation/constants';
import { trackNavigation } from '@/utils/analytics/tracking';

const router = useRouter();

const commentingStore = useCommentingStore();
const identityStore = useIdentityStore();
const schedulerStore = useSchedulerStore();

const trackingStore = useTrackingStore();

const notifications = computed(() => commentingStore.notifications);
const notificationsPaging = computed(() => commentingStore.notificationsPaging);
const pending = computed(() => commentingStore.pending);

const shouldLoadNextPage = computed(() => {
  return (
    !pending.value.notificationsPaging &&
    !pending.value.notifications &&
    notifications.value.every((notification) => !notification.readAt) &&
    !!notificationsPaging.value.next
  );
});

const notificationsCount = computed(() => notifications.value.length);

const unreadNotificationsCount = computed(() =>
  notifications.value.reduce(
    (count, notification) => (!notification.readAt ? count + 1 : count),
    0,
  ),
);

const hasUnreadNotifications = computed(() =>
  notifications.value.some((notification) => !notification.readAt),
);

onBeforeMount(() => {
  commentingStore.getNotifications();
});

watch(
  () => pending,
  (to, from) => {
    if (isEqual(to, from)) {
      return;
    }
    if (
      !to.notifications &&
      !to.notificationsPaging &&
      notificationsPaging.value.next &&
      notifications.value?.every((notification) => !notification.readAt)
    ) {
      commentingStore.getNotificationsNextPage();
    }
  },
);

watch(
  () => shouldLoadNextPage.value,
  (to, from) => {
    if (isEqual(to, from)) {
      return;
    }
    if (to) {
      commentingStore.getNotificationsNextPage();
    }
  },
);

function sendMenuOptionSelectedEvent(option) {
  trackingStore.track('Notification Panel Option Selection', {
    'Notifications Count': notificationsCount.value,
    'Unread Notifications Count': unreadNotificationsCount.value,
    Option: option,
  });
}

function sendNotificationClickedEvent(notification) {
  let action = null;
  let properties = null;

  if (notification?.comment !== undefined && notification?.comment !== null) {
    action = 'Notification Panel View Comment';
    properties = {
      'Resource ID': notification.comment.resourceId,
      'Resource Type': notification.comment.resourceType,
      'Unread Notification': notification.readAt == null,
    };
  }

  if (action !== null && properties !== null) {
    trackingStore.track(action, properties);
  }
}

function loadNextPage() {
  commentingStore.getNotificationsNextPage();
}

function handleScroll({ target: { clientHeight, scrollHeight, scrollTop } }) {
  if (scrollHeight - scrollTop === clientHeight && scrollTop > 0) {
    loadNextPage();
  }
}

async function clickNotification(notification) {
  sendNotificationClickedEvent(notification);
  await commentingStore.markNotificationRead({ id: notification.id });
  const { url } = notification;

  let path = url.match(/^http/) ? url.replace(new URL(notification.url).origin, '') : url;

  if (notification.comment.resourceType === COMMENT_RESOURCE_TYPES.COMMUNITY_ASSIGNMENT) {
    path = path
      .replace(`&resource_id=${notification.comment.id}`, '')
      .replace('/assigned-to-me', '/all-messages');
  }
  if (notification.comment.resourceType === COMMENT_RESOURCE_TYPES.COMMUNITY_INTERNAL_NOTE) {
    path = path.replace(
      `id=${notification.comment.resourceId}`,
      `mentioned_user_ids=${identityStore.identity?.id}`,
    );
  }

  if (
    [
      ...Object.values(SCHEDULER_APPROVAL_REQUEST_RESOURCE_TYPE),
      ...Object.values(SCHEDULER_REQUEST_APPROVED_RESOURCE_TYPE),
    ].includes(notification.comment.resourceType)
  ) {
    await schedulerStore.fetchPost({
      platform: notification.url.split('/')[5],
      id: notification.comment.resourceId,
    });
  }

  if (notification.comment.brandId === identityStore.currentBrand?.id) {
    await router.push(path);
  } else {
    window.location.assign(path);
  }
}

const subMenuContent = computed(() => {
  const dropdownMenuButtonItemList = [
    {
      text: 'Mark All as Read',
      action: () => {
        sendMenuOptionSelectedEvent('Mark All as Read');
        commentingStore.markNotificationsRead();
      },
    },
    {
      text: 'Email Preferences',
      action: () => {
        router.push({
          name: 'settings.notifications.email',
          params: { brandLabel: identityStore.currentBrandLabel },
        });
        sendMenuOptionSelectedEvent('Email Preferences');
      },
    },
  ];
  return dropdownMenuButtonItemList;
});

function createCommentNotificationSocket(payload) {
  commentingStore.createCommentNotificationSocket({ notification: payload });
}

function trackOpen(isBeingOpened) {
  if (isBeingOpened) {
    trackNavigation(MIXPANEL_VALUES.NOTIFICATIONS.ROOT);
  }
}

useRegisterSockets('COMMENT_NOTIFICATION_CREATED', createCommentNotificationSocket, null);
</script>

<template>
  <DropdownBase
    v-bind="{ disabled: false, placement: 'right-end' }"
    class="p-0"
    visual-bridge
    strategy="fixed"
    @update:shown="trackOpen($event)"
  >
    <OverlayBadge v-if="hasUnreadNotifications">
      <Icon
        name="legacy-bell"
        :size="20"
        class="dash-fill-icon-subtle hover:dash-fill-icon-primary m-1 cursor-pointer"
      />
    </OverlayBadge>
    <Icon
      v-else
      name="legacy-bell"
      :size="20"
      class="dash-fill-icon-subtle hover:dash-fill-icon-primary m-1 cursor-pointer"
    />
    <template #content>
      <div class="dash-typo-base w-[var(--secondary-navigation-width)] pt-6">
        <div class="mb-4 flex justify-between px-6 py-2">
          <h5 class="dash-typo-xl-semibold dash-text-primary">Notifications</h5>
          <DropdownButton
            :dropdown-list-classes="['visual-bridge']"
            :button-icon-color="BASIC.BLACK"
            :dropdown-list="subMenuContent"
            button-icon="showMore"
            button-classes="corner"
            align-right
            button-is-width-of-icon
          />
        </div>
        <div class="grid max-h-[35rem] overflow-auto" @scroll="handleScroll">
          <NotificationsPanelItem
            v-for="notification in notifications"
            :key="notification.id"
            :notification="notification"
            :brand="identityStore.identityBrandsById[notification.comment.brandId]"
            @click="clickNotification(notification)"
          />
          <CircularLoader v-if="pending.notificationsPaging" />
          <p v-else-if="!notificationsPaging.next" class="dash-text-subtle px-6 py-4 text-center">
            No more notifications
          </p>
        </div>
      </div>
    </template>
  </DropdownBase>
</template>
