import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { SOCIAL_LISTENING_ROUTE_NAMES } from '@/app/socialListening/constants';
import { useMediaPopupStore } from '@/stores/media-popup';
import { formatChannelForPublicMediaRoute } from '@/utils/formatters';

const contextListIndex = ref();
const context = ref({});

export function usePublicMediaDrawer() {
  const router = useRouter();

  const mediaPopupStore = useMediaPopupStore();

  async function openPublicMedia({ id, channel, t, replaceModal }) {
    const params = {
      id,
      channel,
    };

    const query = {};
    if (t) {
      query.t = t;
    }
    if (replaceModal) {
      query.replaceModal = !!replaceModal;
    }

    const payload = {
      name: SOCIAL_LISTENING_ROUTE_NAMES.PUBLIC_MEDIA,
      params,
    };

    if (Object.keys(query).length > 0) {
      payload.query = query;
    }

    context.value = { id, channel, t };

    await router.push(payload);
  }

  function updateContextListIndex(media) {
    if (!media || !mediaPopupStore.contextList) {
      contextListIndex.value = null;
      return;
    }

    contextListIndex.value = mediaPopupStore.contextList.findIndex(
      (item) => item?.sourceId === media?.sourceId,
    );
  }

  const hasPreviousMedia = computed(() => {
    if (mediaPopupStore.contextList?.length > 0) {
      return contextListIndex.value > 0;
    }
    return false;
  });

  const hasNextMedia = computed(() => {
    if (mediaPopupStore.contextList?.length > 0) {
      return contextListIndex.value < mediaPopupStore.contextList.length - 1;
    }
    return false;
  });

  function loadPreviousPublicMedia() {
    if (!hasPreviousMedia.value) {
      return null;
    }

    contextListIndex.value -= 1;
    const previousMedia = mediaPopupStore.contextList[contextListIndex.value];

    const id = previousMedia.sourceId;
    const channel = formatChannelForPublicMediaRoute(previousMedia.source);

    openPublicMedia({
      id,
      channel,
      replaceModal: true,
    });

    return previousMedia;
  }

  function loadNextPublicMedia() {
    if (!hasNextMedia.value) {
      return null;
    }

    contextListIndex.value += 1;
    const nextMedia = mediaPopupStore.contextList[contextListIndex.value];

    const id = nextMedia.sourceId;
    const channel = formatChannelForPublicMediaRoute(nextMedia.source);

    openPublicMedia({
      id,
      channel,
      replaceModal: true,
    });

    return nextMedia;
  }

  function getMediaFromContextList({ id, channel }) {
    return mediaPopupStore.contextList.find(
      (media) => id === media?.sourceId && channel?.toUpperCase?.() === media?.source,
    );
  }

  return {
    context,
    openPublicMedia,
    hasPreviousMedia,
    hasNextMedia,
    loadPreviousPublicMedia,
    loadNextPublicMedia,
    updateContextListIndex,
    getMediaFromContextList,
  };
}
