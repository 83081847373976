<script setup>
import { computed } from 'vue';
import Insights from '@/components/common/MediaPopupV2/Insights.vue';
import {
  formatDynamicDuration,
  formatNumber,
  formatPercent,
  TIME_UNIT_OPTIONS,
} from '@/utils/formatters';
import { MEDIA_TYPE_OPTIONS } from '@/app/library/constants';
import { CHANNELS } from '@/models/dashboards/channels.enum';
import { toolTips } from '@/config';

// TODO: Once we start moving to media popup v2, most of the constants and functions in this file should be moved into a generic util file
// Props
const props = defineProps({
  mediaItem: {
    type: Object,
    default: () => {
      return {};
    },
  },
});

// Data
const source = CHANNELS.LINKEDIN.value;
const metricFormats = {
  NUMBER: 'number',
  PERCENT: 'percent',
  TIME: 'time',
};
const metricConfig = {
  clickThroughRate: {
    label: 'Click Through Rate',
    tooltip: toolTips.linkedinInsights.clickThroughRate,
    field: 'clickThroughRate',
    format: metricFormats.PERCENT,
  },
  clicks: {
    label: 'Clicks',
    tooltip: toolTips.linkedinInsights.clicks,
    field: 'clicks',
    format: metricFormats.NUMBER,
  },
  comments: {
    label: 'Comments',
    tooltip: toolTips.linkedinInsights.comments,
    field: 'comments',
    format: metricFormats.NUMBER,
  },
  engagementRate: {
    label: 'Engagement Rate',
    tooltip: toolTips.linkedinInsights.engagementRate,
    field: 'engagementRate',
    format: metricFormats.PERCENT,
  },
  engagements: {
    label: 'Engagements',
    tooltip: toolTips.linkedinInsights.engagements,
    field: 'engagements',
    format: metricFormats.NUMBER,
  },
  impressions: {
    label: 'Impressions',
    tooltip: toolTips.linkedinInsights.impressions,
    field: 'impressions',
    format: metricFormats.NUMBER,
  },
  reactions: {
    label: 'Reactions',
    tooltip: toolTips.linkedinInsights.reactions,
    field: 'likes',
    format: metricFormats.NUMBER,
  },
  shares: {
    label: 'Shares',
    tooltip: null,
    field: 'shares',
    format: metricFormats.NUMBER,
  },
  uniqueImpressions: {
    label: 'Unique Impressions',
    tooltip: toolTips.linkedinInsights.uniqueImpressions,
    field: 'uniqueImpressions',
    format: metricFormats.NUMBER,
  },
  videoViews: {
    label: 'Video Views',
    tooltip: toolTips.linkedinInsights.videoViews,
    field: 'videoViews',
    format: metricFormats.NUMBER,
    requiredMediaType: MEDIA_TYPE_OPTIONS.VIDEO.value,
  },
  watchTime: {
    label: 'Watch Time',
    tooltip: toolTips.linkedinInsights.watchTime,
    field: 'watchTime',
    format: metricFormats.TIME,
    requiredMediaType: MEDIA_TYPE_OPTIONS.VIDEO.value,
  },
};

// Methods
function formatValue(rawValue, format) {
  switch (format) {
    case metricFormats.NUMBER:
      return formatNumber(rawValue);
    case metricFormats.PERCENT:
      return formatPercent(rawValue);
    case metricFormats.TIME:
      return formatDynamicDuration(rawValue, TIME_UNIT_OPTIONS.MILLISECOND);
    default:
      throw Error('Metric format must be supplied');
  }
}

// Computed
const insights = computed(() => {
  return Object.values(metricConfig).reduce((acc, fieldData) => {
    if (fieldData?.requiredMediaType && props.mediaItem.mediaType !== fieldData.requiredMediaType) {
      return acc;
    }
    const rawValue = props.mediaItem.insights[fieldData.field];
    fieldData.value = formatValue(rawValue, fieldData.format);
    acc.push({
      value: fieldData.value,
      label: fieldData.label,
      tooltip: fieldData.tooltip,
    });
    return acc;
  }, []);
});

defineExpose({ insights });
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <Insights :insights="insights" :platform-icon="source" />
</template>
