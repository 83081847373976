import merge from 'lodash/merge';
import isNil from 'lodash/isNil';
import { createAxiosInstance } from '@/apis/axios.utils';
import { env } from '@/env';

export const axios = createAxiosInstance(env.socialListeningApiUrl, {
  camelizeKeys: true,
});

export async function getTopicStats(
  { organizationId, topicId, searchFilters, useEstimates, scale },
  axiosConfig = {},
) {
  const url = `/organizations/${organizationId}/stats`;
  const payload = { topicId, searchFilters, useEstimates, scale };
  return axios.post(url, payload, { ...axiosConfig });
}

export async function getInstagramStats(
  { organizationId, searchFilters, useEstimates, scale },
  axiosConfig = {},
) {
  const url = `/organizations/${organizationId}/stats/instagram`;
  const payload = { searchFilters, useEstimates, scale };

  return axios.post(url, payload, { ...axiosConfig });
}

export async function getTopicsUsage(organizationId, axiosConfig = {}) {
  const url = `/organizations/${organizationId}/topics/usage`;
  return axios.get(url, axiosConfig);
}

export async function getTopics(organizationId, summary, axiosConfig = {}) {
  const url = `/organizations/${organizationId}/topics`;
  return axios.get(url, { ...axiosConfig, summary });
}

export async function getTopic({ organizationId, topicId }, axiosConfig = {}) {
  const url = `/organizations/${organizationId}/topics/${topicId}`;
  return axios.get(url, axiosConfig);
}

export async function updateTopic({ organizationId, topicId }, payload, axiosConfig = {}) {
  const url = `/organizations/${organizationId}/topics/${topicId}`;
  return axios.patch(url, payload, axiosConfig);
}

export async function deleteTopic({ organizationId, topicId }, axiosConfig = {}) {
  const url = `/organizations/${organizationId}/topics/${topicId}`;
  return axios.delete(url, axiosConfig);
}

export async function createTopic(
  { organizationId, name, audienceType, selectedBrandId, searchBody, meta },
  axiosConfig = {},
) {
  const url = `/organizations/${organizationId}/topics`;
  const payload = { name, audienceType, selectedBrandId, searchBody, meta };
  return axios.post(url, payload, axiosConfig);
}

export async function getTopKeywords({ organizationId, payload, useEstimates }, axiosConfig = {}) {
  const fullPayload = { ...payload, ...useEstimates };
  const url = `/organizations/${organizationId}/top_keywords`;
  return axios.post(url, fullPayload, axiosConfig);
}

export async function getSearchResults({ organizationId, payload }, axiosConfig = {}) {
  const url = `/organizations/${organizationId}/search`;
  return axios.post(url, payload, axiosConfig);
}

export async function getTimeSeriesPostVolume(
  { organizationId, payload, useEstimates },
  axiosConfig = {},
) {
  const fullPayload = { ...payload, ...useEstimates };
  const url = `/organizations/${organizationId}/time_series/post_volume`;
  return axios.post(url, fullPayload, axiosConfig);
}

export async function getParseTree({ organizationId, payload }, axiosConfig = {}) {
  const url = `/organizations/${organizationId}/search/parse_tree`;
  return axios.post(url, payload, axiosConfig);
}

export async function getPostVolume({ organizationId, payload, useEstimates }, axiosConfig = {}) {
  const fullPayload = { ...payload, ...useEstimates };
  const url = `/organizations/${organizationId}/post_volume`;
  return axios.post(url, fullPayload, axiosConfig);
}

export async function getVisualTrends({ organizationId, payload }, axiosConfig = {}) {
  const url = `/organizations/${organizationId}/visual_trends`;
  return axios.post(url, payload, axiosConfig);
}

export async function getTrendGroup(
  { organizationId, trendId, offset = null, limit = null },
  axiosConfig = {},
) {
  const url = `/organizations/${organizationId}/visual_trends/${trendId}`;
  let requestConfig;
  if (isNil(offset) || isNil(limit)) {
    requestConfig = axiosConfig;
  } else {
    requestConfig = merge({ params: { offset, limit } }, axiosConfig);
  }
  return axios.get(url, requestConfig);
}

export async function getWebResults(
  { organizationId, topicId, limit = null, offset = null },
  axiosConfig = {},
) {
  const url = `/organizations/${organizationId}/topics/${topicId}/websearch_results`;
  return axios.get(url, merge({ params: { limit, offset }, axiosConfig }));
}

export async function getTopicMonitors({ organizationId, topicId }, axiosConfig = {}) {
  const url = `/organizations/${organizationId}/topics/${topicId}/monitors`;
  return axios.get(url, axiosConfig);
}

export async function createTopicMonitor(
  { organizationId, topicId, alertRecipients, muted, sensitivity },
  axiosConfig = {},
) {
  const url = `/organizations/${organizationId}/topics/${topicId}/monitors`;
  return axios.post(
    url,
    {
      alertRecipients,
      muted,
      sensitivity,
    },
    axiosConfig,
  );
}

export async function updateTopicMonitor(
  { organizationId, topicId, topicMonitorId, alertRecipients, muted, sensitivity },
  axiosConfig = {},
) {
  const url = `/organizations/${organizationId}/topics/${topicId}/monitors/${topicMonitorId}`;
  return axios.patch(
    url,
    {
      alertRecipients,
      muted,
      sensitivity,
    },
    axiosConfig,
  );
}

export async function getTopicMonitorEvents(
  { organizationId, topicId, monitorId, startDate, endDate },
  axiosConfig = {},
) {
  const url = `/organizations/${organizationId}/topics/${topicId}/monitors/${monitorId}/monitor_events`;
  const requestConfig = merge({ params: { startDate, endDate } }, axiosConfig);
  return axios.get(url, requestConfig);
}

export async function getTeaserTopKeywords(
  { organizationId, payload, useEstimates },
  axiosConfig = {},
) {
  const fullPayload = { ...payload, ...useEstimates };
  const url = `/teasers/organizations/${organizationId}/top_keywords`;
  return axios.post(url, fullPayload, axiosConfig);
}

export async function getTeaserStats(
  { organizationId, topicId, searchFilters, useEstimates, scale },
  axiosConfig = {},
) {
  const url = `/teasers/organizations/${organizationId}/stats`;
  const payload = { topicId, searchFilters, useEstimates, scale };
  return axios.post(url, payload, { ...axiosConfig });
}

export async function getTeaserSearchResults({ organizationId, payload }, axiosConfig = {}) {
  const url = `/teasers/organizations/${organizationId}/search`;
  return axios.post(url, payload, axiosConfig);
}

export async function getTeaserPostVolume(
  { organizationId, payload, useEstimates },
  axiosConfig = {},
) {
  const fullPayload = { ...payload, ...useEstimates };
  const url = `/teasers/organizations/${organizationId}/post_volume`;
  return axios.post(url, fullPayload, axiosConfig);
}

export async function getTeaserVisualTrends({ organizationId, payload }, axiosConfig = {}) {
  const url = `/teasers/organizations/${organizationId}/visual_trends`;
  return axios.post(url, payload, axiosConfig);
}

export async function getTeaserTrendGroup(
  { organizationId, trendId, offset = null, limit = null },
  axiosConfig = {},
) {
  const url = `/teasers/organizations/${organizationId}/visual_trends/${trendId}`;
  let requestConfig;
  if (isNil(offset) || isNil(limit)) {
    requestConfig = axiosConfig;
  } else {
    requestConfig = merge({ params: { offset, limit } }, axiosConfig);
  }
  return axios.get(url, requestConfig);
}

export async function getTeaserTimeSeriesPostVolume(
  { organizationId, payload, useEstimates },
  axiosConfig = {},
) {
  const fullPayload = { ...payload, ...useEstimates };
  const url = `/teasers/organizations/${organizationId}/time_series/post_volume`;
  return axios.post(url, fullPayload, axiosConfig);
}

export async function getSuggestedTopicsForOrganization(organizationId, axiosConfig = {}) {
  const url = `/organizations/${organizationId}/suggested_topics`;
  return axios.get(url, axiosConfig);
}

export async function createTopicFromSuggestion(
  { organizationId, suggestionId, sourceCreated },
  axiosConfig = {},
) {
  const url = `/organizations/${organizationId}/suggestions/${suggestionId}/topics`;
  return axios.post(url, { sourceCreated }, axiosConfig);
}
