import humps from 'humps';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import { UserEventTracker } from '@/utils/analytics/tracking';

export class MediaPopupUserEventTracker extends UserEventTracker {
  EVENT_NAME = {
    MEDIA_POPUP_OPENED: 'Media Pop-up Opened',
    MEDIA_POPUP_INTERACTION: 'Media Pop-Up Interaction',
    MEDIA_POPUP_SEE_ON_PLATFORM: {
      TIKTOK: 'TikTok Pop Up View TikTok',
      FACEBOOK: 'Facebook View Post',
      TWITTER: 'Twitter View Tweet',
      YOUTUBE: 'YouTube Pop Up View YouTube',
    },
    MEDIA_UPLOAD_SUMMARY: 'Media Upload Summary',
    MEDIA_UPLOAD_BEGIN: 'Library Media Uploader Begin Upload Clicked',
    MEDIA_UPLOAD_CLOSE: 'Library Media Uploader Close Clicked',
    LIBRARY_UPLOAD_CLICKED: 'Library Upload Clicked',
  };

  sharedProperties(media, route) {
    const queryFilters = { ...humps.camelizeKeys(route.query) };
    const { origin } = queryFilters;

    const paid = !isEmpty(media.boostedData);
    const sourceType = paid
      ? 'Instagram Owned Boosted Post'
      : startCase(media?.sourceType?.toLowerCase?.() ?? '');

    return {
      paid,
      mediaSource: startCase(media?.postType?.toLowerCase?.() ?? ''),
      sourceType,
      mediaType: startCase(media?.mediaType?.toLowerCase?.() ?? ''),
      mediaOpened: media.id,
      pageOpenedFrom: origin || route.name,
    };
  }

  popupOpened(media, route, howPopupOpen, extra = {}) {
    this.trackingStore.track(this.EVENT_NAME.MEDIA_POPUP_OPENED, {
      ...this.sharedProperties(media, route),
      ...extra,
      howPopupOpen,
    });
  }

  interaction(media, route, section, action, details) {
    this.trackingStore.track(this.EVENT_NAME.MEDIA_POPUP_INTERACTION, {
      ...this.sharedProperties(media, route),
      action,
      section,
      ...details,
    });
  }

  seeOnPlatformClicked(media, route, platform) {
    const event = this.EVENT_NAME.MEDIA_POPUP_SEE_ON_PLATFORM[platform.toUpperCase()];
    if (event) {
      this.trackingStore.track(event, {
        mediaID: media.id,
        'Clicked From': 'Button',
        URL: media.postUrl,
        Page: route.meta.analytics,
      });
    }
  }

  mediaUploadSummary(
    totalMediaUpload,
    brandIdsIncluded,
    brandNamesIncluded,
    brandTagIdsIncluded,
    brandTagNamesIncluded,
    brandTagNamesAmount,
    approvedPublishDateIncluded,
    approvedPublishDateTimeZone,
    publishStartDate,
    publishEndDate,
    numberOfBrands,
    contentTagAdded,
  ) {
    this.trackingStore.track(this.EVENT_NAME.MEDIA_UPLOAD_SUMMARY, {
      totalMediaUpload,
      brandIdsIncluded,
      brandNamesIncluded,
      brandTagIdsIncluded,
      brandTagNamesIncluded,
      brandTagNamesAmount,
      approvedPublishDateIncluded,
      approvedPublishDateTimeZone,
      publishStartDate,
      publishEndDate,
      numberOfBrands,
      multiBrandUploading: numberOfBrands > 1,
      contentTagAdded,
    });
  }

  mediaBeginUploadClicked({ mediaAdded }) {
    this.trackingStore.track(this.EVENT_NAME.MEDIA_UPLOAD_BEGIN, {
      mediaAdded,
    });
  }

  mediaUploaderClosed({ buttonClicked }) {
    this.trackingStore.track(this.EVENT_NAME.MEDIA_UPLOAD_CLOSE, {
      buttonClicked,
    });
  }

  libraryUploadClicked() {
    this.trackingStore.track(this.EVENT_NAME.LIBRARY_UPLOAD_CLICKED);
  }
}
