import { computed } from 'vue';
import { dashboardsRouteName } from '@/app/dashboards/routes';
import { SOCIAL_LISTENING_ROUTE_NAMES } from '@/app/socialListening/constants';
import { useRoute } from 'vue-router';
import { communityRouteName } from '@/app/community/routes';
import { getStartAndEndDate } from '@/app/socialListening/utils/api-request-utils';
import isEqual from 'lodash/isEqual';
import { useSocialListeningStore } from '@/stores/social-listening';
import { useCommunityInteractionStore } from '@/stores/community-interactions';
import { useSocialListeningTrendsStore } from '@/stores/social-listening-trends';

export function useVisionAiSourceLinks() {
  const route = useRoute();
  const listeningStore = useSocialListeningStore();
  const socialListeningTrendsStore = useSocialListeningTrendsStore();
  const communityInteractionStore = useCommunityInteractionStore();

  const isDashboardsRoute = computed(() => {
    return route.name?.startsWith(dashboardsRouteName);
  });

  const isTrendsRoute = computed(() => {
    return route.name === SOCIAL_LISTENING_ROUTE_NAMES.TRENDS;
  });

  const isTopicsRoute = computed(() => {
    return route.name === SOCIAL_LISTENING_ROUTE_NAMES.TOPICS;
  });

  const isCommunityRoute = computed(() => {
    return route.name.startsWith(communityRouteName);
  });

  function checkTrendsSourceParamsEqual(sourceUrl) {
    if (!isTrendsRoute.value) {
      return false;
    }
    const sourceQuery = sourceUrl.query;
    return Object.keys(socialListeningTrendsStore.filters).every((key) => {
      if (key === 'customDateRange') {
        // Don't worry about this key
        return true;
      }
      if (key === 'dateRange') {
        // Formats are different for this key, because dash web uses consts and the link uses
        // actual datetimes. Need to convert const to datetime range
        let { startDate, endDate } = getStartAndEndDate(socialListeningTrendsStore.filters[key]);
        if (!(startDate && endDate)) {
          startDate = socialListeningTrendsStore.filters.customDateRange.onOrAfter;
          endDate = socialListeningTrendsStore.filters.customDateRange.onOrBefore;
        }

        return isEqual(
          { startDate, endDate },
          {
            endDate: sourceQuery.onOrBefore,
            startDate: sourceQuery.onOrAfter,
          },
        );
      }
      return isEqual(socialListeningTrendsStore.filters[key], sourceQuery[key]);
    });
  }

  function checkTopicSourceParamsEqual(sourceUrl) {
    if (!isTopicsRoute.value) {
      return false;
    }
    const sourceQuery = sourceUrl.query;
    const currentFilters = listeningStore.filters;
    const mediaTypesEqual = isEqual(sourceQuery.mediaTypes, currentFilters.mediaTypes?.includes);
    const visualFiltersEqual =
      isEqual(
        sourceQuery.includeVisualDataSourceAndId,
        currentFilters.visualData?.includes || [],
      ) &&
      isEqual(sourceQuery.excludeVisualDataSourceAndId, currentFilters.visualData?.excludes || []);
    const { onOrAfter, onOrBefore } = sourceQuery;
    const formattedSourceCreated = {
      onOrAfter: currentFilters.sourceCreated.onOrAfter.replace('T', ' '),
      onOrBefore: currentFilters.sourceCreated.onOrBefore.replace('T', ' '),
    };

    const dateRangeEqual = isEqual({ onOrAfter, onOrBefore }, formattedSourceCreated);
    const sourcesEqual = isEqual(currentFilters?.sources || [], sourceQuery.sources || []);
    const sourceSentiment = {
      includeNegative: sourceQuery.includeNegative,
      includeNeutral: sourceQuery.includeNeutral,
      includePositive: sourceQuery.includePositive,
    };
    const sourceHasSentiment = Object.values(sourceSentiment).some((value) => !!value);
    const sentimentEqual = isEqual(
      currentFilters?.sentiment,
      sourceHasSentiment ? sourceSentiment : undefined,
    );
    return (
      mediaTypesEqual && visualFiltersEqual && dateRangeEqual && sourcesEqual && sentimentEqual
    );
  }

  function checkCommunitySourceParamsEqual(sourceUrl) {
    if (!isCommunityRoute.value) {
      return false;
    }
    const sourceQuery = sourceUrl.query;
    return sourceQuery.id === communityInteractionStore.selectedCommunityInteractionId;
  }

  return {
    isDashboardsRoute,
    isTrendsRoute,
    isTopicsRoute,
    isCommunityRoute,
    checkTrendsSourceParamsEqual,
    checkTopicSourceParamsEqual,
    checkCommunitySourceParamsEqual,
  };
}
