<template>
  <section class="media-detail-filter">
    <header>
      <Icon :name="iconName" small />
      {{ filterName }}
    </header>
    <main>
      <div
        v-for="(filter, index) in filterOptions"
        :key="index"
        :class="['filter', { size: filterName === 'size' }]"
        @click.prevent="handleClick(filter, index)"
      >
        {{ filter }}
        <Icon
          v-if="currentFilter === filter || (!currentFilter && index === 0)"
          :color="checkColor"
          name="check"
          small
        />
      </div>
    </main>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'SizeFilter',
  components: {
    Icon,
  },
  props: {
    iconName: { type: String, default: null },
    filterName: { type: String, default: null },
    filterOptions: { type: Array, default: null },
    mediaTypeLimit: { type: String, default: null },
  },
  emits: ['filterSelected'],
  data() {
    return {
      checkColor: colours.ACTION.ACTION_500,
      currentFilter: this.filterOptions[0],
    };
  },
  methods: {
    updateFilter(index) {
      if (index === 'any') {
        this.currentFilter = 'any';
      } else if (index < this.filterOptions.length) {
        this.currentFilter = this.filterOptions[index];
      }
    },
    handleClick(selection, index) {
      if (this.mediaTypeLimit) {
        return;
      }
      const { currentFilter } = this;
      if (currentFilter !== selection) {
        this.currentFilter = selection;
        this.$emit('filterSelected', index);
      } else {
        this.currentFilter = 'any';
        this.$emit('filterSelected', null);
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.media-detail-filter {
  padding: var(--space-24) var(--space-24) 0;
}

header {
  display: flex;
  align-items: center;
  color: var(--text-secondary);
  text-transform: capitalize;

  .svg-icon {
    margin-right: var(--space-24);
  }
}

main {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 0 var(--space-40);
  margin-left: var(--space-4);
}

.filter {
  flex: 1 1 50%;
  display: flex;
  align-items: center;
  padding: var(--space-8) 0 0 0;
  cursor: pointer;
  text-transform: capitalize;
  transition: var(--transition-all);

  .svg-icon {
    margin-left: var(--space-16);
    margin-right: 0;
  }
}

.size {
  text-transform: none;

  &:first-child {
    text-transform: capitalize;
  }
}

.filter:hover {
  color: var(--action-500);
}
</style>
