import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import { logger } from '@/utils/logger';
import * as VisionAiAPI from '@/apis/vision-ai';
import {
  enrichMessages,
  processMessages,
  getStartTimeForApiRequest,
  skipCamelizeMessageKeysOnDashboardReportsAdditionalContent,
} from '@/utils/vision-ai';
import sortBy from 'lodash/sortBy';
import { SUMMARY_INTENT_TYPES } from '@/components/VisionAi/constants';
import { useIdentityStore } from '@/stores/identity';
import { useNotificationStore } from '@/stores/notification';
import { VISION_AI_SENDER } from '@/config';
import { useVisionAiConversationsStore } from '@/stores/vision-ai-conversations';
import {
  assistantMessageReceivedEvent,
  userMessageSentEvent,
} from '@/components/VisionAi/utils/mixpanel-events';

export const useVisionAiMessagesStore = defineStore('visionAiMessagesStore', () => {
  const identityStore = useIdentityStore();
  const notificationStore = useNotificationStore();
  const visionAiConversationsStore = useVisionAiConversationsStore();

  const pending = ref({
    messages: false,
  });
  const messages = ref([]);
  const regeneratingMessageId = ref(null);
  const anySummaryLoaded = ref(false);
  const initMessagesLoaded = ref(false);
  const messagesListNextUrl = ref(null);
  const summaryLoading = ref(false);

  const UPDATE_ACTION = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
  };

  function addLoadingStates({ intentType, sender, conversationId }) {
    const isSummary = SUMMARY_INTENT_TYPES.includes(intentType);
    const isUserMessage = sender === VISION_AI_SENDER.USER;

    if (isSummary && isUserMessage) {
      summaryLoading.value = true;
      anySummaryLoaded.value = true;
    } else if (isUserMessage) {
      visionAiConversationsStore.addLoadingConversationIds(conversationId);
    }
  }

  async function createMessage({
    conversationId,
    sender,
    message,
    intentType,
    intentQuery,
    actionType,
    usedPrompt,
  }) {
    addLoadingStates({ intentType, sender, conversationId });
    try {
      const response = await VisionAiAPI.createMessage({
        conversationId,
        sender,
        message,
        intentType,
        intentQuery,
        actionType,
      });
      if (sender === VISION_AI_SENDER.USER) {
        userMessageSentEvent({
          messageSent: message,
          messageId: response?.data?.id,
          intentType,
          usedPrompt,
          followUps: response?.data?.userMessageCount,
          conversationId,
        });
      }
      return response?.data;
    } catch (error) {
      logger.error('Failed to create Vision AI message', {}, error);
      throw error;
    }
  }

  async function regenerateMessage({ conversationId, messageId, messageIntentType }) {
    addLoadingStates({ intentType: messageIntentType, sender: 'USER', conversationId });
    try {
      await VisionAiAPI.regenerateMessage({
        conversationId,
        messageId,
      });
    } catch (error) {
      logger.error('Failed to regenerate Vision AI message', {}, error);
      throw error;
    }
  }

  async function updateMessage({ messageId, regeneratedId }) {
    try {
      const response = await VisionAiAPI.updateMessage({
        messageId,
        regeneratedId,
      });
      return response.data;
    } catch (error) {
      logger.error('Failed to update Vision AI message', {}, error);
      throw error;
    }
  }

  function setRegenerateMessageId(RegenerateMessageId) {
    regeneratingMessageId.value = RegenerateMessageId;
  }

  function setMessages({ newMessages = [], toBeginning = false, toIndex = 0 }) {
    if (toBeginning) {
      messages.value.unshift(...newMessages);
    } else if (toIndex > 0) {
      messages.value.splice(toIndex, 1, newMessages);
    } else {
      messages.value = newMessages;
    }
  }

  async function getMessages({ limit = 30, offset = 0, startTime = null, intentTypes = null }) {
    pending.value.messages = true;

    if (intentTypes && intentTypes.length > 0) {
      visionAiConversationsStore.setSummariesSelectedValue(
        intentTypes.every((intentType) => SUMMARY_INTENT_TYPES.includes(intentType)),
      );
    }
    const messagesStartTime = getStartTimeForApiRequest(startTime);
    const delimitedIntentTypes = intentTypes?.join(',');
    try {
      const response = await VisionAiAPI.getMessages({
        startTime: messagesStartTime,
        limit,
        offset,
        intentTypes: delimitedIntentTypes,
      });
      const messageList = processMessages(response.data.data);
      // add messages at beginning of array as we are scrolling up the chat window
      setMessages({
        newMessages: messageList,
        toBeginning: true,
      });
      messagesListNextUrl.value = response.data.paging?.next;
      if (!initMessagesLoaded.value) {
        initMessagesLoaded.value = true;
      }
      if (messageList.length > 0) anySummaryLoaded.value = true;
    } catch (error) {
      logger.error('Failed to get Vision AI messages', {}, error);
      setMessages({});
      notificationStore.setToast({
        message: 'Failed to get Vision AI messages',
        type: 'error',
      });
    } finally {
      pending.value.messages = false;
    }
  }
  async function getMessageById(id, params = { includeDeleted: false }) {
    try {
      const response = await VisionAiAPI.getMessageById(id, params);
      const { data: fetchedMessage } = response;
      const messageToReplaceIndex = messages.value.findIndex(
        (msg) => msg.id === fetchedMessage?.id,
      );
      const processedMessage = processMessages([fetchedMessage])[0];
      setMessages({
        newMessages: processedMessage,
        toIndex: messageToReplaceIndex,
      });

      return processedMessage;
    } catch (error) {
      logger.error('Failed to get Vision AI message by id', {}, error);
      return messages.value.find((msg) => msg.id === id);
    }
  }

  function sortMessages() {
    const newMessages = sortBy(messages.value, [
      (message) => message.createdAt,
      (message) => message.id,
    ]);
    setMessages({ newMessages });
  }
  function removeLoadingStates({ isSummary, sender, conversationId }) {
    const isAssistantMessage = sender === VISION_AI_SENDER.ASSISTANT;

    if (isSummary && isAssistantMessage) {
      summaryLoading.value = false;
    } else if (isAssistantMessage) {
      visionAiConversationsStore.removeLoadingConversationIds(conversationId);
    }
  }
  function clearMessageFromHistory(messageId) {
    const newMessages = messages.value.filter((message) => message.id !== messageId);
    setMessages({ newMessages });
  }

  function addMessageToChatWindow(newMsg) {
    const isSummary = SUMMARY_INTENT_TYPES.includes(newMsg.intentType);
    if (isSummary) anySummaryLoaded.value = true;
    removeLoadingStates({
      isSummary,
      sender: newMsg.sender,
      conversationId: newMsg.conversationId,
    });

    const messageIsInCurrentTab =
      newMsg.conversationId === visionAiConversationsStore.currentConversationId ||
      (visionAiConversationsStore.summariesSelected && isSummary);
    if (!messageIsInCurrentTab) return;

    // to support multiple browser tabs open with different message list states, check if msg exists
    const currentMessageIds = new Set(messages.value.map((msg) => msg.id));

    if (!currentMessageIds.has(newMsg.id)) {
      const newMessages = [...messages.value, ...enrichMessages([newMsg])];
      setMessages({ newMessages });

      // re-sort message list to ensure order as USER and ASSISTANT messages are received independently
      sortMessages();
    }
  }

  function onClearMessagesNotification(clearMsgNotification) {
    if (clearMsgNotification.error) {
      notificationStore.setToast({
        message: `Failed to delete Vision AI chat history.`,
        type: 'error',
      });
      return;
    }

    setMessages({});
  }

  function onMessageCreatedNotification(messageData) {
    messageData.updates.forEach((msgUpdate) => {
      // convert keys to camelcase to match msgs in store
      let message = msgUpdate.data;
      message = skipCamelizeMessageKeysOnDashboardReportsAdditionalContent([message])[0];
      if (msgUpdate.action === UPDATE_ACTION.CREATE) {
        if (JSON.stringify(message).includes('updates')) {
          logger.error(`Suspicious payload for message - ${JSON.stringify(message)}`);
        }
        addMessageToChatWindow(message);
        const targetConversation = visionAiConversationsStore.conversations.find((conversation) => {
          return conversation.id === message.conversationId;
        });
        assistantMessageReceivedEvent({
          message,
          conversation: targetConversation,
        });
      }
    });
  }

  watch(
    () => identityStore.currentBrand,
    () => {
      // Re-enrich messages after brand change, so that source links are updated
      if (messages.value && messages.value.length > 0) {
        setMessages({ newMessages: enrichMessages(messages.value) });
      }
    },
  );

  return {
    pending,
    messages,
    regeneratingMessageId,
    setRegenerateMessageId,
    setMessages,
    getMessages,
    getMessageById,
    sortMessages,
    clearMessageFromHistory,
    addMessageToChatWindow,
    onClearMessagesNotification,
    removeLoadingStates,
    onMessageCreatedNotification,
    UPDATE_ACTION,
    createMessage,
    regenerateMessage,
    updateMessage,
    summaryLoading,
    anySummaryLoaded,
    messagesListNextUrl,
  };
});
