import {
  BarElement,
  BarController,
  Chart,
  LineController,
  DoughnutController,
  LineElement,
  PointElement,
  ArcElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  Filler,
  Tooltip,
} from 'chart.js';
import '@/components/foundation/charts/chartjs-adapter-dayjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { WordCloudController, WordElement } from 'chartjs-chart-wordcloud';
import { colours } from '@/ux/colours';
// eslint-disable-next-line import/no-named-as-default
import Legend from '@/components/foundation/charts/chartjs.legend';

export const DEFAULT_TIME_FORMAT = 'MMM D';
export const TIME_FORMATS = {
  day: 'MMM D',
  month: 'MMMM YYYY',
};

Chart.register(
  BarElement,
  BarController,
  LineController,
  DoughnutController,
  WordCloudController,
  WordElement,
  LineElement,
  PointElement,
  ArcElement,
  LinearScale,
  CategoryScale,
  TimeScale,
  Filler,
  Tooltip,
  ChartDataLabels,
  Legend,
);

Chart.defaults.font.family = "'InterVariable', 'Arial', sans-serif";
Chart.defaults.font.size = 10;
Chart.defaults.color = colours.TEXT.TEXT_SECONDARY;

Chart.defaults.hover.mode = 'nearest';
Chart.defaults.hover.intersect = false;

Chart.defaults.maintainAspectRatio = false;

Chart.defaults.layout.padding.top = 2;
Chart.defaults.layout.padding.left = 2;
Chart.defaults.layout.padding.bottom = 2;
Chart.defaults.layout.padding.right = 2;

Chart.defaults.datasets.line.tension = 0.4;
Chart.defaults.datasets.line.borderColor = colours.CHART.SET.CHART_SET_01;
Chart.defaults.datasets.line.borderWidth = 2.5;
Chart.defaults.datasets.line.pointRadius = 5;
Chart.defaults.datasets.line.pointBackgroundColor = colours.CHART.SET.CHART_SET_01;
Chart.defaults.datasets.line.pointBorderColor = 'white';
Chart.defaults.datasets.line.pointBorderWidth = 3;
Chart.defaults.datasets.line.pointHoverBackgroundColor = 'white';
Chart.defaults.datasets.line.pointHoverBorderColor = colours.CHART.SET.CHART_SET_01;
Chart.defaults.datasets.line.pointHoverBorderWidth = 3;

Chart.defaults.datasets.bar.borderRadius = 3;
Chart.defaults.datasets.bar.maxBarThickness = 50;

Chart.defaults.plugins.legend.display = false;
