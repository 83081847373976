<script setup>
import { computed } from 'vue';
import { useAuthStore } from '@/stores/auth';
import dayjs from 'dayjs';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';
import UserAvatar from '@/components/core/UserAvatar.vue';
import { removeApprovalRequestMessage, APPROVAL_STATUS } from '@/app/scheduler/constants';
import { useNotificationStore } from '@/stores/notification';
import { getUserTimezone } from '@/utils/timezone';

const props = defineProps({
  review: {
    type: Object,
    default: null,
  },
  removeReviewer: {
    type: Function,
    required: true,
  },
});

const authStore = useAuthStore();
const notificationStore = useNotificationStore();

const reviewer = computed(() => {
  return authStore.brandAccessibleUsers?.find((user) => {
    return user.id === props.review?.reviewUserId;
  });
});

const fullName = computed(() => {
  return `${reviewer.value?.firstName} ${reviewer.value?.lastName}`;
});

const reviewerStatus = computed(() => {
  return props.review?.approvalStatus || APPROVAL_STATUS.PENDING;
});

const canRemoveReviewer = computed(() => {
  return !('approvalStatus' in props.review);
});

const isApproved = computed(() => {
  return props.review?.approvalStatus === APPROVAL_STATUS.APPROVED;
});

const handleRemoveReviewer = async (name, id) => {
  const { title, message } = removeApprovalRequestMessage(name);
  await notificationStore.confirm(title, message, {
    confirmAlias: 'Remove',
    onConfirm: () => props.removeReviewer(id, true),
  });
};
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div class="approval-status-item">
    <div class="current-account text-small single-brand user-tag">
      <UserAvatar :user="reviewer" class="avatar" small />
      <div class="brand-name">
        {{ fullName }}
      </div>
    </div>
    <div class="approval-status-chip">
      <div
        :class="`circle ${props.review?.approvalStatus === APPROVAL_STATUS.APPROVED ? 'approved' : 'pending'}`"
      />
      <span class="status">{{ reviewerStatus }}</span>
      <Icon
        v-if="canRemoveReviewer"
        class="remove"
        :color="colours.TEXT.TEXT_SECONDARY"
        name="close"
        @click="() => handleRemoveReviewer(fullName, reviewer.id)"
      />
    </div>
  </div>
  <div v-if="isApproved" class="approved-on">
    Approved on {{ dayjs(props.review.updatedAt).tz(getUserTimezone()).format('MMM DD h:mmA') }}
  </div>
</template>

<style lang="postcss" scoped>
.approval-status-item {
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
}

.user-tag {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-top: var(--space-12);
}

.brand-name {
  margin-left: var(--space-8);
  font-weight: 500;
}

.approval-status-chip {
  background-color: #fff;
  border-radius: var(--space-8);
  padding: 0 var(--space-6) 0 var(--space-6);
  display: flex;
  align-items: center;
  height: 20px;
  margin-right: var(--space-20);
  margin-top: var(--space-12);

  .circle {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    margin-right: var(--space-5);
  }

  .pending {
    background-color: #f5a623;
  }

  .approved {
    background-color: var(--success-500);
  }

  .status {
    font-size: var(--x10);
    font-weight: 500;
    text-transform: lowercase;
  }

  .status::first-letter {
    text-transform: capitalize;
  }

  .remove {
    height: var(--space-8) !important;
    width: var(--space-8) !important;
    margin-left: var(--space-5);

    &:hover {
      cursor: pointer;
    }
  }
}

.approved-on {
  display: flex;
  align-self: stretch;
  margin-top: var(--space-5);
  font-size: var(--x12);
  font-weight: 400;
  color: #adadad;
}
</style>
