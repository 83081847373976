<script setup>
/**
 * Video preview component that shows a list of thumbnails sampled from a video.
 *
 * Fast version only works in Chrome, slow version is served for all other browsers. See each
 * component for more details.
 */
import FastVideoPreview from './FastVideoPreview.vue';
import SlowVideoPreview from './SlowVideoPreview.vue';

defineProps({
  media: { type: Object, required: true },
  duration: { type: Number, required: true },
  height: { type: Number, default: 56 },
  width: { type: Number, default: 356 },
});
const isChrome = !!window.chrome;
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <FastVideoPreview
    v-if="isChrome"
    :media="media"
    :duration="duration"
    :height="height"
    :width="width"
  />
  <SlowVideoPreview v-else :media="media" :duration="duration" :height="height" :width="width" />
</template>
