<template>
  <SchedulePostPopupWrapper
    :close="close"
    :close-confirmation="confirmation"
    :post="post"
    :show-post-editor-side-panel="showPostEditorSidePanel"
    :linked-comment-id="linkedCommentId"
    :avatar="connection && connection.avatar_url"
    :display-name="(connection && connection.email_or_handle) || ''"
    :has-unsaved-changes="hasUnsavedChanges"
    platform="twitter"
    class="twitter-scheduling"
  >
    <template #media>
      <TweetStatus
        v-for="(tweet, i) in tweets"
        :key="tweet[UUID]"
        :tweet="tweet"
        :is-expanded="i === expandedTweetIndex"
        :error="errorByTweetUuid[tweet[UUID]]"
        :disable-editing="disabled"
        :referenced-objects="referencedObjects"
        :thread-length="tweets.length"
        :thread-position="i + 1"
        :show-number="tweets.length > 1"
        :auto-publish="postData.autoPublish"
        :max-tweet-length="maxTweetLength"
        :alt-text-media-map="referencedMediaAltText"
        :save-alt-text="saveAltText"
        :can-publish-within="canPublishWithin"
        @update:status="tweet.tweetStatus = $event"
        @update:media="updateTweetMedia(tweet, $event)"
        @delete="deleteTweetAtIndex(i)"
        @add="addTweetAtIndex(i + 1)"
        @expand="expandedTweetIndex = i"
        @media-upload-error="onMediaUploadError(tweet, $event)"
        @upload-status-changed="uploadStatusChanged(tweet, $event)"
        @show-utm-panel="onShowUtmPanel"
      >
        <template #text-controls>
          <CheckBox
            id="shorten-links"
            :key="tweet.id"
            v-model="shortenLinkCheckbox"
            class="mt-2"
            label="Shorten Links"
            @update:tweet="tweet.tweetStatus = $event"
          />
        </template>

        <template #edit-utm-controls>
          <Button
            :disabled="!utmPreviewLink"
            class="mt-2 text-left"
            link
            @click="() => triggerUtmPanel(tweet)"
            >{{ utmScheduleOpenEditTrackingButton }}</Button
          >
        </template>
      </TweetStatus>
      <Banner
        v-if="publishDateRangeWarning"
        :alert-type="publishDateRangeWarning.level"
        class="validation-banner"
      >
        {{ publishDateRangeWarning.message }}
      </Banner>
    </template>

    <template #details>
      <template v-if="currentScreen === 'index'">
        <PostStatusBox
          v-if="originalExpandedTweet"
          :post="{
            ...originalExpandedTweet,
            platform: 'twitter',
            approvalPolicy: post?.approvalPolicy,
          }"
        />
        <section v-if="!replyExpanded">
          <PublishOptionHeader
            :disabled="isDraftToggleDisabled"
            :is-on="isDraft"
            :post="post"
            @drafts-toggled="(draft) => onToggleDraft(draft)"
          />
          <AutoPublishOptions
            v-model="postData.autoPublish"
            :disabled="disabled"
            :disable-send-notification="disableSendNotification"
            platform="twitter"
            :post="post"
          />
          <PostSettingItem
            :input-tooltip="scheduleTimeTooltip"
            :disabled="disabled || !isAppConnected || approvedPublishingDatesExpired"
            :title="scheduledTimeLabel"
            :clear-action="resetTime"
            :on-click="() => switchSubScreen('schedule')"
            :error-message="scheduledTimeError"
            empty="Schedule Date & Time"
            left-icon="calendar"
          />
          <QuickSelectCalendar
            v-if="!disabled && isAppConnected && !approvedPublishingDatesExpired"
            ref="quickSelectCalendar"
            :class="{ 'pt-4': publishDateError }"
            :model-value="postData.timestamp"
            :restrict-selection-to-interval="canPublishWithin"
            :platform="platformLabels.twitter"
            @update:model-value="timeSelected"
          />
          <PostSettingItem
            v-if="hasApprovalRequestsAccess"
            :title="getApprovalRequestTitle({ post })"
            :on-click="
              () =>
                schedulerStore.toggleSidePanel({
                  router: $router,
                  platform: 'twitter',
                  post,
                })
            "
            data-cy="approval-request-setting-item"
            empty="Add Reviewers"
            left-icon="chatBubbleSquareCheck"
            label="Approval Request"
            :approval-status-badge="getApprovalChipText({ post, approvalRequests })"
          />
        </section>
        <section v-if="tweets.length > 1">
          <p class="section-title">Thread settings:</p>
          <CheckBox
            v-model="postData.autoNumberingEnabled"
            :disabled="disabled"
            label="Number Automatically"
          />
        </section>
        <PostSettingItem
          :input-tooltip="locationDisabledTooltip"
          :disabled="disableEditing || locationDisabledByTwitterAPI"
          :title="locationLabel"
          :clear-action="resetLocation"
          empty="Add Location"
          left-icon="location"
          label="Location"
          @click="switchSubScreen('location')"
        />
        <template v-if="!replyExpanded">
          <PostSettingItem
            :disabled="disabled"
            :title="addToLabel"
            :clear-action="resetAddTo"
            :on-click="() => switchSubScreen('addTo')"
            empty="After Published, Add to"
            left-icon="folderAdd"
            :label="addToAfterLabel"
          />
        </template>
      </template>
      <!-- Add location subscreen -->
      <AddLocation
        v-if="currentScreen === 'location'"
        :loading="schedulerStore.pending.twitterLocationSearchList"
        :location="location"
        :on-location-selected="locationSelected"
        has-search-button
        title-key="full_name"
        sub-title-key="country"
        platform="twitter"
      />

      <!-- Select post time subscreen -->
      <SubScreen
        v-if="currentScreen === 'schedule'"
        title="Choose Date & Time"
        @on-back="switchSubScreen('index')"
      >
        <template #content>
          <div>
            <Calendar
              :posts="schedulerStore.getPosts(brandTwitterPosts)"
              :on-fetch-posts="onCalendarFetch"
              :time-selected="timeSelected"
              :restrict-selection-to-interval="canPublishWithin"
              :ideal-post-times="bestTimesToPost"
              platform="twitter"
              type="compact"
            />
          </div>
        </template>
      </SubScreen>

      <!-- Select board to add to subscreen -->
      <AddToGallery
        v-if="currentScreen === 'addTo'"
        :selected-galleries="selectedGalleries"
        :on-galleries-selected="onGalleriesSelected"
        :on-back="() => switchSubScreen('index')"
        type="TWITTER"
      />
      <Controls v-if="currentScreen === 'index'" class="controls">
        <Button
          v-if="!disabled && post && post.id && !replyExpanded"
          v-tooltip="'Remove from Scheduler'"
          remove
          class="remove"
          data-cy="twitter-remove-button"
          @click="onRemoveClick"
        >
          Remove
        </Button>
        <SplitButton
          v-if="!disableEditing"
          :tooltip="saveDisabled?.message || saveDisabled"
          :disabled="!!saveDisabled"
          :loading="schedulerStore.postActionPending || isSaving"
          :options="saveOptions"
          class="save-button"
          primary
          @click="save"
        >
          Save
        </SplitButton>
        <DropdownButton
          v-else-if="showDuplicateButton"
          :dropdown-list="duplicatePostOptions"
          :dropdown-list-styles="{ 'margin-top': 'var(--space-4)' }"
          scrollable-list
          button-classes="primary"
          items-center
          data-cy="duplicate-post-button"
        >
          <template #buttonContent>Duplicate Post</template>
        </DropdownButton>
      </Controls>
    </template>

    <teleport v-if="showUtmPanel" to="#SchedulerRightSidePanel">
      <UtmEditorPanel
        class="w-80"
        :url="utmPreviewLink"
        :channel="utmChannel"
        :brand-channel-utm-settings="utmSettings"
        :tracking-data="trackingData"
        @close="() => triggerUtmPanel(null)"
        @tracking-parameters-updated="
          (newUrl, trackingParameters, urlLinkCustomized) =>
            updateLinkForTweet(expandedTweetIndex, newUrl, urlLinkCustomized)
        "
      />
    </teleport>
  </SchedulePostPopupWrapper>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent, defineAsyncComponent } from 'vue';
import { mapState as mapPiniaState, mapStores } from 'pinia';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import twitter from 'twitter-text';
import { v4 as uuidv4 } from 'uuid';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import {
  discardCommentConfirmMessage,
  discardConfirmMessage,
  maxTweetLength,
  maxTweetLengthWithAutoNumbering,
  toolTips,
  maxMentionsPerTweet,
} from '@/config';
import {
  PLATFORMS,
  popUpMessages,
  postStatus,
  somethingWentWrongTitle,
  somethingWentWrongMessage,
  twitterMentionLimitExceededText,
  tweetTooLongText,
  emptyTweetInThreadText,
  emptyTweetText,
  twitterNotConnectedText,
  tweetRemovedFromTimeline,
  tweetRemovedFromUnscheduled,
  tweetUpdatedAndAddedToTimeline,
  tweetAddedToTimeline,
  tweetIsBeingSaved,
  unknownApiErrorMessage,
  mixpanelMentionWarningType,
  noDuplicationForTwitterThreads,
  ORIGINAL,
  UUID,
  mixpanelActions,
  MAX_ALT_TEXT_LENGTH,
  altTextRemovedNotification,
  errorAltTextTooLong,
  altTextSavedNotification,
  CROSS_BRAND_DUPLICATE_ERROR,
  platformLabels,
} from '@/app/scheduler/constants';
import PostDuplicationMixin from '@/mixins/postDuplicationMixin';
import PostSettingItemLabelMixin from '@/app/scheduler/mixins/PostSettingItemLabelMixin';
import ApprovedPublishingDatesMixin from '@/app/scheduler/mixins/ApprovedPublishingDatesMixin';
import { getUnscheduledRouteName, twitterMentionLimitExceeded } from '@/app/scheduler/utils';
import { validatePostMedia } from '@/app/scheduler/utils/mediaValidation';

import SplitButton from '@/components/foundation/SplitButton.vue';
import Button from '@/components/foundation/Button.vue';
import CheckBox from '@/components/foundation/CheckBox.vue';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import SchedulePostPopupWrapper from '@/app/scheduler/components/EditPost/Layout/SchedulePostPopupWrapper.vue';
import { useCommentingStore } from '@/stores/commenting';
import { useSchedulerStore } from '@/stores/scheduler';
import { allPosts } from '@/app/scheduler/utils/post-query';
import { useTwitterStore } from '@/stores/twitter';
import {
  SchedulerUserEventTracker,
  trackSchedulerUtmPopout,
  postTypeLabelMap,
} from '@/app/scheduler/mixpanel';
import { usePlatformStore } from '@/stores/platform';
import { mapById } from '@/utils';
import { useMediaStore } from '@/stores/media';
import { useFlagStore } from '@/stores/flag';
import { useSocketStore } from '@/stores/socket';
import UtmEditorPanel from '@/app/settings/components/Utm/UtmEditorPanel.vue';
import { LibraryAPI } from '@/apis';
import {
  URL_REGEX,
  utmChannel,
  utmScheduleOpenEditTrackingButton,
} from '@/app/settings/components/Utm/const';
import { fetchUtmSettings, extractAllUrlsFromString } from '@/app/settings/components/Utm/utils';
import Banner from '@/components/foundation/feedback/Banner.vue';
import { useImpersonatorStore } from '@/stores/impersonator';
import { CROSS_BRAND_DUPLICATE_PLATFORMS } from '@/utils/postDuplication';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import {
  getApprovalChipText,
  getApprovalRequestTitle,
  postRequiresApproval,
} from '@/app/scheduler/utils/approvals';
import PublishOptionHeader from '@/app/scheduler/components/PublishOptionHeader.vue';
import { refreshBestTimesToPost } from '@/app/scheduler/utils/bestTimesToPost';
import AddToGallery from './AddToGallery.vue';
import AddLocation from './AddLocation.vue';
import PostSettingItem from '../PostSettingItem.vue';
import SubScreen from './Layout/SubScreen.vue';
import Controls from './Layout/Controls.vue';
import PostStatusBox from '../PostStatusBox.vue';
import AutoPublishOptions from '../AutoPublishOptions.vue';
import TweetStatus from './Layout/TweetStatus.vue';

const selectPostData = (post, altTweetStatus = null) => ({
  [UUID]: uuidv4(),
  [ORIGINAL]: post,
  id: post?.id,
  autoPublish: post?.autoPublish ?? false,
  tweetStatus: altTweetStatus ?? post?.tweetStatus ?? '',
  timestamp: post?.timestamp ?? null,
  autoNumberingEnabled: post?.autoNumberingEnabled ?? true,
  media: [...(post?.media ?? [])],
  mediaIds: [...(post?.mediaIds ?? [])],
  boardIds: [...(post?.boardIds ?? [])],
  campaignIds: [...(post?.campaignIds ?? [])],
  platform: PLATFORMS.TWITTER,
  locationId: post?.locationId ?? null,
  altTextMediaMap: post?.altTextMediaMap ?? {},
  replies: (post?.replies ?? []).map((reply) => ({
    [UUID]: uuidv4(),
    [ORIGINAL]: reply,
    id: reply.id,
    tweetStatus: reply.tweetStatus,
    mediaIds: [...(reply.mediaIds ?? [])],
    locationId: reply.locationId ?? null,
    altTextMediaMap: reply.altTextMediaMap ?? {},
  })),
});

const getMentionedUsernames = (tweet) =>
  [...(tweet.tweetStatus ?? '').matchAll(/(^|[^(@\w)]@*)@(\w+)((?=[^\w@])|$)/g)].map(
    (match) => match[2],
  );

const getLocations = (tweet) =>
  (tweet?.location ? [tweet.location] : []).concat((tweet?.replies ?? []).flatMap(getLocations));

const getMedia = (tweet) => (tweet?.media ?? []).concat((tweet?.replies ?? []).flatMap(getMedia));

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'EditTwitterPost',
  components: {
    PublishOptionHeader,
    Banner,
    AddLocation,
    AddToGallery,
    AutoPublishOptions,
    Button,
    Calendar: defineAsyncComponent(() => import('../Calendar.vue')),
    CheckBox,
    Controls,
    DropdownButton,
    PostSettingItem,
    PostStatusBox,
    QuickSelectCalendar: defineAsyncComponent(
      () => import('@/app/scheduler/components/QuickSelectCalendar.vue'),
    ),
    SchedulePostPopupWrapper,
    SplitButton,
    SubScreen,
    TweetStatus,
    UtmEditorPanel,
  },
  mixins: [PostDuplicationMixin, PostSettingItemLabelMixin, ApprovedPublishingDatesMixin],
  props: {
    close: { type: Function, required: true },
    context: { type: String, default: null },
    disableEditing: { type: Boolean, default: false },
    media: { type: Array, default: null },
    mediaCaption: { type: String, default: null },
    post: { type: Object, default: null },
    showPostEditorSidePanel: { type: Boolean, default: false },
    linkedCommentId: { type: Number, default: null },
    duplicatedFrom: { type: String, default: null },
  },
  data() {
    const postData = selectPostData(this.post, this.mediaCaption);
    return {
      postData,
      originalPostData: Object.freeze(cloneDeep(postData)),
      referencedObjects: {
        media: mapById(getMedia(this.post)),
        location: mapById(getLocations(this.post)),
        board: mapById(this.post?.boards ?? []),
        campaign: mapById(this.post?.campaigns ?? []),
      },
      uploadPending: false,
      expandedTweetIndex: 0,
      currentScreen: 'index',
      isSaving: false,
      isValidating: false,
      mediaErrorsById: {},
      mediaUploadErrorsByTweetUuid: {},
      uploadPendingByTweetUuid: {},
      mediaValidationErrorsByUuid: {},
      postDuplicationData: null,
      mentionWarningTypes: [],
      showUtmPanel: false,
      shortenLinksIsChecked: false,
      shortenLinkCheckbox: false,
      utmSettings: null,
      utmPreviewLink: '',
      utmChannel: utmChannel.TWITTER,
      tweetForUtmIndex: {},
      utmScheduleOpenEditTrackingButton,
      isUtmEncodeDecodePending: false,
      urlLinkCustomized: false,
      shouldKeepDuplicatedLink: this.duplicatedFrom === PLATFORMS.FACEBOOK || false,
      approvalRequests: (this.post && this.post.approval_requests) || [],
      isDraft: (!this.post?.id && !this.post?.timestamp) || this.post?.status === postStatus.DRAFT,
    };
  },
  computed: {
    ...mapStores(
      useCustomerJourneyStore,
      useCommentingStore,
      useSchedulerStore,
      useTwitterStore,
      useNotificationStore,
      usePlatformStore,
      useMediaStore,
      useFlagStore,
      useSocketStore,
      useTrackingStore,
      useImpersonatorStore,
    ),
    ...mapPiniaState(useAuthStore, ['currentBrand', 'user_can', 'brand_can']),
    hasApprovalRequestsAccess() {
      return !this.customerJourneyStore.hasPlanGrow;
    },
    mediaList() {
      return this.threadMediaIds.map((mediaId) => this.referencedObjects.media[mediaId]);
    },
    scheduledTime() {
      return this.postData.timestamp;
    },
    disabled() {
      return this.disableEditing || this.isSaving || this.isUtmEncodeDecodePending;
    },
    UUID() {
      return UUID;
    },
    originalExpandedTweet() {
      if (this.post?.id === this.postData?.id) return this.expandedTweet?.[ORIGINAL];
      return this.post;
    },
    threadMediaIds() {
      return this.tweets.flatMap((tweet) => tweet.mediaIds);
    },
    replyExpanded() {
      return this.expandedTweetIndex > 0;
    },
    disableSendNotification() {
      return this.tweets.length > 1 && toolTips.twitter.sendNotificationDisabledForThreads;
    },
    locationDisabledTooltip() {
      return !this.disableEditing ? toolTips.twitter.disabledLocationToolTip : null;
    },
    locationDisabledByTwitterAPI() {
      return true;
    },
    hasUnsavedChanges() {
      return !isEqual(this.postData, this.originalPostData);
    },
    tweets() {
      const tweets = [this.postData, ...this.postData.replies];
      tweets.forEach((tweet) => {
        tweet.media = tweet.mediaIds.map((mediaId) => this.referencedObjects.media[mediaId]);
      });
      return Object.freeze(tweets);
    },
    expandedTweet() {
      return this.tweets[this.expandedTweetIndex];
    },
    mentionedUsernames() {
      return this.tweets.flatMap(getMentionedUsernames);
    },
    brandTwitterPosts() {
      return allPosts.forBrand(this.currentBrand.id).forPlatform(PLATFORMS.TWITTER);
    },
    selectedGalleries: {
      get() {
        return [
          ...this.postData.boardIds.map((id) => this.referencedObjects.board[id]),
          ...this.postData.campaignIds.map((id) => ({
            ...this.referencedObjects.campaign[id],
            campaign: true,
          })),
        ];
      },
      set(to) {
        const boards = to.filter((g) => !g.campaign);
        const campaigns = to.filter((g) => g.campaign);
        this.referencedObjects.board = {
          ...this.referencedObjects.board,
          ...mapById(boards),
        };
        this.referencedObjects.campaign = {
          ...this.referencedObjects.campaign,
          ...mapById(campaigns),
        };
        this.postData.boardIds = boards.map((b) => b.id);
        this.postData.campaignIds = campaigns.map((c) => c.id);
      },
    },
    addToLabel() {
      if (this.selectedGalleries.length) {
        return this.selectedGalleries.map((g) => g.name).join(', ');
      }
      return null;
    },
    emptyTweetError() {
      const someEmptyTweet = this.tweets.some(
        (tweet) => tweet.mediaIds.length === 0 && !tweet.tweetStatus,
      );
      return someEmptyTweet && (this.tweets.length > 1 ? emptyTweetInThreadText : emptyTweetText);
    },
    scheduleTimeTooltip() {
      if (!this.isAppConnected) {
        return toolTips.noTwitterConnection;
      }
      return '';
    },
    timestampAddedOrRemoved() {
      return !this.postData.timestamp !== !this.post?.timestamp;
    },
    confirmation() {
      if (this.hasUnsavedChanges) {
        return discardConfirmMessage;
      }
      if (this.commentingStore?.hasUnsavedComments) {
        return discardCommentConfirmMessage;
      }
      return null;
    },
    title() {
      if (this.disableEditing) {
        return 'Published Tweet';
      }
      return `${this.post && this.post.id ? 'Edit' : 'Add'} Tweet`;
    },
    maxTweetLength() {
      return this.tweets.length > 1 && this.postData.autoNumberingEnabled
        ? maxTweetLengthWithAutoNumbering
        : maxTweetLength;
    },
    tweetLengthExceededByTweetUuid() {
      return Object.fromEntries(
        this.tweets.map((tweet) => [
          tweet[UUID],
          parseInt(twitter.parseTweet(tweet.tweetStatus).weightedLength, 10) > this.maxTweetLength,
        ]),
      );
    },
    location: {
      get() {
        return this.referencedObjects.location[this.expandedTweet?.locationId];
      },
      set(to) {
        if (this.expandedTweet) {
          if (to) {
            this.referencedObjects.location[to.id] = to;
            this.expandedTweet.locationId = to.id;
          } else {
            this.expandedTweet.locationId = null;
          }
        }
      },
    },
    locationLabel() {
      return this.location?.full_name;
    },
    scheduledTimeLabel() {
      const { timestamp } = this.postData;
      return timestamp && dayjs(timestamp).format('h:mm A, MMM D, YYYY');
    },
    timestampIsPast() {
      return this.postData.timestamp && this.postData.timestamp < new Date();
    },
    mentionLimitExceededError() {
      return (
        this.mentionedUsernames.length > maxMentionsPerTweet &&
        (this.tweets.length > 1
          ? toolTips.twitter.tooManyMentionsPerThread
          : toolTips.twitter.tooManyMentionsPerTweet)
      );
    },
    errorByTweetUuid() {
      return Object.fromEntries(
        this.tweets.map((tweet) => {
          const uuid = tweet[UUID];
          const tweetMedia = tweet.mediaIds.map((mediaId) => this.referencedObjects.media[mediaId]);
          let mediaError = null;
          if (
            this.mediaValidationErrorsByUuid[uuid] &&
            typeof this.mediaValidationErrorsByUuid[uuid][0] !== 'undefined'
          ) {
            mediaError = this.mediaValidationErrorsByUuid[uuid][0];
          }
          const mentionLimitError =
            getMentionedUsernames(tweet).length > 0 && this.mentionLimitExceededError;
          const tweetTooLongError = this.tweetLengthExceededByTweetUuid[uuid] && tweetTooLongText;
          const someTweetMediaHavePublishingDates = tweetMedia.some(
            (media) => media?.canPublishWithin,
          );
          const publishDateError =
            someTweetMediaHavePublishingDates && this.post?.status !== postStatus.IMPORTED
              ? this.publishDateError
              : null;
          const altTextError =
            Object.values(tweet?.altTextMediaMap)?.some((altText) => altText.length > 1000) &&
            errorAltTextTooLong;

          const overallTweetError =
            publishDateError ||
            this.mediaUploadErrorsByTweetUuid[uuid] ||
            mentionLimitError ||
            tweetTooLongError ||
            altTextError ||
            mediaError;

          const hasCrossBrandDuplicationErr = overallTweetError === CROSS_BRAND_DUPLICATE_ERROR;
          Object.values(this.referencedObjects.media).forEach((mediaItem) => {
            if (
              mediaItem?.brandId &&
              mediaItem.brandId !== this.authStore.currentBrand.id &&
              !this.mediaStore.pending.createMediaV2
            ) {
              this.mediaErrorsById[mediaItem.id] = CROSS_BRAND_DUPLICATE_ERROR;
            }
          });

          const formattedError =
            overallTweetError && typeof overallTweetError === 'string'
              ? {
                  message: overallTweetError,
                  level: overallTweetError?.level || 'error',
                  ...(hasCrossBrandDuplicationErr && {
                    icon: 'alertTriangle',
                    mediaIds: this.mediaErrorsById,
                  }),
                }
              : overallTweetError;

          return [uuid, formattedError];
        }),
      );
    },
    saveDisabled() {
      const individualTweetError = this.tweets.reduce(
        (error, tweet) =>
          error ||
          (this.errorByTweetUuid[tweet[UUID]]?.level === 'error' &&
            this.errorByTweetUuid[tweet[UUID]]),
        null,
      );

      return (
        (this.uploadPending && toolTips.mediaUploading) ||
        individualTweetError ||
        this.emptyTweetError ||
        (!this.postData.autoPublish && this.disableSendNotification) ||
        (this.timestampIsPast && toolTips.illegalTimeStamp) ||
        (!this.isAppConnected && twitterNotConnectedText) ||
        (this.isSaving && tweetIsBeingSaved) ||
        !!this.publishDateError
      );
    },
    publishNowDisabled() {
      const isDisabledDueToInsufficientPermission =
        // Brand permission
        !this.brand_can('scheduler', 'can_auto_publish_twit') ||
        // User permission
        !this.user_can('scheduler', 'can_auto_publish_twit') ||
        this.impersonatorStore.isImpersonating;
      return (
        (isDisabledDueToInsufficientPermission && toolTips.insufficientPermission) ||
        (!this.postData.autoPublish && toolTips.notAutoPublish) ||
        (!this.canAssetPublishToday && this.publishNowWarning) ||
        (this.requiresApproval(this.post) && toolTips.requiresApproval)
      );
    },
    duplicateDisabled() {
      return this.tweets.length > 1 && noDuplicationForTwitterThreads;
    },
    showDuplicateButton() {
      return this.disableEditing && !this.postData?.replies?.length > 0;
    },
    duplicatePostOptions() {
      const crossBrandDisabled = this.pdCrossBrandDuplicationDisabled(
        this.post,
        this.postData.media,
      );
      return this.crossBrandDuplicatePostsFlagSet
        ? [
            {
              text: 'To Channel',
              subList: this.pdDuplicationOptions(this.duplicate),
            },
            ...(this.canCrossBrandDuplicatePost
              ? [
                  {
                    text: 'To Brand',
                    subList: this.duplicateToBrandSubList(this.post),
                    disabled: crossBrandDisabled.isDisabled,
                    tooltip: crossBrandDisabled.tip,
                  },
                ]
              : []),
          ]
        : this.pdDuplicationOptions(this.duplicate);
    },
    saveOptions() {
      return [
        {
          text: 'Save & Duplicate',
          disabled: this.duplicateDisabled,
          tooltip: this.duplicateDisabled,
          subList: this.pdDuplicationOptions(this.saveAndDuplicate),
        },
        {
          text: 'Publish Now',
          action: this.publishNow,
          disabled: this.publishNowDisabled,
          tooltip: this.publishNowDisabled,
        },
        {
          text: 'Publish Now & Duplicate',
          disabled: this.publishNowDisabled || this.duplicateDisabled,
          tooltip: this.publishNowDisabled || this.duplicateDisabled,
          subList: this.pdDuplicationOptions(this.publishNowAndDuplicate),
        },
      ];
    },
    connection() {
      return this.platformStore.platformConnectionsMap.twitter?.[this.currentBrand.id];
    },
    isAppConnected() {
      return this.connection && this.connection.status === 'connected';
    },
    trackMixpanel() {
      return new SchedulerUserEventTracker('Twitter');
    },
    postPayload() {
      return {
        ...this.postData,
        replies: this.postData.replies.map((reply, i) => ({
          ...reply,
          altTextMediaMap: this.mapAltTextToMedia(reply.mediaIds),
          threadOrder: i,
        })),
        altTextMediaMap: this.mapAltTextToMedia(this.postData.mediaIds),
        platform: PLATFORMS.TWITTER,
        brandId: this.currentBrand.id,
        callback_socket_id: this.socketStore.id,
        mentionedUsernames: this.mentionedUsernames,
        mentionWarningType: this.mentionWarningTypes,
        shorten_link: this.shortenLinkCheckbox,
        custom_utms: this.urlLinkCustomized,
        approvalRequests: this.approvalRequests,
        ...(this.isDraft && this.flagStore.ready && this.flagStore.flags.schedulerDrafts
          ? { status: postStatus.DRAFT }
          : {}),
        ...(this.post?.approvalPolicy ? { approval_policy: this.post.approvalPolicy } : {}),
      };
    },
    duplicatePostData() {
      return {
        ...this.postData,
        boards: this.selectedGalleries.filter((g) => !g.campaign),
        campaigns: this.selectedGalleries.filter((g) => g.campaign),
      };
    },
    crossBrandDuplicatePostsFlagSet() {
      return this.flagStore.ready && this.flagStore.flags.crossBrandDuplicatePosts;
    },
    canCrossBrandDuplicatePost() {
      return (
        CROSS_BRAND_DUPLICATE_PLATFORMS.includes(this.post?.platform) &&
        this.crossBrandDuplicatePostsFlagSet
      );
    },
    canAllowLongTwitterVideos() {
      return this.flagStore.ready && this.flagStore.flags.allowLongTwitterVideos;
    },
    trackingData() {
      return {
        postId: this.post?.id,
        postPlatform: 'Twitter',
        postType: 'Tweet',
      };
    },
    referencedMediaAltText() {
      let altTextMediaMap = this.postData?.altTextMediaMap ?? {};
      if (this.postData?.replies) {
        this.postData.replies.forEach((reply) => {
          altTextMediaMap = { ...altTextMediaMap, ...reply.altTextMediaMap };
        });
      }
      return altTextMediaMap;
    },
    platformLabels() {
      return platformLabels;
    },
    isDraftToggleDisabled() {
      const isPublished = [
        postStatus.POSTED,
        postStatus.AUTOPUBLISHING,
        postStatus.IMPORTED,
      ].includes(this.post?.status);
      return isPublished || !this.postPayload?.timestamp;
    },
    hasBestTimeToPostFlag() {
      return this.flagStore.ready && this.flagStore.flags.bestTimesToPost;
    },
    bestTimesToPost() {
      return this.hasBestTimeToPostFlag
        ? this.schedulerStore.recommendedPublishTimes?.[this.authStore.currentBrand.id]?.[
            PLATFORMS.TWITTER
          ]
        : [];
    },
  },
  watch: {
    mediaList: {
      deep: true,
      handler() {
        this.validateMedia(this.expandedTweet.media, this.expandedTweet[UUID]);
      },
    },
    'schedulerStore.autoPublish': {
      immediate: true,
      handler() {
        this.validateMedia(this.expandedTweet.media, this.expandedTweet[UUID]);
      },
    },
    'authStore.currentBrand': {
      immediate: true,
      handler(toBrand) {
        if (toBrand && this.media?.length && toBrand.id !== this.media?.[0]?.brandId) {
          this.updateTweetMedia(this.tweets?.[0], this.media);
        }
      },
    },
    async shortenLinkCheckbox(value) {
      if (!this.utmPreviewLink) return;
      if (value) {
        await this.encodeAllTweets();
      } else {
        await this.decodeAllTweets();
      }
    },
    async utmPreviewLink(value) {
      if (value && this.shortenLinksIsChecked) {
        if (!this.shouldKeepDuplicatedLink) {
          // If the post is from Facebook by the "publish and duplicate" option and has a shorten
          // link, we should not parse utm settings yet, because it will set the shorten link in the
          // original facebook post with the Twitter utm parameters incorrectly.
          await this.encodeAllTweets(false);
        } else {
          this.shouldKeepDuplicatedLink = false;
        }
      }
    },
    showUtmPanel(value) {
      if (value && this.showPostEditorSidePanel) this.closePostEditorSidePanel();
    },
    showPostEditorSidePanel(value) {
      if (value && this.showUtmPanel) this.triggerUtmPanel(null);
    },
    expandedTweetIndex(index) {
      const tweet = this.tweets[index];
      if (tweet) {
        this.onShowUtmPanel(tweet);
      }
      this.validateMedia(this.expandedTweet.media, this.expandedTweet[UUID]);
    },
    'schedulerStore.approvalRequests': {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.approvalRequests = newVal;
          this.postData.approvalRequests = this.approvalRequests;
        }
      },
    },
    threadMediaIds: {
      deep: true,
      immediate: true,
      async handler(to, from) {
        // Handle alt text on media delete
        if (from?.length > to?.length) {
          const removedMedia = from.filter((m) => !to.includes(m));
          if (removedMedia.length > 0) {
            removedMedia.forEach((mediaId) => {
              if (this.referencedMediaAltText[mediaId]) {
                this.saveAltText(mediaId, '');
              }
            });
          }
        }
      },
    },
    mentionLimitExceededError(to) {
      if (to) {
        if (!this.mentionWarningTypes.includes(mixpanelMentionWarningType.POST)) {
          this.mentionWarningTypes.push(mixpanelMentionWarningType.POST);
        }
        this.trackMixpanel.twitterMentionWarning(this.post?.id, mixpanelMentionWarningType.POST);
      }
    },
    mentionedUsernames: {
      immediate: true,
      handler(to) {
        this.fetchTwitterUsersDebounced(to);
      },
    },
    postPayload(to) {
      if (!to?.timestamp) this.isDraft = true;
    },
  },
  async created() {
    // Fetch best times to post if they're not already in the store
    refreshBestTimesToPost(this.authStore.currentBrand.id, PLATFORMS.TWITTER);

    try {
      await this.decodeAllTweets(false);
    } catch (error) {
      this.notificationStore.setToast({
        message: somethingWentWrongTitle,
        subtext: somethingWentWrongMessage,
        type: 'error',
      });
      this.close();
      return;
    }
    const firstTweet = this.tweets[0];
    if (firstTweet) {
      this.onShowUtmPanel(firstTweet);
    }
  },
  mounted() {
    if (
      ![postStatus.POSTED, postStatus.AUTOPUBLISHING, postStatus.IMPORTED].includes(
        this.post?.status,
      )
    ) {
      this.tweets.forEach((tweet) => {
        if (tweet.media && tweet.media.length > 0) this.validateMedia(tweet.media, tweet[UUID]);
      });
    }
  },
  methods: {
    getApprovalRequestTitle,
    getApprovalChipText,
    validateMedia(mediaList, tweetUUID) {
      this.isValidating = true;
      const validation = validatePostMedia(
        mediaList || [],
        PLATFORMS.TWITTER,
        this.schedulerStore.autoPublish,
      );
      this.mediaValidationErrorsByUuid[tweetUUID] = validation;
      this.isValidating = false;
    },
    mapAltTextToMedia(mediaIds) {
      const tweetAltTextMediaMap = {};
      mediaIds.forEach((m) => {
        if (this.referencedMediaAltText[m]) {
          tweetAltTextMediaMap[m] = this.referencedMediaAltText[m];
        }
      });
      return tweetAltTextMediaMap;
    },
    closePostEditorSidePanel() {
      this.$router.push({
        name: 'scheduler.twitter.posts',
        params: { id: this.post?.id, post: this.post },
        query: { replaceModal: true },
      });
    },
    onMediaUploadError(tweet, event) {
      this.mediaUploadErrorsByTweetUuid = { [tweet[UUID]]: event?.message };
    },

    uploadStatusChanged(tweet, status) {
      this.uploadPending = status;
      this.uploadPendingByTweetUuid[tweet[UUID]] = status;
    },

    updateTweetMedia(tweet, newMediaList) {
      this.referencedObjects.media = { ...this.referencedObjects.media, ...mapById(newMediaList) };
      tweet.mediaIds = newMediaList.map((m) => m.id);
      tweet.media = newMediaList.map((m) => m); // necessary for saveAndDuplicate proper operation, while for other save paths the id's would suffice
    },
    addTweetAtIndex(index) {
      const { replies } = this.postData;
      if (index <= 0 || index > replies.length + 1) {
        throw new Error(
          `Unable to add a new tweet at index ${index} in thread of length ${this.tweets.length}`,
        );
      }
      replies.splice(index - 1, 0, {
        [UUID]: uuidv4(),
        tweetStatus: '',
        mediaIds: [],
        locationId: null,
        altTextMediaMap: {},
      });
      this.expandedTweetIndex = index;
    },
    deleteTweetAtIndex(index) {
      const { replies } = this.postData;
      if (index <= 0 || index > replies.length) {
        throw new Error(
          `Unable to delete tweet at index ${index} in thread of length ${this.tweets.length}`,
        );
      }
      replies.splice(index - 1, 1);
      this.expandedTweetIndex = Math.min(replies.length, this.expandedTweetIndex);
    },
    fetchTwitterUsersDebounced: debounce(function fetchTwitterUsers(handles) {
      this.twitterStore.fetchUsers(handles, this.currentBrand.id);
    }, 350),
    duplicate({ toType }) {
      // Have to define duplicateData within these functions so computed values
      // are not lost when current post is closed.
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.TWITTER_TWEET,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.DUPLICATE_FROM_PUBLISHED_CHANNEL,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      this.pdDuplicatePostToChannel({
        fromType: this.DUPLICATION_POST_TYPES.TWITTER_TWEET,
        toType,
        post: duplicateData,
      });
    },
    async publishNowAndDuplicate({ toType }) {
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.TWITTER_TWEET,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.PUBLISH_NOW_AND_DUPLICATE,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      await this.publishNow(false);
      if (!duplicateData?.id) {
        duplicateData.id = this.schedulerStore.duplicationPostId;
      }
      this.pdDuplicatePostToChannel({
        fromType: this.DUPLICATION_POST_TYPES.TWITTER_TWEET,
        toType,
        post: duplicateData,
      });
    },
    async saveAndDuplicate({ toType }) {
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.TWITTER_TWEET,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.SAVE_AND_DUPLICATE,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      await this.save(false);
      if (!duplicateData?.id) {
        duplicateData.id = this.schedulerStore.duplicationPostId;
      }
      this.pdDuplicatePostToChannel({
        fromType: this.DUPLICATION_POST_TYPES.TWITTER_TWEET,
        toType,
        post: duplicateData,
      });
    },
    switchSubScreen(screen) {
      if (screen === 'schedule' && !this.isAppConnected) {
        return;
      }
      if (!this.disabled) {
        this.currentScreen = screen;
        if (screen === 'schedule') {
          this.trackingStore.track(mixpanelActions.SCHEDULER_CALENDAR_OPENED, {
            postPlatform: 'Twitter',
            quickSelectClicked: false,
          });
        }
      }
    },
    timeSelected(time) {
      if (!this.postData.timestamp) this.isDraft = false;
      this.postData.timestamp = time.toDate?.() ?? time;
      this.switchSubScreen('index');
    },
    locationSelected(location) {
      this.location = location;
      this.switchSubScreen('index');
    },
    onGalleriesSelected(galleries) {
      this.selectedGalleries = galleries;
    },
    resetAddTo() {
      this.selectedGalleries = [];
    },
    resetTime() {
      this.postData.timestamp = null;
    },
    resetLocation() {
      this.location = null;
    },
    async onRemoveClick() {
      const { title, message } = popUpMessages.removeFromScheduler;
      await this.notificationStore.confirm(title, message, {
        confirmAlias: 'Remove',
        onConfirm: this.remove,
      });
    },
    async remove() {
      await this.schedulerStore.deletePost(this.post);
      if (this.postData.timestamp) {
        this.notificationStore.setToast({ message: tweetRemovedFromTimeline });
      } else {
        this.notificationStore.setToast({
          message: tweetRemovedFromUnscheduled,
        });
      }
      this.close();
    },
    async decodeUtmForTweet(tweet, index, modifyContent = true) {
      const extractedUrls = extractAllUrlsFromString(tweet.tweetStatus).filter((url) => url);
      const requestBody = {
        brandId: this.currentBrand.id,
        channel: this.utmChannel,
        content: tweet.tweetStatus,
        urls: extractedUrls,
      };
      const utmResponse = await LibraryAPI.decodeUtmSettings(requestBody);
      const data = utmResponse.data;
      if (modifyContent) {
        tweet.tweetStatus = data.unshortened_content ?? data.original_content;
        this.updateLinkForTweet(index, data.unshortened_content);
      } else {
        this.shortenLinkCheckbox ||= data.has_shortened_link;
      }
      tweet.fullContent = data.unshortened_content;
      tweet.hasShortenedLink = data.has_shortened_link;
      this.shortenLinksIsChecked = data.has_shortened_link;
      tweet.originalContent = data.original_content;
      tweet.trackingParameters = data.tracking_parameters;
    },
    async decodeAllTweets(modifyContent = true) {
      this.isUtmEncodeDecodePending = true;
      await Promise.all(
        this.tweets.map((tweet, index) => this.decodeUtmForTweet(tweet, index, modifyContent)),
      );
      this.isUtmEncodeDecodePending = false;
    },
    async encodeAllTweets(disableEditor = true) {
      const utmEncodePromises = this.tweets.map((tweet, index) => {
        return this.encodeUtmForTweet(tweet, index);
      });
      if (disableEditor) this.isUtmEncodeDecodePending = true;
      try {
        await Promise.all(utmEncodePromises);
      } catch (error) {
        this.notificationStore.setToast({
          message: somethingWentWrongTitle,
          subtext: somethingWentWrongMessage,
          type: 'error',
        });
      }
      if (disableEditor) this.isUtmEncodeDecodePending = false;
    },
    async encodeUtmForTweet(tweet, index) {
      const url = this.tweetForUtmIndex[index];
      let customUtms = {};
      if (url) {
        customUtms = this.getCustomUtms(url);
      }
      const extractedUrls = extractAllUrlsFromString(tweet.tweetStatus).filter(
        (extractedUrl) => extractedUrl,
      );
      const requestBody = {
        brandId: this.currentBrand.id,
        content: tweet.tweetStatus,
        channel: this.utmChannel,
        customUtms,
        mediaId: this.threadMediaIds?.[0],
        shortenUrl: this.shortenLinkCheckbox ?? false,
        urls: extractedUrls,
      };

      const utmResponse = await LibraryAPI.encodeUtmSettings(requestBody);
      tweet.originalContent = tweet.tweetStatus;
      tweet.tweetStatus = utmResponse.data.content;
    },
    async save(closeAfter = true) {
      if (this.saveDisabled || this.twitterStore.postActionPending) {
        return;
      }
      if (this.publishDateError) {
        return;
      }
      this.isSaving = true;
      await this.encodeAllTweets();

      try {
        if (this.post?.id) {
          // Take value before updating post (as it then updates this.post)
          const timestampAddedOrRemoved = this.timestampAddedOrRemoved;
          await this.schedulerStore.updatePost(this.postPayload);

          if (timestampAddedOrRemoved) {
            if (this.postPayload.timestamp) {
              this.notificationStore.setToast({
                message: tweetUpdatedAndAddedToTimeline,
              });
            } else {
              this.notificationStore.setToast({
                message: 'Post updated and added to your ',
                actionText: 'unscheduled posts.',
                actionTo: {
                  name: getUnscheduledRouteName(PLATFORMS.TWITTER),
                },
                sameLine: true,
              });
            }
          } else {
            this.notificationStore.setToast({ message: 'Post updated.' });
          }
        } else {
          await this.schedulerStore.createPost(this.postPayload);

          if (this.postPayload.timestamp) {
            this.notificationStore.setToast({ message: tweetAddedToTimeline });
          } else {
            this.notificationStore.setToast({
              message: 'Post added to your ',
              actionText: 'unscheduled posts.',
              actionTo: {
                name: getUnscheduledRouteName(PLATFORMS.TWITTER),
              },
              sameLine: true,
            });
          }
        }

        if (closeAfter) {
          this.close();
        }
      } catch (error) {
        this.handlePostError(error);
      } finally {
        this.isSaving = false;
      }
    },
    async publishNow(closeAfter = true) {
      this.isSaving = true;

      await this.encodeAllTweets();

      try {
        const data = {
          ...this.postPayload,
          status: postStatus.AUTOPUBLISHING,
        };
        if (this.post?.id) {
          await this.schedulerStore.updatePost(data);
        } else {
          await this.schedulerStore.createPost(data);
        }

        if (closeAfter) {
          this.close();
        }
      } catch (error) {
        this.handlePostError(error);
      } finally {
        this.isSaving = false;
      }
    },
    async onCalendarFetch(start, end) {
      this.schedulerStore.fetchPosts(this.brandTwitterPosts.betweenDates(start, end));
    },
    showMentionLimitExceededToast() {
      this.notificationStore.setToast({
        message: somethingWentWrongTitle,
        subtext: twitterMentionLimitExceededText,
        buttonText: 'Got It',
        type: 'error',
        autoClear: false,
      });
      if (!this.mentionWarningTypes.includes(mixpanelMentionWarningType.DAY)) {
        this.mentionWarningTypes.push(mixpanelMentionWarningType.DAY);
      }
    },
    showUnknownErrorToast() {
      this.notificationStore.setToast({
        message: unknownApiErrorMessage,
        type: 'error',
      });
    },
    handlePostError(error) {
      if (twitterMentionLimitExceeded(error)) {
        this.showMentionLimitExceededToast();
        this.trackMixpanel.twitterMentionWarning(this.post?.id, mixpanelMentionWarningType.DAY);
      } else {
        this.showUnknownErrorToast();
      }
    },
    onToggleDraft(draft) {
      this.isDraft = draft;
      this.trackMixpanel.draftToggleClicked(draft, postTypeLabelMap.twitter);
    },
    saveAltText(mediaId, altText, originalMediaId = null) {
      const tweet =
        this.expandedTweetIndex > 0
          ? this.postData.replies[this.expandedTweetIndex - 1]
          : this.postData;
      // Handle alt text for cropped media
      if (this.referencedMediaAltText[originalMediaId] && !this.referencedMediaAltText[mediaId]) {
        tweet.altTextMediaMap = { [mediaId]: altText, ...tweet.altTextMediaMap };
        // If the original media before cropping is still used in other threads, don't delete it.
        if (!this.threadMediaIds.includes(originalMediaId)) {
          delete tweet.altTextMediaMap[originalMediaId];
        }
        return;
      }
      if (
        altText === tweet.altTextMediaMap[mediaId] ||
        (altText === '' && !tweet.altTextMediaMap[mediaId])
      )
        return;
      tweet.altTextMediaMap = Object.entries(tweet.altTextMediaMap).reduce(
        (prev, [mediaIdKey, altTextVal]) => ({
          ...prev,
          ...(mediaIdKey !== String(mediaId) ? { [mediaIdKey]: altTextVal } : {}),
        }),
        {},
      );
      if (altText === '') {
        this.updateNotification(altTextRemovedNotification);
      } else {
        tweet.altTextMediaMap = { [mediaId]: altText, ...tweet.altTextMediaMap };
        if (altText.length <= MAX_ALT_TEXT_LENGTH) {
          this.updateNotification(altTextSavedNotification);
        }
      }
    },
    updateNotification(message) {
      const currentNotification = this.notificationStore.toasts.find(
        (notification) => notification.message === message,
      );
      this.notificationStore.clearToasts({ id: currentNotification?.id });
      this.notificationStore.setToast({ message });
    },
    async triggerUtmPanel(tweet = null) {
      const tweetUrlSource = tweet?.originalContent ? tweet?.originalContent : tweet?.tweetStatus;
      if (this.utmSettings === null) {
        this.utmSettings = await this.getUtmSettings();
      }
      if (tweet) {
        tweetUrlSource.replace(URL_REGEX, (url) => {
          this.utmPreviewLink = url;
        });
      }
      if (!this.utmPreviewLink) {
        this.showUtmPanel = false;
      }
      this.showUtmPanel = !this.showUtmPanel;
      trackSchedulerUtmPopout({
        ...this.trackingData,
        action: this.showUtmPanel ? 'open' : 'close',
      });
    },
    getCustomUtms(newUrl) {
      const [newParams] = newUrl.replace(/.+\?(.+)/, '$1').split(' ');
      return newParams.split('&').reduce((initial, param) => {
        const [key, val] = param.split('=');
        initial[key] = val;
        return initial;
      }, {});
    },
    updateLinkForTweet(tweetIndex, newUrl, urlLinkCustomized = this.urlLinkCustomized) {
      this.tweetForUtmIndex[tweetIndex] = newUrl;
      this.urlLinkCustomized = urlLinkCustomized;
    },
    async onShowUtmPanel(tweet) {
      const tweetUrlSource = tweet?.originalContent ? tweet?.originalContent : tweet?.tweetStatus;
      const previewUrl = extractAllUrlsFromString(tweetUrlSource ?? '')[0];
      if (previewUrl) {
        this.utmPreviewLink = previewUrl;
        if (this.utmSettings === null) {
          this.utmSettings = await this.getUtmSettings();
        }
        if (!this.showUtmPanel) {
          trackSchedulerUtmPopout({
            ...this.trackingData,
            action: 'auto open',
          });
        }
      } else {
        this.utmPreviewLink = '';
        if (this.showUtmPanel) {
          trackSchedulerUtmPopout({
            ...this.trackingData,
            action: 'auto close',
          });
        }
      }
      this.showUtmPanel = Boolean(previewUrl);
    },
    async getUtmSettings() {
      return fetchUtmSettings({
        brandId: this.currentBrand.id,
        channel: this.utmChannel,
      });
    },
    requiresApproval(post) {
      return postRequiresApproval({ post });
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.controls {
  position: sticky;
  bottom: 0;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: calc(-1 * var(--space-24));
    right: 0;
    height: var(--space-24);
    background: linear-gradient(to bottom, rgba(255 255 255 / 0%), rgba(255 255 255 / 100%));
  }

  .save-button {
    min-width: 10rem;
  }
}
</style>
