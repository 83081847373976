<template>
  <!-- There are currently 9 types of media tiles:
       Library, Scheduler, Pin, Upload, Product, Spirit,
       GalleryMedia, TrnedGroupMedia and VisualFilterMedia. -->
  <div
    :data-id="item.id"
    :data-h="item.height"
    :data-w="item.width"
    :style="{ width: mediaStyle.width }"
    :class="[
      {
        disabled: isDisabled,
        'sort-metric-unavailable-tile': sortMetricUnavailableMessage,
        'disable-hover': !mediaHoverEnabled,
        'upload-select-tile': item.id === '+',
        'upload-select-tile-with-filename': item.id === '+' && canShowFileName,
        'instagram-story-tile': tileType === 'instagramStory',
      },
      context,
    ]"
    class="media-tile item"
    data-cy="MediaTile"
  >
    <Button
      v-if="shouldShowRemoveButton"
      class="remove-button"
      icon-name="close"
      :icon-color="removeButtonIconColour"
      round
      no-border
      @click="removeButtonClicked"
    />
    <a
      v-tooltip="disabledTooltip || sortMetricUnavailableMessage"
      :class="[
        {
          media: !(item.id === '+' && canShowFileName),
          'spirit-mask': tileType === 'spirit',
          uploading: isUploading,
          'disable-hover': tileType === 'spirit',
        },
      ]"
      :style="{ height: mediaStyle.height }"
      @click.prevent="imageClicked"
    >
      <!-- upload button tile -->
      <div v-if="item.id === '+'" class="icon-wrapper" @click="uploadClicked">
        <span>
          <Icon :color="iconColor" name="add" xsmall />
        </span>
      </div>

      <!-- normal media tile -->
      <template v-else>
        <component
          :is="fileTag"
          v-if="imageUrl && !isImageBroken"
          :src="imageUrl"
          :class="imageClass"
          @error="setImageBroken"
        />
        <div v-if="isVideo" class="video-duration">
          <Icon
            v-if="!item.postType"
            v-tooltip="'This is a video.'"
            name="video-2"
            xxsmall
            color="white"
          />
          <span v-if="showDuration" class="video-duration-text">{{ videoDuration }}</span>
        </div>
        <div
          v-if="tileType !== 'spirit'"
          :class="['media-badges', { 'story-media-badges': tileType === 'instagramStory' }]"
          class="media-badges"
        >
          <div
            v-if="tileType === 'pin' && item.richPin"
            v-tooltip="'This is a Rich Pin.'"
            class="pin"
          />

          <Icon
            v-if="showLinkBadge"
            v-tooltip="'This is a Facebook link post.'"
            name="link"
            small
            color="white"
          />

          <template v-if="showTopRightIcon">
            <Icon
              v-if="isInstagramReel"
              v-tooltip="'This is a Reel.'"
              name="reels"
              small
              color="white"
            />
            <Icon
              v-else-if="isInstagramCarousel"
              v-tooltip="'This is a Carousel.'"
              name="multipleMedia"
              small
              color="white"
            />
            <Icon
              v-else-if="isInstagramVideo"
              v-tooltip="'This is a Video.'"
              name="video"
              small
              color="white"
            />
            <Icon
              v-if="showCommentBubble"
              v-tooltip="'You have commented on this post.'"
              name="chatCheck"
              small
              color="white"
            />
            <Icon
              v-if="showInstagramBadge && !isViqOwned"
              v-tooltip="'This media is published on your Instagram'"
              name="instagram"
              small
              color="white"
            />
            <Icon
              v-else-if="showPinterestBadge"
              v-tooltip="'This media is published on your Pinterest'"
              name="pinterest"
              small
              color="white"
            />
            <Icon
              v-else-if="showInstagramStoryBadge"
              v-tooltip="'This media is published on your Instagram Story'"
              name="stories"
              small
              color="white"
            />
            <Icon
              v-else-if="showAdBadge"
              v-tooltip="'This is an Ad.'"
              name="megaphone"
              small
              color="white"
            />
            <Icon
              v-else-if="showFacebookBadge"
              v-tooltip="'This is published on your Facebook'"
              name="facebook"
              small
              color="white"
            />
            <Icon
              v-else-if="showTwitterBadge"
              v-tooltip="'This is published on your X'"
              name="twitter"
              small
              color="white"
            />
            <Icon
              v-else-if="showTiktokBadge"
              v-tooltip="'This is published on your TikTok'"
              name="tiktok"
              small
              color="white"
            />
            <Icon
              v-else-if="showYouTubeBadge"
              v-tooltip="'This video is published on your YouTube'"
              name="youtube"
              medium
              color="white"
            />
            <Icon
              v-else-if="showLinkedinBadge"
              v-tooltip="'This is published on your LinkedIn'"
              name="linkedin"
              medium
              color="white"
            />
            <Icon
              v-if="showUGCBadge"
              v-tooltip="'This is a UGC post'"
              name="file-heart"
              small
              color="white"
            />
            <PerformanceBadge
              v-if="showPerformanceBadge"
              :rating="
                getPredictionRating(item.predictions, {
                  currentModel: predictionModel,
                })
              "
              small
            />
          </template>
        </div>
        <div
          :class="[
            {
              'media-badges-bg': tileType !== 'instagramStory',
              'story-badges-bg': tileType === 'instagramStory',
              'badges-bg--top': badgeBackgroundTop,
              'badges-bg--bottom': badgeBackgroundBottom,
              'badges-bg--none': badgeBackgroundNone,
            },
          ]"
        />

        <div v-if="item.hidden" class="hidden-icon-label">
          <Icon :color="hiddenLabelColor" name="hide" xlarge />
        </div>

        <div
          :class="[
            'media-mask',
            { act: isSelected },
            { overlay: tileType === 'visualFilterMedia' },
          ]"
        >
          <!-- Product Media Tile -->
          <ProductTile v-if="tileType === 'product'" :media-item="item" />

          <!-- Spirit Media Tile -->
          <SpiritTile v-if="tileType === 'spirit'" :media-item="item" />

          <!-- Upload Media Tile -->
          <UploadTile
            v-if="tileType === 'upload' && item.uploadStatus !== UPLOAD_STATUS.SUCCESS"
            :media-item="item"
          />

          <!-- Visual Filter Media Tile -->
          <VisualFilterMediaTile v-if="tileType === 'visualFilterMedia'" :media-item="item" />

          <MediaTileCheckbox
            v-if="showMediaTileCheckbox"
            :is-selected="isSelected"
            @toggled="toggleMultiSelectClicked"
          />
        </div>

        <section v-if="tileType === 'instagramStory'" class="story-info-section">
          <p class="instagram-story-title">{{ date }}</p>
          <ul class="instagram-card-container">
            <li>
              Reach
              <span class="color-blue">{{ reach }}</span>
            </li>
            <li>
              Impressions
              <span class="color-blue">{{ impressions }}</span>
            </li>
            <li>
              Exit Rate
              <span class="color-blue">{{ exitRate }}</span>
            </li>
            <li>
              Replies
              <span class="color-blue">{{ replies }}</span>
            </li>
            <li v-if="swipeUps">
              Link Clicks
              <span class="color-blue">{{ swipeUps }}</span>
            </li>
          </ul>
        </section>
        <!-- TrendGroup media tile with account avatar and handle  -->
        <TrendGroupTile v-if="tileType === 'trendGroup'" :media-item="item" />
      </template>
    </a>
    <div
      v-if="shouldShowFilename"
      ref="filenameContainer"
      class="show-filename"
      @click.prevent="imageClicked"
    >
      <VerticallyTruncatedText v-tooltip="filename" class="filename">
        {{ filename }}
      </VerticallyTruncatedText>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import find from 'lodash/find';
import dayjs from 'dayjs';
import { useAuthStore } from '@/stores/auth';
import { useIdentityStore } from '@/stores/identity';
import { useMediaSelectStore } from '@/stores/media-select';
import { useSharedStore } from '@/stores/shared';
import { useFlagStore } from '@/stores/flag';
import { constants, toolTips, UPLOAD_STATUS, PREDICTION_MODEL } from '@/config';
import { colours } from '@/ux/colours';
import { pinterestMediaCountLimitMessage } from '@/app/scheduler/constants';
import Icon from '@/components/foundation/Icon.vue';
import MediaTileCheckbox from '@/components/MediaTileCheckbox.vue';
import PerformanceBadge from '@/components/PerformanceBadge.vue';
import ProductTile from '@/components/MediaTileTypes/ProductTile.vue';
import SpiritTile from '@/components/MediaTileTypes/SpiritTile.vue';
import TrendGroupTile from '@/components/MediaTileTypes/TrendGroupTile.vue';
import UploadTile from '@/components/MediaTileTypes/UploadTile.vue';
import enumTypes, { instagramPostTypes, mediaTypes } from '@/app/library/constants';
import { getPredictionRating } from '@/utils/vision';
import { formatDateRangeLabel, formatTime } from '@/utils/formatters';
import { getEnumText } from '@/models/enum.utils';
import { enumProp } from '@/utils/props';
import { validateMediaLimits } from '@/app/scheduler/utils';
import VisualFilterMediaTile from '@/components/MediaTileTypes/VisualFilterMediaTile.vue';
import VerticallyTruncatedText from '@/components/foundation/VerticallyTruncatedText.vue';
import {
  ALL_TIME,
  isWithinInterval,
  areIntervalsOverlapping,
  getFutureAsInterval,
  isValidInterval,
  intersectIntervals,
  getIntervalMillisecondDuration,
} from '@/utils/dateUtils';
import { findDateRangeOverlap } from '@/app/scheduler/utils/publish-dates';
import Button from '@/components/foundation/Button.vue';
import { BRAND } from '@/models/auth/permissions.enum';
import { useMediaDrawer } from '@/composables/useMediaDrawer';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'MediaTile',
  components: {
    Icon,
    MediaTileCheckbox,
    PerformanceBadge,
    ProductTile,
    SpiritTile,
    TrendGroupTile,
    UploadTile,
    VisualFilterMediaTile,
    VerticallyTruncatedText,
    Button,
  },
  props: {
    item: { type: Object, default: null },
    width: { type: Number, default: null },
    height: { type: Number, default: null },
    tileType: { type: String, default: null },
    context: { type: String, default: null },
    clickSelect: { type: Boolean, default: false },
    limitSource: { type: String, default: null },
    mediaTypeLimit: enumProp(mediaTypes, null),
    mediaCountLimit: { type: Number, default: null },
    videoCountLimit: { type: Number, default: null },
    allowMixedMedia: { type: Boolean, default: true },
    canBePublishedAt: { type: [Date, Object], default: null },
    disallowPastPublishDates: { type: Boolean, default: false },
    disallowIncompatiblePublishDates: { type: Boolean, default: false },
    publishDatesMustOverlapWith: {
      type: Object,
      default: () => ALL_TIME,
      validator: (value) => value === false || isValidInterval(value),
    },
    addToPinterestBoard: { type: Boolean, default: false },
    showDuration: { type: Boolean, default: true },
    sortMetric: { type: String, default: null },
    showFilename: { type: Boolean, default: false },
    selectable: { type: Boolean, default: true },
    showRemoveButton: { type: Boolean, default: false },
  },
  emits: ['uploadClicked', 'deleteClicked', 'removeClicked'],
  setup() {
    const { openMedia } = useMediaDrawer();
    return { openMedia };
  },
  data() {
    return {
      ...{ UPLOAD_STATUS },
      iconColor: colours.BASIC.WHITE,
      hiddenLabelColor: colours.TEXT.TEXT_PRIMARY,
      date: null,
      reach: 0,
      impressions: 0,
      exitRate: '0%',
      swipeUps: null,
      replies: 0,
      percentageStyle: { style: 'percent', maximumFractionDigits: 2 },
      isImageBroken: false,
    };
  },
  computed: {
    ...mapStores(
      useMediaSelectStore,
      useAuthStore,
      useMediaSelectStore,
      useSharedStore,
      useFlagStore,
      useIdentityStore,
    ),
    predictionModel() {
      return PREDICTION_MODEL.ENGAGEMENT;
    },
    sortMetricUnavailableMessage() {
      if (
        this.sortMetric === 'reach' &&
        (this.item.source === enumTypes.PINTEREST || this.item.source === enumTypes.TWITTER)
      ) {
        return `Reach is not available for ${getEnumText(this.item.source)}`;
      }
      return null;
    },
    badgeBackgroundTop() {
      return (this.showTopRightIcon || this.showLinkBadge) && !this.videoDuration;
    },
    badgeBackgroundBottom() {
      return this.videoDuration && !this.showTopRightIcon && !this.showLinkBadge;
    },
    badgeBackgroundNone() {
      return this.isUploadedMedia && !this.videoDuration;
    },
    videoDuration() {
      if (this.isVideo && this.item.duration) {
        return formatTime(this.item.duration);
      }
      return null;
    },
    showCommentBubble() {
      return (
        this.authStore.user_can('instagram', 'can_access_community_ugc') && this.item.isCommented
      );
    },
    imageUrl() {
      if (this.tileType) {
        if (this.tileType === 'spirit') {
          return this.item.urls[parseInt(constants.THUMBNAIL_SIZE, 10)] || '';
        }
        if (
          (this.tileType === 'trendGroup' && this.$route.meta.report) ||
          this.tileType === 'visualFilterMedia'
        ) {
          return (this.item.urls && this.item.urls.thumbs) || this.item.url;
        }
        return this.item.url;
      }
      return '';
    },
    fileTag() {
      return this.tileType === 'upload' ? this.item.fileTag : 'img';
    },
    imageSelected() {
      return this.mediaSelectStore.multiSelectSelectedItems.some(
        (item) => item.mediaType === 'IMAGE',
      );
    },
    videoSelected() {
      return this.mediaSelectStore.multiSelectSelectedItems.some(
        (item) => item.mediaType === 'VIDEO',
      );
    },
    videoCount() {
      return this.mediaSelectStore.multiSelectSelectedItems.filter(
        (item) => item.mediaType === 'VIDEO',
      ).length;
    },
    itemTypeDiffersFromSelected() {
      return (
        (this.item.mediaType === 'IMAGE' && this.videoSelected) ||
        (this.item.mediaType === 'VIDEO' && this.imageSelected)
      );
    },
    selected() {
      return this.mediaSelectStore.multiSelectSelectedItems.some(
        (item) => item.id === this.item.id,
      );
    },
    isSourceMatched() {
      if (!this.item?.source) {
        return false;
      }

      switch (this.limitSource) {
        case 'UPLOADED':
          return this.item.source === 'UPLOAD';
        case enumTypes.OWNED:
          return this.item.source !== 'UPLOAD' && !this.item?.sourceType?.includes('UGC');
        case 'UGC':
          return this.item.source !== 'UPLOAD' && this.item?.sourceType?.includes('UGC');
        case enumTypes.SOURCE_FILTER_INSTAGRAM_STORIES:
          return this.item.source === constants.INSTAGRAM_STORY;
        default:
          return this.item.source === this.limitSource;
      }
    },
    canPublishWithin() {
      const { start, end } = this.item.canPublishWithin ?? {};
      if (start && end) {
        return {
          start: dayjs(start).toDate(),
          end: dayjs(end).toDate(),
        };
      }
      return ALL_TIME;
    },
    publishDateRangeIsExpired() {
      return (
        this.disallowPastPublishDates &&
        !areIntervalsOverlapping(getFutureAsInterval(), this.canPublishWithin)
      );
    },
    publishDateRangeDoesNotOverlapOtherSelections() {
      return (
        this.disallowIncompatiblePublishDates &&
        getIntervalMillisecondDuration(
          intersectIntervals(
            this.publishDatesMustOverlapWith,
            findDateRangeOverlap(this.mediaSelectStore.multiSelectSelectedItems),
            this.canPublishWithin,
          ),
        ) === 0
      );
    },
    publishDateRangeDoesNotIncludeProvidedDate() {
      return (
        this.canBePublishedAt &&
        !isWithinInterval(dayjs(this.canBePublishedAt).toDate(), this.canPublishWithin)
      );
    },
    isDisabled() {
      if (this.sharedStore.mediaTileSelectForceEnabled) {
        return false;
      }
      return (
        !this.selected &&
        ((this.mediaCountLimit !== null &&
          this.mediaSelectStore.multiSelectSelectedItems.length >= this.mediaCountLimit) ||
          (this.videoCountLimit !== null && this.videoCount >= this.videoCountLimit) ||
          (this.itemTypeDiffersFromSelected && !this.allowMixedMedia) ||
          this.mediaSelectStore.isDisabledMode ||
          (this.limitSource && !this.isSourceMatched) ||
          this.publishDateRangeIsExpired ||
          this.publishDateRangeDoesNotOverlapOtherSelections ||
          this.publishDateRangeDoesNotIncludeProvidedDate ||
          (this.mediaTypeLimit &&
            this.item?.mediaType &&
            this.mediaTypeLimit !== this.item?.mediaType))
      );
    },
    disabledTooltip() {
      if (this.isDisabled) {
        const validation = validateMediaLimits(
          this.item.mediaType,
          this.mediaSelectStore.multiSelectSelectedItems.length,
          this.videoCount,
          this.mediaCountLimit,
          this.videoCountLimit,
          this.allowMixedMedia,
        );
        if (validation) {
          return validation;
        }

        if (this.limitSource) {
          if (this.limitSource === enumTypes.SOURCE_FILTER_INSTAGRAM_STORIES)
            return toolTips.addToBoardSourceLimit.instagramStory;
          if (this.limitSource === constants.INSTAGRAM)
            return toolTips.addToBoardSourceLimit.instagram;
          if (this.limitSource === constants.PINTEREST)
            return toolTips.addToBoardSourceLimit.pinterest;
          if (this.limitSource === constants.UPLOADED) {
            return toolTips.addToBoardSourceLimit.uploaded;
          }
          return '';
        }

        if (
          this.mediaTypeLimit === mediaTypes.IMAGE &&
          this.item.mediaType !== this.mediaTypeLimit
        ) {
          return toolTips.addToBoardWrongType.expectImage;
        }
        if (
          this.mediaTypeLimit === mediaTypes.VIDEO &&
          this.item.mediaType !== this.mediaTypeLimit
        ) {
          return toolTips.addToBoardWrongType.expectVideo;
        }

        if (this.publishDateRangeIsExpired) {
          return 'The approved publishing dates are in the past';
        }
        if (this.publishDateRangeDoesNotOverlapOtherSelections) {
          const verb = this.mediaSelectStore.multiSelectSelectedItems.length === 1 ? 'has' : 'have';
          return `Media selected ${verb} incompatible approved publishing dates with this item`;
        }
        if (this.publishDateRangeDoesNotIncludeProvidedDate) {
          const formattedPublishingDates = formatDateRangeLabel(
            this.canPublishWithin.start,
            this.canPublishWithin.end,
            {
              forceYearDisplay: false,
              withTime: true,
            },
          );
          return `This item must be published between ${formattedPublishingDates}`;
        }

        // TODO: This should probably be remobed. It doesn't make sense to show this msg for all non-pinterest boards.
        if (!this.addToPinterestBoard) {
          return pinterestMediaCountLimitMessage;
        }
      }
      return '';
    },
    isSelected() {
      return !!find(
        this.mediaSelectStore.multiSelectSelectedItems,
        (item) => item.id === this.item.id,
      );
    },
    isUploadedMedia() {
      return this.mediaSource === enumTypes.UPLOADED;
    },
    isUploading() {
      return (
        this.item.uploadStatus === UPLOAD_STATUS.PENDING ||
        this.item.uploadStatus === UPLOAD_STATUS.UPLOADING ||
        this.item.uploadStatus === UPLOAD_STATUS.PROCESSING ||
        this.item.uploadStatus === UPLOAD_STATUS.FAILED
      );
    },
    mediaSource() {
      if (this.item.sourceType) {
        const sourceType = this.item.sourceType.split(':');
        return sourceType[sourceType.length - 1];
      }
      return null;
    },
    isVideo() {
      return this.item.mediaType === 'VIDEO' || this.item.isVideo || this.item.fileTag === 'video';
    },
    isInstagramReel() {
      return this.item.postType === instagramPostTypes.REEL;
    },
    isInstagramCarousel() {
      return this.item.postType === instagramPostTypes.CAROUSEL;
    },
    isInstagramVideo() {
      return this.item.postType === instagramPostTypes.VIDEO;
    },
    showInstagramBadge() {
      return (
        this.mediaSource === enumTypes.INSTAGRAM_OWNED ||
        this.mediaSource === enumTypes.INSTAGRAM_OWNED_IGTV
      );
    },
    showPinterestBadge() {
      return this.mediaSource === enumTypes.PINTEREST_OWNED;
    },
    showInstagramStoryBadge() {
      return (
        (this.mediaSource === enumTypes.INSTAGRAM_STORY ||
          this.item.source === enumTypes.INSTAGRAM_STORY) &&
        this.mediaSource !== enumTypes.INSTAGRAM_STORY_UGC &&
        this.item.source !== enumTypes.INSTAGRAM_STORY_UGC
      );
    },
    showAdBadge() {
      return this.mediaSource === enumTypes.FACEBOOK_ADS;
    },
    showFacebookBadge() {
      return enumTypes.FACEBOOK_SOURCE_LIST.includes(this.mediaSource);
    },
    showLinkBadge() {
      return enumTypes.FACEBOOK_LINK_LIST.includes(this.mediaSource);
    },
    showTwitterBadge() {
      return enumTypes.TWITTER_SOURCE_LIST.includes(this.mediaSource);
    },
    showTiktokBadge() {
      return this.mediaSource === enumTypes.TIKTOK_OWNED;
    },
    showYouTubeBadge() {
      return this.mediaSource === enumTypes.YOUTUBE_OWNED;
    },
    showLinkedinBadge() {
      return this.mediaSource === enumTypes.LINKEDIN_OWNED;
    },
    showUGCBadge() {
      return enumTypes.UGC_LIST.includes(this.mediaSource);
    },
    showPerformanceBadge() {
      // Please keep Discover.filterSources() logic in sync with this method
      const rating = getPredictionRating(this.item.predictions, {
        currentModel: this.predictionModel,
      });

      // for ads user, only hide prediction for facebook_ads media
      if (
        this.authStore.user_can('ads', 'can_access_ad_predictions') &&
        !this.showAdBadge &&
        rating
      ) {
        return true;
      }
      // for other media, hide prediction if it is owned content / ads content
      return (
        !(
          this.showLinkedinBadge ||
          this.showInstagramBadge ||
          this.showPinterestBadge ||
          this.showInstagramStoryBadge ||
          this.showAdBadge ||
          this.showFacebookBadge ||
          this.showTwitterBadge ||
          this.showTiktokBadge ||
          this.showYouTubeBadge
        ) &&
        rating &&
        !(this.$route.name && this.$route.name.startsWith('instagram.galleries')) &&
        this.mediaSource !== enumTypes.TIKTOK_UGC
      );
    },
    isViqOwned() {
      return this.$route.name && this.$route.name.startsWith('instagram.viq.owned');
    },
    showTopRightIcon() {
      return (
        (((this.tileType === 'upload' && this.item.uploadStatus === UPLOAD_STATUS.SUCCESS) ||
          ['library', 'libraryNoMultiSelect', 'galleryMedia', 'instagramStory'].includes(
            this.tileType,
          )) &&
          !this.$route.meta.report) ||
        ['trendGroup', 'visualFilterMedia'].includes(this.tileType)
      );
    },
    showMediaTileCheckbox() {
      return (
        (['library', 'scheduler', 'pin', 'galleryMedia', 'instagramStory', 'trendGroup'].includes(
          this.tileType,
        ) ||
          this.item.uploadStatus === UPLOAD_STATUS.SUCCESS) &&
        !this.isDisabled &&
        this.selectable
      );
    },
    mediaHoverEnabled() {
      return (
        this.showMediaTileCheckbox ||
        ['spirit', 'visualFilterMedia', 'product', 'similarMedia'].includes(this.tileType)
      );
    },
    mediaStyle() {
      if (this.tileType === 'instagramStory') {
        return { width: 'auto', height: '202px' };
      }

      return {
        width: `${this.width}px`,
        height: this.item.id === '+' && this.canShowFileName ? 'auto' : `${this.height}px`,
      };
    },
    mediaBrandId() {
      return this.item.brandId ?? (parseInt(this.item.brandType?.split(':')?.[0], 10) || null);
    },
    canShowFileName() {
      return (
        this.identityStore.guard(BRAND.LIBRARY.CAN_ACCESS_PUBLISHING_DATES_AND_BULK_UPLOADING) &&
        this.flagStore.ready &&
        this.flagStore.flags.assetManagementGA &&
        this.showFilename
      );
    },
    shouldShowFilename() {
      return this.canShowFileName && this.item.filename;
    },
    shouldShowRemoveButton() {
      return (
        this.flagStore.flags.assetManagementGA &&
        this.showRemoveButton &&
        this.item.id !== '+' &&
        this.item.uploadStatus === UPLOAD_STATUS.INIT
      );
    },
    filename() {
      return this.item.filename;
    },
    imageClass() {
      if (this.tileType === 'instagramStory') {
        return 'media-item-image';
      }
      if (this.shouldShowFilename) {
        return 'show-filename-image';
      }
      return null;
    },
    removeButtonIconColour() {
      return colours.TEXT.TEXT_SECONDARY;
    },
  },
  mounted() {
    if (this.tileType === 'instagramStory') {
      this.updateInstagramStoryStats();
    }
  },
  methods: {
    getPredictionRating,
    imageClicked(e) {
      if (
        this.isDisabled ||
        (this.item.uploadStatus && this.item.uploadStatus !== UPLOAD_STATUS.SUCCESS) ||
        this.tileType === 'visualFilterMedia' ||
        !this.selectable
      ) {
        return;
      }
      if (this.clickSelect) {
        this.toggleMultiSelectClicked();
        return;
      }
      if (this.tileType === 'scheduler') {
        this.toggleMultiSelectClicked();
      } else if (
        !Object.values(e.target.classList).includes('media-action-button') &&
        this.item.uploadStatus !== UPLOAD_STATUS.PENDING
      ) {
        const mediaId = this.item.mediaId ? this.item.mediaId : this.item.id;
        // we set id = '+' for the upload tile in order to line it up with real media tiles.
        // when the upload tile is clicked, we don't want to get media detail.
        if (mediaId === '+') {
          return;
        }
        this.openMedia({
          id: mediaId || this.item.sourceId,
          brandLabel: this.authStore.currentBrand.label,
          source: !mediaId ? 'INSTAGRAM' : undefined,
          replaceModal: this.tileType === 'similarMedia',
        });
      }
    },
    toggleMultiSelectClicked() {
      if (!this.isSelected) {
        if (this.mediaSelectStore.isSingleMode) {
          this.mediaSelectStore.clearItemsFromMultiSelect();
        }
        this.mediaSelectStore.addItemToMultiSelect({ item: this.item });
      } else {
        this.mediaSelectStore.removeItemFromMultiSelect({ item: this.item });
      }
    },
    uploadClicked() {
      this.$emit('uploadClicked');
    },
    deleteClicked(item) {
      this.$emit('deleteClicked', item.id);
    },
    updateInstagramStoryStats() {
      if (this.tileType === 'instagramStory' && this.item.sourceData) {
        this.date = !this.item.sourceData.date
          ? ''
          : dayjs(this.item.sourceData.date).format('lll');
        this.reach = !this.item.sourceData.reach ? 0 : this.item.sourceData.reach.toLocaleString();
        this.impressions = !this.item.sourceData.views
          ? 0
          : this.item.sourceData.views.toLocaleString();
        this.exitRate = !this.item.sourceData.exitRate
          ? '0%'
          : this.item.sourceData.exitRate.toLocaleString('en', this.percentageStyle);
        this.replies = !this.item.sourceData.replies
          ? 0
          : this.item.sourceData.replies.toLocaleString();

        if (this.item.sourceData.shortUrl) {
          this.swipeUps = this.item.sourceData.shortUrl.totalClicks;
        } else {
          this.swipeUps = '-';
        }
      }
    },
    setImageBroken() {
      this.isImageBroken = true;
    },
    removeButtonClicked() {
      this.$emit('removeClicked', this.item);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.media-tile {
  /*
    don't put any width / max-width / min-width style here,
    as it will break the JS responsive width calculation and break media section lay out.
  */

  margin: var(--space-12);
  display: inline-block;
  box-sizing: content-box;
  position: relative;
  border-radius: var(--round-corner);
  transition: var(--transition-all);
  user-select: none;
  page-break-inside: avoid;

  img,
  video {
    display: block;
    width: auto;
    height: 100%;
  }

  .video-img-placeholder {
    height: 100%;
    width: 10rem;
  }

  .hidden-icon-label {
    position: absolute;
    display: flex;
    inset: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: rgb(255 255 255 / 70%);

    .svg-icon {
      margin: auto;
    }
  }

  .remove-button {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    padding: var(--space-8);
    width: var(--space-28);
    height: var(--space-28);
    top: -12px;
    right: -15px;
    z-index: calc(var(--z-index-sticky) - 1);
    box-shadow: var(--shadow-1);
  }

  a.media {
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: relative;
    width: auto;
    height: 100%;
    overflow: visible;
    border-radius: var(--round-corner);
    background: url('@/assets/img/logos/white-dh.png') no-repeat center var(--background-400);
    background-size: 30%;

    .media-item-image {
      width: 113px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      object-fit: cover;
    }

    .show-filename-image {
      border-bottom-right-radius: initial;
      border-bottom-left-radius: initial;
      display: block;
      overflow: hidden;
    }

    img,
    video {
      border-radius: var(--round-corner);
      display: block;
      overflow: hidden;
    }

    .media-mask {
      border-radius: var(--round-corner);
      border: var(--space-4) solid transparent;
      position: absolute;
      inset: 0;
      background: rgb(0 0 0 / 0%);
      opacity: 0;
      visibility: hidden;
      transition: var(--transition-all);
    }

    .media-mask.act {
      border: var(--space-4) solid var(--action-500);
      opacity: 1;
      visibility: visible;

      .control--indicator {
        position: absolute;
        z-index: var(--z-index-raised);
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &:hover {
    transform: scale(1.03, 1.03);

    a.media .media-mask {
      background: rgb(0 0 0 / 40%);
      opacity: 1;
      visibility: visible;

      &.overlay {
        background: rgb(0 0 0 / 60%);
        z-index: 5;
      }
    }

    .remove-button {
      opacity: 1;
      visibility: visible;
    }
  }

  a.media.spirit-mask,
  a.media.uploading {
    .media-mask {
      background: rgb(0 0 0 / 40%);
      opacity: 1;
      visibility: visible;
      border: none;
    }
  }

  a.media.uploading {
    &:hover {
      transform: none;
      box-shadow: none;
    }

    .media-mask {
      background: rgb(0 0 0 / 50%);
      opacity: 1;
      visibility: visible;

      .loader {
        max-width: 100%;
      }
    }
  }

  .media-badges {
    position: absolute;
    z-index: 4;
    top: 0;
    right: 0;
    padding: 0.5rem 0.5rem 0.5rem 0;
    margin-left: 2rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;

    & > * {
      margin: 0 0 0.1875rem 0.1875rem;
      width: 1.25rem;
      height: 1.25rem;
    }

    .pin {
      border-radius: 50%;
      background-color: var(--background-0);
      transition: var(--transition-all);
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
    }
  }

  .media-badges-bg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--tile-bg-gradient);
    border-radius: var(--round-corner);
  }

  .story-badges-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 113px;
    height: 100%;
    background: var(--tile-bg-gradient);
    border-radius: var(--round-corner);
  }

  .badges-bg--bottom {
    background: var(--tile-bg-gradient-bottom);
    bottom: 0;
    height: 50%;
    top: initial;
  }

  .badges-bg--top {
    background: var(--tile-bg-gradient-top);
    height: 50%;
  }

  .badges-bg--none {
    background: transparent;
    height: 100%;
  }

  .story-media-badges {
    width: 20px;
    left: 95px;
    margin-left: 0;
    flex-flow: row;
  }
}

.media-tile.disable-hover:hover,
.media-tile *.disable-hover:hover {
  transform: none;
}

.media-tile.disabled:hover,
.media-tile.disable-hover:hover {
  transform: none;

  a.media {
    cursor: auto;

    &:not(.uploading) .media-mask {
      visibility: hidden;
    }
  }

  div.show-filename {
    cursor: auto;
  }
}

.sort-metric-unavailable-tile {
  opacity: 0.3;
}

.instagram-story-tile {
  background: var(--background-0);
  box-shadow: var(--shadow-1);
}

.upload-select-tile {
  box-sizing: border-box;
  border: 2px dashed var(--border);
  border-radius: var(--round-corner-small);
  transition: var(--transition-all);
  background-color: var(--background-0);
  cursor: pointer;

  &.upload-select-tile-with-filename {
    height: 100%;
  }

  .icon-wrapper {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    background-color: var(--background-0);
  }

  span {
    width: 2.5rem;
    height: 2.5rem;
    margin: auto;
    background: var(--border);
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    transition: var(--transition-all);
  }

  &:hover {
    border-color: var(--action-500);

    span {
      background: var(--action-500);
    }
  }
}

.dz-drag-hover .upload-select-tile {
  cursor: pointer;
  border-color: var(--action-500);

  span {
    background: var(--action-500);
  }
}

.media-tile .add-to-dropdown {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);

  input {
    z-index: var(--z-index-raised);
  }
}

.color-blue {
  color: var(--action-400);
  float: right;
}

.instagram-story-link {
  margin: var(--space-8);
}

.instagram-card-container {
  padding: 0 1rem;
  width: 100%;
  font-size: var(--x13);
  font-weight: var(--font-normal);
}

.instagram-story-card {
  flex-direction: row;
  justify-content: normal;
  padding: 0;
  width: 100%;
}

.instagram-story-title {
  text-align: center;
  overflow: hidden;
  margin: var(--space-16) 0 var(--space-24) 0;
  font-size: var(--x13);
  color: var(--text-secondary);
}

.board-card-title {
  margin: var(--space-8) 0 var(--space-4) 0;
}

.story-info-section {
  height: 100%;
  flex-grow: 1;
}

.video-duration {
  position: absolute;
  bottom: 10px;
  right: 12px;
  z-index: 4;

  .icon {
    position: relative;
    top: 2px;
  }

  .video-duration-text {
    padding-left: var(--space-4);
    color: var(--white);
    font-size: var(--x12);
    font-weight: var(--font-medium);
    line-height: var(--space-18);
  }
}

.show-filename {
  overflow: hidden;
  padding: var(--space-10);
  margin-bottom: var(--space-10);
  box-shadow: var(--shadow-1);
  border-radius: 0 0 var(--space-4) var(--space-4);

  .filename {
    font-size: var(--x12);
    line-height: var(--x15);
    color: var(--text-primary);
    height: var(--space-48);
  }
}
</style>
