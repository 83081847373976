<script setup>
import { computed, toRefs, useSlots, provide, ref, watch } from 'vue';
import { recomputeAllPoppers } from 'floating-vue';
import DropdownBase from '@/components/foundation/form/DropdownBase.vue';
import CheckBox from '@/components/foundation/CheckBox.vue';
import TextInput from '@/components/foundation/form/TextInput.vue';
import DropdownChip from '@/components/foundation/DropdownChip.vue';
import { useSelectOptions } from '@/components/foundation/composables/useSelectOptions';
import MultiSelectDropdownChip from '@/components/foundation/form/MultiSelectDropdownChip.vue';
import MultiSelectDropdownOption from '@/components/foundation/form/MultiSelectDropdownOption.vue';
import { useLazyList } from '@/components/foundation/composables/useLazyList';

const props = defineProps({
  value: { type: Array, default: null },
  options: { type: Array, default: null },
  suggestedOptions: { type: Array, default: null },
  placeholder: { type: String, default: null },
  /**
   * Name of the property on the option object to use for the display label.
   */
  optionLabel: { type: String, default: 'label' },
  /**
   * Name of the property on the option object to use for the value.
   */
  optionValue: { type: String, default: 'value' },
  noDataText: { type: String, default: 'No Data' },
  disabled: { type: Boolean, default: false },
  clearButtonDisabled: { type: Boolean, default: false },
  tooltip: { type: String, default: null },
  optionsLoading: { type: Boolean, default: false },
  optionsLoadingText: { type: String, default: 'Options loading...' },
  linesPerLabel: { type: [String, Number], default: 1 },
  hideSelectAll: { type: Boolean, default: false },
  disableHideTriggers: { type: Boolean, default: false },
  maxChips: { type: Number, default: 5 },
  enableLazyList: { type: Boolean, default: false },
  /**
   * The props to pass to the DropdownBase component.
   */
  dropdownBaseProps: { type: Object, default: null },
});

const slots = useSlots();
const emit = defineEmits(['input', 'apply', 'auto-hide', 'clear']);

const propRefs = toRefs(props);
const {
  onOptionClick,
  isOptionSelected,
  isOptionDisabled,
  selectedValueOptions,
  displayOptions,
  hasValueChanged,
  selectedValues,
  cancelChanges,
  selectAllInput,
  toggleSelectAll,
  search,
  getOptionTooltip,
  isPartiallySelected,
  onOptionExpandClick,
  canOptionExpand,
  canOptionCollapse,
  isOptionExpanded,
  isOptionPartiallySelected,
  hasDividerBefore,
  closeAllExpandedOptions,
  clearAllSelections,
} = useSelectOptions({
  value: propRefs.value,
  options: propRefs.options,
  suggestedOptions: propRefs.suggestedOptions,
  optionValue: propRefs.optionValue,
  optionLabel: propRefs.optionLabel,
});

const { lazyItems, resetLimit, scrollableContainerProps } = useLazyList({
  list: displayOptions,
  disableLazyList: !propRefs.enableLazyList.value,
});

provide('isOptionSelected', isOptionSelected, () => false);
provide('isOptionDisabled', isOptionDisabled, () => false);
provide('canOptionExpand', canOptionExpand, () => false);
provide('canOptionCollapse', canOptionCollapse, () => false);
provide('isOptionExpanded', isOptionExpanded, () => false);
provide('getOptionTooltip', getOptionTooltip, () => false);
provide('onOptionClick', onOptionClick, () => false);
provide('onOptionExpandClick', onOptionExpandClick, () => false);
provide('isOptionPartiallySelected', isOptionPartiallySelected, () => false);
provide('hasDividerBefore', hasDividerBefore, () => false);

const visibleChipOptions = computed(() => {
  return (selectedValueOptions.value ?? []).slice(0, props.maxChips);
});

const hiddenChipOptions = computed(() => {
  return (selectedValueOptions.value ?? []).slice(props.maxChips);
});

function updateValue() {
  if (hasValueChanged.value) {
    emit('input', selectedValues.value);
  }
}

function onOpen(open) {
  if (open) {
    cancelChanges();
  } else {
    updateValue();
    closeAllExpandedOptions();
    search.value = '';
  }
}
function onAutoHide() {
  updateValue();
  resetLimit();

  emit('auto-hide', selectedValues.value);
}

function onSelectionRemove(value) {
  if (!props.disabled) {
    onOptionClick(value);
    updateValue();
  }
}

function onApply() {
  emit('apply', selectedValues.value);
}

function onClear() {
  emit('clear', selectedValues.value);
  clearAllSelections();
}
const prevSelectedValues = ref([]);

function hasSelectionsChanged(prevSelections, currSelections) {
  return (
    prevSelections.some((element) => !currSelections.includes(element)) ||
    currSelections.some((element) => !prevSelections.includes(element))
  );
}

function updateSelectedValues(prevSelections, currSelections, updateValueCallback) {
  const selectionsUpdated = hasSelectionsChanged(prevSelections.value, currSelections.value);
  if (selectionsUpdated) {
    updateValueCallback();
    prevSelections.value = [...currSelections.value];
  }
}

watch(selectedValues, () =>
  updateSelectedValues(prevSelectedValues, selectedValues, () => {
    recomputeAllPoppers();
    emit('input', selectedValues.value);
  }),
);

defineExpose({
  hasSelectionsChanged,
  updateSelectedValues,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <DropdownBase
    v-tooltip="props.tooltip"
    v-bind="dropdownBaseProps"
    :value="selectedValueOptions"
    :placeholder="props.placeholder"
    :disabled="props.disabled"
    :clear-button-disabled="props.clearButtonDisabled"
    @open="onOpen"
    @auto-hide="onAutoHide"
    @clear="onClear"
    @apply="onApply"
  >
    <template v-if="slots.selectionDisplayInput" #selectionDisplayInput>
      <slot name="selectionDisplayInput" />
    </template>
    <template #SelectionDisplayContent>
      <div class="flex flex-wrap gap-1 py-3">
        <template v-for="option in visibleChipOptions" :key="option[props.optionValue]">
          <MultiSelectDropdownChip
            :option="option"
            :disabled="props.disabled"
            @remove="onSelectionRemove(option)"
          />
        </template>
        <template v-if="hiddenChipOptions.length > 0">
          <DropdownChip
            :dropdown-items="hiddenChipOptions"
            :text="`${hiddenChipOptions.length} more...`"
            :chip-props="{ xxsmall: true, fontWeightNormal: true }"
            :enable-lazy-list="!propRefs.disableLazyList"
          >
            <template #item="{ item: hiddenOption }">
              <MultiSelectDropdownChip
                :option="hiddenOption"
                :disabled="props.disabled"
                @remove="onSelectionRemove(hiddenOption)"
              />
            </template>
          </DropdownChip>
        </template>
      </div>
    </template>
    <div class="px-4 py-2">
      <TextInput
        v-model="search"
        placeholder="Search"
        round
        hide-details
        clearable
        prepend-inner-icon="search"
        prepend-inner-icon-size="xxsmall"
      />
    </div>
    <div
      v-if="!hideSelectAll"
      class="text-small-medium flex cursor-pointer items-center justify-between gap-2 px-4 hover:bg-grey-300"
      @click.capture.stop.prevent="toggleSelectAll"
    >
      <div class="flex w-full items-center border-b border-solid border-[var(--border)] py-2 pl-2">
        <CheckBox :value="selectAllInput" :use-indeterminate-style="isPartiallySelected" />
        Select All
      </div>
    </div>
    <div v-bind="scrollableContainerProps" class="max-h-64 overflow-y-auto overflow-x-hidden">
      <template v-if="props.optionsLoading">
        <div class="text-secondary py-2 pl-6 pr-4">
          {{ props.optionsLoadingText }}
        </div>
      </template>
      <template v-if="displayOptions.length > 0">
        <template v-for="option in lazyItems" :key="option[props.optionValue]">
          <MultiSelectDropdownOption
            :option="option"
            :option-label="props.optionLabel"
            :option-value="props.optionValue"
            :lines-per-label="props.linesPerLabel"
            @click="onOptionClick"
            @expand="onOptionExpandClick"
          >
            <template v-if="!!slots.optionRight" #optionRight="optionProps">
              <slot name="optionRight" v-bind="optionProps" />
            </template>
          </MultiSelectDropdownOption>
        </template>
      </template>
      <template v-else>
        <div class="text-secondary py-2 pl-6 pr-4">
          {{ noDataText }}
        </div>
      </template>
    </div>

    <template v-if="Boolean(slots.floatingFooter)" #floatingFooter>
      <slot name="floatingFooter" />
    </template>
  </DropdownBase>
</template>
