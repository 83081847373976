<template>
  <div v-if="flagStore.ready">
    <template v-if="currentScreen === 'index'">
      <PostStatusBox :post="{ ...post, platform: 'instagram' }" />
      <Banner
        v-if="hasPublishedCollaborators"
        alert-type="neutral"
        custom-icon="paperplane"
        class="information-box -mt-4 mb-8"
      >
        <span>Collaborator invites sent for this post:</span>
        <ul>
          <li
            v-for="collaborator in collaborators"
            :key="collaborator.username"
            class="list-collaborator"
          >
            {{ collaborator.username }}
          </li>
        </ul>
        <span>This post will appear on the collaborators profile(s) once accepted.</span>
      </Banner>
      <FirstCommentStatusBox v-if="firstCommentFailed" :post="post" />
      <Banner v-if="invalidShoppingTags" alert-type="danger" class="invalid-tags-warning">
        {{ invalidShoppingTagsWarningMessage }}
      </Banner>
      <Banner
        v-if="invalidCollaboratorsWarning"
        alert-type="warning"
        class="invalid-tags-warning -mt-4"
      >
        {{ invalidCollaboratorsWarning }}
      </Banner>
      <Banner v-if="showInformationBox" alert-type="warning" class="information-box mb-8">
        <div>
          <div class="links-prompt">
            {{ linksPromptMessage }}
          </div>
          <div class="links-prompt-buttons">
            <Button @click="dismissLinksPrompt">Ok</Button>
          </div>
        </div>
      </Banner>
      <Banner v-if="invalidUserTagsWarning" alert-type="warning" class="invalid-tags-warning">
        {{ invalidUserTagsWarning }}
      </Banner>
      <Banner v-if="invalidLocationWarning" alert-type="warning" class="invalid-tags-warning">
        {{ invalidLocationWarning }}
      </Banner>
      <section>
        <PublishOptionHeader
          :disabled="isDraftToggleDisabled"
          :is-on="isDraft"
          :post="post"
          @drafts-toggled="(draft) => onToggleDraft(draft)"
        />
        <AutoPublishOptions
          :key="schedulerStore.instagramTabContext"
          v-model="autoPublish"
          :disable-auto-publish="disableAutoPublish"
          :disabled="disableEditing"
          platform="instagram"
          :post="post"
        />
        <PostSettingItem
          :disabled="disableEditing || approvedPublishingDatesExpired"
          :title="scheduledTimeLabel"
          :clear-action="resetTime"
          :on-click="() => switchSubScreen('schedule')"
          :error-message="scheduledTimeError"
          empty="Schedule Date & Time"
          left-icon="calendar"
        />
        <QuickSelectCalendar
          v-if="!disableEditing && isActiveTab && !approvedPublishingDatesExpired"
          ref="quickSelectCalendar"
          :class="{ 'pt-4': publishDateError }"
          :model-value="scheduledTime"
          :restrict-selection-to-interval="canPublishWithin"
          :platform="platformLabels.instagram"
          @update:model-value="timeSelected"
        />
      </section>
      <PostSettingItem
        v-if="hasApprovalRequestsAccess"
        :title="getApprovalRequestTitle({ post })"
        :on-click="
          () =>
            schedulerStore.toggleSidePanel({
              router: $router,
              platform: 'instagram',
              post,
            })
        "
        data-cy="approval-request-setting-item"
        empty="Add Reviewers"
        left-icon="chatBubbleSquareCheck"
        label="Approval Request"
        :approval-status-badge="getApprovalChipText({ post, approvalRequests })"
      />
      <section>
        <div class="caption-header">
          <p class="section-title">
            Caption
            <InfoTooltip v-if="!autoPublish" :tooltip="captionTooltip" xsmall />
          </p>
        </div>
        <Textarea
          v-model="caption"
          :class="{ 'invalid-field': !isCaptionValid }"
          :disabled="disableEditing"
          placeholder="Enter caption"
          resizable
          data-cy="caption-area"
        />
        <div class="red">
          <span v-if="!captionHashtagsValid">
            You can only have {{ maxInstagramHashtags }} unique hashtags.
          </span>
          <span v-else-if="caption.length > maxInstagramCaptionLength">
            -{{ caption.length - maxInstagramCaptionLength }}
          </span>
        </div>
        <div class="first-comment-header">
          <p class="section-title">
            First Comment
            <InfoTooltip :tooltip="firstCommentTooltip" xsmall />
          </p>
        </div>
        <RichTextarea
          ref="firstCommentTextArea"
          v-model="firstCommentHTML"
          v-tooltip="firstCommentDisabledTooltip"
          :disabled="disableEditing || !autoPublish"
          :class="[
            'first-comment-textarea',
            { disabled: !autoPublish, 'invalid-field': !isFirstCommentValid },
          ]"
          placeholder="Enter the first comment for your post"
          enable-hash-tag-highlighting
          resizable
        />
        <div class="red">
          <span v-if="autoPublish && !firstCommentHashtagsValid">
            You can only have {{ maxInstagramHashtags }} unique hashtags including the caption.
          </span>
          <span v-else-if="firstComment.length > maxInstagramCaptionLength">
            -{{ firstComment.length - maxInstagramCaptionLength }}
          </span>
        </div>
      </section>
      <!-- TODO disable when auto publish is not selected -->
      <PostSettingItem
        v-if="canAutoPublishInstagram"
        :input-tooltip="addLocationTooltip"
        :disabled="disableEditing || !autoPublish"
        :title="locationLabel"
        :clear-action="resetLocation"
        :on-click="() => switchSubScreen('location')"
        empty="Add Location"
        left-icon="location"
        label="Location"
      />
      <PostSettingItem
        v-if="canAutoPublishInstagram"
        :input-tooltip="tagPeopleTooltip"
        :disabled="tagPeopleDisabled"
        :title="instagramUserTaggerStore.getTagsLabel('mediaTag')"
        :clear-action="instagramUserTaggerStore.clearUserTags"
        :on-click="() => switchSubScreen('tagPeople')"
        class="tagPeopleSettingItem"
        empty="Add People Tags"
        left-icon="user"
        label="People Tags"
      />
      <PostSettingItem
        v-if="showCollaborators"
        :input-tooltip="inviteCollaboratorsToolTip"
        :disabled="inviteCollaboratorsDisabled"
        :title="instagramUserTaggerStore.getTagsLabel('collaborator')"
        :clear-action="instagramUserTaggerStore.clearCollaborators"
        :on-click="() => switchSubScreen('inviteCollaborators')"
        class="tagPeopleSettingItem"
        empty="Invite Collaborators"
        left-icon="paperplane"
        label="Collaborators"
        :label-info-tooltip="toolTips.instagramInviteCollaborators"
      />
      <PostSettingItem
        v-if="canAutoPublishInstagram"
        :input-tooltip="tagProductsTooltip"
        :disabled="tagProductsDisabled"
        :title="shoppingTagsLabel"
        :on-click="() => switchSubScreen('tagProducts')"
        empty="Add Product Tags"
        left-icon="tag"
        label="Instagram Shopping"
      />
      <PostSettingItem
        :input-tooltip="linksDisabledToolTip"
        :title="linksLabel"
        :disabled="linksDisabled || disableEditing"
        :clear-action="resetLinks"
        :on-click="openLinks"
        :show-tooltip="mediaUpdated"
        empty="Add Links"
        left-icon="link"
        label="Links"
        :label-info-tooltip="toolTips.instagramLink"
      />
      <PostSettingItem
        :disabled="disableEditing"
        :title="addToLabel"
        :clear-action="resetAddTo"
        :on-click="() => switchSubScreen('addTo')"
        empty="After Published, Add to"
        left-icon="folderAdd"
        :label="addToAfterLabel"
      />
      <Controls class="controls">
        <Button
          v-if="!disableEditing && post && post.id"
          v-tooltip="'Remove from Scheduler'"
          remove
          class="remove"
          data-cy="instagram-post-remove-button"
          @click="onRemoveClick"
        >
          Remove
        </Button>
        <SplitButton
          v-if="!disableEditing"
          :tooltip="saveTooltip"
          :disabled="saveDisabled"
          :loading="schedulerStore.postActionPending || publishing || creatingThumbnailUrl"
          :options="saveOptions"
          class="save-button"
          data-cy="instagram-feed-post-save-button"
          primary
          @click="save"
        >
          Save
        </SplitButton>
        <DropdownButton
          v-else
          :dropdown-list="duplicatePostOptions"
          items-center
          button-classes="primary"
          primary
          data-cy="duplicate-post-button"
        >
          <template #buttonContent>Duplicate Post</template>
        </DropdownButton>
      </Controls>
    </template>

    <!-- Add location subscreen -->
    <AddLocation
      v-if="currentScreen === 'location'"
      :loading="schedulerStore.pending.instagramLocationSearchList"
      :location="location"
      :on-back="() => switchSubScreen('index')"
      :on-location-selected="locationSelected"
      title-key="name"
      sub-title-key=""
      platform="instagram"
    />

    <!-- Tag people subscreen -->
    <InstagramTagPeople
      v-if="currentScreen === 'tagPeople' || currentScreen === 'inviteCollaborators'"
      :on-back="() => switchSubScreen('index')"
      :on-tags-changed="onTagsChanged"
      :tag-type="tagType"
      :is-carousel="isCarousel"
    />

    <InstagramShoppingTags
      v-if="currentScreen === 'tagProducts'"
      :on-back="() => switchSubScreen('index')"
      :on-shopping-tags-changed="onShoppingTagsChanged"
      :saved-tags="post && post.shoppingTags"
      :product-tagging-view-only="productTaggingViewOnly"
    />

    <!-- Select post time subscreen -->
    <SubScreen
      v-if="currentScreen === 'schedule'"
      title="Choose Date & Time"
      @on-back="switchSubScreen('index')"
    >
      <template #content>
        <section>
          <Calendar
            :posts="schedulerStore.getPosts(brandInstagramPosts)"
            :ideal-post-times="bestTimesToPost"
            :time-selected="timeSelected"
            :on-fetch-posts="onCalendarFetch"
            :restrict-selection-to-interval="canPublishWithin"
            platform="instagram"
            type="compact"
          />
        </section>
      </template>
    </SubScreen>

    <!-- Select board to add to subscreen -->
    <AddToGallery
      v-if="currentScreen === 'addTo'"
      :selected-galleries="selectedGalleries"
      :on-galleries-selected="onGalleriesSelected"
      :on-back="() => switchSubScreen('index')"
      type="INSTAGRAM"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import { useTrackingStore } from '@/stores/tracking';
import { useAuthStore } from '@/stores/auth';
import { useNotificationStore } from '@/stores/notification';
import { useCommentingStore } from '@/stores/commenting';
import { extractHashtags } from '@/utils/text-utils';
import {
  maxInstagramCaptionLength,
  maxInstagramCommentLength,
  maxInstagramHashtags,
  toolTips,
  UPLOAD_STATUS,
  hashtagValidationDebounceDelay,
} from '@/config';
import {
  ThumbnailUrlGenerator,
  videoPredictionMixpanelEvent,
  checkUnsavedChanges,
  allMediaUploaded,
  getUnscheduledRouteName,
} from '@/app/scheduler/utils';
import {
  decodeFromTiptapForInstagramFeed,
  encodeForTiptapFromInstagramFeed,
} from '@/app/scheduler/utils/tiptap';
import { isPosted } from '@/app/scheduler/utils/post-status';
import PostDuplicationMixin from '@/mixins/postDuplicationMixin';
import PostSettingItemLabelMixin from '@/app/scheduler/mixins/PostSettingItemLabelMixin';
import AddLocation from '@/app/scheduler/components/EditPost/AddLocation.vue';
import Button from '@/components/foundation/Button.vue';
import Calendar from '@/app/scheduler/components/Calendar.vue';
import SplitButton from '@/components/foundation/SplitButton.vue';
import Banner from '@/components/foundation/feedback/Banner.vue';
import QuickSelectCalendar from '@/app/scheduler/components/QuickSelectCalendar.vue';
import Textarea from '@/components/Textarea.vue';
import RichTextarea from '@/app/scheduler/components/EditPost/RichTextarea/RichTextarea.vue';
import {
  maxUserTags,
  tooManyUserTagsMessage,
  postStatus,
  postTypes,
  popUpMessages,
  somethingWentWrongMessage,
  tabContexts,
  firstCommentStatus,
  linkPromptMessage,
  thumbnailSourceType,
  invalidShoppingTagsWarningMessage,
  mixpanelActions,
  PLATFORMS,
  postPublishInfoTooltip,
  platformLabels,
  USER_TAG_TYPE,
} from '@/app/scheduler/constants';
import { FacebookAPI } from '@/apis';
import InstagramRateLimitMixin from '@/app/scheduler/mixins/InstagramRateLimitMixin';
import ApprovedPublishingDatesMixin from '@/app/scheduler/mixins/ApprovedPublishingDatesMixin';
import { BRAND, USER } from '@/models/auth/permissions.enum';
import { allPosts } from '@/app/scheduler/utils/post-query';
import { useSchedulerStore } from '@/stores/scheduler';
import { usePlatformStore } from '@/stores/platform';
import DropdownButton from '@/components/foundation/DropdownButton.vue';
import { PLATFORM_CONNECTION } from '@/models/platform/platform-connection.enum';
import { useCropperStore } from '@/stores/cropper';
import { useMediaLinksStore } from '@/stores/media-links';
import { useFlagStore } from '@/stores/flag';
import { useInstagramPostsStore } from '@/stores/instagram-posts';
import { useCustomerJourneyStore } from '@/stores/customer-journey';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import { useInstagramShoppingTaggerStore } from '@/stores/instagram-shopping-tagger';
import { useInstagramUserTaggerStore } from '@/stores/instagram-user-tagger';
import { utmChannel } from '@/app/settings/components/Utm/const';
import { useImpersonatorStore } from '@/stores/impersonator';
import {
  getApprovalChipText,
  getApprovalRequestTitle,
  postRequiresApproval,
} from '@/app/scheduler/utils/approvals';
import { postTypeLabelMap, SchedulerUserEventTracker } from '@/app/scheduler/mixpanel';
import PublishOptionHeader from '@/app/scheduler/components/PublishOptionHeader.vue';
import AddToGallery from './AddToGallery.vue';
import AutoPublishOptions from '../AutoPublishOptions.vue';
import Controls from './Layout/Controls.vue';
import FirstCommentStatusBox from '../FirstCommentStatusBox.vue';
import InstagramShoppingTags from './InstagramShoppingTags.vue';
import InstagramTagPeople from './InstagramTagPeople.vue';
import PostSettingItem from '../PostSettingItem.vue';
import PostStatusBox from '../PostStatusBox.vue';
import SubScreen from './Layout/SubScreen.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'EditInstagramFeedPost',
  components: {
    PublishOptionHeader,
    AddLocation,
    AddToGallery,
    AutoPublishOptions,
    Button,
    Calendar,
    Controls,
    DropdownButton,
    FirstCommentStatusBox,
    Banner,
    InstagramShoppingTags,
    InstagramTagPeople,
    PostSettingItem,
    PostStatusBox,
    QuickSelectCalendar,
    RichTextarea,
    SplitButton,
    SubScreen,
    Textarea,
    InfoTooltip,
  },
  mixins: [
    PostDuplicationMixin,
    InstagramRateLimitMixin,
    PostSettingItemLabelMixin,
    ApprovedPublishingDatesMixin,
  ],
  props: {
    close: { type: Function, required: true },
    context: { type: String, default: null },
    mediaCaption: { type: String, default: null },
    invalidUserTagsWarning: { type: String, default: null },
    invalidCollaboratorsWarning: { type: String, default: null },
    isActiveTab: { type: Boolean, default: true },
    post: { type: Object, default: null },
    uploadPending: { type: Boolean, default: false },
    mediaValidationError: { type: Object, default: null },
    isValidatingMedia: { type: Boolean, default: false },
    // Provide these next two values if re-gramming
    disableEditing: { type: Boolean, default: false },
    media: { type: Array, default: null },
    mediaValidationPending: { type: Boolean, required: true },
    bestTimesToPost: { type: Array, default: () => [] },
  },
  emits: ['updateUnsavedStatus', 'postDataUpdated'],
  data() {
    return {
      autoPublish: this.post?.auto_publish || false,
      caption: this.mediaCaption || (this.post && this.post.caption) || '',
      creatingThumbnailUrl: false,
      currentLinkTab: 'single',
      currentScreen: 'index',
      productTaggingViewOnly:
        ['POSTED', 'IMPORTED'].includes(this.post?.status) && this.post?.hasInvalidProductTags,
      userClearedMediaLinks: false,
      disableAutoPublishDueToAPILimit: false,
      disablePublishNowDueToAPILimit: false,
      firstComment: (this.post && this.post.first_comment) || '',
      isCaptionValid: true,
      isFirstCommentValid: true,
      isPublishNow: false,
      isSaving: false,
      linksPrompt: false,
      linktabs: ['Single-Link', 'Multi-Link'],
      loadingShoppingTags: false,
      location: this.post && this.post.location,
      collaborators: (this.post && this.post.collaborators) || [],
      approvalRequests: (this.post && this.post.approval_requests) || [],
      maxInstagramCaptionLength,
      maxInstagramHashtags,
      maxUserTags,
      mediaUpdated: false,
      publishing: false,
      scheduledTime: this.post && this.post.timestamp && dayjs(this.post.timestamp),
      selectedGalleries: [
        ...(this.post?.campaigns?.map((campaignObj) => ({ ...campaignObj, campaign: true })) ?? []),
        ...(this.post?.boards ?? []),
      ],
      singleLink: null,
      shoppingTagsPrompt: false,
      tagsPrompt: false,
      thumbnailChanged: false,
      thumbnailError: false,
      thumbnailUrl: (this.post && this.post.thumbnail_url) || null,
      thumbnailUrlGenerator: new ThumbnailUrlGenerator(),
      tooltipTimer: null,
      userTags: (this.post && this.post.user_tags) || [],
      shoppingTags: this.post?.shopping_tags || [],
      toolTips,
      invalidShoppingTagsWarningMessage,
      captionHashtagsValid: true,
      firstCommentHashtagsValid: true,
      isDraft: (!this.post?.id && !this.post?.timestamp) || this.post?.status === postStatus.DRAFT,
    };
  },
  computed: {
    ...mapStores(
      useCustomerJourneyStore,
      useCommentingStore,
      useSchedulerStore,
      useAuthStore,
      useNotificationStore,
      usePlatformStore,
      useCropperStore,
      useMediaLinksStore,
      useFlagStore,
      useInstagramPostsStore,
      useInstagramShoppingTaggerStore,
      useInstagramUserTaggerStore,
      useTrackingStore,
      useImpersonatorStore,
    ),
    brandInstagramPosts() {
      return allPosts.forBrand(this.authStore.currentBrand.id).forPlatform(PLATFORMS.INSTAGRAM);
    },
    selectedBoardIds() {
      return this.selectedGalleries.filter((g) => !g.campaign).map((g) => g.id);
    },
    selectedCampaignIds() {
      return this.selectedGalleries.filter((g) => g.campaign).map((g) => g.id);
    },
    mediaList() {
      return this.media;
    },
    postData() {
      const data = {
        platform: PLATFORMS.INSTAGRAM,
        id: this.post?.id,
        auto_publish: this.autoPublish,
        board_ids: this.selectedBoardIds,
        campaign_ids: this.selectedCampaignIds,
        brand_id: this.authStore.currentBrand.id,
        caption: this.caption,
        first_comment: this.firstComment,
        location_id: (this.location && this.location.id) || null,
        location: this.location,
        media: this.media,
        media_ids: this.media.map((m) => m.id),
        post_type: postTypes.FEED,
        timestamp: this.scheduledTime && this.scheduledTime.toISOString(),
        ...(this.isDraft && this.flagStore.ready && this.flagStore.flags.schedulerDrafts
          ? { status: postStatus.DRAFT }
          : {}),
        thumb_offset:
          parseInt(this.schedulerStore.thumbOffset, 10) >= 0
            ? this.schedulerStore.thumbOffset * 1000
            : null, // Multiply by 1000 to convert to milliseconds
        thumbnail_url: this.thumbnailUrl,
        user_tags: this.instagramUserTaggerStore.taggedUsers.map((tag) => {
          if (tag.x !== null && tag.y !== null) {
            return {
              username: tag.username,
              media_id: parseInt(tag.mediaId, 10),
              x: tag.x,
              y: tag.y,
            };
          }
          return {
            username: tag.username,
            media_id: parseInt(tag.mediaId, 10),
          };
        }),
        collaborators: this.instagramUserTaggerStore.collaborators,
        approvalRequests: this.approvalRequests,
        ...(this.post?.approvalPolicy ? { approval_policy: this.post.approvalPolicy } : {}),
      };

      const mediaIds = this.media.map((m) => m.id);
      data.shopping_tags = this.instagramShoppingTaggerStore.taggedProducts
        .filter((tag) => mediaIds.includes(parseInt(tag.mediaId, 10)))
        .map((tag) => {
          return {
            fb_product_id: tag.fbProductId,
            media_id: parseInt(tag.mediaId, 10),
            x: tag.x,
            y: tag.y,
            title: tag.title,
            image_url: tag.imageUrl,
            catalog_id: this.instagramShoppingTaggerStore.catalogs?.[0]?.catalog_id,
          };
        });

      return data;
    },
    duplicatePostData() {
      return {
        ...this.postData,
        boards: this.selectedGalleries.filter((g) => !g.campaign),
        campaigns: this.selectedGalleries.filter((g) => g.campaign),
      };
    },
    showMultipleInfoBoxes() {
      return !!(
        this.post?.invalid_user_tags ||
        this.post?.invalid_location ||
        this.firstCommentFailed
      );
    },
    isAScheduledPost() {
      return !!this?.scheduledTime;
    },
    disableUserTags() {
      return !this.schedulerStore.autoPublish;
    },
    linksPromptMessage() {
      return linkPromptMessage;
    },
    linksDisabledToolTip() {
      if (this.linksDisabled && !this.disableEditing) {
        return 'You must add media to your post before adding product links';
      }
      return '';
    },
    showInformationBox() {
      return (
        (this.linksPrompt && this.schedulerStore.instagramCarouselPage === 0) ||
        (this.tagsPrompt && !this.disableUserTags) ||
        this.shoppingTagsPrompt
      );
    },
    timestampAddedOrRemoved() {
      return (
        (!this.scheduledTime && this.post && this.post.timestamp) ||
        (!this.post.timestamp && this.scheduledTime)
      );
    },
    locationLabel() {
      return this.location ? this.location.name : null;
    },
    addLocationTooltip() {
      return !this.autoPublish ? toolTips.locationOnlyAllowedInInsAutoPublish : null;
    },
    firstCommentDisabledTooltip() {
      return !this.autoPublish && !this.disableEditing
        ? toolTips.firstCommentOnlyAllowedForAutoPublish
        : null;
    },
    tagPeopleTooltip() {
      if (this.autoPublish && this.media.length === 0) {
        return toolTips.tagPeopleOnlyAllowedInInsWhenMediaSelected;
      }
      if (!this.autoPublish) {
        return toolTips.tagPeopleOnlyAllowedInInsAutoPublish;
      }
      return null;
    },
    inviteCollaboratorsToolTip() {
      if (!this.autoPublish) {
        return toolTips.inviteCollaboratorsDisabled;
      }
      if (this.instagramUserTaggerStore.taggedUsers.length >= maxUserTags) {
        return tooManyUserTagsMessage;
      }
      return null;
    },
    tagProductsTooltip() {
      return !this.autoPublish ? toolTips.tagProductsOnlyAllowedInInsAutoPublish : null;
    },
    canAutoPublishInstagram() {
      return this.authStore.guard(BRAND.SCHEDULER.CAN_AUTO_PUBLISH_INSTAGRAM);
    },
    showCollaborators() {
      return this.canAutoPublishInstagram && this.hasCollaboratorFeatureFlag;
    },
    hasApprovalRequestsAccess() {
      return !this.customerJourneyStore.hasPlanGrow;
    },
    isVideoPost() {
      return this.media.length > 0 && this.media.every((media) => media.type === 'VIDEO');
    },
    containsVideo() {
      return this.media.length > 0 && this.media.some((media) => media.type === 'VIDEO');
    },
    isMediaTypeVideo() {
      return this.media[0]?.type === 'VIDEO';
    },
    firstCommentFailed() {
      return (
        this.post &&
        this.post.first_comment_status &&
        this.post.first_comment_status === firstCommentStatus.FAILED
      );
    },
    scheduledTimeLabel() {
      return this.scheduledTime ? this.scheduledTime.format('LT, ll') : null;
    },
    shoppingTagsLabel() {
      if (this.instagramShoppingTaggerStore.taggedProducts.length > 0) {
        const numTags = this.instagramShoppingTaggerStore.taggedProducts.length;
        const plural = numTags > 1 ? 's' : '';
        return `${numTags} Product${plural}`;
      }
      return null;
    },
    linksLabel() {
      if (!this.userClearedMediaLinks && this.mediaLinksStore.mediaLinks.length > 0) {
        if (this.mediaLinksStore.mediaLinks.length === 1) {
          return this.mediaLinksStore.mediaLinks[0].url;
        }
        return `${this.mediaLinksStore.mediaLinks.length} links`;
      }
      return null;
    },
    addToLabel() {
      if (this.selectedGalleries.length) {
        return this.selectedGalleries.map((g) => g.name).join(', ');
      }
      return null;
    },
    tagPeopleDisabled() {
      return this.disableEditing || !this.autoPublish;
    },
    tagProductsDisabled() {
      return (this.disableEditing && !this.productTaggingViewOnly) || !this.autoPublish;
    },
    linksDisabled() {
      return (
        this.media.length === 0 ||
        (this.media[0].uploadStatus && this.media[0].uploadStatus !== UPLOAD_STATUS.SUCCESS)
      );
    },
    saveDisabled() {
      return (
        (this.media.length === 0 && this.autoPublish) ||
        this.uploadPending ||
        this.mediaValidationError?.level === 'error' ||
        this.isValidatingMedia ||
        this.isSaving ||
        this.publishing ||
        this.creatingThumbnailUrl ||
        (this.scheduledTime && this.scheduledTime < dayjs.utc(dayjs()).local()) ||
        this.caption.length > maxInstagramCaptionLength ||
        this.firstComment.length > maxInstagramCommentLength ||
        !this.captionHashtagsValid ||
        !this.firstCommentHashtagsValid ||
        this.invalidShoppingTags ||
        this.loadingShoppingTags ||
        !!this.publishDateError
      );
    },
    saveTooltip() {
      if (this.uploadPending) {
        return { content: toolTips.mediaUploading };
      }
      if (this.invalidShoppingTags) {
        return { content: invalidShoppingTagsWarningMessage };
      }
      if (this.mediaValidationError?.level === 'error') {
        return { content: this.mediaValidationError.message };
      }
      if (this.scheduledTime && this.scheduledTime < dayjs.utc(dayjs()).local()) {
        return { content: toolTips.illegalTimeStamp };
      }
      if (this.caption.length > maxInstagramCaptionLength) {
        return { content: toolTips.maxIGCharactersReached };
      }
      if (this.firstComment.length > maxInstagramCommentLength) {
        return { content: toolTips.maxIGCommentCharactersReached };
      }
      if (this.loadingShoppingTags) {
        return { content: toolTips.shoppingTagsLoading };
      }
      if (!this.captionHashtagsValid || !this.firstCommentHashtagsValid) {
        return { content: toolTips.maxIGHashtagsReached };
      }
      return null;
    },
    isCarousel() {
      return this.media.length > 1;
    },
    disableAutoPublish() {
      if (!this.mediaValidationPending && !allMediaUploaded(this.media)) {
        return { isDisabled: true, tip: toolTips.noMediaSelectedAutoPublish };
      }
      if (this.disableAutoPublishDueToAPILimit) {
        return {
          isDisabled: true,
          tip: toolTips.maxDailyIGAutoPublishPostsReached(this.maxDailyInstagramAutoPublishPosts),
        };
      }
      return { isDisabled: false, tip: '' };
    },
    isSaveDropdownDisabled() {
      const isImpersonating = this.impersonatorStore.isImpersonating;
      const isDisabledDueToInsufficientPermission =
        !this.authStore.guard(BRAND.SCHEDULER.CAN_AUTO_PUBLISH_INSTAGRAM) ||
        !this.authStore.guard(USER.SCHEDULER.CAN_AUTO_PUBLISH_INSTAGRAM) ||
        isImpersonating;
      const isDisabledDueToNoMedia = this.media.length < 1;
      const isDisabledDueToNotAutoPublish = !this.autoPublish;

      // Limit from https://developers.facebook.com/docs/instagram-api/guides/content-publishing/#limitations-2
      const isDisabledDueToMediaCapacityExceeded = this.media.length > 10;
      if (isDisabledDueToInsufficientPermission) {
        return { isDisabled: true, tip: toolTips.insufficientPermission };
      }
      if (isDisabledDueToNoMedia) {
        return { isDisabled: true, tip: toolTips.noMediaSelected };
      }
      if (isDisabledDueToNotAutoPublish) {
        return { isDisabled: true, tip: toolTips.notAutoPublish };
      }
      if (isDisabledDueToMediaCapacityExceeded) {
        return { isDisabled: true, tip: toolTips.igMediaCapacityExceeded };
      }
      if (this.disablePublishNowDueToAPILimit) {
        return {
          isDisabled: true,
          tip: toolTips.maxDailyIGAutoPublishPostsReached(this.maxDailyInstagramAutoPublishPosts),
        };
      }
      if (!this.canAssetPublishToday) {
        return { isDisabled: true, tip: this.publishNowWarning };
      }
      if (this.requiresApproval(this.post)) {
        return { isDisabled: true, tip: toolTips.requiresApproval };
      }
      return false;
    },
    duplicatePostOptions() {
      return this.pdDuplicationOptions(this.duplicate);
    },
    saveOptions() {
      return [
        {
          text: 'Save & Duplicate',
          subList: this.pdDuplicationOptions(this.saveAndDuplicate),
        },
        {
          text: 'Publish Now',
          action: this.publishNow,
          disabled: this.isSaveDropdownDisabled.isDisabled,
          tooltip: this.isSaveDropdownDisabled.tip,
        },
        {
          text: 'Publish Now & Duplicate',
          disabled: this.isSaveDropdownDisabled.isDisabled,
          tooltip: this.isSaveDropdownDisabled.tip,
          subList: this.pdDuplicationOptions(this.publishNowAndDuplicate),
        },
      ];
    },
    invalidLocationWarning() {
      if (this.post?.invalid_location && isPosted(this.post, {})) {
        return `We were unable to include the location ${this.post.invalid_location.name} due to a Facebook error`;
      }
      return null;
    },
    firstCommentHTML: {
      get() {
        return encodeForTiptapFromInstagramFeed(this.firstComment);
      },
      set(val) {
        this.firstComment = decodeFromTiptapForInstagramFeed(val);
      },
    },
    hasPublishedCollaborators() {
      return this.post && isPosted(this.post, {}) && this.collaborators?.length > 0;
    },
    invalidShoppingTags() {
      return this.instagramShoppingTaggerStore.taggedProducts.some((t) => t.error);
    },
    captionTooltip() {
      return postPublishInfoTooltip.IG_FEED_CAPTION;
    },
    firstCommentTooltip() {
      return postPublishInfoTooltip.FIRST_COMMENT;
    },
    platformLabels() {
      return platformLabels;
    },
    hasCollaboratorFeatureFlag() {
      return this.flagStore.ready && this.flagStore.flags.instagramCollabPosts;
    },
    tagType() {
      return this.currentScreen === 'inviteCollaborators'
        ? USER_TAG_TYPE.COLLABORATOR
        : USER_TAG_TYPE.MEDIA_TAG;
    },
    inviteCollaboratorsDisabled() {
      return (
        this.disableEditing ||
        !this.autoPublish ||
        this.instagramUserTaggerStore.taggedUsers.length >= maxUserTags
      );
    },
    isDraftToggleDisabled() {
      const isPublished = [
        postStatus.POSTED,
        postStatus.AUTOPUBLISHING,
        postStatus.IMPORTED,
      ].includes(this.post?.status);
      return isPublished || !this.postData?.timestamp;
    },
    trackMixpanel() {
      return new SchedulerUserEventTracker('Scheduler Editor');
    },
  },
  watch: {
    'cropperStore.adjustLinksPrompt': {
      handler(newVal) {
        this.linksPrompt = newVal;
      },
    },
    'cropperStore.adjustTagsPrompt': {
      handler(newVal) {
        this.tagsPrompt = newVal;
      },
    },
    'cropperStore.adjustShoppingTagsPrompt': {
      handler(newVal) {
        this.shoppingTagsPrompt = newVal;
      },
    },
    disableAutoPublishDueToAPILimit() {
      if (this.disableAutoPublishDueToAPILimit && this.autoPublish) {
        this.autoPublish = false;
      }
    },
    'mediaLinksStore.linkUpdateStatus': function linkUpdateStatusWatcher(newVal) {
      if (newVal === 'success') {
        this.notificationStore.setToast({ message: 'Post links updated.' });
      }
    },
    scheduledTime() {
      this.$emit('updateUnsavedStatus', this.checkUnsavedStatus());
      this.validateMaxAutoPublishPosts();
    },
    autoPublish(isAutoPublish) {
      this.schedulerStore.setAutoPublish(this.autoPublish);
      if (isAutoPublish) {
        if (this.schedulerStore.instagramTabContext === tabContexts.FEED_POST) {
          this.confirmAutoPublishConnection();
          // Only update the thumbnail offset when under Feed tab to prevent
          // unwanted behaviour when toggling publish type in Reel editor with cover photo.
          if (
            this.isMediaTypeVideo &&
            this.media[0]?.duration &&
            !this.media[0]?.videoSuggestedThumbnailList
          ) {
            const offset = Math.round((this.media[0].duration / 2) * 10) / 10;
            this.schedulerStore.setThumbOffset(offset);
          }
        }
      }
    },
    'schedulerStore.thumbOffset': {
      handler(newVal) {
        // sets thumbnail changed to true, every time the pop up is opened
        // prevent this if value is same as post
        if (!this.post || !this.post.thumb_offset || this.post.thumb_offset !== newVal * 1000) {
          this.thumbnailChanged = true;
          this.thumbnailUrl = null;
        }
      },
    },
    'schedulerStore.approvalRequests': {
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) this.approvalRequests = newVal;
      },
    },
    postData(to, from) {
      if (this.isActiveTab) {
        const valuesDiffer = (a, b) => !!a !== !!b || (!!a && !!b && !isEqual(a, b));
        const newPostData = this.post?.id ? to : { ...to, auto_publish: null };
        const oldPostData = this.post?.id ? from : { ...from, auto_publish: null };
        const hasChanges = Object.keys(newPostData).some((field) =>
          valuesDiffer(newPostData[field], oldPostData[field]),
        );
        if (hasChanges) {
          this.$emit('updateUnsavedStatus', this.checkUnsavedStatus());
        }
        if (to.caption !== from.caption) {
          this.validateHashtags('captionHashtagsValid');
        }
        if (to.first_comment !== from.first_comment) {
          this.validateHashtags('firstCommentHashtagsValid');
        }
      }
      if (!to?.timestamp) this.isDraft = true;
      this.$emit('postDataUpdated', to);
    },
    media: {
      deep: true,
      handler(newVal, oldVal) {
        // always show notification when a link is gone with the first media.
        if (!isEqual(newVal[0], oldVal[0]) && oldVal[0] && this.mediaLinksStore.mediaLinks.length) {
          this.handleMediaUpdateTooltip();
        }
        if (newVal.length === 0) {
          this.userClearedMediaLinks = false;
          this.mediaLinksStore.clearMediaLinks();
          // If there's no media & auto publish is selected, change back to send notification
          if (this.autoPublish) {
            this.autoPublish = false;
            this.schedulerStore.setAutoPublish(this.autoPublish);
          }
        } else if (!isEqual(newVal[0], oldVal[0])) {
          this.userClearedMediaLinks = false;
          // If the first media item has changed, update data for the links popup
          if (!newVal[0].uploadStatus) {
            this.mediaLinksStore.listMediaLinks({
              brandId: this.authStore.currentBrand.id,
              mediaId: newVal[0].id,
            });
          } else if (newVal[0].uploadStatus === UPLOAD_STATUS.SUCCESS) {
            this.mediaLinksStore.listMediaLinks({
              brandId: this.authStore.currentBrand.id,
              mediaId: newVal[0].fullMediaObject.id,
            });
          } else if (newVal[0].uploadStatus !== UPLOAD_STATUS.SUCCESS) {
            this.mediaLinksStore.clearMediaLinks();
          }
        }

        if (
          this.instagramUserTaggerStore.taggedUsers.length > 0 &&
          newVal.length > 1 &&
          this.containsVideo
        ) {
          let videoMediaId;
          newVal.forEach((currentVal) => {
            if (currentVal.type === 'VIDEO') {
              this.instagramUserTaggerStore.taggedUsers.forEach((taggedUser) => {
                if (taggedUser.mediaId === currentVal.id.toString()) {
                  videoMediaId = taggedUser.mediaId;
                }
              });
            }
            if (videoMediaId) {
              this.instagramUserTaggerStore.deleteMediaUserTags({ mediaId: videoMediaId });
            }
          });
        }
        // Use caption on media if exists
        const originalPostMediaId = this.post?.mediaIds?.[0];
        if (
          newVal.length === 1 &&
          newVal[0].instagram?.caption &&
          !this.caption &&
          // Do not load caption if user already cleared it when previously editing
          newVal[0].id !== originalPostMediaId
        ) {
          let newCaption = newVal[0].instagram.caption;
          // And append the handle if it exists
          const handle = newVal[0].instagram.instagramUser?.handle || null;
          if (handle) {
            newCaption += ` | @${handle}`;
          }
          this.caption = newCaption;
        }
      },
    },
  },
  async created() {
    // Set state on Auto Publish
    this.schedulerStore.setAutoPublish(this.autoPublish);
    this.instagramShoppingTaggerStore.setShoppingTaggerPostId({ postId: this.post?.id ?? null });

    this.validateMaxAutoPublishPosts();

    // Set media detail so links popup shows the correct media
    if (this.media && this.media.length > 0) {
      this.mediaLinksStore.listMediaLinks({
        brandId: this.authStore.currentBrand.id,
        mediaId: this.media[0].id,
      });
    }

    // Set utm channel and fetch utm settings
    this.mediaLinksStore.utmChannel = utmChannel.INSTAGRAM_LIKESHOP;
    this.mediaLinksStore.fetchUtmSettings();

    if (this.userTags.length > 0) {
      const convertedUserTags = this.userTags.map((tag) => {
        return {
          username: tag.username,
          mediaId: tag.media_id ?? this.post?.media?.[0].id,
          x: tag.x,
          y: tag.y,
        };
      });
      this.instagramUserTaggerStore.setUserTags({ tags: convertedUserTags });
    }

    if (this.collaborators.length > 0) {
      this.instagramUserTaggerStore.collaborators = this.collaborators.map((collaborator) => {
        return { username: collaborator.username };
      });
    }

    if (this.shoppingTags.length > 0) {
      this.loadingShoppingTags = true;
      const productIds = this.shoppingTags.map((tag) => tag.fb_product_id);
      const response = await FacebookAPI.instagramProductTags.searchProductsByIds({
        brandId: this.authStore.currentBrand.id,
        params: { productIds },
      });
      const products = Object.fromEntries(
        response.data.map((product) => [product.fbProductId, product]),
      );

      const convertedShoppingTags = this.shoppingTags.map((tag) => {
        const product = products[tag.fb_product_id];
        return {
          ...product,
          mediaId: tag.media_id,
          x: tag.x,
          y: tag.y,
        };
      });
      this.instagramShoppingTaggerStore.setShoppingTags({
        shoppingTags: convertedShoppingTags,
        postId: this.post?.id ?? null,
      });
      this.loadingShoppingTags = false;
    }
  },
  activated() {
    this.schedulerStore.setActiveSubScreen(this.currentScreen);
  },
  beforeUnmount() {
    this.thumbnailUrlGenerator.destroy();
  },
  unmounted() {
    this.mediaLinksStore.clearMediaLinks();
    this.instagramUserTaggerStore.clearUserTags();
    this.instagramUserTaggerStore.clearCollaborators();
    this.instagramShoppingTaggerStore.clearShoppingTags();
    this.cropperStore.removeAdjustLinksPrompt();
    this.cropperStore.removeAdjustTagsPrompt();
    this.cropperStore.removeAdjustShoppingTagsPrompt();
  },
  methods: {
    getApprovalRequestTitle,
    getApprovalChipText,
    switchSubScreen(screen) {
      if (!this.disableEditing || this.productTaggingViewOnly) {
        // hide tooltip if change sub-screen.
        this.mediaUpdated = false;
        this.currentScreen = screen;
        this.schedulerStore.setActiveSubScreen(this.currentScreen);
        // Editing reel user tags
        if (screen === 'tagPeople' && this.media.length === 1 && this.isVideoPost) {
          this.instagramUserTaggerStore.setEditingUserTag({ value: true });
        }
        if (screen === 'schedule') {
          this.trackingStore.track(mixpanelActions.SCHEDULER_CALENDAR_OPENED, {
            postPlatform: 'Instagram',
            quickSelectClicked: false,
          });
        } else if (screen === 'inviteCollaborators') {
          this.trackingStore.track(mixpanelActions.INVITE_COLLABORATORS_CLICKED, {
            postPlatform: 'Instagram',
            postType: tabContexts.FEED_POST,
          });
        }
      }
    },
    async duplicate({ toType }) {
      // Have to define duplicateData within these functions so computed values
      // are not lost when current post is closed.
      const duplicateData = this.duplicatePostData;
      this.productTaggingViewOnly = false;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.INSTAGRAM_POST,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.DUPLICATE_FROM_PUBLISHED_CHANNEL,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      this.pdDuplicatePostToChannel({
        toType,
        fromType: this.DUPLICATION_POST_TYPES.INSTAGRAM_POST,
        post: duplicateData,
      });
    },
    async publishNowAndDuplicate({ toType }) {
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.INSTAGRAM_POST,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.PUBLISH_NOW_AND_DUPLICATE,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      await this.publishNow(false);
      if (!duplicateData?.id) {
        duplicateData.id = this.schedulerStore.duplicationPostId;
      }
      this.pdDuplicatePostToChannel({
        toType,
        fromType: this.DUPLICATION_POST_TYPES.INSTAGRAM_POST,
        post: duplicateData,
      });
    },
    async saveAndDuplicate({ toType }) {
      const duplicateData = this.duplicatePostData;
      const postDuplicationData = {
        duplicatedFrom: this.DUPLICATION_POST_TYPES.INSTAGRAM_POST,
        duplicatedTo: toType,
        duplicationType: this.DUPLICATION_TYPES.SAVE_AND_DUPLICATE,
      };
      this.trackingStore.track(mixpanelActions.DUPLICATE_POST_CLICKED, postDuplicationData);
      await this.save(false);
      if (!duplicateData?.id) {
        duplicateData.id = this.schedulerStore.duplicationPostId;
      }
      this.pdDuplicatePostToChannel({
        toType,
        fromType: this.DUPLICATION_POST_TYPES.INSTAGRAM_POST,
        post: duplicateData,
      });
    },
    locationSelected(location) {
      this.location = location;
      this.switchSubScreen('index');
      this.trackingStore.track('Instagram Location Tagged', { location });
    },
    resetLocation() {
      this.location = null;
    },
    timeSelected(time) {
      if (!this.scheduledTime) this.isDraft = false;
      this.scheduledTime = dayjs(time);
      this.switchSubScreen('index');
    },
    resetTime() {
      this.scheduledTime = null;
    },
    resetLinks() {
      this.userClearedMediaLinks = true;
      this.mediaLinksStore.clearMediaLinks();
    },
    resetAddTo() {
      this.selectedGalleries = [];
    },
    openLinks() {
      this.mediaLinksStore.utmChannel = utmChannel.INSTAGRAM_LIKESHOP;
      this.mediaLinksStore.utmTrackingData = {
        postId: this.post?.id,
        postPlatform: 'Instagram',
        postType: 'Post',
      };

      this.userClearedMediaLinks = false;
      const media = this.media[0]?.fullMediaObject ?? this.media[0];
      this.mediaLinksStore.openLinkPopup(media);

      const updateLinkData = {
        editFrom: 'Scheduler Popup',
        firstLink: this.mediaLinksStore.mediaLinks.length === 0,
      };
      this.trackingStore.track(mixpanelActions.LINK_UPDATE_CLICKED, updateLinkData);
    },
    onGalleriesSelected(galleries) {
      this.selectedGalleries = galleries;
    },
    onShoppingTagsChanged(shoppingTags) {
      this.cropperStore.removeAdjustShoppingTagsPrompt();
      this.trackingStore.track('Instagram Shopping Product Tagged', { shoppingTags });
    },
    onTagsChanged(userTags) {
      this.cropperStore.removeAdjustTagsPrompt();
      this.trackingStore.track('Instagram Account(s) Tagged', {
        userTags,
        postType: tabContexts.FEED_POST,
      });
    },
    onThumbnailError() {
      this.thumbnailError = true;
      const message = somethingWentWrongMessage;
      this.notificationStore.setToast({ message, type: 'error' });
    },
    handleMediaUpdateTooltip() {
      if (
        this.schedulerStore.instagramTabContext === tabContexts.FEED_POST &&
        !this.linksPrompt &&
        this.media.length > 0
      ) {
        this.tooltipTimer = null;
        this.mediaUpdated = true;
        this.tooltipTimer = setTimeout(() => {
          this.mediaUpdated = false;
        }, 3000);
      }
    },
    checkUnsavedStatus() {
      return checkUnsavedChanges(this.postData, this.post, this.scheduledTime);
    },
    async onRemoveClick() {
      const { title, message } = popUpMessages.removeFromScheduler;
      await this.notificationStore.confirm(title, message, {
        confirmAlias: 'Remove',
        onConfirm: this.remove,
      });
    },
    async remove() {
      await this.schedulerStore.deletePost(this.post);
      if (this.scheduledTime) {
        this.notificationStore.setToast({ message: 'Post removed from your timeline.' });
      } else {
        this.notificationStore.setToast({
          message: 'Post removed from your unscheduled posts.',
        });
      }
      this.close();
    },
    async save(closeAfter = true) {
      if (this.saveDisabled || this.schedulerStore.postActionPending) {
        return;
      }
      if (!this.isCaptionValid || !this.isFirstCommentValid) {
        return;
      }
      if (this.publishDateError) {
        return;
      }
      this.isSaving = true;
      await this.savePost(closeAfter);
    },
    async savePost(closeAfter = true) {
      // Create Thumbnail Image
      if (this.media.length > 0 && this.isMediaTypeVideo && this.thumbnailChanged) {
        this.creatingThumbnailUrl = true;
        this.thumbnailUrl = await this.thumbnailUrlGenerator.createThumbnailUrl(
          this.media,
          this.schedulerStore.thumbOffset,
          this.onThumbnailError,
        );
        this.creatingThumbnailUrl = false;
        if (this.thumbnailError) {
          this.thumbnailError = false;
          this.isSaving = false;
          return;
        }
      } else if ((!this.thumbnailChanged && !this.thumbnailUrl) || !this.isMediaTypeVideo) {
        this.schedulerStore.setThumbOffset(0);
        this.thumbnailUrl = null;
      }

      if (this.userClearedMediaLinks && this.media.length > 0) {
        this.mediaLinksStore.updateMediaLinks({
          brandId: this.authStore.currentBrand.id,
          mediaId: this.media[0].id,
          data: [],
        });
      }

      if (this.post?.id) {
        // Take value before updating post (as it then updates this.post)
        const timestampAddedOrRemoved = this.timestampAddedOrRemoved;
        await this.schedulerStore.updatePost(this.postData);
        if (timestampAddedOrRemoved) {
          if (this.scheduledTime) {
            this.notificationStore.setToast({
              message: 'Post updated & added to your timeline.',
            });
          } else {
            this.notificationStore.setToast({
              message: 'Post updated & added to your ',
              actionText: 'unscheduled posts.',
              actionTo: {
                name: getUnscheduledRouteName(PLATFORMS.INSTAGRAM),
              },
              sameLine: true,
            });
          }
        } else {
          this.notificationStore.setToast({ message: 'Post updated.' });
        }
      } else {
        await this.schedulerStore.createPost(this.postData);
        if (this.scheduledTime) {
          this.notificationStore.setToast({ message: 'Post added to your timeline.' });
        } else {
          this.notificationStore.setToast({
            message: 'Post added to your ',
            actionText: 'unscheduled posts.',
            actionTo: {
              name: getUnscheduledRouteName(PLATFORMS.INSTAGRAM),
            },
            sameLine: true,
          });
        }
      }

      this.handleMixpanelEvent();
      this.isSaving = false;
      if (closeAfter) {
        this.close();
      }
    },
    async publishNow(closeAfter = true) {
      if (this.confirmAutoPublishConnection()) {
        if (!this.isCaptionValid || !this.isFirstCommentValid) {
          return;
        }
        this.publishing = true;
        this.isPublishNow = true;

        if (this.isMediaTypeVideo && this.thumbnailChanged) {
          // Create Thumbnail Image
          this.creatingThumbnailUrl = true;
          this.thumbnailUrl = await this.thumbnailUrlGenerator.createThumbnailUrl(
            this.media,
            this.schedulerStore.thumbOffset,
            this.onThumbnailError,
          );
          this.creatingThumbnailUrl = false;
          if (this.thumbnailError) {
            this.thumbnailError = false;
            this.publishing = false;
            this.isPublishNow = false;
            return;
          }
        } else {
          this.schedulerStore.setThumbOffset(0);
          this.thumbnailUrl = null;
        }

        const data = {
          ...this.postData,
          status: postStatus.AUTOPUBLISHING,
        };
        if (this.post?.id) {
          await this.schedulerStore.updatePost(data);
        } else {
          await this.schedulerStore.createPost(data);
        }

        this.notificationStore.setToast({
          message: 'Publishing in progress.',
          actionText: 'See it on your Timeline',
          actionTo: { name: 'scheduler.instagram.timeline' },
          type: 'primary',
        });

        this.publishing = false;
        this.handleMixpanelEvent();
        if (closeAfter) {
          this.close();
        }
      }
    },
    handleMixpanelEvent() {
      if (this.schedulerStore.thumbnailSource === thumbnailSourceType.SLIDER) {
        this.trackingStore.track('Instagram Video Thumbnail Changed');
      } else if (this.schedulerStore.thumbnailSource === thumbnailSourceType.VISION) {
        const suggestionEvent = videoPredictionMixpanelEvent(
          this.media[0].videoSuggestedThumbnailList,
          this.schedulerStore.thumbOffset,
          this.authStore.currentBrand.id,
        );
        this.trackingStore.track(suggestionEvent.action, suggestionEvent.properties);
      }
    },
    async onCalendarFetch(start, end) {
      this.schedulerStore.fetchPosts(this.brandInstagramPosts.betweenDates(start, end));
    },
    confirmAutoPublishConnection() {
      if (!this.authStore.currentBrand) {
        return null;
      }
      const isConnected = this.platformStore.isPlatformConnected(PLATFORM_CONNECTION.FACEBOOK, {
        scopes: [PLATFORM_CONNECTION.FACEBOOK.SCOPES.INSTAGRAM_CONTENT_PUBLISH],
      });
      if (!isConnected) {
        this.platformStore.connect(PLATFORM_CONNECTION.FACEBOOK, {
          onCancel: () => this.cancelConnectionPopup(),
          title: 'Enable Auto Publishing',
          message:
            'To enable auto publishing for Instagram, please reconnect your Facebook account.',
        });
        return false;
      }
      return true;
    },
    cancelConnectionPopup() {
      this.autoPublish = false;
      this.schedulerStore.setAutoPublish(this.autoPublish);
    },
    dismissLinksPrompt() {
      this.cropperStore.removeAdjustLinksPrompt();
      this.cropperStore.removeAdjustShoppingTagsPrompt();
      this.cropperStore.removeAdjustTagsPrompt();
    },
    validateHashtags: debounce(function hashtagsValid(hashtagField) {
      this.captionHashtagsValid = true;
      this.firstCommentHashtagsValid = true;

      // extract caption hashtags
      const captionHashtags = extractHashtags(this.caption);

      // extract first comment hashtags
      const firstCommentHashtags = extractHashtags(this.firstComment);

      // max hashtags are enforced on total unique hashtags a post's caption plus comments
      const totalUniqueHashtags = new Set([...firstCommentHashtags, ...captionHashtags]);
      if (totalUniqueHashtags.size > maxInstagramHashtags) this[hashtagField] = false;
    }, hashtagValidationDebounceDelay),
    requiresApproval(post) {
      return postRequiresApproval({ post });
    },
    onToggleDraft(draft) {
      this.isDraft = draft;
      this.trackMixpanel.draftToggleClicked(
        draft,
        postTypeLabelMap.instagram,
        postTypeLabelMap.feed,
      );
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.section-title {
  display: flex;
  align-items: center;
}

.nav {
  display: flex;
  align-items: center;
  font-size: var(--x18);
  font-weight: var(--font-medium);
  margin: 0;

  .back {
    background: var(--background-300);
    width: var(--space-40);
    height: var(--space-32);
    display: flex;
    border: solid 1px var(--border);
    border-radius: var(--round-corner-small);
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: var(--space-16);
    transition: var(--transition-all);

    &:hover {
      transform: var(--hover-move);
    }
  }
}

.controls {
  position: sticky;
  bottom: 0;
}

.caption-header,
.first-comment-header {
  display: flex;
  justify-content: space-between;
}

.red {
  color: var(--error-500);
}

.first-comment-header {
  margin-top: var(--space-16);
}

.first-comment-textarea.disabled {
  opacity: 0.4;
  cursor: auto;
}

.link-tabs {
  display: flex;
  align-items: baseline;

  &:hover {
    transform: none;
  }
}

.link-editors {
  margin-top: var(--space-24);

  .empty-multi-link {
    height: 15rem;
    font-size: var(--x18);
    font-weight: var(--font-medium);
    color: var(--text-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.add-top-margin {
  margin-top: var(--space-32);
}

.empty-control {
  display: flex;
  justify-content: flex-start;
}

.add-to-content {
  height: 14.5rem;
}

input.popup-input {
  margin: var(--space-16) 0 13.5rem;
  width: 100%;
  height: var(--space-40);
  border: 1px solid var(--border);
  border-radius: var(--round-corner-small);
  padding: 0 var(--space-8);
  color: var(--text-primary);
  font-size: var(--x14);
}

.save-button {
  min-width: 10rem;
}

.information-box {
  .links-prompt {
    padding-bottom: var(--space-16);
    text-align: left;
  }

  .list-collaborator {
    list-style-type: disc;
    margin-left: 20px;
  }

  .links-prompt-buttons {
    display: flex;

    :deep(.xsmall) {
      min-height: var(--space-32);
      min-width: 148px;
      padding: 0 var(--space-12);
      margin-right: var(--space-8);
    }
  }
}

.invalid-field :deep(textarea),
.invalid-field :deep(.editor) {
  border: 1px solid var(--error-500);
  border-radius: var(--round-corner-small);
}

.invalid-tags-warning {
  margin-bottom: var(--space-16);
}
</style>
