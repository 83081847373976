<script setup>
import Chip from '@/components/foundation/Chip.vue';

const props = defineProps({
  option: {
    type: Object,
    default: null,
  },
  disabled: { type: Boolean, default: false },
  optionLabel: { type: String, default: 'label' },
  optionValue: { type: String, default: 'value' },
});

const emit = defineEmits(['remove']);
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <Chip
    v-if="props.option"
    :key="props.option[props.optionValue]"
    :title="props.option[props.optionLabel]"
    xxsmall
    remove
    :disabled="props.disabled"
    truncated-text-width="var(--width-196)"
    @remove="emit('remove', props.option)"
    ><span class="text-extra-small">{{ props.option[props.optionLabel] }}</span></Chip
  >
</template>
