<template>
  <div class="form-item" :class="{ invalid, large, compact, hide: hideDetails }">
    <div class="input-slot">
      <div
        v-if="label"
        class="flex place-content-between items-end"
        :class="{
          'mb-3': large,
          'mb-2': normal,
          'mb-1': compact,
        }"
      >
        <label
          :for="labelFor"
          class="m-0"
          :class="{
            'required-field': requiredField,
            bold: labelBold,
            'text-secondary': disabled,
            'text-main': large,
            'text-small': normal || compact,
          }"
        >
          {{ label }}
          <InfoTooltip
            v-if="tooltipText"
            :tooltip="tooltipText"
            :icon-color="iconColor"
            data-html2canvas-ignore
          />
        </label>
        <div
          class="text-secondary"
          :class="{
            'text-small': large,
            'text-extra-small': normal || compact,
          }"
        >
          <slot name="label-description" />
        </div>
      </div>
      <slot :invalid="invalid" />
    </div>
    <div
      v-if="showMessageContainer"
      class="text-extra-small mt-1"
      :class="['messages', { 'messages-error': invalid }]"
    >
      <div v-if="!invalid && requiredField" class="message">* Required</div>
      <template v-if="showHint">
        <div :class="{ 'text-primary': !disabled, 'text-secondary': disabled }">
          {{ hint }}
        </div>
      </template>
      <div v-if="invalid">
        <div v-for="(error, index) in allErrorMessages" :key="index" class="message">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import isString from 'lodash/isString';
import template from 'lodash/template';
import templateSettings from 'lodash/templateSettings';
import { DEFAULT_VALIDATION_TEMPLATES } from '@/config';
import { colours } from '@/ux/colours';
import InfoTooltip from '@/components/core/InfoTooltip.vue';

templateSettings.interpolate = /{([\s\S]+?)}/g;

/**
 * Wrapper for form inputs to display a label, validation and error messages.
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  components: {
    InfoTooltip,
  },
  props: {
    /**
     * Label for the form input.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Use a bold label
     */
    labelBold: {
      type: Boolean,
      default: false,
    },
    /**
     * Vuelidate validation object for the input.
     */
    validator: {
      type: Object,
      default: null,
    },
    /**
     * Object map of validation messages.  Used to overwrite default validation messages.
     */
    validationTemplates: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Error message to be displayed on the input.  Used to display errors with the input
     * outside of the validation (eg Server Errors).
     */
    errorMessages: {
      type: [String, Array],
      default: null,
    },
    /**
     * Used to overwrite the error messages that use the input field's heading (label) to create error message.
     */
    forceGenericErrorMessage: {
      type: Boolean,
      default: false,
    },
    /**
     * Use a large style for the form input.
     */
    large: {
      type: Boolean,
      default: false,
    },
    /**
     * Used to hide the messages and reclaim spacing around component.
     */
    hideDetails: {
      type: Boolean,
      default: false,
    },
    /**
     * Use a compact style for the form input.
     */
    compact: {
      type: Boolean,
      default: false,
    },
    /**
     * Will render '* Required' below input when true
     */
    requiredField: {
      type: Boolean,
      default: false,
    },
    /**
     * Used to show the tooltip text next to the label
     */
    tooltipText: {
      type: String,
      default: '',
    },
    /**
     * label for attribute
     */
    labelFor: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconColor: colours.ICON.ICON_SECONDARY,
    };
  },
  computed: {
    invalid() {
      return (
        this.errorMessagesAsArray?.length > 0 ||
        (this.validator?.$dirty && this.validator?.$invalid)
      );
    },
    allErrorMessages() {
      if (this.invalid) {
        return (this.validator?.$errors || [])
          .reduce((errors, error) => {
            const validatorName = error.$validator;
            const rule = this.validator[validatorName];
            if (rule?.$invalid) {
              const compiled = template(this.allValidationTemplates[validatorName]);
              errors.push(compiled({ label: this.label, ...rule?.$params }));
            }
            return errors;
          }, [])
          .concat(this.errorMessagesAsArray || []);
      }
      return [];
    },
    allValidationTemplates() {
      return {
        ...DEFAULT_VALIDATION_TEMPLATES,
        ...this.formItemValidationTemplates,
        ...this.validationTemplates,
      };
    },
    formItemValidationTemplates() {
      const localTemplates = {};
      if (this.label) {
        if (!this.forceGenericErrorMessage) {
          localTemplates.required = '{label} is required';
        }
        localTemplates.maxLength = '{label} cannot be more than {max} characters.';
      }
      return localTemplates;
    },
    errorMessagesAsArray() {
      return isString(this.errorMessages) ? [this.errorMessages] : this.errorMessages;
    },
    showMessageContainer() {
      return !this.hideDetails && (this.invalid || this.requiredField || this.showHint);
    },
    showHint() {
      return this.hint && (!this.invalid || this.persistentHint);
    },
    normal() {
      return !this.compact && !this.large;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.form-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: var(--space-24);

  .input-slot {
    position: relative;
    display: block;

    label {
      &.required-field::after {
        content: '*';
      }

      & > .svg-icon {
        margin-bottom: -0.2rem;
        margin-left: 0;
      }
    }

    label.bold {
      color: var(--mkt-grey-text-dark);
      font-weight: var(--font-medium);
    }

    :slotted(input) {
      height: 2.5rem;
      width: 100%;
    }
  }

  .messages {
    color: var(--text-secondary);
    flex: 1 1 auto;
    min-height: var(--x14);
    min-width: 1px;
    position: relative;

    &.messages-error {
      color: var(--error-500);
    }

    .message {
      line-height: 12px;
      word-break: break-word;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }
  }

  &.invalid {
    input {
      border-color: var(--error-500);
    }
  }

  &.hide {
    margin-top: 0;
  }
}
</style>
