import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { useFlagStore } from '@/stores/flag';
import { CHANNELS } from '@/models/dashboards/channels.enum';
import { useSocialListeningStore } from '@/stores/social-listening';
import { TRENDS_DEFAULT_FILTERS } from '@/app/socialListening/constants/listeningFilters';
import { getStartAndEndDate } from '@/app/socialListening/utils/api-request-utils';
import { groupByPrimaryPercentBreakdown } from '@/app/socialListening/utils/graph-utils';
import cloneDeep from 'lodash/cloneDeep';
import { POST_VOLUME_GRAPH_OPTIONS } from '@/app/socialListening/constants';

export const useSocialListeningTrendsStore = defineStore('socialListeningTrends', () => {
  const flagStore = useFlagStore();
  const socialListeningStore = useSocialListeningStore();

  const filters = ref({ ...cloneDeep(TRENDS_DEFAULT_FILTERS) });
  const pending = ref({
    sentimentTrendTimeSeries: false,
  });
  const sentimentTrendTimeSeries = ref(null);
  const queryKeyword = ref('');

  const sourceCreated = computed(() => {
    const dateRange = getStartAndEndDate(filters.value.dateRange);
    return { onOrAfter: dateRange.startDate, onOrBefore: dateRange.endDate };
  });

  function getChannels(channels) {
    const canAccessYoutubeCompliance = flagStore.ready && flagStore.flags.youtubeCompliance;
    if (canAccessYoutubeCompliance) {
      return channels;
    }
    return channels.filter((c) => c !== CHANNELS.YOUTUBE.value);
  }

  const formattedFilters = computed(() => {
    return {
      sources: getChannels(filters.value.channels),
      mediaTypes: { includes: filters.value.mediaTypes },
      keywordsAndHashtags: filters.value.keyword,
      sourceCreated: sourceCreated.value,
    };
  });

  const setFormattedSentimentChartData = (data) => {
    const datasets = [];
    const breakdownGroups = groupByPrimaryPercentBreakdown(
      data,
      POST_VOLUME_GRAPH_OPTIONS.POSTS_BY_SENTIMENT.value,
      filters.value.sentiments.map((key) => key.toLowerCase()),
    );
    Object.entries(breakdownGroups).forEach(([label, dataList]) => {
      datasets.push({
        label,
        data: dataList.map((d) => {
          return { ...d, y: d.totalPosts.replace(',', '') };
        }),
      });
    });
    sentimentTrendTimeSeries.value = datasets;
  };

  function setFilters({
    channels,
    sentiments,
    mediaTypes,
    dateRange,
    keyword,
    keywordTypes,
    customDateRange,
  }) {
    filters.value = {
      channels: channels ? getChannels(channels) : channels,
      sentiments,
      mediaTypes,
      dateRange,
      keyword,
      keywordTypes,
      customDateRange,
    };
  }

  function resetFilters() {
    filters.value = { ...cloneDeep(TRENDS_DEFAULT_FILTERS) };
    queryKeyword.value = '';
  }

  function setKeywordFilter(keyword) {
    filters.value = {
      ...filters.value,
      keyword,
    };
  }

  function removeKeywordFilter() {
    filters.value = {
      ...filters.value,
      keyword: '',
    };
    socialListeningStore.enableTopLevelFilters();
  }

  async function getSentimentTrend() {
    try {
      sentimentTrendTimeSeries.value = null;
      pending.value.sentimentTrendTimeSeries = true;
      const result = await socialListeningStore.getSentimentTrendTimeSeries({
        searchFilters: formattedFilters.value,
        scale: filters.value.scale,
      });
      setFormattedSentimentChartData(result);
    } finally {
      pending.value.sentimentTrendTimeSeries = false;
    }
  }

  return {
    filters,
    queryKeyword,
    removeKeywordFilter,
    setFilters,
    resetFilters,
    setKeywordFilter,
    pending,
    getSentimentTrend,
    sentimentTrendTimeSeries,
    sourceCreated,
    formattedFilters,
  };
});
