import { defineStore } from 'pinia';
import { ref, watch, computed } from 'vue';
import { loadScript } from '@/utils/dom';
import { isDatadogSyntheticsBrowser } from '@/config';
import { env } from '@/env';
import { useIdentityStore } from '@/stores/identity';
import { useFlagStore } from '@/stores/flag';
import * as AuthAPI from '@/apis/auth';

export const useHubspotStore = defineStore('hubspot', () => {
  const flagStore = useFlagStore();
  const identityStore = useIdentityStore();
  const ready = ref(false);
  const ctaReady = ref(false);

  const enabled = env.hubspotEnabled && !isDatadogSyntheticsBrowser;
  const hasHubspotLiveChatFlag = computed(() => flagStore.ready && flagStore.flags.hubspotLiveChat);

  // Initializing HubSpot Tracking Array
  window._hsq = [];

  function onConversationsAPIReady() {
    ready.value = true;
  }

  function onCallToActionAPIReady() {
    ctaReady.value = true;
  }

  function boot() {
    if (enabled) {
      window.hsConversationsSettings = {
        loadImmediately: false,
      };

      if (window.HubSpotConversations) {
        onConversationsAPIReady();
      } else {
        window.hsConversationsOnReady = [onConversationsAPIReady];
      }

      if (window.HubSpotCallsToActions) {
        onCallToActionAPIReady();
      } else {
        window.hsCallsToActionsReady = [onCallToActionAPIReady];
      }

      loadScript(`//js-na1.hs-scripts.com/${env.hubspotTrackingCode}.js`, {
        id: 'hs-script-loader',
      });
    }
  }

  async function getToken() {
    try {
      const res = await AuthAPI.getHubspotSupportToken();
      return res.data.token;
    } catch (e) {
      return '';
    }
  }

  async function loadWidget() {
    // Hubspot Chat Identity
    const identity = identityStore.identity;
    const email = identity.email;

    const token = await getToken();

    window.hsConversationsSettings.identificationEmail = email;
    window.hsConversationsSettings.identificationToken = token;

    window.HubSpotConversations.widget.load();

    // Hubspot Tracking Identity
    window._hsq.push(['identify', { email }]);
  }

  function show() {
    window.HubSpotConversations.widget.open();
  }

  function refreshCallToAction() {
    if (ctaReady.value) {
      window.HubSpotCallsToActions.refresh();
    }
  }

  function trackEvent({ eventName, eventDetails }) {
    window._hsq.push([eventName], eventDetails);
  }

  watch(
    () => ctaReady.value,
    () => {
      refreshCallToAction();
    },
    { immediate: true },
  );

  watch(
    () => ({
      identity: identityStore.identity,
      ready: ready.value,
      flag: hasHubspotLiveChatFlag.value,
    }),
    (context) => {
      if (enabled && context.ready && context.identity && context.flag) {
        loadWidget();
      }
    },
    { immediate: true },
  );

  return {
    enabled,
    ready,
    boot,
    show,
    refreshCallToAction,
    trackEvent,
  };
});
