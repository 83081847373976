<template>
  <div class="emoji-icon-container">
    <div v-if="!disabled" data-cy="EmojiIconWrapper" @click="toggleEmojiPicker">
      <Icon
        ref="emojiIcon"
        :color="iconColor"
        :width="size"
        :height="size"
        data-cy="emoji-icon"
        class="emoji-icon"
        name="smile"
        :hover-color="hoverColor"
      />
    </div>
    <VDropdown
      :shown="showEmojiPicker"
      theme="dh-dropdown-full"
      placement="bottom-end"
      :triggers="[]"
    >
      <template #popper>
        <EmojiPicker
          ref="picker"
          v-on-click-outside="closeEmojiPicker"
          class="tooltip-content picker"
          @select="emojiSelected"
        />
      </template>
    </VDropdown>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { vOnClickOutside } from '@vueuse/components';
import EmojiPicker from '@/components/foundation/EmojiPicker.vue';
import Icon from '@/components/foundation/Icon.vue';
import { colours } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  components: {
    EmojiPicker,
    Icon,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  props: {
    disabled: { type: Boolean, default: false },
    size: { type: String, default: '1.75rem' },
    hoverColor: { type: String, default: null },
  },
  emits: ['emoji-selected', 'opened'],
  data() {
    return {
      iconColor: colours.ICON.ICON_SECONDARY,
      showEmojiPicker: false,
    };
  },
  methods: {
    openEmojiPicker() {
      this.showEmojiPicker = true;
      this.iconColor = colours.ACTION.ACTION_500;
      this.$emit('opened');
    },
    closeEmojiPicker() {
      this.showEmojiPicker = false;
      this.iconColor = colours.ICON.ICON_SECONDARY;
    },
    toggleEmojiPicker(e) {
      e.stopPropagation();
      if (this.showEmojiPicker) {
        this.closeEmojiPicker();
      } else {
        this.openEmojiPicker();
      }
    },
    emojiSelected(emoji) {
      this.$emit('emoji-selected', emoji);
      this.closeEmojiPicker();
    },
  },
});
export default comp;
</script>
