<template>
  <span v-if="availability" class="availability-label" :class="availabilityConfig.style">
    {{ availabilityConfig.label }}
  </span>
</template>

<script>
import { defineComponent } from 'vue';
import { productCardAvailabilityLabels } from '@/app/library/constants';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  props: {
    availability: {
      type: String,
      default: null,
      validator(value) {
        if (!value) {
          return true;
        }
        return Object.keys(productCardAvailabilityLabels).includes(value);
      },
    },
  },
  computed: {
    availabilityConfig() {
      return productCardAvailabilityLabels[this.availability];
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.availability-label {
  font-size: var(--x12);
  font-weight: var(--font-medium);
}

.availability-green {
  color: var(--success-500);
}

.availability-blue {
  color: var(--action-500);
}

.availability-red {
  color: var(--error-500);
}

.availability-grey {
  color: var(--text-secondary);
}
</style>
