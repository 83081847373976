<script setup>
import { ref, computed, watch, nextTick, onMounted } from 'vue';
import { useContentTagsStore } from '@/stores/content-tags';
import { useAuthStore } from '@/stores/auth';
import TagsBase from '@/app/library/components/MediaPopup/TagsBase.vue';
import Chip from '@/components/foundation/Chip.vue';
import ImageDropdownSelect from '@/components/foundation/form/ImageDropdownSelect.vue';

const authStore = useAuthStore();
const contentTagsStore = useContentTagsStore();

const emit = defineEmits(['add', 'remove']);

const props = defineProps({
  brandIds: {
    type: Array,
    default: () => [],
  },
  mediaContentTags: {
    type: Array,
    default: () => [],
  },
  saving: {
    type: Boolean,
    default: false,
  },
  openAddOnMount: {
    type: Boolean,
    default: false,
  },
});

const showAddInput = ref(false);
const contentTagToAdd = ref(null);
const addContentTagInput = ref(null);

const brandIds = computed(() => {
  return props.brandIds?.length > 0 ? props.brandIds : [authStore.currentBrand?.id];
});

const availableContentTags = computed(() => {
  return (
    contentTagsStore.organizationContentTags
      ?.filter((organizationContentTag) => {
        return organizationContentTag.brandIds.some((contentTagBrandId) =>
          brandIds.value.includes(contentTagBrandId),
        );
      })
      ?.filter((organizationContentTag) => {
        return !props.mediaContentTags.some(
          (mediaContentTag) => mediaContentTag.id === organizationContentTag.id,
        );
      })
      ?.map((contentTag) => {
        return {
          text: contentTag.name,
          value: contentTag.id,
        };
      }) ?? []
  );
});
const hasAvailableContentTags = computed(() => availableContentTags.value.length > 0);
const deleting = computed(() =>
  props.mediaContentTags?.some((mediaContentTag) => mediaContentTag.deleting),
);
const hasTags = computed(() => props.mediaContentTags?.length > 0);

function onAddTagClick() {
  showAddInput.value = true;
  nextTick(() => {
    addContentTagInput.value.showOptions();
  });
}

function onOptionsClose() {
  if (!contentTagToAdd.value) {
    showAddInput.value = false;
  }
}

async function onAddContentTag(option) {
  if (option) {
    const contentTag = contentTagsStore.organizationContentTags.find(
      (organizationContentTag) => organizationContentTag.id === option.value,
    );
    emit('add', contentTag);
  }
}

async function onRemoveTagClick(contentTagToRemove) {
  emit('remove', contentTagToRemove);
}

watch(
  () => {
    return {
      contentTagToAdd,
      saving: props.saving,
    };
  },
  () => {
    if (!contentTagToAdd.value && !props.saving) {
      contentTagToAdd.value = null;
      if (hasAvailableContentTags.value) {
        onAddTagClick();
      } else {
        onOptionsClose();
      }
    }
  },
);

onMounted(() => {
  if (props.openAddOnMount) {
    onAddTagClick();
  }
});

// for unit tests
defineExpose({
  availableContentTags,
  showAddInput,
  contentTagToAdd,
});
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <TagsBase
    :tags="mediaContentTags"
    :prevent-actions="props.saving || deleting"
    @add="onAddTagClick"
    @remove="onRemoveTagClick"
  >
    <template #prependTags>
      <template v-if="!hasTags && !showAddInput">
        <span class="text-small">Add</span>
      </template>
      <template v-else>
        <Chip v-if="showAddInput" color="var(--background-500)" small :loading-right="props.saving">
          <ImageDropdownSelect
            ref="addContentTagInput"
            v-model="contentTagToAdd"
            :options="availableContentTags"
            autocomplete
            return-object
            invisible
            min-picker-width="4rem"
            no-data-text="No content tags found."
            data-cy="AddContentTagDropdown"
            @input="onAddContentTag"
            @closed="onOptionsClose"
          />
        </Chip>
      </template>
    </template>
  </TagsBase>
</template>
