<script setup>
import Icon from '@/components/foundation/Icon.vue';
import CollapsibleContainer from '@/components/CollapsibleContainer.vue';
import Button from '@/components/foundation/Button.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';
import { useContentAutomationPermissions } from '@/app/settings/composables/content-automation-permissions';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { noEditContentAutomationPermissionsTooltip } from '@/app/settings/constants/content-automation';
import { ContentAutomationEventTracker } from '@/app/settings/mixpanel';

const router = useRouter();

const props = defineProps({
  automations: {
    type: Array,
    default: null,
  },
  channel: { type: String, required: true },
  mediaType: { type: String, required: true },
});
const eventTracker = new ContentAutomationEventTracker();
const { canManageContentAutomations } = useContentAutomationPermissions();
const emit = defineEmits(['dropdownOpen']);
const dropdownOpen = ref(false);

function toggleDropdown() {
  dropdownOpen.value = !dropdownOpen.value;
  if (dropdownOpen.value) {
    emit('dropdownOpen', 'Automations Triggered', 'Show Automations Triggered');
  }
}

function manageContentAutomations() {
  eventTracker.trackClickConfigure({
    channel: props.channel,
    mediaType: props.mediaType,
  });
  router.push({ name: 'settings.contentAutomation' });
}
</script>

<template>
  <main class="triggered-automations">
    <header @click.prevent="toggleDropdown">
      <span>
        <Icon name="automation" custom-class="mr-2" small />
        Automations Triggered
        <InfoTooltip
          tooltip="Content automations you've created will be listed here if your post triggers one or more"
          xsmall
        />
        <div class="automations-count">
          {{ props.automations.length }}
        </div>
      </span>
      <Icon :class="['dropdown-toggle', { open: dropdownOpen }]" name="caret" xxsmall />
    </header>

    <CollapsibleContainer class="triggered-automations-list" :open="dropdownOpen">
      <div v-for="automation in automations" :key="automation.id" class="row">
        <span class="flex capitalize">{{ automation.name }}</span>
      </div>
      <Button
        v-tooltip="!canManageContentAutomations ? noEditContentAutomationPermissionsTooltip : ''"
        :disabled="!canManageContentAutomations"
        link
        class="ml-7 flex cursor-pointer pt-[10px] text-[12px] leading-none"
        data-cy="add-custom-tags-save"
        @click="manageContentAutomations()"
      >
        Manage Content Automations
      </Button>
    </CollapsibleContainer>
  </main>
</template>

<style scoped lang="postcss">
main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--background-300);
  margin: var(--space-8) auto;
  padding: var(--space-24);
  border-radius: var(--round-corner-small);

  header {
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    font-size: var(--x16);
    color: var(--text-primary);
    cursor: pointer;

    span {
      display: flex;
      align-items: center;

      .svg-icon {
        margin-right: var(--space-16);
      }
    }

    .automations-count {
      height: 1rem;
      border-radius: var(--round-corner-large);
      background: var(--text-secondary);
      color: var(--white);
      padding: 0 var(--space-8);
      font-weight: var(--font-medium);
      font-size: var(--x12);
      display: flex;
      align-items: center;
      line-height: var(--space-16);
    }

    .dropdown-toggle {
      transition: var(--transition-all);

      &.open {
        transform: rotate(90deg);
      }
    }
  }

  .triggered-automations-list {
    width: 100%;

    .row {
      margin-left: var(--space-28);
      padding-bottom: var(--space-8);
      padding-top: var(--space-8);
      font-size: var(--x14);
      line-height: 1;

      &:first-child {
        margin-top: var(--space-16);
      }
    }
  }
}
</style>
