<template>
  <div class="auto-publish-options">
    <p v-if="label" class="label">
      {{ label }}
    </p>
    <RadioInputGroup
      v-model="radioSelection"
      :options="options"
      name="auto-publish-options"
      class="radio-group"
    />
    <DismissibleInlineBanner
      :link="inlineBannerInfo.LINK"
      :qr-code-link="inlineBannerInfo.QR_CODE_LINK"
      :storage-key="inlineBannerInfo.STORAGE_KEY"
      :text="inlineBannerInfo.TEXT"
      :platform="platform"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import { toolTips } from '@/config';
import { colours } from '@/ux/colours';
import { postStatus, postPublishInfoTooltip, inlineBannerInfo } from '@/app/scheduler/constants';
import RadioInputGroup from '@/components/RadioInputGroup.vue';
import DismissibleInlineBanner from '@/components/DismissibleInlineBanner.vue';
import { useSchedulerStore } from '@/stores/scheduler';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'AutoPublishOptions',
  components: {
    DismissibleInlineBanner,
    RadioInputGroup,
  },
  props: {
    hideAutoPublish: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    disableAutoPublish: {
      type: Object,
      default: () => {
        return { isDisabled: false, tip: '' };
      },
    },
    disableSendNotification: { type: [String, Boolean], default: null },
    platform: { type: String, required: true },
    post: { type: Object, default: null },
    value: { type: Boolean, default: null },
    hideSendNotification: { type: Boolean, default: false },
    label: { type: String, default: null },
  },
  emits: ['input'],
  computed: {
    ...mapStores(useSchedulerStore, useAuthStore),
    radioSelection: {
      get() {
        return this.schedulerStore.autoPublish ? 'autoPublish' : 'pushNotification';
      },
      set(newVal) {
        const autoPublish = newVal === 'autoPublish';
        this.schedulerStore.setAutoPublish(autoPublish);
      },
    },
    postIsLive() {
      return [postStatus.IMPORTED, postStatus.POSTED].includes(this.post?.status);
    },
    userDoesNotHavePermission() {
      return !this.authStore.canAccessAutoPublish(this.platform);
    },
    isAutoPublishDisabled() {
      if (this.postIsLive) {
        return { isDisabled: true };
      }
      if (this.userDoesNotHavePermission) {
        return { isDisabled: true, tip: toolTips.insufficientPermission };
      }
      if (this.disableAutoPublish.isDisabled) {
        return this.disableAutoPublish;
      }
      if (this.post?.status === postStatus.AUTOPUBLISHING) {
        return { isDisabled: true };
      }
      return { isDisabled: false, tip: '' };
    },
    options() {
      return [
        ...(!this.hideSendNotification
          ? [
              {
                label: 'Send Notification',
                value: 'pushNotification',
                disabled: this.disableSendNotification || this.disabled,
                disabledTooltip: this.disableSendNotification,
                iconName: 'alarm-timeout',
                iconColor: colours.BRAND.BRAND_ACCENT,
                tooltip: postPublishInfoTooltip.SEND_NOTIFICATION,
              },
            ]
          : []),
        ...(!this.hideAutoPublish
          ? [
              {
                label: 'Auto Publish',
                value: 'autoPublish',
                disabled: this.isAutoPublishDisabled.isDisabled || this.disabled,
                disabledTooltip: this.isAutoPublishDisabled.tip,
                iconName: 'flash',
                iconColor: colours.SUCCESS.SUCCESS_500,
                tooltip: postPublishInfoTooltip.AUTO_PUBLISH,
              },
            ]
          : []),
      ];
    },
    inlineBannerInfo() {
      return inlineBannerInfo;
    },
  },
  watch: {
    isAutoPublishDisabled: {
      immediate: true,
      handler(to) {
        if (!this.disabled) {
          if (to?.isDisabled) {
            this.radioSelection = 'pushNotification';
          }
        }
      },
    },
    value: {
      immediate: true,
      handler(to) {
        if (to === null) {
          this.$emit('input', this.schedulerStore.autoPublish);
        } else if (to !== this.schedulerStore.autoPublish) {
          this.schedulerStore.setAutoPublish(to);
        }
      },
    },
    'schedulerStore.autoPublish': {
      handler(to) {
        if (to !== this.value) {
          this.$emit('input', to);
        }
      },
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.auto-publish-options {
  display: flex;
  flex-direction: column;
  width: 100%;

  .label {
    font-size: var(--x14);
    margin-bottom: var(--space-6);
  }

  .radio-group {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;

    :deep(label.radio-label) {
      width: fit-content;
      white-space: nowrap;
    }

    :deep(label.radio-label):last-child {
      margin-left: var(--space-16);
    }
  }
}
</style>
