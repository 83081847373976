<template>
  <div v-tooltip="tooltip" :style="styleObject" class="grid" data-cy="CalendarTimeSelect">
    <Button
      v-for="(dt, index) in datetimes"
      :key="index"
      :class="{ 'past-time': isDisabled(dt), disabled: disabled }"
      link
      @click="() => timeSelected(dt.datetime)"
    >
      {{ dt.formatted }}
    </Button>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import range from 'lodash/range';
import { defineComponent } from 'vue';
import Button from '@/components/foundation/Button.vue';
import {
  ALL_TIME,
  getFutureAsInterval,
  intersectIntervals,
  isValidInterval,
  isWithinInterval,
} from '@/utils/dateUtils';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'CalendarTimeSelect',
  components: {
    Button,
  },
  props: {
    datetime: { type: Date, required: true },
    offsetTop: { type: Number, required: true },
    offsetLeft: { type: Number, required: true },
    timeSelected: { type: Function, required: true },
    disabled: { type: Boolean, default: false },
    tooltip: { type: String, default: null },
    restrictSelectionToInterval: {
      type: Object,
      default: () => ALL_TIME,
      validator: (value) => value === false || isValidInterval(value),
    },
  },
  computed: {
    datetimes() {
      return range(12)
        .map((i) => dayjs(this.datetime).add(i * 5, 'minutes'))
        .map((djs) => ({ datetime: djs.toDate(), formatted: djs.format('h:mm') }));
    },
    styleObject() {
      return {
        top: `${this.offsetTop}px`,
        left: `${this.offsetLeft}px`,
      };
    },
  },
  methods: {
    isDisabled(option) {
      return !isWithinInterval(
        option.datetime,
        intersectIntervals(getFutureAsInterval(), this.restrictSelectionToInterval),
      );
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.grid {
  position: absolute;
  display: grid;
  grid-gap: var(--space-16);
  grid-template-columns: repeat(4, min-content);
  white-space: nowrap;
  border-radius: var(--round-corner);
  box-shadow: var(--light-shadow);
  padding: var(--space-16);
  z-index: 50;
  background-color: var(--background-0);

  button.past-time,
  button.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
</style>
