<script setup>
import { IMG } from '@/assets';

const props = defineProps({
  showPreviousArrow: {
    type: Boolean,
    default: false,
  },
  showNextArrow: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['clickPreviousArrow', 'clickNextArrow']);

const handlePrevArrowClicked = () => {
  emit('clickPreviousArrow');
};

const handleNextArrowClicked = () => {
  emit('clickNextArrow');
};
</script>

<script>
export default {
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
};
</script>

<template>
  <div>
    <div v-if="props.showPreviousArrow" @click="handlePrevArrowClicked">
      <img ref="previousArrow" class="nav-arrow prev-arrow" :src="IMG.arrowLeft" />
    </div>

    <div v-if="props.showNextArrow" @click="handleNextArrowClicked">
      <img ref="nextArrow" class="nav-arrow next-arrow" :src="IMG.arrowRight" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.nav-arrow {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  padding: var(--space-24);
  cursor: pointer;
}

.prev-arrow {
  position: absolute;
  left: -60px;
}

.next-arrow {
  position: absolute;
  right: -60px;
}
</style>
