<template>
  <SvgIcon
    v-bind="svgIconProps"
    :name="name"
    :width="width ? width : size"
    :height="height ? height : size"
    :dir="dir"
    :style="style"
    :class="['icon', iconCustomClass]"
    :original="original"
    @mousedown.prevent
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  />
</template>

<script>
import { defineComponent } from 'vue';
import SvgIcon from '@/components/foundation/SvgIcon.vue';
import '@/assets/icons/compiled';

const SIZE_MAP = {
  tiny: '8',
  xxxsmall: '10',
  xxsmall: '12',
  xsmall: '16',
  small: '20',
  medium: '24',
  large: '28',
  xlarge: '32',
  xxlarge: '64',
  xxxlarge: '128',
};

/**
 * Icon component used to display svg icons.
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'Icon',
  components: {
    SvgIcon,
  },
  props: {
    /**
     * Name of the svg icon to display.
     */
    name: { type: String, default: null },
    /**
     * Color of the icon.
     */
    color: { type: String, default: null },
    /**
     * Hover color of the icon.
     */
    hoverColor: { type: String, default: null },
    /**
     * Active color of the icon.
     */
    activeColor: { type: String, default: null },
    /**
     * Set the icon in active mode.
     */
    isActive: { type: Boolean, default: false },
    /**
     * Tiny size.
     */
    tiny: { type: Boolean, default: false },
    /**
     * xxxsmall size.
     */
    xxxsmall: { type: Boolean, default: false },
    /**
     * xxsmall size.
     */
    xxsmall: { type: Boolean, default: false },
    /**
     * xmsmall size.
     */
    xsmall: { type: Boolean, default: false },
    /**
     * small size.
     */
    small: { type: Boolean, default: false },
    /**
     * medium size.
     */
    medium: { type: Boolean, default: false },
    /**
     * large size.
     */
    large: { type: Boolean, default: false },
    /**
     * xlarge size.
     */
    xlarge: { type: Boolean, default: false },
    /**
     * xxlarge size.
     */
    xxlarge: { type: Boolean, default: false },
    /**
     * xxxlarge size.
     */
    xxxlarge: { type: Boolean, default: false },
    /**
     * Direction of the icon.  Possible values are up, down, right, left.
     */
    dir: { type: String, default: 'up' },
    /**
     * Height of the icon.
     */
    height: { type: String, default: null },
    /**
     * Width of the icon.
     */
    width: { type: String, default: null },
    /**
     * Custom css class for the icon.
     */
    customClass: { type: String, default: null },
    /**
     * Original color of the icon
     */
    original: { type: Boolean, default: false },
    /**
     * Properties of the svg icon.
     */
    svgIconProps: { type: Object, default: () => {} },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    iconCustomClass() {
      return this.customClass ? this.customClass : '';
    },
    size() {
      const { tiny, xxxsmall, xxsmall, xsmall, small, medium, large, xlarge, xxlarge, xxxlarge } =
        this.$props;
      const sizes = {
        tiny,
        xxxsmall,
        xxsmall,
        xsmall,
        small,
        medium,
        large,
        xlarge,
        xxlarge,
        xxxlarge,
      };
      const size = Object.keys(sizes).find((key) => sizes[key]) || 'medium';
      return SIZE_MAP[size];
    },
    iconColor() {
      if (this.isHovered && this.hoverColor) {
        return this.hoverColor;
      }
      if (this.isActive && this.activeColor) {
        return this.activeColor;
      }
      return this.color;
    },
    style() {
      if (this.iconColor) {
        return { fill: this.iconColor };
      }
      return null;
    },
  },
  watch: {
    name(to, from) {
      // Workaround to remove the hover state because sometimes the mouseleave event does not get
      // triggered after we change the icon
      if (to !== null && to !== from) {
        this.isHovered = false;
      }
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.icon {
  fill: var(--icon-secondary);
}

.svg-up {
  /* default */
  transform: rotate(0deg);
  transition: var(--transition-all);
}

.svg-right {
  transform: rotate(90deg);
  transition: var(--transition-all);
}

.svg-down {
  transform: rotate(180deg);
  transition: var(--transition-all);
}

.svg-left {
  transform: rotate(-90deg);
  transition: var(--transition-all);
}
</style>
