<template>
  <div :class="['details-container', className]">
    <img v-if="avatarUrl" :src="avatarUrl" class="avatar" />
    <div class="body">
      <p class="name">
        {{ name }}
        <Icon v-if="isVerified" :color="blueCheck" name="checkCircleSolid" width="14" height="14" />
      </p>
      <p v-if="locationString" class="location">{{ locationString }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'FacebookMentionDetails',
  components: {
    Icon,
  },
  props: {
    avatarUrl: { type: String, required: true },
    name: { type: String, required: true },
    location: { type: Object, default: null },
    verificationStatus: { type: String, required: true },
    listItem: { type: Boolean, default: false },
  },
  data() {
    return {
      blueCheck: colours.ACTION.ACTION_500,
    };
  },
  computed: {
    isVerified() {
      return this.verificationStatus === 'blue_verified';
    },
    locationString() {
      if (this.location) {
        const { city, state, country } = this.location;
        if (city && state) {
          return `${city}, ${state}`;
        }
        if (city && country) {
          return `${city}, ${country}`;
        }
      }
      return null;
    },
    className() {
      return this.listItem ? 'list-item' : 'standalone-item';
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
.details-container {
  display: flex !important;
  align-items: center;
  padding: var(--space-8) var(--space-16);
  min-height: var(--space-64);
  max-height: fit-content;
  min-width: 100px;
  background: var(--background-0);

  &.standalone-item {
    box-shadow: var(--shadow-4);
    border-radius: var(--round-corner-small);
  }

  .avatar {
    width: var(--space-32);
    height: var(--space-32);
    border-radius: 50%;
    box-shadow: var(--shadow-1);
    margin-right: var(--space-16);
  }

  .name {
    display: flex;
    align-items: center;
    color: var(--text-primary);
    font-size: var(--space-14);
    font-weight: var(--font-medium);

    .icon {
      margin-left: var(--space-4);
    }
  }

  .location {
    color: var(--text-secondary);
    font-size: var(--x12);
  }

  .verified {
    .icon {
      margin: var(--space-8) 0 0 var(--space-8);
    }
  }
}
</style>
