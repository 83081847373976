/* eslint-disable camelcase */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import uniqBy from 'lodash/uniqBy';
import * as AuthAPI from '@/apis/auth';
import {
  browserStorageGetItem,
  browserStorageRemoveItem,
  browserStorageSetItem,
} from '@/utils/browserStorage';
import { flattenBrandUserPermissions } from '@/app/settings/utils';
import { guardRoute } from '@/models/auth/permissions.enum';

import { defaultErrorMessage, LoginMethodLabel } from '@/app/auth/constants';
import { logger } from '@/utils/logger';
import { isSameCustomerPlan } from '@/models/auth/customer-plan.enum';
import { AuthEventTracker } from '@/app/auth/mixpanel';
import { useIdentityStore } from '@/stores/identity';
import { useImpersonatorStore } from '@/stores/impersonator';
import { useOneTimePasswordStore } from './one-time-password';

export const TOKEN_KEY = 'token';
const track = new AuthEventTracker();

export const useAuthStore = defineStore('auth', () => {
  const identityStore = useIdentityStore();
  const impersonatorStore = useImpersonatorStore();
  const oneTimePasswordStore = useOneTimePasswordStore();

  /**
   * Below are passthrough to the identity store.  If you only require access to the
   * identity or currentBrand state, please use the identity store.
   *
   * At some point, we will look to remove these references in the future.
   */

  /** ************************************************************ */
  const identity = computed({
    get() {
      return identityStore.identity;
    },
    set(v) {
      identityStore.identity = v;
    },
  });
  const currentBrandLabel = computed({
    get() {
      return identityStore.currentBrandLabel;
    },
    set(v) {
      identityStore.currentBrandLabel = v;
    },
  });

  const isLoggedIn = computed(() => !!identityStore.identity);
  const canOnlyAccessInactiveBrand = computed(() => identityStore.canOnlyAccessInactiveBrand);
  const currentBrand = computed(() => identityStore.currentBrand);
  const brand_can = computed(() => identityStore.brand_can);
  const user_can = computed(() => identityStore.user_can);
  const identityBrandsById = computed(() => identityStore.identityBrandsById);
  const isSuperAdmin = computed(() => identityStore.isSuperAdmin);
  const canAccessOrganic = computed(() => identityStore.canAccessOrganic);
  const canAccessVision = computed(() => identityStore.canAccessVision);
  const canAccessStoriesSwipeups = computed(() => identityStore.canAccessStoriesSwipeups);
  const brandCanAccessScheduler = computed(() => identityStore.brandCanAccessScheduler);
  const canAccessSchedulerPlatform = computed(() => identityStore.canAccessSchedulerPlatform);
  const canAccessAutoPublish = computed(() => identityStore.canAccessAutoPublish);
  const brandCanAccessIndustryBenchmarks = computed(
    () => identityStore.brandCanAccessIndustryBenchmarks,
  );
  const hasBoardsAccess = computed(() => identityStore.hasBoardsAccess);
  const currentBrandRole = computed(() => identityStore.currentBrandRole);
  const currentPlanType = computed(() => identityStore.currentPlanType);
  const isCurrentBrandRoleAdmin = computed(() => identityStore.isCurrentBrandRoleAdmin);
  const identityBrandIds = computed(() => identityStore.identityBrandIds);
  const canAccessMultipleBrands = computed(() => identityStore.canAccessMultipleBrands);
  const brandsWithAccessToContentTags = computed(() => identityStore.brandsWithAccessToContentTags);

  function guard(...args) {
    return identityStore.guard(...args);
  }

  async function setCurrentBrand(...args) {
    return identityStore.setCurrentBrand(...args);
  }

  function setIdentity(...args) {
    return identityStore.setIdentity(...args);
  }

  function setCurrentBrandByAttributes(...args) {
    return identityStore.setCurrentBrandByAttributes(...args);
  }

  function updateIdentity(...args) {
    return identityStore.updateIdentity(...args);
  }

  function filterBrands(...args) {
    return identityStore.filterBrands(...args);
  }

  function checkIfBrandPermissionsExist(...args) {
    return identityStore.checkIfBrandPermissionsExist(...args);
  }

  function checkIfUserPermissionsExist(...args) {
    return identityStore.checkIfUserPermissionsExist(...args);
  }
  /** ************************************************************ */

  const brandUser = ref(null);
  const brandUsers = ref([]);
  const brandUsersNext = ref(null);
  const brandUsersOffset = ref(0);
  const pending = ref({
    brandUsers: false,
    export: false,
    addTagsToMultipleBrands: false,
    identity: false,
    setCompanyCustomerInfo: false,
    resetUserPassword: false,
    changeUserPassword: false,
  });
  const userListForAllBrandsCSV = ref(null);
  const brandAccessibleUsers = ref(null);
  const samlIdP = ref(null);
  const token = ref(browserStorageGetItem(TOKEN_KEY));
  const oauthVersion = ref(browserStorageGetItem('oauthVersion'));
  const oauthState = ref(browserStorageGetItem('oauthState'));
  const oauthResourceOwnerKey = ref(browserStorageGetItem('oauthResourceOwnerKey'));
  const oauthResourceOwnerSecret = ref(browserStorageGetItem('oauthResourceOwnerSecret'));
  const oauthNext = ref(browserStorageGetItem('oauthNext'));
  const oauthDeniedNext = ref(browserStorageGetItem('oauthDeniedNext'));
  const oauthBrand = ref(browserStorageGetItem('oauthBrand'));
  const oauthBrandToken = ref(browserStorageGetItem('oauthBrandToken'));
  const oauthConnectionId = ref(browserStorageGetItem('oauthConnectionId'));
  const oauthInvitedByBrandId = ref(browserStorageGetItem('oauthInvitedByBrandId'));
  const oauthSourceCreatorId = ref(browserStorageGetItem('oauthSourceCreatorId'));
  const oauthCreatorHandleName = ref(browserStorageGetItem('oauthInfluencer'));

  const isProcessingLogin = ref(false);
  const loginErrorMessage = ref(null);

  const hasAttemptedIdentification = ref(false);
  const isRouteUnauthorized = ref(false); // user level permission check
  const brandTags = ref([]);
  const salesTerritory = ref(null);
  const salesRegion = ref(null);

  function getBrandTagsById(currentOrgOnly = false) {
    if (!identityStore.identity) {
      return {};
    }
    const tagBrandMapping = {};
    const identityBrands = Object.values(identityStore.identity?.brands ?? {});
    let brands = [];
    if (currentOrgOnly) {
      brands = identityBrands.filter(
        (brand) => brand.organizationId === currentBrand.value.organizationId,
      );
    } else {
      brands = identityBrands;
    }

    brands.forEach((brand) => {
      const tags = brand?.tags ?? [];

      tags.forEach((tag) => {
        const brandCountFromTag = brandTags.value.find(
          (brandTag) => tag.id === brandTag.id,
        )?.brandCount;
        if (!tagBrandMapping?.[tag.id]) {
          tagBrandMapping[tag.id] = {
            id: tag.id,
            name: tag.name,
            color: tag.color,
            brands: [],
            brandCount: brandCountFromTag,
          };
        }
        tagBrandMapping[tag.id].brands.push({
          name: brand.name,
          avatarUrl: brand.avatarUrl,
          id: brand.id,
          label: brand.label,
        });
      });
    });
    Object.values(tagBrandMapping).forEach((tag) => {
      tag.inaccessibleBrandsCount = tag.brandCount - tag.brands.length;
    });
    return tagBrandMapping;
  }

  const brandTagsById = computed(() => {
    return getBrandTagsById();
  });

  function hasPlan(incomingPlansToCheck) {
    const plansToCheck = Array.isArray(incomingPlansToCheck)
      ? incomingPlansToCheck
      : [incomingPlansToCheck];
    return plansToCheck.some((plan) => isSameCustomerPlan(plan, currentPlanType));
  }

  async function getBrandUsers({
    brandId,
    email,
    includeRestricted = false,
    holdMutation = false,
  }) {
    pending.value.brandUsers = true;
    try {
      const response = await AuthAPI.getBrandUsers({
        brandId,
        email,
        includeRestricted,
        sort: 'invitation_status,is_admin,first_name,last_name',
      });
      const payload = response?.data;

      if (!holdMutation) {
        brandUsers.value = payload?.data;
        brandUsersNext.value = payload?.paging?.next;
      }

      return response;
    } finally {
      pending.value.brandUsers = false;
    }
  }

  function clearBrandUsers() {
    brandUsers.value = [];
    brandUsersOffset.value = 0;
  }

  async function getPaginatedBrandUsers({ brandId, limit = 20, search, clear }) {
    pending.value.brandUsers = true;
    try {
      if (clear) {
        clearBrandUsers();
      }
      if (brandUsers.value.length === 0 || brandUsersNext.value || clear) {
        const response = await AuthAPI.getBrandUsers({
          brandId,
          limit,
          offset: clear ? 0 : brandUsersOffset.value,
          search,
          includeRestricted: false,
          sort: 'invitation_status,is_admin,first_name,last_name',
        });
        const payload = response?.data;

        brandUsers.value = clear ? payload.data : [...brandUsers.value, ...payload.data];
        brandUsersNext.value = payload.paging.next;
        brandUsersOffset.value += limit;
      }
    } finally {
      pending.value.brandUsers = false;
    }
  }
  function canDeleteBrandTag(tagId) {
    const tag = this.brandTagsById[tagId];
    return tag.inaccessibleBrandsCount === 0;
  }
  async function getBrandUser({ brandId, userId }) {
    const response = await AuthAPI.getBrandUser({ brandId, userId });
    const user = response.data;
    brandUser.value = {
      ...user,
      permission: flattenBrandUserPermissions(user.permission),
    };
  }
  async function createBrandUser({ brandId, email, permissions, role }) {
    return AuthAPI.createBrandUser({ brandId, email, permissions, role });
  }
  async function updateBrandUser({ brandId, userId, role, permissions }) {
    return AuthAPI.updateBrandUser({ brandId, userId, role, permissions });
  }
  async function removeBrandUser({ brandId, userId }) {
    return AuthAPI.removeBrandUser({ brandId, userId });
  }
  async function resendBrandUserInvitation({ brandId, userId }) {
    return AuthAPI.resendBrandUserInvitation({ brandId, userId });
  }
  async function generateApiKey({ brandId, label }) {
    return AuthAPI.generateApiKey({ brandId, label });
  }
  async function getApiKeysForBrand({ brandId }) {
    return AuthAPI.getApiKeysForBrand({ brandId });
  }
  function clearBrandUser() {
    brandUser.value = null;
  }

  async function getUserListForAllBrandsCSV({ userId }) {
    pending.value.export = true;
    try {
      const response = await AuthAPI.getBrandUserExportUserList({
        userId,
      });
      userListForAllBrandsCSV.value = response?.data;
    } catch (error) {
      pending.value.export = false;
      throw error;
    }
  }
  function receivedCSV() {
    pending.value.export = false;
  }
  async function getBrandAccessibleUsers({ brandId }) {
    try {
      const response = await AuthAPI.getBrandUsers({
        brandId,
        sort: 'first_name,last_name',
      });
      const payload = response?.data;
      brandAccessibleUsers.value = payload?.data;
      return response;
    } catch (error) {
      if (error.response?.status === 401) {
        logger.error(
          `auth getBrandAccessibleUsers request returned authentication error: ${error.response?.data?.description}`,
          {},
          error,
        );
        return null;
      }
      throw error;
    }
  }

  function clearBrandAccessibleUsers() {
    brandAccessibleUsers.value = null;
  }

  async function getSAMLIdP({ email }) {
    const response = await AuthAPI.getSAMLIdP({ email });
    samlIdP.value = response?.data;
  }

  function submitFormPost(path, params, method = 'post') {
    const form = document.createElement('form');
    form.setAttribute('method', method);
    form.setAttribute('action', path);

    Object.keys(params).forEach((key) => {
      const hiddenField = document.createElement('input');
      hiddenField.setAttribute('type', 'hidden');
      hiddenField.setAttribute('name', key);
      hiddenField.setAttribute('value', params[key]);

      form.appendChild(hiddenField);
    });

    document.body.appendChild(form);
    form.submit();
  }

  async function createSamlAssertion({ samlRequest, samlRelayState }) {
    const response = await AuthAPI.postSamlAssertion({
      samlRequest,
      samlRelayState,
    });
    const {
      destination,
      saml_response: SAMLResponse,
      relay_state: RelayState,
    } = response?.data ?? {};
    submitFormPost(destination, { SAMLResponse, RelayState });
  }

  async function identify() {
    loginErrorMessage.value = null;
    hasAttemptedIdentification.value = true;
    pending.value.identity = true;
    let selfSubscriptionsResponse;
    let selfActionMetadataResponse;
    let selfResponse;

    try {
      selfResponse = await AuthAPI.getSelf({ token: token.value });
      if (selfResponse) {
        selfSubscriptionsResponse = await AuthAPI.getSelfSubscriptions({ token: token.value });
        selfActionMetadataResponse = await AuthAPI.getSelfActionMetadata({ token: token.value });
      }
    } finally {
      pending.value.identity = false;
    }
    identityStore.setActionMetadata(selfActionMetadataResponse?.data);
    identityStore.setSubscriptions(selfSubscriptionsResponse?.data);
    setIdentity({ identity: selfResponse?.data });
  }

  async function refreshSelfActionMetadata() {
    let res;
    try {
      res = await AuthAPI.getSelfActionMetadata({ token: token.value });
    } catch (error) {
      logger.error('error fetching action metadata for self: ', {}, error);
    }
    identityStore.setActionMetadata(res?.data);
  }

  async function login({ email, password, otpCode }) {
    isProcessingLogin.value = true;
    loginErrorMessage.value = null;
    identityStore.clearIdentity();

    try {
      const response = await AuthAPI.createToken({
        email,
        password,
        otpCode,
      });
      const payload = response?.data;
      const newToken = payload?.token;
      browserStorageSetItem(TOKEN_KEY, newToken);
      token.value = newToken;

      await identify();

      try {
        track.loggedOn({
          method: LoginMethodLabel.email_password,
          enforcedLoginMethod:
            LoginMethodLabel[identityStore.identity.login_configuration.login_method],
        });
      } catch (_) {
        // catch the error to avoid breaking the login flow.
      }
    } catch (error) {
      const otpError = oneTimePasswordStore.handleLoginError({ error, email, password });
      if (!otpError) {
        loginErrorMessage.value =
          error.response?.data?.description ||
          'An error occurred during login. Please try again later.';
      }
      throw error;
    } finally {
      isProcessingLogin.value = false;
    }
  }
  async function logout() {
    identityStore.clearCurrentBrand();
    identityStore.clearIdentity();
    impersonatorStore.clearImpersonatedUser();
    isProcessingLogin.value = false;
    loginErrorMessage.value = null;
    await AuthAPI.destroyToken({ token: token.value });
    token.value = null;
    browserStorageRemoveItem(TOKEN_KEY);
  }

  function setRouteUnauthorized({ unauthorized }) {
    isRouteUnauthorized.value = unauthorized;
  }

  // check permission at user level (looking at identity.permissions)
  function checkRouteAccess({ route }) {
    const unauthorized = !guardRoute(identityStore.identity, identityStore.currentBrand, route);
    setRouteUnauthorized({ unauthorized });
  }

  async function identifyOrLogout() {
    try {
      await identify();
    } catch (e) {
      setRouteUnauthorized({ unauthorized: true });
      await logout();
      return false;
    }
    return true;
  }

  function setToken({ token: newToken }) {
    browserStorageSetItem(TOKEN_KEY, newToken);
    token.value = newToken;
  }
  async function loginByTokenAndBrandLabel({ token: loginToken, brand } = {}) {
    if (loginToken) {
      isProcessingLogin.value = false;
      loginErrorMessage.value = null;

      const response = await AuthAPI.getSelf({ token: loginToken });
      const newIdentity = response?.data;

      loginErrorMessage.value = null;
      identityStore.setIdentity({ identity: newIdentity });
    } else {
      await identify();
    }
    const identityBrandLabels = identityStore.identity?.brands
      ? Object.keys(identityStore.identity.brands)
      : [];
    if (identityBrandLabels.length > 0) {
      if (identityBrandLabels.indexOf(brand) > 0) {
        setCurrentBrand({ brandLabel: brand });
      } else {
        const firstBrandLabel = identityBrandLabels[0];
        setCurrentBrand({ brandLabel: firstBrandLabel });
      }
    }
  }

  async function resetUserPassword({ userId, hash, password }) {
    pending.value.resetUserPassword = true;
    try {
      await AuthAPI.resetUserPassword({ userId, hash, password });
    } finally {
      pending.value.resetUserPassword = false;
    }
  }

  async function changeUserPassword({ userId, password, oldPassword }) {
    pending.value.changeUserPassword = true;
    try {
      await AuthAPI.changeUserPassword({ userId, password, oldPassword });
    } finally {
      pending.value.changeUserPassword = false;
    }
  }

  async function startOAuth({ router, provider, next, brandId }) {
    isProcessingLogin.value = true;
    loginErrorMessage.value = null;
    browserStorageSetItem('startOauthFrom', router.currentRoute.value?.fullPath);

    const redirectUri = `${window.location.origin}${
      router?.resolve({ name: 'auth.callback', params: { provider } })?.href ?? ''
    }`;

    try {
      const response = await AuthAPI.postOAuth({
        provider,
        redirectUri,
        brandId,
      });
      const {
        oauth_version: incomingOauthVersion,
        authorization_url: authUrl,
        state: incomingOauthState,
        resource_owner_key: resourceOwnerKey,
        resource_owner_secret: resourceOwnerSecret,
      } = response.data;
      browserStorageSetItem('oauthNext', next);
      browserStorageSetItem('oauthVersion', incomingOauthVersion);
      if (incomingOauthVersion === 1) {
        browserStorageSetItem('oauthResourceOwnerKey', resourceOwnerKey);
        browserStorageSetItem('oauthResourceOwnerSecret', resourceOwnerSecret);
      } else {
        browserStorageSetItem('oauthState', incomingOauthState);
      }

      window.location.assign(authUrl);
    } catch (error) {
      if (error.response) {
        loginErrorMessage.value = error?.response?.data?.description ?? '';
      } else {
        throw error;
      }
    } finally {
      isProcessingLogin.value = false;
    }
  }

  function cleanupOAuthState() {
    browserStorageRemoveItem('oauthVersion');
    browserStorageRemoveItem('oauthBrand');
    browserStorageRemoveItem('oauthBrandToken');
    browserStorageRemoveItem('oauthConnectionId');
    browserStorageRemoveItem('oauthInvitedByBrandId');
    browserStorageRemoveItem('oauthInfluencer');
    browserStorageRemoveItem('oauthSourceCreatorId');
    browserStorageRemoveItem('oauthNext');
    browserStorageRemoveItem('oauthDeniedNext');
    browserStorageRemoveItem('oauthState');
    browserStorageRemoveItem('oauthResourceOwnerKey');
    browserStorageRemoveItem('oauthResourceOwnerSecret');
  }

  async function processOAuthCallbackLogin({ router, oauth }) {
    isProcessingLogin.value = true;
    loginErrorMessage.value = null;

    try {
      const response = await AuthAPI.createToken({ oauth });
      const payload = response?.data;
      const newToken = payload?.token;
      browserStorageSetItem(TOKEN_KEY, newToken);
      token.value = newToken;
      await identify();

      try {
        track.loggedOn({
          method: LoginMethodLabel.oauth,
          enforcedLoginMethod:
            LoginMethodLabel?.[identityStore.identity?.login_configuration?.login_method],
        });
      } catch (_) {
        // catch the error to avoid breaking the login flow.
      }

      if (oauthNext.value?.startsWith('http')) {
        // TODO: is it used anymore?
        // See https://app.shortcut.com/dashhudson/story/67768/investigate-if-http-support-for-oauthnext-is-used
        window.location = oauthNext;
      } else {
        await router.push(oauthNext);
      }
    } catch (error) {
      logger.error('auth /tokens request returned error: ', {}, error);
      loginErrorMessage.value = error.response?.data?.description || defaultErrorMessage;
      router.push({ name: 'auth.login' });
    } finally {
      isProcessingLogin.value = false;
      cleanupOAuthState();
    }
  }

  async function getBrands(params) {
    try {
      const response = await AuthAPI.getBrands({ params });
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        logger.error(
          `auth /brands request authentication error: ${error.response?.data?.description}`,
          {},
          error,
        );
        return [];
      }
      throw error;
    }
  }

  function canManageBrandTags(identityBrands) {
    const userOrganizationPermissions = this.identity.organization_user_permissions;
    return Object.values(identityBrands).some((brand) => {
      const orgPermissions = userOrganizationPermissions?.[brand.organizationId]?.tags;
      return orgPermissions?.can_manage_brand_tags === true && brand.id === this.currentBrand.id;
    });
  }

  function canManageCompetitorTags(userOrganizationPermissions, brand) {
    return userOrganizationPermissions[brand.organizationId]?.tags?.can_manage_competitor_tags;
  }
  async function fetchBrandTags() {
    const response = await AuthAPI.getBrandTags({
      organizationId: identityStore.currentBrand.organizationId,
    });
    brandTags.value = response.data;
  }
  async function createBrandTag({ name, color }) {
    const response = await AuthAPI.createBrandTag({
      organizationId: identityStore.currentBrand.organizationId,
      name,
      color,
    });
    const newTag = response?.data;
    brandTags.value.push(newTag);
    return newTag;
  }
  async function addTagsToMultipleBrands({ brandIds, selectedTags }) {
    pending.value.addTagsToMultipleBrands = true;
    const brandTagIds = selectedTags.map((tag) => tag.id);
    try {
      await AuthAPI.createBrandTagAssociations({
        organizationId: identityStore.currentBrand.organizationId,
        brandIds,
        brandTagIds,
      });
    } finally {
      pending.value.addTagsToMultipleBrands = false;
    }

    const brands = Object.values(identityStore.identity.brands).filter(
      (brand) => brandIds.indexOf(brand.id) > -1,
    );
    brands.forEach((brand) => {
      brand.tags = uniqBy([...selectedTags, ...brand.tags], 'id');
      identityStore.identity.brands[brand.label] = brand;
    });
  }
  async function removeTagFromBrand({ brandId, tag }) {
    try {
      await AuthAPI.removeTagFromBrand({
        organizationId: tag.organizationId,
        brandId,
        brandTagId: tag.id,
      });
    } catch (error) {
      if (error.response?.status !== 404) {
        throw error;
      }
    }

    const brand = Object.values(identityStore.identity.brands).find(({ id }) => id === brandId);
    if (brand) {
      brand.tags = brand.tags.filter(({ id }) => id !== tag.id);
    }
  }
  async function deleteBrandTag({ tag }) {
    try {
      await AuthAPI.deleteBrandTag({ organizationId: tag.organizationId, brandTagId: tag.id });
    } catch (error) {
      if (error.response?.status !== 404) {
        throw error;
      }
    }
  }

  function getBrandNames(brandIds) {
    const brandNames = [];
    const brandsById = identityBrandsById.value;
    brandIds.forEach((brandId) => {
      if (brandsById[brandId]) {
        brandNames.push(brandsById[brandId].name);
      }
    });
    return brandNames;
  }

  async function setCompanyCustomerInfo() {
    if (currentBrand.value.organization?.company?.id === undefined) {
      salesTerritory.value = null;
      salesRegion.value = null;
    } else
      try {
        pending.value.setCompanyCustomerInfo = true;
        const { data } = await AuthAPI.getCompanyCustomerInformation(
          currentBrand.value.organization?.company?.id,
        );
        salesTerritory.value = data.salesTerritory;
        salesRegion.value = data.country;
      } catch (e) {
        logger.error(
          `auth /companies/${currentBrand.value.organization?.company?.id}/customer_information error description: ${e.response?.data?.description}`,
          {},
          e,
        );
      } finally {
        pending.value.setCompanyCustomerInfo = false;
      }
  }

  return {
    brandUser,
    brandUsers,
    brandUsersNext,
    brandUsersOffset,
    pending,
    userListForAllBrandsCSV,
    brandAccessibleUsers,
    samlIdP,
    token,
    oauthVersion,
    oauthState,
    oauthResourceOwnerKey,
    oauthResourceOwnerSecret,
    oauthNext,
    oauthDeniedNext,
    oauthBrand,
    oauthBrandToken,
    oauthConnectionId,
    oauthInvitedByBrandId,
    oauthSourceCreatorId,
    oauthCreatorHandleName,
    identity,
    isProcessingLogin,
    loginErrorMessage,
    currentBrandLabel,
    hasAttemptedIdentification,
    isLoggedIn,
    isRouteUnauthorized,
    brandTags,
    salesRegion,
    salesTerritory,
    identityBrandsById,
    isSuperAdmin,
    currentBrand,
    brandsWithAccessToContentTags,
    canAccessOrganic,
    canAccessVision,
    canAccessStoriesSwipeups,
    brandCanAccessScheduler,
    canAccessSchedulerPlatform,
    canAccessAutoPublish,
    brandCanAccessIndustryBenchmarks,
    brand_can,
    user_can,
    hasBoardsAccess,
    currentBrandRole,
    currentPlanType,
    isCurrentBrandRoleAdmin,
    identityBrandIds,
    canOnlyAccessInactiveBrand,
    canAccessMultipleBrands,
    brandTagsById,
    getBrandNames,
    guard,
    hasPlan,
    getBrandUsers,
    getPaginatedBrandUsers,
    getBrandUser,
    createBrandUser,
    updateBrandUser,
    removeBrandUser,
    resendBrandUserInvitation,
    generateApiKey,
    getApiKeysForBrand,
    clearBrandUser,
    clearBrandUsers,
    getUserListForAllBrandsCSV,
    receivedCSV,
    getBrandAccessibleUsers,
    clearBrandAccessibleUsers,
    getSAMLIdP,
    createSamlAssertion,
    setIdentity,
    setCurrentBrand,
    login,
    logout,
    checkRouteAccess,
    setCurrentBrandByAttributes,
    setCompanyCustomerInfo,
    identify,
    identifyOrLogout,
    setToken,
    loginByTokenAndBrandLabel,
    resetUserPassword,
    changeUserPassword,
    setRouteUnauthorized,
    startOAuth,
    processOAuthCallbackLogin,
    cleanupOAuthState,
    updateIdentity,
    getBrands,
    checkIfBrandPermissionsExist,
    checkIfUserPermissionsExist,
    fetchBrandTags,
    createBrandTag,
    addTagsToMultipleBrands,
    removeTagFromBrand,
    deleteBrandTag,
    filterBrands,
    canManageBrandTags,
    canManageCompetitorTags,
    canDeleteBrandTag,
    refreshSelfActionMetadata,
    getBrandTagsById,
  };
});
