<template>
  <Popup :close="resetAndClose" enable-drag-select type="large" :style="{ overflow: 'hidden' }">
    <main class="media-gallery-popup">
      <header>
        <Tabs ref="tabs" :tabs="tabs" small @on-change="updateTabView" />
        <div class="right">
          <span v-if="mediaSelectStore.multiSelectSelectedItems.length">
            <span class="blue-stats">{{ mediaSelectStore.multiSelectSelectedItems.length }}</span>
            selected
          </span>
          <div v-tooltip="addButtonDisabledTooltip">
            <Button
              :disabled="addButtonDisabled"
              primary
              wide
              data-cy="select-media-popup-add-media-button"
              :loading="loading"
              @click="handleAddClicked"
            >
              {{ buttonLabel }}
            </Button>
          </div>
        </div>
      </header>

      <div class="select-media-scrollable-area">
        <FindMedia
          v-if="currentTab === tabs[0]"
          cancel-requests
          :limit-source="limitSource"
          :media-type-limit="mediaTypeLimit"
          :allow-mixed-media="allowMixedMedia"
          :media-count-limit="searchMediaLimit"
          :video-count-limit="videoCountLimit"
          :competitive-search-media-sources="competitiveSearchMediaSources"
          :can-be-published-at="canBePublishedAt"
          :disallow-past-publish-dates="disallowPastPublishDates"
          :disallow-incompatible-publish-dates="disallowIncompatiblePublishDates"
          :publish-dates-must-overlap-with="publishDatesMustOverlapWith"
        />

        <div v-else-if="currentTab === 'galleries'">
          <GalleryList
            v-if="galleryView === 'galleryList'"
            @gallery-selected="handleGallerySelected"
          />

          <GalleryDetail
            v-else-if="galleryView === 'galleryDetail'"
            :gallery="selectedGallery"
            :limit-source="limitSource"
            :media-type-limit="mediaTypeLimit"
            :add-to-pinterest-board="addToPinterestBoard"
            :allow-mixed-media="allowMixedMedia"
            :media-count-limit="mediaCountLimit"
            :video-count-limit="videoCountLimit"
            :disable-multi-select="limitToCompetitiveSearch"
            :can-be-published-at="canBePublishedAt"
            :disallow-past-publish-dates="disallowPastPublishDates"
            :disallow-incompatible-publish-dates="disallowIncompatiblePublishDates"
            :publish-dates-must-overlap-with="publishDatesMustOverlapWith"
            @on-back-click="resetGalleriesView"
          />
        </div>
      </div>
    </main>
  </Popup>
</template>

<script>
import { defineComponent } from 'vue';
import { mapStores } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import Button from '@/components/foundation/Button.vue';
import Popup from '@/components/Popup.vue';
import Tabs from '@/components/Tabs.vue';
import FindMedia from '@/app/scheduler/components/EditPost/LibraryMediaSelector/FindMedia.vue';
import GalleryList from '@/app/scheduler/components/EditPost/LibraryMediaSelector/GalleryList.vue';
import GalleryDetail from '@/app/scheduler/components/EditPost/LibraryMediaSelector/GalleryDetail.vue';
import { enumProp } from '@/utils/props';
import enumTypes, { mediaTypes } from '@/app/library/constants';
import { checkTweetMediaIsOriginal } from '@/utils/media';
import { toolTips } from '@/config';
import { useMediaSelectStore } from '@/stores/media-select';
import { useMediaStore } from '@/stores/media';
import { usePopupGalleryStore } from '@/stores/popup-gallery';
import { logger } from '@/utils/logger';
import { useGalleryTagStore } from '@/stores/gallery-tag';
import { ALL_TIME, isValidInterval } from '@/utils/dateUtils';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'SelectMediaPopup',
  components: {
    Button,
    Popup,
    Tabs,
    FindMedia,
    GalleryList,
    GalleryDetail,
  },
  props: {
    close: { type: Function, required: true },
    mediaSelected: { type: Function, required: true },
    limitSource: { type: String, default: null },
    mediaTypeLimit: enumProp(mediaTypes, null),
    allowMixedMedia: { type: Boolean, default: true },
    mediaCountLimit: { type: Number, default: null },
    videoCountLimit: { type: Number, default: null },
    canBePublishedAt: { type: [Date, Object], default: null },
    disallowPastPublishDates: { type: Boolean, default: false },
    disallowIncompatiblePublishDates: { type: Boolean, default: false },
    publishDatesMustOverlapWith: {
      type: Object,
      default: () => ALL_TIME,
      validator: (value) => value === false || isValidInterval(value),
    },
    addToPinterestBoard: { type: Boolean, default: false },
    allowSelectFromGallery: { type: Boolean, default: true },
    buttonLabel: { type: String, default: 'Add' },
    popupTabs: { type: Array, default: () => ['find media', 'galleries'] },
    competitiveSearchMediaSources: { type: Array, default: () => [] },
    useSingleMode: { type: Boolean, default: false },
    mediaIncludeFields: { type: String, default: 'predictions' },
  },
  data() {
    return {
      currentTab: null,
      galleryView: 'galleryList',
      selectedGallery: null,
      loading: false,
    };
  },
  computed: {
    ...mapStores(
      useAuthStore,
      useMediaSelectStore,
      useMediaStore,
      usePopupGalleryStore,
      useGalleryTagStore,
    ),
    tabs() {
      const tabs = this.popupTabs;
      if (!this.allowSelectFromGallery) {
        const index = tabs.indexOf('galleries');
        if (index !== -1) {
          tabs.splice(index, 1);
        }
      }

      return tabs;
    },
    disableUnselected() {
      return (
        this.limitMediaCount &&
        this.mediaSelectStore.multiSelectSelectedItems.length >= this.limitMediaCount
      );
    },
    nonOriginalTweetMediaSelected() {
      return this.mediaSelectStore.multiSelectSelectedItems.some(
        (item) => !checkTweetMediaIsOriginal(item),
      );
    },
    addButtonDisabled() {
      return (
        this.mediaSelectStore.multiSelectSelectedItems.length === 0 ||
        (this.limitSource === enumTypes.TWITTER && this.nonOriginalTweetMediaSelected)
      );
    },
    addButtonDisabledTooltip() {
      if (this.limitSource === enumTypes.TWITTER && this.nonOriginalTweetMediaSelected) {
        return toolTips.twitter.cannotAddNonOriginalTweetToBoard;
      }
      return null;
    },
    limitToCompetitiveSearch() {
      return this.competitiveSearchMediaSources.length > 0;
    },
    searchMediaLimit() {
      if (this.competitiveSearchMediaSources.length > 0 && this.useSingleMode) {
        return 1;
      }
      return this.mediaCountLimit;
    },
  },
  watch: {
    disableUnselected(to) {
      if (to) {
        this.mediaSelectStore.setDisabledMode();
      } else {
        this.mediaSelectStore.setMultipleMode();
      }
    },
  },
  mounted() {
    this.changeTab(0);
    this.mediaSelectStore.disableMultiSelectBar();
    if (this.useSingleMode) {
      this.mediaSelectStore.setSingleMode();
    }
  },
  unmounted() {
    this.mediaSelectStore.enableMultiSelectBar();
    this.mediaSelectStore.setMultipleMode();
  },
  methods: {
    changeTab(index) {
      this.currentTab = this.tabs[index];
    },
    updateTabView(option) {
      this.changeTab(option);
      this.popupGalleryStore.clearGallerySearchTerm();
      this.galleryTagStore.clearGalleryTagFilters();
      this.popupGalleryStore.clearGalleries();
      this.mediaSelectStore.clearItemsFromMultiSelect();
      this.resetGalleriesView();
    },
    async handleAddClicked() {
      try {
        this.loading = true;
        let selectedMedia;
        if (!this.limitToCompetitiveSearch) {
          selectedMedia = await this.mediaStore.validateMediaList(
            {
              brandId: this.authStore.currentBrand.id,
              mediaArrList: this.mediaSelectStore.multiSelectSelectedItems,
            },
            this.mediaIncludeFields,
          );
        } else {
          selectedMedia = this.mediaSelectStore.multiSelectSelectedItems;
        }
        await this.mediaSelected(selectedMedia);
      } catch (e) {
        logger.error('Failed to add board to campaign.', {}, e);
      } finally {
        this.loading = false;
        this.resetAndClose();
      }
      // Before passing media to the scheduler popup, validate that it is the correct object shape
      // and if not, call the Media V2 endpoint for it.
    },
    handleGallerySelected(option) {
      this.galleryView = 'galleryDetail';
      this.selectedGallery = option;
    },
    resetGalleriesView() {
      this.galleryView = 'galleryList';
    },
    resetAndClose() {
      this.mediaSelectStore.clearItemsFromMultiSelect();
      this.popupGalleryStore.clearGallerySearchTerm();
      this.galleryTagStore.clearGalleryTagFilters();
      this.popupGalleryStore.clearGalleryMedia();
      this.popupGalleryStore.clearGalleries();
      this.close();
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.media-gallery-popup {
  padding-top: var(--space-16);
  height: auto;
  width: 100%;
  overflow: hidden;
  border-radius: var(--round-corner);

  header {
    display: flex;
    justify-content: space-between;
    padding: 0 var(--space-24) var(--space-16);

    .tab-container {
      padding-top: var(--space-6);
    }

    .right {
      display: flex;
      align-items: center;

      span {
        font-size: var(--x18);

        &.blue-stats {
          color: var(--action-500);
          margin-right: var(--space-4);
        }
      }

      button {
        margin-left: var(--space-24);
      }
    }
  }

  .select-media-scrollable-area {
    max-height: 80vh;
    overflow-y: scroll;
  }
}
</style>
