<template>
  <span ref="textEle" v-tooltip="tooltip">{{ text }}</span>
</template>

<script>
import { defineComponent } from 'vue';
import { isElementOverflow } from '@/utils';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'TooltipOnelineText',
  props: {
    text: { type: String, default: '' },
  },
  data() {
    return {
      tooltip: null,
    };
  },
  mounted() {
    if (isElementOverflow(this.$refs.textEle)) {
      this.tooltip = {
        content: this.text,
        theme: 'dh-tooltip-medium',
        delay: { show: 300, hide: 0 },
      };
    }
  },
});
export default comp;
</script>
