<template>
  <label class="circular-checkbox">
    <input id="termCheck" :checked="isSelected" type="checkbox" name="" />
    <div
      class="control-indicator"
      data-cy="media-tile-checkbox-control-indicator"
      @click.prevent.stop="toggleMultiSelect"
    />
  </label>
</template>

<script>
import { defineComponent } from 'vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'MediaTileCheckbox',
  props: {
    isSelected: { type: Boolean, default: false },
  },
  emits: ['toggled'],
  methods: {
    toggleMultiSelect() {
      this.$emit('toggled');
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
/* check box- default blue------------------------------ */

.circular-checkbox {
  position: relative;
  display: block;
  padding-left: var(--space-32);
  cursor: pointer;
  text-align: left;
  z-index: var(--z-index-raised);
}

.circular-checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.control-indicator {
  position: absolute;
  background: rgb(255 255 255 / 60%);
  top: 0.25rem;
  left: 0.25rem;
  border: 1px solid transparent;
  transition: var(--transition-all);
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
}

/* Checkbox tick */
.control-indicator::after {
  position: absolute;
  display: block;
  content: '';
  top: 3px;
  left: 7px;
  transform: rotate(45deg);
  border: solid var(--text-primary);
  width: 0.3125rem;
  height: 0.625rem;
  border-width: 0 3px 3px 0;
  box-sizing: content-box;
}

/* Hover and focus states */
.circular-checkbox:hover input ~ .control-indicator,
.circular-checkbox input:focus ~ .control-indicator {
  background: rgb(255 255 255 / 100%);
}

/* Checked state */
.circular-checkbox input:checked ~ .control-indicator {
  background: var(--action-500);
  border: 1px solid var(--action-500);
}

/* Disabled state */
.circular-checkbox input:disabled ~ .control-indicator {
  pointer-events: none;
  opacity: 0.6;
  background: #e6e6e6;
}

/* Disabled tick colour */
.circular-checkbox input:disabled ~ .control-indicator::after {
  border-color: #7b7b7b;
}

.circular-checkbox input:checked ~ .control-indicator::after {
  border-color: #fff;
}

/* Hover state whilst checked */
.circular-checkbox input:checked:focus ~ .control-indicator,
.circular-checkbox:hover input:not([disabled]):checked ~ .control-indicator {
  background: var(--action-400);
}
</style>
