<template>
  <div v-show="remainingLength < textLimit" class="text-limit-progress-circle">
    <span v-if="remainingLength <= 20" :style="{ color: svgStrokeColor }">
      {{ remainingLength }}
    </span>
    <svg width="120" height="120" viewBox="0 0 120 120">
      <circle :r="radius" cx="60" cy="60" fill="none" stroke="#dddddd" stroke-width="12" />
      <circle
        :stroke-dashoffset="svgOffset"
        :stroke="svgStrokeColor"
        :stroke-dasharray="circumference"
        :r="radius"
        cx="60"
        cy="60"
        fill="none"
        stroke-width="12"
      />
    </svg>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { colours } from '@/ux/colours';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'TextLimitProgressCircle',
  props: {
    remainingLength: { type: Number, required: true },
    textLimit: { type: Number, required: true },
  },
  data() {
    return {
      radius: 54,
    };
  },
  computed: {
    circumference() {
      return Math.PI * 2 * this.radius;
    },
    svgOffset() {
      const percentUsed = this.remainingLength / this.textLimit;
      // If the user has used all or more of the maxTweetLength, set the offset to 0. Otherwise,
      // set the offset to the value of the circumference * percent of maxTweetLength used.
      if (this.remainingLength < 0) {
        return 0;
      }
      return this.circumference * percentUsed;
    },
    svgStrokeColor() {
      if (this.remainingLength > 20) {
        return colours.ACTION.ACTION_500;
      }
      if (this.remainingLength <= 0) {
        return colours.ERROR.ERROR_500;
      }
      return colours.ALERT.ALERT_500;
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.text-limit-progress-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: var(--x12);

  svg {
    height: 1.12rem;
    width: 1.12rem;
    transform: rotate(-90deg);
    margin-left: var(--space-4);
  }
}
</style>
