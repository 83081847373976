<template>
  <div class="flex items-center gap-2">
    <Avatar v-bind="$attrs" :img-src="avatarUrl" :tooltip="tooltipContent">
      <template v-if="!avatarUrl">
        <template v-if="iconName">
          <Icon :name="iconName" />
        </template>
        <template v-else-if="userShouldDisplayAsPending">
          <Icon name="user-timeout" />
        </template>
        <span v-else-if="userInitials" class="initials">
          {{ userInitials }}
        </span>
        <slot v-else name="fallbackIcon"></slot>
      </template>
    </Avatar>
    <div v-if="showName">
      {{ formattedUserName }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import Icon from '@/components/foundation/Icon.vue';
import { getUserInitials, getFormattedUserName } from '@/utils/user';
import Avatar from '@/components/foundation/Avatar.vue';

/**
 * Displays a circular avatar for the given user.  This component wraps the Avatar component.
 */
const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'UserAvatar',
  components: {
    Avatar,
    Icon,
  },
  props: {
    /**
     * Icon to display if the user's avatar url isn't present.
     */
    iconName: { type: String, default: null },
    /**
     * Brand user object used to display the user's avatar.  Will use user's avatar_url, invitation_status,
     * or initials of first_name/last_name to draw avatar.
     */
    user: { type: Object, default: null },
    /**
     * Show full name tooltip if set to true.  If set to a string, will show custom tooltip.
     */
    tooltip: {
      type: [Boolean, String, Function],
      default: false,
    },
    showName: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    avatarUrl() {
      return this.user?.avatarUrl || this.user?.avatar_url;
    },
    userInitials() {
      if (!this.user) {
        return null;
      }
      return getUserInitials(this.user);
    },
    userShouldDisplayAsPending() {
      const invitationStatus = this.user?.invitationStatus || this.user?.invitation_status;
      return !!invitationStatus && invitationStatus !== 'completed';
    },
    formattedUserName() {
      return getFormattedUserName(this.user) || this.user?.email || '';
    },
    tooltipContent() {
      if (isFunction(this.tooltip)) {
        return this.tooltip(this.user);
      }
      if (isString(this.tooltip)) {
        return this.tooltip;
      }
      if (this.tooltip === true) {
        return this.formattedUserName;
      }
      return '';
    },
  },
});
export default comp;
</script>

<style lang="postcss" scoped>
.initials {
  text-transform: uppercase;
}
</style>
