<template>
  <main class="insights">
    <header @click.prevent="toggleDropdown">
      <span>
        <Icon name="stories" />
        Instagram Stories Insights
      </span>
      <Icon :class="['dropdown-toggle', { open: dropdownOpen }]" name="caret" xxsmall />
    </header>

    <div :class="['insights-list', { open: dropdownOpen }]">
      <ul>
        <template v-for="(value, key) in mediaItem.insights">
          <li v-if="value != null" :key="key">
            <!-- '!= null' will filter both null and undefined,
               '!== null' will only catch null
                we want the first case here -->
            <span class="stat-name">
              {{ formatKey(key) }}
              <InfoTooltip v-if="showTooltipIcon(key)" :tooltip="tooltips[key]" xsmall />
            </span>
            <span class="stat">{{ formatValue(key, value) }}</span>
          </li>
        </template>

        <li class="link-items">
          <span class="stat-name">
            Link Clicks
            <InfoTooltip
              v-if="!canAccessStoriesSwipeups"
              :tooltip="tooltips.swipeUpsUnavailable"
              xsmall
            />
            <InfoTooltip v-else-if="isUgcStory" :tooltip="tooltips.ugcStorySwipeUps" xsmall />
            <InfoTooltip v-else :tooltip="tooltips.swipeUps" xsmall />
          </span>
          <p class="stat">{{ swipeUps }}</p>
        </li>
        <li v-if="hasSwipeUpStats" class="link-items">
          <span class="stat-name">Swipe Up Link</span>
          <p class="stat">
            <a
              :href="mediaItem.shortURLData.swipeUpLink"
              :title="mediaItem.shortURLData.swipeUpLink"
              target="_blank"
              rel="noopener"
            >
              {{ truncateUrl(mediaItem.shortURLData.swipeUpLink, 30) }}
            </a>
          </p>
        </li>
        <li v-if="hasSwipeUpStats" class="link-items">
          <span class="stat-name">
            Trackable Link
            <InfoTooltip :tooltip="tooltips.trackableLink" />
          </span>
          <p class="stat">
            <a :href="displayTrackableLink" target="_blank" rel="noopener">
              {{ displayTrackableLink }}
            </a>
          </p>
        </li>
      </ul>
    </div>
  </main>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState as mapPiniaState } from 'pinia';
import startCase from 'lodash/startCase';
import { useAuthStore } from '@/stores/auth';
import { sourceEnum, toolTips } from '@/config';
import { truncateUrl } from '@/utils';
import Icon from '@/components/foundation/Icon.vue';
import InfoTooltip from '@/components/core/InfoTooltip.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  name: 'IGStoriesInsights',
  components: {
    Icon,
    InfoTooltip,
  },
  props: {
    mediaItem: { type: Object, default: null },
  },
  data() {
    return {
      dropdownOpen: true,
      tooltips: toolTips.instagramStory,
    };
  },
  computed: {
    ...mapPiniaState(useAuthStore, ['canAccessStoriesSwipeups']),
    isUgcStory() {
      return this.mediaItem.sourceType === sourceEnum.INSTAGRAM_STORY_UGC;
    },
    hasSwipeUpStats() {
      return this.mediaItem.shortURLData && this.mediaItem.shortURLData.swipeUpLink;
    },
    displayTrackableLink() {
      if (this.mediaItem.shortURLData.swipeUpLink && !this.mediaItem.shortURLData.trackableLink) {
        return '-';
      }
      return this.mediaItem.shortURLData.trackableLink;
    },
    swipeUps() {
      if (!this.canAccessStoriesSwipeups) {
        return '-';
      }
      if (this.mediaItem.shortURLData && this.mediaItem.shortURLData.swipeUpLink) {
        return this.mediaItem.shortURLData.swipeUps || 0;
      }
      return '-';
    },
  },
  methods: {
    truncateUrl,
    formatKey(key) {
      if (key === 'likeshopClicks') {
        // we want LikeShop instead of Likeshop, have to change it manually.
        return 'LikeShop Clicks';
      }
      return startCase(key);
    },
    formatValue(key, value) {
      if (value === -1 || this.mediaItem.importedViaDm) {
        return '-';
      }
      if (['exitRate', 'completionRate'].includes(key)) {
        return `${(value * 100).toFixed(2)}%`;
      }
      return value.toLocaleString();
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    showTooltipIcon(key) {
      return Object.keys(toolTips.instagramStory).includes(key);
    },
  },
});
export default comp;
</script>

<style scoped lang="postcss">
main {
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    font-size: var(--x16);
    color: var(--text-primary);
    padding: var(--space-8) 0;
    cursor: pointer;

    span {
      display: flex;
      align-items: center;

      .svg-icon {
        margin-right: var(--space-12);
      }
    }

    .dropdown-toggle {
      transition: var(--transition-all);

      &.open {
        transform: rotate(90deg);
      }
    }
  }

  .at-mentions {
    display: flex;
    flex-wrap: wrap;
    margin: var(--space-16) 0 0 var(--space-32);
    padding-left: var(--space-4);

    span {
      display: flex;
      align-items: center;
      font-weight: var(--font-medium);
      color: var(--text-primary);
      font-size: var(--x12);
      line-height: 1.6rem;
      text-align: center;
      border: 1px solid var(--border);
      border-radius: var(--button-border-radius);
      padding: 0 var(--space-12);
      margin: 0 var(--space-4) var(--space-8) 0;
      height: 1.8rem;

      .svg-icon {
        margin-right: var(--space-4);
        color: var(--text-primary);
      }
    }
  }

  .stat-name {
    padding-right: var(--space-8);
  }

  .stat {
    max-width: 60%;
    word-break: break-word;
    text-align: right;
  }

  .insights-list {
    visibility: hidden;
    height: 0;
    opacity: 0;
    transition: all 0.3s;

    ul {
      margin: var(--space-16) 0 0 var(--space-32);
      padding-left: var(--space-4);

      li::before {
        float: left;
        width: 0;
        white-space: nowrap;
        content: '--------------------------------------------------------------------------------';
        color: var(--border);
      }

      li {
        display: flex;
        justify-content: space-between;
        margin: var(--space-8) 0;
        overflow: hidden;

        span {
          display: flex;
          align-items: center;
          color: var(--text-primary);

          svg {
            margin-left: var(--space-8);
          }
        }

        span + span,
        p {
          float: right;
          padding-left: var(--space-8);
          background: var(--background-300);
        }

        span:first-child {
          position: absolute;
          background: var(--background-300);
        }

        .stat {
          color: var(--action-500);
        }
      }
    }

    &.open {
      visibility: visible;
      height: auto;
      opacity: 1;
    }
  }

  .post-caption {
    margin: var(--space-8) 0 0 var(--space-32);
    padding-left: var(--space-4);
    text-align: left;
    color: var(--text-primary);
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  button {
    margin: var(--space-16) auto var(--space-12) auto;
  }

  .link-items {
    align-items: flex-start;
  }
}
</style>
