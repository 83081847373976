export const TWITTER_OVERVIEW_SINGLE_BRAND = [
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metrics: ['TOTAL_FOLLOWERS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metrics: ['NET_NEW_FOLLOWERS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metrics: ['FOLLOWER_GROWTH_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metrics: ['AVG_ENGAGEMENT_RATE'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metrics: ['LINK_CLICKS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 0,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metrics: ['IMPRESSIONS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 0,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metrics: ['ORGANIC_VIDEO_VIEWS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 1,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metrics: ['TOTAL_ENGAGEMENTS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 2,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metrics: ['REPLIES'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 3,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metrics: ['RETWEETS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 4,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metrics: ['QUOTE_TWEETS'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 2,
    type: 'TOTAL_METRIC',
    w: 1,
    x: 5,
    y: 2,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metrics: ['LIKES'],
      aggregate_by: 'TOTAL',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metric: 'NET_NEW_FOLLOWERS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 4,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metric: 'AVG_ENGAGEMENT_RATE',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 0,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metric: 'ORGANIC_VIDEO_VIEWS',
    },
  },
  {
    h: 4,
    type: 'GRAPH',
    w: 3,
    x: 3,
    y: 8,
    meta: {
      brand_ids: ['{brandId0}'],
      channels: ['TWITTER'],
      metric: 'IMPRESSIONS',
    },
  },
  {
    h: 1,
    type: 'HEADER',
    w: 6,
    x: 0,
    y: 12,
    meta: {
      name: 'Tweet Performance',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 13,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 17,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'VIDEO_VIEWS',
      sort_order: 'DESC',
    },
  },
  {
    h: 4,
    type: 'CONTENT',
    w: 6,
    x: 0,
    y: 21,
    meta: {
      brand_ids: ['{brandId0}'],
      channel: 'TWITTER',
      metric: 'ENGAGEMENT_RATE',
      sort_order: 'ASC',
    },
  },
];
