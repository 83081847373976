<script setup>
import { computed, ref } from 'vue';
import { colours } from '@/ux/colours';
import Icon from '@/components/foundation/Icon.vue';
import VisionToolbar from '@/components/VisionAi/VisionToolbar.vue';
import { VISION_AI_WIDGET_INTERACTIONS, NEW_CHAT_TOOLTIP } from '@/components/VisionAi/constants';
import { aiWidgetInteractionEvent } from '@/components/VisionAi/utils/mixpanel-events';
import sphereAnimation from '@/assets/animations/lottie/vision-ai-sphere-blue.json';
import { useVisionAiConversationsStore } from '@/stores/vision-ai-conversations';
import { useVisionAiStore } from '@/stores/vision-ai';
import { useVisionAiMessagesStore } from '@/stores/vision-ai-messages';

const props = defineProps({
  chatPopupIsExpanded: { type: Boolean, default: false },
  showingConversationHistory: { type: Boolean, default: false },
});

const visionAiConversationsStore = useVisionAiConversationsStore();
const visionAiStore = useVisionAiStore();
const visionAiMessagesStore = useVisionAiMessagesStore();

const hoverOnButton = ref(false);

const emit = defineEmits([
  'closeChatWindow',
  'clearChatHistory',
  'launchVisionAiResourceCenter',
  'expandChatPopup',
  'shrinkChatPopup',
  'showConversationHistory',
  'hideConversationHistory',
]);

const hasConversations = computed(() => {
  return (
    visionAiConversationsStore.conversations.length > 0 || visionAiMessagesStore.anySummaryLoaded
  );
});

const sphereAnimationProps = computed(() => {
  return {
    animationData: sphereAnimation,
    width: '1.75rem',
    height: '1.75rem',
    renderer: 'canvas',
  };
});

const resizeIcon = computed(() => {
  if (!visionAiStore.canAccessConversational) return null;
  if (props.chatPopupIsExpanded) {
    return {
      dataCy: 'shrink-chat-popup',
      buttonClass: 'shrink-icon',
      clickEvent: (event) => {
        emit('shrinkChatPopup', event);
        aiWidgetInteractionEvent({
          interaction: VISION_AI_WIDGET_INTERACTIONS.SHRINK,
        });
      },
      iconName: 'shrinkDiagonal',
      tooltip: {
        content: 'Minimize Window',
        boundary: 'viewport',
        placement: 'top-end',
      },
      iconHoverColor: colours.TEXT.TEXT_PRIMARY,
      showBorderOnHover: true,
      iconHeight: '32px',
      iconWidth: '32px',
    };
  }
  return {
    dataCy: 'expand-chat-popup',
    buttonClass: 'expand-icon',
    clickEvent: (event) => {
      emit('expandChatPopup', event);
      aiWidgetInteractionEvent({
        interaction: VISION_AI_WIDGET_INTERACTIONS.EXPAND,
      });
    },
    iconName: 'expandDiagonal',
    tooltip: {
      content: 'Expand Window',
      boundary: 'viewport',
      placement: 'top-end',
    },
    iconHoverColor: colours.TEXT.TEXT_PRIMARY,
    showBorderOnHover: true,
    iconHeight: '32px',
    iconWidth: '32px',
  };
});

const conversationHistoryIcon = computed(() => {
  if (!visionAiStore.canAccessConversational) return null;
  if (!props.showingConversationHistory) {
    return {
      dataCy: 'show-chat-history',
      buttonClass: 'shrink-icon',
      clickEvent: (event) => {
        emit('showConversationHistory', event);
        aiWidgetInteractionEvent({
          interaction: VISION_AI_WIDGET_INTERACTIONS.SHOW_CHAT_HISTORY,
        });
      },
      iconName: 'chatHistory',
      tooltip: {
        content: 'Show Chat History',
        boundary: 'viewport',
        placement: 'top-end',
      },
      iconHoverColor: colours.TEXT.TEXT_PRIMARY,
      showBorderOnHover: true,
      iconHeight: '32px',
      iconWidth: '32px',
    };
  }

  return {
    dataCy: 'hide-chat-history',
    buttonClass: 'shrink-icon',
    clickEvent: (event) => {
      emit('hideConversationHistory', event);
      aiWidgetInteractionEvent({
        interaction: VISION_AI_WIDGET_INTERACTIONS.HIDE_CHAT_HISTORY,
      });
    },
    iconName: 'hideChatHistory',
    tooltip: {
      content: 'Hide Chat History',
      boundary: 'viewport',
      placement: 'top-end',
    },
    iconHoverColor: colours.TEXT.TEXT_PRIMARY,
    showBorderOnHover: true,
    iconHeight: '32px',
    iconWidth: '32px',
  };
});

const newChatDisabled = computed(() => {
  return (
    visionAiConversationsStore.isCurrentConversationEmpty &&
    !visionAiConversationsStore.summariesSelected
  );
});

const newChatTooltip = computed(() => {
  const tooltip = newChatDisabled.value ? NEW_CHAT_TOOLTIP.DISABLED : NEW_CHAT_TOOLTIP.ENABLED;
  return {
    content: tooltip,
    boundary: 'viewport',
    placement: 'top-end',
  };
});

const showNewChat = computed(() => {
  return !props.chatPopupIsExpanded && visionAiStore.canAccessConversational;
});

const toolbarIconsProps = computed(() => {
  // Properties needed to properly render each Button component
  const toolbarIcons = [];
  if (resizeIcon.value) toolbarIcons.push(resizeIcon.value);
  if (conversationHistoryIcon.value) toolbarIcons.push(conversationHistoryIcon.value);
  return toolbarIcons.concat([
    {
      buttonClass: 'help-icon',
      clickEvent: (event) => {
        emit('launchVisionAiResourceCenter', event);
        aiWidgetInteractionEvent({
          interaction: VISION_AI_WIDGET_INTERACTIONS.HELP,
        });
      },
      iconName: 'question-mark',
      tooltip: {
        content: 'Help',
        boundary: 'viewport',
        placement: 'top-end',
      },
      iconHoverColor: colours.TEXT.TEXT_PRIMARY,
      showBorderOnHover: true,
      iconHeight: '24px',
      iconWidth: '24px',
    },
    {
      dataCy: 'clear-chat-history',
      buttonClass: 'clear-chat-history',
      disabled: !hasConversations.value,
      clickEvent: (event) => {
        emit('clearChatHistory', event);
        aiWidgetInteractionEvent({
          interaction: VISION_AI_WIDGET_INTERACTIONS.DELETE_ICON_CLICKED,
        });
      },
      hoverColor: !hasConversations.value ? null : colours.ERROR.ERROR_500,
      iconName: 'bin',
      iconHoverColor: !hasConversations.value ? null : colours.ERROR.ERROR_500,
      showBorderOnHover: hasConversations.value,
      iconHeight: '24px',
      iconWidth: '24px',
      tooltip: {
        content: 'Delete Entire Chat History',
        boundary: 'viewport',
        placement: 'top-end',
      },
      mouseEnterEvent: () => {
        hoverOnButton.value = true;
      },
      mouseLeaveEvent: () => {
        hoverOnButton.value = false;
      },
    },
    {
      dataCy: 'close-chat-popup',
      buttonClass: 'border-action-200 mt-4',
      clickEvent: (event) => emit('closeChatWindow', event),
      iconName: 'chevronDown',
      iconHoverColor: colours.TEXT.TEXT_PRIMARY,
      iconHeight: '24px',
      iconWidth: '24px',
    },
  ]);
});

async function startNewChat() {
  if (newChatDisabled.value) return;

  aiWidgetInteractionEvent({
    interaction: VISION_AI_WIDGET_INTERACTIONS.NEW_CHAT,
  });
  visionAiStore.clearLocalChatHistory();
  await visionAiConversationsStore.createConversation();
}

defineExpose(
  process.env.NODE_ENV === 'test'
    ? {
        resizeIcon,
        conversationHistoryIcon,
        toolbarIconsProps,
      }
    : {},
);
</script>

<template>
  <VisionToolbar display-vertically :clickable-icons="toolbarIconsProps" class="w-8 pt-6">
    <template #leftIcons>
      <div class="flex flex-col items-center justify-end gap-4">
        <Vue3Lottie v-bind="sphereAnimationProps" />
        <transition name="fade">
          <Icon
            v-if="showNewChat"
            v-tooltip="newChatTooltip"
            data-cy="sidebarNewChat"
            name="newChat"
            :color="colours.TEXT.TEXT_LINK"
            medium
            :class="newChatDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'"
            @click="startNewChat"
          />
        </transition>
      </div>
    </template>

    <template #clickableIcons="{ iconName, iconColor, iconHeight, iconWidth, iconHoverColor }">
      <Icon
        :name="iconName"
        :color="hoverOnButton && hasConversations.value ? iconHoverColor : iconColor"
        :hover-color="iconHoverColor"
        :height="iconHeight"
        :width="iconWidth"
      />
    </template>
  </VisionToolbar>
</template>
