<template>
  <div v-show="loading || items.length" class="items" data-cy="mention-search-list">
    <CircularLoader v-if="loading" class="loading-spinner" />
    <template v-else-if="items.length">
      <button
        v-for="(item, index) in items"
        :key="index"
        class="item"
        :class="{ 'is-selected': index === selectedIndex }"
        @click.stop="selectItem(index)"
        @mouseenter="selectedIndex = index"
      >
        <component v-bind="item" :is="mentionDetailsComponent" list-item />
      </button>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import throttle from 'lodash/throttle';
import CircularLoader from '@/components/CircularLoader.vue';

const comp = defineComponent({
  compatConfig: {
    ATTR_FALSE_VALUE: 'suppress-warning',
    COMPONENT_V_MODEL: 'suppress-warning',
    WATCH_ARRAY: 'suppress-warning',
  },
  components: {
    CircularLoader,
  },
  props: {
    query: { type: String, required: true },
    insertSuggestion: { type: Function, required: true },
    mentionDetailsComponent: { type: Object, required: true },
    onMentionAdd: { type: Function, required: true },
    onMentionSearch: { type: Function, required: true },
  },
  data() {
    return {
      loading: false,
      items: [],
      selectedIndex: 0,
    };
  },
  watch: {
    query: {
      immediate: true,
      handler(to) {
        if (to) {
          (this.throttledLoadItems ?? this.loadItems)(to);
        }
      },
    },
    items() {
      this.selectedIndex = 0;
    },
  },
  methods: {
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler();
        return true;
      }
      if (event.key === 'ArrowDown') {
        this.downHandler();
        return true;
      }
      if (event.key === 'Enter') {
        this.enterHandler();
        return true;
      }
      return false;
    },
    upHandler() {
      this.selectedIndex = (this.selectedIndex + this.items.length - 1) % this.items.length;
    },
    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    },
    enterHandler() {
      this.selectItem(this.selectedIndex);
    },
    selectItem(index) {
      const item = this.items[index];
      if (item) {
        this.insertSuggestion({ id: item.id, label: item.text });
        this.onMentionAdd(item);
      }
    },
    async loadItems(query) {
      this.loading = true;
      this.items = await this.onMentionSearch(query);
      this.loading = false;
    },
    throttledLoadItems: throttle(
      function throttledLoadItems(query) {
        this.loadItems(query);
      },
      250,
      { leading: true, trailing: true },
    ),
  },
});
export default comp;
</script>

<style lang="postcss">
.items {
  width: max-content;
  min-width: 17rem;
  background: var(--background-0);
  overflow: hidden;
  border-radius: var(--round-corner-small);
  box-shadow: var(--shadow-4);
  padding: var(--space-8) 0;
}

.item {
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  cursor: pointer;

  &.is-selected > div {
    background-color: var(--background-300);
  }
}

.loading-spinner {
  margin: 1rem auto !important;
}
</style>
