/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'bin': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.5 2.5v1.667h5v1.666h-15V4.167h5V2.5h5zM4.165 16.667c0 .919.748 1.666 1.667 1.666h8.333c.92 0 1.667-.747 1.667-1.666v-10H4.165v10zm1.667-8.334h8.333l.001 8.334H5.832V8.333zM7.5 10h1.667v5H7.5v-5zm5 0h-1.667v5H12.5v-5z" _fill="#000"/>'
  }
})
